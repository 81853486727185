import { GenericTableData } from 'components/GenericTable/GenericTable'
import { TraineeDTO } from 'model/ctrl/trainees/GetTraineesRes.schema'

export type TraineeData = TraineeDTO & 
  GenericTableData & { displayName?: string }


export interface TraineesState {
  data: TraineeData[]
  meta: {
    loading: boolean
    notFound: boolean
    error: string | null
  }
}

export const defaultTraineesState = {
  data: [],
  meta: {
    loading: false,
    notFound: false,
    error: null,
  },
}
