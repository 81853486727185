import { defineMessages } from 'react-intl'

export default defineMessages({
  trainerTableName: {
    id: 'cp.components.instituteTrainerTable.name',
    defaultMessage: 'Name',
  },
  trainerTableTitle: {
    id: 'cp.components.instituteTrainerTable.title',
    defaultMessage: 'Trainers',
  },
  trainerTableSubtitle: {
    id: 'cp.components.instituteTrainerTable.subtitle',
    defaultMessage: 'All trainer of that institute',
  },
  trainerTableButton: {
    id: 'cp.components.instituteTrainerTable.button',
    defaultMessage: 'Invite Trainer',
  },
  tableRowsStreet: {
    id: 'cp.components.instituteTrainerTable.tableRowsStreet',
    defaultMessage: 'Street',
  },
  trainerEmptyMessage: {
    id: 'cp.components.instituteTrainerTable.trainerEmptyMessage',
    defaultMessage: 'No trainers yet',
  },
  selected: {
    id: 'cp.components.instituteTrainerTable.selected',
    defaultMessage: 'selected',
  },
  deleteLabel: {
    id: 'cp.components.instituteTrainerTable.deleteLabel',
    defaultMessage: 'delete',
  },
  deleteTrainerMessage: {
    id: 'cp.components.instituteAdminTable.deleteTrainerMessage',
    defaultMessage:
      'Do you really want to delete the selected trainer? This will be permanent.',
  },
})
