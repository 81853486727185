import { Locale } from 'model/Locale'
import { SignUpStatus } from 'model/User.base'
import { BaseUserDTO } from 'model/User.dto'
import { USERROLES } from '../../model/UserRole'

export const defaultUserState: BaseUserDTO = {
  _id: 'no-such-id',
  email: '',
  familyName: '',
  firstName: '',
  role: USERROLES.Unknown,
  availableRoles: [],
  gender: 'other',
  signUpStatus: SignUpStatus.pending,
  token: 'no-such-token',
  settings: { locale: Locale.en },
}

export interface SessionState {
  user: BaseUserDTO
  token: string | null
}

export const defaultSessionState: SessionState = {
  user: defaultUserState,
  token: null,
}
