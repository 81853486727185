import axios from 'axios'
import { AppStore } from 'state/store'
import { tools } from 'services/utils/tools'

export interface AxiosServiceMessages {
  type: 'Axios/Config/Set'
  payload: { token: boolean }
}

export const connectAxios = (store: AppStore) => {
  const { onChange } = tools(store)

  axios.defaults.responseType = 'json'

  onChange(state => state.session.token).subscribe(token => {
    if (token) {
      axios.defaults.headers['authorization'] = 'Bearer ' + token
    } else {
      axios.defaults.headers['authorization'] = undefined
    }
    store.dispatch({
      type: 'Axios/Config/Set',
      payload: { token: token !== null },
    })
  })

  // If loged in the request permission header is set to the current users active role
  onChange(state => state.session.user).subscribe(user => {
    console.log('user.role: ', user.role)
    if (user) {
      axios.defaults.headers['request-with-role'] = user.role
    } else {
      axios.defaults.headers['request-with-role'] = undefined
    }
  })
}
