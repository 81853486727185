import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.userChangeEmail.notFound.title',
    defaultMessage: 'Not found.',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.userChangeEmail.notFound.message',
    defaultMessage: 'Sorry, this user does not exist anymore Sorry :(',
  },
  changeEmail: {
    id: 'cp.pages.userChangeEmail.changeEmail',
    defaultMessage: 'Change E-Mail',
  },
  newEmail: {
    id: 'cp.pages.userChangeEmail.newEmail',
    defaultMessage: 'New E-Mail',
  },
  cancelButton: {
    id: 'cp.pages.userChangeEmail.cancelButton',
    defaultMessage: 'Cancel',
  },
  sendEmail: {
    id: 'cp.pages.userChangeEmail.sendEmail',
    defaultMessage: 'E-Mail send',
  },
  emailChangedSnackbar: {
    id: 'cp.pages.userChangeEmail.emailChangedSnackbar',
    defaultMessage: 'E-Mail changed',
  },
  emailErrorSnackbar: {
    id: 'cp.pages.userChangeEmail.emailErrorSnackbar',
    defaultMessage: 'Could not change E-Mail',
  },
  verifyEmailTitle: {
    id: 'cp.pages.userChangeEmail.verifyEmail.title',
    defaultMessage: 'Please verify your E-Mail',
  },
  verifyEmailContent: {
    id: 'cp.pages.userChangeEmail.verifyEmail.content',
    defaultMessage:
      'If you want we can keep you informed about the latest and greates changes to our app and products. This is an opt-in but still we would like to offer you the full capabilities of our app. Therefore we need to verify your E-Mail address.',
  },
  verifyEmailButton: {
    id: 'cp.pages.userChangeEmail.verifyEmail.button',
    defaultMessage: 'Send me a new verification E-Mail to my address',
  },
  ok: {
    id: 'cp.pages.userChangeEmail.ok',
    defaultMessage: 'OK',
  },
  email: {
    id: 'cp.pages.userChangeEmail.email',
    defaultMessage: 'E-Mail',
  },
})
