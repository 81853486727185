import { InstituteAdminUserDTO } from 'model/User.dto'

export interface AdminsState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  admins: InstituteAdminUserDTO[]
}

export const defaultAdminsState: AdminsState = {
  meta: {
    loading: false,
    notFound: false,
  },
  admins: [],
}
