import { Message } from '@restate/core'
import { routes } from '../../model/ctrl/routes'
import { USERROLES } from '../../model/UserRole'
import { tools } from '../../services/utils/tools'
import { AppStore } from '../../state/store'
import { defaultOnboardingState, OnboardingProgress } from './Reminder.state'

export interface ReminderPageServiceMessages extends Message {
  type:
    | 'Pages/Service/Reminder/Update'
    | 'Pages/Service/Reminder/Error'
    | 'Pages/Service/Reminder/Email/Verify'
}

export const connectReminderPageService = (store: AppStore) => {
  const { http, hasUserRole, onLogout, onAction, snackBar } = tools(store)

  hasUserRole(USERROLES.Trainee).subscribe(() => {
    http<OnboardingProgress>({
      route: { path: routes.onboarding.onboarding },
    }).subscribe(
      onboarding => {
        store.next(
          state => {
            state.pages.onboarding.onboarding = onboarding
            state.pages.onboarding.meta.loading = false
            state.pages.onboarding.meta.notFound = false
          },
          { type: 'Pages/Service/Reminder/Update' }
        )
      },

      _error => {
        console.error(_error)
        store.next(
          state => {
            state.pages.onboarding.onboarding =
              defaultOnboardingState.onboarding
            state.pages.onboarding.meta.loading = false
            state.pages.onboarding.meta.notFound = true
          },
          { type: 'Pages/Service/Reminder/Error' }
        )
      }
    )
  })

  onAction({ type: 'Pages/Service/Reminder/Email/Verify' }).subscribe(() => {
    http<string>({
      route: { path: routes.onboarding.resendVerificationEmail },
    }).subscribe(
      () => {
        snackBar.success('The verification E-Mail has been sent')
      },
      () => {
        console.error('Error sending verification E-Mail: ')
        snackBar.error('Sorry, verification E-Mail could not be sent')
      }
    )
  })

  onLogout().subscribe(() => {
    store.next(state => {
      state.pages.onboarding.onboarding = defaultOnboardingState.onboarding
      state.pages.onboarding.meta.loading = true
      state.pages.onboarding.meta.notFound = false
    })
  })
}
