import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AppDrawer from 'components/AppDrawer/AppDrawer'
import { NavBar } from 'components/NavBar/NavBar'
import React from 'react'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { MainPageLayout } from '../layouts/MainPageLayout'
import { center } from 'styles/styles'
import { PublicPageLayout } from 'components/layouts/PublicPageLayout'

const useStyles = makeStyles({
  container: {
    ...center,
  },

  content: {
    ...center,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
    },

    '& img': {
      width: '50%',
      minHeight: 400,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
      },
    },
  },
  title: {
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  txt: {
    margin: theme.spacing(3),
  },
})

interface PageNotFoundProps {
  variant?: 'app' | 'public'
  title?: string
  message?: string
  img?: string
  showBackButton?: boolean
  action?: () => React.ReactNode
}

export const PageNotFound: React.FunctionComponent<PageNotFoundProps> = props => {
  const classes = useStyles()
  const {
    title,
    message,
    img,
    action,
    variant = 'app',
    showBackButton = false,
  } = props
  const url = img || Images.missingUrl

  const Title = () => (
    <>
      {title && (
        <Typography variant='h5' className={classes.title}>
          {title}
        </Typography>
      )}
    </>
  )
  const Img = () => <img src={url} alt='missing' aria-hidden={true} />

  const Message = () => (
    <>
      {' '}
      {message && <Typography className={classes.txt}>{message}</Typography>}
    </>
  )

  const Action = () => (
    <>{action && <div className={classes.txt}>{action()}</div>}</>
  )

  const Layout = variant === 'public' ? PublicPageLayout : MainPageLayout

  return (
    <Layout title={title || ''}>
      {variant === 'app' && (
        <>
          <NavBar showBackButton={showBackButton} title={''} />
          <AppDrawer />
        </>
      )}
      <div className={classes.container}>
        <div className={classes.content}>
          <Title />
          <Img />
          <Message />
          <Action />
        </div>
      </div>
    </Layout>
  )
}
