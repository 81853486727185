import { Message } from '@restate/core'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { TrainerDTO } from 'model/ctrl/trainers/GetTrainersRes.schema'
import { UUID } from 'model/UUID'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { USERROLES } from '../../model/UserRole'

import messages from './Trainers.messages'
// TODO: this action should be in an own service as it is used on different pages
export interface TrainersPageMessages extends Message {
  type: 'Pages/Trainers/Delete'
  payload: UUID[]
}

export const connectTrainersPage = (store: AppStore) => {
  const { http, onAction, snackBar, onPageLeave, intl, onLoginPageEnter } =
    tools(store)
  const successSnackbar = intl().formatMessage(messages.successSnackbar)
  const errorSnackbar = intl().formatMessage(messages.errorSnackbar)

  const { api } = config()

  onPageLeave(AppRoutes.Trainers).subscribe(() => {
    store.next(s => {
      s.pages.trainers.meta = { loading: true, notFound: false }
    })
  })

  onAction({ type: 'Pages/Trainers/Delete' }).subscribe(pck => {
    const ids = (pck.message as TrainersPageMessages).payload

    ids.forEach(id => {
      http({
        method: 'DELETE',
        route: {
          path: routes.userDelete,
          id,
        },
      }).subscribe(
        () => snackBar.success(successSnackbar),
        () => snackBar.success(errorSnackbar)
      )
    })

    // TODO: del multiple users
    const id = ids[0]
    const nextTrainersData = store.state.pages.trainers.data.filter(
      trainer => trainer._id !== id
    )
    store.next(s => {
      s.pages.trainers.data = nextTrainersData
    })
  })

  onLoginPageEnter(AppRoutes.Trainers, {
    roles: [USERROLES.CorPatchAdmin, USERROLES.InstituteAdmin],
  }).subscribe(() => {
    http<TrainerDTO[]>({
      method: 'GET',
      url: api + routes.trainers,
    }).subscribe(trainers => {
      store.next(s => {
        s.pages.trainers.meta.loading = false
        s.pages.trainers.data = trainers.map(t => ({
          id: t._id,
          selected: false,
          isSelectionEnabled: true,
          displayName: t.firstName + ' ' + t.familyName,
          ...t,
        }))
      })
    })
  })
}
