export interface LoginPasswordForgotSetPasswordState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  success: boolean
}

export const defaultLoginPasswordForgotSetPasswordState: LoginPasswordForgotSetPasswordState = {
  meta: {
    loading: true,
    notFound: false,
  },
  success: false,
}
