import React from 'react'
import { useAppState } from 'state/store'
import { Typography, Card, CardContent } from '@material-ui/core'
import {
  termsOfUse_en_US,
  termsOfUse_de_DE,
  termsOfUse_da_DA,
  termsOfUse_fi_FI,
  termsOfUse_it_IT,
  termsOfUse_fr_FR,
  termsOfUse_es_ES,
} from './terms_of_use'

interface TermsOfUseProps {
  cardStyle: object
}

export const TermsOfUse = (props: TermsOfUseProps) => {
  return (
    <Card style={props.cardStyle}>
      <CardContent>
        <TermsOfUseText />
      </CardContent>
    </Card>
  )
}

export const TermsOfUseText = () => {
  const locale = useAppState(state => state.i18n.locale)

  let __html: string = "imprint"

  switch (locale) {
    case 'en-US':
      __html = termsOfUse_en_US
      break
    case 'de-DE':
      __html = termsOfUse_de_DE
      break
    case 'da-DA':
      __html = termsOfUse_da_DA
      break
    case 'fi-FI':
      __html = termsOfUse_fi_FI
      break
    case 'it-IT':
      __html = termsOfUse_it_IT
      break
    case 'fr-FR':
      __html = termsOfUse_fr_FR
      break
    case 'es-ES':
      __html = termsOfUse_es_ES
      break
  }

  return (
    <Typography
      style={{ margin: '20px' }}
      color='textSecondary'
      dangerouslySetInnerHTML={{ __html: __html }}
    ></Typography>
  )
}

export default TermsOfUse
