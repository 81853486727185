import { routes } from 'model/ctrl/routes'
import { UserDetailsDTO } from 'model/ctrl/user/GetUserRes.schema'
import { AppRoutes } from 'routes'
import { combineLatest } from 'rxjs'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { Message } from '@restate/core'

import messages from './UserDetails.messages'

export interface UserDetailsSendInvite extends Message {
  type: 'Page/UserDetails/SendInvite'
}

export const connectUserPageService = (store: AppStore) => {
  const {
    http,
    pageParams,
    onPageEnter,
    onLogin,
    onPageLeave,
    onAction,
    snackBar,
    intl,
  } = tools(store)
  const successSnackbar = intl().formatMessage(messages.successSnackbar)
  const errorSnackbar = intl().formatMessage(messages.errorSnackbar)

  const getUserId = pageParams(AppRoutes.UserDetails)

  onAction({ type: 'Page/UserDetails/SendInvite' }).subscribe(() => {
    const { id } = getUserId()

    http({
      route: {
        path: routes.userInviteAgain,
        id,
      },
    }).subscribe(
      () => snackBar.success(successSnackbar),
      () => snackBar.error(errorSnackbar)
    )
  })

  onPageLeave(AppRoutes.UserDetails).subscribe(() => {
    store.next(s => {
      s.pages.userDetails = {
        meta: {
          loading: true,
          notFound: false,
        },
        userDetails: null,
      }
    })
  })

  combineLatest(onPageEnter(AppRoutes.UserDetails), onLogin()).subscribe(() => {
    const { id } = getUserId()

    store.next(s => {
      s.pages.userDetails = {
        meta: {
          loading: true,
          notFound: false,
        },
        userDetails: null,
      }
    })

    http<UserDetailsDTO>({
      route: {
        path: routes.user,
        id,
      },
    }).subscribe(
      // Success
      userDetails => {
        store.next(s => {
          s.pages.userDetails.meta = {
            notFound: false,
            loading: false,
          }
          s.pages.userDetails.userDetails = userDetails
        })
      },
      // Error
      () => {
        store.next(s => {
          s.pages.userDetails = {
            meta: {
              notFound: true,
              loading: false,
            },
            userDetails: null,
          }
        })
      }
    )
  })
}
