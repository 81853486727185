import { UserRole } from './UserRole'
import { UUID } from './UUID'
import { EMail } from './Email'
import { UserSettings } from './UserSettings'

export enum SignUpStatus {
  pending = 'pending',
  valid = 'valid',
}

export interface UserBase {
  firstName: string
  familyName: string
  gender: 'female' | 'male' | 'other'
  roles: UserRole[]
  email: EMail
  signUpStatus: SignUpStatus
  settings: UserSettings
  instituteId?: UUID
}
