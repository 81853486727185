import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.networkError.pageNotFound.title',
    defaultMessage: 'Oh no! The server seems do be down!',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.networkError.pageNotFound.message',
    defaultMessage:
      'The server was not able to complete the request. Are you connected to the Internet? ',
  },
  pageNotFoundButton: {
    id: 'cp.pages.networkError.pageNotFound.button',
    defaultMessage: 'Try again',
  },
})
