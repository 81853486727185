import { TrainingsTableData } from '../../components/TrainingsTable/TrainingsTable'

export interface TrainingsTablePageState {
  meta: {
    loading: false
  }
  data: TrainingsTableData[]
}

export const defaultTrainingsTableState: TrainingsTablePageState = {
  meta: {
    loading: false,
  },
  data: [],
}
