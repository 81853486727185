/**
 * The role of the user
 */
export type UserRole =
  | 'CorPatchAdmin'
  | 'InstituteAdmin'
  | 'Trainer'
  | 'Trainee'
  | 'Researcher'
  | 'Unknown'

/**
 * The UserRole type as an enum to avoid typos
 */
export const USERROLES: {
  CorPatchAdmin: UserRole
  InstituteAdmin: UserRole
  Trainer: UserRole
  Trainee: UserRole
  Researcher: UserRole
  Unknown: UserRole
} = {
  CorPatchAdmin: 'CorPatchAdmin',
  InstituteAdmin: 'InstituteAdmin',
  Trainer: 'Trainer',
  Trainee: 'Trainee',
  Researcher: 'Researcher',
  Unknown: 'Unknown',
}

/**
 * All available roles with different permissions. These roles are ordered in descending order of their permission priviledges
 * It is important that if you add a new role you respect this order because it is implied in other functions e.g. getMostProviledgedRole()
 */
export const allRoles: UserRole[] = [
  USERROLES.CorPatchAdmin,
  USERROLES.InstituteAdmin,
  USERROLES.Trainer,
  USERROLES.Trainee,
  USERROLES.Researcher,
  USERROLES.Unknown,
]

/**
 *
 * @param roles a users roles
 * @returns the role with the highest permission priviledges
 */
export const getMostProviledgedRole = (roles: UserRole[]) => {
  for (const role of allRoles) {
    if (roles.includes(role)) return role
  }

  return USERROLES.Unknown
}

/**
 *
 * @param requiredRoles array of roles of which at least one must be matched by the user
 * @param userRoles all roles of the user
 * @returns boolean if the user fulfills at least one of the required roles
 */
export const hasAnyRolesOf = (
  requiredRoles: UserRole[],
  userRoles: UserRole[]
) => {
  for (const role of userRoles) {
    if (requiredRoles.includes(role)) return true
  }

  return false
}
