import { Button, IconButton, TableCell, Tooltip } from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  GenericTable,
  GenericTableCellHeader,
} from 'components/GenericTable/GenericTable'
import { InstituteUserDto } from 'model/ctrl/institute/GetInstituteRes.schema'
import React, { useState } from 'react'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState, useDispatchHook, useNextAppState } from 'state/store'
import { theme } from 'theme/theme'
import { useStyles } from './useStyles'
import { FormattedMessage, useIntl } from 'react-intl'

import messages from './InstituteAdminTable.messages'
import { SelectedUserIds } from 'pages/Institute/SelectedUserIds'
import { ConfirmDeletionDialog } from 'components/dialogs/ConfirmDeletionDialog'

const cellHeaders = (name: string, eMail: string): GenericTableCellHeader[] => {
  return [
    { id: 'firstName', numeric: false, disablePadding: false, label: name },
    { id: 'email', numeric: false, disablePadding: false, label: eMail },
    { id: 'actions', numeric: true, disablePadding: false, label: '' },
  ]
}

const search = (searchTerm: string) => {
  const searchReg = RegExp(searchTerm, 'i')
  return (entry: InstituteUserDto) =>
    searchReg.test(entry.firstName) ||
    searchReg.test(entry.familyName) ||
    searchReg.test(entry.email)
}

export const InstituteAdminTable: React.FC = () => {
  const intl = useIntl()
  const tableTitle = intl.formatMessage(messages.adminsTableTitle)
  const tableSubtitle = intl.formatMessage(messages.adminsTableSubtitle)
  const name = intl.formatMessage(messages.name)
  const email = intl.formatMessage(messages.adminsTableEmail)
  const inviteButton = intl.formatMessage(messages.adminsTableInviteButton)
  const selected = intl.formatMessage(messages.selected)
  const deleteLabel = intl.formatMessage(messages.deleteLabel)
  // const deleteAdminMessage = intl.formatMessage(messages.deleteAdminMessage)

  const classes = useStyles()
  const goTo = useGoToHook()
  const dispatch = useDispatchHook()

  const instituteId = useAppState(state => state.pages.institute.id)
  const [selectedIdsToDelete, setSelectedIdsToDelete] = useState<
    SelectedUserIds
  >(null)
  const goToInvitePage = () => goTo(AppRoutes.AdminInvite, { id: instituteId })
  const next = useNextAppState(s => s.pages.institute)

  const admins = useAppState(state => state.pages.institute.admins).map(
    admin => ({
      id: admin._id,
      selected: false,
      isSelectionEnabled: true,
      ...admin,
    })
  )

  const getAdminRoles = (id: string) => {
    return admins.find(a => a._id === id)?.roles ?? []
  }

  const deleteAdmins = (ids: string[]) => {
    ids.forEach(id =>
      next(s => {
        s.admins = s.admins.filter(admin => admin._id !== id)
        s.trainers = s.trainers.filter(trainer => trainer._id !== id)
      })
    )

    dispatch({ type: 'Pages/Trainers/Delete', payload: ids })
  }

  const navigateToUserDetailsPage = (id: string) =>
    goTo(AppRoutes.UserDetails, { id })

  return (
    <>
      <div style={{ width: '100%', marginTop: theme.spacing(2) }}>
        <GenericTable
          singleSelect={true}
          title={tableTitle}
          subTitle={tableSubtitle}
          rows={admins}
          headers={cellHeaders(name, email)}
          search={search as any}
          selectedItemsMessage={({ count }) => (
            <FormattedMessage
              id='institutesTable'
              defaultMessage={`{count, number} {count, plural, one { admin } other { admins }} ${selected}`}
              // defaultMessage={deleteAdminMessage}
              values={{ count }}
            />
          )}
          rowRender={({ row }) => (
            <>
              <TableCell>
                {row.firstName} {row.familyName}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => navigateToUserDetailsPage(row.id)}>
                  <RightIcon />
                </IconButton>
              </TableCell>
            </>
          )}
          tools={({ selected, setSelected }) => (
            <Tooltip title={deleteLabel}>
              <IconButton
                aria-label={deleteLabel}
                onClick={() => {
                  setSelectedIdsToDelete({
                    selectedIds: selected,
                    clearSelection: () => setSelected([]),
                  })
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        />

        <ConfirmDeletionDialog
          open={
            !!selectedIdsToDelete &&
            selectedIdsToDelete?.selectedIds.length === 1
          }
          userRoles={getAdminRoles(selectedIdsToDelete?.selectedIds[0] ?? '')}
          onPositiveButtonClick={() => {
            deleteAdmins(selectedIdsToDelete?.selectedIds ?? [])
            selectedIdsToDelete?.clearSelection()
            setSelectedIdsToDelete(null)
          }}
          onNegativeButtonClick={() => {
            selectedIdsToDelete?.clearSelection()
            setSelectedIdsToDelete(null)
          }}
        />

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.button}
            onClick={goToInvitePage}
          >
            {inviteButton}
          </Button>
        </div>
      </div>
    </>
  )
}
