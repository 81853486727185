import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'
import { CircularTrainingProgress } from 'components/CircularTrainingProgress/CircularTrainingProgress'
import {
  GenericTable,
  GenericTableCellHeader,
  GenericTableData,
} from 'components/GenericTable/GenericTable'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import React from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useAppState, useDispatchHook, useNextAppState } from 'state/store'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import messages from './SingleTrainings.messages'
import { SingleTrainingView } from './SingleTrainings.page-service'
import { useSingleTrainingsState } from './SingleTrainings.restate'

function ShareDialog({
  open = false,
  onCancel = () => {},
  onShare = () => {},
}) {
  const intl = useIntl()
  const selectedTraineeSharingObject = useAppState(
    // TODO: typings AND wording of this - otherwise it's not maintainable
    s => s.pages.singleTrainings.share.selectedTraineeSharingObject
  )
  const selectedTrainingId = useAppState(
    s => s.pages.singleTrainings.share.selectedTrainingId
  )

  const next = useNextAppState(s => s.pages.singleTrainings.share)

  const traineeSharingObjects = useAppState(
    s => s.pages.singleTrainings.traineeSharingObjects
  )

  const shareButtonDisabled = useAppState(
    s => s.pages.singleTrainings.share.selectedTraineeSharingObject.length === 0
  )

  function handleToggle(sharingObject: any) {
    if (trainingHasBeenSharedAlready(sharingObject)) {
      return
    }

    next(share => {
      if (
        share.selectedTraineeSharingObject[0] === sharingObject.sharingObjectId
      ) {
        share.selectedTraineeSharingObject = []
      } else {
        share.selectedTraineeSharingObject = [sharingObject.sharingObjectId]
      }
    })
  }

  function trainingHasBeenSharedAlready(sharingObject: any) {
    return sharingObject.trainings.some(
      (training: any) => training.trainingId === selectedTrainingId
    )
  }

  function sharingIsDisabled(traineeSharingObject: any) {
    return (
      traineeSharingObject.endDate !== null ||
      traineeSharingObject.sharingObject.meta.deletedAt !== null
    )
  }

  function trainingSharedDate(traineeSharingObject: any) {
    const training = traineeSharingObject.trainings.find(
      (training: any) => training.trainingId === selectedTrainingId
    )

    if (training) {
      return intl.formatDate(training.sharedAt)
    }
    return ''
  }

  const visibleTraineeSharingObjects = traineeSharingObjects
    .filter(
      (sharingObject: any) =>
        !sharingIsDisabled(sharingObject) ||
        trainingHasBeenSharedAlready(sharingObject)
    )
    .sort((a: any, b: any) => {
      if (trainingHasBeenSharedAlready(a)) {
        return 1
      }
      if (trainingHasBeenSharedAlready(b)) {
        return -1
      }
      return a.institute.name.localeCompare(b.institute.name)
    })

  return (
    <Dialog open={open}>
      <DialogTitle>{intl.formatMessage(messages.shareDialogTitle)}</DialogTitle>
      <DialogContent>
        <Typography>
          {intl.formatMessage(messages.shareDialogContent)}
        </Typography>
      </DialogContent>
      <List>
        {visibleTraineeSharingObjects.map(traineeSharingObject => (
          <ListItem
            key={traineeSharingObject._id}
            onClick={() => handleToggle(traineeSharingObject)}
          >
            <ListItemIcon>
              <Checkbox
                edge='start'
                checked={
                  selectedTraineeSharingObject.some(
                    value => value === traineeSharingObject.sharingObjectId
                  ) || trainingHasBeenSharedAlready(traineeSharingObject)
                }
                disabled={trainingHasBeenSharedAlready(traineeSharingObject)}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <Tooltip
              title={
                trainingHasBeenSharedAlready(traineeSharingObject)
                  ? intl.formatMessage(messages.sharedDialogTooltip) +
                    trainingSharedDate(traineeSharingObject)
                  : intl.formatMessage(messages.shareDialogTooltip)
              }
            >
              <ListItemText
                primary={traineeSharingObject.institute.name}
                secondary={traineeSharingObject.sharingObject.name}
              />
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={onCancel} color='secondary'>
          {intl.formatMessage(messages.shareDialogCancelButton)}
        </Button>
        <Button
          onClick={() => onShare()}
          color='primary'
          disabled={shareButtonDisabled}
        >
          {intl.formatMessage(messages.shareDialogShareButton)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type SingleTrainingsTableData = SingleTrainingView & GenericTableData

const SingleTrainings = () => {
  const intl = useIntl()
  const dispatch = useDispatchHook()
  const next = useNextAppState(s => s)
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false)
  const traineeSharingObjects = useAppState(
    s => s.pages.singleTrainings.traineeSharingObjects
  )

  const title = intl.formatMessage(messages.title)
  const singleTraining = intl.formatMessage(messages.singleTraining)
  const multipleTrainings = intl.formatMessage(messages.multipleTrainings)
  const tableSelected = intl.formatMessage(messages.tableSelected)

  const tableRowDate = intl.formatMessage(messages.tableRowDate)
  const tableRowOrigin = intl.formatMessage(messages.tableRowOrigin)
  const tableRowFlow = intl.formatMessage(messages.tableRowFlow)
  const tableRowDepth = intl.formatMessage(messages.tableRowDepth)
  const tableRowRecoil = intl.formatMessage(messages.tableRowRecoil)
  const tableRowFrequency = intl.formatMessage(messages.tableRowFrequency)
  const tableShare = intl.formatMessage(messages.shareDialogShareButton)
  const tableShareTrainingTooltip = intl.formatMessage(
    messages.tableShareTrainingTooltip
  )

  const cellHeaders = (): GenericTableCellHeader[] => {
    return [
      {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: tableRowDate,
      },
      {
        id: 'origin',
        numeric: false,
        disablePadding: false,
        label: tableRowOrigin,
      },
      {
        id: 'flow',
        numeric: false,
        disablePadding: false,
        label: tableRowFlow,
      },
      {
        id: 'depth',
        numeric: false,
        disablePadding: false,
        label: tableRowDepth,
      },
      {
        id: 'recoil',
        numeric: false,
        disablePadding: false,
        label: tableRowRecoil,
      },
      {
        id: 'frequency',
        numeric: false,
        disablePadding: false,
        label: tableRowFrequency,
      },
      {
        id: 'dummy',
        numeric: false,
        disablePadding: false,
        label: tableShare,
      },
    ]
  }

  const rows = useSingleTrainingsState(s =>
    s.singleTrainings.map(training => {
      return {
        id: training._id,
        selected: false,
        isSelectionEnabled: true,
        ...training,
      }
    })
  )

  const search = (searchTerm: string) => {
    const searchExp = RegExp(searchTerm, 'i')
    return (entry: SingleTrainingView) => {
      return (
        searchExp.test(entry.sessionType as string) ||
        searchExp.test(entry.origin as string) ||
        searchExp.test(entry.sessionDuration.toString() as string) ||
        searchExp.test(entry.sessionType as string) ||
        searchExp.test(entry.frequency.toString() as string)
      )
    }
  }

  function openSharedDialog(row: SingleTrainingsTableData) {
    next(s => {
      s.pages.singleTrainings.share.selectedTrainingId = row.id
      s.pages.singleTrainings.share.selectedTraineeSharingObject = []
    })
    setShareDialogOpen(true)
  }

  function handleShare() {
    setShareDialogOpen(false)
    dispatch({ type: 'Page/SingleTraining/Share' })
  }

  function isShared(row: SingleTrainingsTableData) {
    return traineeSharingObjects.some(sharingObject =>
      sharingObject.trainings.some(training => training.trainingId === row._id)
    )
  }

  function shareButtonColor(row: SingleTrainingsTableData) {
    return isShared(row) ? Colors.supportingCyan[0] : Colors.neutrals[3]
  }

  return (
    <AppPageLayout title={title}>
      <GenericTable
        rows={rows}
        headers={cellHeaders()}
        singleSelect={true}
        search={search as any}
        checkboxEnabled={false}
        checkboxVisible={false}
        selectedItemsMessage={({ count }) => (
          <FormattedMessage
            id='trainees_count'
            defaultMessage={`{count, number} {count, plural, one { ${singleTraining} } other { ${multipleTrainings} }} ${tableSelected}`}
            values={{ count }}
          />
        )}
        rowRender={({ row }: { row: SingleTrainingsTableData }) => (
          <>
            <TableCell>
              {
                <FormattedDate
                  value={row.date}
                  year='numeric'
                  month='2-digit'
                  day='2-digit'
                />
              }
            </TableCell>
            <TableCell>{row.origin}</TableCell>
            <TableCell>
              <CircularTrainingProgress
                size={50}
                fontSize={12}
                showLabel={false}
                thickness={4}
                styles={{ margin: theme.spacing(1) }}
                value={row.result.flowPercent}
                color={Colors.primary[3]}
              />
            </TableCell>
            <TableCell>
              <CircularTrainingProgress
                size={50}
                fontSize={12}
                showLabel={false}
                thickness={4}
                styles={{ margin: theme.spacing(1) }}
                value={row.result.cprCorrectDepthPercent}
                color={Colors.vivid[3]}
              />
            </TableCell>
            <TableCell>
              <CircularTrainingProgress
                size={50}
                fontSize={12}
                showLabel={false}
                thickness={4}
                styles={{ margin: theme.spacing(1) }}
                value={row.result.cprCorrectRecoilPercent}
                color={Colors.supportingLime[3]}
              />
            </TableCell>
            <TableCell>
              <CircularTrainingProgress
                size={50}
                fontSize={12}
                showLabel={false}
                thickness={4}
                styles={{ margin: theme.spacing(1) }}
                value={row.result.cprCorrectFrequencyPercent}
                color={Colors.supportingCyan[3]}
              />
            </TableCell>
            <TableCell>
              <Tooltip title={tableShareTrainingTooltip}>
                <Button
                  style={{ color: shareButtonColor(row) }}
                  onClick={() => {
                    openSharedDialog(row)
                  }}
                >
                  <ShareIcon />
                </Button>
              </Tooltip>
            </TableCell>
          </>
        )}
      ></GenericTable>
      <ShareDialog
        open={shareDialogOpen}
        onCancel={() => setShareDialogOpen(false)}
        onShare={handleShare}
      />
    </AppPageLayout>
  )
}

export default SingleTrainings
