import React from 'react'
import { Divider, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { UserMenuButtonProps } from '../NavBar/NavBar'
import { useNextAppTranslationState } from './TranslationMenu.restate'
import { useDispatchHook } from 'state/store'
import { FrontendLocale } from 'I18N/I18N.state'

const TranslationMenu: React.FC<UserMenuButtonProps> = props => {
  const next = useNextAppTranslationState()
  const dispatch = useDispatchHook()

  const changeLanguage = (language: FrontendLocale) => {
    next(settings => {
      settings.locale = language
    })
    props.handleClose()
    dispatch({ type: 'Page/Settings/Locale' })
  }

  return (
    <Menu
      open={props.open}
      onClose={() => props.handleClose()}
      anchorEl={props.anchorEl}
    >
      <MenuItem onClick={() => changeLanguage('de-DE')}>
        <ListItemText primary='Deutsch' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('en-US')}>
        <ListItemText primary='English' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('da-DA')}>
        <ListItemText primary='Dansk' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('fi-FI')}>
        <ListItemText primary='Suomi' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('it-IT')}>
        <ListItemText primary='Italiano' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('fr-FR')}>
        <ListItemText primary='Français' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('es-ES')}>
        <ListItemText primary='Español' />
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => changeLanguage('ar-SA')}>
        <ListItemText primary='العربية' />
      </MenuItem>
    </Menu>
  )
}

export default TranslationMenu
