import { routes } from 'model/ctrl/routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { Message } from '@restate/core'
import { UserDTO } from '../../model/User.dto'
import { LoginService } from '../../services/login/login.service'
import { defaultState } from '../../state/state'

import messages from './ChangeUserRole.messages'

export interface ChangeUserRoleMessage extends Message {
  type: 'Components/ChangeUserRole/Set'
  payload: {
    requestedRole: string
  }
}

export const connectChangeUserRoleComponentService = (store: AppStore) => {
  const { http, onAction, snackBar, intl } = tools(store)

  /**
   * Subscription that is called if the user logs into another role. The request-with-role header is changed and a new user state is loaded.
   * Also the preserved state is reset to the default and a log in event is emitted.
   */
  onAction({ type: 'Components/ChangeUserRole/Set' }).subscribe(pck => {
    const requestedRole = (pck.message as ChangeUserRoleMessage).payload

    http<UserDTO>({
      route: {
        path: routes.auth.changeRole,
      },
      method: 'POST',
      data: requestedRole,
    }).subscribe(
      response => {
        const { token, ...user } = response

        // Reducer doesnt accept the root state. Therefore we loop through each slice of the state and update it.
        // This also causes a short twitch in the UI but is essential to keep the state clean.
        store.next(rootState => {
          Object.keys(rootState).forEach(key => {
            //@ts-ignore
            rootState[key] = defaultState[key]
          })
        })

        store.next(s => {
          s.session.user = user as UserDTO
          s.session.token = token
        })

        const successSnackbar = intl().formatMessage(messages.successSnackbar, {
          role: requestedRole.requestedRole,
        })
        snackBar.success(successSnackbar)
        store.dispatch({ type: LoginService.LoginSuccess })
      },
      () => {
        const errorSnackbar = intl().formatMessage(messages.errorSnackbar)
        snackBar.error(errorSnackbar)
      }
    )
  })
}
