import { useNextAppState, AppStoreProvider } from 'state/store'

import { createStateHook } from '@restate/core'

export const useNextAppDrawerState = () =>
  useNextAppState(state => state.components.appDrawer)
export const useAppDrawerState = createStateHook(
  AppStoreProvider,
  state => state.components.appDrawer
)
