import { IconButton, TableCell, Tooltip, Typography } from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import {
  GenericTable,
  GenericTableCellHeader,
  GenericTableData,
} from 'components/GenericTable/GenericTable'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'

import tableMessages from './CourseTable.messages'
import { useDispatchHook, useAppState } from 'state/store'
import { USERROLES } from '../../model/UserRole'

export interface CourseTableData extends GenericTableData {
  date: Date
  trainer: string
  trainerEmail: string
  institute: string
  identifier: string
  location: string
  traineeCount: number
}

interface CourseTableProps {
  data: CourseTableData[]
  onCourseClick: (id: string) => any
  showInstituteCol: boolean
  showAssociatedTrainerCol: boolean
}

export const CourseTable: React.FC<CourseTableProps> = ({
  data,
  onCourseClick,
  showInstituteCol,
  showAssociatedTrainerCol = false,
}) => {
  const intl = useIntl()
  const tableRowProvider = intl.formatMessage(tableMessages.tableRowProvider)
  const tableRowAssociatedTrainer = intl.formatMessage(
    tableMessages.tableRowAssociatedTrainer
  )
  const tableRowIdentifier = intl.formatMessage(
    tableMessages.tableRowIdentifier
  )
  const tableRowDate = intl.formatMessage(tableMessages.tableRowDate)
  const tableRowLocation = intl.formatMessage(tableMessages.tableRowLocation)

  const tableRowTraineeCount = intl.formatMessage(
    tableMessages.tableRowTraineeCount
  )
  const deleteLabel = intl.formatMessage(tableMessages.deleteLabel)

  const dispatch = useDispatchHook()
  const userRole = useAppState(s => s.session.user.role)

  const search = (searchTerm: string) => {
    const splits = searchTerm.split(/\s+/).filter(s => s !== '')

    if (splits.length === 0) return () => true

    return (entry: CourseTableData & GenericTableData) =>
      splits.every(s => {
        const searchReg = RegExp(s, 'ig')
        // Matches columns for either I-Admin or Trainer/CP-Admin
        const conditionalSortMatch = showInstituteCol
          ? searchReg.test(entry.institute)
          : showAssociatedTrainerCol
          ? searchReg.test(entry.trainerEmail)
          : false

        return (
          searchReg.test(entry.identifier) ||
          conditionalSortMatch ||
          searchReg.test(entry.location) ||
          searchReg.test(entry.trainer) ||
          Number.parseInt(searchTerm) >= entry.traineeCount
        )
      })
  }

  const deleteCourses = (ids: string[]) => {
    dispatch({ type: 'Page/Courses/Delete', payload: ids[0] })
  }

  const CellHeaderInstitute: GenericTableCellHeader = {
    id: 'institute',
    numeric: false,
    disablePadding: false,
    label: tableRowProvider,
  }

  const CellHeaderAssociatedTrainer: GenericTableCellHeader = {
    id: 'associatedTrainer',
    numeric: false,
    disablePadding: false,
    label: tableRowAssociatedTrainer,
  }

  const CellHeaderIdentifier: GenericTableCellHeader = {
    id: 'identifier',
    numeric: false,
    disablePadding: false,
    label: tableRowIdentifier,
  }
  const CellHeaderDate: GenericTableCellHeader = {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: tableRowDate,
  }

  const CellHeaderLocation: GenericTableCellHeader = {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: tableRowLocation,
  }

  const CellHeaderTraineeCount: GenericTableCellHeader = {
    id: 'traineeCount',
    numeric: false,
    disablePadding: false,
    label: tableRowTraineeCount,
  }

  const CellHeaderActions: GenericTableCellHeader = {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: '',
  }

  let headers = [
    CellHeaderIdentifier,
    CellHeaderDate,
    CellHeaderLocation,
    CellHeaderTraineeCount,
    CellHeaderActions,
  ]

  if (showInstituteCol) {
    headers = [CellHeaderInstitute, ...headers]
  } else if (showAssociatedTrainerCol) {
    headers = [CellHeaderAssociatedTrainer, ...headers]
  }

  return (
    <GenericTable
      rows={data}
      headers={headers}
      search={search as any}
      initialSortProperty='date'
      initialSortDirection='asc'
      singleSelect={true}
      checkboxEnabled={userRole === USERROLES.CorPatchAdmin ? true : false}
      selectedItemsMessage={({ count }) => (
        <FormattedMessage
          id='courseTable'
          defaultMessage={`{count, number} {count, plural, one { course } other { courses }} selected`}
          values={{ count }}
        />
      )}
      rowRender={({ row }: { row: CourseTableData }) => (
        <>
          {showInstituteCol ? (
            <TableCell>
              <Typography variant='body1'>{row.trainer}</Typography>
              <Typography variant='caption'>{row.institute}</Typography>
            </TableCell>
          ) : showAssociatedTrainerCol ? (
            <TableCell>
              <Typography variant='body1'>{row.trainer}</Typography>
              <Typography variant='caption'>{row.trainerEmail}</Typography>
            </TableCell>
          ) : null}

          <TableCell>{row.identifier}</TableCell>
          <TableCell>
            {
              <FormattedDate
                value={row.date}
                year='numeric'
                month='2-digit'
                day='2-digit'
              />
            }
          </TableCell>
          <TableCell>{row.location}</TableCell>
          <TableCell>{row.traineeCount}</TableCell>
          <TableCell align='right'>
            <IconButton onClick={() => onCourseClick(row.id)}>
              <RightIcon />
            </IconButton>
          </TableCell>
        </>
      )}
      tools={({ selected, setSelected }) => (
        <Tooltip title={deleteLabel}>
          <IconButton
            aria-label={deleteLabel}
            onClick={() => {
              deleteCourses(selected)
              setSelected([])
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    ></GenericTable>
  )
}

export default CourseTable
