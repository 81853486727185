import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AppDrawer from 'components/AppDrawer/AppDrawer'
import Footer from 'components/Footer/Footer'
import { NavBar } from 'components/NavBar/NavBar'
import { Spinner } from 'components/Spinner/Spinner'
import React from 'react'
import { MainPageLayout } from './MainPageLayout'

const useStyles = makeStyles((theme: Theme) => ({
  scroller: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  safariScroller: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },

  container: {
    [theme.breakpoints.up('md')]: {
      // TODO: This should be done in a better CSS way such that the
      // drawer ist not overlaying the content
      marginLeft: theme.direction === 'ltr' ? theme.spacing(10) : 0,
      marginRight: theme.direction === 'rtl' ? theme.spacing(10) : 0,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },

  content: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 900,
    },
    flexGrow: 1,

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      maxWidth: 'auto',
      width: '100%',
    },
  },
}))

interface AppPageLayoutProps {
  title: string
  loading?: boolean
  showBackButton?: boolean
}

export const AppPageLayout: React.FunctionComponent<AppPageLayoutProps> = props => {
  const classes = useStyles()
  const { loading = false, title, showBackButton = false } = props

  // @ts-ignore
  const isSafari = window.safari !== undefined

  return (
    <MainPageLayout title={title}>
      <NavBar title={title} showBackButton={showBackButton} />
      <AppDrawer />
      <div className={isSafari ? classes.safariScroller : classes.scroller}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Spinner visible={loading || false} />
            {props.children}
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <Footer />
      </div>
    </MainPageLayout>
  )
}
