import { defineMessages } from 'react-intl'

export default defineMessages({
  validationText: {
    id: 'cp.pages.email.validation.text',
    defaultMessage:
      'Thank you for verifying you email. We will use it to keep your account secure and keep you up to date if you wish.',
  },
  loginButton: {
    id: 'cp.pages.email.validation.button',
    defaultMessage: 'Go to Login',
  },
})
