import React from 'react'
import { Card } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'

const useStyles = makeStyles({
  card: {
    width: '100%',
    margin: '5% 0',
  },
  cancelCardButton: {
    display: 'block',
    float: 'right',
    cursor: 'pointer',
  },
  cardTitle: {
    width: '100%',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardImage: {
    width: '25%',
  },
  cardContent: {
    width: '70%',
    textAlign: 'center',
  },
  cardButton: {
    margin: 'auto',
  },
})

interface ReminderTileProps {
  images: { src: string; }[]
  title: string
  message: string
  buttonText: string
  secondaryButtonText?: string
  clickHandlerPrimary: () => void
  clickHandlerSecondary?: () => void
}

export const ReminderTile = ({
  images,
  message,
  title,
  buttonText,
  secondaryButtonText,
  clickHandlerPrimary,
  clickHandlerSecondary,
}: ReminderTileProps) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.cardTitle} variant='h4'>
            {title}
          </Typography>

          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: '1rem',
              justifyContent: 'space-around',
            }}
          >
            {images.map(({ src }, index) => (
              <img
                key={index}
                src={src}
                className={classes.cardImage}
                alt=''
              />
            ))}
          </div>

          <Typography className={classes.cardContent} variant='body1'>
            {message}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <Button
          className={classes.cardButton}
          onClick={clickHandlerPrimary}
          size='medium'
        >
          <AndroidIcon style={{ margin: '0 5px' }} fontSize='small' />

          {buttonText}
        </Button>

        {secondaryButtonText && (
          <Button
            className={classes.cardButton}
            onClick={clickHandlerSecondary}
            size='medium'
          >
            <AppleIcon style={{ margin: '0 5px' }} fontSize='small' />

            {secondaryButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default ReminderTile
