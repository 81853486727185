export interface TrainingsPageState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  idx: number
}

export const defaultTrainingsPageState: TrainingsPageState = {
  meta: {
    loading: false,
    notFound: false,
  },
  idx: 0,
}
