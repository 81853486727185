export enum Locale {
  ar = 'ar',
  de = 'de',
  en = 'en',
  da = 'da',
  it = 'it',
  fi = 'fi',
  fr = 'fr',
  es = 'es',
}

export const getLocaleByCode = (code: string): Locale => {
  switch (code) {
    case 'ar':
      return Locale.ar
    case 'de':
      return Locale.de
    case 'en':
      return Locale.en
    case 'da':
      return Locale.da
    case 'it':
      return Locale.it
    case 'fi':
      return Locale.fi
    case 'fr':
      return Locale.fr
    case 'es':
      return Locale.es
    default:
      return Locale.en
  }
}
