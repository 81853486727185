export interface InstituteEntry {
  _id: string
  name: string
  street: string
  zip: string
  city: string
  country: string
}

export interface InstitutesState {
  meta: {
    loading: boolean
  }
  entries: InstituteEntry[]
}

export const defaultInstitutesState: InstitutesState = {
  meta: {
    loading: true,
  },
  entries: [],
}
