import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { theme } from 'theme/theme'
import { useLoginActions, useLoginState } from './Login.restate'
import { Colors } from 'theme/colors'

import messages from './Login.messages'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { AppRoutes } from 'routes'
import { useAppState } from 'state/store'
import { useRTL } from 'utils/useRTL'

const useClasses = makeStyles({
  formItem: {
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },

  submitButton: {
    marginTop: 20,
  },

  innerContainer: {
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  forgotPassword: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: theme.spacing() * 2,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  error: {
    ...theme.typography.h6,
    color: theme.palette.error.dark,
    padding: theme.spacing(),
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid ' + theme.palette.primary.dark,
  },
})

const EmailAndPasswordForm: React.FC = () => {
  const classes = useClasses()

  const intl = useIntl()
  const userOrPasswordNotFoundLabel = intl.formatMessage(
    messages.userOrPasswordNotFound
  )
  const userNewlyRegisteredLabel1 = intl.formatMessage(
    messages.userNewlyRegisteredLabel1
  )
  const userNewlyRegisteredLabel2 = intl.formatMessage(
    messages.userNewlyRegisteredLabel2
  )
  const termsOfUseButton = intl.formatMessage(messages.termsOfUseButton)

  const { error, email, password } = useLoginState()
  const { setPassword, setEmail, signIn } = useLoginActions()
  const registerSuccess = useAppState(state => state.pages.register.success)
  const embeddedMode = useAppState(state => state.routerExtension.embeddedMode)

  const goTo = useGoToHook()
  const showResetDialog = () => goTo(AppRoutes.LoginPasswordForgot)
  const isRTL = useRTL()

  return (
    <form onSubmit={signIn}>
      {error ? (
        <div className={classes.error}>{userOrPasswordNotFoundLabel}</div>
      ) : registerSuccess ? (
        <div
          className={classes.error}
          style={{ color: theme.palette.success.light }}
        >
          {userNewlyRegisteredLabel1}
          <br></br>
          {userNewlyRegisteredLabel2}
        </div>
      ) : null}
      <Grid
        direction='column'
        container={true}
        className={classes.innerContainer}
      >
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='email'>
            <FormattedMessage {...messages.email} />
          </InputLabel>
          <Input
            id='email'
            type='email'
            value={email}
            autoComplete='email'
            onChange={event => setEmail(event.target.value)}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <EmailIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <EmailIcon color='action' />
                </InputAdornment>
              )
            }
          />
        </FormControl>
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='adornment-password'>
            <FormattedMessage id={messages.password.id} />
          </InputLabel>
          <Input
            id='adornment-password'
            type='password'
            autoComplete='password'
            value={password}
            onChange={event => setPassword(event.target.value)}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <LockIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <LockIcon color='action' />
                </InputAdornment>
              )
            }
          />
        </FormControl>

        <div className={classes.forgotPassword}>
          <Button
            color='inherit'
            style={{ color: Colors.neutrals[6] }}
            size='small'
            onClick={showResetDialog}
          >
            <FormattedMessage id={messages.passwordForgot.id} />
          </Button>
        </div>

        <div
          style={{
            flexDirection: 'column',
            display: 'flex',
            marginBottom: theme.spacing() * 3,
          }}
        >
          <Button
            variant='contained'
            size='large'
            color='primary'
            className={classes.formItem + ' ' + classes.submitButton}
            type='submit'
          >
            <FormattedMessage {...messages.loginButton} />
          </Button>
          <Button
            variant='contained'
            size='large'
            color='secondary'
            className={classes.formItem + ' ' + classes.submitButton}
            onClick={() => goTo(AppRoutes.Register)}
          >
            <FormattedMessage {...messages.registerButton} />
          </Button>
        </div>
      </Grid>
      {!embeddedMode && (
        <Button
          color='primary'
          style={{ fontSize: '75%' }}
          onClick={() => goTo(AppRoutes.LoginTermsOfUse)}
        >
          {termsOfUseButton}
        </Button>
      )}
    </form>
  )
}

export const Busy = () => {
  return (
    <div
      style={{
        minHeight: 130,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </div>
  )
}

export const SignInForm: React.FC = () => {
  const { showProgress } = useLoginState()
  if (showProgress) {
    return <Busy />
  } else {
    return <EmailAndPasswordForm />
  }
}
