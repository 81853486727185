import RouteParser from 'route-parser'
import { Observable, animationFrameScheduler } from 'rxjs'
import { map } from 'rxjs/internal/operators/map'
import { distinctUntilChanged, filter, observeOn } from 'rxjs/operators'
import { AppStore } from 'state/store'

export const onPageEnter = (store: AppStore) => (route: string) => {
  const parser = new RouteParser(route)

  const ret = store.state$.pipe(
    observeOn(animationFrameScheduler),
    map(update => update.state.location.pathname),
    distinctUntilChanged(),
    map(pathname => parser.match(pathname)),
    filter(params => params !== false)
  )

  return (ret as unknown) as Observable<{ [x: string]: any }>
}
