import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { defaultState } from 'state/state'
import { clearStorage } from 'services/utils/clearStorage'

export enum LogoutService {
  Logout = 'Service/Logout/Logout',
  Done = 'Service/Logout/Done',
}

export type LogoutServiceMessages = { type: LogoutService }

export const connectLogout = (store: AppStore) => {
  const { onAction, goTo } = tools(store)

  onAction({ type: LogoutService.Logout }).subscribe(() => {
    const location = store.state.location
    store.next(() => ({ ...defaultState, location }), {
      type: LogoutService.Done,
    })
    clearStorage()
    goTo(AppRoutes.Logout)
  })
}
