import { Message } from '@restate/core'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { GlobalConfig } from 'model/GlobalConfig'
import { NotificationType } from 'model/Notification'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { PostGlobalConfigReq } from '../../model/ctrl/cpxCorpatchAdminConfig/PostGlobalConfigReq.schema'
import { defaultGlobalSettingsState } from './GlobalSettings.state'

export interface GlobalSettingsPageNotificationMessages extends Message {
  type: 'Page/GlobalSettings/Notifications/Test'
  payload?: { notificationType: NotificationType }
}

export interface GlobalSettingsPageCptConfigMessages extends Message {
  type: 'Page/GlobalSettings/Config/Save' | 'Page/GlobalSettings/Config/Load'
}

export const connectGlobalSettingsPage = (store: AppStore) => {
  const { http, onAction, snackBar, onPageLeave } = tools(store)
  const { api } = config()

  //
  // onPageEnter
  //
  function setLoading() {
    store.next(state => {
      state.pages.globalSettings = defaultGlobalSettingsState
    })
  }

  onPageLeave(AppRoutes.GlobalSettings).subscribe(setLoading)

  onAction({ type: 'Page/GlobalSettings/Config/Load' }).subscribe(() => {
    setLoading()

    http<GlobalConfig>({
      url: api + routes.corPatchAdmin.globalConfig,
    }).subscribe(
      res => {
        store.next(state => {
          state.pages.globalSettings.feedbackConfig = res.feedbackConfig
          state.pages.globalSettings.resultConfig.simple =
            res.resultConfig.simple
          state.pages.globalSettings.sessionConfig.audioFeedbackDebounceTime =
            res.sessionConfig.audioFeedbackDebounceTime.value
          state.pages.globalSettings.meta.loading = false
        })
      },

      _error => {
        console.error(_error)
        store.next(state => {
          state.pages.globalSettings = defaultGlobalSettingsState
        })
      }
    )
  })

  onAction({ type: 'Page/GlobalSettings/Config/Save' }).subscribe(() => {
    const globalSettings = store.state.pages.globalSettings

    const data: PostGlobalConfigReq = {
      feedbackConfig: globalSettings.feedbackConfig,
      resultConfig: globalSettings.resultConfig,
      sessionConfig: {
        audioFeedbackDebounceTime: {
          locked: true,
          value: globalSettings.sessionConfig.audioFeedbackDebounceTime,
        },
      },
    }

    http({
      url: api + routes.corPatchAdmin.globalConfig,
      method: 'POST',
      data: data,
    }).subscribe(
      () => snackBar.success('Your updated configuration was saved'),
      () => snackBar.error('Your configuration could not be saved')
    )
  })

  onAction({ type: 'Page/GlobalSettings/Notifications/Test' }).subscribe(
    pack => {
      const notificationType = (
        pack.message as GlobalSettingsPageNotificationMessages
      ).payload?.notificationType

      http({
        method: 'GET',
        route: {
          path: routes.corPatchAdmin.testNotifications,
          id: notificationType,
        },
      }).subscribe(
        () => {
          snackBar.success('The test notification has been sent successfully')
        },
        () => {
          snackBar.error('The test notification could not be sent')
        }
      )
    }
  )
}
