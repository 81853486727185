import { LogoutService } from 'services/logout/logout.service'
import { useDispatchHook } from 'state/store'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'

export const useLogout = () => {
  const dispatch = useDispatchHook()
  const go = useGoToHook()
  return function logout() {
    go(AppRoutes.Logout)
    dispatch({ type: LogoutService.Logout })
  }
}
