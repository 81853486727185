import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'cp.pages.singleTraining.title',
    defaultMessage: 'Your trainings',
  },
  tableName: {
    id: 'cp.pages.singleTraining.table.name',
    defaultMessage: 'Full Name',
  },
  tableCreated: {
    id: 'cp.pages.singleTraining.table.created',
    defaultMessage: 'Created',
  },
  singleTraining: {
    id: 'cp.pages.singleTraining.singleTraining',
    defaultMessage: 'training',
  },
  multipleTrainings: {
    id: 'cp.pages.singleTraining.multipleTrainings',
    defaultMessage: 'trainings',
  },
  tableSelected: {
    id: 'cp.pages.singleTraining.table.selected',
    defaultMessage: 'selected',
  },
  tableRowDate: {
    id: 'cp.components.singleTrainingsTable.tableRowDate',
    defaultMessage: 'Date',
  },
  tableRowOrigin: {
    id: 'cp.components.singleTrainingsTable.tableRowOrigin',
    defaultMessage: 'Origin',
  },
  tableRowFlow: {
    id: 'cp.components.singleTrainingsTable.tableRowFlow',
    defaultMessage: 'Flow',
  },
  tableRowDepth: {
    id: 'cp.components.singleTrainingsTable.tableRowDepth',
    defaultMessage: 'Depth',
  },
  tableRowRecoil: {
    id: 'cp.components.singleTrainingsTable.tableRowRecoil',
    defaultMessage: 'Recoil',
  },
  tableRowFrequency: {
    id: 'cp.components.singleTrainingsTable.tableRowFrequency',
    defaultMessage: 'Frequency',
  },
  tableShareTrainingTooltip: {
    id: 'cp.components.singleTrainingsTable.tableShareTrainingTooltip',
    defaultMessage: 'Share training',
  },
  shareDialogTitle: {
    id: 'cp.components.singleTrainingsTable.shareDialogTitle',
    defaultMessage: 'Share training',
  },
  shareDialogContent: {
    id: 'cp.components.singleTrainingsTable.shareDialogContent',
    defaultMessage:
      'Select the institute you want to share this training with:',
  },
  shareDialogTooltip: {
    id: 'cp.components.singleTrainingsTable.shareDialogTooltip',
    defaultMessage: 'Share training',
  },
  sharedDialogTooltip: {
    id: 'cp.components.singleTrainingsTable.sharedDialogTooltip',
    defaultMessage: 'Shared at: ',
  },
  shareDialogCancelButton: {
    id: 'cp.components.singleTrainingsTable.sharedDialogCancelButton',
    defaultMessage: 'Cancel',
  },
  shareDialogShareButton: {
    id: 'cp.components.singleTrainingsTable.sharedDialogShareButton',
    defaultMessage: 'Share',
  },
})
