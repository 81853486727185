import { createTheme } from '@material-ui/core/styles'
import { Colors } from './colors'

// Material UI color tool:
// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=a31c2f&secondary.color=232323
export const theme = createTheme({
  palette: {
    warning: {
      main: Colors.vivid[3],
    },
    error: {
      main: Colors.primary[0],
    },
    success: {
      main: Colors.supportingLime[3],
    },
    background: {
      paper: Colors.neutrals[9],
      default: Colors.background[0],
    },
    primary: {
      light: Colors.primary[7],
      main: Colors.primary[4],
      dark: Colors.primary[2],
    },
    secondary: {
      light: Colors.supportingCyan[4],
      main: Colors.supportingCyan[2],
      dark: Colors.supportingCyan[0],
    },
    text: {
      primary: Colors.neutrals[2],
      secondary: Colors.neutrals[3],
      disabled: Colors.neutrals[4],
      hint: Colors.neutrals[5],
    },
  },
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: Colors.background[0],
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: Colors.background[0],
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: Colors.background[0],
      },
    },
  },
})
