import React from 'react'
import { makeStyles, ListItem } from '@material-ui/core'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState } from 'state/store'

const useClasses = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: Colors.neutrals[8],
    '& p': {
      fontSize: theme.typography.fontSize - 3,
    },
    '& svg': {
      height: 25,
      width: 25,
      color: Colors.neutrals[8],
    },
  },
})

export const DrawerListItem: React.FC<{ route: string }> = props => {
  const classes = useClasses()
  const goTo = useGoToHook()
  const path = useAppState(s => s.location.pathname)

  return (
    <ListItem
      style={props.route === path ? { backgroundColor: Colors.primary[2] } : {}}
      button
      className={classes.listItem}
      onClick={() => goTo(props.route)}
    >
      <div className={classes.listItem}>{props.children}</div>
    </ListItem>
  )
}
