import { Message } from '@restate/core'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { pageStateMachine } from 'machines/pageState.machine'
import { Institute } from 'model/Institute'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { Locale } from '../../model/Locale'
import { InstituteDocument } from 'model/db/InstituteDocument.db-schema'

import messages from '../../pages/Institutes/Institutes.messages'
import { USERROLES } from '../../model/UserRole'

export enum InstituteServiceMessageType {
  Clear = 'Service/Institutes/Clear',
  Create = 'Service/Institutes/Create',
  Update = 'Service/Institutes/Update',
  Delete = 'Service/Institutes/Delete',
  Edit = 'Service/Institutes/Edit',
}

interface InstituteServiceClearMessage extends Message {
  type: InstituteServiceMessageType.Clear
}

interface InstituteServiceEditMessage extends Message {
  type: InstituteServiceMessageType.Edit
  payload: string
}

interface InstituteServiceCreateMessage extends Message {
  type: InstituteServiceMessageType.Create
}
interface InstituteServiceUpdateMessage extends Message {
  type: InstituteServiceMessageType.Update
}
interface InstituteServiceDeleteMessage extends Message {
  type: InstituteServiceMessageType.Delete
  payload: string
}

export type InstituteServiceMessages =
  | InstituteServiceEditMessage
  | InstituteServiceCreateMessage
  | InstituteServiceUpdateMessage
  | InstituteServiceDeleteMessage
  | InstituteServiceClearMessage

export const connectInstituteService = (store: AppStore) => {
  const {
    http,
    onAction,
    goTo,
    hasUserRole,
    onLogout,
    onPageEnter,
    onPageLeave,
    snackBar,
    intl,
  } = tools(store)
  const { api } = config()

  const machine = pageStateMachine({
    id: 'connectInstituteService',
    fetchData,
    setLoading,
  }).start()

  hasUserRole(USERROLES.CorPatchAdmin).subscribe(() => machine.send('LOGIN'))

  onLogout().subscribe(() => machine.send('LOGOUT'))

  onPageEnter(AppRoutes.Institutes).subscribe(() => machine.send('PAGE_ENTER'))

  onPageLeave(AppRoutes.Institutes).subscribe(() => machine.send('PAGE_LEAVE'))

  function fetchData() {
    http<Institute[]>({
      url: api + routes.corPatchAdmin.institutes,
    }).subscribe(institutes => {
      machine.send('RESOLVE')
      store.next(
        state => {
          state.services.institutes = institutes
        },
        { type: InstituteServiceMessageType.Update }
      )
    })
  }

  function setLoading() {
    store.next(
      s => {
        // s.services.institutes = []
        s.pages.institutes.meta.loading = true
      },
      { type: InstituteServiceMessageType.Clear }
    )
  }

  //
  // Create new institute
  //
  onAction({ type: InstituteServiceMessageType.Create }).subscribe(() => {
    const data: Institute = {
      name: 'New institute',

      address: {
        street: '',
        nr: '',
        co1: '',
        co2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },

      settings: {
        locale: Locale.en,
      },
    }

    http<Institute>({
      url: api + routes.corPatchAdmin.institutes,
      method: 'POST',
      data,
    }).subscribe(institute => {
      store.next(state => {
        state.services.institutes.push(institute)
      })
      goTo(AppRoutes.Institute, { id: (institute as any)._id })
    })
  })

  //
  // Edit  institute
  //
  onAction({ type: InstituteServiceMessageType.Edit }).subscribe(update => {
    const id = (update.message as InstituteServiceEditMessage).payload
    goTo(AppRoutes.Institute, { id })
  })

  //
  // Delete institute and associated trainers, admins and trainees
  //
  onAction({ type: InstituteServiceMessageType.Delete }).subscribe(update => {
    const id = (update.message as InstituteServiceEditMessage).payload

    http<InstituteDocument>({
      route: { path: routes.corPatchAdmin.institute, id },
      method: 'DELETE',
    }).subscribe(
      () => {
        const successSnackbar = intl().formatMessage(messages.successSnackbar)
        snackBar.success(successSnackbar)
      },
      () => {
        const errorSnackbar = intl().formatMessage(messages.errorSnackbar)
        snackBar.error(errorSnackbar)
      }
    )
  })
}
