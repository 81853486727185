import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { AppPageLayout } from '../layouts/AppPageLayout'
import { useAppState } from '../../state/store'
import Typography from '@material-ui/core/Typography'
import { useIntl } from 'react-intl'
import ReminderTile from './ReminderTile'
import { Images } from 'theme/Images'
import { config } from '../../config/config'

import messages from './Reminder.messages'

const {
  APPLE_APP_STORE_CPS_URL,
  GOOGLE_PLAY_STORE_CPS_URL
} = config()

const useStyles = makeStyles({
  title: { textAlign: 'center' },
  reminderContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
})

const Loading = () => {
  const intl = useIntl()
  const title = intl.formatMessage(messages.title)

  return <AppPageLayout title={title} loading={true}></AppPageLayout>
}

export const Reminder = () => {
  const intl = useIntl()
  const title = intl.formatMessage(messages.title)
  const welcome = intl.formatMessage(messages.welcome)

  const appTitle = intl.formatMessage(messages.appTitle)
  const appContent = intl.formatMessage(messages.appContent)
  const appPlayStoreButton = intl.formatMessage(messages.appPlayStoreButton)
  const appAppStoreButton = intl.formatMessage(messages.appAppStoreButton)

  const classes = useStyles()

  const onboardingState = useAppState(s => s.pages.onboarding)
  const user = useAppState(s => s.session.user)

  if (onboardingState.meta.loading || !onboardingState.onboarding) {
    return <Loading />
  }

  return (
    <AppPageLayout title={title}>
      <Typography className={classes.title} variant='h2' color='textPrimary'>
        {`${welcome} ${user.firstName} ${user.familyName}`}
      </Typography>
      <div className={classes.reminderContainer}>
        <ReminderTile
          images={[
            { src: Images.qrCodePlayStoreCPS },
            { src: Images.qrCodeAppStoreCPS },
          ]}
          title={appTitle}
          message={appContent}
          buttonText={appPlayStoreButton}
          secondaryButtonText={appAppStoreButton}
          clickHandlerPrimary={() => window.open(GOOGLE_PLAY_STORE_CPS_URL)}
          clickHandlerSecondary={() => window.open(APPLE_APP_STORE_CPS_URL)}
        />
      </div>
    </AppPageLayout>
  )
}

export default Reminder
