import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import React, { ChangeEvent, useState } from 'react'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { useIntl } from 'react-intl'

import messages from '../ChangePassword/ChangePassword.messages'
import { config } from 'config/config'

const PASSWORD_MIN_LEN = config().passwordMinLen

const useClasses = makeStyles({
  inputField1: {
    marginTop: theme.spacing(6),
    minHeight: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      width: '100%',
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
    minHeight: 70,
  },
})
interface InviteSetPasswordProps {
  onSend: (newPassword: string) => any
}

export const InviteSetPassword: React.FC<InviteSetPasswordProps> = props => {
  const intl = useIntl()
  const requiredError = intl.formatMessage(messages.passwordRequired)
  const matchError = intl.formatMessage(messages.passwordDontMatch)
  const newPassword = intl.formatMessage(messages.newPassword)
  const newPasswordRepeat = intl.formatMessage(messages.newPasswordRepeat)
  const setPasswordButton = intl.formatMessage(messages.setPasswordButton)
  const newToShortError = intl.formatMessage(messages.newToShortError)

  const { onSend } = props
  const classes = useClasses()

  const [newPassword0, setNewPassword0] = useState('')
  const [errorNewPassword0, setErrorNewPassword0] = useState(false)
  const [errorNewPasswordMessage0, setErrorNewPasswordMessage0] = useState('')

  const [newPassword1, setNewPassword1] = useState('')
  const [errorNewPassword1, setErrorNewPassword1] = useState(false)
  const [errorNewPasswordMessage1, setErrorNewPasswordMessage1] = useState('')

  const [valid, setValid] = useState(true)

  //
  // NewPassword0
  //
  function resetNewPassword0Validation() {
    setErrorNewPassword0(false)
    setErrorNewPasswordMessage0('')
    setValid(true)
  }

  function validateNewPassword0() {
    resetNewPassword0Validation()

    if (newPassword0 === '') {
      setErrorNewPassword0(true)
      setErrorNewPasswordMessage0(requiredError)
      setValid(false)
    }
  }

  //
  // NewPassword1
  //
  function resetNewPassword1Validation() {
    setErrorNewPassword1(false)
    setErrorNewPasswordMessage1('')
    setValid(true)
  }

  function validateNewPassword1() {
    resetNewPassword1Validation()

    if (newPassword0.length < PASSWORD_MIN_LEN) {
      setErrorNewPassword0(true)
      setErrorNewPasswordMessage0(newToShortError)
    }

    if (newPassword0 !== newPassword1) {
      setErrorNewPassword1(true)
      setErrorNewPasswordMessage1(matchError)
      setValid(false)
    }
  }

  function validate() {
    validateNewPassword0()
    validateNewPassword1()

    const errorNewPasswordMatch = newPassword0 !== newPassword1

    return !errorNewPasswordMatch && !errorNewPassword0
  }

  function handleSetNewPassword0(e: ChangeEvent<HTMLInputElement>) {
    resetNewPassword0Validation()
    setNewPassword0(e.target.value)
  }

  function handleSetNewPassword1(e: ChangeEvent<HTMLInputElement>) {
    resetNewPassword1Validation()
    setNewPassword1(e.target.value)
  }

  function sendChangePasswordRequest() {
    const valid = validate()
    if (valid) {
      onSend(newPassword0)
    }
  }

  return (
    <ImageCard imgUrl={Images.securityOn}>
      <ImageCardContent>
        <TextField
          value={newPassword0}
          label={newPassword}
          onChange={handleSetNewPassword0}
          className={classes.inputField1}
          onBlur={validateNewPassword0}
          error={errorNewPassword0}
          helperText={errorNewPasswordMessage0}
          type='password'
          placeholder={newPassword}
        />
        <TextField
          value={newPassword1}
          label={newPasswordRepeat}
          onChange={handleSetNewPassword1}
          onBlur={validateNewPassword1}
          className={classes.inputField}
          error={errorNewPassword1}
          helperText={errorNewPasswordMessage1}
          type='password'
          placeholder={newPasswordRepeat}
        />
      </ImageCardContent>
      <ImageCardActions>
        <Button
          color='secondary'
          variant='contained'
          onClick={sendChangePasswordRequest}
          disabled={!valid}
        >
          {setPasswordButton}
        </Button>
      </ImageCardActions>
    </ImageCard>
  )
}

export default InviteSetPassword
