import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { Images } from 'theme/Images'
import { Button } from '@material-ui/core'
import { useGoBack } from 'services/router/useGoBack.hook'
import { useIntl } from 'react-intl'

import messages from './NetworkError.messages'

export const NetworkError: React.FC<{}> = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)
  const pageNotFoundButton = intl.formatMessage(messages.pageNotFoundButton)

  const goBack = useGoBack()
  return (
    <PageNotFound
      variant='public'
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.questions}
      action={() => (
        <Button color='secondary' variant='contained' onClick={goBack}>
          {pageNotFoundButton}
        </Button>
      )}
    />
  )
}

export default NetworkError
