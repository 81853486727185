import { Message } from '@restate/core'
import axios from 'axios'
import { config } from 'config/config'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { routes } from 'model/ctrl/routes'
import { Locale } from 'model/Locale'
import { AppRoutes } from 'routes'

import messages from './Register.messages'

export enum RegisterService {
  Register = 'Service/Register/Request',
  RegisterSuccess = 'Service/Register/Success',
}

export interface RegisterMessage extends Message {
  type: RegisterService.Register
  payload: {
    email: string
    password: string
    firstName: string
    lastName: string
  }
}

export type RegisterServiceMessages =
  | RegisterMessage
  | { type: RegisterService.RegisterSuccess }

export const connectRegister = (store: AppStore) => {
  const { onAction, snackBar, goTo, intl } = tools(store)

  onAction({ type: RegisterService.Register }).subscribe(event => {
    const { api } = config()
    const {
      email,
      password,
      firstName,
      lastName,
    } = (event.message as RegisterMessage).payload
    const locale = store.state.pages.userDetails.userDetails?.settings.locale
      ? store.state.pages.userDetails.userDetails.settings.locale
      : Locale.en

    store.next(s => {
      s.pages.register.showProgress = true
      s.pages.register.error = ''
    })

    axios
      .post(api + routes.auth.traineeSignUp, {
        email,
        password,
        firstName,
        lastName,
        locale,
      })
      .then(function () {
        store.next(s => {
          s.pages.register.showProgress = false
          s.pages.register.success = true
        })
        goTo(AppRoutes.Login)
        const successSnackbar = intl().formatMessage(messages.successSnackbar)
        snackBar.success(successSnackbar)
        store.dispatch({ type: RegisterService.RegisterSuccess })
      })
      .catch(function (error) {
        store.next(s => {
          s.pages.register.showProgress = false
          s.pages.register.error = error.response.data.errorMessage
          s.pages.register.success = false
        })
        const errorSnackbar = intl().formatMessage(messages.errorSnackbar)
        snackBar.error(errorSnackbar)
        goTo(AppRoutes.Register)
      })
  })
}
