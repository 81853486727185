import { defineMessages } from 'react-intl'

export default defineMessages({
  tableRowName: {
    id: 'cp.pages.institutesTable.tableRowName',
    defaultMessage: 'Name',
  },
  tableRowStreet: {
    id: 'cp.pages.institutesTable.tableRowStreet',
    defaultMessage: 'Street',
  },
  tableRowCity: {
    id: 'cp.pages.institutesTable.tableRowCity',
    defaultMessage: 'City',
  },
  tableRowZIP: {
    id: 'cp.pages.institutesTable.tableRowZIP',
    defaultMessage: 'ZIP',
  },
  tableRowCountry: {
    id: 'cp.pages.institutesTable.tableRowCountry',
    defaultMessage: 'Country',
  },
  institutesLowercase: {
    id: 'cp.pages.institutesTable.institutesLowercase',
    defaultMessage: 'institutes',
  },
  instituteLowercase: {
    id: 'cp.pages.institutesTable.instituteLowercase',
    defaultMessage: 'institute',
  },
  deleteWarning: {
    id: 'cp.pages.institutesTable.deleteWarning',
    defaultMessage:
      'Do you want to delete the selected institute? All associated accounts and data will be lost!',
  },
})
