import { defineMessages } from 'react-intl'

export default defineMessages({
  adminsTableTitle: {
    id: 'cp.components.instituteAdminTable.adminsTableTitle',
    defaultMessage: 'Admins',
  },
  adminsTableSubtitle: {
    id: 'cp.components.instituteAdminTable.adminsTableSubtitle',
    defaultMessage: 'Users allowed to administrate the institute',
  },
  adminsTableInviteButton: {
    id: 'cp.components.instituteAdminTable.adminsTableInviteButton',
    defaultMessage: 'Invite Admin',
  },
  adminsTableEmail: {
    id: 'cp.components.instituteAdminTable.adminsTableEmail',
    defaultMessage: 'Email',
  },
  name: {
    id: 'cp.components.instituteAdminTable.name',
    defaultMessage: 'name',
  },
  selected: {
    id: 'cp.components.instituteAdminTable.selected',
    defaultMessage: 'selected',
  },
  deleteLabel: {
    id: 'cp.components.instituteAdminTable.deleteLabel',
    defaultMessage: 'delete',
  },
  deleteAdminMessage: {
    id: 'cp.components.instituteAdminTable.deleteAdminMessage',
    defaultMessage:
      'Do you really want to delete the selected admin? This will be permanent.',
  },
})
