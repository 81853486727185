import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { Colors } from 'theme/colors'
import { Typography } from '@material-ui/core'
import { theme } from 'theme/theme'
import { useIntl } from 'react-intl'

import messages from './Footer.messages'
import { useAppState } from '../../state/store'

const useClasses = makeStyles({
  footer: {
    // backgroundColor: lighten(Colors.neutrals[8], 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(12),
    color: Colors.neutrals[3],
    padding: theme.spacing(),

    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(8),
    },
    // borderBottom: '3px solid ' + Colors.primary[5],
  },
  link: {
    textDecoration: 'none',
    color: Colors.primary[5],
  },
})

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  const intl = useIntl()
  const rightsReserved = intl.formatMessage(messages.rightsReserved)

  const classes = useClasses()

  const embeddedMode = useAppState(state => state.routerExtension.embeddedMode)

  const currentYear = new Date().getFullYear()

  if (embeddedMode) return null

  return (
    <div className={classes.footer}>
      <Typography
        color='inherit'
        style={{
          fontSize: '0.8rem',
          color: Colors.neutrals[4],
          textAlign: 'center',
        }}
      >
        Copyright© {currentYear}{' '}
        <a className={classes.link} href='https://corpatch.com/'>
          SmartResQ Aps{' '}
        </a>
        {rightsReserved}
      </Typography>
    </div>
  )
}

export default Footer
