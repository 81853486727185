export interface LoginState {
  email: string
  password: string
  showProgress: boolean
  error: string | null
}

export const defaultLoginState: LoginState = {
  email: '',
  password: '',
  showProgress: false,
  error: null,
}
