import { makeStyles } from '@material-ui/styles'
import AppDrawer from 'components/AppDrawer/AppDrawer'
import Footer from 'components/Footer/Footer'
import { NavBar } from 'components/NavBar/NavBar'
import { Spinner } from 'components/Spinner/Spinner'
import React from 'react'
import { theme } from 'theme/theme'
import { MainPageLayout } from './MainPageLayout'
import { Tabs, Tab } from '@material-ui/core'

const useStyles = makeStyles({
  scroller: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },

  content: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 800,
    flexGrow: 1,

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      maxWidth: 'auto',
      width: '100%',
    },
  },
})

interface TabsPageLayoutProps {
  title: string
  loading?: boolean
  showBackButton?: boolean
  tabs: string[]
  tabIdx: number
  onTabChange: (idx: number) => any
}

export const TabsPageLayout: React.FunctionComponent<TabsPageLayoutProps> = props => {
  const classes = useStyles()
  const {
    loading = false,
    title,
    showBackButton = false,
    tabs,
    tabIdx,
    onTabChange,
  } = props

  const handleChange = (_event: React.ChangeEvent<{}>, newTabIndex: number) => {
    onTabChange(newTabIndex)
  }

  return (
    <MainPageLayout title={title}>
      <NavBar title={title} showBackButton={showBackButton} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={tabIdx}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
        >
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </div>

      <AppDrawer />

      <div className={classes.scroller}>
        <div className={classes.container}>
          <div className={classes.content}>
            <Spinner visible={loading || false} />
            {props.children}
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <Footer />
      </div>
    </MainPageLayout>
  )
}
