import { defineMessages } from 'react-intl'

export default defineMessages({
  userOrPasswordNotFound: {
    id: 'cp.pages.login.userOrPasswordNotFound',
    defaultMessage: 'User or password not found',
  },
  userNewlyRegisteredLabel1: {
    id: 'cp.pages.login.userNewlyRegisteredLabel1',
    defaultMessage: 'You have been registered successfully.',
  },
  userNewlyRegisteredLabel2: {
    id: 'cp.pages.login.userNewlyRegisteredLabel2',
    defaultMessage:
      'Dont forget to confirm your E-Mail address and login righ away.',
  },
  email: {
    id: 'cp.pages.login.email',
    defaultMessage: 'EMail',
  },
  password: {
    id: 'cp.pages.login.password',
    defaultMessage: 'Password',
  },
  passwordForgot: {
    id: 'cp.pages.login.passwordForgot',
    defaultMessage: 'Forgot password?',
  },
  loginButton: {
    id: 'cp.pages.login.loginButton',
    defaultMessage: 'Login',
  },
  registerButton: {
    id: 'cp.pages.login.registerButton',
    defaultMessage: 'Register',
  },
  loginInstitutesTitle: {
    id: 'cp.pages.login.loginInstitutesTitle',
    defaultMessage: 'Institutes',
  },
  termsOfUseButton: {
    id: 'cp.pages.login.termsOfUseButton',
    defaultMessage: 'terms of use',
  },
})
