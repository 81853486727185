import { defineMessages } from 'react-intl'

export default defineMessages({
  emptyMessage: {
    id: 'cp.components.genericTable.emptyMessage',
    defaultMessage: 'Empty',
  },
  previousPage: {
    id: 'cp.components.genericTable.previousPage',
    defaultMessage: 'previous page',
  },
  nextPage: {
    id: 'cp.components.genericTable.nextPage',
    defaultMessage: 'next page',
  },
  multiSelect: {
    id: 'cp.components.genericTable.multiSelect',
    defaultMessage: 'select all desserts',
  },
  searchPlaceholder: {
    id: 'cp.components.genericTable.searchPlaceholder',
    defaultMessage: 'Search',
  },
  rowsPerPageLabel: {
    id: 'cp.components.genericTable.rowsPerPageLabel',
    defaultMessage: 'Rows per page:',
  },
})
