import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'cp.pages.trainee.title',
    defaultMessage: 'Trainees',
  },
  tableName: {
    id: 'cp.pages.trainee.table.name',
    defaultMessage: 'Full Name',
  },
  tableEmail: {
    id: 'cp.pages.trainee.table.email',
    defaultMessage: 'E-Mail',
  },
  tableCreated: {
    id: 'cp.pages.trainee.table.created',
    defaultMessage: 'Created',
  },
  tableSelected: {
    id: 'cp.pages.trainee.table.selected',
    defaultMessage: 'selected',
  },
  mobileButton: {
    id: 'cp.pages.trainee.mobileButton',
    defaultMessage: 'Download Mobile Data',
  },
  cpsButton: {
    id: 'cp.pages.trainee.cpsButton',
    defaultMessage: 'Download CPS Data',
  },
  singleTrainee: {
    id: 'cp.pages.trainee.singleTrainee',
    defaultMessage: 'trainee',
  },
  multipleTrainees: {
    id: 'cp.pages.trainee.multipleTrainees',
    defaultMessage: 'trainees',
  },
  deleteLabel: {
    id: 'cp.pages.trainee.deleteLabel',
    defaultMessage: 'delete',
  },

  successSnackbar: {
    id: 'cp.pages.trainee.delete.successSnackbar',
    defaultMessage: 'Trainees deleted successfully',
  },
  errorSnackbar: {
    id: 'cp.pages.trainee.delete.errorSnackbar',
    defaultMessage: 'An unknown error occured',
  },
  warningSnackbar: {
    id: 'cp.pages.trainee.delete.warningSnackbar',
    defaultMessage: 'One or more users were not deleted',
  },
})
