import { GenericTableData } from 'components/GenericTable/GenericTable'
import { TrainerDTO } from 'model/ctrl/trainers/GetTrainersRes.schema'

export type TrainersTableData = TrainerDTO &
  GenericTableData & { displayName: string }

export interface TrainersState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  data: TrainersTableData[]
}

export const defaultTrainersState: TrainersState = {
  meta: {
    loading: true,
    notFound: false,
  },
  data: [],
}
