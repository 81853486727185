import React from 'react'
import { AppPageLayout } from '../layouts/AppPageLayout'
import { useIntl } from 'react-intl'
import TermsOfUse from 'components/TermsOfUse/TermsOfUse'

import messages from './Imprint.messages'

export const Imprint: React.FunctionComponent = () => {
  const intl = useIntl()

  const pageTitle = intl.formatMessage(messages.pageTitle)

  return (
    <AppPageLayout title={pageTitle} showBackButton={false}>
      <TermsOfUse cardStyle={{ width: '100%' }} />
    </AppPageLayout>
  )
}

export default Imprint
