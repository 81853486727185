import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.acceptInvitation.pageNotFound.title',
    defaultMessage: 'Invitation not found',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.acceptInvitation.pageNotFound.message',
    defaultMessage: 'This invitation is not valid anymore.',
  },
  inviteSuccessTitle: {
    id: 'cp.pages.acceptInvitation.title',
    defaultMessage: 'Done.',
  },
  inviteSuccessMessage: {
    id: 'cp.pages.acceptInvitation.message',
    defaultMessage: 'All set up and you are good to go!',
  },
  inviteLoginButton: {
    id: 'cp.pages.acceptInvitation.button',
    defaultMessage: 'To the login page',
  },
  inviteAcceptedWelcome: {
    id: 'cp.pages.acceptInvitation.welcome',
    defaultMessage: 'Welcome',
  },
})
