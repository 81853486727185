import { defineMessages } from 'react-intl'

export default defineMessages({
  loadingTitle: {
    id: 'cp.pages.userChangePassword.title',
    defaultMessage: 'Change password',
  },
  pageNotFoundTitle: {
    id: 'cp.pages.userChangePassword.notFound.title',
    defaultMessage: 'Nutzer nicht gefunden.',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.userChangePassword.notFound.message',
    defaultMessage: 'Dieser Nutzer wurde gelöscht. Sorry :(',
  },
  wrongPasswordSnackbar: {
    id: 'cp.pages.userChangePassword.wrongPasswordSnackbar',
    defaultMessage: 'Current password is wrong',
  },
  notChangedPasswordSnackbar: {
    id: 'cp.pages.userChangePassword.notChangedPasswordSnackbar',
    defaultMessage: 'Could not change password:',
  },
  successSnackbar: {
    id: 'cp.pages.userChangePassword.successSnackbar',
    defaultMessage: 'Password changed',
  },
})
