import { Address } from 'model/Address'
import { InstituteUserDto } from 'model/ctrl/institute/GetInstituteRes.schema'
import { UUID } from 'model/UUID'
import { Locale } from '../../model/Locale'
import { ResultConfig } from 'model/CptConfig'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'

export interface InstituteState {
  id: UUID
  meta: {
    loading: boolean
    notFound: boolean
    error: string | null
  }
  name: string
  address: Address
  settings: {
    locale: Locale
  }
  admins: InstituteUserDto[]
  trainers: InstituteUserDto[]
  sharingObjects: SharingObjectDocument[]
}

export const defaultInstituteState: InstituteState = {
  id: '',
  meta: {
    loading: true,
    notFound: false,
    error: null,
  },
  name: '',
  address: {
    street: '',
    nr: '',
    co1: '',
    co2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  settings: {
    locale: Locale.en,
  },
  admins: [],
  trainers: [],
  sharingObjects: [],
}

export const defaultInstituteResultConfigState: ResultConfig = {
  simple: {
    thresholdGood: 50,
    thresholdGreat: 70,
  },
}
