import {
  defaultRouterState,
  WithConnectReactRouterState,
} from '@restate/router'
import {
  AppDrawerState,
  defaultAppDrawerState,
} from 'components/AppDrawer/AppDrawer.state'
import {
  defaultUserMenuState,
  UserMenuState,
} from 'components/UserMenu/UserMenu.state'
import { defaultI18NState, I18NState } from 'I18N/I18N.state'
import { ResultConfig } from 'model/CptConfig'
import { Institute } from 'model/Institute'
import {
  AcceptInvitationPageState,
  defaultAcceptInvitationPageState,
} from 'pages/AcceptInvitation.tsx/AcceptInvitation.state'
import {
  AdminInviteState,
  defaultAdminInviteState,
} from 'pages/AdminInvite/AdminInvite.state'
import { AdminsState, defaultAdminsState } from 'pages/Admins/Admins.state'
import { CourseState, defaultCourseState } from 'pages/Course/Course.state'
import { CoursesState, defaultCoursesState } from 'pages/Courses/Courses.state'
import {
  defaultExportPageState,
  ExportPageState,
} from 'pages/Export/Export.state'
import {
  defaultGlobalSettingsState,
  GlobalSettingsState,
} from 'pages/GlobalSettings/GlobalSettings.state'
import {
  defaultInstituteResultConfigState,
  defaultInstituteState,
  InstituteState,
} from 'pages/Institute/Institute.state'
import {
  defaultInstitutesState,
  InstitutesState,
} from 'pages/Institutes/Institutes.state'
import { defaultLoginState, LoginState } from 'pages/Login/Login.state'
import {
  defaultLoginPasswordForgotState,
  LoginPasswordForgotState,
} from 'pages/LoginPasswordForgot/LoginPasswordForgot.state'
import {
  defaultLoginPasswordForgotSetPasswordState,
  LoginPasswordForgotSetPasswordState,
} from 'pages/LoginPasswordForgotSetPassword/LoginPasswordForgotSetPassword.state'
import { defaultMeState, MeState } from 'pages/Me/Me.state'
import {
  defaultRegisterState,
  RegisterState,
} from 'pages/Register/Register.state'
import {
  defaultSettingsState,
  SettingsState,
} from 'pages/Settings/Settings.state'
import {
  defaultSharingObjectsState,
  SharingObjectsState,
} from 'pages/SharingObject/SharingObject.state'
import {
  defaultSingleTrainingsState,
  SingleTrainingsState,
} from 'pages/SingleTrainings/SingleTrainings.state'
import {
  defaultTrainerInviteState,
  TrainerInviteState,
} from 'pages/TrainerInvite/TrainerInvite.state'
import {
  defaultTrainersState,
  TrainersState,
} from 'pages/Trainers/Trainers.state'
import {
  defaultTrainingsPageState,
  TrainingsPageState,
} from 'pages/Trainings/Trainings.state.'
import {
  defaultTrainingsTableState,
  TrainingsTablePageState,
} from 'pages/TrainingsTablePage/TrainingsTablePage.state'
import {
  defaultUserChangeEmailState,
  UserChangeEmailState,
} from 'pages/UserChangeEmail/UserChangeEmail.state'
import {
  defaultUserChangePasswordState,
  UserChangePasswordState,
} from 'pages/UserChangePassword/UserChangePassword.state'
import {
  defaultUserDetailsState,
  UserDetailsState,
} from 'pages/UserDetails/UserDetails.state'
import {
  defaultNetworkState,
  NetworkState,
} from 'services/network/network.state'
import {
  defaultSessionState,
  SessionState,
} from 'services/session/session.state'
import {
  defaultOnboardingState,
  OnboardingState,
} from '../components/Reminder/Reminder.state'
import {
  DashboardState,
  defaultDashboardState,
} from '../pages/Dashboard/Dashboard.state'
import {
  defaultTraineesState,
  TraineesState,
} from '../pages/Trainees/Trainees.state'
import {
  defaultRouterExtensionState,
  RouterExtensionState,
} from '../services/router/router.service'
import {
  defaultTraineeSharesState,
  TraineeSharesState,
} from 'pages/TraineeShares/TraineeShares.state'
import {
  defaultInstituteAdminSharesState,
  InstituteAdminSharesState,
} from 'pages/InstituteAdminShares/InstituteAdminShares.state'

export interface State extends WithConnectReactRouterState<any> {
  services: {
    institutes: Institute[]
  }
  pages: {
    acceptInvitation: AcceptInvitationPageState
    adminInvite: AdminInviteState
    admins: AdminsState
    course: CourseState
    courses: CoursesState
    dashboard: DashboardState
    export: ExportPageState
    globalSettings: GlobalSettingsState
    institute: InstituteState
    instituteAdminShares: InstituteAdminSharesState
    instituteResultConfig: ResultConfig
    institutes: InstitutesState
    login: LoginState
    loginPasswordForgot: LoginPasswordForgotState
    loginPasswordForgotSetPassword: LoginPasswordForgotSetPasswordState
    me: MeState
    onboarding: OnboardingState
    register: RegisterState
    settings: SettingsState
    sharingObjects: SharingObjectsState
    singleTrainings: SingleTrainingsState
    trainees: TraineesState
    traineeShares: TraineeSharesState
    trainerInvite: TrainerInviteState
    trainers: TrainersState
    trainings: TrainingsPageState
    trainingsTable: TrainingsTablePageState
    userChangeEmail: UserChangeEmailState
    userChangePassword: UserChangePasswordState
    userDetails: UserDetailsState
  }
  components: {
    appDrawer: AppDrawerState
    userMenu: UserMenuState
  }
  document: {
    title: string
  }
  network: NetworkState
  i18n: I18NState
  session: SessionState
  routerExtension: RouterExtensionState
}

export const defaultState: State = {
  services: {
    institutes: [],
  },
  pages: {
    acceptInvitation: defaultAcceptInvitationPageState,
    adminInvite: defaultAdminInviteState,
    admins: defaultAdminsState,
    course: defaultCourseState,
    courses: defaultCoursesState,
    dashboard: defaultDashboardState,
    export: defaultExportPageState,
    globalSettings: defaultGlobalSettingsState,
    institute: defaultInstituteState,
    instituteAdminShares: defaultInstituteAdminSharesState,
    instituteResultConfig: defaultInstituteResultConfigState,
    institutes: defaultInstitutesState,
    login: defaultLoginState,
    loginPasswordForgot: defaultLoginPasswordForgotState,
    loginPasswordForgotSetPassword: defaultLoginPasswordForgotSetPasswordState,
    me: defaultMeState,
    onboarding: defaultOnboardingState,
    register: defaultRegisterState,
    settings: defaultSettingsState,
    sharingObjects: defaultSharingObjectsState,
    singleTrainings: defaultSingleTrainingsState,
    trainees: defaultTraineesState,
    traineeShares: defaultTraineeSharesState,
    trainerInvite: defaultTrainerInviteState,
    trainers: defaultTrainersState,
    trainings: defaultTrainingsPageState,
    trainingsTable: defaultTrainingsTableState,
    userChangeEmail: defaultUserChangeEmailState,
    userChangePassword: defaultUserChangePasswordState,
    userDetails: defaultUserDetailsState,
  },
  network: defaultNetworkState,
  session: defaultSessionState,
  components: {
    appDrawer: defaultAppDrawerState,
    userMenu: defaultUserMenuState,
  },
  document: {
    title: '',
  },
  location: { ...defaultRouterState },
  i18n: defaultI18NState,
  routerExtension: defaultRouterExtensionState,
}
