import { Message } from '@restate/core'
import { config } from 'config/config'
import { InstituteDocumentWithUsers } from 'model/ctrl/institute/GetInstituteRes.schema'
import { routes } from 'model/ctrl/routes'
import { GlobalConfig } from 'model/GlobalConfig'
import { Institute } from 'model/Institute'
import RouteParser from 'route-parser'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { ResultConfig } from '../../model/CptConfig'
import { Locale } from '../../model/Locale'
import { USERROLES } from '../../model/UserRole'
import messages from './Institute.messages'
import {
  defaultInstituteResultConfigState,
  defaultInstituteState,
  InstituteState,
} from './Institute.state'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'

export let institutePageTabIndex = 0
export const setInstitutePageTabIndex = (newPageIndex: number) => {
  institutePageTabIndex = newPageIndex
}

function model2ViewModel(model: InstituteDocumentWithUsers): InstituteState {
  return {
    id: model._id,
    meta: {
      loading: false,
      notFound: false,
      error: null,
    },
    name: model.name,
    address: model.address,
    settings: model.settings ? model.settings : { locale: Locale.en },
    admins: model.admins,
    trainers: model.trainers,
    sharingObjects: model.sharingObjects,
  }
}

function viewModel2Model(viewModel: InstituteState): Institute {
  return {
    name: viewModel.name,
    address: viewModel.address,
    settings: viewModel.settings,
  }
}

export interface PagesInstituteServiceMessages extends Message {
  type:
    | 'Pages/Institute/CorPatchAdmin/Sharing/Create'
    | 'Pages/Institute/CorPatchAdmin/ResultConfig/Save'
    | 'Pages/Institute/Service/Error'
    | 'Pages/Institute/Service/Loading'
    | 'Pages/Institute/Service/Save'
    | 'Pages/Institute/Service/Update'
}

export const connectInstitutePage = (store: AppStore) => {
  const {
    onLoginPageEnter,
    http,
    snackBar,
    onAction,
    onPageLeave,
    goTo,
  } = tools(store)

  const { api } = config()

  function setLoading() {
    store.next(
      state => {
        state.pages.institute = defaultInstituteState
      },
      { type: 'Pages/Institute/Service/Loading' }
    )
  }

  //
  // entering page and user is a corpatch admin
  //
  onLoginPageEnter(AppRoutes.Institute, {
    roles: [USERROLES.CorPatchAdmin],
  }).subscribe(params => {
    const { id } = params.pageEnter

    //
    // Fetch
    //
    function fetchData(id: string) {
      const onSuccess = (institute: InstituteDocumentWithUsers) => {
        store.next(
          state => {
            state.pages.institute.id = id
            state.pages.institute = model2ViewModel(institute)
          },
          { type: 'Pages/Institute/Service/Update' }
        )
      }

      const onError = () =>
        store.next(
          state => {
            state.pages.institute = defaultInstituteState
            state.pages.institute.meta.notFound = true
            state.pages.institute.meta.loading = false
          },
          { type: 'Pages/Institute/Service/Error' }
        )

      http<InstituteDocumentWithUsers>({
        route: { path: routes.corPatchAdmin.institute, id },
      }).subscribe(onSuccess, onError)
    }

    function fetchResultConfig(id: string) {
      const onSuccess = (instituteResultConfig: ResultConfig) => {
        store.next(state => {
          state.pages.instituteResultConfig.simple =
            instituteResultConfig.simple
        })
      }

      const onError = () =>
        http<GlobalConfig>({
          route: { path: routes.corPatchAdmin.globalConfig },
        }).subscribe(
          (config: GlobalConfig) => {
            console.log('CONFIG: ', config)
            store.next(state => {
              state.pages.instituteResultConfig.simple =
                config.resultConfig.simple
            })
          },
          () => {
            store.next(
              state => {
                state.pages.instituteResultConfig = defaultInstituteResultConfigState
                state.pages.institute.meta.notFound = true
                state.pages.institute.meta.loading = false
              },
              { type: 'Pages/Institute/Service/Error' }
            )
          }
        )

      http<ResultConfig>({
        route: { path: routes.corPatchAdmin.resultConfig, id },
      }).subscribe(onSuccess, onError)
    }

    fetchResultConfig(id)
    fetchData(id)
  })

  //
  // on page leave
  //
  onPageLeave(AppRoutes.Institute).subscribe(setLoading)

  //
  // Save
  //
  onAction({ type: 'Pages/Institute/Service/Save' }).subscribe(() => {
    const { intl } = tools(store)

    const successSnackbar = intl().formatMessage(
      messages.instituteSuccessSnackbar
    )

    const errorSnackbar = intl().formatMessage(messages.instituteErrorSnackbar)

    const institute = viewModel2Model(store.state.pages.institute)
    const id = store.state.pages.institute.id
    const path = new RouteParser(routes.corPatchAdmin.institute).reverse({ id })
    http<InstituteDocumentWithUsers>({
      url: api + path,
      method: 'PUT',
      data: institute,
    }).subscribe(
      _success => snackBar.success(successSnackbar),
      _error => snackBar.error(errorSnackbar)
    )
  })

  onAction({
    type: 'Pages/Institute/CorPatchAdmin/ResultConfig/Save',
  }).subscribe(() => {
    const { intl } = tools(store)

    const successSnackbar = intl().formatMessage(
      messages.instituteSuccessSnackbar
    )

    const errorSnackbar = intl().formatMessage(messages.instituteErrorSnackbar)

    const id = store.state.pages.institute.id
    const instituteResultConfig = store.state.pages.instituteResultConfig
    const path = new RouteParser(routes.corPatchAdmin.resultConfig).reverse({
      id,
    })
    http<any>({
      url: api + path,
      method: 'POST',
      data: instituteResultConfig,
    }).subscribe(
      _success => snackBar.success(successSnackbar),
      _error => snackBar.error(errorSnackbar)
    )
  })

  //
  // Create a sharing config
  //
  onAction({ type: 'Pages/Institute/CorPatchAdmin/Sharing/Create' }).subscribe(
    () => {
      http<any>({
        url: api + routes.sharing.sharingObjects,
        method: 'PUT',
        data: {
          instituteId: store.state.pages.institute.id,
        },
      }).subscribe(
        (sharingObject: SharingObjectDocument) => {
          store.next(state => {
            state.pages.institute.sharingObjects.push(sharingObject)
          })
          goTo(AppRoutes.SharingObject, { id: sharingObject._id })
          snackBar.success('Sharing object created')
        },
        _error => snackBar.error('Error creating sharing object')
      )
    }
  )
}
