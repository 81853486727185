import ChangePassword from 'components/ChangePassword/ChangePassword'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { useDispatchHook } from 'state/store'
import { Images } from 'theme/Images'
import { useUserChangePasswordState } from './UserChangePassword.restate'
import { useIntl } from 'react-intl'

import messages from './UserChangePassword.messages'

const UserChangePasswordLoading = () => {
  const intl = useIntl()
  const loadingTitle = intl.formatMessage(messages.loadingTitle)

  return <AppPageLayout title={loadingTitle} loading={true}></AppPageLayout>
}

const UserChangePasswordNotFound = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)

  return (
    <PageNotFound
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.empty}
    />
  )
}

export const UserChangePassword: React.FC<{}> = () => {
  const intl = useIntl()
  const dispatch = useDispatchHook()
  const loadingTitle = intl.formatMessage(messages.loadingTitle)
  const { loading, notFound } = useUserChangePasswordState(s => s.meta)

  function onSend({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string
    newPassword: string
  }) {
    dispatch({
      type: 'Page/ChangePassword/Send',
      payload: {
        oldPassword,
        newPassword,
      },
    })
  }

  if (loading) return <UserChangePasswordLoading />
  if (notFound) return <UserChangePasswordNotFound />

  return (
    <AppPageLayout title={loadingTitle}>
      <ChangePassword onSend={onSend} />
    </AppPageLayout>
  )
}

export default UserChangePassword
