import { SharingTraineeAggregate } from 'model/SharingTraineeAggregate'

export interface TraineeSharesState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  sharingTrainee: SharingTraineeAggregate[]
  addDialogOpen: boolean
  addDialogCode: string
  value: number
}

export const defaultTraineeSharesState: TraineeSharesState = {
  meta: {
    loading: false,
    notFound: false,
  },
  sharingTrainee: [],
  addDialogOpen: false,
  addDialogCode: '',
  value: 0,
}
