import { CardContent } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import { MainPageLayout } from 'components/layouts/MainPageLayout'
import React from 'react'
import { center } from 'styles/styles'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import { Images } from '../../theme/Images'
import { SignInForm } from './LoginForm'
import { useIntl } from 'react-intl'

import messages from './Login.messages'

const useStyles = makeStyles({
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    backgroundColor: Colors.background[0],
  },

  card: {
    minWidth: 400,
    minHeight: 516,
    borderTop: `4px solid ${Colors.primary[3]}`,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
      width: '100%',
      height: '100%',
      borderRadius: 0,
    },
    borderRadius: 8,
  },

  titleBg: {
    position: 'relative',
    maxWidth: '250px',
    top: '50px',
    borderRadius: 8,
  },

  logo: {
    ...center,
    marginBottom: theme.spacing() * 3,
    marginTop: theme.spacing() * 3,
  },

  title: {
    fontSize: '1.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoImg: {},
})

export const Login = () => {
  const intl = useIntl()
  const loginInstitutesTitle = intl.formatMessage(messages.loginInstitutesTitle)

  const { center, card, logo, logoImg } = useStyles()

  return (
    <MainPageLayout title={loginInstitutesTitle} showTranslationSwitch>
      <div className={center}>
        <Card className={card} elevation={3}>
          <CardContent>
            <div className={logo}>
              <img
                className={logoImg}
                height='150'
                src={Images.logoSmallUrl}
                alt='logo'
              />
            </div>
            <SignInForm />
          </CardContent>
        </Card>
      </div>
    </MainPageLayout>
  )
}

export default Login
