import React from 'react'
import { IntlProvider } from 'react-intl'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { AppStore, useAppState } from 'state/store'
import { FrontendLocale } from './I18N.state'

const translations: any = {
  de: require(`./translations/de`),
  en: require(`./translations/en`),
  da: require(`./translations/da`),
  fi: require(`./translations/fi`),
  it: require(`./translations/it`),
  fr: require(`./translations/fr`),
  es: require(`./translations/es`),
  ar: require(`./translations/ar`),
}

export const AppIntlProvider: React.FC<{}> = props => {
  const locale = useAppState(state => state.i18n.locale)
  const language = locale.split(/[-_]/)[0] // language without region code
  const messages: any = translations[language] || translations['de']
  return (
    <IntlProvider locale={locale} messages={messages}>
      {props.children}
    </IntlProvider>
  )
}

const LangReg = /lang=(\w*)&?/

export function connectTranslationSwitch(store: AppStore) {
  store.state$
    .pipe(
      map(update => update.state.location.search),
      distinctUntilChanged()
    )
    .subscribe(search => {
      const matches = LangReg.exec(search)
      if (matches) {
        const language = matches[1] as FrontendLocale
        if (language) {
          store.next(s => {
            s.i18n.locale = language
          })
        }
      }
    })
}
