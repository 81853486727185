import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { useNextAppState } from '../../state/store'
import { routes } from '../../model/ctrl/routes'
import { config } from '../../config/config'
import axios from 'axios'
import { useIntl } from 'react-intl'

import messages from './Trainees.messages'

const useClasses = makeStyles({
  root: {
    '& > *': {
      margin: '0 75px',
    },
  },
})

const DownloadTraineeData: React.FC = () => {
  const intl = useIntl()
  const mobileButton = intl.formatMessage(messages.mobileButton)
  const cpsButton = intl.formatMessage(messages.cpsButton)

  const classes = useClasses()
  const next = useNextAppState(s => s)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date().toLocaleDateString([], options)

  const downloadMobileAncCpsData = (dataType: string) => {
    let path
    if (dataType === 'mobileCSV') {
      path = routes.export.allMobiles
    } else if (dataType === 'cpsCSV') {
      path = routes.export.allCPS
    }

    const fileName = `${date}${dataType}.zip`
    const url = config().api + path

    next(state => {
      state.pages.trainees.meta.loading = true
    })

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      next(state => {
        state.pages.trainees.meta.loading = false
      })
    })
  }

  return (
    <div className={classes.root}>
      <Button
        variant='contained'
        color='primary'
        onClick={() => downloadMobileAncCpsData('mobileCSV')}
      >
        {mobileButton}
      </Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => downloadMobileAncCpsData('cpsCSV')}
      >
        {cpsButton}
      </Button>
    </div>
  )
}

export default DownloadTraineeData
