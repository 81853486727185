export interface RegisterState {
  firstName: string
  lastName: string
  email: string
  password: string
  termsOfUseAccepted: boolean
  showProgress: boolean
  error: string | null
  success: boolean
}

export const defaultRegisterState: RegisterState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  termsOfUseAccepted: false,
  showProgress: false,
  error: null,
  success: false,
}
