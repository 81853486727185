import { defineMessages } from 'react-intl'

export default defineMessages({
  trainerInviteTitle: {
    id: 'cp.pages.trainerInvite.title',
    defaultMessage: 'Invite trainer',
  },
  trainerSuccessSnackbar: {
    id: 'cp.pages.trainerInvite.successSnackbar',
    defaultMessage: 'Email send to {email}',
  },
  trainerErrorSnackbar: {
    id: 'cp.pages.trainerInvite.errorSnackbar',
    defaultMessage: 'Email is in use',
  },
})
