import { AppStore } from 'state/store'

export const snackBar = (store: AppStore) => ({
  success(msg: string) {
    store.dispatch({ type: 'AppSnackBar/Success', message: msg })
  },
  error(msg: string) {
    store.dispatch({ type: 'AppSnackBar/Error', message: msg })
  },
})
