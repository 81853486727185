import { AppStore } from '../../state/store'
import { tools } from '../../services/utils/tools'
import { config } from '../../config/config'
import RouteParser from 'route-parser'
import { routes } from '../../model/ctrl/routes'
import { AppRoutes } from '../../routes'
import { defaultDashboardState } from './Dashboard.state'
import { Message } from '@restate/core'
import { USERROLES } from '../../model/UserRole'

export interface PagesDashboardServiceMessages extends Message {
  type:
    | 'Pages/Dashboard/Service/Loading'
    | 'Pages/Dashboard/Service/Update'
    | 'Pages/Dashboard/Service/Error'
    | 'Pages/Dashboard/Service/Save'
}

export const connectDashboardPage = (store: AppStore) => {
  const { onLoginPageEnter, http } = tools(store)
  const { api } = config()

  onLoginPageEnter(AppRoutes.Home, {
    roles: [USERROLES.CorPatchAdmin],
  }).subscribe(() => {
    fetchData()
  })

  function setLoading() {
    store.next(state => {
      state.pages.dashboard = defaultDashboardState
      state.pages.dashboard.meta.notFound = false
      state.pages.dashboard.meta.loading = true
    })
  }

  function fetchData() {
    setLoading()
    const path = new RouteParser(routes.dashboard).reverse({})

    http<any>({ url: api + path }).subscribe(
      dashboard => {
        store.next(
          state => {
            state.pages.dashboard.data = dashboard
            state.pages.dashboard.meta.loading = false
          },
          { type: 'Pages/Dashboard/Service/Update' }
        )
      },

      _error => {
        console.error(_error)
        store.next(
          state => {
            state.pages.dashboard = defaultDashboardState
            state.pages.dashboard.meta.notFound = true
            state.pages.dashboard.meta.loading = false
          },
          { type: 'Pages/Dashboard/Service/Error' }
        )
      }
    )
  }
}
