import { UUID } from 'model/UUID'
import { SingleTrainingView } from './SingleTrainings.page-service'
import { SharingTraineeAggregate } from 'model/SharingTraineeAggregate'

export type SingleTrainingsState = {
  singleTrainings: SingleTrainingView[]
  traineeSharingObjects: SharingTraineeAggregate[]
  share: {
    selectedTrainingId: UUID
    selectedTraineeSharingObject: UUID[]
  }
}

export const defaultSingleTrainingsState: SingleTrainingsState = {
  singleTrainings: [],
  traineeSharingObjects: [],
  share: {
    selectedTrainingId: '',
    selectedTraineeSharingObject: [],
  },
}
