import React, { useState } from 'react'
import { theme } from 'theme/theme'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  FormControl,
  InputAdornment,
  Input,
  Button,
  Checkbox,
  Typography,
} from '@material-ui/core'
import { useRegisterActions, useRegisterState } from './Register.restate'
import InputLabel from '@material-ui/core/InputLabel'
import EmailIcon from '@material-ui/icons/Email'
import PersonIcon from '@material-ui/icons/Person'
import LockIcon from '@material-ui/icons/Lock'
import { AppRoutes } from 'routes'
import { Busy } from '../Login/LoginForm'
import { useIntl } from 'react-intl'

import messages from './Register.messages'
import { config } from 'config/config'
import { useAppState } from '../../state/store'
import { useRTL } from 'utils/useRTL'

const useClasses = makeStyles({
  formItem: {
    margin: '10px 10px 0 10px',
  },

  submitButton: {
    marginTop: 10,
  },

  innerContainer: {
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  forgotPassword: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: theme.spacing() * 2,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  error: {
    ...theme.typography.h6,
    color: theme.palette.error.dark,
    padding: theme.spacing(),
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid ' + theme.palette.primary.dark,
  },
})

export const RegisterForm = () => {
  const classes = useClasses()

  const intl = useIntl()
  const firstnameField = intl.formatMessage(messages.firstnameField)
  const lastnameField = intl.formatMessage(messages.lastnameField)
  const emailField = intl.formatMessage(messages.emailField)
  const passwordField = intl.formatMessage(messages.passwordField)
  const acceptTermsOfUse = intl.formatMessage(messages.acceptTermsOfUse)
  const create = intl.formatMessage(messages.create)
  const newToShortError = intl.formatMessage(messages.newToShortError)
  const isRTL = useRTL()

  const {
    error,
    email,
    password,
    firstName,
    lastName,
    termsOfUseAccepted,
    showProgress,
  } = useRegisterState()
  const {
    setPassword,
    setEmail,
    register,
    setfirstName,
    setlastName,
    setTermsOfUseAccepted,
  } = useRegisterActions()
  const [errorPassword, setErrorPassword] = useState(false)
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('')
  const embeddedMode = useAppState(state => state.routerExtension.embeddedMode)

  const isValid =
    password !== '' && email !== '' && termsOfUseAccepted && !errorPassword

  function validatePasswordInput() {
    setErrorPassword(false)
    setErrorPasswordMessage('')

    if (password.length < config().passwordMinLen) {
      setErrorPassword(true)
      setErrorPasswordMessage(newToShortError)
    }
  }

  if (showProgress) return <Busy />

  return (
    <form onSubmit={register}>
      {error ? <div className={classes.error}>{error}</div> : null}
      <Grid
        direction='column'
        container={true}
        className={classes.innerContainer}
      >
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='firstName'>{firstnameField}</InputLabel>
          <Input
            id='firstName'
            type='text'
            value={firstName}
            onChange={event => setfirstName(event.target.value)}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <PersonIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <PersonIcon color='action' />
                </InputAdornment>
              )
            }
          />
        </FormControl>
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='lastName'>{lastnameField}</InputLabel>
          <Input
            id='lastName'
            type='text'
            value={lastName}
            onChange={event => setlastName(event.target.value)}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <PersonIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <PersonIcon color='action' />
                </InputAdornment>
              )
            }
          />
        </FormControl>
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='email'>{emailField}*</InputLabel>
          <Input
            id='email'
            type='email'
            value={email}
            required={true}
            autoComplete='email'
            onChange={event => setEmail(event.target.value)}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <EmailIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <EmailIcon color='action' />
                </InputAdornment>
              )
            }
          />
        </FormControl>
        <FormControl className={classes.formItem}>
          <InputLabel htmlFor='adornment-password'>{passwordField}*</InputLabel>
          <Input
            id='adornment-password'
            type='password'
            autoComplete='password'
            value={password}
            required={true}
            error={errorPassword}
            onChange={event => {
              setPassword(event.target.value)
              if (errorPassword) {
                validatePasswordInput()
              }
            }}
            onBlur={validatePasswordInput}
            endAdornment={
              !isRTL && (
                <InputAdornment position='end'>
                  <LockIcon color='action' />
                </InputAdornment>
              )
            }
            startAdornment={
              isRTL && (
                <InputAdornment position='end'>
                  <LockIcon color='action' />
                </InputAdornment>
              )
            }
          />
          <Typography style={{ fontSize: '75%', color: 'red' }}>
            {errorPasswordMessage}
          </Typography>
        </FormControl>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: theme.spacing() * 3,
          }}
        >
          {!embeddedMode && (
            <FormControl
              className={classes.formItem}
              style={{ marginTop: '30px' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <a
                  style={{ textDecoration: 'none' }}
                  href={AppRoutes.LoginTermsOfUse}
                >
                  {acceptTermsOfUse}*
                </a>
                <Checkbox
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    padding: '0',
                  }}
                  checked={termsOfUseAccepted}
                  onChange={() => setTermsOfUseAccepted(!termsOfUseAccepted)}
                />
              </div>
            </FormControl>
          )}

          <Button
            variant='contained'
            size='large'
            color='primary'
            type='submit'
            className={classes.formItem + ' ' + classes.submitButton}
            disabled={!isValid}
          >
            {create}
          </Button>
        </div>
      </Grid>
    </form>
  )
}
