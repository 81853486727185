import { Drawer, Hidden, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import TrainingsIcon from '@material-ui/icons/Assessment'
import InstitutesIcon from '@material-ui/icons/Business'
import GetAppIcon from '@material-ui/icons/GetApp'
import TrainersIcon from '@material-ui/icons/Group'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import SettingsIcon from '@material-ui/icons/Settings'
import ShareIcon from '@material-ui/icons/Share'
import ShopIcon from '@material-ui/icons/ShoppingCart'
import ViewListIcon from '@material-ui/icons/ViewList'
import { makeStyles, withStyles } from '@material-ui/styles'
import { UserRole } from 'model/UserRole'
import React from 'react'
import { useIntl } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState } from 'state/store'
import { Colors } from 'theme/colors'
import { DRAWER_WIDTH, LOGO_HEIGHT } from 'theme/constants'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import messages from './AppDrawer.messages'
import { useAppDrawerState, useNextAppDrawerState } from './AppDrawer.restate'
import { DrawerListItem } from './DrawerListItem'

const useStyles = makeStyles({
  drawer: {},
  drawerContent: {
    width: DRAWER_WIDTH,
    height: '100%',
  },
  logo: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    marginTop: 3,
    paddingBottom: theme.spacing(3),
    '& img': {
      height: LOGO_HEIGHT,
    },
  },
  deco: {
    width: '100%',
    height: '3px',
  },
})

const ShopItem = () => {
  const intl = useIntl()
  const drawerShop = intl.formatMessage(messages.drawerShop)

  return (
    <DrawerListItem route={AppRoutes.Shop}>
      <ShopIcon />
      <Typography>{drawerShop}</Typography>
    </DrawerListItem>
  )
}

const TrainersItem = () => {
  const intl = useIntl()
  const drawerTrainers = intl.formatMessage(messages.drawerTrainers)

  return (
    <DrawerListItem route={AppRoutes.Trainers}>
      <TrainersIcon />
      <Typography>{drawerTrainers}</Typography>
    </DrawerListItem>
  )
}

const CoursesItem = () => {
  const intl = useIntl()
  const drawerCourses = intl.formatMessage(messages.drawerCourses)

  return (
    <DrawerListItem route={AppRoutes.Courses}>
      <TrainingsIcon />
      <Typography>{drawerCourses}</Typography>
    </DrawerListItem>
  )
}

const SingleTrainingsItem = () => {
  const intl = useIntl()
  const drawerSingleTrainings = intl.formatMessage(
    messages.drawerSingleTrainings
  )

  return (
    <DrawerListItem route={AppRoutes.SingleTrainings}>
      <ViewListIcon />
      <Typography>{drawerSingleTrainings}</Typography>
    </DrawerListItem>
  )
}

const ExportItem = () => {
  const intl = useIntl()
  const drawerExport = intl.formatMessage(messages.drawerExport)

  return (
    <DrawerListItem route={AppRoutes.Export}>
      <GetAppIcon />
      <Typography>{drawerExport}</Typography>
    </DrawerListItem>
  )
}

const SettingsItem = () => {
  const intl = useIntl()
  const drawerSettings = intl.formatMessage(messages.drawerSettings)

  return (
    <DrawerListItem route={AppRoutes.Settings}>
      <SettingsIcon />
      <Typography>{drawerSettings}</Typography>
    </DrawerListItem>
  )
}

const GlobalSettingsItem = () => {
  const intl = useIntl()
  const drawerSettings = intl.formatMessage(messages.drawerSettings)

  return (
    <DrawerListItem route={AppRoutes.GlobalSettings}>
      <SettingsIcon />
      <Typography>{drawerSettings}</Typography>
    </DrawerListItem>
  )
}

const InstitutesItem = () => {
  const intl = useIntl()
  const drawerInstitutes = intl.formatMessage(messages.drawerInstitutes)

  return (
    <DrawerListItem route={AppRoutes.Institutes}>
      <InstitutesIcon />
      <Typography>{drawerInstitutes}</Typography>
    </DrawerListItem>
  )
}

const TraineesItem = () => {
  const intl = useIntl()
  const drawerTrainees = intl.formatMessage(messages.drawerTrainees)

  return (
    <DrawerListItem route={AppRoutes.Trainees}>
      <TrainersIcon />
      <Typography>{drawerTrainees}</Typography>
    </DrawerListItem>
  )
}

const ImprintItem = () => {
  const intl = useIntl()
  const drawerImprint = intl.formatMessage(messages.drawerImprint)

  return (
    <DrawerListItem route={AppRoutes.Imprint}>
      <LibraryBooksIcon />
      <Typography>{drawerImprint}</Typography>
    </DrawerListItem>
  )
}

const SharesItem = (props: { route: string }) => {
  const intl = useIntl()
  const drawerShares = intl.formatMessage(messages.drawerShares)

  return (
    <DrawerListItem route={props.route}>
      <ShareIcon />
      <Typography>{drawerShares}</Typography>
    </DrawerListItem>
  )
}

const InstituteAdminEntries = () => (
  <List>
    <ShopItem />
    <CoursesItem />
    <TrainersItem />
    <SharesItem route={AppRoutes.InstituteAdminShares} />
    <SettingsItem />
    <ImprintItem />
  </List>
)

const CorPatchAdminEntries = () => (
  <List>
    <ShopItem />
    <InstitutesItem />
    <TraineesItem />
    <CoursesItem />
    <ExportItem />
    <GlobalSettingsItem />
    <ImprintItem />
  </List>
)

const TrainerEntries = () => (
  <List>
    <CoursesItem />
    <ShopItem />
    <ImprintItem />
  </List>
)

const TraineeEntries = () => (
  <List>
    <ShopItem />
    <SharesItem route={AppRoutes.TraineeShares} />
    <SingleTrainingsItem />
    <ImprintItem />
  </List>
)

const USER_ROLES_to_Entries: { [k in UserRole]: React.ComponentType } = {
  CorPatchAdmin: CorPatchAdminEntries,
  InstituteAdmin: InstituteAdminEntries,
  Trainer: TrainerEntries,
  Trainee: TraineeEntries,
  Researcher: () => null,
  Unknown: () => null,
}

interface AppDrawerProps {
  open: boolean
  closeDrawer: () => void
  variant: UserRole
}

const AppDrawerContent: React.FC<{ variant: UserRole }> = ({ variant }) => {
  const classes = useStyles()
  const Entries = USER_ROLES_to_Entries[variant]
  const goTo = useGoToHook()

  return (
    <div className={classes.drawerContent}>
      <div className={classes.logo}>
        <img
          src={Images.logoSmallUrl}
          alt='logo'
          style={{ cursor: 'pointer' }}
          onClick={() => goTo(AppRoutes.Home)}
        />
      </div>
      <Entries />
    </div>
  )
}

const CustomDrawer = withStyles({
  paper: {
    border: 0,
    boxShadow: theme.shadows[5],
    backgroundColor: Colors.primary[3],
  },
})(Drawer)

const MobileDrawer = withStyles({
  paper: {
    backgroundColor: Colors.primary[3],
  },
})(Drawer)

export const AppDrawer: React.FunctionComponent<AppDrawerProps> = ({
  open,
  closeDrawer,
  variant,
}) => {
  return (
    <>
      <Hidden mdUp implementation='css'>
        <MobileDrawer anchor={'left'} open={open} onClose={closeDrawer}>
          <AppDrawerContent variant={variant} />
        </MobileDrawer>
      </Hidden>

      <Hidden smDown implementation='css'>
        <CustomDrawer anchor={'left'} open variant='permanent' elevation={5}>
          <AppDrawerContent variant={variant} />
        </CustomDrawer>
      </Hidden>
    </>
  )
}

export default function () {
  const open = useAppDrawerState(state => state.open)
  const variant = useAppState(state => state.session.user.role)
  const next = useNextAppDrawerState()

  const closeDrawer = () =>
    next(drawer => {
      drawer.open = false
    })

  return <AppDrawer open={open} closeDrawer={closeDrawer} variant={variant} />
}
