import { defineMessages } from 'react-intl'

export default defineMessages({
  validationTitle: {
    id: 'cp.pages.login.validate.validationTitle',
    defaultMessage: 'Please verify your login',
  },
  validationContent: {
    id: 'cp.pages.login.validate.validationContent',
    defaultMessage: 'We have sent you an E-Mail to verify your login.',
  },
  validationButton: {
    id: 'cp.pages.login.validate.validationButton',
    defaultMessage: 'Go back to login',
  },

  successTitle: {
    id: 'cp.pages.login.validate.successTitle',
    defaultMessage: 'We are logging you in.',
  },
  successContent: {
    id: 'cp.pages.login.validate.successContent',
    defaultMessage: 'Please wait a moment while we validate your login.',
  },

  errorTitle: {
    id: 'cp.pages.login.validate.errorTitle',
    defaultMessage: 'We could not recognize your browser session',
  },
  errorContent: {
    id: 'cp.pages.login.validate.errorContent',
    defaultMessage:
      'Maybe you have tried to login from another browser or device or you have closed your browser in the meantime. Try to request another E-Mail to login.',
  },

  errorSnackbar: {
    id: 'cp.pages.login.validate.errorSnackbar',
    defaultMessage:
      'There was an error with loging you in. Your link might be outdated.',
  },
})
