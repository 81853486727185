import {
  Card,
  CardContent,
  CardHeader,
  Fab,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'
import React from 'react'
import { useIntl } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useDispatchHook } from 'state/store'
import { useInstituteState } from '../Institute.restate'
import messages from '../../InstituteAdminShares/InstituteAdminShares.messages'

const useClasses = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
  },
  startDate: {
    fontSize: '0.8rem',
    color: theme.palette.grey[500],
  },
}))

export function InstituteSharingObjects() {
  const sharingObjects = useInstituteState(s => s.sharingObjects)
  const classes = useClasses()
  const intl = useIntl()

  const activeSharingObjects = sharingObjects.filter(
    sharingObject => sharingObject.meta.deletedAt === null
  )

  const deactivatedSharingObjects = sharingObjects.filter(
    sharingObject => sharingObject.meta.deletedAt !== null
  )

  const hasActiveSharingObjects = activeSharingObjects.length > 0
  const hasDeactivatedSharingObjects = deactivatedSharingObjects.length > 0

  return (
    <>
      <Card>
        <CardHeader title={'Active'} />
        <CardContent>
          <Table size={'small'} className={classes.table}>
            <TableBody>
              {!hasActiveSharingObjects && (
                <Typography>{intl.formatMessage(messages.noActiveSharingObjectsMessage)}</Typography>
              )}
              {hasActiveSharingObjects &&
                activeSharingObjects.map(sharingObject => (
                  <SharingObjectRow sharingObject={sharingObject} />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <CardHeader title={'Deleted'} />
        <CardContent>
          <Table size={'small'} className={classes.table}>
            <TableBody>
              {!hasDeactivatedSharingObjects && (
                <Typography>{intl.formatMessage(messages.noDeletedSharingObjectsMessage)}</Typography>
              )}
              {hasDeactivatedSharingObjects &&
                deactivatedSharingObjects.map(sharingObject => (
                  <SharingObjectRow sharingObject={sharingObject} disabled />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <AddButton />
    </>
  )
}

function SharingObjectRow(props: {
  sharingObject: SharingObjectDocument
  disabled?: boolean
}) {
  const { sharingObject, disabled = false } = props
  const classes = useClasses()
  const intl = useIntl()

  const goTo = useGoToHook()
  const navigateToSharingObject = () =>
    goTo(AppRoutes.SharingObject, { id: sharingObject._id })

  return (
    <TableRow>
      <TableCell style={{ height: 60 }}>
        <div>{sharingObject.name}</div>
        <div className={classes.startDate}>
          Start: {intl.formatDate(sharingObject.meta.createdAt)}
        </div>
        {sharingObject.meta.deletedAt && (
          <div className={classes.startDate}>
            End: {intl.formatDate(sharingObject.meta.deletedAt)}
          </div>
        )}
      </TableCell>
      <TableCell align='right'>
        {!disabled && (
          <IconButton onClick={navigateToSharingObject}>
            <RightIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

function AddButton() {
  const { fab } = useClasses()
  const dispatch = useDispatchHook()

  function onClick() {
    dispatch({ type: 'Pages/Institute/CorPatchAdmin/Sharing/Create' })
  }

  return (
    <Fab
      className={fab}
      color='secondary'
      aria-label='Add institute'
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  )
}
