export interface TrainerInviteState {
  email: string
  send: boolean
  meta: {
    loading: boolean
    notFound: boolean
  }
}

export const defaultTrainerInviteState: TrainerInviteState = {
  email: '',
  send: false,
  meta: {
    loading: false,
    notFound: false,
  },
}
