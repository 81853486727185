import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'

export interface InstituteAdminSharesState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  sharingObjects: SharingObjectDocument[]
}

export const defaultInstituteAdminSharesState: InstituteAdminSharesState = {
  meta: {
    loading: false,
    notFound: false,
  },
  sharingObjects: [],
}
