import { defineMessages } from 'react-intl'

export default defineMessages({
  inviteCardTitle: {
    id: 'cp.pages.adminInvite.inviteCardTitle',
    defaultMessage: 'Invite Admin',
  },
  successSnackbar: {
    id: 'cp.pages.adminInvite.successSnackbar',
    defaultMessage: 'Email send to {email}',
  },
  errorSnackbar: {
    id: 'cp.pages.adminInvite.errorSnackbar',
    defaultMessage: 'E-Mail {email} is in use',
  },
})
