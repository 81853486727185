import { defineMessages } from 'react-intl'

export default defineMessages({
  roleInstituteAdmin: {
    id: 'cp.pages.courses.roleInstituteAdmin',
    defaultMessage: 'Institute Admin',
  },
  roleTrainee: {
    id: 'cp.pages.courses.roleTrainee',
    defaultMessage: 'Trainee',
  },
  pageTitle: {
    id: 'cp.pages.courses.pageTitle',
    defaultMessage: 'Courses',
  },
  pageNotFoundTitle: {
    id: 'cp.pages.courses.pageNotFoundTitle',
    defaultMessage: 'Nicht gefunden.',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.courses.pageNotFoundMessage',
    defaultMessage: 'Wurde gelöscht. Sorry :(',
  },
  successSnackbar: {
    id: 'cp.pages.courses.successSnackbar',
    defaultMessage: 'Course has been deleted successfully',
  },
  errorSnackbar: {
    id: 'cp.pages.courses.errorSnackbar',
    defaultMessage: 'Sorry, course could not be deleted',
  },
})
