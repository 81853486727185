import { AppStore } from 'state/store'
import { tools } from 'services/utils/tools'
import { Message } from '@restate/core'
import { routes } from 'model/ctrl/routes'
import { config } from 'config/config'
import { SignUpReq } from 'model/ctrl/signup/SignUpReq.schema'
import { AppRoutes } from 'routes'
import { Locale } from 'model/Locale'
import { EMail } from 'model/Email'
import messages from './AdminInvite.messages'
import { USERROLES } from '../../model/UserRole'

export interface AdminInviteMessage extends Message {
  type: 'Page/Service/AdminInvite/Invite'
  payload: {
    email: EMail
    firstName: string
    lastName: string
  }
}

export const connectAdminInvitePageService = (store: AppStore) => {
  const { onAction, http, pageParams, snackBar, intl } = tools(store)
  const { api } = config()
  const getInstituteId = pageParams(AppRoutes.AdminInvite)

  onAction({ type: 'Page/Service/AdminInvite/Invite' }).subscribe(pack => {
    const payload = (pack.message as AdminInviteMessage).payload

    const { id } = getInstituteId()

    if (!id) return

    const setSend = (send: boolean) =>
      store.next(s => {
        s.pages.adminInvite.send = send
      })

    store.next(s => {
      s.pages.adminInvite.meta.loading = true
    })
    const data: SignUpReq = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      instituteId: id,
      locale: Locale.de,
      roles: [USERROLES.InstituteAdmin, USERROLES.Trainer, USERROLES.Trainee],
    }

    http({
      url: api + routes.auth.signup,
      method: 'POST',
      data,
    }).subscribe(
      // Success
      () => {
        store.next(s => {
          s.pages.adminInvite.meta.loading = false
        })
        setSend(true)
        const successSnackbar = intl().formatMessage(messages.successSnackbar, {
          email: payload.email,
        })
        snackBar.success(successSnackbar)
      },
      // Error
      _err => {
        const errorSnackbar = intl().formatMessage(messages.errorSnackbar, {
          email: payload.email,
        })
        // const status = err.response.status
        snackBar.error(errorSnackbar)
        store.next(s => {
          s.pages.adminInvite.error = ''
          s.pages.adminInvite.meta.loading = false
        })
      }
    )
  })
}
