import { UserDetailsDTO } from 'model/ctrl/user/GetUserRes.schema'

export interface UserDetailsState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  userDetails: UserDetailsDTO | null
}

export const defaultUserDetailsState: UserDetailsState = {
  meta: {
    loading: true,
    notFound: false,
  },
  userDetails: null,
}
