import React, { useState } from 'react'
import { AppPageLayout } from '../../components/layouts/AppPageLayout'
import {
  GenericTable,
  GenericTableCellHeader,
} from '../../components/GenericTable/GenericTable'
import { useTraineesState } from './Trainees.restate'
import { TableCell, Tooltip, IconButton } from '@material-ui/core'
import { FormattedMessage, FormattedDate } from 'react-intl'
import DownloadTraineeData from './DownloadTraineeData'
import { useIntl } from 'react-intl'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { useDispatchHook } from 'state/store'
import { NotificationType } from 'model/Notification'
import { NotificationSelectCard } from 'pages/GlobalSettings/GlobalSettings'

import messages from './Trainees.messages'
import { TraineeData } from './Trainees.state'
import { SelectedUserIds } from 'pages/Institute/SelectedUserIds'
import { ConfirmDeletionDialog } from 'components/dialogs/ConfirmDeletionDialog'

export const Trainees = () => {
  const intl = useIntl()
  const title = intl.formatMessage(messages.title)
  const tableName = intl.formatMessage(messages.tableName)
  const tableEmail = intl.formatMessage(messages.tableEmail)
  const tableCreated = intl.formatMessage(messages.tableCreated)
  const tableSelected = intl.formatMessage(messages.tableSelected)
  const singleTrainee = intl.formatMessage(messages.singleTrainee)
  const multipleTrainees = intl.formatMessage(messages.multipleTrainees)
  const deleteLabel = intl.formatMessage(messages.deleteLabel)

  const dispatch = useDispatchHook()

  const [selectedIdsToDelete, setSelectedIdsToDelete] = useState<
    SelectedUserIds
  >(null)
  const [selectedIdsToNotify, setSelectedIdsToNotify] = useState<
    SelectedUserIds
  >(null)

  const cellHeaders = (
    name: string,
    email: string,
    created: string
  ): GenericTableCellHeader[] => {
    return [
      { id: 'displayName', numeric: false, disablePadding: false, label: name },
      { id: 'email', numeric: false, disablePadding: false, label: email },
      {
        id: 'createdAt',
        numeric: false,
        disablePadding: false,
        label: created,
      },
    ]
  }

  const trainees = useTraineesState(s => s.data)

  const search = (searchTerm: string) => {
    const searchExp = RegExp(searchTerm, 'i')
    return (entry: TraineeData) => {
      return (
        searchExp.test(entry.displayName as string) ||
        searchExp.test(entry.email)
      )
    }
  }

  const getTraineeRoles = (id: string) => {
    return trainees.find(t => t._id === id)?.roles ?? []
  }

  const deleteTrainees = (ids: string[]) => {
    dispatch({ type: 'Pages/Trainees/Delete', payload: { ids } })
  }

  return (
    <AppPageLayout title={title}>
      <GenericTable
        rows={trainees}
        headers={cellHeaders(tableName, tableEmail, tableCreated)}
        singleSelect={true}
        search={search as any}
        selectedItemsMessage={({ count }) => (
          <FormattedMessage
            id='trainees_count'
            defaultMessage={`{count, number} {count, plural, one { ${singleTrainee} } other { ${multipleTrainees} }} ${tableSelected}`}
            values={{ count }}
          />
        )}
        rowRender={({ row }: { row: TraineeData }) => (
          <>
            <TableCell>{row.displayName}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>
              {
                <FormattedDate
                  value={row.createdAt}
                  year='numeric'
                  month='2-digit'
                  day='2-digit'
                />
              }
            </TableCell>
          </>
        )}
        tools={({ selected, setSelected }) => (
          <div style={{ width: '6em' }}>
            <Tooltip title={deleteLabel}>
              <IconButton
                style={{ display: 'inline-block' }}
                aria-label={deleteLabel}
                onClick={() => {
                  setSelectedIdsToDelete({
                    selectedIds: selected,
                    clearSelection: () => setSelected([]),
                  })
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={'notify user'}>
              <IconButton
                style={{ display: 'inline-block' }}
                aria-label={'notify user'}
                onClick={() => {
                  setSelectedIdsToNotify({
                    selectedIds: selected,
                    clearSelection: () => setSelected([]),
                  })
                }}
              >
                <NotificationsActiveIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      ></GenericTable>

      <ConfirmDeletionDialog
        open={
          !!selectedIdsToDelete && selectedIdsToDelete?.selectedIds.length === 1
        }
        userRoles={getTraineeRoles(selectedIdsToDelete?.selectedIds[0] ?? '')}
        onPositiveButtonClick={() => {
          deleteTrainees(selectedIdsToDelete?.selectedIds ?? [])
          selectedIdsToDelete?.clearSelection()
          setSelectedIdsToDelete(null)
        }}
        onNegativeButtonClick={() => {
          selectedIdsToDelete?.clearSelection()
          setSelectedIdsToDelete(null)
        }}
      />

      {selectedIdsToNotify && selectedIdsToNotify.selectedIds.length > 0 && (
        <NotificationSelectCard
          handleSubmit={(notificationType: NotificationType) => {
            dispatch({
              type: 'Pages/Trainees/Notify',
              payload: {
                ids: selectedIdsToNotify.selectedIds ?? [],
                notificationType,
              },
            })
            selectedIdsToNotify?.clearSelection()
            setSelectedIdsToNotify(null)
          }}
          showClose={true}
          handleClose={() => {
            selectedIdsToNotify?.clearSelection()
            setSelectedIdsToNotify(null)
          }}
        />
      )}
      <DownloadTraineeData />
    </AppPageLayout>
  )
}

export default Trainees
