import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Modal,
  Card,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { Gender } from 'model/Gender'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatchHook, useNextAppState } from 'state/store'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { Colors } from '../../theme/colors'
import messages from './Me.messages'
import { useMeState } from './Me.restate'

const useClasses = makeStyles({
  input: {
    marginTop: theme.spacing(2),
  },

  deleteButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    maxWidth: 700,
    backgroundColor: Colors.vivid[3],
  },

  modal: {
    position: 'absolute',
    height: '250',
    width: '400px',
    top: '40%',
    left: '40%',
    padding: '20px',
  },

  warning: {
    textAlign: 'center',
  },
})

export const Me: React.FC<{}> = () => {
  const next = useNextAppState(s => s.pages.me)
  const { firstName, familyName, gender } = useMeState(s => s)
  const loading = useMeState(s => s.meta.loading)
  const ready = useMeState(s => s.meta.ready)

  const classes = useClasses()
  const dispatch = useDispatchHook()

  const intl = useIntl()
  const title = intl.formatMessage(messages.title)
  const genderLabel = intl.formatMessage(messages.gender)
  const genderFemaleLabel = intl.formatMessage(messages.genderFemale)
  const genderMaleLabel = intl.formatMessage(messages.genderMale)
  const genderOtherLabel = intl.formatMessage(messages.genderOther)
  const saveLabel = intl.formatMessage(messages.save)
  const firstNameLabel = intl.formatMessage(messages.firstName)
  const familyNameLabel = intl.formatMessage(messages.familyName)
  const deleteOwnButtonRequest = intl.formatMessage(
    messages.deleteOwnButtonRequest
  )
  const deleteOwnContent = intl.formatMessage(messages.deleteOwnContent)
  const deleteOwnButtonConfirm = intl.formatMessage(
    messages.deleteOwnButtonConfirm
  )

  const [modalIsOpen, setModalisOpen] = useState(false)

  const setFirstName = (e: React.ChangeEvent<{ value: string }>) =>
    next(s => {
      s.firstName = e.target.value
    })

  const setFamilyName = (e: React.ChangeEvent<{ value: string }>) =>
    next(s => {
      s.familyName = e.target.value
    })

  const setGender = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.gender = e.target.value as Gender
    })

  const imgUrl = () => {
    if (gender === 'female') return Images.femaleAvatar
    if (gender === 'male') return Images.maleAvatar
    return Images.otherAvatar
  }

  const save = () => dispatch({ type: 'Page/Me/Save' })

  const deleteUserAccount = () => {
    setModalisOpen(false)
    dispatch({ type: 'Page/Me/Delete' })
  }

  if (!ready)
    return <AppPageLayout title={title} loading={loading}></AppPageLayout>

  return (
    <AppPageLayout title={title} loading={loading}>
      <ImageCard imgUrl={imgUrl()}>
        <ImageCardContent>
          <Grid direction='column' container={true}>
            <TextField
              label={firstNameLabel}
              value={firstName}
              onChange={setFirstName}
              className={classes.input}
            />
            <TextField
              label={familyNameLabel}
              value={familyName}
              onChange={setFamilyName}
              className={classes.input}
            />
            <InputLabel id='gender-label' className={classes.input}>
              {genderLabel}
            </InputLabel>
            <Select labelId='gender-label' value={gender} onChange={setGender}>
              <MenuItem value={'female'}>{genderFemaleLabel}</MenuItem>
              <MenuItem value={'male'}>{genderMaleLabel}</MenuItem>
              <MenuItem value={'other'}>{genderOtherLabel}</MenuItem>
            </Select>
          </Grid>
        </ImageCardContent>
        <ImageCardActions>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            onClick={save}
            disabled={loading}
          >
            {saveLabel}
          </Button>
        </ImageCardActions>
      </ImageCard>
      <Button
        onClick={() => setModalisOpen(true)}
        variant='contained'
        size='large'
        className={classes.deleteButton}
      >
        {deleteOwnButtonRequest}
      </Button>

      <Modal open={modalIsOpen} onClose={() => setModalisOpen(false)}>
        <Card className={classes.modal}>
          <div className={classes.warning}>
            <Typography variant='h6'>{deleteOwnContent}</Typography>
          </div>
          <Button
            variant='contained'
            size='large'
            onClick={deleteUserAccount}
            className={classes.deleteButton}
          >
            {deleteOwnButtonConfirm}
          </Button>
        </Card>
      </Modal>
    </AppPageLayout>
  )
}

export default Me
