import {
  createDispatchHook,
  createNextHook,
  createProvider,
  createStateHook,
  createStore,
  createStoreHook,
  RxStore,
} from '@restate/core'
import { connectDevTools } from '@restate/dev-tools'
import { connectReactRouter } from '@restate/router'
import { connectAppDrawer } from 'components/AppDrawer/AppDrawer.service'
import { createBrowserHistory } from 'history'
import { connectLanguageQueryParam } from 'I18N/connectLanguageQueryParam'
import { connectAcceptInvitationPageService } from 'pages/AcceptInvitation.tsx/AcceptInvitation.page-service'
import { connectAdminInvitePageService } from 'pages/AdminInvite/AdminInvite.page-service'
import { connectCoursePageService } from 'pages/Course/Course.page-service'
import { connectCoursesPageService } from 'pages/Courses/Courses.page-service'
import { connectExportPageService } from 'pages/Export/Export.page-service'
import { connectGlobalSettingsPage } from 'pages/GlobalSettings/GlobalSettings.page-service'
import { connectInstitutePage } from 'pages/Institute/Institute.page-service'
import { connectInstituteAdminSharesPage } from 'pages/InstituteAdminShares/InstituteAdminShares.page-service'
import { connectInstitutesPage } from 'pages/Institutes/Institutes.page-service'
import { connectLoginPasswordForgot } from 'pages/LoginPasswordForgot/LoginPasswordForgot.service'
import { connectLoginPasswordForgotSetPasswordService } from 'pages/LoginPasswordForgotSetPassword/LoginPasswordForgotSetPassword.service'
import { connectMePage } from 'pages/Me/Me.service'
import { connectRegister } from 'pages/Register/Register.page-service'
import { connectSettingsPage } from 'pages/Settings/Settings.page-service'
import { connectSharingObjectPage } from 'pages/SharingObject/SharingObject.page-service'
import { connectShopPage } from 'pages/Shop/Shop.page-service'
import { connectSingleTrainingsPage } from 'pages/SingleTrainings/SingleTrainings.page-service'
import { connectTraineesSharePage } from 'pages/TraineeShares/TraineesShares.page-service'
import { connectTrainerInvitePageService } from 'pages/TrainerInvite/TrainerInvite.page-service'
import { connectTrainersPage } from 'pages/Trainers/Trainers.page-service'
import { connectTrainingsPageService } from 'pages/Trainings/Trainings.page-service'
import { connectUserChangeEmailPageService } from 'pages/UserChangeEmail/UserChangeEmail.service'
import { connectChangePasswordPageService } from 'pages/UserChangePassword/UserChagnePassword.service'
import { connectUserPageService } from 'pages/UserDetails/UserDetails.page-service'
import { connectAxios } from 'services/axios/axios.service'
import { connectEcwidLocaleService } from 'services/ecwidLocale/ecwidLocale.service'
import { connectInstituteService } from 'services/institutes/institutes.service'
import { connectLogger } from 'services/logger/logger.service'
import { connectLogin } from 'services/login/login.service'
import { connectLogout } from 'services/logout/logout.service'
import { connectNetwork } from 'services/network/network.service'
import { connectRouteGuard } from 'services/routeGuard/routeGuard.service'
import { connectRouter } from 'services/router/router.service'
import { connectSession } from 'services/session/session.service'
import { connectChangeUserRoleComponentService } from '../components/ChangeUserRole/ChangeUserRole.component-service'
import { connectReminderPageService } from '../components/Reminder/Reminder.page-service'
import { connectTranslationMenuPage } from '../components/TranslationMenu/TranslationMenu.page-service'
import { connectTranslationSwitch } from '../I18N/AppIntlProvider'
import { connectDashboardPage } from '../pages/Dashboard/Dashboard.page-service'
import { connectTraineesPage } from '../pages/Trainees/Trainees.page-service'
import { AppMessages } from './appMessages'
import { connectUserChangePassword } from './connector/userChangeEmail.connector'
import { connectUserMenu } from './connector/userMenu.connector'
import { defaultState, State } from './state'

export type AppStore = RxStore<State, AppMessages>

export const store = createStore<State, AppMessages>({
  state: defaultState,
  options: {
    storeName: 'CP',
  },
})

export const history = createBrowserHistory()

connectLogger(store, { color: '#333', backgroundColor: '#bbfdc8' })
connectAxios(store)
connectNetwork(store)
connectSession(store)
connectDevTools(store)
connectReactRouter({ appStore: store, history })
connectRouter({ store, history })
connectLanguageQueryParam(store)
connectLogin(store)
connectLogout(store)
connectRegister(store)
connectInstituteService(store)
connectAppDrawer(store)
connectRouteGuard(store)
connectTranslationSwitch(store)
connectEcwidLocaleService(store)

// Pages
connectAcceptInvitationPageService(store)
connectAdminInvitePageService(store)
connectChangePasswordPageService(store)
connectCoursePageService(store)
connectCoursesPageService(store)
connectDashboardPage(store)
connectExportPageService(store)
connectGlobalSettingsPage(store)
connectInstituteAdminSharesPage(store)
connectInstitutePage(store)
connectInstitutesPage(store)
connectLoginPasswordForgot(store)
connectLoginPasswordForgotSetPasswordService(store)
connectMePage(store)
connectReminderPageService(store)
connectSettingsPage(store)
connectSharingObjectPage(store)
connectShopPage(store)
connectSingleTrainingsPage(store)
connectTraineesPage(store)
connectTraineesSharePage(store)
connectTrainerInvitePageService(store)
connectTrainersPage(store)
connectTrainingsPageService(store)
connectTranslationMenuPage(store)
connectUserChangeEmailPageService(store)
connectUserPageService(store)

// Widgets
connectUserMenu(store)
connectUserChangePassword(store)
connectChangeUserRoleComponentService(store)

store.dispatch({ type: 'App/Init' })

export const AppStoreProvider = createProvider<State, AppMessages>(store)
export const useAppState = createStateHook(AppStoreProvider)
export const useNextAppState = createNextHook(AppStoreProvider)
export const useStoreHook = createStoreHook(AppStoreProvider)
export const useDispatchHook = createDispatchHook(AppStoreProvider)
