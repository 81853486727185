import axios, { AxiosRequestConfig } from 'axios'
import { config } from 'config/config'
import RouteParser from 'route-parser'
import { AppRoutes } from 'routes'
import { Observable } from 'rxjs'
import { LogoutService } from 'services/logout/logout.service'
import { AppStore } from 'state/store'
import { goTo } from './goTo'

interface HttpProps extends AxiosRequestConfig {
  route?: {
    path: string
    id?: string
    token?: string
  }
}

export interface HttpError<T extends string> {
  errorMessage: T
  status: number
}

export const http = (_store: AppStore) => <T>(conf: HttpProps) => {
  const { route } = conf

  if (route) {
    const { api } = config()
    const routeParser = new RouteParser(route.path)
    const params = route.id
      ? { id: route.id }
      : route.token
      ? { token: route.token }
      : {}
    const fqRoute = routeParser.reverse(params)
    conf.url = api + fqRoute
  }

  const request$ = new Observable<T>(observable => {
    console.log('HTTP [REQ]: ' + conf.url, conf)
    axios(conf)
      .then(response => {
        console.log('HTTP [RES]', response)
        observable.next(response.data.data)
        observable.complete()
      })
      .catch(err => {
        const redirect = goTo(_store)

        if (!err.response || !err.response.status) {
          // TODO: handle Network error (server is not responding)
          return redirect(AppRoutes.NetworkError)
        }

        const status = err.response.status

        if (status === 400 || status === 500) {
          return redirect(AppRoutes.InternalServerError)
        }

        if (status === 401) {
          _store.dispatch({ type: LogoutService.Logout })
        }

        const errMsg: HttpError<any> = {
          errorMessage:
            err.response.data?.errorMessage ||
            'No error message provided by server.',
          status,
        }
        observable.error(errMsg)
      })
  })

  return request$
}
