import { makeStyles } from '@material-ui/styles'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'

export const useStyles = makeStyles({
  card: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    width: '100%',
  },

  field: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%',
    },
  },

  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  button: {
    margin: theme.spacing(1),
  },

  secondaryButton: {
    margin: theme.spacing(1),
    color: Colors.neutrals[4],
  },

  cardHeader: {},
})
