import React, { useState } from 'react'
import { useLayoutStyles } from './useLayoutStyles.hook'
import { useTitle } from './useTitle.hook'
import { TranslationButton } from '../NavBar/NavBar'
import { Colors } from '../../theme/colors'

interface MainPageLayoutProps {
  title: string
  showTranslationSwitch?: boolean
}

export const MainPageLayout: React.FunctionComponent<MainPageLayoutProps> = props => {
  const { root, deco } = useLayoutStyles()
  const { title, showTranslationSwitch = false } = props

  const [isOpen, setIsOpen] = useState(false)

  useTitle(title)

  return (
    <div className={root}>
      <>
        <div className={deco} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            backgroundColor: Colors.background[0],
          }}
        >
          <TranslationButton
            open={isOpen}
            handleClose={() => {
              setIsOpen(false)
            }}
            handleOpen={() => {
              setIsOpen(true)
            }}
            showTranslationSwitch={showTranslationSwitch}
          />
        </div>

        {props.children}
      </>
    </div>
  )
}
