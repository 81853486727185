import { Course } from 'model/Course'
import { Institute } from 'model/Institute'
import { TrainerDTO } from 'model/ctrl/trainers/GetTrainersRes.schema'

export interface CourseAggregate extends Course {
  institute: Institute
  trainer: TrainerDTO
}

export interface CourseState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  course: CourseAggregate | null
}

export const defaultCourseState: CourseState = {
  meta: {
    loading: false,
    notFound: false,
  },
  course: null,
}
