import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'cp.components.reminder.title',
    defaultMessage: 'Home',
  },
  welcome: {
    id: 'cp.components.reminder.welcome',
    defaultMessage: 'Welcome',
  },
  appTitle: {
    id: 'cp.components.reminder.app.title',
    defaultMessage: 'Want to start? Download the App!',
  },
  appContent: {
    id: 'cp.components.reminder.app.content',
    defaultMessage:
      'In order to unlock the full potential of CorPatch please download our App from the Google-Playstore and get your training started today. You are able to chose from multiple different training simulations and you`ll get feedback for every training you finish. In browser and the app you will see all your results and how you get better every time you practice.',
  },
  appPlayStoreButton: {
    id: 'cp.components.reminder.app.appPlayStoreButton',
    defaultMessage: 'Get the App',
  },
  appAppStoreButton: {
    id: 'cp.components.reminder.app.appAppStoreButton',
    defaultMessage: 'Get the App',
  },
})
