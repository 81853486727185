import {
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { makeStyles } from '@material-ui/styles'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { Colors } from 'theme/colors'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import {
  useUserChangeEmailActions,
  useUserChangeEmailState,
} from './UserChangeEmail.restate'
import {
  ImageCard,
  ImageCardContent,
  ImageCardActions,
} from 'components/ImageCard/ImageCard'
import { useIntl } from 'react-intl'
import messages from './UserChangeEmail.messages'
import { useGoBack } from 'services/router/useGoBack.hook'
import {
  useAppState,
  useDispatchHook,
  useNextAppState,
} from '../../state/store'

const useClasses = makeStyles({
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  send: {
    display: 'flex',
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkCircleIcon: {
    width: 80,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      margin: theme.spacing(2),
    },
    margin: theme.spacing(3),
    color: Colors.supportingCyan[3],
  },
})

const UserChangeEmailLoading = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.email)

  return <AppPageLayout title={pageTitle} loading={true}></AppPageLayout>
}

const UserChangeEmailNotFound = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)

  return (
    <PageNotFound
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.empty}
    />
  )
}

const EMailForm = () => {
  const intl = useIntl()
  const verifyEmailTitle = intl.formatMessage(messages.verifyEmailTitle)
  const verifyEmailContent = intl.formatMessage(messages.verifyEmailContent)
  const verifyEmailButton = intl.formatMessage(messages.verifyEmailButton)
  const changeEmail = intl.formatMessage(messages.changeEmail)
  const newEmail = intl.formatMessage(messages.newEmail)
  const cancelButton = intl.formatMessage(messages.cancelButton)
  const pageTitle = intl.formatMessage(messages.email)

  const onboardingState = useAppState(s => s.pages.onboarding)

  const classes = useClasses()
  const goBack = useGoBack()
  const dispatch = useDispatchHook()

  const sendVerificationEmailRequest = () => {
    dispatch({ type: 'Pages/Service/Reminder/Email/Verify' })
  }

  const {
    nextEmail,
    errorNextEmail,
    errorNextEmailMessage,
  } = useUserChangeEmailState(s => s)
  const {
    setNextEmail,
    sendChangeEmailRequest,
    validateEmail,
  } = useUserChangeEmailActions()

  return (
    <AppPageLayout title={pageTitle}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        {onboardingState.onboarding.emailAddressVerified === false && (
          <ImageCard imgUrl={Images.emailValidation}>
            <ImageCardContent>
              <Typography className={classes.title} variant='h5'>
                {verifyEmailTitle}
              </Typography>

              <Typography variant='body1'>{verifyEmailContent}</Typography>
            </ImageCardContent>
            <ImageCardActions>
              <Button
                color='secondary'
                variant='contained'
                onClick={sendVerificationEmailRequest}
              >
                {verifyEmailButton}
              </Button>
            </ImageCardActions>
          </ImageCard>
        )}

        <ImageCard imgUrl={Images.mail}>
          <ImageCardContent>
            <Typography className={classes.title} variant='h5'>
              {changeEmail}
            </Typography>
            <TextField
              value={nextEmail}
              label={newEmail}
              onBlur={validateEmail}
              onChange={e => setNextEmail(e.target.value)}
              error={errorNextEmail}
              helperText={errorNextEmailMessage}
              type='email'
              placeholder='hello@corpatch.de'
            />
          </ImageCardContent>
          <ImageCardActions>
            <Button
              color='secondary'
              variant='contained'
              onClick={sendChangeEmailRequest}
            >
              {changeEmail}
            </Button>
            <Button onClick={goBack}>{cancelButton}</Button>
          </ImageCardActions>
        </ImageCard>
      </div>
    </AppPageLayout>
  )
}

const EMailSend = () => {
  const intl = useIntl()
  const sendEmail = intl.formatMessage(messages.sendEmail)
  const OK = intl.formatMessage(messages.ok)
  const classes = useClasses()
  const pageTitle = intl.formatMessage(messages.email)

  const next = useNextAppState(s => s.pages.userChangeEmail)

  return (
    <AppPageLayout title={pageTitle}>
      <Card>
        <CardContent>
          <div className={classes.send}>
            <CheckCircleIcon
              color='inherit'
              className={classes.checkCircleIcon}
            />
            <Typography variant='h6' style={{ marginBottom: 20 }}>
              {sendEmail}
            </Typography>
          </div>
        </CardContent>

        <CardActions style={{ flexDirection: 'row-reverse' }}>
          <Button
            type='button'
            onClick={() => next(s => (s.send = false))}
            variant='contained'
            color='secondary'
          >
            {OK}
          </Button>
        </CardActions>
      </Card>
    </AppPageLayout>
  )
}

export const UserChangeEmail: React.FC<{}> = () => {
  const { loading, notFound } = useUserChangeEmailState(s => s.meta)
  const send = useUserChangeEmailState(s => s.send)

  if (loading) return <UserChangeEmailLoading />
  else if (notFound) return <UserChangeEmailNotFound />
  else if (send) return <EMailSend />
  else return <EMailForm />
}

export default UserChangeEmail
