import { makeStyles } from '@material-ui/styles'
import { background } from 'styles/styles'
import { Colors } from 'theme/colors'

export const useLayoutStyles = makeStyles({
  root: {
    height: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    ...background,
  },
  deco: {
    width: '100%',
    height: '3px',
    backgroundColor: Colors.primary[3],
  },
})
