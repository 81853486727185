import { useAppState, useNextAppState, AppStoreProvider } from 'state/store'
import { ActionFactoryProps, createActionsHook } from '@restate/core'
import { RegisterState } from './Register.state'
import { AppMessages } from 'state/appMessages'
import { RegisterMessage, RegisterService } from './Register.page-service'
import { config } from 'config/config'
import { isEmail } from '../../utils/isEmail'
import { FormEvent } from 'react'

export const useRegisterState = () => useAppState(state => state.pages.register)
export const useNextRegisterState = () =>
  useNextAppState(state => state.pages.register)

const RegisterActions = ({
  next,
  dispatch,
  state,
}: ActionFactoryProps<RegisterState, AppMessages>) => ({
  setPassword(password: string) {
    next(loginState => (loginState.password = password))
  },
  setEmail(email: string) {
    next(loginState => (loginState.email = email))
  },
  setfirstName(firstName: string) {
    next(registerState => (registerState.firstName = firstName))
  },
  setlastName(lastName: string) {
    next(registerState => (registerState.lastName = lastName))
  },
  setTermsOfUseAccepted(accepted: boolean) {
    next(registerState => (registerState.termsOfUseAccepted = accepted))
  },

  register(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const password = state().password
    const email = state().email
    const termsOfUseAccepted = state().termsOfUseAccepted
    const error = state().error

    const firstName = state().firstName
    const lastName = state().lastName

    const isValid =
      password.length >= config().passwordMinLen &&
      isEmail(email) &&
      termsOfUseAccepted &&
      error === null

    if (!isValid) {
      return
    }

    const registerMessage: RegisterMessage = {
      type: RegisterService.Register,
      payload: {
        email,
        password,
        firstName,
        lastName,
      },
    }
    dispatch(registerMessage)
  },
})

export const useRegisterActions = createActionsHook(
  AppStoreProvider,
  state => state.pages.register,
  RegisterActions
)
