import { ThemeProvider } from '@material-ui/styles'
import { AppSnackBar } from 'components/AppSnackBar/AppSnackBar'
import Imprint from 'components/Imprint/Imprint'
import AcceptInvitation from 'pages/AcceptInvitation.tsx/AcceptInvitation'
import AdminInvite from 'pages/AdminInvite/AdminInvite'
import { Course } from 'pages/Course/Course'
import { Courses } from 'pages/Courses/Courses'
import Export from 'pages/Export/Export'
import Institute from 'pages/Institute/Institute'
import Institutes from 'pages/Institutes/Institutes'
import InternalServerError from 'pages/InternalServerError/InternalServerError'
import LoginPasswordForgot from 'pages/LoginPasswordForgot/LoginPasswordForgot'
import { LoginPasswordForgotSetPassword } from 'pages/LoginPasswordForgotSetPassword/LoginPasswordForgotSetPassword'
import Logout from 'pages/Logout/Logout'
import { Me } from 'pages/Me/Me'
import NetworkError from 'pages/NetworkError/NetworkError'
import Settings from 'pages/Settings/Settings'
import { Shop } from 'pages/Shop/Shop'
import TrainerInvite from 'pages/TrainerInvite/TrainerInvite'
import Trainers from 'pages/Trainers/Trainers'
import Trainings from 'pages/Trainings/Trainings'
import { UserChangeEmail } from 'pages/UserChangeEmail/UserChangeEmail'
import { UserChangePassword } from 'pages/UserChangePassword/UserChangePassword'
import { UserDetails } from 'pages/UserDetails/UserDetails'
import React, { useEffect, useMemo } from 'react'
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom'
import { AppRoutes } from 'routes'
import Reminder from './components/Reminder/Reminder'
import { AppIntlProvider } from './I18N/AppIntlProvider'
import './index.css'
import { Login } from './pages/Login/Login'
import {
  AppStoreProvider,
  history,
  store,
  useAppState,
  useNextAppState,
} from './state/store'
import { theme } from './theme/theme'
import Dashboard from './pages/Dashboard/Dashboard'
import Trainees from './pages/Trainees/Trainees'
import GlobalSettings from 'pages/GlobalSettings/GlobalSettings'
import TermsOfUse from 'components/TermsOfUse/TermsOfUse'
import Register from 'pages/Register/Register'
import EmailValidation from 'pages/EmailValidation/EmailValidation'
import SingleTrainings from 'pages/SingleTrainings/SingleTrainings'
import ValidateLogin from './pages/ValidateLogin/ValidateLogin'
import { USERROLES } from './model/UserRole'
import SharingObjects from 'pages/SharingObject/SharingObject'
import TraineeShares from 'pages/TraineeShares/TraineeShares'
import { InstituteAdminShares } from 'pages/InstituteAdminShares/InstituteAdminShares'
import { deDE, enUS, fiFI, frFR, itIT, esES } from '@material-ui/core/locale'
import { createTheme } from '@material-ui/core'
import { useRTL } from 'utils/useRTL'

const UserMenuPages = () => (
  <>
    <Route exact path={AppRoutes.MeEMail} component={UserChangeEmail} />
    <Route exact path={AppRoutes.MePassword} component={UserChangePassword} />
    <Route exact path={AppRoutes.MeAccount} component={Me} />
  </>
)

const CorPatchAdminPages = () => {
  const isCorPatchAdmin = useAppState(
    s => s.session.user.role === USERROLES.CorPatchAdmin
  )

  if (!isCorPatchAdmin) {
    return <></>
  }

  return (
    <>
      <Route exact path={AppRoutes.Home} component={Dashboard} />
      <UserMenuPages />
      <Route exact path={AppRoutes.AdminInvite} component={AdminInvite} />
      <Route exact path={AppRoutes.GlobalSettings} component={GlobalSettings} />
      <Route exact path={AppRoutes.Course} component={Course} />
      <Route exact path={AppRoutes.Courses} component={Courses} />
      <Route exact path={AppRoutes.Export} component={Export} />
      <Route exact path={AppRoutes.Imprint} component={Imprint} />
      <Route exact path={AppRoutes.Institute} component={Institute} />
      <Route exact path={AppRoutes.Institutes} component={Institutes} />
      <Route exact path={AppRoutes.Settings} component={Settings} />
      <Route exact path={AppRoutes.TrainerInvite} component={TrainerInvite} />
      <Route exact path={AppRoutes.Trainers} component={Trainers} />
      <Route exact path={AppRoutes.Trainings} component={Trainings} />
      <Route exact path={AppRoutes.Trainees} component={Trainees} />
      <Route exact path={AppRoutes.UserDetails} component={UserDetails} />
      <Route exact path={AppRoutes.SharingObject} component={SharingObjects} />
    </>
  )
}

const TrainerPages = () => {
  const isTrainer = useAppState(s => s.session.user.role === USERROLES.Trainer)
  if (!isTrainer) {
    return <></>
  }
  return (
    <>
      <Route exact path='/'>
        <Redirect to={AppRoutes.Courses} />
      </Route>
      <UserMenuPages />
      <Route exact path={AppRoutes.Course} component={Course} />
      <Route exact path={AppRoutes.Courses} component={Courses} />
      <Route exact path={AppRoutes.Export} component={Export} />
      <Route exact path={AppRoutes.Imprint} component={Imprint} />
      <Route exact path={AppRoutes.Settings} component={Settings} />
      <Route exact path={AppRoutes.Trainings} component={Trainings} />
      <Route exact path={AppRoutes.UserDetails} component={UserDetails} />
    </>
  )
}

const TraineePages = () => {
  const isTrainee = useAppState(s => s.session.user.role === USERROLES.Trainee)
  if (!isTrainee) {
    return <></>
  }

  return (
    <>
      <Route exact path={'/'} component={Reminder} />
      <UserMenuPages />
      <Route exact path={AppRoutes.TraineeShares} component={TraineeShares} />
      <Route
        exact
        path={AppRoutes.TraineeSharesInvite}
        component={TraineeShares}
      />
      <Route exact path={AppRoutes.Imprint} component={Imprint} />
      <Route
        exact
        path={AppRoutes.SingleTrainings}
        component={SingleTrainings}
      />
    </>
  )
}

const InstituteAdminPages = () => {
  const isInstituteAdmin = useAppState(
    s => s.session.user.role === USERROLES.InstituteAdmin
  )
  if (!isInstituteAdmin) {
    return <></>
  }

  return (
    <>
      <Route exact path='/'>
        <Redirect to={AppRoutes.Courses} />
      </Route>
      <UserMenuPages />
      <Route exact path={AppRoutes.AdminInvite} component={AdminInvite} />
      <Route exact path={AppRoutes.Course} component={Course} />
      <Route exact path={AppRoutes.Courses} component={Courses} />
      {/* <Route exact path={AppRoutes.Export} component={Export} /> */}
      <Route exact path={AppRoutes.Imprint} component={Imprint} />
      <Route exact path={AppRoutes.Settings} component={Settings} />
      <Route
        exact
        path={AppRoutes.InstituteAdminShares}
        component={InstituteAdminShares}
      />
      <Route exact path={AppRoutes.TrainerInvite} component={TrainerInvite} />
      <Route exact path={AppRoutes.Trainers} component={Trainers} />
      <Route exact path={AppRoutes.Trainings} component={Trainings} />
      <Route exact path={AppRoutes.UserDetails} component={UserDetails} />
      <Route
        exact
        path={AppRoutes.InstituteAdminSharingObject}
        component={SharingObjects}
      />
    </>
  )
}

const PublicPages = () => {
  // Routing is restricted if the query for embeddedMode has been set.
  // This is the case when the app is displayed as a webview from a mobile device.
  const next = useNextAppState(state => state.routerExtension)

  const { search } = useLocation()

  const embeddedMode = useMemo(() => {
    const query = new URLSearchParams(search)

    return Boolean(query.get('embeddedMode'))
  }, [search])

  useEffect(() => {
    // Only unidirectional change to embedded mode. Will be persisted over route changes.
    // A reload will refresh this value, because it will usually stay consistent if embedded mode is turned on.
    if (embeddedMode) {
      next(session => {
        session.embeddedMode = true
      })
    }
  }, [embeddedMode, next])

  return (
    <>
      <Route
        exact
        path={AppRoutes.AcceptInvitation}
        component={AcceptInvitation}
      />
      <Route
        exact
        path={AppRoutes.LoginPasswordForgotSetPassword}
        component={LoginPasswordForgotSetPassword}
      />
      <Route
        exact
        path={AppRoutes.InternalServerError}
        component={InternalServerError}
      />
      <Route exact path={AppRoutes.Login} component={Login} />
      <Route
        exact
        path={AppRoutes.LoginPasswordForgot}
        component={LoginPasswordForgot}
      />
      <Route path={AppRoutes.ValidateLogin} component={ValidateLogin} />
      <Route
        path={AppRoutes.ValidateLoginPublic}
        exact
        component={ValidateLogin}
      />
      <Route exact path={AppRoutes.Logout} component={Logout} />
      <Route exact path={AppRoutes.NetworkError} component={NetworkError} />
      <Route exact path={AppRoutes.LoginTermsOfUse} component={TermsOfUse} />
      <Route exact path={AppRoutes.Register} component={Register} />
      <Route
        exact
        path={AppRoutes.EmailValidation}
        component={EmailValidation}
      />
    </>
  )
}

function useLocalizedTheme() {
  function localization(locale: string) {
    switch (locale) {
      case 'de-DE':
        return deDE
      case 'en-US':
        return enUS
      case 'da-DA':
        return enUS
      case 'fi-FI':
        return fiFI
      case 'it-IT':
        return itIT
      case 'fr-FR':
        return frFR
      case 'es-ES':
        return esES
      case 'ar-SA': // no arabic support in MUI v4
        return enUS
      default:
        return enUS
    }
  }

  const s = useAppState(s => s.i18n.locale)
  const isRTL = useRTL()

  const localizedTheme = React.useMemo(
    () =>
      createTheme(
        { ...theme, direction: isRTL ? 'rtl' : 'ltr' },
        localization(s)
      ),
    [s, isRTL]
  )
  return localizedTheme
}

function App() {
  const theme = useLocalizedTheme()
  const RTL = useAppState(s => s.i18n.locale === 'ar-SA')

  return (
    <div dir={RTL ? 'rtl' : 'ltr'}>
      <AppStoreProvider.Provider value={store}>
        <AppIntlProvider>
          <Router history={history}>
            <ThemeProvider theme={theme}>
              <AppSnackBar>
                <Shop />
                <Switch>
                  <>
                    <CorPatchAdminPages />
                    <InstituteAdminPages />
                    <TrainerPages />
                    <TraineePages />
                    <PublicPages />
                  </>
                </Switch>
              </AppSnackBar>
            </ThemeProvider>
          </Router>
        </AppIntlProvider>
      </AppStoreProvider.Provider>
    </div>
  )
}

export default App
