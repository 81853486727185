import { Message } from '@restate/core'
import { routes } from 'model/ctrl/routes'
import { AppRoutes } from 'routes'
import { combineLatest } from 'rxjs'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { CptConfig } from 'model/CptConfig'

export interface PagesSettingsServiceMessages extends Message {
  type:
    | 'Page/Settings/Save'
    | 'Pages/Institute/InstituteAdmin/ResultConfig/Save'
}

export const connectSettingsPage = (store: AppStore) => {
  const { onPageEnter, onLogin, http, onAction, snackBar } = tools(store)

  combineLatest(onLogin(), onPageEnter(AppRoutes.Settings)).subscribe(() => {
    http<CptConfig>({ route: { path: routes.cpt.config } }).subscribe(
      appConfig => {
        store.next(s => {
          s.pages.settings.appConfig = appConfig
          s.pages.instituteResultConfig = appConfig.resultConfig
        })
      }
    )
  })

  onAction({ type: 'Page/Settings/Save' }).subscribe(() => {
    const data = store.state.pages.settings.appConfig
    http<CptConfig>({
      route: { path: routes.cpt.config },
      method: 'PUT',
      data,
    }).subscribe(() => {
      snackBar.success('Configuration saved')
    })
  })

  onAction({
    type: 'Pages/Institute/InstituteAdmin/ResultConfig/Save',
  }).subscribe(() => {
    const data = store.state.pages.instituteResultConfig
    http<any>({
      route: { path: routes.corPatchAdmin.resultConfigInstituteAdmin },
      method: 'POST',
      data,
    }).subscribe(() => {
      snackBar.success('Configuration saved')
    })
  })
}
