import { Machine, assign, interpret } from 'xstate'

interface PageStateMachineProps {
  id: string
  fetchData: () => any
  setLoading: () => any
}

export type PageStateMachineEvents =
  | { type: 'LOGIN' }
  | { type: 'PAGE_ENTER' }
  | { type: 'LOGOUT' }
  | { type: 'PAGE_LEAVE' }
  | { type: 'RESOLVE' }
  | { type: 'REJECT' }

interface Context {
  id: string
}

interface Schema {
  states: {
    init: {}
    waitingForPageEnter: {}
    waitingForLogin: {}
    fetching: {}
    success: {}
    error: {}
  }
}

/**
 *
 * Do not use anymore. This approach is outdated. Use the `onLoginPageEnter` tool instead
 * @deprecated
 */
export const pageStateMachine = ({
  id,
  fetchData,
  setLoading,
}: PageStateMachineProps) => {
  const m = Machine<Context, Schema, PageStateMachineEvents>(
    {
      id,
      initial: 'init',
      context: {
        id: '',
      },
      states: {
        init: {
          entry: ['setLoading'],
          on: {
            LOGIN: 'waitingForPageEnter',
            PAGE_ENTER: 'waitingForLogin',
          },
        },
        waitingForPageEnter: {
          entry: ['setLoading'],
          on: {
            LOGOUT: 'init',
            PAGE_ENTER: {
              target: 'fetching',
              actions: assign({ id: (ctx: any) => ctx.id }),
            },
          },
        },
        waitingForLogin: {
          entry: ['setLoading'],
          on: {
            PAGE_LEAVE: 'init',
            LOGIN: {
              target: 'fetching',
              actions: assign({ id: (ctx: any) => ctx.id }),
            },
          },
        },
        fetching: {
          entry: ['fetchData'],
          on: {
            LOGOUT: 'waitingForLogin',
            PAGE_LEAVE: 'waitingForPageEnter',
            PAGE_ENTER: 'fetching',
            RESOLVE: 'success',
            REJECT: 'error',
          },
        },
        success: {
          on: {
            PAGE_ENTER: 'fetching',
            PAGE_LEAVE: 'waitingForPageEnter',
            LOGOUT: 'waitingForLogin',
          },
        },
        error: {
          on: {
            PAGE_ENTER: 'fetching',
            PAGE_LEAVE: 'waitingForPageEnter',
            LOGOUT: 'waitingForLogin',
          },
        },
      },
    },
    {
      actions: {
        fetchData,
        setLoading,
      },
    }
  )
  return interpret(m).start()
}
