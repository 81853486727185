export interface DashboardState {
  data: {
    usersCount: number
    mobilesCount: number
    cpsCount: number
    trainingsCount: number
    usersCountRecently: number
    mobilesCountRecently: number
    cpsCountRecently: number
  }
  meta: {
    loading: boolean
    notFound: boolean
    error: string | null
  }
}

export const defaultDashboardState = {
  data: {
    usersCount: 0,
    mobilesCount: 0,
    cpsCount: 0,
    trainingsCount: 0,
    usersCountRecently: 0,
    mobilesCountRecently: 0,
    cpsCountRecently: 0,
  },
  meta: {
    loading: false,
    notFound: false,
    error: null,
  },
}
