import { makeStyles } from '@material-ui/styles'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { Images } from 'theme/Images'
import { useSharingObjectsState } from './SharingObject.restate'
import { theme } from 'theme/theme'
import { Colors } from 'theme/colors'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useAppState, useDispatchHook, useNextAppState } from 'state/store'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { config } from 'config/config'
import { YesNoDialog } from 'components/YesNoDialog/GenericConfirmDeletionDialog'
import { USERROLES } from 'model/UserRole'
import messages from './SharingObject.messages'
import { useIntl } from 'react-intl'

const SharingObjectsLoading = () => {
  const intl = useIntl()
  return (
    <AppPageLayout
      title={intl.formatMessage(messages.pageTitle)}
      loading={true}
    ></AppPageLayout>
  )
}

const SharingObjectsNotFound = () => {
  const intl = useIntl()

  return (
    <PageNotFound
      title={intl.formatMessage(messages.pageNotFoundTitle)}
      message={intl.formatMessage(messages.pageNotFoundMessage)}
      img={Images.detailedExamination}
    />
  )
}

export function SharingObject() {
  const { loading, notFound } = useSharingObjectsState(s => s.meta)
  const intl = useIntl()
  if (loading) return <SharingObjectsLoading />
  if (notFound) return <SharingObjectsNotFound />

  return (
    <AppPageLayout showBackButton title={intl.formatMessage(messages.title)}>
      <SharingObjectForm />
    </AppPageLayout>
  )
}

const useStyles = makeStyles({
  card: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    width: '100%',
  },

  field: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%',
    },
  },

  button: {
    margin: theme.spacing(1),
  },

  secondaryButton: {
    margin: theme.spacing(1),
    color: Colors.neutrals[4],
  },

  modal: {
    position: 'absolute',
    height: '250',
    width: '250',
    top: '40%',
    left: '40%',
    padding: '20px',
  },

  cardHeader: {},
})

function getHostUrl() {
  return window.location.protocol + '//' + window.location.host
}

function SharingObjectForm() {
  const sharingObject = useSharingObjectsState(s => s.data)
  const intl = useIntl()

  const isInstituteAdmin = useAppState(s =>
    s.session.user.availableRoles.includes(USERROLES.InstituteAdmin)
  )
  const isCorPatchAdmin = useAppState(s =>
    s.session.user.availableRoles.includes(USERROLES.CorPatchAdmin)
  )

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = React.useState(
    false
  )
  const next = useNextAppState(s => s.pages.sharingObjects)
  const dispatch = useDispatchHook()
  const classes = useStyles()

  const pullUrl =
    config().apiAbsolute + `/api/sharing/pull/${sharingObject._id}`

  const invitationLink =
    getHostUrl() + `/trainee-shares/invite/${sharingObject.invitationCode}`

  function saveSharingObject() {
    dispatch({ type: 'Page/SharingObject/Save' })
  }

  function deleteSharingObject() {
    dispatch({ type: 'Page/SharingObject/Delete' })
    setConfirmDeleteDialogOpen(false)
  }
  return (
    <>
      <div style={{ width: '100%' }}>
        <Card className={classes.card}>
          <CardHeader title={intl.formatMessage(messages.title)} />
          <CardContent>
            <FormGroup row>
              <TextField
                label={intl.formatMessage(messages.name)}
                disabled={isInstituteAdmin}
                required
                className={classes.field}
                style={{ width: '100%' }}
                value={sharingObject.name}
                onChange={e => next(s => (s.data.name = e.target.value))}
              />
            </FormGroup>
            <FormGroup row>
              <TextField
                label={intl.formatMessage(messages.invitationCode)}
                disabled
                required
                style={{ width: '100%' }}
                className={classes.field}
                value={sharingObject.invitationCode}
                onChange={e =>
                  next(s => (s.data.invitationCode = e.target.value))
                }
              />
              <TextField
                disabled
                label={intl.formatMessage(messages.invitationLink)}
                style={{ width: '100%' }}
                className={classes.field}
                value={invitationLink}
              />

              <TextField
                disabled={isCorPatchAdmin}
                label={intl.formatMessage(messages.webHook)}
                style={{ width: '100%' }}
                className={classes.field}
                value={sharingObject.webHook}
                onChange={e => next(s => (s.data.webHook = e.target.value))}
              />
              <TextField
                disabled={true}
                label={intl.formatMessage(messages.pullUrl)}
                style={{ width: '100%' }}
                className={classes.field}
                value={pullUrl}
              />
              
              <TextField
                disabled
                label={intl.formatMessage(messages.pullApiSecret)}
                style={{ width: '100%' }}
                className={classes.field}
                value={sharingObject.pullApiSecret}
                onChange={e =>
                  next(s => (s.data.pullApiSecret = e.target.value))
                }
              />
              
              {!isInstituteAdmin && (
                <FormControl style={{ width: '100%', marginTop: 10 }}>
                  <InputLabel id='notification-select'>
                    {intl.formatMessage(messages.apiAccess)}
                  </InputLabel>
                  <Select
                    style={{ width: 200 }}
                    value={sharingObject.active ? 'true' : 'false'}
                    onChange={e =>
                      next(
                        s =>
                          (s.data.active =
                            e.target.value === 'true' ? true : false)
                      )
                    }
                  >
                    <MenuItem value={'true'}>
                      {intl.formatMessage(messages.enabled)}
                    </MenuItem>
                    <MenuItem value={'false'}>
                      {intl.formatMessage(messages.disabled)}
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </FormGroup>
          </CardContent>
        </Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            marginTop: theme.spacing(2),
          }}
        >
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.button}
            onClick={saveSharingObject}
          >
            {intl.formatMessage(messages.saveButton)}
          </Button>
          {!isInstituteAdmin && (
            <Button
              variant='contained'
              color='primary'
              size='small'
              className={classes.button}
              onClick={() => {
                setConfirmDeleteDialogOpen(true)
              }}
            >
              {intl.formatMessage(messages.deleteButton)}
            </Button>
          )}
        </div>
      </div>

      {!isInstituteAdmin && (
        <YesNoDialog
          open={confirmDeleteDialogOpen}
          dialogTitle={intl.formatMessage(messages.confirmDeleteDialogTitle)}
          yesButtonTitle={intl.formatMessage(messages.confirmDeleteDialogYes)}
          noButtonTitle={intl.formatMessage(messages.confirmDeleteDialogNo)}
          onYesButtonClick={deleteSharingObject}
          onNoButtonClick={() => {
            setConfirmDeleteDialogOpen(false)
          }}
        >
          <div>
            <Typography>
              {intl.formatMessage(messages.confirmDeleteDialogMessage1)}{' '}
            </Typography>
            <Typography style={{ marginTop: 20 }}>
              {intl.formatMessage(messages.confirmDeleteDialogMessage2)}
            </Typography>
          </div>
        </YesNoDialog>
      )}
    </>
  )
}

export default SharingObject
