import { Invite } from 'components/Invite/Invite'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import React from 'react'
import { useDispatchHook } from 'state/store'
import { useGoBack } from 'services/router/useGoBack.hook'
import { useIntl } from 'react-intl'

import messages from './TrainerInvite.messages'
import { setInstitutePageTabIndex } from 'pages/Institute/Institute.page-service'
import { useTrainerInviteState, useNextTrainerInviteState } from './TrainerInvite.restate'

export const TrainerInvite: React.FC<{}> = () => {
  const intl = useIntl()
  const trainerInviteTitle = intl.formatMessage(messages.trainerInviteTitle)

  const dispatch = useDispatchHook()
  const goBack = useGoBack()
  const send = useTrainerInviteState(s => s.send)
  const next = useNextTrainerInviteState(s => s)
  return (
    <AppPageLayout title={trainerInviteTitle} showBackButton>
      <Invite
        title={trainerInviteTitle}
        send={send}
        onCancel={() => {
          setInstitutePageTabIndex(2)
          goBack()
          next((s) => {
            s.send = false
          })
        }}
        onOk={() => {
          setInstitutePageTabIndex(2)
          goBack()
          next((s) => {
            s.send = false
          })
        }}
        onSend={payload => {
          dispatch({ type: 'Page/Service/TrainerInvite/Invite', payload })
        }}
      />
    </AppPageLayout>
  )
}

export default TrainerInvite
