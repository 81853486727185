import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InviteSetPassword } from 'components/InviteSetPassword/InviteSetPassword'
import { PublicPageLayout } from 'components/layouts/PublicPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import { Spinner } from 'components/Spinner/Spinner'
import React from 'react'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { useAcceptInvitationState } from './AcceptInvitation.restate'
import { useDispatchHook } from 'state/store'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { AppRoutes } from 'routes'
import { useIntl } from 'react-intl'

import messages from './AcceptInvitation.messages'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      margin: '1rem',
    },
  },

  welcome: {
    marginBottom: theme.spacing(3),
  },
})

const AcceptInvitationLoading = () => {
  const classes = useStyles()

  return (
    <PublicPageLayout title=''>
      <div className={classes.container}>
        <Spinner visible={true} />
      </div>
    </PublicPageLayout>
  )
}

const AcceptInvitationNotFound: React.FC = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)

  return (
    <PageNotFound
      variant='public'
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.empty}
    />
  )
}

const Success = () => {
  const intl = useIntl()
  const inviteSuccessTitle = intl.formatMessage(messages.inviteSuccessTitle)
  const inviteSuccessMessage = intl.formatMessage(messages.inviteSuccessMessage)
  const inviteLoginButton = intl.formatMessage(messages.inviteLoginButton)

  const goTo = useGoToHook()

  const goToLogin = () => goTo(AppRoutes.Login)

  return (
    <PageNotFound
      variant='public'
      title={inviteSuccessTitle}
      message={inviteSuccessMessage}
      img={Images.confirmation}
      action={() => (
        <Button color='secondary' variant='contained' onClick={goToLogin}>
          {inviteLoginButton}
        </Button>
      )}
    />
  )
}

export const AcceptInvitation: React.FC<{}> = () => {
  const intl = useIntl()
  const inviteAcceptedWelcome = intl.formatMessage(
    messages.inviteAcceptedWelcome
  )
  const classes = useStyles()

  const { loading, notFound, success } = useAcceptInvitationState(s => s.meta)
  const dispatch = useDispatchHook()
  const userInfo = useAcceptInvitationState(s => s.userInfo)

  const onSend = (newPassword: string) =>
    dispatch({ type: 'Page/AcceptInvitation/Send', payload: newPassword })

  if (loading) return <AcceptInvitationLoading />
  if (notFound) return <AcceptInvitationNotFound />
  if (success) return <Success />

  const name = userInfo ? userInfo.firstName + ' ' + userInfo.lastName : ''

  return (
    <PublicPageLayout title={inviteAcceptedWelcome}>
      <div className={classes.container}>
        <Typography variant='h4' className={classes.welcome}>
          {inviteAcceptedWelcome} {name}!
        </Typography>
        <InviteSetPassword onSend={onSend} />
      </div>
    </PublicPageLayout>
  )
}

export default AcceptInvitation
