import { ActionFactoryProps, createActionsHook } from '@restate/core'
import { FormEvent } from 'react'
import { LoginMessage, LoginService } from 'services/login/login.service'
import { AppMessages } from 'state/appMessages'
import {
  AppStoreProvider,
  useAppState,
  useNextAppState,
} from '../../state/store'
import { isEmail } from '../../utils/isEmail'
import { LoginState } from './Login.state'

export const useLoginState = () => useAppState(state => state.pages.login)
export const useNextLoginState = () =>
  useNextAppState(state => state.pages.login)

const LoginActions = ({
  next,
  dispatch,
  state,
}: ActionFactoryProps<LoginState, AppMessages>) => ({
  setPassword(password: string) {
    next(loginState => (loginState.password = password))
  },
  setEmail(email: string) {
    next(loginState => (loginState.email = email))
  },

  signIn(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { email, password } = state()

    const isValid = isEmail(email) && password !== ''

    if (!isValid) {
      return
    }

    const loginMessage: LoginMessage = {
      type: LoginService.Login,
      payload: {
        email,
        password,
      },
    }
    dispatch(loginMessage)
  },
})

export const useLoginActions = createActionsHook(
  AppStoreProvider,
  state => state.pages.login,
  LoginActions
)
