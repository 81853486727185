import { defineMessages } from 'react-intl'

export default defineMessages({
  active: {
    id: 'cp.pages.instituteAdminShares.active',
    defaultMessage: 'Active',
  },
  deleted: {
    id: 'cp.pages.instituteAdminShares.deleted',
    defaultMessage: 'Deleted',
  },
  pageTitle: {
    id: 'cp.pages.instituteAdminShares.pageTitle',
    defaultMessage: 'Shares',
  },
  pageTitleNotFound: {
    id: 'cp.pages.instituteAdminShares.pageTitleNotFound',
    defaultMessage: 'Not found',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.instituteAdminShares.pageNotFoundMessage',
    defaultMessage: 'The shares you are looking for do not exists.',
  },
  noActiveSharingObjectsMessage: {
    id: 'cp.pages.instituteAdminShares.noActiveSharingObjectsMessage',
    defaultMessage: 'Currently, you have not active sharing objects.',
  },
  noDeletedSharingObjectsMessage: {
    id: 'cp.pages.instituteAdminShares.noDeletedSharingObjectsMessage',
    defaultMessage: 'No deleted sharing objects.',
  },
  startDate: {
    id: 'cp.pages.instituteAdminShares.startDate',
    defaultMessage: 'Start: ',
  },
  endDate: {
    id: 'cp.pages.instituteAdminShares.endDate',
    defaultMessage: 'End: ',
  },
})
