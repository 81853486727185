import { distinctUntilChanged, map } from 'rxjs/operators'
import { AppStore } from 'state/store'
import { FrontendLocale } from './I18N.state'

const LangReg = /lang=(\w*)/

function setLocale(search: string, store: AppStore) {
  const matches = LangReg.exec(search)
  if (matches) {
    const language = matches[1]

    const lang: FrontendLocale =
      ({
        de: 'de-DE',
        en: 'en-US',
        da: 'da-DA',
        fi: 'fi-FI',
        it: 'it-IT',
        es: 'es-ES',
        ar: 'ar-SA',
      } as any)[language] || 'en-US'

    if (language) {
      store.next(s => {
        s.i18n.locale = lang
      })
    }
  }
}

// Enables the user to change the language to another local using ?lang=de for example
export function connectLanguageQueryParam(store: AppStore) {
  store.state$
    .pipe(
      map(update => update.state.location.search),
      distinctUntilChanged()
    )
    .subscribe(search => setLocale(search, store))
}
