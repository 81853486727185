export interface UserMenuState {
  avatarUrl: string
  userName: string
  eMail: string
}

export const defaultUserMenuState: UserMenuState = {
  avatarUrl: '',
  userName: '',
  eMail: '',
}
