export const termsOfUse_en_US: string = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
		
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
		
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>

<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 

    <!--This is the 1st section - title: Terms and conditions-->
    <div class="top-section">
        <h1><a href="#terms">Terms and Conditions</a> & <a href="#policy">Privacy Policy</a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
      
    </div>
  
   
<!--This is the 2nd section (white) - title: Purpose of this document and how to contact SmartResQ -->
<div class="white">																								 
<h1 id="terms">Terms and Conditions – SmartResQ ApS / CorPatch<sup>®</sup></h1>

<h2>Purpose of this document</h2>

<p>Welcome to SmartResQ/CorPatch<sup>®</sup>!</p>

 

<p>You are currently reading our Terms and Conditions; a document with information about your relationship with SmartResQ ApS, which started when you entered our website, downloaded our free app(s), bought one of our products or the like.</p>

 

<p>SmartResQ/CorPatch<sup>®</sup> and our associates provide our products and services to you subject to the following conditions. If you visit or shop within our website(s) or app(s), you accept these conditions. We strongly advise you to read them carefully before you proceed any use of our products or services.</p>

 

<p>This document covers:</p>
<ul>
<li>How to contact SmartResQ</li>

<li>Products, properties, and proper use</li>

<li>Disclaimers and warranties</li>

<li>Ordering</li>

<li>Payment</li>

<li>Delivery</li>

<li>Responsibilities and rights relating to the sale</li>

<li>Returns</li>

<li>Data processing of personal data</li>

<li>Complaints</li>

<li>Changes to this document</li>

<li>Law and jurisdiction</li>

<li>Contact information</li>
</ul>
 

<p>If you have any questions in relation to SmartResQ Terms and Conditions, please contact us. </p>

 

<h2>How to contact SmartResQ </h2>

<p>At SmartResQ we aim to increase survival through quality CPR in case of cardiac arrest. We want to prepare and guide bystanders to take action from the first crucial minutes after they witness a cardiac arrest. Therefore, we have developed CorPatch<sup>®</sup> – We help you save a life. </p>

 

<p>Our company is located at Lundevej 26, 5700 Svendborg, Denmark </p>

 

<p>You can find us in the Central Business Register under VAT No. DK 38674102 </p>

 

<p>You can call us on telephone number +45 62 200 100 </p>

 

<p>or </p>

 

<p>e-mail us at <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

 

<h3>Any questions, comments, or complaints? </h3>

<p>We appreciate your opinion so please contact us through the above-mentioned contact information or use the contact formular on our website(s) or app(s). </p>

</div> 

<!--This is the 3rd section (beige) - title: Products, properties, and proper use-->
<div class="beige">
<h2>Products, properties, and proper use </h2>
<h3>Our website(s), apps, and products </h3>

<p>The following paragraphs describe essential properties, rights, and responsibilities relating to the use of SmartResQ/CorPatch<sup>®</sup> services, apps, and products. 
<br>
We use reasonable efforts to include accurate and up-to-date information on the website(s) and in apps. However, SmartResQ/CorPatch<sup>®</sup> makes no warranties or representation as to the accuracy of the information. We assume no liability or responsibility for any errors or omissions in the website content, products, and apps. The information provided on this website or in apps are not a substitute for medical care. We assume responsibility for our products and apps according to the Medical Device Directive EU 745/2017 (MDR). </p>

 

<h3>Copyright </h3>

<p>All content included on this site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of SmartResQ/CorPatch<sup>®</sup> or its content suppliers and protected by international copyright laws. The compilation of all content on this site is the exclusive property of SmartResQ/CorPatch<sup>®</sup>, with copyright authorship for this collection by SmartResQ/CorPatch<sup>®</sup> and protected by international copyright laws. </p>

 

<h3>Registered trademarks and design </h3>

<p>SmartResQ/CorPatch<sup>®</sup> trademarks, trade dress and product design may not be used in connection with any product or service that is not SmartResQ/CorPatch<sup>®</sup>, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits SmartResQ/CorPatch<sup>®</sup>. All other trademarks not owned by SmartResQ/CorPatch<sup>®</sup> or its subsidiaries that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to or sponsored by SmartResQ/CorPatch<sup>®</sup> or its subsidiaries. </p>

 

<h3>License and site access </h3>

<p>SmartResQ/CorPatch<sup>®</sup> grants you a limited license to access and make personal use of our site(s) and apps and not to download (other than page caching) or modify it or any portion of it, except with express written consent of SmartResQ/CorPatch<sup>®</sup>. This license does not include any resale or commercial use of these site(s) or apps or its contents: (a) any collection and use of any product listings, descriptions, or prices: (b) any derivative use of this site or its contents: (c) any downloading or copying of account information for the benefit of another merchant: or (d) any use of data mining, robots, or similar data gathering and extraction tools.</p>

 

<p>This site or app or any portion of this site may not be reproduced, re-engineered, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of SmartResQ/CorPatch<sup>®</sup>. You may not frame or utilise framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of SmartResQ/CorPatch<sup>®</sup> and our associates without express written consent. </p>

 

<p>You may not use any meta tags or any other ‘hidden text’ utilising SmartResQ/CorPatch<sup>®</sup> name or trademarks without the express written consent of SmartResQ/CorPatch<sup>®</sup>. Any unauthorised use terminates the permission or license granted by SmartResQ/CorPatch<sup>®</sup>.</p>

 

<p>You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the home page of SmartResQ/CorPatch<sup>®</sup> so long as the link does not portray SmartResQ/CorPatch<sup>®</sup>, its associates or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any SmartResQ/CorPatch<sup>®</sup> logo or other proprietary graphic or trademark as part of the link without express written permission. 
<br>
If the agreement about license and site access is breached, legal action will be taken, and a lawsuit may be filed in court. </p>

<h3>Electronic communication </h3>

<p>When you visit the homepage and social media channels of SmartResQ/CorPatch<sup>®</sup> or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on our websites or in the apps in the form of pop-ups or notifications. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. Please refer to our Privacy Policy in a separate document that can be found on our homepage. </p>

 

<p>Visitors of our website and social media channels may be invited to post reviews, comments, and other content e.g. submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of ‘spam’. </p>

 

<p>You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. SmartResQ/CorPatch<sup>®</sup> reserves the right (but not the obligation) to remove or edit such content but does not regularly review posted content. If you do post content or submit material and unless we indicate otherwise, you grant SmartResQ/CorPatch<sup>®</sup> and its associates a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant SmartResQ/CorPatch<sup>®</sup> and its associates and sublicensees the right to use the name that you submit in connection with such content if they choose. </p>

 

<p>You represent and warrant that you own or otherwise control all the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify SmartResQ/CorPatch<sup>®</sup> or its associates for all claims resulting from content you supply. SmartResQ/CorPatch<sup>®</sup> has the right but not the obligation to monitor and edit or remove any activity or content. SmartResQ/CorPatch<sup>®</sup> takes no responsibility and assumes no liability for any content posted by you or any third party. </p>

</div> 


<!--This is the 4th section (white) - title: Disclaimers and warranties-->
<div class="white">
<h2>Disclaimers and warranties </h2>

<h3>Product information – CorPatch<sup>®</sup> and CorPatch<sup>®</sup> Trainer </h3>

<p>For correct use of any SmartResQ/CorPatch<sup>®</sup> product or service you must always follow the latest user guides, instructions, and descriptions. These can be found on our website and under each product description. </p>

 

<p>The product CorPatch<sup>®</sup> is certified as a medical device. In case of an emergency with an adult person suffering from cardiac arrest, the product must be taken out of its enclosure, placed, and attached correctly on the chest of the victim. The product can collect data on compression, depth, frequency, and recoil and might transmit this via Bluetooth<sup>®</sup> to potential devices. Free apps on devices may guide the bystander to exercise CPR and show flow fraction, depth, recoil, and frequency if activated and correctly installed i.e., Bluetooth<sup>®</sup>, smartphone, app, battery power, etc. SmartResQ/CorPatch<sup>®</sup> is not responsible for external factors, e.g., interfering communication signals, lack of data coverage, lack of battery, incorrect hardware, or software settings, that may affect the user experience or the like. </p>

 

			   
<p>Similarly, SmartResQ/CorPatch<sup>®</sup> is not responsible for any physical harm caused during the use of the product in case it is not applied or used according to the instructions, e.g., performing chest compressions in a non-ergonomic way or having an unsuitable hand position. SmartResQ/CorPatch<sup>®</sup> has assessed technical risks as part of the legally prescribed risk management for medical devices, but no guarantee is given for errors going beyond this. In the event of unexpected malfunctions or occurring of non-plausible behaviour of a SmartResQ/CorPatch<sup>®</sup> system, the user is required to perform CPR manually. In this case, SmartResQ/CorPatch<sup>®</sup> is not liable as it is out of the control of SmartResQ/CorPatch<sup>®</sup>. 
<br>
SmartResQ/CorPatch<sup>®</sup> monitors battery levels and state-of-health, but if the battery runs out of power, the product will not work. It is solely the responsibility of the user to ensure that the device is up to date, not harmed and has battery enough to work correctly, which can be done easily by executing a training that validates correct functionality. We recommend training for 4 minutes every 3 months with your CorPatch<sup>®</sup>. </p>

 

<p><b>Important!</b> The product CorPatch<sup>®</sup> must exclusively be used on a person in a real-life situation and only if a person suffers from cardiac arrest. It is not intended to be used on persons suffering from e.g., stroke, heart attack, or other health-related illnesses that are not cardiac arrest. The CorPatch<sup>®</sup> is not intended for use if the person is lying on a soft surface e.g., a couch or bed, as the depth feedback during such conditions might be inaccurate. No SmartResQ/CorPatch<sup>®</sup> solutions are intended for use in a moving environment including but not limited to an air, sea, or road ambulance. If used during patient transport or lifted/removed from the body during CPR, the device may provide inaccurate feedback. The CorPatch<sup>®</sup> must be attached to the chest of the patient with the patch. Make sure that the patient is lying on a firm, plane and not moving surface and that the CorPatch<sup>®</sup> is attached to the chest with the patch. </p>

 

   
<p>The product CorPatch<sup>®</sup> Trainer must exclusively be used on manikins or similar objects in training sessions and never in a real-life situation on a real person suffering from cardiac arrest or any other health-related illness. </p>

 

<p>It is possible to train your CPR-performance in relation to chest compressions using CorPatch<sup>®</sup> or CorPatch<sup>®</sup> Trainer regularly. We recommend that you train on a manikin, but if you do not have access to a manikin, an office chair with sufficient recoil or a hard couch can be used as a replacement. Consider the elasticity and hardness of the object you train with. SmartResQ does not recommend using soft objects including but not limited to pillows or soft couches when training, as user experience will not be right. </p>



<p>If you cannot locate a manikin or a replacement object for training, consider training the situation that happens before you start chest compressions. This could be following the information given by the free app on identification of a cardiac arrest and calling emergency services and unpacking the CorPatch<sup>®</sup> from the key hanger. In this case, you will be ready to apply the CorPatch<sup>®</sup> quickly in case you witness a cardiac arrest. </p>

 

<p>Do not use any SmartResQ/CorPatch<sup>®</sup> system for training or ‘for fun’ on any persons (living or dead), pets or any other living human being or creature. </p>

 

<h3>Products, website(s), and apps disclaimer </h3>

<p>SmartResQ/CorPatch<sup>®</sup> makes no representations or warranties and shall not be responsible for the competency of any person who may receive educational information and/or medical training provided through or based on the system or for the exercise of his or her skills by such person after completion of any training, courses or curriculum using the system. SmartResQ/CorPatch<sup>®</sup> does not guarantee that any person receiving educational information and/or medical training from the system will achieve any particular skill level or the necessary proficiency to qualify for any license, certificates, or ratings issued by any regulatory agency or government authority. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> and its associates attempt to be as accurate as possible. However, SmartResQ/CorPatch<sup>®</sup> makes no representation and gives no warranty that the system and the information, products, and training provided herein: (a) will be available always or available at all; or (b) is free of error, complete, accurate, true, up to date, and/or non-misleading. When you use the system, you know and are aware that you waive any claim you may have against SmartResQ/CorPatch<sup>®</sup> for reliance on any information or training presented through the system. </p>

 

<p>Our products are not intended for use by children. The products are small and colourful and can be mistaken for toys, but SmartResQ/CorPatch<sup>®</sup> products are not toys! We recommend not to leave the products out in the open and accessible to children. SmartResQ/CorPatch<sup>®</sup> takes no responsibility in relation to children’s use of the products. Any use of CorPatch<sup>®</sup> by children or teenagers must be allowed and monitored by responsible adults, e.g., parents, family member, or teachers. </p>

 


<p>Disclaimer of warranties and limitation of liability, this site is provided by SmartResQ/CorPatch<sup>®</sup> on an ‘as is’ and ‘as available’ basis. SmartResQ/CorPatch<sup>®</sup> makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, or products included on this site. You expressly agree that your use of this site is at your sole risk. 
<br>
To the full extent permissible by applicable law, SmartResQ/CorPatch<sup>®</sup> disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. SmartResQ/CorPatch<sup>®</sup> does not warrant that this site, its servers, or e-mail sent from SmartResQ/CorPatch<sup>®</sup> are free of viruses/spam or other harmful components. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> will not be liable for any damages of any kind arising from the use of this site or its products, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.
<br>
Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions or limitations may not apply to you, and you might have additional rights. </p>

 

<p>The information presented on this website should not be construed as professional advice. You should always consult with professional advisors familiar with your factual situation for advice concerning specific matters before making any decision. </p>

 

<p>The website may contain links which lead to websites maintained by individuals or organisations over which SmartResQ/CorPatch<sup>®</sup> has no control. SmartResQ/CorPatch<sup>®</sup> makes no representations and provides no warranties regarding the accuracy or any other aspect of the information located on such websites. </p>

 

<p>The responsibility for any opinions, advice, statements, or other information contained in any articles or texts on this website resides solely with the author and may not necessarily reflect opinions and policies of SmartResQ/CorPatch<sup>®</sup> itself. </p>



<h3>Legal disclaimer</h3>

<p>By purchasing, licensing, viewing, using, and/or accessing our website(s), products, and apps, you acknowledge and agree that: </p>
<ol>
<li>The systems provided by SmartResQ/CorPatch<sup>®</sup> are specific products to be used solely for the intended use stated in the manual of the product. Please carefully read the user instructions and manuals, and make sure you are familiar with our medical products before taking into use.  </li>

<li>The systems provided by SmartResQ/CorPatch<sup>®</sup> are specific educational and medical training products and tools are not certified as a medical device unless explicitly stated, they are not intended for any clinical or diagnostic use and intended to be used solely for medical training and performance improvement purposes. </li>

<li>At all times you will use and access the system solely in connection with such medical training and performance improvement purposes; in compliance with all applicable laws and regulations; and in accordance with any user documentation, instruction manuals, guides, and/or requirements we provide to you electronically or in person. </li>

<li>At no time can any SmartResQ/CorPatch<sup>®</sup> system alone diagnose, treat, or cure a human being’s condition or in a life-saving situation; support professional medical decisions, diagnosis or treatments or replace any diagnosis, recommendation, advice, treatment, or decision by an appropriately trained and licensed physician. </li>

<li>The association of a device with any patient harm or outcomes does not mean that the device caused the harm or outcome. </li>

<li>SmartResQ/CorPatch<sup>®</sup> takes no responsibility for harm resulting from unreasonable use of our products or use beyond the intended use of the product. </li>
</ol>
   

<h3>Performance information </h3>

<p>The information contained herein is presented only as a guide for application of SmartResQ/CorPatch<sup>®</sup> products. SmartResQ/CorPatch<sup>®</sup> is continually working to improve the quality and reliability of its products. Nevertheless, our devices can malfunction or fail due to their inherent electrical sensitivity and vulnerability to physical stress and adverse communication signals. It is the responsibility of the buyer, when utilising SmartResQ/CorPatch<sup>®</sup> products, to observe standards of safety and testing and to avoid situations in which a malfunction or failure could result in bodily injury or damage to property. </p>

 

<h3>Standard warranty </h3>

<p>SmartResQ/CorPatch<sup>®</sup> Standard Limited Warranty is conditioned upon proper use of the products, website(s), and apps. 
<br>
This Warranty is confined to the first purchaser of the product only and only if the product is purchased from an authorized SmartResQ/CorPatch<sup>®</sup> dealer. Manufacturers, suppliers, or publishers, other than SmartResQ/CorPatch<sup>®</sup>, may provide their own warranties to you – please contact them for further information. </p>

 

<p><u>The warranty does not cover:</u></p>
<ol>
<li>defects or damage resulting from accident, misuse, abnormal use, abnormal conditions, improper storage, exposure to liquid, moisture, dampness, sand or dirt, neglect or unusual physical, electrical, or electromechanical stress, </li>

<li>defects or damage resulting from excessive force or use of a metallic objects, </li>

<li>equipment that has the production number or the enhancement data code removed, defaced, damaged, altered, or made illegible, </li>

<li>ordinary wear and tear or normal ageing of the product, </li>

<li>scratches, dents, and cosmetic damage, unless failure has occurred due to defect in materials or workmanship, </li>

<li>defects or damage resulting from the use of products in conjunction or connection with accessories, products or ancillary/peripheral equipment not furnished or approved by SmartResQ/CorPatch<sup>®</sup>, </li>

<li>defects or damage resulting from improper testing, operation, maintenance, installation, service, or adjustment not furnished or approved by SmartResQ/CorPatch<sup>®</sup>, </li>

<li>damage caused by operating the SmartResQ/CorPatch<sup>®</sup> product outside the published guidelines, </li>

<li>demonstration / installation of the product purchased, </li>

<li>defects or damage resulting from external causes such as collision with an object, fire, flooding, dirt, windstorm, lightning, earthquake, exposure to weather conditions, theft, blown fuse, or improper use of any electrical source, </li>

<li>defects or damage resulting from transmission or viruses, or other software problems introduced into the Products, </li>

<li>if the batteries are charged by chargers other than those compliant with CorPatch<sup>®</sup> Trainer, </li>

<li>any of the seals on the battery enclosure or cells are broken or show evidence of tampering, </li>

<li>products repaired by, used, or purchased from another company than SmartResQ/CorPatch<sup>®</sup>, </li>

<li>if SmartResQ/CorPatch<sup>®</sup> receives information from relevant public authorities that the product is stolen or if you are unable to deactivate passcode-enabled or other security measures designed to prevent unauthorised access to the product or you cannot prove that you are the authorised user of the product, </li>

<li>if the products are used outside of the specified conditions stated in the manuals, e.g., temperature range, pressure, and humidity. </li>
</ol>
 

<h3>Batteries and chargers </h3>

<p>SmartResQ/CorPatch<sup>®</sup> products contain either non-replaceable batteries (CorPatch<sup>®</sup>) or rechargeable batteries (CorPatch<sup>®</sup> Trainer). The types of batteries used in our products are described under each, individual product. SmartResQ/CorPatch<sup>®</sup> takes no responsibility if the rechargeable batteries are not handled correctly according to the manual for use. </p>

 

<p>In connection with the sale of devices that contain batteries, we are under obligation to bring the following to your attention: 
<br>
As the end user, you are legally under obligation to dispose them correctly. The symbol of the crossed-out dustbin means that the battery may not be discarded with the household waste. </p>
   
</div>
 
<!--This is the 5th section (beige) - title: Ordering -->
<div class="beige">
	
<h2>Ordering </h2>
<p>The Corpatch.com webshop is open 24 hours a day and you can buy at almost any time. However, we may close the shop for maintenance. Purchase in large volume may occur directly with SmartResQ/CorPatch<sup>®</sup>. </p>


<p>SmartResQ/CorPatch<sup>®</sup> does not offer products for sale to minors. Products intended for children can only be bought by adults. To purchase from SmartResQ/CorPatch<sup>®</sup> you must be at least 18 years old and possess a valid credit card or other means of payment that we accept. </p>

 

<p>The presentation of the products in the online shop is not a legally binding offer, but a non-binding online catalogue. When you are ready to shop, select the items you want to buy and put them in the ‘shopping cart’. You can edit the contents of the shopping cart right up to the time of order. Any extra payments such as shipping, or debit card charges will be calculated immediately before you pay. </p>

 

<p>When you are ready to order, click on ‘Checkout’ and enter relevant information. You can change the contents of the shopping cart right up until you confirm your purchase by clicking on the button ‘Pay’. After this, you place a binding order for the goods contained in the shopping cart that can no longer be changed. 
<br>
SmartResQ/CorPatch<sup>®</sup> can accept the order by sending an order confirmation by e-mail or by delivering the goods within the delivery period. </p>

 

<p>Certain countries may prevent the use and ownership of our products. You are solely responsible for finding out whether this product is legal to import and/or use in your country. We will send you the products you order and cannot accept any liability for customs issues or any implications of your ownership or usage of this device. </p>

   
 </div>

<!--This is the 6th section (white) - title: Payment-->
<div class="white">
	
<h2>Payment </h2>
<h3>Our website(s), app(s), and webshop(s) </h3>

<p>Our website(s) and app(s) are free to use as long as our legal policies are accepted and complied with. Be aware that purchases of our products might be available in the webshop(s) on our website(s) and in app(s). </p>

 

<h3>Our products </h3>

<p>SmartResQ/CorPatch<sup>®</sup> uses QuickPay as a payment gateway. QuickPay is certified by the Payment Card Industry (PCI) Security Standards Council following the latest release of PCI Data Security Standard (DSS) Level 1, which includes: (a) an annual report - ‘Report on Compliance’ (ROC) conducted by a Qualified Security Assessor (QSA), (b) quarterly network scans performed by Approved Scan Vendor (ASV), and (c) a large number of rules and guidelines for workflow and the processing of data. </p>

   

<p>We accept payment by: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>Mastercard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
		   

<p>Payments will be deducted from your account upon shipment of goods. All amounts are in Euro and the statutory value added tax is already included in all the prices mentioned. All credit/debit card holders are subject to validation checks and authorisation by the card issuer or payment provider. SmartResQ/CorPatch<sup>®</sup> is not responsible in the event that your payment card provider refuses to authorise payments. </p>

 























 

<p>We use encryption for credit card information through the Secure Socket Layer (SSL) protocol. This ensures that others cannot intercept the credit card number or other information during the transaction with our provider. </p>

 

<p>Before finishing the contract, the customer must review and accept the shipping and logistic costs added to the purchase price, as the customer is charged these costs. After placing an order, you will receive an e-mail from us acknowledging that we have received your order. Please note that this does not mean that your order has been accepted. Your order constitutes an offer to SmartResQ/CorPatch<sup>®</sup> to buy a product (and SmartResQ/CorPatch<sup>®</sup> reserves the right to refuse orders for products). No contract will exist in relation to the products until we have confirmed to you by e-mail that the product(s) has been dispatched. Our acceptance to your offer will be deemed complete and the contract between us shall be formed when we send you a dispatch confirmation mail. </p>

 

<p>The shipping costs are always shown in relation to each individual order. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> retains ownership of the purchased item until the invoice amount has been fully paid by the customer and automatically ‘drawn’ just before shipping. </p>

 

<p>Customers are only entitled to offset if their counterclaims have been legally established or are undisputed or recognised by SmartResQ/CorPatch<sup>®</sup>. In addition, customers only have a right of retention if and insofar as their counterclaim is based on the same contractual relationship. </p>

 

<p>If the customer is in arrears with us with any payment obligations, all existing claims become due immediately. </p>

 

<h3>Surcharging fees </h3>

<p>As per 1 January 2018, the rules regarding surcharging have been changed. Therefore, it is no longer legal to surcharge fees on payments from consumer cards if they are issued by banks/card issuers within the EU. This applies for both debit and credit cards. Consumer cards are cards issued to a private consumer. 
<br>
However, if the card is either a corporate card or consumer card issued outside the EU, the transaction fee will be surcharged. This means that the cardholder pays the transaction fee automatically. 
<br>
The fee will not be higher than what SmartResQ/CorPatch<sup>®</sup> is charged by the acquirer. The fee will clearly be shown as a separate post in the payment window. </p>


<!--This is the 7th section (beige) - title: Delivery-->
<div class="beige">

<h2>Delivery </h2>
<p>We endeavour to ship orders from working day to working day and use an internationally trusted shipping agency. You will find the total price for your purchase including delivery at checkout before you accept your final order. </p>

 

<p>If the customer does not accept the goods, SmartResQ/CorPatch<sup>®</sup> can withdraw from the contract or receive compensation for non-performance after a period of two weeks to cover handling and shipping costs. </p>

 

<p>If the customer has given incorrect details about the address for delivery, it is possible to pick up the package at the package shop named in our webshop, otherwise the package is lost. </p>

 

<p>The customer will never receive partial deliveries unless explicitly stated by SmartResQ/CorPatch<sup>®</sup>. </p>

 

<h3>Risk of loss </h3>

<p>The risk ownership of the product is transferred to the buyer when the product is made available to the buyer according to this agreement. If the time of delivery has passed and the buyer fails to receive a product that is made available for him/her or at his/her disposal according to the agreement, the buyer holds the risk of loss or damage caused by characteristics of the product itself. </p>
																																						   
   
</div>
 


<!--This is the 8th section (white) - title: Cancellation and returns-->
<div class="white">   
<h2>Cancellation and returns </h2>

<p>When you shop with SmartResQ/CorPatch<sup>®</sup> online or offline, you have 14 days to regret and cancel where you can inform us that you have changed your mind and return the item to us in the same state as received. 
<br>
We only accept returns of unused items in original, sealed, and unharmed packaging and they must be properly packed for shipment, otherwise the products will be considered used, and no partial refunds will apply. The cancellation right only applies to unsealed products upon receipt. <p>

 

<h3>Acceptable reasons for returning a product </h3>
<ul>
<li>Use of the 14 days cancellation right </li>

<li>Product not as described (warranty) </li>

<li>Product is faulty </li>
</ul>
 

<p>Terms for returning CorPatch<sup>®</sup> is following the standard EU rules. </p>

 

 

<p>If returning the product, keep the original packaging and do not damage, stick on or write on it. Obtain and use a dedicated return packaging, e.g., cardboard box. </p>

 

<p>For CorPatch<sup>®</sup> Trainer, the withdrawal terms for this product are following the standard EU rules. </p>

 

<p>To exercise the right to cancellation, you must notify us within 14 days of receipt of the items. Cancellation requests shall be sent by e-mail to info@corpatch.com, clearly stating that you wish to use your cancellation rights and why. </p>

 

<p>We expect you to return the items as soon as possible after you have given cancellation notice and no later than 14 days after you have informed us by e-mail. </p>

 

<p>We can reject the repayment until you have returned the items or proven that you have returned the items. For this repayment we will use the same payment method as used in the original transaction. </p>
 

<h3>Non-acceptable reasons for returning a product </h3>
<ul>
<li>Change of mind after the 14-day cancellation right. </li>

<li>If the product has been activated. </li>

<li>If the product is used or harmed in any other way. </li>

<li>If the software/free app is downloaded, connected to, installed, or in any other way combined with the physical product(s). </li>
</ul>
 

<h3>How to return </h3>

<p>We only accept returns of unopened items in original and unharmed packaging, and they must be properly packed for shipment, otherwise the products will be considered used and no refunds will apply. </p>

 

<p><u>Unless otherwise stated, returns must be sent to:</u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg 
<br>
Denmark 
</p>
 

<p><b>IMPORTANT!</b> You are sole responsible for the packaging quality and the items until received by us. Please keep the postal receipt including information on shipping costs and if applicable the track and trace number. We do not cover return shipping costs and we do not accept packages sent ‘Cash on Delivery (COD)’ or similar. 
																																 
</div>
 
<!--This is the 9th section (beige) - title: Refunds-->
<div class="beige">
<h2>Refunds </h2>

<p>SmartResQ/CorPatch<sup>®</sup> is obliged to repair, replace, or give a price reduction or a full refund if the item turns out to be faulty within 2 years after you purchased the item. 
<br>
The customer is not entitled to a refund if the problem is minor, such as scratches on the item or the like. 
<br>
Once SmartResQ/CorPatch<sup>®</sup> receives the customer’s item, a refund is initiated. The value of the refund amount depends on the state of the products when received at SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>The way the customer’s refund is processed depends on the original payment method. If the customer paid by credit or debit card, refunds will be sent to the card-issuing bank within 5 business days of receipt of the returned item or cancelation request. Please contact the card-issuing bank with questions about when the credit will be posted to your account. </p>
																														 
   
</div>
   

<!--This is the 10th section (white) - title: Data processing of personal information-->
<div class="white">
<h2>Data processing of personal information </h2>

<p>At SmartResQ/CorPatch<sup>®</sup> we value your personal data through our three-step data compliance effort: a. keeping a detailed map of our dataflow, b. undertaking legal assessment based on the dataflow in order to, c. implement the necessary security measures to keep your data safe. </p>

 

<p>In order to use our webshop you must at least give the following information: </p>
<ul>
<li>Name </li>
<li>Address </li>
<li>E-mail address </li>
<li>Phone number </li>
</ul>
 

<p>The collection of personal customer information takes place within the framework of existing legislation and EU General Protection Regulation (GDPR) legislation. </p>

 

<p>If you want to know more about our processing of your personal data, please read our Privacy Policy. </p>
   
</div>
 
<!--this is the 11th section (beige) - title: Complaints-->
<div class="beige">
<h2>Complaints </h2>

<h3>How to complain to us </h3>

<p>If there is something wrong with the product, you can either have a faulty product repaired or replaced, receive a refund or reduction in price, depending on the specific situation. 
<br>
Of course, it is a requirement that the complaint is justified and that the defect is not caused by incorrect use of the product or other incorrect conduct. </p>

 

<p>We recommend that you claim as soon as possible and within one week of the defect being discovered.  
<br>
Please do not hesitate to contact us with any question, comment, or complaints by contacting us via e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<h3>Complain to other entities within the EU </h3>

<p>Please visit the official website of the European Union to complain to other entities within the EU. Find information <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">here</a>.</p>
   
</div>
 
<!--this is the 12th section (white) - title: Changes to this document-->
<div class="white">
<h2>Changes to this document </h2>

<h3>On website(s), app(s), and policies </h3>

<p>SmartResQ/CorPatch<sup>®</sup> reserves the right to make changes, delete, modify, or supplement our website(s), app(s), policies and documents at any time for any reason without any notice to anyone. 
<br>
If any of these conditions shall be deemed invalid, void or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. </p>

 

<p>If we change our policies, we will publish the revised policies online with an updated revision date. We encourage you to review the policies regularly. If we make material changes to our policies that significantly changes our policy practices, we may also notify you in other ways, for example, by sending an e-mail or by posting a notice on our website and/or social media before the changes take effect. </p>

 

<h3>Relating a specific purchase </h3>

<p>When purchasing a product, you will be asked to accept a version of certain documents as they are at that exact point in time – that version will not be changed after that point in time and will dictate the terms of our relationship with you regarding that exact purchase. </p>
   
</div>
 
<!--this is the 13th section (beige)- title: Law and jurisdiction -->
<div class="beige">
<h2>Law and jurisdiction </h2>

<h3>The laws of Denmark and the District Court of Svendborg </h3>

 

<p>SmartResQ/CorPatch<sup>®</sup> applies Danish law and venue for any legal disputes but not CISG. 
<br>
Any dispute relating in any way to your visit to SmartResQ/CorPatch<sup>®</sup> or to products you purchase through SmartResQ/CorPatch<sup>®</sup> shall be submitted to confidential in Denmark, except that, to the extent you have in any manner violated or threatened to violate SmartResQ/CorPatch<sup>®</sup> intellectual property rights, SmartResQ/CorPatch<sup>®</sup> may seek injunctive or other appropriate relief in any country and you consent to exclusive jurisdiction and venue in such courts. </p>

 

<p>If the ‘Terms and Conditions’ agreement is breached, legal action will be taken, and a lawsuit may be filed in court. </p>

<p>Disputes between us and any consumer is subject to the District Court of Svendborg, Christiansvej 41, 5700 Svendborg, Denmark. </p>


</div>
 
   
<!--this is the 14th section (white) title: Contact information-->
<div class="white">
<h2>Contact information </h2>

<p>Thank you for reading SmartResQ/CorPatch<sup>®</sup> ‘Terms and Conditions’. 
<br>
If you have any questions, comments, or complaints, please feel free to contact us. </p>

 

<p>Our company is located at: Lundevej 26, 5700 Svendborg, Denmark </p>

 

<p>You can find us in the Central Business Register under VAT No. DK 38674102 </p>

 

<p>You can call us on Telephone number +45 62 200 100 </p>

 

<p>or </p>

<p>e-mail us at: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<p><b>© SmartResQ ApS – All rights reserved 
<br>
Denmark, Version 2.1 – Issued 2023.04.25 </b></p>
</div>

<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->
   
   
   
   
<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Privacy Policy -->
<div class="beige">
<h1 id="policy">SmartResQ / CorPatch<sup>®</sup> Privacy Policy </h1>
   
 <!--This is the 16th section (beige) = joined together with the 15th section - title: We Handle your data in compliance with GDPR-->
<h2>We handle your data in compliance with GDPR </h2>

<p>SmartResQ/CorPatch<sup>®</sup> respects your privacy. This privacy statement describes your privacy rights and our commitment to secure your personal information. </p>

<p>If you have any questions about our processing of your personal data, please contact us. The data controller is: </p>

<p><b>Company:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Address:</b>
<br>
Lundevej 26 
<br>
5700 Svendborg 
<br>
Denmark </p>

<p><b>CVR No.:</b> 38674102 </p>

<p><b>Telephone no.:</b> +45 62 200 100 </p>

<p><b>E-mail:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> is a Danish/European company with legal entities, business processes, management structures and technical systems, across national borders. SmartResQ/CorPatch<sup>®</sup> provides products, software and services to public/private companies in Europe. </p>

<p>The head office is located in Denmark and SmartResQ/CorPatch<sup>®</sup> is subject to European data protection legislation, including the General Data Protection Regulation (GDPR). All major decisions in SmartResQ/CorPatch<sup>®</sup> concerning the protection of personal data is taken at Management Level under the supervision of the Data Protection Officer. </p>

<p>This privacy statement is available on our websites and in our apps. </p>

<p>Please do not use SmartResQ/CorPatch<sup>®</sup> pages, apps or our services if you do not agree to the way we process personal data under this privacy statement. </p>
</div>
 
 
   <!--This is the 17th section (white) - title: The type of personal information we collect   -->
    <div class="white">
<h2>The type of personal information we collect  </h2>
																																																																													
																																							  

<p>When a handler determines the purposes and means of the processing of your personal data, the handler shall act as the controller. This includes scenarios where SmartResQ/CorPatch<sup>®</sup> collects personal data in the context of you being a job seeker, a representative of a customer or a lead, or when you are a software user. </p>
																																																																		
																																																													   

<p>SmartResQ is processing personal information for a variety of purposes, depending on the relationship we have with you. </p>

<p>We may process: </p>
<ol>
<li>basic contact information, such as name, address, phone number (mobile and/or landline) and e-mail, </li>

<li>employment information such as employer, title, position, including preferences and interests in a professional context, </li>
		  

																							
					   
														
<li>feedback, comments or questions about SmartResQ/CorPatch<sup>®</sup> or our products and services, </li>

<li>photos or video recorded at our sites, </li>
																														   

<li>content you have uploaded, such as photos, videos and performance over time, </li>
																																																																										  
					   
						
											   
												  
						 
						
																		   
																											 

<li>unique user information such as login ID, username, password and security question, </li>
						 
						
																													  

<li>financial information, where you agree to us using your information e.g. for storing your payment card details, </li>
						 
						
												  
																																		

<li>traffic information provided by your web browser, such as the type of browser, device, language and the address of the website you came from and other traffic information including IP address, </li>
							
																																										
																											  
						 
						
													
																																		

<li>clickstream-behaviour and activity on SmartResQ/CorPatch<sup>®</sup> IDs and in our products and services, </li>
						 
						
														
																											 
						 
						
				
		  

<li>e-mail behaviour, such as e-mail messages from SmartResQ/CorPatch<sup>®</sup> you open when and how, </li>
					   
										 
																																					
					
																																								
																											
			
																																											   
																																										

<li>other personal information contained in your profile that you have freely placed on third party social networks, such as LinkedIn, Facebook, Google etc., </li>

<li>information used for scientific purposes to improve survival after cardiac arrest collected via our website(s) and apps, </li>
					   
											
																																	  

<li>information about users to deliver products to comply with quality and safety requirements, to provide services to the users, and to maintain and improve our offerings, </li>
																																									  
																																				   
					
																																																															 
																																						  
																																														  
																																											  
																																																										 

<li>information about job applicants to handle job applications, to communicate about future job offerings and to maintain and improve our recruiting processes, </li>
		  

<li>information about persons that have signed up to receive newsletters and other materials to deliver the materials and to maintain and improve our offerings, </li>
					   
								  
																																											  
																																																																						 

<li>cookie information to provide tailored advertising on social media and websites. </li>
</ol>
</div>
<!--This is the 18th section (beige) - tile: Data collected and handled in the CorPatch<sup>®</sup> Services platform and apps-->
<div class="beige">
<h2>Data collected and handled in the CorPatch<sup>®</sup> Services platform and apps </h2>

<p>SmartResQ handle, collect and store the following personal data when you use CorPatch<sup>®</sup> Services platform or apps. </p>

<h3>All users (Institute Admin, Trainer, Trainee/end user) </h3>
																																																  
<ul>
<li>First name (if entered) </li>

<li>Last name (if entered) </li>

<li>Nicknames (if entered) </li>

<li>E-mail address (mandatory) </li>

<li>Preferred language of communication (mandatory) </li>
					   
												   
																																																								

<li>Password hash (mandatory) </li>

<li>Whether the e-mail address has been validated (mandatory) </li>
</ul>
 
		  

<h3>Additionally for trainees/end users (mandatory) </h3>
					   
											 
																																																								   

<p>Data on the mobile phone used: </p>

<p>Operating system (Android/iOS): </p>
<ul>
<li>Operating system version (e.g. 9) </li>

<li>Manufacturer (e.g. Samsung) </li>

<li>Model (e.g. SM-T518) </li>

<li>App version (e.g. 1.2.4) </li>

<li>Time of the last foreground activity of the app </li>

<li>Time of the last background activity of the app </li>
</ul>

<p>Data on the CorPatch<sup>®</sup> (CPS) used: </p>
<ul>
<li>Serial number/MAC address </li>

<li>Firmware version </li>

<li>Model name (e.g. CPS_01) </li>

<li>Manufacturer (currently always SRQ) </li>

<li>Name (currently always CorPatch<sup>®</sup>) </li>

<li>Battery condition </li>

<li>Defects </li>
</ul>
 

<p>User onboarding data: </p>
<ul>
<li>Tutorial completed (yes/no) </li>

<li>Terms of use accepted (yes/no) </li>

<li>Self-assessment completed (yes/no) </li>

<li>Test training completed (yes/no) </li>

<li>First login successful (yes/no) </li>

<li>Was a CPS connected (yes/no) </li>
</ul>
 

<p>Data collected through training: </p>
<ul>
<li>Date, time, and length of the training </li>

<li>Training result </li>

<li>Training type or training settings </li>

<li>In case of a training within an institute, additional information about the course, trainer, and institute </li>
</ul>
 
 

<h3>Server logs </h3>

<p>The following data is stored in the web server logs: </p>
<ul>
<li>IP address of the accessing party </li>

<li>Browser version of the accessing party </li>

<li>Date/time of access </li>

<li>URL of access </li>
</ul>
<p>External services that process data: </p>
<ul>
<li>Google/Firebase for remote logging, crash and error analysis </li>

<li>Google/Firebase for sending notifications </li>

<li>Sendgrid for sending e-mails </li>

<li>Hetzner Online GmbH for hosting web backend and database </li>
</ul>
 

<h3>What happens when a user is deleted </h3>
<ul>
<li>The user deletes himself/herself from our system on the CorPatch<sup>®</sup> Services homepage <a href="https://app.corpatch.com">https://app.corpatch.com</a>.</li>

<li>The user is marked as deleted. After that he can no longer log in, is no longer visible for admins, etc. but the user still exists in the database. </li>

<li>After 14 days the user’s data is automatically deleted from the database. </li>

<li>For the purpose of scientific evaluation and functionality improvement, data of trainings and use of CorPatch<sup>®</sup> will still exist in the database after deletion of the user, but the reference (the ID) to the user will be empty, and all references to personal data will be removed. </li>
</ul>
</div>
<!--This is the 19th section (white) - tile: How we collect your personal information-->
<div class="white">
<h2>How we collect your personal information </h2>

<p>Most of the personal information we process is provided to us directly by you. We collect data and process data when you: </p>
<ul>
<li>register online or place an order for any of our products or services; e.g. demographic data, e-mail address, payment information, items, order amount, discount level and frequency. Including sending transactional e-mails, e.g. order confirmation, shipping confirmation and refund confirmation, </li>

<li>engage in the sent communication (e-mail, SMS, direct mail or phone); e.g. open rate, click rate and time spend reading the e-mails, sender domain and type of e-mail client, </li>

<li>voluntarily complete a customer survey or provide feedback on any of our message boards or via e-mail. </li>
</ul>
 

<p>We might also receive personal information indirectly, from the following sources in the following scenarios: </p>
<ul>
<li>From cookies: when you visit our websites or apps; e.g. IP address, country, viewed pages, viewed products, interaction/clicks and searches. </li>

<li>From you or any other person affiliated with our customer. These people can be a manager or a colleague. If the customer you are working for buys products or services from SmartResQ/CorPatch<sup>®</sup> through a partner company to SmartResQ/CorPatch<sup>®</sup>, we may collect information about you from the partner company. </li>

<li>SmartResQ/CorPatch<sup>®</sup> Marketing partners, public sources or social networks of third parties. </li>

<li>SmartResQ/CorPatch<sup>®</sup> will be able to combine personal data about you collected from one source with information obtained from another source. This gives us a more complete picture of you, which also allows us to serve you in a more relevant way with a higher level of personalization. </li>
</ul>
</div>

<!--This is the 20th section (beige) - title: How we use your data-->
<div class="beige">
<h2>How we use your data </h2>

<p>In order to generally manage our customer relationships and to meet our customer commitments, SmartResQ/CorPatch<sup>®</sup> needs information about you in your role as a customer or when you use a service. The purposes of processing such personal data are: </p>
<ul>
<li>Process your order, manage your account, </li>
<li>e-mail you with special offers on other products and services we think you might like, </li>

<li>performing the sales and contract process for customers, </li>

<li>to deliver the requested products and services to customers, </li>

<li>to conduct deliveries in accordance with agreements concluded with you or customers, </li>

<li>to offer support to users of our products and services, </li>

<li>improve and develop the quality, functionality and user experience of our products, services and SmartResQ/CorPatch<sup>®</sup> website(s) and apps, </li>

<li>detect, limit, and prevent security threats and perform maintenance and troubleshooting and debugging, </li>

<li>preventing the misuse of our products and services, </li>

<li>to process orders, invoicing, payments or another financial follow-up, </li>

<li>create interest profiles to promote relevant products and services, </li>

<li>to establish user communities to educate and to facilitate interaction between users and SmartResQ/CorPatch<sup>®</sup>. </li>
</ul>
 

<h3>About leads </h3>

<p>SmartResQ/CorPatch<sup>®</sup> processes personal data on leads for marketing purposes. In order to provide targeted and relevant content to prospective customers, SmartResQ/CorPatch<sup>®</sup> builds an interest profile based on your activity and your choices and actions on SmartResQ/CorPatch<sup>®</sup> pages, as well as your response to marketing content. The legal basis for such processing is primarily your consent. </p>

<h3>About jobseekers </h3>

<p>If you are an applicant for jobs, we process personal data to evaluate your potential as a SmartResQ/CorPatch<sup>®</sup> employee. Our secure, online career platform ensures that we comply with the latest laws and regulations in relation to data privacy. The legal basis for such processing is your consent. </p>

<h3>About website visitors </h3>

<p>In order to monitor access to our sites, we process personal information about visitors. The processing is based on our legitimate interest in protecting our business secrets, employees, locations and you as a visitor. You will be informed of your rights in this context when you register with our electronic system for visitors. </p>

<p>To improve quality of CPR, especially through CPR training, SmartResQ/CorPatch<sup>®</sup> might share your data with our partner companies (Training Institutes) so that they may offer you their products and services. </p>

<p>When we process your order, our system may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases. </p>
</div>
<!-- This is 21st section (white) - title: How we store your personal information -->
<div class="white">
<h2>How we store your personal information </h2>

<p>SmartResQ/CorPatch<sup>®</sup> takes the trust you and our customers show us very seriously. SmartResQ/CorPatch<sup>®</sup> undertakes to avoid unauthorized access, disclosure or other aberrant processing of personal data. SmartResQ/CorPatch<sup>®</sup> shall ensure the confidentiality of the personal data we process, maintain the integrity of personal data and ensure its accessibility in accordance with applicable privacy laws. </p>

<p>As part of our commitments, we take reasonable and adequate organizational, technical and physical procedures and measures to protect the information we collect and process. We are taking into account the type of personal data and the risk that our customers are exposed to by any security breach, as there is a high likelihood that the root causes of personal data breaches can be found internally, we believe that the building of a strong corporate culture in which respect and vigilance about data protection among our employees is fundamental to ensuring the legal processing and protection of your information. In case of a data breach, SmartResQ/CorPatch<sup>®</sup> will follow practices laid out by the Danish Datatilsynet. </p>

<p>Your information is securely stored according to GDPR regulations. </p>
</div>
<!--This is the 22nd section (beige) - title: How long do we keep your personal data?-->
<div class="beige">
<h2>How long do we keep your personal data? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> retains your personal data only for as long as it is necessary for the purposes stated, while taking into account our need to respond to queries and resolve issues and comply with legal requirements under applicable law. </p>

<p>This means that SmartResQ/CorPatch<sup>®</sup> may store your personal information for a reasonable period after your and our customer’s last interaction with us. When the personal data we have collected is no longer necessary, we delete it. We may process data for statistical and/or scientific purposes, but in such cases the data will be pseudonymized or anonymized. </p>

 
 

<h3>Time frame for data storage </h3>

<p>We will retain your personal information for the period necessary to fulfil the purposes outlined in these Privacy Policy unless a longer retention period is required or permitted by law, for legal, tax or regulatory reasons or other legitimate and lawful business purposes. </p>

<table>
<tr>
<td><b>Purpose </b></td>
<td><b>Time frame </b></td>
</tr>

<tr>
<td>Customer service and accounting regulation </td>

<td>5 years or as long as we are obligated to meet the needed legal requirement.
<br>
We delete your registration data as soon as you delete your user account with us, or once the statutory retention period expires. 
</td>
</tr>

<tr>
<td>Career platform </td>
<td>6 months for application documents that do not result in hiring of the job applicant.
<br>
Renewal each 6 months if you wish to be considered for future job openings and you consent to longer storage period. 
</td>
</tr>

<tr>
<td>Marketing purpose </td>
<td>3 years after your latest activity e.g. visit on our websites, purchase or engagement in communication.
<br>
If you have given your marketing permission (e-mail, SMS, phone etc.) and as long as we have your permission to contact you. 
<br> 
We delete your e-mail address in newsletter automatically, once you delete your user account or if you unsubscribe from our newsletter.  
</td>
</tr>

<tr>
<td>Storage of order history and obligations to fulfil orders </td>

<td>5 years or as long as we are obligated to meet the needed legal requirements  </td>
</tr>
<tr>
<td>Customer experience </td>

<td>3 years after your latest activity e.g. visit on our websites, purchase or engagement in communication.
<br> 
If you have given your marketing permission (e-mail, SMS, phone etc) and as long as we have your permission.
</td>
</tr>

<tr>
<td>Fraud risk assessment </td>

<td>5 years or as long as we are obligated to meet the needed legal requirement. </td>
</tr>
</table>
</div>
 
 
<!--This is the 23rd section (white) - title: Marketing communications-->
<div class="white">
<h2>Marketing communications </h2>

<p>You have the right to opt out of receiving marketing communications from SmartResQ/CorPatch<sup>®</sup> and can do it either by: </p>
<ul>
<li>following the opt-out instructions in the relevant marketing communications, </li>

<li>changing preferences under the relevant editing section for the account, if you have an account with SmartResQ/CorPatch<sup>®</sup>,</li>

<li>contacting us by e-mail at <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</li>
</ul>
 

<p>Please note that even if you opt out of receiving marketing communications, you may still receive administrative messages from SmartResQ/CorPatch<sup>®</sup>, such as order confirmations and notices necessary to manage your account or the services provided to our customers through e.g. mobile services or apps. </p>
</div>
<!--This is the 24th section (beige) - title: Your data protection rights-->
<div class="beige">
<h2>Your data protection rights </h2>

<p>It is important for SmartResQ/CorPatch<sup>®</sup> that you are fully aware of all your data protection rights. </p>

<p>Some data protection laws, including the European Union’s General Data Protection Regulation (GDPR), corresponding legislation in Germany Bundesdatenschutzgesetz (BDSG), in Switzerland and in the United Kingdom, and some U.S. state laws, provide you with certain rights in connection with personal data you have shared with us. If you are a resident in the European Economic Area, you may have the following rights: </p>
<ol>
<li><b>Your right of access</b> – You have the right to ask us for copies of your personal information. </li>

<li><b>Your right to rectification</b> – You have the right to request that we correct any personal information you believe is inaccurate. You also have the right to request us to complete information you believe is incomplete.  </li>

<li><b>Your right to erasure</b> – You have the right to request us to erase your personal information under certain conditions.  </li>

<li><b>Your right to restrict processing</b> – You have the right to request us to restrict the processing of your personal information under certain conditions. </li>

<li><b>Your right to object to processing</b> – You have the right to object to the processing of your personal information under certain conditions. </li>

<li><b>Your right to data portability</b> – You have the right to request that we transfer the personal information that we have collected to another organisation, or directly to you under certain conditions. </li>
</ol>
 

<p>If you would like to exercise any of these rights, please contact us at our e-mail <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>
</div>
<!--This is the 25th section (white) - title: What are cookies?-->
<div class="white">
<h2>What are cookies? </h2>

<p>Cookies are small text files that contain a character string and create a unique identifier for a user. They are returned to the website and/or third parties. Most browsers are initially set up to accept cookies, since most websites are required to access them. However, you can change your browser settings so that your browser can generally reject cookies, block third-party cookies or specify when a cookie is being sent.</p>
 
 

<p>SmartResQ/CorPatch<sup>®</sup> is committed to ensuring your right to adjust your interests and to manage the scope of digital marketing from us through a system of preferential management. </p>
</div>
<!--This is the 26th section (beige) - title: How we use cookies-->
<div class="beige">
<h2>How we use cookies </h2>

<p>SmartResQ/CorPatch<sup>®</sup> uses cookies in a range of ways to improve your experience on our websites, apps and services for different reasons e.g.: </p>
<ul>
<li>Functionality – we use these cookies to recognize you on our website and remember your previously selected preferences. These would include that language you prefer and location you are in. A mix of first-party and third-party cookies are used. </li>

<li>Advertising – we use these cookies to collect information about your visit to our websites, app(s), the content you viewed, the links you followed and information about your browser, device and your IP address. Sometimes we share some limited aspects of this data with third parties for advertising purposes. We may also share online data collected through cookies with our advertising partners. This means that when you visit another website, you may be shown advertising based on your browsing patterns on our website. </li>
</ul>
</div>
<!--This is the 27th section (white) - title: The type of cookies we use-->
<div class="white">
<h2>The type of cookies we use </h2>

<p>Our website(s) use the following types of cookies: </p>
<ul>
<li><b>Google Analytics:</b> This cookie allows us to view information about website activities for users, including but not limited to page views, source, and time spent on a website. The information is anonymized and is displayed as numbers, which means that it cannot be traced back to individuals. This helps protect your privacy. By using Google Analytics, we can see what content is popular on our pages, and we strive to give you more of what you like to read and see. </li>

<li><b>Google Analytics remarketing:</b> Places cookies on your computer, which means that when you leave our site, Google may show you ads about SmartResQ/CorPatch<sup>®</sup> that you may be interested in based on your previous behavior on our website. This information is not personally identifiable. </li>

<li><b>Google Ads:</b> By using Google Ads, we are able to see which pages were helpful in leading to submissions via our contact form. This information is not personally identifiable, but the data provided in the contact form is. </li>

<li><b>Google Ads remarketing:</b> Places cookies on your computer, which means that when you leave our site, Google may show you ads about SmartResQ/CorPatch<sup>®</sup> that you may be interested in based on your previous behavior on our website. This information is not personally identifiable. </li>

<li><b>Facebook remarketing:</b> Facebook’s tracking pixel places cookies on your computer that tell Facebook that you’ve checked the site. We then assume that you have an interest in SmartResQ/CorPatch<sup>®</sup> and the content on this site. When you visit Facebook, you will then be exposed to information or advertisements with similar content. Please use your privacy settings on Facebook to limit the exposure to marketing of this type. </li>

<li><b>YouTube:</b> We integrate videos from the YouTube platform provided by Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Privacy policy: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</li>
</ul>
</div>
 
 
<!--This is the 28th section (beige) - title: How to manage cookies-->
<div class="beige">
<h2>How to manage cookies </h2>

<p>You can set your browser not to accept or to delete cookies. However, some of our website features may not function as a result. See how to avoid cookies in specific browsers: </p>
<ul>
<li>The US site: <a href="https://www.aboutads.info/choices">https://www.aboutads.info/choices</a></li>
<li>The EU site: <a href="https://www.youronlinechoices.com">https://www.youronlinechoices.com</a></li>
</ul>
 

<p>SmartResQ/CorPatch<sup>®</sup> uses some trusted third-party services on our pages. These services might use cookies. You can choose to reject third-party cookies in your browser by following this <a href="https://www.youronlinechoices.com/">link</a>. </p>

<p>You may prevent the information generated by the Google cookie about your use of our sites from being collected and processed by Google by downloading and installing the Google Analytics opt-out browser add-on for your current web browser. This add-on is available <a href="https://tools.google.com/dlpage/gaoptout">here</a>.  </p>
</div>
<!--This is the 29th section (white) - title: Privacy policies of other websites-->
<div class="white">
<h2>Privacy policies of other websites </h2>

<p>SmartResQ/CorPatch<sup>®</sup> websites contain links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy. </p>
</div>
<!--This is the 30th section (beige) - title: Changes to our privacy policy-->
<div class="beige">
<h2>Changes to our privacy policy </h2>

<p>If we change our privacy statement, we will publish the revised statement here with an updated revision date. We encourage you to review the statement regularly. If we make material changes to our statement that significantly changes our privacy practices, we may also notify you in other ways, for example, by sending an e-mail or by posting a notice on our website and/or social media before the changes take effect. </p>
</div>
<!--This is the 31st section (white) - title: How to contact SmartResQ ApS-->
<div class="white">
<h2>How to contact SmartResQ ApS </h2>

<p>If you have any questions about SmartResQ/CorPatch<sup>®</sup> privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. </p>

<p>E-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Website: <a href="https://corpatch.com">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: How to contact the appropriate authority-->
<div class="beige">
<h2>How to contact the appropriate authority </h2>

<p>Should you wish to report a complaint or if you feel that SmartResQ/CorPatch<sup>®</sup> has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office (ICO). </p>

<p>The ICO’s address: 
<br><br>
Information Commissioner’s Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>

 

<p>Helpline number: +44 303 123 1113 </p>

<p>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – All rights reserved 
<br>
Denmark, Version 1.3 – Issued 2023.04.25 </b></p>

</div>
</div>
</body>
</html>`

export const termsOfUse_de_DE: string = `<!DOCTYPE html>
<html lang="de">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
        
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
        
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>

<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 
    
    <!--This is the 1st section - title: Allgemeine Geschäftsbedingungen-->
    <div class="top-section">
        <h1><a href="#terms">Allgemeine Geschäftsbedingungen</a> & <a href="#policy">Datenschutzrichtlinie</a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
    </div>
<!--This is the 2nd section (white) - title: Zweck dieses Dokuments and Wie du SmartResQ kontaktierst -->
<div class="white">
<h1 id="terms">Allgemeine Geschäftsbedingungen – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Zweck dieses Dokuments </h2>

<p>Willkommen bei SmartResQ/CorPatch<sup>®</sup>! </p>

 

<p>Du liest gerade unsere Allgemeinen Geschäftsbedingungen; ein Dokument mit Informationen über deine Beziehung zu SmartResQ ApS, die begonnen hat, als du unsere Website besucht, unsere kostenlose(n) App(s) heruntergeladen, eines unserer Produkte gekauft oder ähnliches getan hast. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> und unsere Partner stellen dir unsere Produkte und Dienstleistungen zu den folgenden Bedingungen zur Verfügung. Wenn du unsere Website(s) oder App(s) nutzt oder dort einkaufst, akzeptierst du diese Bedingungen. Wir empfehlen dir dringend, sie sorgfältig zu lesen, bevor du unsere Produkte oder Dienstleistungen nutzt. </p>

 

<p>Dieses Dokument umfasst: </p>
<ul>
<li>Wie du SmartResQ kontaktierst </li>

<li>Produkte, Eigenschaften und ordnungsgemäße Nutzung </li>

<li>Haftungsausschlüsse und Garantien </li>

<li>Bestellung </li>

<li>Zahlung </li>

<li>Lieferung </li>

<li>Verantwortlichkeiten und Rechte in Bezug auf den Verkauf </li>

<li>Rücksendungen </li>

<li>Datenverarbeitung persönlicher Daten </li>

<li>Beschwerden </li>

<li>Änderungen an diesem Dokument </li>

<li>Recht und Gerichtsbarkeit </li>

<li>Kontaktinformationen </li>

 
</ul>
<p>Wenn du Fragen zu den Allgemeinen Geschäftsbedingungen von SmartResQ hast, kontaktiere uns bitte. </p>

 

<h2>Wie du SmartResQ kontaktierst </h2>

<p>Das Ziel von SmartResQ ist es, die Überlebenschancen von Menschen mit Herzstillstand durch qualifizierte HLW zu erhöhen. Wir wollen Umstehende darauf vorbereiten und anleiten, in den ersten Minuten nach einem Herzstillstand zu handeln. Deshalb haben wir CorPatch<sup>®</sup> entwickelt – Wir helfen dir, um ein Leben zu retten. </p>

 

<p>Unser Unternehmen befindet sich in Lundevej 26, 5700 Svendborg, Dänemark </p>

 

<p>Du findest uns im Zentralen Unternehmensregister unter der Umsatzsteuernummer DK 38674102 </p>

 

<p>Du kannst uns anrufen unter der Telefonnummer +45 62 200 100 </p>

 

<p>oder </p>

 

<p>schreib uns eine E-Mail an: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

 

<h3>Hast du Fragen, Kommentare oder Beschwerden? </h3>

<p>Wir freuen uns über deine Meinung, also kontaktiere uns bitte über die oben genannten Kontaktinformationen oder benutze das Kontaktformular auf unserer/unseren Website(s) oder App(s). </p>

 
</div>

<!--This is the 3rd section (beige) - title: Produkte, Eigenschaften und ordnungsgemäße Nutzung-->
<div class="beige">
<h2>Produkte, Eigenschaften und ordnungsgemäße Nutzung </h2>

<h3>Unsere Website(s), Apps und Produkte </h3>

<p>Die folgenden Abschnitte beschreiben wesentliche Eigenschaften, Rechte und Pflichten im Zusammenhang mit der Nutzung der Dienste, Apps und Produkte von SmartResQ/CorPatch<sup>®</sup>. 
<br>
Wir bemühen uns nach Kräften, auf der/den Website(s) und in den Apps genaue und aktuelle Informationen bereitzustellen. SmartResQ/CorPatch<sup>®</sup> gibt jedoch keine Garantien oder Zusicherungen hinsichtlich der Richtigkeit der Informationen. Wir übernehmen keine Haftung oder Verantwortung für Fehler oder Auslassungen in den Inhalten der Website, Produkte und Apps. Die auf dieser Website oder in den Apps bereitgestellten Informationen sind kein Ersatz für eine medizinische Versorgung. Wir übernehmen die Verantwortung für unsere Produkte und Apps gemäß der Medizinprodukterichtlinie EU 745/2017 (MDR).</p> 

 

<h3>Copyright </h3>

<p>Alle auf dieser Website enthaltenen Inhalte wie Texte, Grafiken, Logos, Schaltflächensymbole, Bilder, Audioclips, digitale Downloads, Datenzusammenstellungen und Software sind das Eigentum von SmartResQ/CorPatch<sup>®</sup> oder deren Inhaltslieferanten und durch internationale Urheberrechtsgesetze geschützt. Die Zusammenstellung aller Inhalte auf dieser Seite ist das ausschließliche Eigentum von SmartResQ/CorPatch<sup>®</sup>, wobei die Urheberschaft für diese Sammlung bei SmartResQ/CorPatch<sup>®</sup> liegt und durch internationale Urheberrechtsgesetze geschützt ist. </p>

 

<h3>Eingetragene Marken und Design </h3>

<p>SmartResQ/CorPatch<sup>®</sup> Marken, Handelsaufmachungen und Produktdesigns dürfen nicht in Verbindung mit einem Produkt oder einer Dienstleistung verwendet werden, die nicht von SmartResQ/CorPatch<sup>®</sup> stammt, oder in einer Weise, die bei den Kunden zu Verwechslungen führen kann oder SmartResQ/CorPatch<sup>®</sup> in Verruf bringt oder diskreditiert. Alle anderen Marken, die nicht im Besitz von SmartResQ/CorPatch<sup>®</sup> oder seinen Tochtergesellschaften sind und auf dieser Seite erscheinen, sind das Eigentum ihrer jeweiligen Besitzer, die mit SmartResQ/CorPatch<sup>®</sup> oder seinen Tochtergesellschaften verbunden sind oder von ihnen gesponsert werden können. </p>

 

<h3>Lizenz und Zugang zur Website </h3>

<p>SmartResQ/CorPatch<sup>®</sup> gewährt dir eine eingeschränkte Lizenz für den Zugriff und die persönliche Nutzung unserer Website(s) und Apps. Du darfst die Website oder Teile davon nicht herunterladen (außer zum Zwischenspeichern von Seiten) oder verändern, es sei denn, du hast die ausdrückliche schriftliche Genehmigung von SmartResQ/CorPatch<sup>®</sup>. Diese Lizenz umfasst nicht den Weiterverkauf oder die kommerzielle Nutzung dieser Website(s) oder Apps oder ihrer Inhalte: (a) jede Sammlung und Nutzung von Produktlisten, Beschreibungen oder Preisen: (b) jede abgeleitete Nutzung dieser Seite oder ihrer Inhalte: (c) jegliches Herunterladen oder Kopieren von Kontoinformationen zum Nutzen eines anderen Händlers: oder (d) jegliche Nutzung von Data Mining, Robotern oder ähnlichen Tools zur Datensammlung und -extraktion.</p>

 

<p>Diese Website oder App oder Teile davon dürfen ohne ausdrückliche schriftliche Zustimmung von SmartResQ/CorPatch<sup>®</sup> nicht reproduziert, nachgebaut, vervielfältigt, kopiert, verkauft, weiterverkauft, besucht oder anderweitig für kommerzielle Zwecke genutzt werden. Du darfst ohne ausdrückliche schriftliche Zustimmung von SmartResQ/CorPatch<sup>®</sup> und unseren Partnern keine Framing-Techniken verwenden, um Marken, Logos oder andere urheberrechtlich geschützte Informationen (einschließlich Bilder, Text, Seitenlayout oder Form) einzubinden. </p>

 

<p>Du darfst ohne die ausdrückliche schriftliche Zustimmung von SmartResQ/CorPatch<sup>®</sup> keine Meta-Tags oder anderen „versteckten Text“ verwenden, der den Namen oder die Marken von SmartResQ/CorPatch<sup>®</sup> nutzt. Jede unbefugte Nutzung beendet die von SmartResQ/CorPatch<sup>®</sup> erteilte Erlaubnis oder Lizenz.</p>

 

<p>Dir wird ein begrenztes, widerrufliches und nicht-exklusives Recht gewährt, einen Hyperlink zur Homepage von SmartResQ/CorPatch<sup>®</sup> zu erstellen, solange der Link SmartResQ/CorPatch<sup>®</sup>, seine Partner oder deren Produkte oder Dienstleistungen nicht in einer falschen, irreführenden, abwertenden oder anderweitig anstößigen Weise darstellt. Du darfst ohne ausdrückliche schriftliche Genehmigung kein SmartResQ/CorPatch<sup>®</sup> Logo oder eine andere geschützte Grafik oder Marke als Teil des Links verwenden. 
<br>
Bei einem Verstoß gegen die Vereinbarung über die Lizenz und den Website-Zugang werden rechtliche Schritte eingeleitet, und es kann eine Klage vor Gericht eingereicht werden. </p>

<h3>Elektronische Kommunikation </h3>

<p>Wenn du die Homepage und die Social-Media-Kanäle von SmartResQ/CorPatch<sup>®</sup> besuchst oder E-Mails an uns schickst, kommunizierst du elektronisch mit uns. Du erklärst dich damit einverstanden, dass wir dir auf elektronischem Wege Nachrichten zusenden. Wir kommunizieren mit dir per E-Mail oder durch Hinweise auf unseren Websites oder in den Apps in Form von Pop-ups oder Benachrichtigungen. Du erklärst dich damit einverstanden, dass alle Vereinbarungen, Mitteilungen, Bekanntmachungen und andere Kommunikationen, die wir dir elektronisch zukommen lassen, alle gesetzlichen Anforderungen an die Schriftform erfüllen. Unsere Datenschutzrichtlinie findest du in einem separaten Dokument auf unserer Homepage. </p>

 

<p>Besucher unserer Website und unserer Social-Media-Kanäle können Bewertungen, Kommentare und andere Inhalte, z. B. Vorschläge, Ideen, Kommentare, Fragen oder andere Informationen einreichen, solange der Inhalt nicht illegal, obszön, bedrohlich, verleumderisch, die Privatsphäre verletzend, geistige Eigentumsrechte verletzend oder anderweitig Dritten gegenüber verletzend oder anstößig ist und nicht aus Software-Viren, politischen Kampagnen, kommerzieller Werbung, Kettenbriefen, Massenmailings oder irgendeiner Form von „Spam“ besteht oder diese enthält. </p>

 

<p>Du darfst keine falsche E-Mail-Adresse verwenden, dich als eine andere Person oder Organisation ausgeben oder auf andere Weise über die Herkunft einer Karte oder anderer Inhalte täuschen. SmartResQ/CorPatch<sup>®</sup> behält sich das Recht (aber nicht die Pflicht) vor, solche Inhalte zu entfernen oder zu bearbeiten, überprüft aber nicht regelmäßig die eingestellten Inhalte. Wenn du Inhalte einstellst oder Inhalte einreichst, gewährst du SmartResQ/CorPatch<sup>®</sup> und seinen Partnern ein nicht-exklusives, lizenzfreies, unbefristetes, unwiderrufliches und vollständig unterlizenzierbares Recht, diese Inhalte weltweit in allen Medien zu nutzen, zu vervielfältigen, zu verändern, anzupassen, zu veröffentlichen, zu übersetzen, davon abgeleitete Werke zu erstellen, zu verbreiten und darzustellen, sofern wir nichts anderes angeben. Du gewährst SmartResQ/CorPatch<sup>®</sup> und seinen Partnern und Unterlizenznehmern das Recht, den Namen, den du in Verbindung mit solchen Inhalten einreichst, zu verwenden, wenn sie dies wünschen. </p>

 

<p>Du erklärst und garantierst, dass du alle Rechte an dem von dir eingestellten Inhalt besitzt oder anderweitig kontrollierst; dass der Inhalt korrekt ist; dass die Verwendung des von dir bereitgestellten Inhalts nicht gegen diese Richtlinie verstößt und keine Personen oder Unternehmen verletzt; und dass du SmartResQ/CorPatch<sup>®</sup> oder seine Partner für alle Ansprüche, die sich aus dem von dir bereitgestellten Inhalt ergeben, schadlos halten wirst. SmartResQ/CorPatch<sup>®</sup> hat das Recht, aber nicht die Pflicht, jegliche Aktivitäten oder Inhalte zu überwachen und zu bearbeiten oder zu entfernen. SmartResQ/CorPatch<sup>®</sup> übernimmt keine Verantwortung und keine Haftung für Inhalte, die von dir oder einer dritten Partei eingestellt werden. </p>

 
</div>
<!--This is the 4th section (white) - title: Haftungsausschlüsse und Garantien-->
<div class="white">
<h2>Haftungsausschlüsse und Garantien </h2>

<h3>Produktinformation – CorPatch<sup>®</sup> und CorPatch<sup>®</sup> Trainer </h3>

<p>Für die korrekte Nutzung eines Produkts oder Dienstes von SmartResQ/CorPatch<sup>®</sup> musst du immer die aktuellen Benutzerhandbücher, Anweisungen und Beschreibungen beachten. Diese findest du auf unserer Website und unter jeder Produktbeschreibung. </p>

 

<p>Das Produkt CorPatch<sup>®</sup> ist als Medizinprodukt zertifiziert. Bei einem Notfall mit einer erwachsenen Person, die einen Herzstillstand erleidet, muss das Produkt aus seinem Gehäuse genommen und korrekt auf der Brust des Opfers platziert und befestigt werden. Das Produkt kann Daten über Kompression, Tiefe, Frequenz und Rückstoß sammeln und diese möglicherweise über Bluetooth<sup>®</sup> an mögliche Geräte übertragen. Kostenlose Apps auf den Geräten können den Umstehenden bei der Durchführung der HLW anleiten und Flow-Anteil, Tiefe, Rückstoß und Frequenz anzeigen, wenn sie aktiviert und richtig installiert sind, d. h. Bluetooth<sup>®</sup>, Smartphone, App, Batteriestrom usw. SmartResQ/CorPatch<sup>®</sup> ist nicht verantwortlich für externe Faktoren, z. B. gestörte Kommunikationssignale, mangelnde Datenabdeckung, fehlende Batterie, falsche Hardware- oder Softwareeinstellungen, die das Benutzererlebnis beeinträchtigen können oder ähnliches. </p>

 

<p>Ebenso ist SmartResQ/CorPatch<sup>®</sup> nicht für körperliche Schäden verantwortlich, die während der Verwendung des Produkts entstehen, wenn es nicht gemäß den Anweisungen angewendet oder benutzt wird, z. B. wenn du die Herzdruckmassage nicht ergonomisch durchführst oder eine ungeeignete Handhaltung einnimmst. SmartResQ/CorPatch<sup>®</sup> hat die technischen Risiken im Rahmen des gesetzlich vorgeschriebenen Risikomanagements für Medizinprodukte bewertet, übernimmt aber keine Garantie für darüber hinausgehende Fehler. Bei unerwarteten Fehlfunktionen oder nicht plausiblem Verhalten eines SmartResQ/CorPatch<sup>®</sup> Systems muss der Benutzer die HLW manuell durchführen. In diesem Fall ist SmartResQ/CorPatch<sup>®</sup> nicht haftbar, da dies außerhalb der Kontrolle von SmartResQ/CorPatch<sup>®</sup> liegt. </p>

<p>SmartResQ/CorPatch<sup>®</sup> überwacht den Batteriestand und den Gesundheitszustand, aber wenn die Batterie leer ist, funktioniert das Produkt nicht. Es liegt in der alleinigen Verantwortung des Benutzers, dafür zu sorgen, dass das Gerät auf dem neuesten Stand ist, nicht beschädigt wird und über genügend Batterie verfügt, um korrekt zu funktionieren. Dies kann ganz einfach durch ein Training geschehen, das die korrekte Funktionsweise bestätigt. Wir empfehlen, alle 3 Monate ein 4-minütiges Training mit deinem CorPatch<sup>®</sup> durchzuführen. </p>

 

<p><b>Wichtig!</b> Das Produkt CorPatch<sup>®</sup> darf ausschließlich an einer Person in einer realen Situation und nur dann verwendet werden, wenn eine Person einen Herzstillstand erleidet. Es darf nicht bei Personen verwendet werden, die z. B. an einem Schlaganfall, Herzinfarkt oder anderen gesundheitlichen Erkrankungen leiden, die nicht mit einem Herzstillstand einhergehen. Der CorPatch<sup>®</sup> darf nicht verwendet werden, wenn die Person auf einer weichen Oberfläche liegt, z. B. auf einer Couch oder einem Bett, da die Tiefenrückmeldung unter solchen Bedingungen ungenau sein könnte. Die SmartResQ/CorPatch<sup>®</sup> Lösungen sind nicht für die Verwendung in einer sich bewegenden Umgebung vorgesehen, z. B. in einem Krankentransport in der Luft, auf dem Wasser oder auf der Straße. Wenn das Gerät während des Patiententransports verwendet oder während der HLW vom Körper gehoben/entfernt wird, kann es ungenaue Rückmeldungen geben. Der CorPatch<sup>®</sup> muss mit dem Patch auf der Brust des Patienten/der Patientin befestigt werden. Vergewissere dich, dass der Patient/die Patientin auf einer festen, ebenen und unbeweglichen Unterlage liegt und dass das CorPatch<sup>®</sup> mit dem Patch auf dem Brustkorb befestigt ist. </p>

 

<p>Das Produkt CorPatch<sup>®</sup> Trainer darf ausschließlich an Übungspuppen oder ähnlichen Objekten in Training Sessions verwendet werden und niemals in einer realen Situation an einer echten Person, die einen Herzstillstand oder eine andere gesundheitsbezogene Krankheit hat. </p>

 

<p>Mit dem CorPatch<sup>®</sup> Trainer kannst du deine HLW-Leistung in Bezug auf Herzdruckmassagen regelmäßig trainieren. Wir empfehlen dir, an einer Übungspuppe zu trainieren. Wenn du jedoch keinen Zugang zu einer Übungspuppe hast, kann ein Bürostuhl mit ausreichendem Rückstoß oder eine harte Liege als Ersatz verwendet werden. Achte auf die Elastizität und Härte des Objekts, mit dem du trainierst. SmartResQ rät davon ab, weiche Gegenstände, wie z. B. Kissen oder weiche Sofas, für das Training zu verwenden, da der/die Benutzer/in dann nicht die richtige Erfahrung macht. </p>


<p>Wenn du keine Übungspuppe oder keinen Ersatzgegenstand für das Training finden kannst, solltest du die Situation trainieren, die eintritt, bevor du mit der Herzdruckmassage beginnst. Das könnte das Befolgen der Informationen der kostenlosen App zur Erkennung eines Herzstillstands und zum Absetzen eines Notrufs und das Auspacken des CorPatch<sup>®</sup> aus dem Schlüsselanhänger sein. In diesem Fall bist du bereit, den CorPatch<sup>®</sup> schnell anzuwenden, falls du Zeuge eines Herzstillstands wirst. </p>

 

<p>Verwende das SmartResQ/CorPatch<sup>®</sup> System nicht zum Training oder „zum Spaß“ an Personen (lebend oder tot), Haustieren oder anderen Lebewesen. </p>

 

<h3>Haftungsausschluss für Produkte, Website(s) und Apps </h3>

<p>SmartResQ/CorPatch<sup>®</sup> gibt keine Zusicherungen oder Garantien ab und ist nicht verantwortlich für die Kompetenz einer Person, die durch das System oder auf der Grundlage des Systems pädagogische Informationen und/oder medizinisches Training erhält, oder für die Ausübung ihrer Fähigkeiten durch diese Person nach Abschluss eines Trainings, Kurses oder Lehrplans unter Verwendung des Systems. SmartResQ/CorPatch<sup>®</sup> garantiert nicht, dass eine Person, die Bildungsinformationen und/oder medizinisches Training aus dem System erhält, ein bestimmtes Fähigkeitsniveau oder die notwendigen Fähigkeiten erreicht, um sich für eine Lizenz, ein Zertifikat oder eine Bewertung zu qualifizieren, die von einer Aufsichtsbehörde oder einer Regierungsbehörde ausgestellt werden. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> und seine Mitarbeiter/innen versuchen, so genau wie möglich zu sein. SmartResQ/CorPatch<sup>®</sup> übernimmt jedoch keine Gewähr dafür, dass das System und die darin enthaltenen Informationen, Produkte und Trainings: (a) immer oder überhaupt verfügbar sind; oder (b) fehlerfrei, vollständig, genau, wahrheitsgemäß, aktuell und/oder nicht irreführend sind. Wenn du das System benutzt, ist dir bewusst, dass du auf jegliche Ansprüche gegenüber SmartResQ/CorPatch<sup>®</sup> verzichtest, die sich aus dem Vertrauen auf die über das System bereitgestellten Informationen oder Trainings ergeben könnten. </p>

 

<p>Unsere Produkte sind nicht für die Verwendung durch Kinder gedacht. Die Produkte sind klein und bunt und können mit Spielzeug verwechselt werden, aber SmartResQ/CorPatch<sup>®</sup> Produkte sind kein Spielzeug! Wir empfehlen, die Produkte nicht im Freien und für Kinder unzugänglich aufzubewahren. SmartResQ/CorPatch<sup>®</sup> übernimmt keine Verantwortung in Bezug auf die Verwendung der Produkte durch Kinder. Jegliche Nutzung von CorPatch<sup>®</sup> durch Kinder oder Jugendliche muss von verantwortlichen Erwachsenen, z. B. Eltern, Familienmitgliedern oder Lehrern, erlaubt und überwacht werden. </p>

 

<p>Haftungsausschluss und Haftungsbeschränkung, diese Seite wird von SmartResQ/CorPatch<sup>®</sup> auf einer „wie besehen“ und „wie verfügbar“ Basis bereitgestellt. SmartResQ/CorPatch<sup>®</sup> gibt keine ausdrücklichen oder stillschweigenden Zusicherungen oder Gewährleistungen in Bezug auf den Betrieb dieser Seite oder die Informationen, Material, Inhalte oder Produkte auf dieser Seite. Du erklärst dich ausdrücklich damit einverstanden, dass die Nutzung dieser Seite auf dein eigenes Risiko erfolgt. 
<br>
SmartResQ/CorPatch<sup>®</sup> lehnt, soweit dies nach geltendem Recht zulässig ist, alle ausdrücklichen oder stillschweigenden Garantien ab, einschließlich, aber nicht beschränkt auf stillschweigende Garantien der Marktgängigkeit und der Eignung für einen bestimmten Zweck. SmartResQ/CorPatch<sup>®</sup> garantiert nicht, dass diese Seite, ihre Server oder von SmartResQ/CorPatch<sup>®</sup> versandte E-Mails frei von Viren/Spam oder anderen schädlichen Komponenten sind. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ist nicht haftbar für Schäden jeglicher Art, die aus der Nutzung dieser Seite oder ihrer Produkte entstehen, einschließlich, aber nicht beschränkt auf direkte, indirekte, zufällige, strafende und Folgeschäden. 
<br>
In einigen Staaten sind Beschränkungen von stillschweigenden Garantien oder der Ausschluss oder die Beschränkung bestimmter Schäden nicht zulässig. Wenn diese Gesetze auf dich zutreffen, gelten einige oder alle der oben genannten Haftungsausschlüsse oder Beschränkungen möglicherweise nicht für dich, und du hast möglicherweise zusätzliche Rechte. <p>

 

<p>Die auf dieser Website enthaltenen Informationen sind nicht als professionelle Beratung zu verstehen. Bevor du eine Entscheidung triffst, solltest du immer professionellen Rat von einer Person einholen, die mit deiner tatsächlichen Situation vertraut ist, um dich in bestimmten Angelegenheiten beraten zu lassen. </p>

 

<p>Die Website kann Links enthalten, die zu Websites führen, die von Personen oder Organisationen unterhalten werden, über die SmartResQ/CorPatch<sup>®</sup> keine Kontrolle hat. SmartResQ/CorPatch<sup>®</sup> gibt keine Zusicherungen und Garantien in Bezug auf die Richtigkeit oder andere Aspekte der Informationen auf solchen Websites. </p>

 

<p>Die Verantwortung für Meinungen, Ratschläge, Aussagen oder andere Informationen, die in Artikeln oder Texten auf dieser Website enthalten sind, liegt allein beim Autor und spiegelt nicht notwendigerweise die Meinung und Grundsätze von SmartResQ/CorPatch<sup>®</sup> selbst wider. </p>

 

<h3>Rechtlicher Hinweis</h3>

<p>Durch den Kauf, die Lizenzierung, das Anzeigen, die Nutzung und/oder den Zugriff auf unsere Website(s), Produkte und Apps erkennst du an und stimmst zu, dass: </p>
<ol>
<li>Die von SmartResQ/CorPatch<sup>®</sup> bereitgestellten Systeme spezifische Produkte sind, die ausschließlich für den im Manual des Produkts angegebenen Verwendungszweck verwendet werden dürfen. Bitte lies die Benutzeranleitungen und Manuals sorgfältig durch und vergewissere dich, dass du mit unseren medizinischen Produkten vertraut bist, bevor du sie in Gebrauch nimmst.  </li>

<li>Die von SmartResQ/CorPatch<sup>®</sup> bereitgestellten Systeme sind spezifische Bildungs- und medizinische Schulungsprodukte und -instrumente, die nicht als Medizinprodukt zertifiziert sind, es sei denn, dies ist ausdrücklich vermerkt; sie sind nicht für klinische oder diagnostische Zwecke bestimmt und dienen ausschließlich dem medizinischen Training und der Leistungssteigerung. </li>

<li>Du wirst das System jederzeit ausschließlich in Verbindung mit solchen medizinischen Trainings- und Leistungsverbesserungszwecken verwenden und darauf zugreifen, unter Einhaltung aller geltenden Gesetzen und Vorschriften und in Übereinstimmung mit Benutzerdokumentationen, Bedienungsanleitungen, Guides und/oder Anforderungen, die wir dir elektronisch oder persönlich zur Verfügung stellen. </li>

<li>Ein SmartResQ/CorPatch<sup>®</sup> System kann allein zu keinem Zeitpunkt den Zustand eines Menschen oder in einer lebensrettenden Situation diagnostizieren, behandeln oder heilen, professionelle medizinische Entscheidungen, Diagnosen oder Behandlungen unterstützen oder eine Diagnose, Empfehlung, einen Ratschlag, eine Behandlung oder eine Entscheidung einer entsprechend ausgebildeten und zugelassenen medizinischen Fachkraft ersetzen. </li>

<li>Die Verbindung eines Geräts mit einem Schaden oder einem Ergebnis für den Patienten/die Patientin bedeutet nicht, dass das Gerät den Schaden oder das Ergebnis verursacht hat. </li>

<li>SmartResQ/CorPatch<sup>®</sup> übernimmt keine Verantwortung für Schäden, die aus einer unangemessenen Verwendung unserer Produkte oder einer Verwendung außerhalb des vorgesehenen Verwendungszwecks des Produkts resultieren. </li>

 
</ol>
<h3>Leistungsinformationen </h3>

<p>Die hier enthaltenen Informationen dienen lediglich als Guide für die Anwendung der SmartResQ/CorPatch<sup>®</sup> Produkte. SmartResQ/CorPatch<sup>®</sup> arbeitet kontinuierlich daran, die Qualität und Zuverlässigkeit seiner Produkte zu verbessern. Dennoch können unsere Geräte aufgrund ihrer inhärenten elektrischen Empfindlichkeit und Anfälligkeit für physischen Stress und ungünstige Kommunikationssignale Fehlfunktionen aufweisen oder ausfallen. Es liegt in der Verantwortung des Käufers, bei der Verwendung von SmartResQ/CorPatch<sup>®</sup> Produkten die Sicherheits- und Prüfstandards einzuhalten und Situationen zu vermeiden, in denen eine Fehlfunktion oder ein Ausfall zu Körperverletzungen oder Sachschäden führen könnte. </p>

 

<h3>Standard-Garantie </h3>

<p>Die eingeschränkte Standardgarantie von SmartResQ/CorPatch<sup>®</sup> ist an die ordnungsgemäße Nutzung der Produkte, der Website(s) und der Apps gebunden. 
<br>
Diese Garantie gilt nur für den/die Erstkäufer/in des Produkts und nur dann, wenn das Produkt von einem autorisierten SmartResQ/CorPatch<sup>®</sup> Händler erworben wurde. Andere Hersteller, Lieferanten oder Verlage als SmartResQ/CorPatch<sup>®</sup> können dir ihre eigenen Garantien anbieten – bitte kontaktiere sie für weitere Informationen. </p>

 

<p><u>Die Garantie gilt nicht für: </u></p>
<ol>
<li>Defekte oder Schäden, die durch Unfälle, unsachgemäßen Gebrauch, anormale Bedingungen, unsachgemäße Lagerung, Einwirkung von Flüssigkeiten, Feuchtigkeit, Nässe, Sand oder Schmutz, Vernachlässigung oder ungewöhnliche physische, elektrische oder elektromechanische Belastungen entstehen, </li>

<li>Defekte oder Schäden, die durch übermäßige Gewaltanwendung oder den Gebrauch von Metallgegenständen entstanden sind, </li>

<li>Geräte, bei denen die Produktionsnummer oder der Erweiterungsdatencode entfernt, verunstaltet, beschädigt, verändert oder unlesbar gemacht wurde, </li>

<li>normalen Verschleiß oder normale Alterung des Produkts, </li>

<li>Kratzer, Dellen und kosmetische Schäden, es sei denn, der Ausfall ist auf einen Material- oder Verarbeitungsfehler zurückzuführen, </li>

<li>Mängel oder Schäden, die durch die Verwendung der Produkte in Verbindung mit Zubehör, Produkten oder Zusatz-/Peripheriegeräten entstehen, die nicht von SmartResQ/CorPatch<sup>®</sup> geliefert oder genehmigt wurden, </li>

<li>Defekte oder Schäden, die durch unsachgemäße Tests, Betrieb, Wartung, Installation, Service oder Einstellung entstehen, die nicht von SmartResQ/CorPatch<sup>®</sup> geliefert oder genehmigt wurden, </li>

<li>Schäden, die durch den Betrieb des SmartResQ/CorPatch<sup>®</sup> Produkts außerhalb der veröffentlichten Richtlinien entstehen, </li>

<li>Vorführung/Installation des erworbenen Produkts, </li>

<li>Defekte oder Schäden, die auf äußere Ursachen zurückzuführen sind, wie z. B. Zusammenstoß mit einem Gegenstand, Feuer, Überschwemmung, Verschmutzung, Sturm, Blitzschlag, Erdbeben, Witterungseinflüsse, Diebstahl, durchgebrannte Sicherungen oder unsachgemäße Verwendung einer Stromquelle, </li>

<li>Mängel oder Schäden, die durch Übertragungen, Viren oder andere Softwareprobleme entstehen, die in die Produkte eingeschleust wurden, </li>

<li>wenn die Batterien mit anderen als den mit CorPatch<sup>®</sup> Trainer kompatiblen Ladegeräten geladen werden, </li>

<li>eine der Versiegelungen des Batteriegehäuses oder der Zellen gebrochen ist oder Anzeichen von Manipulation aufweist, </li>

<li>Produkte, die von einem anderen Unternehmen als SmartResQ/CorPatch<sup>®</sup> repariert, verwendet oder gekauft wurden, </li>

<li>wenn SmartResQ/CorPatch<sup>®</sup> von den zuständigen Behörden Informationen erhält, dass das Produkt gestohlen wurde, oder wenn du nicht in der Lage bist, den Passcode oder andere Sicherheitsmaßnahmen zu deaktivieren, die den unbefugten Zugriff auf das Produkt verhindern sollen, oder wenn du nicht nachweisen kannst, dass du der/die autorisierte Benutzer/in des Produkts bist, </li>

<li>wenn die Produkte außerhalb der in den Manuals angegebenen Bedingungen verwendet werden, z. B. Temperaturbereich, Druck und Feuchtigkeit. </li>

 
</ol>
<h3>Akkus und Ladegeräte </h3>

<p>SmartResQ/CorPatch<sup>®</sup> Produkte enthalten entweder nicht austauschbare Batterien (CorPatch<sup>®</sup>) oder wiederaufladbare Batterien (CorPatch<sup>®</sup> Trainer). Die Batterietypen, die in unseren Produkten verwendet werden, sind unter jedem einzelnen Produkt beschrieben. SmartResQ/CorPatch<sup>®</sup> übernimmt keine Verantwortung, wenn die wiederaufladbaren Batterien nicht entsprechend des Manuals behandelt werden. </p>

 

<p>Im Zusammenhang mit dem Verkauf von Geräten, die Batterien enthalten, sind wir verpflichtet, dich auf Folgendes hinzuweisen: 
<br>
Als Endbenutzer/in bist du gesetzlich verpflichtet, sie richtig zu entsorgen. Das Symbol der durchgestrichenen Mülltonne bedeutet, dass die Batterie nicht über den Hausmüll entsorgt werden darf. </p>

 
</div>
    <!--This is the 5th section (beige) - title: Bestellung -->
	<div class="beige">
	<h2>Bestellung </h2>

<p>Der Corpatch.com Onlineshop ist 24 Stunden am Tag geöffnet und du kannst fast jederzeit einkaufen. Es kann jedoch vorkommen, dass wir den Shop wegen Wartungsarbeiten schließen. Einkäufe in großen Mengen können direkt bei SmartResQ/CorPatch<sup>®</sup> getätigt werden. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> bietet keine Produkte zum Verkauf an Minderjährige an. Produkte, die für Kinder bestimmt sind, können nur von Erwachsenen gekauft werden. Um bei SmartResQ/CorPatch<sup>®</sup> zu kaufen, musst du mindestens 18 Jahre alt sein und eine gültige Kreditkarte oder ein anderes von uns akzeptiertes Zahlungsmittel besitzen. </p>

 

<p>Die Präsentation der Produkte im Online-Shop ist kein rechtsverbindliches Angebot, sondern ein unverbindlicher Online-Katalog. Wenn du einkaufen möchtest, wählst du die Artikel aus, die du kaufen möchtest, und legst sie in den „Einkaufswagen“. Du kannst den Inhalt des Warenkorbs bis zum Zeitpunkt der Bestellung bearbeiten. Alle zusätzlichen Zahlungen, wie z. B. Versand- oder Kreditkartengebühren, werden sofort berechnet, bevor du bezahlst. </p>

 

<p>Wenn du bereit bist zu bestellen, klickst du auf „Zur Kasse“ und gibst die relevanten Informationen ein. Du kannst den Inhalt des Warenkorbs bis zur Bestätigung deines Kaufs ändern, indem du auf die Schaltfläche „Bezahlen“ klickst. Danach gibst du eine verbindliche Bestellung für die im Warenkorb enthaltenen Waren ab, die nicht mehr geändert werden kann.
<br>
SmartResQ/CorPatch<sup>®</sup> kann die Bestellung durch Zusendung einer Auftragsbestätigung per E-Mail oder durch Auslieferung der Ware innerhalb der Lieferfrist annehmen. </p>

 

<p>Bestimmte Länder können die Verwendung und den Besitz unserer Produkte verbieten. Du bist allein dafür verantwortlich, herauszufinden, ob das Produkt in deinem Land legal eingeführt und/oder verwendet werden darf. Wir senden dir die von dir bestellten Produkte zu und übernehmen keine Haftung für Zollprobleme oder Auswirkungen, die sich aus dem Besitz oder der Nutzung dieses Geräts ergeben. </p>

     </div>

    <!--This is the 6th section (white) - title: Zahlung-->
    <div class="white">
<h2>Zahlung </h2>

<h3>Unsere Website(s), App(s) und Onlineshop(s) </h3>

<p>Die Nutzung unserer Website(s) und App(s) ist kostenlos, solange du unsere rechtlichen Bestimmungen akzeptierst und einhältst. Sei dir bewusst, dass du unsere Produkte möglicherweise in den Onlineshops auf unserer/unseren Website(s) und in der/den App(s) kaufen kannst. </p>

 

<h3>Unsere Produkte </h3>

<p>SmartResQ/CorPatch<sup>®</sup> nutzt QuickPay als Zahlungsgateway. QuickPay ist vom Payment Card Industry (PCI) Security Standards Council nach der neuesten Version des PCI Data Security Standard (DSS) Level 1 zertifiziert, was Folgendes beinhaltet: (a) einen jährlichen Bericht – „Report on Compliance“ (ROC), der von einem Qualified Security Assessor (QSA) erstellt wird; (b) vierteljährliche Netzwerkscans, die von Approved Scan Vendor (ASV) durchgeführt werden, und (c) eine große Anzahl von Regeln und Richtlinien für Arbeitsabläufe und die Verarbeitung von Daten. </p>

 

<p>Wir akzeptieren Zahlungen per: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>MasterCard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
 

<p>Die Zahlungen werden bei Versand der Ware von deinem Konto abgebucht. Alle Beträge sind in Euro angegeben und die gesetzliche Mehrwertsteuer ist in allen genannten Preisen bereits enthalten. Alle Kredit-/Debitkarteninhaber/innen unterliegen der Validierungsprüfung und Autorisierung durch den Kartenaussteller oder Zahlungsanbieter. SmartResQ/CorPatch<sup>®</sup> ist nicht verantwortlich für den Fall, dass dein Zahlungskartenanbieter sich weigert, Zahlungen zu autorisieren. </p>
 

<p>Wir verwenden eine Verschlüsselung der Kreditkartendaten durch das Secure Socket Layer (SSL) Protokoll. Dadurch wird sichergestellt, dass andere die Kreditkartennummer oder andere Informationen während der Transaktion mit unserem Anbieter nicht abfangen können. </p>

 

<p>Vor Vertragsabschluss muss der Kunde/die Kundin die zum Kaufpreis hinzugefügten Versand- und Logistikkosten überprüfen und akzeptieren, da diese Kosten dem Kunden/der Kundin in Rechnung gestellt werden. Nachdem du eine Bestellung aufgegeben hast, erhältst du von uns eine E-Mail, in der wir bestätigen, dass wir deine Bestellung erhalten haben. Bitte beachte, dass dies nicht bedeutet, dass deine Bestellung angenommen wurde. Deine Bestellung stellt ein Angebot an SmartResQ/CorPatch<sup>®</sup> zum Kauf eines Produkts dar (und SmartResQ/CorPatch<sup>®</sup> behält sich das Recht vor, Bestellungen für Produkte abzulehnen). Es besteht kein Vertrag in Bezug auf die Produkte, bis wir dir per E-Mail bestätigt haben, dass das/die Produkt(e) versandt wurde(n). Unsere Annahme deines Angebots gilt als vollständig und der Vertrag zwischen uns kommt zustande, wenn wir dir eine Versandbestätigungsmail schicken. </p>

 

<p>Die Versandkosten werden immer in Bezug auf jede einzelne Bestellung angegeben. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> behält sich das Eigentum an dem gekauften Artikel vor, bis der Rechnungsbetrag vom Kunden/von der Kundin vollständig bezahlt und kurz vor dem Versand automatisch „abgezogen“ wurde. </p>

 

<p>Kunden sind nur dann zur Aufrechnung berechtigt, wenn ihre Gegenforderungen rechtskräftig festgestellt, unbestritten oder von SmartResQ/CorPatch<sup>®</sup> anerkannt sind. Darüber hinaus haben Kunden nur dann ein Zurückbehaltungsrecht, wenn und soweit ihr Gegenanspruch auf demselben Vertragsverhältnis beruht. </p>

 

<p>Befindet sich der Kunde/die Kundin mit irgendwelchen Zahlungsverpflichtungen uns gegenüber im Rückstand, werden alle bestehenden Forderungen sofort fällig. </p>

 

<h3>Zuschläge für Gebühren </h3>

<p>Ab dem 1. Januar 2018 wurden die Regeln für die Erhebung von Zuschlägen geändert. Es ist daher nicht mehr zulässig, Aufschläge auf Zahlungen mit Verbraucherkarten zu erheben, wenn diese von Banken/Kartenausstellern innerhalb der EU ausgegeben werden. Das gilt sowohl für Debit- als auch für Kreditkarten. Verbraucherkarten sind Karten, die an eine/n private/n Verbraucher/in ausgegeben werden. </p>

<p>Handelt es sich jedoch um eine Unternehmenskarte oder eine Verbraucherkarte, die außerhalb der EU ausgegeben wurde, wird ein Aufschlag auf die Transaktionsgebühr erhoben. Das bedeutet, dass der/die Karteninhaber/in die Transaktionsgebühr automatisch bezahlt. 
<br>
Die Gebühr wird nicht höher sein als die Gebühr, die SmartResQ/CorPatch<sup>®</sup> vom Erwerber/von der Erwerberin berechnet wird. Die Gebühr wird im Zahlungsfenster deutlich als separater Posten angezeigt. </p>

     </div>

    <!--This is the 7th section (beige) - title: Lieferung-->
    <div class="beige">
<h2>Lieferung </h2>

<p>Wir bemühen uns, Bestellungen von Werktag zu Werktag zu versenden und arbeiten mit einer international vertrauenswürdigen Versandagentur zusammen. Den Gesamtpreis für deinen Einkauf einschließlich Lieferung findest du an der Kasse, bevor du deine endgültige Bestellung akzeptierst. </p>

 

<p>Nimmt der Kunde/die Kundin die Ware nicht an, kann SmartResQ/CorPatch<sup>®</sup> nach einer Frist von zwei Wochen zur Deckung der Bearbeitungs- und Versandkosten vom Vertrag zurücktreten oder Schadenersatz wegen Nichterfüllung erhalten. </p>

 

<p>Wenn der Kunde/die Kundin falsche Angaben zur Lieferadresse gemacht hat, ist es möglich, das Paket in dem in unserem Onlineshop genannten Paketshop abzuholen, andernfalls ist das Paket verloren. </p>

 

<p>Der Kunde/die Kundin erhält niemals Teillieferungen, es sei denn, dies wird von SmartResQ/CorPatch<sup>®</sup> ausdrücklich angegeben. </p>

 

<h3>Risiko des Verlusts </h3>

<p>Das Risiko des Eigentums an der Ware geht auf den/die Käufer/in über, wenn die Ware dem/der Käufer/in gemäß dieser Vereinbarung zur Verfügung gestellt wird. Wenn die Lieferfrist verstrichen ist und der Käufer/in ein Produkt, das ihm/ihr gemäß der Vereinbarung zur Verfügung gestellt wurde, nicht erhält, trägt der/die Käufer/in das Risiko des Verlusts oder der Beschädigung, die durch die Eigenschaften des Produkts selbst verursacht wurden. </p>

     </div>

    <!--This is the 8th section (white) - title: Stornierung und Rückgabe-->
    <div class="white">
<h2>Stornierung und Rückgabe </h2>

<p>Wenn du online oder offline bei SmartResQ/CorPatch<sup>®</sup> einkaufst, hast du 14 Tage Zeit, um den Kauf zu bedauern und zu widerrufen, indem du uns mitteilst, dass du es dir anders überlegt hast, und den Artikel in demselben Zustand zurückschickst, in dem du ihn erhalten hast. 
<br>
Wir akzeptieren nur Rücksendungen unbenutzter Artikel in der versiegelten und unbeschädigten Originalverpackung und sie müssen ordnungsgemäß für den Versand verpackt sein, andernfalls gelten die Produkte als gebraucht und es erfolgt keine Teilrückerstattung. Das Widerrufsrecht gilt nur für unversiegelte Produkte bei Erhalt. </p>

 

<h3>Akzeptable Gründe für die Rückgabe eines Produkts </h3>
<ul>
<li>Nutzung des 14-tägigen Widerrufsrechts </li>

<li>Das Produkt entspricht nicht der Beschreibung (Garantie) </li>

<li>Das Produkt ist fehlerhaft </li>
</ul>
 

<p>Die Bedingungen für die Rückgabe von CorPatch<sup>®</sup> richten sich nach den EU-Standardregeln. </p>

 

 

<p>Wenn du das Produkt zurückschickst, bewahre die Originalverpackung auf und beschädige, beklebe oder beschrifte sie nicht. Besorge dir eine geeignete Rücksendeverpackung, z. B. einen Karton, und verwende diese. </p>

 

<p>Für CorPatch<sup>®</sup> Trainer gelten die Standard-Widerrufsbedingungen für dieses Produkt gemäß den EU-Vorschriften. </p>

 

<p>Um von deinem Widerrufsrecht Gebrauch zu machen, musst du uns innerhalb von 14 Tagen nach Erhalt der Ware informieren. Widerrufsanfragen sind per E-Mail an info@corpatch.com, zu senden, aus der klar hervorgeht, dass du von deinem Widerrufsrecht Gebrauch machen möchtest und warum. </p>

 

<p>Wir erwarten, dass du die Artikel so schnell wie möglich zurückschickst, nachdem du den Widerruf erklärt hast, spätestens aber 14 Tage, nachdem du uns per E-Mail informiert hast. </p>

 

<p>Wir können die Rückzahlung verweigern, bis du die Artikel zurückgeschickt hast oder nachweisen kannst, dass du die Artikel zurückgeschickt hast. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das du bei der ursprünglichen Transaktion eingesetzt hast. </p>

 

<h3>Nicht akzeptable Gründe für die Rückgabe eines Produkts </h3>
<ul>
<li>Änderung der Meinung nach dem 14-tägigen Widerrufsrecht. </li>

<li>Wenn das Produkt aktiviert worden ist. </li>

<li>Wenn das Produkt auf irgendeine andere Weise benutzt oder beschädigt wird. </li>

<li>Wenn die Software/die kostenlose App heruntergeladen, verbunden, installiert oder auf andere Weise mit dem/den physischen Produkt(en) kombiniert wird. </li>
</ul>
 

<h3>Rückgabeverfahren </h3>

<p>Wir nehmen nur ungeöffnete Artikel in der unbeschädigten Originalverpackung zurück. Sie müssen für den Versand ordnungsgemäß verpackt sein, sonst gelten die Produkte als gebraucht und werden nicht erstattet. </p>

 

<p><u>Wenn nicht anders angegeben, müssen die Rücksendungen an folgende Adresse geschickt werden: </u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg 
<br>
Dänemark 
</p>
 

<p><b>WICHTIG!</b> Du bist allein für die Qualität der Verpackung und die Artikel verantwortlich, bis sie bei uns eingegangen sind. Bitte bewahre den Postbeleg auf, der Informationen zu den Versandkosten und ggf. die Track & Trace-Nummer enthält. Wir übernehmen keine Rücksendekosten und wir akzeptieren keine Pakete, die per Nachnahme oder ähnlichem verschickt werden. </p>

</div>

<!--This is the 9th section (beige) - title: Rückerstattungen-->
<div class="beige">
<h2>Rückerstattungen </h2>

<p>SmartResQ/CorPatch<sup>®</sup> ist verpflichtet, den Artikel zu reparieren, zu ersetzen oder einen Preisnachlass oder eine vollständige Rückerstattung zu gewähren, wenn sich der Artikel innerhalb von 2 Jahren nach dem Kauf als fehlerhaft erweist. 
<br>
Der Kunde/die Kundin hat keinen Anspruch auf eine Rückerstattung, wenn es sich um ein geringfügiges Problem handelt, wie z. B. Kratzer auf dem Artikel oder Ähnliches. 
<br>
Sobald SmartResQ/CorPatch<sup>®</sup> den Artikel des Kunden/der Kundin erhalten hat, wird eine Rückerstattung eingeleitet. Die Höhe des Rückerstattungsbetrags hängt vom Zustand der Produkte ab, wenn sie bei SmartResQ/CorPatch<sup>®</sup> eingehen. </p>

 

<p>Die Art und Weise, wie die Rückerstattung für den Kunden/die Kundin bearbeitet wird, hängt von der ursprünglichen Zahlungsmethode ab. Wenn der Kunde/die Kundin mit einer Kredit- oder Debitkarte bezahlt hat, wird die Rückerstattung innerhalb von 5 Werktagen nach Erhalt der zurückgesendeten Ware oder des Stornierungsantrags an die kartenausgebende Bank geschickt. Bitte wende dich an die kartenausgebende Bank, wenn du wissen möchtest, wann die Gutschrift auf deinem Konto verbucht wird. </p>
</div>
   
<!--This is the 10th section (white) - title: Verarbeitung von persönlichen Informationen-->
<div class="white">

<h2>Verarbeitung von persönlichen Informationen </h2>


<p>Bei SmartResQ/CorPatch<sup>®</sup> legen wir Wert auf deine persönlichen Daten, indem wir uns in drei Schritten um die Einhaltung der Datenschutzbestimmungen bemühen: a. Wir führen eine detaillierte Karte unseres Datenflusses, b. wir nehmen eine rechtliche Bewertung auf der Grundlage des Datenflusses vor, um c. die notwendigen Sicherheitsmaßnahmen zu ergreifen, damit deine Daten sicher sind. </p>

 

<p>Um unseren Onlineshop nutzen zu können, musst du mindestens die folgenden Angaben machen: </p>
<ul>
<li>Name </li>

<li>Adresse </li>

<li>E-Mail-Adresse </li>

<li>Telefonnummer </li>
</ul>
 

<p>Die Erhebung von persönlichen Kundendaten erfolgt im Rahmen der bestehenden Gesetzgebung und der EU-Gesetzgebung zur Allgemeinen Datenschutzverordnung (DSGVO). </p>

 

<p>Wenn du mehr über die Verarbeitung deiner persönlichen Daten erfahren möchtest, lies bitte unsere Datenschutzrichtlinie. </p>

</div>
   
<!--this is the 11th section (beige) - title: Beschwerden-->
<div class="beige">
<h2>Beschwerden </h2>

<h3>Wie du dich bei uns beschweren kannst </h3>

<p>Wenn etwas mit dem Produkt nicht in Ordnung ist, kannst du ein fehlerhaftes Produkt entweder reparieren oder ersetzen lassen, eine Rückerstattung oder einen Preisnachlass erhalten, je nach der konkreten Situation. 
<br>
Voraussetzung ist natürlich, dass die Beschwerde gerechtfertigt ist und der Mangel nicht durch unsachgemäßen Gebrauch des Produkts oder anderes Fehlverhalten verursacht wurde. </p>

 

<p>Wir empfehlen dir, die Reklamation so schnell wie möglich und innerhalb einer Woche nach Feststellung des Mangels geltend zu machen.
<br>
Bitte zögere nicht, dich mit Fragen, Kommentaren oder Beschwerden an uns zu wenden, indem du dich per E-Mail an uns wendest: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p> 

<h3>Beschwerde bei anderen Stellen innerhalb der EU </h3>

<p>Bitte besuche die offizielle Website der Europäischen Union, um dich bei anderen Stellen innerhalb der EU zu beschweren. Informationen findest du <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">hier</a>.</p>

</div>

<!--this is the 12th section (white) - title: Änderungen an diesem Dokument-->
<div class="white"> 
<h2>Änderungen an diesem Dokument </h2>

<h3>Zu Website(s), App(s) und Richtlinien </h3>

<p>SmartResQ/CorPatch<sup>®</sup> behält sich das Recht vor, unsere Website(s), App(s), Richtlinien und Dokumente jederzeit und ohne Angabe von Gründen zu ändern, zu löschen, zu modifizieren oder zu ergänzen, ohne dass jemand davon in Kenntnis gesetzt wird. 
<br>
Sollte eine dieser Bedingungen ungültig, nichtig oder aus irgendeinem Grund nicht durchsetzbar sein, gilt diese Bedingung als abtrennbar und beeinträchtigt nicht die Gültigkeit und Durchsetzbarkeit der übrigen Bedingungen. </p>

 

<p>Wenn wir unsere Richtlinien ändern, werden wir die überarbeiteten Richtlinien online mit einem aktualisierten Änderungsdatum veröffentlichen. Wir empfehlen dir, die Richtlinien regelmäßig zu überprüfen. Wenn wir unsere Richtlinien inhaltlich wesentlich ändern, können wir dich auch auf andere Weise benachrichtigen, z. B. per E-Mail oder durch einen Hinweis auf unserer Website und/oder in den sozialen Medien, bevor die Änderungen in Kraft treten. </p>

 

<h3>Bezug auf einen bestimmten Kauf </h3>

<p>Wenn du ein Produkt kaufst, wirst du aufgefordert, eine Version bestimmter Dokumente zu akzeptieren, wie sie zu diesem Zeitpunkt vorliegt – diese Version wird nach diesem Zeitpunkt nicht mehr geändert und bestimmt die Bedingungen unserer Beziehung zu dir in Bezug auf diesen bestimmten Kauf. </p>

</div>

<!--this is the 13th section (beige)- title: Recht und Gerichtsbarkeit-->
<div class="beige"> 
<h2>Recht und Gerichtsbarkeit </h2>

<h3>Die Gesetze Dänemarks und das Bezirksgericht von Svendborg </h3>

 

<p>SmartResQ/CorPatch<sup>®</sup> wendet dänisches Recht und den Gerichtsstand für alle Rechtsstreitigkeiten an, jedoch nicht das CISG. 
<br>
Alle Rechtsstreitigkeiten, die in irgendeiner Weise mit deinem Besuch bei SmartResQ/CorPatch<sup>®</sup> oder mit Produkten, die du über SmartResQ/CorPatch<sup>®</sup> kaufst, zusammenhängen, werden in Dänemark vertraulich behandelt, außer wenn du in irgendeiner Weise die geistigen Eigentumsrechte von SmartResQ/CorPatch<sup>®</sup> verletzt hast oder zu verletzen drohst; SmartResQ/CorPatch<sup>®</sup> kann in jedem Land eine einstweilige Verfügung oder einen anderen angemessenen Rechtsbehelf beantragen, und du stimmst der ausschließlichen Zuständigkeit und dem ausschließlichen Gerichtsstand in diesen Gerichten zu. </p>

 

<p>Bei einem Verstoß gegen die Vereinbarung über die Allgemeinen Geschäftsbedingungen werden rechtliche Schritte eingeleitet, und es kann eine Klage vor Gericht eingereicht werden. </p>

 

<p>Für Streitigkeiten zwischen uns und einem Verbraucher ist das Bezirksgericht Svendborg, Christiansvej 41, 5700 Svendborg, Dänemark, zuständig. </p>

</div>

<!--this is the 14th section (white) title: Kontaktinformationen-->
<div class="white">
<h2>Kontaktinformationen </h2>

<p>Vielen Dank, dass du die Allgemeinen Geschäftsbedingungen von SmartResQ/CorPatch<sup>®</sup> gelesen hast. 
<br>
Wenn du Fragen, Kommentare oder Beschwerden hast, kannst du dich gerne an uns wenden. </p>

 

<p>Unser Unternehmen befindet sich in: Lundevej 26, 5700 Svendborg, Dänemark </p>

 

<p>Du findest uns im Zentralen Unternehmensregister unter der Umsatzsteuernummer DK 38674102 </p>

 

<p>Du kannst uns unter der Telefonnummer +45 62 200 100 anrufen </p>

 

<p>oder </p>

 

<p>schreib uns eine E-Mail an: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p> 

 

<p><b>© SmartResQ ApS – Alle Rechte vorbehalten
<br>
Dänemark, Version 2.1 – Veröffentlicht 2023.04.25 </b></p>

</div>
   
<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->



<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Datenschutzrichtlinie -->
<div class="beige">

<h1 id="policy">SmartResQ / CorPatch<sup>®</sup> Datenschutzrichtlinie </h1>
 <!--This is the 16th section (beige) = joined together with the 15th section - title: Wir behandeln deine Daten in Übereinstimmung mit der DSGVO-->
<h2>Wir behandeln deine Daten in Übereinstimmung mit der DSGVO </h2>

<p>SmartResQ/CorPatch<sup>®</sup> respektiert deine Privatsphäre. Diese Datenschutzerklärung beschreibt deine Datenschutzrechte und unsere Verpflichtung zum Schutz deiner persönlichen Daten. </p>

<p>Wenn du Fragen zu unserer Verarbeitung deiner persönlichen Daten hast, wende dich bitte an uns. Der Datenverantwortliche ist: </p>

<p><b>Das Unternehmen:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Adresse: </b>
<br>
Lundevej 26 
<br>
5700 Svendborg 
<br>
Dänemark </p>

 

<p><b>CVR-Nr.:</b> 38674102 </p>

<p><b>Telefon-Nr.:</b> +45 62 200 100 </p>

<p><b>E-Mail:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p> 

<p>SmartResQ/CorPatch<sup>®</sup> ist ein dänisches/europäisches Unternehmen mit juristischen Einheiten, Geschäftsprozessen, Managementstrukturen und technischen Systemen, über nationale Grenzen hinweg. SmartResQ/CorPatch<sup>®</sup> bietet Produkte, Software und Dienstleistungen für öffentliche/private Unternehmen in Europa an. </p>

<p>Der Hauptsitz befindet sich in Dänemark und SmartResQ/CorPatch<sup>®</sup> unterliegt der europäischen Datenschutzgesetzgebung, einschließlich der Allgemeinen Datenschutzverordnung (DSGVO). Alle wichtigen Entscheidungen in SmartResQ/CorPatch<sup>®</sup>, die den Schutz persönlicher Daten betreffen, werden auf Managementebene unter der Aufsicht des Datenschutzbeauftragten getroffen. </p>

<p>Diese Datenschutzerklärung ist auf unseren Websites und in unseren Apps verfügbar. </p>

<p>Bitte nutze die Seiten, Apps oder Dienste von SmartResQ/CorPatch<sup>®</sup> nicht, wenn du mit der Art und Weise nicht einverstanden bist, wie wir persönliche Daten gemäß dieser Datenschutzerklärung verarbeiten. </p>

</div>

<!--This is the 17th section (white) - title: Die Art der persönlichen Daten, die wir sammeln -->
<div class="white"> 

<h2>Die Art der persönlichen Daten, die wir sammeln  </h2>

<p>Wenn ein/e Verantwortliche/r die Zwecke und Mittel der Verarbeitung deiner persönlichen Daten bestimmt, handelt er/sie als für die Verarbeitung Verantwortliche/r. Dazu gehören Szenarien, in denen SmartResQ/CorPatch<sup>®</sup> persönliche Daten im Zusammenhang mit dir als Arbeitssuchende/m, Vertreter/in eines Kunden oder eines Kundenkontakts erhebt, oder wenn du ein/e Software-Nutzer/in bist. </p>

<p>SmartResQ verarbeitet persönliche Daten für eine Vielzahl von Zwecken, je nachdem, welche Beziehung wir zu dir haben. </p>

<p>Wir können Folgendes verarbeiten: </p>
<ol>
<li>Grundlegende Kontaktinformationen wie Name, Adresse, Telefonnummer (Handy und/oder Festnetz) und E-Mail, </li>

<li>Beschäftigungsinformationen wie Arbeitgeber, Titel, Position, einschließlich Vorlieben und Interessen im beruflichen Kontext, </li>

<li>Feedback, Kommentare oder Fragen zu SmartResQ/CorPatch<sup>®</sup> oder unseren Produkten und Dienstleistungen, </li>

<li>Fotos oder Videos, die an unseren Standorten aufgenommen wurden, </li>

<li>Inhalte, die du hochgeladen hast, wie Fotos, Videos und Leistungen im Laufe der Zeit, </li>

<li>Eindeutige Benutzerinformationen wie Anmelde-ID, Benutzername, Passwort und Sicherheitsfrage, </li>

<li>Finanzielle Informationen, wenn du zustimmst, dass wir deine Daten verwenden, z. B. für die Speicherung deiner Zahlungskartendaten, </li>

<li>Verkehrsdaten, die von deinem Webbrowser zur Verfügung gestellt werden, wie z. B. die Art des Browsers, das Gerät, die Sprache und die Adresse der Website, von der du gekommen bist, sowie andere Verkehrsdaten, einschließlich der IP-Adresse, </li>

<li>Clickstream-Verhalten und Aktivitäten auf SmartResQ/CorPatch<sup>®</sup> IDs und in unseren Produkten und Diensten, </li>

<li>E-Mail-Verhalten, wie z. B. E-Mail-Nachrichten von SmartResQ/CorPatch<sup>®</sup>, die du wann und wie öffnest, </li>

<li>andere persönliche Informationen, die in deinem Profil enthalten sind und die du freiwillig in sozialen Netzwerken Dritter wie LinkedIn, Facebook, Google usw. eingestellt hast, </li>

<li>Informationen, die zu wissenschaftlichen Zwecken verwendet werden, um das Überleben nach einem Herzstillstand zu verbessern, die über unsere Website(s) und Apps gesammelt werden, </li>

<li>Informationen über Nutzer/innen, um Produkte zu liefern, die den Qualitäts- und Sicherheitsanforderungen entsprechen, um Dienstleistungen für die Nutzer/innen zu erbringen und um unsere Angebote zu pflegen und zu verbessern, </li>

<li>Informationen über Stellenbewerber/innen, um Bewerbungen zu bearbeiten, über zukünftige Stellenangebote zu informieren und unsere Rekrutierungsprozesse zu pflegen und zu verbessern, </li>

<li>Informationen über Personen, die sich für den Erhalt von Newslettern und anderen Inhalten angemeldet haben, um die Inhalte zu versenden und unser Angebot zu pflegen und zu verbessern, </li>

<li>Cookie-Informationen, um maßgeschneiderte Werbung in sozialen Medien und auf Websites bereitzustellen. </li>
</ol>

</div>

<!--This is the 18th section (beige) - tile: Daten, die auf der CorPatch<sup>®</sup> Services Plattform und in den Apps gesammelt und verarbeitet werden -->
<div class="beige">
<h2>Daten, die auf der CorPatch<sup>®</sup> Services Plattform und in den Apps gesammelt und verarbeitet werden </h2>

<p>SmartResQ verarbeitet, sammelt und speichert die folgenden persönlichen Daten, wenn du die CorPatch<sup>®</sup> Services Plattform oder die Apps nutzt. </p>

 
 

<h3>Alle Benutzer/innen (Institute Admin, Trainer/in, Trainee/Endbenutzer/in) </h3>
<ul>
<li>Vorname (falls eingegeben) </li>

<li>Nachname (falls eingegeben) </li>

<li>Benutzernamen (falls eingegeben) </li>

<li>E-Mail-Adresse (obligatorisch) </li>

<li>Bevorzugte Kommunikationssprache (obligatorisch) </li>

<li>Passwort-Hash (obligatorisch) </li>

<li>Ob die E-Mail-Adresse validiert wurde (obligatorisch) </li>
</ul>
 

<h3>Zusätzlich für Trainees/Endnutzer (obligatorisch) </h3>

<p>Daten über das verwendete Mobiltelefon: </p>


<p>Betriebssystem (Android/iOS): </p>
<ul>
<li>Version des Betriebssystems (z. B. 9) </li>

<li>Hersteller (z. B. Samsung) </li>

<li>Modell (z. B. SM-T518) </li>

<li>App-Version (z. B. 1.2.4) </li>

<li>Zeitpunkt der letzten Vordergrundaktivität der App </li>

<li>Zeitpunkt der letzten Hintergrundaktivität der App </li>

</ul>

<p>Daten zum verwendeten CorPatch<sup>®</sup> Gerät (CPS): </p>
<ul>
<li>Seriennummer / MAC-Adresse </li>

<li>Firmware-Version </li>

<li>Modellbezeichnung (z. B. CPS_01) </li>

<li>Hersteller (derzeit immer SRQ) </li>

<li>Name (derzeit immer CorPatch<sup>®</sup>) </li>

<li>Akkuzustand </li>

<li>Defekte </li>
</ul>
 

<p>Daten zum Onboarding des Benutzers: </p>
<ul>
<li>Tutorial abgeschlossen (ja/nein) </li>

<li>Nutzungsbedingungen akzeptiert (ja/nein) </li>

<li>Selbsteinschätzung abgeschlossen (ja/nein) </li>

<li>Testtraining abgeschlossen (ja/nein) </li>

<li>Erste Anmeldung erfolgreich (ja/nein) </li>

<li>Wurde ein CPS angeschlossen (ja/nein) </li>

</ul>

<p>Durch das Training erhobene Daten: </p>
<ul>
<li>Datum, Uhrzeit und Dauer des Trainings </li>

<li>Ergebnis des Trainings </li>

<li>Art des Trainings oder Trainingseinstellungen </li>

<li>Bei einem Training innerhalb eines Instituts, zusätzliche Informationen über den Kurs, den/die Trainer/in und das Institut </li>

</ul>
 

<h3>Server-Protokolle </h3>

<p>Die folgenden Daten werden in den Webserver-Protokollen gespeichert: </p>
<ul>
<li>IP-Adresse des/der zugreifenden Teilnehmers/Teilnehmerin </li>

<li>Browserversion des/der zugreifenden Teilnehmers/Teilnehmerin </li>

<li>Datum/Uhrzeit des Zugriffs </li>

<li>URL des Zugriffs </li>
</ul>
<h3>Externe Dienste, die Daten verarbeiten: </h3>
<ul>
<li>Google/Firebase für die Fernprotokollierung, Absturz- und Fehleranalyse </li>

<li>Google/Firebase für den Versand von Benachrichtigungen </li>

<li>Sendgrid für den Versand von E-Mails </li>

<li>Hetzner Online GmbH für das Hosting von Web-Backend und Datenbank </li>

</ul> 

<h3>Was passiert, wenn ein Benutzer gelöscht wird </h3>
<ul>
<li>Der/die Benutzer/in löscht sich selbst aus unserem System auf der CorPatch<sup>®</sup> Services Homepage <a href="https://app.corpatch.com">https://app.corpatch.com</a></li>

<li>Der/die Benutzer/in wird als gelöscht markiert. Danach kann er/sie sich nicht mehr anmelden, ist für Admins nicht mehr sichtbar, etc., der/die Benutzer/in existiert aber noch in der Datenbank. </li>

<li>Nach 14 Tagen werden die Daten des/der Benutzers/Benutzerin automatisch aus der Datenbank gelöscht. </li>

<li>Zum Zweck der wissenschaftlichen Auswertung und Funktionsverbesserung bleiben die Daten der Trainings und der Nutzung von CorPatch<sup>®</sup> auch nach der Löschung des Nutzers in der Datenbank bestehen, aber der Verweis (die ID) auf den/die Benutzer/in ist dann leer, und alle Verweise auf persönliche Daten werden entfernt.  </li>
</ul>

</div>

<!--This is the 19th section (white) - tile: Wie wir deine persönlichen Daten sammeln-->
<div class="white">
<h2>Wie wir deine persönlichen Daten sammeln </h2>

<p>Die meisten persönlichen Daten, die wir verarbeiten, werden uns direkt von dir zur Verfügung gestellt. Wir sammeln Daten und verarbeiten Daten, wenn du: </p>
<ul>
<li>dich online registrierst oder eine Bestellung für eines unserer Produkte oder eine unserer Dienstleistungen aufgibst; z. B. demografische Daten, E-Mail-Adresse, Zahlungsinformationen, Artikel, Bestellmenge, Rabattstufe und -häufigkeit. Einschließlich des Versands von Transaktions-E-Mails, z. B. Bestell-, Versand- und Erstattungsbestätigungen, </li>

<li>dich an der gesendeten Kommunikation (E-Mail, SMS, Direktwerbung oder Telefon) beteiligst; z. B. Öffnungsrate, Klickrate und Zeit, die mit dem Lesen der E-Mails verbracht wird, Absenderdomäne und Art des E-Mail-Clients, </li>

<li>freiwillig eine Kundenumfrage ausfüllst oder Feedback auf einem unserer Messageboards oder per E-Mail gibst. </li>
</ul>
 

<p>Wir können persönliche Daten auch indirekt aus den folgenden Quellen in den folgenden Szenarien erhalten: </p>
<ul>
<li>Von Cookies: wenn du unsere Websites oder Apps besuchst; z. B. IP-Adresse, Land, angesehene Seiten, angesehene Produkte, Interaktionen/Klicks und Suchanfragen. </li>

<li>Von dir oder einer anderen Person, die mit unserem Kunden verbunden ist. Diese Personen können ein/e Manager/in oder ein/e Kollege/Kollegin sein. Wenn der/die Kunde/Kundin, für den/die du arbeitest, Produkte oder Dienstleistungen von SmartResQ/CorPatch<sup>®</sup> über ein Partnerunternehmen von SmartResQ/CorPatch<sup>®</sup> kauft, können wir Informationen über dich von dem Partnerunternehmen sammeln. </li>

<li>SmartResQ/CorPatch<sup>®</sup> Marketingpartnern, öffentlichen Quellen oder sozialen Netzwerken von Dritten. </li>

<li>SmartResQ/CorPatch<sup>®</sup> kann persönliche Daten über dich, die aus einer Quelle gesammelt wurden, mit Informationen aus einer anderen Quelle kombinieren. Dadurch erhalten wir ein vollständigeres Bild von dir und können dir relevantere Dienste mit einem höheren Maß an Personalisierung anbieten. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Wie wir deine Daten verwenden-->
<div class="beige">
<h2>Wie wir deine Daten verwenden </h2>

<p>Um unsere Kundenbeziehungen allgemein zu verwalten und unsere Kundenverpflichtungen zu erfüllen, benötigt SmartResQ/CorPatch<sup>®</sup> Informationen über dich in deiner Rolle als Kunde/Kundin oder wenn du einen Dienst nutzt. Die Zwecke der Verarbeitung dieser persönlichen Daten sind: </p>

<ul>
<li>Deine Bestellung zu bearbeiten, dein Konto zu verwalten, </li>
<li>dir per E-Mail Sonderangebote für andere Produkte und Dienstleistungen zukommen zu lassen, von denen wir glauben, dass sie dir gefallen könnten, </li>

<li>den Verkaufs- und Vertragsprozess für Kunden/Kundinnen durchzuführen, </li>

<li>um die angeforderten Produkte und Dienstleistungen an die Kunden/Kundinnen zu liefern, </li>

<li>um Lieferungen gemäß den mit dir oder den Kunden geschlossenen Vereinbarungen durchzuführen, </li>

<li>um den Benutzern unserer Produkte und Dienstleistungen Support zu bieten, </li>

<li>die Qualität, Funktionalität und Benutzerfreundlichkeit unserer Produkte, Dienstleistungen und SmartResQ/CorPatch<sup>®</sup> Website(s) und Apps zu verbessern und weiterzuentwickeln, </li>

<li>Sicherheitsbedrohungen zu erkennen, einzuschränken und zu verhindern sowie Wartung, Fehlerbehebung und Fehlersuche durchzuführen, </li>

<li>den Missbrauch unserer Produkte und Dienstleistungen zu verhindern, </li>

<li>um Bestellungen, Rechnungen, Zahlungen oder andere finanzielle Folgemaßnahmen zu bearbeiten, </li>

<li>Interessenprofile zu erstellen, um relevante Produkte und Dienstleistungen zu bewerben, </li>

<li>Benutzergemeinschaften aufzubauen, um die Interaktion zwischen Benutzern/Benutzerinnen und SmartResQ/CorPatch<sup>®</sup> zu fördern. </li>

</ul>

<h3>Über Kundenkontakte </h3>

<p>SmartResQ/CorPatch<sup>®</sup> verarbeitet persönlichen Daten von Kundenkontakten zu Marketingzwecken. Um potenziellen Kunden zielgerichtete und relevante Inhalte anbieten zu können, erstellt SmartResQ/CorPatch<sup>®</sup> ein Interessenprofil, das auf deiner Aktivität und deinen Entscheidungen und Handlungen auf den SmartResQ/CorPatch<sup>®</sup> Seiten sowie auf deiner Reaktion auf Marketinginhalte basiert. Die Rechtsgrundlage für diese Verarbeitung ist in erster Linie deine Zustimmung. </p>

<h3>Über Arbeitssuchende </h3>

<p>Wenn du ein/e Arbeitssuchender/Arbeitssuchende bist, verarbeiten wir persönliche Daten, um dein Potenzial als SmartResQ/CorPatch<sup>®</sup> Mitarbeiter/in zu bewerten. Unsere sichere Online-Karriereplattform stellt sicher, dass wir die neuesten Gesetze und Vorschriften in Bezug auf den Datenschutz einhalten. Die Rechtsgrundlage für diese Verarbeitung ist deine Zustimmung. </p>

<h3>Über Website-Besucher </h3>

<p>Um den Zugriff auf unsere Websites zu überwachen, verarbeiten wir persönliche Daten über die Besucher. Die Verarbeitung basiert auf unserem berechtigten Interesse, unsere Geschäftsgeheimnisse, Mitarbeiter, Standorte und dich als Besucher/in zu schützen. Du wirst über deine Rechte in diesem Zusammenhang informiert, wenn du dich in unserem elektronischen Besuchersystem registrierst. </p>

<p>Um die Qualität der HLW zu verbessern, insbesondere durch HLW-Trainings, kann SmartResQ/CorPatch<sup>®</sup> deine Daten an unsere Partnerunternehmen (Training Institute) weitergeben, damit diese dir ihre Produkte und Dienstleistungen anbieten können. </p>

<p>Wenn wir deine Bestellung bearbeiten, kann unser System deine Daten an Kreditauskunfteien senden und die daraus resultierenden Informationen nutzen, um betrügerische Käufe zu verhindern. </p>

</div>

<!-- This is 21st section (white) - title: Wie speichern wir deine persönlichen Daten? -->
<div class="white">
<h2>Wie speichern wir deine persönlichen Daten? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> nimmt das Vertrauen, das du und unsere Kunden uns entgegenbringen, sehr ernst. SmartResQ/CorPatch<sup>®</sup> verpflichtet sich, den unbefugten Zugriff, die Weitergabe oder eine andere abweichende Verarbeitung persönlicher Daten zu verhindern. SmartResQ/CorPatch<sup>®</sup> stellt die Vertraulichkeit der von uns verarbeiteten persönlichen Daten sicher, wahrt die Integrität der persönlichen Daten und gewährleistet ihre Zugänglichkeit in Übereinstimmung mit den geltenden Datenschutzgesetzen. </p>

<p>Im Rahmen unserer Verpflichtungen ergreifen wir angemessene und geeignete organisatorische, technische und physische Verfahren und Maßnahmen, um die von uns erhobenen und verarbeiteten Daten zu schützen. Wir berücksichtigen die Art der persönlichen Daten und das Risiko, dem unsere Kunden durch eine Sicherheitsverletzung ausgesetzt sind. Da die Ursachen für Verstöße gegen persönliche Daten mit hoher Wahrscheinlichkeit intern zu finden sind, glauben wir, dass der Aufbau einer starken Unternehmenskultur, in der Respekt und Wachsamkeit in Bezug auf den Datenschutz unter unseren Mitarbeitern grundlegend sind, um die rechtmäßige Verarbeitung und den Schutz deiner Daten zu gewährleisten. Im Falle einer Datenpanne wird SmartResQ/CorPatch<sup>®</sup> die vom dänischen Datatilsynet festgelegten Praktiken anwenden. </p>

<p>Deine Daten werden gemäß den Bestimmungen der DSGVO sicher gespeichert. </p>

</div>

<!--This is the 22nd section (beige) - title: Wie lange bewahren wir deine persönlichen Daten auf?-->
<div class="beige">
<h2>Wie lange bewahren wir deine persönlichen Daten auf? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> bewahrt deine persönlichen Daten nur so lange auf, wie es für die genannten Zwecke notwendig ist. Dabei berücksichtigen wir, dass wir auf Anfragen reagieren und Probleme lösen müssen und dass wir die rechtlichen Anforderungen nach geltendem Recht erfüllen. </p>

<p>Das bedeutet, dass SmartResQ/CorPatch<sup>®</sup> deine persönlichen Daten für einen angemessenen Zeitraum nach der letzten Interaktion mit dir und unseren Kunden speichern kann. Wenn die von uns erhobenen persönlichen Daten nicht mehr erforderlich sind, löschen wir sie. Wir können Daten für statistische und/oder wissenschaftliche Zwecke verarbeiten, aber in solchen Fällen werden die Daten pseudonymisiert oder anonymisiert. </p>

 
 

<h3>Zeitrahmen für die Datenspeicherung </h3>

<p>Wir bewahren deine persönlichen Daten so lange auf, wie es für die Erfüllung der in diesen Datenschutz-Bestimmungen genannten Zwecke erforderlich ist, es sei denn, eine längere Aufbewahrungsfrist ist gesetzlich vorgeschrieben oder zulässig, aus rechtlichen, steuerlichen oder behördlichen Gründen oder aus anderen legitimen und rechtmäßigen Geschäftszwecken. </p>



<table>
<tr>
<td><b>Zweck </b></td>
<td><b>Zeitrahmen </b></td>
</tr>

<tr>
<td>Kundendienst und Buchhaltungsvorschriften </td>

<td>5 Jahre oder so lange, wie wir verpflichtet sind, die erforderlichen gesetzlichen Anforderungen zu erfüllen. 
<br>
Wir löschen deine Anmeldedaten, sobald du dein Benutzerkonto bei uns löschst oder wenn die gesetzliche Aufbewahrungsfrist abgelaufen ist. 
</td>
</tr>

<tr>
<td>Karriereplattform </td>

<td>6 Monate für Bewerbungsunterlagen, die nicht zu einer Einstellung des Bewerbers/der Bewerberin führen. 
<br>
 

Erneuerung alle 6 Monate, wenn du für zukünftige Stellenangebote berücksichtigt werden möchtest und du einer längeren Speicherdauer zustimmst. 
</td>
</tr>

<tr>
<td>Marketingzwecke </td>

<td>3 Jahre nach deiner letzten Aktivität, z. B. Besuch unserer Websites, Kauf oder Beteiligung an der Kommunikation. 
<br>
 

Wenn du uns deine Erlaubnis für Marketingzwecke (E-Mail, SMS, Telefon usw.) gegeben hast und solange wir deine Erlaubnis haben, dich zu kontaktieren. 
<br>
 

Wir löschen deine E-Mail-Adresse im Newsletter automatisch, wenn du dein Benutzerkonto löschst oder dich von unserem Newsletter abmeldest.  
</td>
</tr>

<tr>
<td>Speicherung der Bestellhistorie und Verpflichtungen zur Erfüllung von Bestellungen </td>

<td>5 Jahre oder so lange, wie wir verpflichtet sind, die erforderlichen gesetzlichen Anforderungen zu erfüllen  </td>
</tr>

<tr>
<td>Kundenerfahrung </td>

<td>3 Jahre nach deiner letzten Aktivität, z. B. Besuch unserer Websites, Kauf oder Beteiligung an der Kommunikation. 
<br>
 

Wenn du deine Zustimmung zu Marketingmaßnahmen (E-Mail, SMS, Telefon usw.) gegeben hast und solange wir deine Zustimmung haben. 
</td>
</tr>

<tr>
<td>Betrug und Risikobewertung </td>

<td>5 Jahre oder so lange, wie wir verpflichtet sind, die erforderlichen gesetzlichen Anforderungen zu erfüllen. </td>
</tr>

</table>
 
</div>
 
 
<!--This is the 23rd section (white) - title: Marketingmitteilungen-->
<div class="white">
<h2>Marketingmitteilungen </h2>

<p>Du hast das Recht, den Erhalt von Marketingmitteilungen von SmartResQ/CorPatch<sup>®</sup> abzulehnen und kannst dies tun, indem du eines der folgenden Dinge tust: </p>
<ul>
<li>die Anweisungen zur Abmeldung in den jeweiligen Marketingmitteilungen befolgen, </li>

<li>deine Einstellungen im entsprechenden Bearbeitungsbereich deines Kontos ändern, wenn du ein Konto bei SmartResQ/CorPatch<sup>®</sup> hast, </li>

<li>uns per E-Mail unter <a href="mailto:info@corpatch.com">info@corpatch.com</a> kontaktieren. </li>
</ul>
 

<p>Bitte beachte, dass du auch dann administrative Nachrichten von SmartResQ/CorPatch<sup>®</sup> erhalten kannst, wenn du dich gegen den Erhalt von Marketingmitteilungen entscheidest, z. B. Auftragsbestätigungen und Mitteilungen, die für die Verwaltung deines Kontos oder der Dienste, die wir unseren Kunden z. B. über mobile Dienste oder Apps anbieten, notwendig sind. </p>

</div>

<!--This is the 24th section (beige) - title: Deine Datenschutzrechte-->
<div class="beige">
<h2>Deine Datenschutzrechte </h2>

<p>Für SmartResQ/CorPatch<sup>®</sup> ist es wichtig, dass du über alle deine Datenschutzrechte Bescheid weißt. </p>

<p>Einige Datenschutzgesetze, darunter die Allgemeine Datenschutzverordnung (DSGVO) der Europäischen Union, die entsprechenden Gesetze in Deutschland, dem Bundesdatenschutzgesetz (BDSG), in der Schweiz und im Vereinigten Königreich sowie einige Gesetze der US-Bundesstaaten, gewähren dir bestimmte Rechte im Zusammenhang mit persönlichen Daten, die du uns mitgeteilt hast. Wenn du im Europäischen Wirtschaftsraum wohnhaft bist, hast du unter Umständen die folgenden Rechte: </p>
<ol>
<li><b>Dein Recht auf Auskunft</b> – Du hast das Recht, von uns Kopien deiner persönlichen Daten zu verlangen. </li>

<li><b>Dein Recht auf Berichtigung</b> – Du hast das Recht, von uns zu verlangen, dass wir persönliche Daten berichtigen, von denen du glaubst, dass sie unrichtig sind. Du hast auch das Recht, uns aufzufordern, Informationen zu vervollständigen, die du für unvollständig hältst.  </li>

<li><b>Dein Recht auf Löschung</b> – Du hast das Recht, von uns zu verlangen, deine persönlichen Daten unter bestimmten Bedingungen zu löschen.  </li>

<li><b>Dein Recht auf Einschränkung der Verarbeitung</b> – Du hast das Recht, von uns zu verlangen, dass wir die Verarbeitung deiner persönlichen Daten unter bestimmten Bedingungen einschränken. </li>

<li><b>Dein Recht, der Verarbeitung zu widersprechen</b> – Du hast das Recht, der Verarbeitung deiner persönlichen Daten unter bestimmten Bedingungen zu widersprechen. </li>

<li><b>Dein Recht auf Datenübertragbarkeit</b> – Du hast das Recht zu verlangen, dass wir die von uns erhobenen persönlichen Daten unter bestimmten Bedingungen an eine andere Organisation oder direkt an dich weitergeben. </li>

</ol>

<p>Wenn du eines dieser Rechte ausüben möchtest, kontaktiere uns bitte per E-Mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

</div>

<!--This is the 25th section (white) - title: Was sind Cookies?-->
<div class="white">
<h2>Was sind Cookies? </h2>

<p>Cookies sind kleine Textdateien, die eine Zeichenkette enthalten und eine eindeutige Kennung für einen Benutzer erstellen. Sie werden an die Website und/oder an Dritte zurückgegeben. Die meisten Browser sind von vornherein so eingestellt, dass sie Cookies akzeptieren, da die meisten Websites auf sie zugreifen müssen. Du kannst deine Browsereinstellungen jedoch so ändern, dass dein Browser Cookies generell ablehnt, Cookies von Dritten blockiert oder festlegt, wann ein Cookie gesendet wird.</p>
 
 

<p>SmartResQ/CorPatch<sup>®</sup> setzt sich dafür ein, dass du das Recht hast, deine Interessen anzupassen und den Umfang des digitalen Marketings von uns durch ein System der bevorzugten Verwaltung zu steuern. </p>

</div>

<!--This is the 26th section (beige) - title: Wie wir Cookies verwenden-->
<div class="beige">
<h2>Wie wir Cookies verwenden </h2>

<p>SmartResQ/CorPatch<sup>®</sup> verwendet Cookies auf verschiedene Weise, um deine Erfahrung auf unseren Websites, Apps und Diensten aus verschiedenen Gründen zu verbessern, z. B: </p>
<ul>
<li>Funktionalität – Wir verwenden diese Cookies, um dich auf unserer Website wiederzuerkennen und deine zuvor gewählten Einstellungen zu speichern. Dazu gehören z. B. die von dir bevorzugte Sprache und der Ort, an dem du dich befindest. Es wird eine Mischung aus Erstanbieter- und Drittanbieter-Cookies verwendet. </li>

<li>Werbung – Wir verwenden diese Cookies, um Informationen über deinen Besuch auf unseren Websites und Apps, die von dir angesehenen Inhalte, die Links, denen du gefolgt bist, sowie Informationen über deinen Browser, dein Gerät und deine IP-Adresse zu sammeln. Manchmal teilen wir einige begrenzte Aspekte dieser Daten mit Dritten zu Werbezwecken. Wir können die durch Cookies gesammelten Online-Daten auch mit unseren Werbepartnern teilen. Das heißt, wenn du eine andere Website besuchst, wird dir möglicherweise Werbung angezeigt, die auf deinem Surfverhalten auf unserer Website basiert. </li>
</ul>

</div>

<!--This is the 27th section (white) - title: Die Art der Cookies, die wir verwenden-->
<div class="white">
<h2>Die Art der Cookies, die wir verwenden </h2>

<p>Unsere Website(s) verwenden die folgenden Arten von Cookies: </p>
<ul>
<li><b>Google Analytics:</b> Dieses Cookie ermöglicht es uns, Informationen über die Website-Aktivitäten der Benutzer einzusehen, einschließlich, aber nicht beschränkt auf Seitenaufrufe, Quelle und Verweildauer auf einer Website. Die Informationen sind anonymisiert und werden als Zahlen angezeigt, was bedeutet, dass sie nicht zu einzelnen Personen zurückverfolgt werden können. Das hilft, deine Privatsphäre zu schützen. Durch den Einsatz von Google Analytics können wir sehen, welche Inhalte auf unseren Seiten beliebt sind, und wir bemühen uns, dir mehr von dem zu bieten, was du gerne liest und siehst. </li>

<li><b>Google Analytics Remarketing:</b> Legt Cookies auf deinem Computer ab. Das bedeutet, dass Google dir beim Verlassen unserer Seite möglicherweise Anzeigen zu SmartResQ/CorPatch<sup>®</sup> zeigt, die dich aufgrund deines vorherigen Verhaltens auf unserer Website interessieren könnten. Diese Informationen sind nicht persönlich identifizierbar. </li>

<li><b>Google Ads:</b> Durch die Nutzung von Google Ads können wir sehen, welche Seiten dazu führten, dass jemand unser Kontaktformular ausfüllte. Diese Informationen sind nicht persönlich identifizierbar, wohl aber die Daten, die im Kontaktformular angegeben werden. </li>

<li><b>Google Ads Remarketing:</b> Legt Cookies auf deinem Computer ab. Das bedeutet, dass Google dir beim Verlassen unserer Seite möglicherweise Anzeigen zu SmartResQ/CorPatch<sup>®</sup> zeigt, die dich aufgrund deines vorherigen Verhaltens auf unserer Website interessieren könnten. Diese Informationen sind nicht persönlich identifizierbar. </li>

<li><b>Facebook Remarketing:</b> Das Tracking-Pixel von Facebook platziert Cookies auf deinem Computer, die Facebook mitteilen, dass du die Seite besucht hast. Wir gehen dann davon aus, dass du ein Interesse an SmartResQ/CorPatch<sup>®</sup> und den Inhalten auf dieser Seite hast. Wenn du Facebook besuchst, werden dir dann Informationen oder Werbung mit ähnlichem Inhalt angezeigt. Bitte verwende deine Privatsphäre-Einstellungen auf Facebook, um die Exposition gegenüber dieser Art von Marketing einzuschränken. </li>

<li><b>YouTube:</b> Wir integrieren Videos von der YouTube-Plattform, die von Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) bereitgestellt wird. Datenschutzrichtlinie: <a href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</li>
</ul>

</div>

<!--This is the 28th section (beige) - title: Wie Cookies verwaltet werden-->
<div class="beige">
<h2>Wie Cookies verwaltet werden </h2>

<p>Du kannst deinen Browser so einstellen, dass er Cookies nicht akzeptiert oder löscht. Allerdings kann es sein, dass dadurch einige Funktionen unserer Website nicht funktionieren. Hier erfährst du, wie du Cookies in bestimmten Browsern vermeiden kannst: </p>
<ul>
<li>Die US-Website: <a href="https://www.aboutads.info/choices/"> https://www.aboutads.info/choices/</a></li>

<li>Die EU-Website: <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>

</ul>

<p>SmartResQ/CorPatch<sup>®</sup> nutzt einige vertrauenswürdige Dienste Dritter auf unseren Seiten. Diese Dienste können Cookies verwenden. Du kannst Cookies von Drittanbietern in deinem Browser ablehnen, indem du diesem <a href="https://www.youronlinechoices.com/">Link</a> folgst. </p>

<p>Du kannst die Erfassung und Verarbeitung der durch das Google-Cookie erzeugten Informationen über deine Nutzung unserer Seiten durch Google verhindern, indem du das Google Analytics Opt-out Browser Add-on für deinen aktuellen Webbrowser herunterlädst und installierst. Dieses Add-on ist <a href="https://tools.google.com/dlpage/gaoptout">hier</a> erhältlich.  </p>

</div>

<!--This is the 29th section (white) - title: Datenschutzrichtlinien anderer Websites-->
<div class="white">

<h2>Datenschutzrichtlinien von anderen Websites </h2>

<p>SmartResQ/CorPatch<sup>®</sup> Websites enthalten Links zu anderen Websites. Unsere Datenschutzrichtlinie gilt nur für unsere Website. Wenn du also auf einen Link zu einer anderen Website klickst, solltest du deren Datenschutzrichtlinie lesen. </p>

</div>

<!--This is the 30th section (beige) - title: Änderungen an unserer Datenschutzrichtlinie -->
<div class="beige">
<h2>Änderungen an unserer Datenschutzrichtlinie </h2>

<p>Wenn wir unsere Datenschutzerklärung ändern, werden wir die überarbeitete Erklärung hier mit einem aktualisierten Änderungsdatum veröffentlichen. Wir empfehlen dir, die Erklärung regelmäßig zu lesen. Wenn wir wesentliche Änderungen an unserer Erklärung vornehmen, die unsere Datenschutzpraktiken erheblich verändern, können wir dich auch auf andere Weise benachrichtigen, z. B. per E-Mail oder durch einen Hinweis auf unserer Website und/oder in den sozialen Medien, bevor die Änderungen in Kraft treten. </p>

</div>

<!--This is the 31st section (white) - title: Wie du SmartResQ ApS kontaktieren kannst-->
<div class="white">
<h2>Wie du SmartResQ ApS kontaktieren kannst </h2>

<p>Wenn du Fragen zu den Datenschutzrichtlinien von SmartResQ/CorPatch<sup>®</sup> hast, zu den Daten, die wir über dich gespeichert haben, oder wenn du eines deiner Datenschutzrechte ausüben möchtest, zögere bitte nicht, uns zu kontaktieren. </p>

<p>E-Mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Website: <a href="https://www.corpatch.com/">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Wie man die zuständige Behörde kontaktiert-->
<div class="beige"> 

<h2>Wie man die zuständige Behörde kontaktiert </h2>

<p>Wenn du eine Beschwerde einreichen möchtest oder das Gefühl hast, dass SmartResQ/CorPatch<sup>®</sup> dein Anliegen nicht zufriedenstellend behandelt hat, kannst du dich an das Information Commissioner's Office (ICO) wenden. </p>

<p>Die Adresse des ICO:
<br><br>
Information Commissioner's Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>

 

<p>Helpline-Nummer: +44 303 123 1113 </p>

<p>ICO-Website: <a href="https://www.ico.org.uk/">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – Alle Rechte vorbehalten 
<br>
Dänemark, Version 1.3 – Veröffentlicht 2023.04.25 </b></p>

</div>
</div>
</body>
</html>`

export const termsOfUse_da_DA: string = `<!DOCTYPE html>
<html lang="da">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
        
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
        
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>
<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 

    <!--This is the 1st section - title: Vilkår -->
    <div class="top-section">
        <h1><a href="#terms">Vilkår </a> & <a href="#policy">Privatlivspolitik</a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
               
      
    </div>

<!--This is the 2nd section (white) - title: Formålet med dette dokument og Sådan kan SmartResQ ApS kontaktes -->
<div class="white">


<h1 id="terms">Vilkår – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Formålet med dette dokument </h2>

<p>Velkommen til SmartResQ/CorPatch<sup>®</sup>! </p>

 

<p>Du er i gang med at læse vores Vilkår, som er et dokument, der indeholder information om dit forhold til SmartResQ ApS, som startede, da du gik ind på vores website, downloadede vores gratis app(s), købte et af vores produkter eller lignende. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> og vores associerede selskaber leverer vores produkter og tjenester til dig på følgende betingelser. Hvis du besøger eller handler på vores website(s) eller app(s), accepterer du disse betingelser. Vi anbefaler på det kraftigste, at du læser dem nøje, inden du begynder at bruge vores produkter eller tjenester. </p>

 

<p>Dette dokument omhandler følgende: </p>
<ul>
<li>Sådan kan SmartResQ ApS kontaktes </li>

<li>Produkter, ejendomsret og korrekt brug </li>

<li>Ansvarsfraskrivelse og garantier </li>

<li>Bestilling </li>

<li>Betaling </li>

<li>Levering </li>

<li>Forpligtelser og rettigheder i forbindelse med salg </li>

<li>Returnering af produkter </li>

<li>Databehandling af personoplysninger </li>

<li>Klager </li>

<li>Ændringer af dette dokument </li>

<li>Lovvalg og værneting </li>

<li>Kontaktoplysninger </li>
</ul>
 

<p>Hvis du har spørgsmål vedrørende SmartResQs Vilkår, er du velkommen til at kontakte os. </p>

 

<h2>Sådan kan SmartResQ ApS kontaktes </h2>

<p>Hos SmartResQ har vi til formål at øge overlevelsen ved hjælp af hjerte-lunge-redning af høj kvalitet i tilfælde af hjertestop. Vi ønsker at forberede og vejlede vidner til et hjertestop, så de kan gribe ind allerede i de første afgørende minutter. Derfor har vi udviklet CorPatch<sup>®</sup> – Vi hjælper dig med at redde liv. </p>

 

<p>Vores virksomhed ligger på adressen Lundevej 26, 5700 Svendborg </p>
<p>Du kan finde os i det Centrale Virksomhedsregister under CVR-nr. 38674102 </p>

 

<p>Du kan ringe til os på telefonnummer +45 62 200 100 </p>

 

<p>eller </p>

 

<p>skrive en e-mail til os på <a href="mailto:info@corpatch.com">info@corpatch.com</a></p> 

 

<h3>Har du spørgsmål, kommentarer eller klager? </h3>

<p>Vi sætter pris på din mening, og du er meget velkommen til at kontakte os via ovennævnte kontaktoplysninger eller bruge kontaktformularen på vores website(s) eller app(s). </p>

</div>
   
<!--This is the 3rd section (beige) - title: Produkter, ejendomsret og korrekt brug-->
<div class="beige">
<h2>Produkter, ejendomsret og korrekt brug </h2>

<h3>Vores website(s), apps og produkter </h3>

<p>Følgende afsnit beskriver vigtige egenskaber, rettigheder og forpligtelser i forbindelse med brugen af SmartResQ/CorPatch<sup>®</sup>-tjenester, -apps og -produkter. 
<br>
<p>Vi bestræber os på i rimelig grad at inkludere nøjagtige og opdaterede oplysninger på website(s) og i apps. SmartResQ/CorPatch<sup>®</sup> udsteder imidlertid ingen garantier eller erklæringer vedrørende nøjagtigheden af oplysningerne. Vi påtager os intet ansvar for eventuelle fejl eller udeladelser i websitets indhold, produkter og apps. Oplysningerne på dette website eller i apps erstatter ikke lægebehandling. Vi påtager os ansvar for vores produkter og apps i overensstemmelse med forordning EU 745/2017 om medicinsk udstyr (MDR). </p>

 

<h3>Ophavsret </h3>

<p>Alt indhold på dette website som f.eks. tekst, grafik, logoer, knapikoner, billeder, lydklip, digitale downloads, datasamlinger og software, tilhører SmartResQ/CorPatch<sup>®</sup> eller dets indholdsleverandører og er beskyttet af internationale ophavsretslove. Det ophavsretlige forfatterskab af al data og indhold på ovenstående medier er ejet af SmartResQ/CorPatch<sup>®</sup> og er dermed beskyttet af internationale ophavsretslove. </p>

 

<h3>Registrerede varemærker og design </h3>

<p>SmartResQ/CorPatch<sup>®</sup>'s varemærker, handelsmærker og produktdesign må ikke anvendes i forbindelse med noget produkt eller nogen tjeneste, som ikke er SmartResQ/CorPatch<sup>®</sup>, på en måde som kan skabe forvirring hos kunderne, eller på en måde som nedgør eller bringer SmartResQ/CorPatch<sup>®</sup> i miskredit. Alle andre varemærker, der ikke ejes af SmartResQ/CorPatch<sup>®</sup> eller dets datterselskaber, og som vises på dette website, tilhører deres respektive ejere, som kan være men ikke nødvendigvis er tilknyttet til, forbundet med eller sponsoreret af SmartResQ/CorPatch<sup>®</sup> eller dets datterselskaber. </p>

 

<h3>Licens og adgang til websitet </h3>

<p>SmartResQ/CorPatch<sup>®</sup> giver dig en begrænset licens til at tilgå og gøre personlig brug af vores website(s) og apps men ikke til at downloade (bortset fra page caching) eller ændre disse eller dele heraf, medmindre der er indhentet udtrykkeligt skriftligt samtykke fra SmartResQ/CorPatch<sup>®</sup>. Denne licens omfatter ikke videresalg eller kommerciel brug af disse website(s) eller apps eller deres indhold: (a) indsamling og brug af produktfortegnelser, beskrivelser eller priser; (b) afledt brug af dette website eller dets indhold; (c) download eller kopiering af kontooplysninger til fordel for en anden handlende; eller (d) brug af datamining, robotter eller lignende værktøjer til indsamling og udtræk af data.</p>

 

<p>Hverken dette website eller apps eller dele heraf må reproduceres, ombygges, duplikeres, kopieres, sælges, videresælges, besøges eller på anden måde udnyttes til noget kommercielt formål uden udtrykkeligt skriftligt samtykke fra SmartResQ/CorPatch<sup>®</sup>. Det er ikke tilladt at frame eller bruge framingteknikker til at inkludere varemærker, logoer eller andre ophavsretligt beskyttede oplysninger (herunder billeder, tekst, sidelayout eller form) tilhørende SmartResQ/CorPatch<sup>®</sup> og vores associerede selskaber uden udtrykkeligt skriftligt samtykke. </p>

 

<p>Det er ikke tilladt at bruge metatags eller anden "skjult tekst", der anvender SmartResQ/CorPatch<sup>®</sup>'s navn eller varemærker uden udtrykkeligt skriftligt samtykke fra SmartResQ/CorPatch<sup>®</sup>. Ved uautoriseret brug annulleres den tilladelse eller licens, der er givet af SmartResQ/CorPatch<sup>®</sup>.</p>

 

<p>Du får en begrænset, genkaldelig og ikke-eksklusiv ret til at oprette et hyperlink til SmartResQ/CorPatch<sup>®</sup>'s hjemmeside, såfremt linket ikke fremstiller SmartResQ/CorPatch<sup>®</sup>, dets associerede selskaber eller dets produkter eller tjenester på en falsk, vildledende, nedsættende eller anden krænkende måde. Du må ikke bruge noget SmartResQ/CorPatch<sup>®</sup>-logo eller anden ophavsretligt beskyttet grafik eller varemærke som en del af linket uden udtrykkelig skriftlig tilladelse. 
<br>
Ved brud på aftalen om licens og adgang til websitet vil der blive taget retslige skridt, og der kan blive lagt sag an ved domstolene. </p>

<h3>Elektronisk kommunikation </h3>

<p>Når du besøger SmartResQ/CorPatch<sup>®</sup>'s hjemmeside og sociale medier eller sender e-mails til os, kommunikerer du med os elektronisk. Du accepterer at modtage kommunikation fra os elektronisk. Vi vil kommunikere med dig via e-mail eller ved at offentliggøre meddelelser på vores websites eller i apps i form af pop-up-notifikationer. Du accepterer, at alle aftaler, meddelelser, offentliggørelser og anden kommunikation, som vi sender til dig elektronisk, opfylder ethvert lovkrav om, at sådan kommunikation skal være skriftlig. Se vores Privatlivspolitik i et særskilt dokument, som kan findes på vores hjemmeside. </p>

 

<p>Besøgende på vores website og sociale medier kan blive opfordret til at poste anmeldelser, kommentarer og andet indhold som f.eks. forslag, idéer, kommentarer, spørgsmål eller anden information, når blot indholdet ikke er ulovligt, stødende, truende, nedgørende, krænkende for privatlivet, krænker immaterielle rettigheder eller på anden måde er sårende over for tredjeparter eller anstødeligt og ikke består af eller indeholder softwarevirusser, politiske kampagner, reklamer, kædebreve, masseudsendelse af e-mails eller nogen form for "spam". </p>

 

<p>Du må ikke bruge en falsk e-mailadresse, udgive dig for at være/repræsentere en fysisk eller juridisk person eller på anden måde vildlede omkring oprindelsen af et kort eller andet indhold. SmartResQ/CorPatch<sup>®</sup> forbeholder sig ret (men er ikke forpligtet) til at fjerne eller redigere sådant indhold men gennemgår ikke postet indhold regelmæssigt. Hvis du poster indhold eller fremsender materiale, og medmindre vi anfører andet, giver du SmartResQ/CorPatch<sup>®</sup> og dets associerede selskaber en ikke-eksklusiv, royaltyfri, tidsubegrænset, uigenkaldelig og fuldt underlicenserbar ret til at bruge, reproducere, ændre, tilpasse, offentliggøre, oversætte, skabe afledte værker af, distribuere og vise sådant indhold globalt i alle medier. Du giver SmartResQ/CorPatch<sup>®</sup> og dettes associerede selskaber og underlicenstagere ret til at bruge det navn, som du indsender i forbindelse med sådant indhold, hvis de ønsker det. </p>

 

<p>Du erklærer og garanterer, at du ejer eller på anden måde kontrollerer alle rettighederne til det indhold, som du poster; at indholdet er nøjagtigt; at brugen af indholdet, som du leverer, ikke overtræder denne politik og ikke vil medføre skade på nogen fysisk eller juridisk person; og at du vil holde SmartResQ/CorPatch<sup>®</sup> eller dets associerede selskaber skadesløs for alle krav, som følger af det indhold, som du leverer. SmartResQ/CorPatch<sup>®</sup> har ret, men er ikke forpligtet, til at overvåge og redigere eller fjerne enhver aktivitet eller indhold. SmartResQ/CorPatch<sup>®</sup> påtager sig ikke noget ansvar for indhold, der postes af dig eller en tredjepart. </p>

</div>

<!--This is the 4th section (white) - title: Ansvarsfraskrivelse og garantier-->
<div class="white">
<h2>Ansvarsfraskrivelse og garantier </h2>

<h3>Produktinformation – CorPatch<sup>®</sup> og CorPatch<sup>®</sup> Trainer </h3>

<p>For korrekt brug af et SmartResQ/CorPatch<sup>®</sup>-produkt eller -tjeneste skal du altid følge de seneste brugervejledninger, instruktioner og beskrivelser. Disse kan findes på vores website og under hver produktbeskrivelse. </p>

 

<p>Produktet CorPatch<sup>®</sup> er certificeret som medicinsk udstyr. I en nødsituation med en voksen, der får hjertestop, skal produktet tages ud af sit hylster og placeres og fastgøres korrekt på personens brystkasse. Produktet kan indsamle oplysninger om kompression, dybde, frekvens og recoil og kan overføre disse via Bluetooth<sup>®</sup> til potentielle enheder. Gratis apps på enheder kan hjælpe vidner med at udføre hjerte-lunge-redning og vise flow-fraction, dybde, recoil og frekvens, hvis de er aktiveret og korrekt installeret, dvs. Bluetooth<sup>®</sup>, smartphone, app, batteristrøm etc. SmartResQ/CorPatch<sup>®</sup> er ikke ansvarlig for eksterne faktorer som f.eks. forstyrrende kommunikationssignaler, manglende datadækning, mangel på batteri, forkerte hardware- og softwareindstillinger, der kan påvirke brugeroplevelsen eller lignende. </p>

 

<p>Ligeledes er SmartResQ/CorPatch<sup>®</sup> ikke ansvarlig for fysiske skader, der måtte opstå under brugen af produktet, hvis det ikke er påsat eller anvendt i overensstemmelse med instruktionerne, f.eks. udførelse af brystkompressioner på en ikke-ergonomisk måde eller en uhensigtsmæssig håndposition. SmartResQ/CorPatch<sup>®</sup> har vurderet tekniske risici som en del af den lovbestemte risikostyring for medicinsk udstyr, men der gives ingen garanti for fejl, der går ud over dette. I tilfælde af uventet fejlfunktion af SmartResQ/CorPatch<sup>®</sup>-systemet, eller hvis det opfører sig mærkeligt, skal brugeren udføre hjerte-lunge-redning manuelt. I så fald er SmartResQ/CorPatch<sup>®</sup> ikke ansvarlig, da det er uden for SmartResQ/CorPatch<sup>®</sup>'s kontrol. 
<br>
SmartResQ/CorPatch<sup>®</sup> overvåger batteriniveauer og produktets tilstand, men hvis batteriet løber tør for strøm, fungerer produktet ikke. Det er alene brugerens ansvar at sikre, at enheden er opdateret, ikke beskadiget og har tilstrækkeligt batteri til at fungere korrekt. Dette kan nemt gøres ved at udføre en træning, der validerer korrekt funktionalitet. Vi anbefaler træning i 4 minutter hver 3. måned med din CorPatch<sup>®</sup>. </p>

 

<p><b>Vigtigt!</b> Produktet CorPatch<sup>®</sup> må kun bruges på en person i en virkelig situation, og kun hvis en person har hjertestop. Det er ikke beregnet til at blive brugt på personer, der har f.eks. slagtilfælde, hjerteanfald eller andre hjerterelaterede sygdomme, der ikke er hjertestop. CorPatch<sup>®</sup> er ikke beregnet til at blive brugt på en person, der ligger på et blødt underlag som f.eks. en sofa eller seng, da feedback om dybde under sådanne forhold kan være unøjagtig. Ingen SmartResQ/CorPatch<sup>®</sup>-løsninger er beregnet til brug i et miljø i bevægelse, herunder bl.a. luft-, båd- eller vejambulance. Hvis enheden anvendes under patienttransport eller løftes/fjernes fra kroppen under hjerte-lunge-redning, kan den give unøjagtig feedback. CorPatch<sup>®</sup> skal fastgøres på patientens brystkasse med patchet. Sørg for, at patienten ligger på et fast, plant underlag, der ikke bevæger sig, og at CorPatch<sup>®</sup> er fastgjort på brystkassen med patchet. </p>

 

<p>Produktet CorPatch<sup>®</sup> Trainer må alene anvendes på dukker eller lignende i Training Sessions og aldrig i virkelige situationer på et rigtigt menneske med hjertestop eller andre hjerterelaterede sygdomme. </p>

 

<p>Det er muligt regelmæssigt at træne HLR i forhold til brystkompressioner ved hjælp af CorPatch<sup>®</sup> eller CorPatch<sup>®</sup> Trainer. Vi anbefaler, at du øver dig på en dukke, men hvis du ikke har adgang til en sådan, kan du i stedet anvende en kontorstol med tilstrækkelig affjedring eller en hård sofa. Overvej elasticiteten og hårdheden af den genstand, du øver dig på. SmartResQ anbefaler ikke brug af bløde genstande, herunder bl.a. puder eller bløde sofaer, til træningsformål, da det ikke giver den rette brugeroplevelse. </p>


<p>Hvis du ikke kan skaffe en dukke eller lignende til træning, kan du overveje at træne den situation, der opstår, inden du begynder på brystkompressioner. Dette kunne være at følge de anvisninger, som den gratis app giver i at identificere et hjertestop og ringe til alarmcentralen samt tage CorPatch<sup>®</sup> ud af nøgleringen. I så fald vil du være klar til hurtigt at påsætte CorPatch<sup>®</sup>, hvis du skulle overvære et hjertestop. </p>

 

<p>Brug ikke SmartResQ/CorPatch<sup>®</sup>-systemet til træning eller "for sjov" på personer (levende eller døde), kæledyr eller andre levende væsener. </p>

 

<h3>Ansvarsfraskrivelse for produkter, website(s) og apps </h3>

<p>SmartResQ/CorPatch<sup>®</sup> udsteder ingen erklæringer eller garantier og er ikke ansvarlig for kompetencerne hos personer, der modtager undervisning og/eller medicinsk træning via eller baseret på systemet eller for udøvelsen af deres færdigheder efter afslutningen af eventuel træning, kurser eller undervisningsplan med anvendelse af systemet. SmartResQ/CorPatch<sup>®</sup> garanterer ikke, at personer, som modtager undervisning og/eller medicinsk træning fra systemet, vil opnå et bestemt færdighedsniveau eller den nødvendige kompetence til at kvalificere sig til en licens, certifikater eller bedømmelser udstedt af en tilsynsmyndighed eller statslig myndighed. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> og dets associerede selskaber forsøger at være så nøjagtige som muligt. SmartResQ/CorPatch<sup>®</sup> giver imidlertid ikke nogen erklæringer eller garantier for, at systemet og informationen, produkterne og træningen, der anføres heri: (a) altid eller i det hele taget vil være tilgængelige; eller (b) er fri for fejl, komplette, nøjagtige, sande, opdaterede og/eller ikke-vildledende. Når du bruger systemet, er du bevidst om, at du fraskriver dig ethvert krav, som du måtte have mod SmartResQ/CorPatch<sup>®</sup> for at have stolet på information eller træning præsenteret i systemet. </p>

 

<p>Vores produkter er ikke beregnet til at blive brugt af børn. Produkterne er små og farverige og kan forveksles med legetøj, men SmartResQ/CorPatch<sup>®</sup>-produkter er ikke legetøj! Vi anbefaler, at man ikke at lader produkterne ligge frit fremme og tilgængelige for børn. SmartResQ/CorPatch<sup>®</sup> påtager sig ikke noget ansvar for børns brug af produkterne. En ansvarlig voksen skal give tilladelse til og overvåge børns eller teenageres brug af CorPatch<sup>®</sup>, f.eks. forældre, familiemedlemmer eller lærere. </p>

 

<p>Fraskrivelse af garanti og ansvarsbegrænsning, dette website leveres af SmartResQ/CorPatch<sup>®</sup> "som det er" og "forefindes". SmartResQ/CorPatch<sup>®</sup> udsteder ingen erklæringer eller garantier af nogen art, hverken udtrykkelige eller underforståede, vedrørende driften af dette website eller den information, det indhold og materiale eller de produkter, der ligger på det. Du accepterer udtrykkeligt, at din brug af websitet alene sker for egen risiko. 
<br>
Så vidt det er tilladt i henhold til gældende lov, fraskriver SmartResQ/CorPatch<sup>®</sup> sig alle garantier, både udtrykkelige og underforståede, herunder bl.a. underforståede garantier for salgbarhed og egnethed til et bestemt formål. SmartResQ/CorPatch<sup>®</sup> garanterer ikke, at dette website, dets servere eller e-mails sendt fra SmartResQ/CorPatch<sup>®</sup> er fri for virus/spam eller andre skadelige komponenter. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> hæfter ikke for skade af nogen art som følge af brugen af dette website eller dets produkter, herunder bl.a. direkte, indirekte og afledte skader, skadeserstatning og følgeskader. 
<br>
Lovgivningen i visse stater tillader ikke begrænsninger i underforståede garantier eller udelukkelse eller begrænsning af visse erstatninger. Hvis disse love gælder for dig, er det muligt, at nogle af eller alle ovenstående ansvarsfraskrivelser, udelukkelser eller begrænsninger ikke gælder for dig, og du kan have yderligere rettigheder. </p>

 

<p>Informationen på dette website skal ikke fortolkes som professionel rådgivning. Du bør altid rådføre dig med professionelle rådgivere, der er bekendt med netop din situation, for at få råd om særlige forhold, inden du træffer en beslutning. </p>

 

<p>Websitet kan indeholde links, som fører til websites, der drives af personer eller virksomheder, som SmartResQ/CorPatch<sup>®</sup> ikke har nogen kontrol over. SmartResQ/CorPatch<sup>®</sup> udsteder ingen erklæringer eller garantier vedrørende nøjagtigheden eller andre aspekter af den information, der ligger på sådanne websites. </p>

 

<p>Ansvaret for eventuelle holdninger, råd, udsagn eller anden information i artikler eller tekster på dette website ligger alene hos forfatteren og afspejler ikke nødvendigvis SmartResQ/CorPatch<sup>®</sup>'s holdninger og politikker. </p>

 

<h3>Juridisk ansvarsfraskrivelse </h3>

<p>Når du køber, licenserer, kigger på, bruger og/eller tilgår vores website(s), produkter og apps, anerkender du og accepterer, at: </p>
<ol>
<li>De systemer, der leveres af SmartResQ/CorPatch<sup>®</sup>, er specifikke produkter, som alene må bruges til de formål, der er anført i produktmanualen. Læs brugsanvisningerne og brugermanualerne nøje og sørg for at gøre dig bekendt med vores medicinske produkter inden brug.  </li>

<li>De systemer, der leveres af SmartResQ/CorPatch<sup>®</sup>, er særlige produkter og værktøjer til undervisning og medicinsk træning, som ikke er certificeret som medicinsk udstyr, medmindre andet er udtrykkeligt anført; de er ikke beregnet til klinisk eller diagnostisk brug men alene til medicinsk træning og forbedring af færdigheder. </li>

<li>Du til enhver tid kun vil anvende og tilgå systemet i forbindelse med sådan medicinsk træning og forbedring af færdigheder; i overensstemmelse med alle gældende love og bestemmelser; og i overensstemmelse med brugerdokumentation, instruktionsmanualer, vejledninger og/eller krav, som vi udleverer til dig elektronisk eller personligt. </li>

<li>Et SmartResQ/CorPatch<sup>®</sup>-system ikke på noget tidspunkt alene kan diagnosticere, behandle eller kurere et menneskes tilstand eller i en livreddende situation; understøtte professionelle medicinske beslutninger, diagnoser eller behandlinger eller erstatte en diagnose, anbefaling, rådgivning, behandling eller beslutning truffet af en behørigt uddannet og autoriseret læge. </li>

<li>Hvis en enhed sættes i forbindelse med patientskade eller patientudfald, betyder det ikke, at enheden forårsagede skaden eller udfaldet. </li>

<li>SmartResQ/CorPatch<sup>®</sup> ikke påtager sig noget ansvar for skade som følge af uhensigtsmæssig brug af vores produkter eller brug ud over den tilsigtede anvendelse af produktet. </li>
</ol>
 

<h3>Oplysninger om funktion </h3>

<p>Informationen heri er alene præsenteret som en guide til anvendelse af SmartResQ/CorPatch<sup>®</sup>-produkter. SmartResQ/CorPatch<sup>®</sup> arbejder løbende på at forbedre kvaliteten og pålideligheden af sine produkter. Vores enheder kan imidlertid fungere forkert eller svigte på grund af deres indbyggede elektriske følsomhed og sårbarhed over for fysisk belastning og ugunstige kommunikationssignaler. Det er købers ansvar ved brug af SmartResQ/CorPatch<sup>®</sup>-produkter at overholde standarder for sikkerhed og afprøvning og at undgå situationer, hvor et funktionssvigt kan medføre personskade eller skade på ejendom. </p>

 

<h3>Standardgaranti </h3>

<p>SmartResQ/CorPatch<sup>®</sup> Standard Limited Warranty er betinget af korrekt brug af produkterne, website(s) og apps. 
<br>
Denne garanti er begrænset til den første køber af produktet, og kun hvis produktet købes hos en autoriseret SmartResQ/CorPatch<sup>®</sup>-forhandler. Andre producenter, leverandører eller udgivere end SmartResQ/CorPatch<sup>®</sup> kan udstede deres egne garantier til dig – kontakt dem for yderligere information. </p>

 

<p><u>Garantien dækker ikke: </u></p>
<ol>
<li>defekter eller skade som følge af uheld, forkert brug, unormal brug, unormale forhold, forkert opbevaring, udsættelse for væske, fugt, sand eller snavs, forsømmelse eller usædvanlig fysisk, elektrisk eller elektromekanisk belastning, </li>

<li>defekter eller skade som følge af overdreven brug af kraft eller brug af metalgenstande, </li>

<li>udstyr, som har fået produktionsnummeret eller forbedringsdatakoden fjernet, udvisket, beskadiget, ændret eller gjort ulæseligt, </li>

<li>almindelig slitage eller normal ældning af produktet, </li>

<li>ridser, buler og kosmetiske skader, medmindre fejlen er opstået på grund af materiale- eller fabrikationsfejl, </li>

<li>defekter eller skader som følge af brug af produkterne i forbindelse med tilbehør, produkter eller ekstraudstyr/periferiudstyr, der ikke er leveret eller godkendt af SmartResQ/CorPatch<sup>®</sup>, </li>

<li>fejl eller skader som følge af ukorrekt afprøvning, drift, vedligeholdelse, installation, service eller justering, som ikke er leveret eller godkendt af SmartResQ/CorPatch<sup>®</sup>, </li>

<li>skader forårsaget af betjening af SmartResQ/CorPatch<sup>®</sup>-produktet uden for de udsendte retningslinjer, </li>

<li>demonstration/installation af det købte produkt, </li>

<li>defekter eller skader som følge af eksterne årsager som f.eks. sammenstød med en genstand, brand, oversvømmelse, snavs, storm, lynnedslag, jordskælv, udsættelse for vejrforhold, tyveri, sprunget sikring eller forkert brug af en elektrisk kilde, </li>

<li>defekter eller skader som følge af overførsel af virus eller andre softwareproblemer, der er indført i produkterne, </li>

<li>hvis batterierne oplades med andre opladere end dem, der passer til CorPatch<sup>®</sup> Trainer, </li>

<li>hvis forseglingerne på batterihuset eller -cellerne er brudt eller viser tegn på manipulation, </li>

<li>produkter, der er repareret af, brugt eller købt hos et andet firma end SmartResQ/CorPatch<sup>®</sup>, </li>

<li>hvis SmartResQ/CorPatch<sup>®</sup> modtager information fra relevante offentlige myndigheder om, at produktet er stjålet, eller hvis du ikke kan deaktivere adgangskodeaktiverede eller andre sikkerhedsmæssige foranstaltninger, der er beregnet til at forhindre uautoriseret adgang til produktet, eller du ikke kan bevise, at du er en autoriseret bruger af produktet, </li>

<li>hvis produkterne anvendes uden for de angivne betingelser, der er anført i manualerne, f.eks. temperaturområde, tryk eller fugtighed. </li>
</ol>
 

<h3>Batterier og opladere </h3>

<p>SmartResQ/CorPatch<sup>®</sup>-produkter indeholder enten ikke-udskiftelige batterier (CorPatch<sup>®</sup>) eller genopladelige batterier (CorPatch<sup>®</sup> Trainer). De typer af batterier, der anvendes i vores produkter, er beskrevet under hvert enkelt produkt. SmartResQ/CorPatch<sup>®</sup> påtager sig intet ansvar, hvis de genopladelige batterier ikke håndteres korrekt i henhold til brugermanualen. </p>

 

<p>I forbindelse med salg af enheder, der indeholder batterier, er vi forpligtet til at gøre dig opmærksom på følgende: 
<br>
Som slutbruger er du juridisk forpligtet til at bortskaffe dem korrekt. Symbolet med den overstregede skraldespand betyder, at batteriet ikke må smides ud med husholdningsaffaldet. </p>

</div>            

<!--This is the 5th section (beige) - title: Bestilling -->
<div class="beige">
<h2>Bestilling </h2>

<p>Corpatch.coms webshop er åben 24 timer i døgnet, og du kan købe næsten når som helst. Vi kan dog lukke butikken i forbindelse med vedligeholdelse. Køb i store mængder kan ske direkte hos SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> tilbyder ikke produkter til salg til mindreårige. Produkter, der er beregnet til børn, kan kun købes af voksne. For at købe hos SmartResQ/CorPatch<sup>®</sup> skal du være mindst 18 år og være i besiddelse af et gyldigt kreditkort eller et andet betalingsmiddel, som vi accepterer. </p>

 

<p>Præsentationen af produkterne i webshoppen er ikke et juridisk bindende tilbud, men et uforpligtende online-katalog. Når du er klar til at handle, skal du vælge de varer, du ønsker at købe, og lægge dem i “indkøbskurven”. Du kan redigere indholdet af indkøbskurven helt indtil bestillingstidspunktet. Eventuelle ekstra omkostninger som f.eks. forsendelses- eller betalingskortgebyrer beregnes umiddelbart, før du betaler. </p>

 

<p>Når du er klar til at bestille, skal du klikke på “Kassen” og indtaste relevante oplysninger. Du kan ændre indholdet af indkøbskurven, lige indtil du bekræfter dit køb, ved at klikke på knappen “Betal”. Herefter afgiver du en bindende ordre på de varer, der er indeholdt i indkøbskurven, som ikke længere kan ændres. 
<br>
SmartResQ/CorPatch<sup>®</sup> kan acceptere ordren ved at sende en ordrebekræftelse pr. e-mail eller ved at levere varerne inden for leveringsfristen. </p>

 

<p>Visse lande kan forhindre brugen og ejerskabet af vores produkter. Du er alene ansvarlig for at finde ud af, om dette produkt er lovligt at importere og/eller bruge i dit land. Vi sender dig de produkter, du bestiller, og kan ikke påtage os noget ansvar for toldproblemer eller eventuelle konsekvenser af dit ejerskab eller brug af denne enhed. </p>

</div>

<!--This is the 6th section (white) - title: Betaling-->
<div class="white">
<h2>Betaling </h2>

<h3>Vores website(s), app(s) og webshop(s) </h3>

<p>Vores website(s) og app(s) er gratis at bruge, så længe vores juridiske politikker accepteres og overholdes. Vær opmærksom på, at køb af vores produkter kan være tilgængelige i webshop(s) på vores website(s) og i app(s). </p>

 

<h3>Vores produkter </h3>

<p>SmartResQ/CorPatch<sup>®</sup> bruger QuickPay som betalingsgateway. QuickPay er certificeret af Payment Card Industry (PCI) Security Standards Council efter den seneste udgave af PCI Data Security Standard (DSS) Level 1, som omfatter: (a) en årlig rapport – “Report on Compliance” (ROC) udført af en Qualified Security Assessor (QSA); (b) kvartalsvise netværksscanninger udført af Approved Scan Vendor (ASV) og (c) et stort antal regler og retningslinjer for arbejdsgang og behandling af oplysninger. </p>

 

<p>Vi accepterer betaling med: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>MasterCard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
 

<p>Betalingerne trækkes fra din konto ved forsendelse af varerne. Alle beløb er i euro, og den lovpligtige moms er allerede inkluderet i alle de nævnte priser. Alle indehavere af kredit-/debitkort er underlagt valideringskontrol og godkendelse fra kortudstederen eller betalingsudbyderen. SmartResQ/CorPatch<sup>®</sup> er ikke ansvarlig i tilfælde af, at din betalingskortudbyder nægter at godkende betalinger. </p>


<p>Vi anvender kryptering af kreditkortoplysninger via SSL-protokollen (Secure Socket Layer). Dette sikrer, at andre ikke kan opsnappe kreditkortnummeret eller andre oplysninger under transaktionen med vores udbyder. </p>

 

<p>Inden købet afsluttes, skal kunden gennemgå og acceptere de forsendelses- og logistikomkostninger, der er tilføjet til købsprisen, da kunden skal betale disse omkostninger. Når du har afgivet en ordre, modtager du en e-mail fra os med en bekræftelse på, at vi har modtaget din ordre. Bemærk venligst, at dette ikke betyder, at din ordre er blevet accepteret. Din ordre udgør et tilbud til SmartResQ/CorPatch<sup>®</sup> om at købe et produkt (og SmartResQ/CorPatch<sup>®</sup> forbeholder sig ret til at afvise ordrer på produkter). Der vil ikke være nogen kontrakt i forbindelse med produkterne, før vi har bekræftet over for dig pr. e-mail, at produktet/produkterne er afsendt. Vores accept af dit tilbud anses for at være fuldført, og kontrakten mellem os er indgået, når vi sender dig en bekræftelsesmail om afsendelse. </p>

 

<p>Forsendelsesomkostningerne er altid angivet i forhold til hver enkelt ordre. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> beholder ejendomsretten til den købte vare, indtil fakturabeløbet er betalt fuldt ud af kunden og automatisk “trukket” lige før forsendelse. </p>

 

<p>Kunden er kun berettiget til at foretage modregning, hvis dennes modkrav er retligt fastslået eller er ubestridt eller anerkendt af SmartResQ/CorPatch<sup>®</sup>. Desuden har kunderne kun en tilbageholdelsesret, hvis og i det omfang deres modkrav er baseret på det samme kontraktforhold. </p>

 

<p>Hvis kunden er i restance hos os med betalingsforpligtelser, forfalder alle eksisterende krav straks. </p>

 

<h3>Tillægsgebyrer </h3>

<p>Pr. 1. januar 2018 er reglerne for tillægsgebyrer blevet ændret. Det er derfor ikke længere lovligt at opkræve tillægsgebyrer på betalinger fra forbrugerkort, hvis de er udstedt af banker/kortudstedere inden for EU. Dette gælder både for debet- og kreditkort. Forbrugerkort er kort, der er udstedt til en privat forbruger. 
<br>
Hvis kortet imidlertid enten er et erhvervskort eller et forbrugerkort udstedt uden for EU, vil transaktionsgebyret blive pålagt et tillægsgebyr. Det betyder, at kortindehaveren automatisk betaler transaktionsgebyret. 
<br>
Gebyret vil ikke være højere end det gebyr, som SmartResQ/CorPatch<sup>®</sup> opkræves af indløseren. Gebyret vil tydeligt blive vist som en separat post i betalingsvinduet. </p>

</div>

<!--This is the 7th section (beige) - title: Levering-->
<div class="beige">
<h2>Levering </h2>

<p>Vi bestræber os på at sende ordrer fra den ene hverdag til den anden og bruger et internationalt anerkendt forsendelsesfirma. Du finder den samlede pris for dit køb inklusiv levering ved Tjek ud, før du accepterer din endelige ordre. </p>

 

<p>Hvis kunden ikke tager imod varerne, kan SmartResQ/CorPatch<sup>®</sup> udtræde af købskontrakten eller modtage erstatning for misligholdelse efter en periode på to uger for at dække håndterings- og forsendelsesomkostninger. </p>

 

<p>Hvis kunden har givet forkerte oplysninger om leveringsadressen, er det muligt at afhente pakken i den pakkeshop, der er anført i vores webshop. Ellers er pakken tabt. </p>

 

<p>Kunden vil aldrig modtage delleverancer, medmindre SmartResQ/CorPatch<sup>®</sup> udtrykkeligt har givet besked herom. </p>

 

<h3>Risiko for tab </h3>

<p>Risikoen for ejerskab af produktet overgår til køberen, når produktet stilles til rådighed for køberen i henhold til denne aftale. Hvis leveringstidspunktet er overskredet, og køber ikke modtager et produkt, der er gjort tilgængeligt eller stillet til rådighed for denne i henhold til aftalen, bærer køberen risikoen for tab eller skade som følge af egenskaber ved selve produktet. </p>

</div>

<!--This is the 8th section (white) - title: Annullering og returnering-->
<div class="white">
<h2>Annullering og returnering </h2>

<p>Når du handler med SmartResQ/CorPatch<sup>®</sup> online eller offline, har du 14 dage til at fortryde og annullere, hvor du kan informere os om, at du har ombestemt dig, og returnere varen til os i samme stand som den er modtaget. 
<br>
Vi accepterer kun ubrugte returvarer i original, forseglet og ubeskadiget emballage, og produkterne skal være forsvarligt pakket til forsendelse. Ellers anses de for at være brugt, og der gives ingen delvis refundering. Fortrydelsesretten gælder kun for uforseglede produkter ved modtagelse. </p>

 

<h3>Acceptable grunde til at returnere et produkt </h3>
<ul>
<li>Udøvelse af 14 dages fortrydelsesret </li>

<li>Produktet er ikke som beskrevet (garanti) </li>

<li>Produktet er defekt </li>
</ul>
 

<p>Vilkårene for returnering af CorPatch<sup>®</sup> følger EU's standardregler. </p>

 

 

<p>Hvis du returnerer produktet, skal du beholde den originale emballage og må ikke beskadige, påsætte klistermærker eller skrive på det. Anskaf og brug velegnet returemballage f.eks. en papkasse. </p>

 

<p>For CorPatch<sup>®</sup> Trainer følger fortrydelsesbetingelserne for dette produkt de almindelige EU-regler. </p>

 

<p>For at udøve fortrydelsesretten skal du give os besked inden for 14 dage efter modtagelse af varerne. Anmodninger om annullering skal sendes pr. e-mail til info@corpatch.com med tydelig angivelse af, at du ønsker at gøre brug af din fortrydelsesret og hvorfor. </p>

 

<p>Vi forventer, at du returnerer varerne så hurtigt som muligt efter, at du har givet meddelelse om brug af fortrydelsesretten, og senest 14 dage efter, at du har informeret os pr. e-mail. </p>

 

<p>Vi kan afvise tilbagebetalingen, indtil du har returneret varerne eller bevist, at du har returneret varerne. Til denne tilbagebetaling vil vi bruge den samme betalingsmetode som ved den oprindelige transaktion. </p>

 

<h3>Ikke-acceptable grunde til at returnere et produkt </h3>
<ul>
<li>Du ombestemmer dig efter de 14 dages fortrydelsesret. </li>

<li>Hvis produktet er blevet aktiveret. </li>

<li>Hvis produktet er brugt eller beskadiget på anden måde. </li>

<li>Hvis softwaren/den gratis app er downloadet, forbundet til, installeret eller på anden måde kombineret med det/de fysiske produkt(er). </li>
</ul>
 

<h3>Sådan returnerer du </h3>

<p>Vi accepterer kun returvarer i uåbnet, original og ubeskadiget emballage, og produkterne skal være forsvarligt pakket til forsendelse. Ellers anses de for at være brugt, og der gives ingen refundering. </p>

 

<p><u>Medmindre andet er angivet, skal returvarer sendes til: </u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg 
<br>
Danmark </p>

 

<p><b>VIGTIGT!</b> Du er eneansvarlig for emballagekvaliteten og varerne, indtil de er modtaget af os. Du bedes gemme postkvitteringen, herunder oplysninger om forsendelsesomkostninger og evt. track and trace-nummeret. Vi dækker ikke returforsendelsesomkostninger, og vi accepterer ikke pakker, der sendes pr. efterkrav eller lignende. </p>

</div>

<!--This is the 9th section (beige) - title: Refundering-->
<div class="beige"> 
<h2>Refundering </h2>

<p>SmartResQ/CorPatch<sup>®</sup> er forpligtet til at reparere, erstatte eller give et prisnedslag eller fuld refundering, hvis varen viser sig at være defekt inden for 2 år efter, at du har købt varen. 
<br>
Kunden har ikke ret til refundering, hvis der er tale om et mindre problem, f.eks. ridser på varen eller lignende. 
<br>
Når SmartResQ/CorPatch<sup>®</sup> modtager kundens vare, iværksættes en refundering. Værdien af refunderingsbeløbet afhænger af varens tilstand, når den modtages hos SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>Den måde, hvorpå kundens refusion behandles, afhænger af den oprindelige betalingsmetode. Hvis kunden har betalt med kredit- eller betalingskort, sendes refusionen til den kortudstedende bank inden for 5 hverdage efter modtagelsen af den returnerede vare eller anmodningen om annullering. Kontakt venligst den kortudstedende bank, hvis du har spørgsmål om, hvornår beløbet vil blive krediteret din konto </p>

</div>

<!--This is the 10th section (white) - title: Databehandling af personoplysninger-->
<div class="white">
<h2>Databehandling af personoplysninger </h2>

<p>Hos SmartResQ/CorPatch<sup>®</sup> værdsætter vi dine personoplysninger via vores tretrins-indsats for overholdelse af datafortrolighed: a. opretholdelse af et detaljeret kort over vores dataflow, b. udførelse af en juridisk vurdering baseret på dataflow for at, c. implementere de nødvendige sikkerhedsforanstaltninger for at beskytte dine oplysninger. </p>

 

<p>For at kunne bruge vores webshop skal du som minimum give følgende oplysninger: </p>
<ul>
<li>Navn </li>

<li>Adresse </li>

<li>E-mailadresse </li>

<li>Telefonnummer </li>
</ul>
 

<p>Indsamlingen af personlige kundeoplysninger finder sted inden for rammerne af den eksisterende lovgivning og EU's Databeskyttelsesforordning (GDPR). </p>

 

<p>Hvis du vil vide mere om vores behandling af dine personoplysninger, kan du læse vores privatlivspolitik. </p>

</div>

<!--this is the 11th section (beige) - title: Klager-->
<div class="beige">
<h2>Klager </h2>

<h3>Sådan klager du til os </h3>

<p>Hvis der er noget galt med produktet, kan du enten få et defekt produkt repareret eller udskiftet, få pengene tilbage eller et prisnedslag, afhængigt af den specifikke situation. 
<br>
Det er naturligvis et krav, at klagen er berettiget, og at fejlen ikke skyldes forkert brug af produktet eller anden ukorrekt adfærd. </p>

 

<p>Vi anbefaler, at du indgiver klagen så hurtigt som muligt og inden for en uge efter, at fejlen er blevet opdaget.
<br>
Du er velkommen til at kontakte os med spørgsmål, kommentarer eller klager ved at kontakte os via e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

<h3>Klage til andre virksomheder indenfor EU </h3>

<p>Du kan besøge EU's officielle website for at klage til andre virksomheder i EU. Du kan finde oplysningerne her. </p>

</div>

<!--this is the 12th section (white) - title: Ændringer af dette dokument-->
<div class="white">
<h2>Ændringer af dette dokument </h2>

<h3>Vores website(s), app(s) og politikker </h3>

<p>SmartResQ/CorPatch<sup>®</sup> forbeholder sig til enhver tid, uanset årsag, ret til uden forudgående varsel til nogen at foretage ændringer i, slette, modificere eller supplere vores website(s), app(s), politikker og dokumenter. 
<br>
Hvis nogen af disse betingelser skønnes at være ugyldige eller uden retskraft, skal den pågældende betingelse anses for at være uafhængig og skal ikke påvirke gyldigheden og retskraften af de resterende betingelser. </p>

 

<p>Hvis vi ændrer vores politikker, vil vi offentliggøre de reviderede politikker her med en opdateret revisionsdato. Vi opfordrer dig til at gennemlæse politikkerne regelmæssigt. Hvis vi foretager væsentlige ændringer af vores politikker, der i betydelig grad ændrer vores politikpraksis, kan vi også informere dig på anden vis, f.eks. ved at sende en e-mail eller ved at poste en meddelelse på vores website og/eller sociale medier, inden ændringerne træder i kraft. </p>

 

<h3>Vedrørende et specifikt køb </h3>

<p>Når du køber et produkt, vil du blive bedt om at acceptere en version af visse dokumenter, som de foreligger på det nøjagtige tidspunkt – denne version vil ikke blive ændret efter dette tidspunkt og vil fastsætte betingelserne for vores forhold til dig vedrørende netop dette køb. </p>

</div>

<!--this is the 13th section (beige)- title: Lovvalg og værneting-->
<div class="beige">
<h2>Lovvalg og værneting </h2>

<h3>Lovgivningen i Danmark og byretten i Svendborg </h3>

 

<p>SmartResQ/CorPatch<sup>®</sup> anvender dansk lov og værneting i tilfælde af retlige tvister, men ikke CISG. 
<br>
Enhver tvist, der på nogen måde vedrører dit besøg på SmartResQ/CorPatch<sup>®</sup> eller produkter, som du køber via SmartResQ/CorPatch<sup>®</sup>, skal rejses på fortrolig vis i Danmark, dog således at hvis du på nogen måde har krænket eller truet med at krænke immaterielle rettigheder tilhørende SmartResQ/CorPatch<sup>®</sup>, kan SmartResQ/CorPatch<sup>®</sup> søge om afhjælpning ved forbud eller andet passende retsmiddel i et hvilket som helst land, og du giver dit samtykke til enekompetence og værneting for sådanne domstole. </p>

 

<p>Ved brud på aftalen om "Vilkår" vil der blive taget retslige skridt, og der kan blive lagt sag an ved domstolene. </p>

 

<p>Tvister mellem os og en forbruger er underlagt byretten i Svendborg, Christiansvej 41, 5700 Svendborg, Danmark </p>

</div>

<!--this is the 14th section (white) title: Kontaktoplysninger-->
<div class="white"> 
<h2>Kontaktoplysninger </h2>

<p>Tak fordi du læste SmartResQ/CorPatch<sup>®</sup>'s "Vilkår".
<br>
Hvis du har nogen spørgsmål, kommentarer eller klager, er du velkommen til at kontakte os. </p>

 

<p>Vores virksomhed er beliggende på adressen: Lundevej 26, 5700 Svendborg </p>

 

<p>Du kan finde os i det Centrale Virksomhedsregister under CVR-nr. 38674102 </p>

 

<p>Du kan kontakte os på tlf. +45 62 200 100 </p>

 

<p>eller </p>

 

<p>skrive en e-mail til os på: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<p><b>©SmartResQ ApS – Alle rettigheder forbeholdes 
<br>
Danmark, Version 2.1 – Udsendt 2023.04.25 </b></p>


</div>
   
<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->
   



<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Privacy Policy -->
<div class="beige">
<h1 id="policy">Privatlivspolitik for SmartResQ / CorPatch<sup>®</sup> 

<!--This is the 16th section (beige) = joined together with the 15th section - title: Vi behandler dine oplysninger i overensstemmelse med GDPR-->
<h2>Vi behandler dine oplysninger i overensstemmelse med GDPR </h2>

<p>SmartResQ/CorPatch<sup>®</sup> respekterer dit privatliv. Denne privatlivspolitik beskriver dine privatlivsrettigheder og vores forpligtelse til at beskytte dine personoplysninger. </p>

<p>Kontakt os, hvis du har spørgsmål vedrørende vores behandling af dine personoplysninger. Den dataansvarlige er: </p>

<p><b>Selskab:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Adresse: </b>
<br>
Lundevej 26 
<br>
5700 Svendborg 
<br>
Danmark </p>

 

<p><b>CVR-nr.:</b> 38674102 </p>

<p><b>Telefonnr.:</b> +45 62 200 100 </p>

<p><b>E-mail:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> er en dansk/europæisk virksomhed med selskaber, forretningsprocesser, ledelsesstrukturer og tekniske systemer på tværs af nationale grænser. SmartResQ/CorPatch<sup>®</sup> leverer produkter, software og tjenester til offentlige/private virksomheder i Europa. </p>

<p>Hovedkontoret ligger i Danmark, og SmartResQ/CorPatch<sup>®</sup> er underlagt den europæiske databeskyttelseslovgivning, herunder Databeskyttelsesforordningen (GDPR). Alle større beslutninger i SmartResQ/CorPatch<sup>®</sup> vedrørende beskyttelse af personoplysninger træffes på ledelsesniveau under tilsyn af databeskyttelsesrådgiveren. </p>

<p>Denne privatlivspolitik er tilgængelig på vores websites og i vores apps. </p>

<p>Brug ikke SmartResQ/CorPatch<sup>®</sup>-siderne, -apps eller vores tjenester, hvis du ikke kan acceptere den måde, vi behandler personoplysninger på i henhold til denne privatlivspolitik. </p>

</div>

<!--This is the 17th section (white) - title: Typer af indsamlede personoplysninger -->
<div class="white">
<h2>Typer af indsamlede personoplysninger  </h2>

<p>Når en behandler bestemmer formålene med og metoderne til behandling af dine personoplysninger, fungerer behandleren som dataansvarlig. Dette omfatter scenarier, hvor SmartResQ/CorPatch<sup>®</sup> indsamler personoplysninger, når du er jobansøger, kunderepræsentant eller et lead, eller når du er softwarebruger. </p>

<p>SmartResQ behandler personoplysninger til en række forskellige formål afhængigt af vores forhold til dig. </p>

<p>Vi kan behandle: </p>
<ol>
<li>almindelige kontaktoplysninger som f.eks. navn, adresse, telefonnummer (mobil og/eller fastnet) og e-mail, </li>

<li>ansættelsesoplysninger som f.eks. arbejdsgiver, titel, stilling, herunder præferencer og interesser i professionel sammenhæng, </li>

<li>feedback, kommentarer eller spørgsmål om SmartResQ/CorPatch<sup>®</sup> eller vores produkter og tjenester, </li>

<li>fotos eller video optaget på vores lokationer, </li>

<li>indhold, som du har uploadet, f.eks. fotos, videoer og resultater over tid, </li>

<li>unikke brugeroplysninger som f.eks. login-ID, brugernavn, adgangskode og sikkerhedsspørgsmål, </li>

<li>økonomiske oplysninger, hvor du accepterer, at vi bruger dine oplysninger til f.eks. opbevaring af dine betalingskortoplysninger, </li>

<li>trafikoplysninger fra din webbrowser som f.eks. browsertype, enhed, sprog og adressen på det website, hvor du kom fra, samt andre trafikoplysninger, herunder IP-adresse, </li>

<li>clickstream-adfærd og -aktivitet på SmartResQ/CorPatch<sup>®</sup> ID'er og i vores produkter og tjenester, </li>

<li>e-mail-adfærd som f.eks. e-mails fra SmartResQ/CorPatch<sup>®</sup>, som du åbner på et hvilket som helst tidspunkt og på en hvilken som helst måde, </li>

<li>andre personoplysninger i din profil, som du frivilligt har lagt ud på tredjeparts sociale netværk, f.eks. LinkedIn, Facebook, Google etc., </li>

<li>oplysninger, der anvendes til videnskabelige formål for at forbedre overlevelsen efter hjertestop og indsamles via vores website(s) og apps, </li>

<li>oplysninger om brugere, til hvem der skal leveres produkter, for at overholde kvalitets- og sikkerhedskrav, levere tjenester til brugerne og vedligeholde og forbedre vores tilbud, </li>

<li>oplysninger om jobansøgere for at behandle jobansøgninger, kommunikere om fremtidige jobtilbud og vedligeholde og forbedre vores rekrutteringsprocesser, </li>

<li>oplysninger om personer, der har tilmeldt sig nyhedsbreve og andet materiale, for at levere materialerne og vedligeholde og forbedre vores tilbud, </li>

<li>oplysninger om cookies for at levere skræddersyede reklamer på sociale medier og websites. </li>
</ol>

</div>

<!--This is the 18th section (beige) - tile:Data, der indsamles og behandles i CorPatch<sup>®</sup> Services-platformen og apps -->
<div class="beige">
<h2>Data, der indsamles og behandles i CorPatch<sup>®</sup> Services-platformen og apps </h2>

<p>SmartResQ behandler, indsamler og opbevarer følgende personoplysninger, når du bruger CorPatch<sup>®</sup> Services-platformen eller apps. </p>

 
 

<h3>Alle brugere (Institute Admin, Trainer, Trainee/slutbruger) </h3>
<ul>
<li>Fornavn (hvis indtastet) </li>

<li>Efternavn (hvis indtastet) </li>

<li>Kaldenavne (hvis indtastet) </li>

<li>E-mailadresse (obligatorisk) </li>

<li>Foretrukket kommunikationssprog (obligatorisk) </li>

<li>Adgangskode-hash (obligatorisk) </li>

<li>Om e-mailadressen er valideret (obligatorisk) </li>
</ul>
 

<h3>Yderligere for trainees/slutbrugere (obligatorisk) </h3>

<p>Oplysninger om den benyttede mobiltelefon: </p>

<p>Styresystem (Android/iOS): </p>
<ul>
<li>Styresystemversion (f.eks. 9) </li>

<li>Producent (f.eks. Samsung) </li>

<li>Model (f.eks. SM-T518) </li>

<li>App-version (f.eks. 1.2.4) </li>

<li>Tidspunktet for seneste forgrundsaktivitet i appen </li>

<li>Tidspunktet for seneste baggrundsaktivitet i appen </li>
</ul>
 

<p>Data om den CorPatch<sup>®</sup> (CPS), der bruges: </p>
<ul>
<li>Serienummer / MAC-adresse </li>

<li>Firmwareversion </li>

<li>Modelnavn (f.eks. CPS_01) </li>

<li>Producent (på nuværende tidspunkt altid SRQ) </li>

<li>Navn (på nuværende tidspunkt altid CorPatch<sup>®</sup>) </li>

<li>Batteritilstand </li>

<li>Fejl </li>
</ul>
 

<p>Oplysninger om onboarding af brugere: </p>
<ul>
<li>Tutorial gennemført (ja/nej) </li>

<li>Brugsbetingelser accepteret (ja/nej) </li>

<li>Selvevaluering gennemført (ja/nej) </li>

<li>Testtræning gennemført (ja/nej) </li>

<li>Første login vellykket (ja/nej) </li>

<li>Var en CPS forbundet (ja/nej) </li>
</ul>
 

<p>Oplysninger indsamlet via træning: </p>
<ul>
<li>Dato, tidspunkt og varighed af træning </li>

<li>Træningsresultat </li>

<li>Træningstype eller træningsindstillinger </li>

<li>I tilfælde af træning i et institute, yderligere oplysninger om kurset, trainer og institute </li>
</ul>
 
 

<h3>Serverlogfiler </h3>

<p>Følgende oplysninger gemmes i webserverens logfiler: </p>
<ul>
<li>IP-adresse for den tilgående part </li>

<li>Browser-version for den tilgående part </li>

<li>Dato/tidspunkt for adgang </li>

<li>URL for adgang </li>
</ul>
<h3>Eksterne tjenester der behandler oplysninger: </h3>
<ul>
<li>Google/Firebase for fjernlogning, nedbrud og fejlanalyse </li>

<li>Google/Firebase for afsendelse af notifikationer </li>

<li>Sendgrid for afsendelse af e-mails </li>

<li>Hetzner Online GmbH for hosting af web-backend og database </li>
</ul>
 

<h3>Hvad sker der, når en bruger slettes? </h3>
<ul>
<li>Brugeren sletter sig selv i vores system på CorPatch<sup>®</sup> Services-websitet <a href="https://app.corpatch.com">https://app.corpatch.com</a></li>

<li>Brugeren markeres som slettet. Herefter kan brugeren ikke længere logge ind, er ikke længere synlig for admins etc., men brugeren eksisterer stadig i databasen. </li>

<li>Efter 14 dage slettes brugerens data automatisk fra databasen. </li>

<li>Med henblik på videnskabelig vurdering og forbedring af funktionaliteten vil oplysninger om træninger og brug af CorPatch<sup>®</sup> stadig ligge i databasen efter sletning af brugeren, men referencen (ID) til brugeren vil være tom, og alle referencer til personoplysninger vil blive fjernet.  </li>
</ul>

</div>

<!--This is the 19th section (white) - tile: Sådan indsamler vi dine personoplysninger-->
<div class="white">
<h2>Sådan indsamler vi dine personoplysninger </h2>

<p>De fleste af de personoplysninger, som vi behandler, får vi direkte fra dig. Vi indsamler oplysninger og behandler dem, når du: </p>
<ul>
<li>registrerer dig online eller afgiver en ordre på et af vores produkter eller tjenester, f.eks. demografiske oplysninger, e-mailadresse, betalingsoplysninger, varer, ordrebeløb, rabatniveau og hyppighed. Dette omfatter afsendelse af transaktionsrelaterede e-mails, f.eks. ordrebekræftelse, forsendelsesbekræftelse og refunderingsbekræftelse, </li>

<li>beskæftiger dig med den sendte kommunikation (e-mail, sms, direct mail eller telefon), f.eks. åbningsrate, click-rate og tid brugt på at læse e-mails, afsenderdomæne og type af e-mailklient, </li>

<li>frivilligt deltager i en kundeundersøgelse eller giver feedback på en af vores opslagstavler eller via e-mail. </li>
</ul>
<p>Vi kan også modtage personoplysninger indirekte fra følgende kilder i følgende scenarier: </p>
<ul>
<li>Fra cookies: når du besøger vores websites eller apps, f.eks. IP-adresse, land, besøgte sider, besøgte produkter, interaktion/clicks og søgninger. </li>

<li>Fra dig eller en anden person med tilknytning til vores kunde. Disse personer kan være en leder eller en kollega. Hvis den kunde, som du arbejder for, køber produkter eller tjenester fra SmartResQ/CorPatch<sup>®</sup> via en samarbejdspartner til SmartResQ/CorPatch<sup>®</sup>, kan vi indsamle oplysninger om dig fra samarbejdspartneren. </li>

<li>SmartResQ/CorPatch<sup>®</sup>'s markedsføringspartnere, offentlige kilder eller tredjeparters sociale netværk. </li>

<li>SmartResQ/CorPatch<sup>®</sup> vil kunne samkøre personoplysninger om dig indsamlet fra én kilde med oplysninger indhentet fra en anden kilde. Dette giver os et mere komplet billede af dig og gør det muligt for os at give dig en mere relevant og personlig service. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Sådan bruger vi dine oplysninger-->
<div class="beige">
<h2>Sådan bruger vi dine oplysninger </h2>

<p>For generelt at administrere vores kundeforhold og opfylde vores kundeforpligtelser har SmartResQ/CorPatch<sup>®</sup> brug for oplysninger om dig i din rolle som kunde, eller når du bruger en tjeneste. Formålene med at behandle sådanne personoplysninger er følgende: </p>

<ul>
<li>at behandle din ordre, administrere din konto, </li>

<li>at sende dig e-mails med særlige tilbud på andre produkter og tjenester, som vi tror, du vil kunne lide, </li>

<li>at udføre salgs- og kontraktprocessen for kunderne, </li>

<li>at levere de ønskede produkter og tjenester til kunderne, </li>

<li>at foretage levering i henhold til aftaler indgået med dig eller kunderne, </li>

<li>at tilbyde support til brugere af vores produkter og tjenester, </li>

<li>at forbedre og udvikle kvaliteten, funktionaliteten og brugeroplevelsen af vores produkter, tjenester og SmartResQ/CorPatch<sup>®</sup>-website(s) og -apps, </li>

<li>at opdage, begrænse og forebygge sikkerhedstrusler og udføre vedligeholdelse og fejlfinding og fejlretning, </li>

<li>at forhindre misbrug af vores produkter og tjenester, </li>

<li>at behandle ordrer, foretage fakturering, betalinger eller anden økonomisk opfølgning, </li>

<li>at oprette interesseprofiler for at reklamere for relevante produkter og tjenester, </li>

<li>at etablere brugergrupper for at uddanne og lette interaktionen mellem brugerne og SmartResQ/CorPatch<sup>®</sup>. </li>
</ul>
 

<h3>Om leads </h3>

<p>SmartResQ/CorPatch<sup>®</sup> behandler personoplysninger om leads til markedsføringsformål. For at levere målrettet og relevant indhold til potentielle kunder opbygger SmartResQ/CorPatch<sup>®</sup> en interesseprofil baseret på din aktivitet og dine valg og handlinger på SmartResQ/CorPatch<sup>®</sup>-siderne samt din reaktion på markedsføringsindhold. Retsgrundlaget for denne behandling er primært dit samtykke. </p>

<h3>Om jobansøgere </h3>

<p>Hvis du er jobansøger, behandler vi personoplysninger for at vurdere dit potentiale som SmartResQ/CorPatch<sup>®</sup>-medarbejder. Vores sikre online- karriereplatform sørger for, at vi overholder de seneste love og bestemmelser vedrørende databeskyttelse. Retsgrundlaget for denne behandling er dit samtykke. </p>

<h3>Om besøgende på websitet </h3>

<p>For at overvåge adgangen til vores websites behandler vi personoplysninger om besøgende. Behandlingen er baseret på vores legitime interesse i at beskytte vores forretningshemmeligheder, medarbejdere, lokationer og dig som besøgende. Du vil blive informeret om dine rettigheder i denne forbindelse, når du registrerer dig i vores elektroniske system for besøgende. </p>

<p>For at forbedre kvaliteten af hjerte-lunge-redning, især gennem træning i hjerte-lunge-redning, kan SmartResQ/CorPatch<sup>®</sup> dele dine oplysninger med vores samarbejdspartnere (Training Institutes), så de kan tilbyde dig deres produkter og tjenester. </p>

<p>Når vi behandler din ordre, kan vores system sende dine oplysninger til, og bruge de returnerede oplysninger fra, kreditoplysningsbureauer for at forhindre falske køb. </p>

</div>

<!-- This is 21st section (white) - title: Sådan opbevarer vi dine personoplysninger -->
<div class="white">
<h2>Sådan opbevarer vi dine personoplysninger </h2>

<p>SmartResQ/CorPatch<sup>®</sup> tager den tillid, som du og vores kunder viser os, meget alvorligt. SmartResQ/CorPatch<sup>®</sup> forpligter sig til at undgå uautoriseret adgang, videregivelse eller anden afvigende behandling af personoplysninger. SmartResQ/CorPatch<sup>®</sup> skal sikre fortroligheden af de personoplysninger, vi behandler, fastholde integriteten af personoplysninger og sikre deres tilgængelighed i overensstemmelse med gældende databeskyttelseslove. </p>

<p>Som en del af vores forpligtelser udfører og træffer vi rimelige og tilstrækkelige organisatoriske, tekniske og fysiske procedurer og foranstaltninger for at beskytte de oplysninger, som vi indsamler og behandler. Vi tager højde for typen af personoplysninger og den risiko, som vores kunder er udsat for ved et eventuelt sikkerhedsbrud, idet der er stor sandsynlighed for, at hovedårsagen til brud på persondatasikkerhed skal findes internt. Vi mener, at opbygningen af en stærk virksomhedskultur med respekt og årvågenhed omkring databeskyttelse blandt vores medarbejdere er afgørende for at sikre lovlig behandling og beskyttelse af dine oplysninger. I tilfælde af et brud på datasikkerheden vil SmartResQ/CorPatch<sup>®</sup> følge den praksis, som Datatilsynet har fastlagt. </p>

<p>Dine oplysninger opbevares sikkert i henhold til bestemmelserne i GDPR. </p>

</div>

<!--This is the 22nd section (beige) - title: Hvor længe opbevarer vi dine personoplysninger?-->
<div class="beige">
<h2>Hvor længe opbevarer vi dine personoplysninger? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> opbevarer kun dine personoplysninger, så længe det er nødvendigt til de anførte formål, idet der tages højde for vores behov for at besvare forespørgsler og løse problemer samt overholde gældende lovkrav. </p>

<p>Det betyder, at SmartResQ/CorPatch<sup>®</sup> kan opbevare dine personoplysninger i en rimelig periode efter din og vores kundes sidste interaktion med os. Når de personoplysninger, vi har indsamlet, ikke længere er nødvendige, sletter vi dem. Vi kan behandle oplysninger til statistiske og/eller videnskabelige formål, men i sådanne tilfælde vil oplysningerne blive pseudonymiseret eller anonymiseret. </p>

 
 

<h3>Tidsramme for opbevaring af oplysninger </h3>

<p>Vi vil opbevare dine personoplysninger, så længe det er nødvendigt for at opfylde de formål, der er anført i denne Privatlivspolitik, medmindre en længere opbevaringsperiode er påkrævet eller tilladt ved lov af juridiske, skattemæssige eller regulatoriske årsager eller andre legitime og lovlige forretningsmæssige formål. </p>

<table>
<tr>
<td><b>Formål </b></td>

<td><b>Tidsramme </b></td>
</tr>
<tr>
<td>Kundeservice og kontoregulering </td>

<td>5 år eller så længe vi er forpligtet til at opfylde de nødvendige lovkrav.
<br>
 

Vi sletter dine registreringsoplysninger, så snart du sletter din brugerkonto hos os, eller når den lovbestemte opbevaringsperiode udløber. 
</td>
</tr>
<tr>
<td>Karriereplatform </td>

<td>6 måneder for ansøgningsdokumenter, der ikke fører til ansættelse af ansøgeren. 
<br>
 

Fornyelse hver 6. måned, hvis du ønsker at komme i betragtning til fremtidige ledige stillinger, og du giver dit samtykke til en længere opbevaringsperiode. 
</td>
</tr>
<tr>
<td>Markedsføringsformål </td>

<td>3 år efter din seneste aktivitet, f.eks. besøg på vores websites, køb eller deltagelse i kommunikation. 
<br>
 

Hvis du har givet tilladelse til markedsføring (e-mail, sms, telefon etc.), og så længe vi har din tilladelse til at kontakte dig. 
<br>
 

Vi sletter automatisk din e-mailadresse til nyhedsbreve, når du sletter din brugerkonto, eller hvis du afmelder vores nyhedsbrev.  
</td>
</tr>
<tr>
<td>Opbevaring af ordrehistorik og forpligtelser til at opfylde ordrer </td>

<td>5 år eller så længe vi er forpligtet til at opfylde de nødvendige lovkrav  </td>
</tr>
<tr>
<td>Kundeoplevelse </td>

<td>3 år efter din seneste aktivitet, f.eks. besøg på vores websites, køb eller deltagelse i kommunikation. 
<br>
 

Hvis du har givet tilladelse til markedsføring (e-mail, sms, telefon etc.), og så længe vi har din tilladelse. </td>
</tr>
<tr>

<td>Bedrageri og risikovurdering </td>

<td>5 år eller så længe vi er forpligtet til at opfylde de nødvendige lovkrav. </td>
</tr>
</table>

</div>

<!--This is the 23rd section (white) - title: Markedsføringskommunikation-->
<div class="white">
 
<h2>Markedsføringskommunikation </h2>

<p>Du har ret til at fravælge at modtage markedsføringskommunikation fra SmartResQ/CorPatch<sup>®</sup> ved enten at: </p>
<ul>
<li>følge fravalgsanvisningerne i den relevante markedsføringskommunikation, </li>

<li>ændre præferencer i det relevante redigeringsafsnit for kontoen, hvis du har en konto hos SmartResQ/CorPatch<sup>®</sup>,</li>

<li>kontakte os via e-mail på <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</li>
</ul>
 

<p>Bemærk, at selvom du fravælger at modtage markedsføringskommunikation, kan du stadig modtage administrative meddelelser fra SmartResQ/CorPatch<sup>®</sup> som f.eks. ordrebekræftelser og meddelelser, der er nødvendige for at administrere din konto eller tjenester, der leveres til vores kunder via f.eks. mobile tjenester eller apps. </p>

</div>

<!--This is the 24th section (beige) - title: Dine databeskyttelsesrettigheder-->
<div class="beige">
<h2>Dine databeskyttelsesrettigheder </h2>

<p>Det er vigtigt for SmartResQ/CorPatch<sup>®</sup>, at du er fuldt bevidst om alle dine databeskyttelsesrettigheder. </p>

<p>Nogle databeskyttelseslove, herunder EU's Databeskyttelsesforordning (GDPR), tilsvarende lovgivning i Tyskland, Bundesdatenschutzgesetz (BDSG), i Schweiz og i Storbritannien samt nogle amerikanske delstatslove giver dig visse rettigheder i forbindelse med personoplysninger, som du har delt med os. Hvis du bor inden for det Europæiske Økonomiske Samarbejdsområde, kan du have følgende rettigheder: </p>
<ol>
<li><b>Indsigtsret</b> – Du har ret til at anmode os om kopier af dine personoplysninger. 

<li><b>Ret til berigtigelse</b> – du har ret til at anmode om, at vi berigtiger personoplysninger, som du mener er forkerte. Du har også ret til at anmode os om at fuldstændiggøre ufuldstændige oplysninger.  

<li><b>Ret til sletning</b> – Du har ret til at anmode os om at slette dine personoplysninger under visse omstændigheder.  

<li><b>Ret til begrænsning af behandling</b> – Du har ret til at anmode os om at begrænse behandlingen af dine personoplysninger under visse omstændigheder. 

<li><b>Ret til at gøre indsigelse mod behandling</b> – Du har ret til at gøre indsigelse mod behandlingen af dine personoplysninger under visse omstændigheder. 

<li><b>Ret til dataportabilitet</b> – Du har ret til at anmode om, at vi overfører de personoplysninger, vi har indsamlet, til en anden organisation eller direkte til dig under visse omstændigheder. 
</ol>
 

<p>Hvis du ønsker at udøve en af disse rettigheder, skal du kontakte os på vores e-mail <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

</div>

<!--This is the 25th section (white) - title: Hvad er cookies?-->
<div class="white">
<h2>Hvad er cookies? </h2>

<p>Cookies er små tekstfiler, der indeholder en tekststreng og opretter en unik identifikator for en bruger. De returneres til websitet og/eller tredjeparter. De fleste browsere er som standard sat op til at acceptere cookies, da de fleste websites kræver adgang til dem. Du kan imidlertid ændre dine browserindstillinger, så din browser generelt kan afvise cookies, blokere tredjepartscookies eller angive, hvornår en cookie sendes.</p>
 
 

<p>SmartResQ/CorPatch<sup>®</sup> er fast besluttet på at sikre din ret til at justere dine interesser og administrere omfanget af digital markedsføring fra os via et system med præferenceadministration. </p>

</div>

<!--This is the 26th section (beige) - title: Sådan bruger vi cookies-->
<div class="beige">
<h2>Sådan bruger vi cookies </h2>

<p>SmartResQ/CorPatch<sup>®</sup> bruger cookies på en række forskellige måder til at forbedre din oplevelse på vores websites, apps og tjenester af forskellige grunde, f.eks.: </p>
<ul>
<li>Funktionalitet – vi bruger disse cookies til at genkende dig på vores website og huske dine tidligere valgte præferencer. Disse omfatter dit foretrukne sprog og det sted, hvor du befinder dig. Der anvendes en blanding af førsteparts- og tredjepartscookies. </li>

<li>Reklame – vi bruger disse cookies til at indsamle oplysninger om dit besøg på vores websites, app(s), det indhold du har set, de links du har fulgt, og oplysninger om din browser, enhed og IP-adresse. Nogle gange deler vi begrænsede elementer af disse oplysninger med tredjeparter til reklameformål. Vi kan også dele onlineoplysninger indsamlet via cookies med vores reklamepartnere. Det betyder, at når du besøger et andet website, kan du få vist reklamer, der er baseret på dine browsingmønstre på vores website. </li>
</ul>
</div>

<!--This is the 27th section (white) - title: Typer af anvendte cookies-->
<div class="white">
<h2>Typer af anvendte cookies </h2>

<p>Vores website(s) anvender følgende typer af cookies: </p>
<ul>
<li><b>Google Analytics:</b> Denne cookie gør det muligt for os at se oplysninger om website-aktiviteter for brugere, herunder bl.a. sidevisninger, kilde og den tid, der bliver brugt på websitet. Oplysningerne anonymiseres og vises som tal, hvilket betyder, at de ikke kan spores tilbage til personer. Dette er med til at opretholde fortroligheden af dine oplysninger. Når vi bruger Google Analytics, kan vi se hvilket indhold, der er populært på vores sider, og vi bestræber os på at give dig mere af det, du godt kan lide at læse og se. </li>

<li><b>Google Analytics remarketing:</b> Placerer cookies på din computer, hvilket betyder, at når du forlader websitet, kan Google vise dig reklamer om SmartResQ/CorPatch<sup>®</sup>, som du måske er interesseret i ud fra din tidligere adfærd på vores website. Disse oplysninger er ikke personhenførbare. </li>

<li><b>Google Ads:</b> Når vi bruger Google Ads, kan vi se hvilke sider, der var nyttige, idet de førte til indsendelser via vores kontaktformular. Disse oplysninger er ikke personhenførbare, men det er oplysningerne i kontaktformularen. </li>

<li><b>Google Ads remarketing:</b> Placerer cookies på din computer, hvilket betyder, at når du forlader websitet, kan Google vise dig reklamer om SmartResQ/CorPatch<sup>®</sup>, som du måske er interesseret i ud fra din tidligere adfærd på vores website. Disse oplysninger er ikke personhenførbare. </li>

<li><b>Facebook remarketing:</b> Facebooks sporingspixel placerer cookies på din computer, som fortæller Facebook, at du har være inde på websitet. Vi går så ud fra, at du har interesse for SmartResQ/CorPatch<sup>®</sup> og indholdet på dette site. Når du går ind på Facebook, får du vist information eller reklamer med lignende indhold. Du bedes anvende vores privatlivsindstillinger på Facebook for at begrænse visningen af denne type af markedsføring. </li>

<li><b>YouTube:</b> Vi bruger videoer fra YouTube-platformen, der leveres af Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Privatlivspolitik: <a href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</li>
</ul>
 
</div>

<!--This is the 28th section (beige) - title: Sådan administrerer vi cookies-->
<div class="beige"> 
<h2>Sådan administrerer vi cookies </h2>

<p>Du kan indstille din browser til ikke at acceptere eller slette cookies. Dette kan imidlertid betyde, at nogle af vores website-funktioner ikke fungerer. Se, hvordan man undgår cookies i specifikke browsere: </p>
<ul>
<li>Amerikansk website: <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a></li>

<li>EU-website: <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>
</ul>
 

<p>SmartResQ/CorPatch<sup>®</sup> bruger visse betroede tredjepartstjenester på vores sider. Disse tjenester bruger muligvis cookies. Du kan vælge at afvise tredjepartscookies i din browser ved at følge dette <a href="https://www.youronlinechoices.com/">link</a>.</p> 

<p>Du kan forhindre, at oplysninger genereret af Google-cookien om din brug af vores websites bliver indsamlet og behandlet af Google ved at downloade og installere Google Analytics opt-out browser-add-on til din aktuelle webbrowser. Denne add-on er tilgængelig <a href="https://tools.google.com/dlpage/gaoptout">her</a>.</p>  

</div>

<!--This is the 29th section (white) - title: Privatlivspolitikker på andre websites-->
<div class="white">
<h2>Privatlivspolitikker på andre websites </h2>

<p>SmartResQ/CorPatch<sup>®</sup> websites indeholder links til andre websites. Vores privatlivspolitik gælder kun for vores website, så hvis du klikker på et link til et andet website, skal du læse deres privatlivspolitik. </p>

</div>

<!--This is the 30th section (beige) - title: Ændringer i vores privatlivspolitik-->
<div class="beige">
<h2>Ændringer i vores privatlivspolitik </h2>

<p>Hvis vi ændrer vores privatlivspolitik, vil vi offentliggøre den reviderede politik her med en opdateret revisionsdato. Vi opfordrer dig til at gennemlæse politikken regelmæssigt. Hvis vi foretager væsentlige ændringer af vores politik, der i betydelig grad ændrer vores privatlivspraksis, kan vi også informere dig på anden vis, f.eks. ved at sende en e-mail eller ved at poste en meddelelse på vores website og/eller sociale medier, inden ændringerne træder i kraft. </p>

</div>

<!--This is the 31st section (white) - title: Sådan kan SmartResQ ApS kontaktes-->
<div class="white">
<h2>Sådan kan SmartResQ ApS kontaktes </h2>

<p>Hvis du har spørgsmål om SmartResQ/CorPatch<sup>®</sup>'s privatlivspolitik, de oplysninger vi opbevarer om dig, eller hvis du ønsker at udøve en af dine databeskyttelsesrettigheder, er du meget velkommen til at kontakte os. </p>

<p>E-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Website: <a href="https://corpatch.com/">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Sådan kontaktes den relevante myndighed-->
<div class="beige">

<h2>Sådan kontaktes den relevante myndighed </h2>

<p>Hvis du ønsker at indgive en klage, eller hvis du føler, at SmartResQ/CorPatch<sup>®</sup> ikke har håndteret din bekymring på tilfredsstillende vis, kan du kontakte Information Commissioner's Office (ICO). </p>

<p>ICO's adresse: 
<br><br>
Information Commissioner's Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>

 

<p>Helpline-nummer: +44 303 123 1113 </p>

<p>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p> 

<p><b>© SmartResQ ApS – Alle rettigheder forbeholdes 
<br>
Danmark, Version 1.3 – Udsendt 2023.04.25 </b></p>

</div>

</div>
</body>
</html>`

export const termsOfUse_fi_FI: string = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
        
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
        
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>


</head>
<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 

    <!--This is the 1st section - title: Käyttöehdot -->
    <div class="top-section">
        <h1><a href="#terms">Käyttöehdot </a> & <a href="#policy">Tietosuojakäytäntö </a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
               
    </div>
   
<!--This is the 2nd section (white) - title: Tämän asiakirjan tarkoitus and Kuinka voit ottaa yhteyttä SmartResQ-yritykseen-->
<div class="white">

<h1 id="terms">Käyttöehdot – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Tämän asiakirjan tarkoitus </h2>

<p>Tervetuloa SmartResQ/CorPatch<sup>®</sup>:iin! </p>

 

<p>Luet juuri käyttöehtojamme, asiakirjaa, joka sisältää tietoja suhteestasi SmartResQ ApS:ään. Suhde alkoi, kun tulit verkkosivustollemme, latasit maksuttoman sovelluksemme, ostit jonkin tuotteistamme tai muuta vastaavaa. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ja yhteistyökumppanimme tarjoavat sinulle tuotteitamme ja palvelujamme seuraavien ehtojen mukaisesti. Jos vierailet verkkosivustollamme tai sovelluksessamme tai teet ostoksia niissä, hyväksyt nämä ehdot. Suosittelemme, että luet ehdot huolellisesti ennen kuin käytät tuotteitamme tai palvelujamme. </p>

 

<p>Tässä asiakirjassa käsitellään: </p>
<ul>
<li>Kuinka voit ottaa yhteyttä SmartResQ-yritykseen </li>

<li>Tuotteet, ominaisuudet ja asianmukainen käyttö </li>

<li>Vastuuvapauslausekkeet ja takuut </li>

<li>Tilaaminen </li>

<li>Maksaminen </li>

<li>Toimitus </li>

<li>Myyntiin liittyvät velvollisuudet ja oikeudet </li>

<li>Palautukset </li>

<li>Henkilötietojen käsittely </li>

<li>Reklamaatiot </li>

<li>Tämän asiakirjan muutokset </li>

<li>Laki ja lainkäyttöalue </li>

<li>Yhteystiedot </li>
</ul>
 

<p>Jos sinulla on kysyttävää SmartResQ:n käyttöehdoista, ota yhteyttä meihin. </p>

 

<h2>Kuinka voit ottaa yhteyttä SmartResQ-yritykseen </h2>

<p>SmartResQ:n tavoitteena on parantaa eloonjäämistä laadukkaan PPE:n avulla sydänpysähdystapauksissa. Haluamme valmistaa ja opastaa sivullisia toimimaan ensimmäisten ratkaisevien minuuttien aikana sen jälkeen, kun he ovat havainneet sydänpysähdyksen. Siksi olemme kehittäneet CorPatch<sup>®</sup>:n – autamme sinua pelastamaan ihmishengen. </p>

 

<p>Yrityksemme sijaitsee osoitteessa Lundevej 26, DK- 5700 Svendborg, Tanska </p>

 

<p>Löydät meidät keskusyritysrekisteristä ALV-numerolla DK 38674102 </p>

 

<p>Voit soittaa meille numeroon +45 62 200 100 </p>

 

<p>tai </p>

 

<p>lähettää sähköpostia osoitteeseen <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<h3>Kysymyksiä, kommentteja tai reklamaatioita? </h3>

<p>Arvostamme mielipidettäsi, joten ota meihin yhteyttä edellä ilmoitettuja yhteystietoja käyttäen tai käytä verkkosivustomme tai sovelluksemme yhteydenottolomaketta. </p>

</div>

<!--This is the 3rd section (beige) - title: Tuotteet, ominaisuudet ja asianmukainen käyttö-->
<div class="beige"> 
<h2>Tuotteet, ominaisuudet ja asianmukainen käyttö </h2>

<h3>Verkkosivustomme, sovelluksemme ja tuotteemme </h3>

<p>Seuraavissa kappaleissa kuvataan SmartResQ/CorPatch<sup>®</sup>-palveluiden, -sovellusten ja -tuotteiden käyttöön liittyviä keskeisiä ominaisuuksia, oikeuksia ja velvollisuuksia. 
<br>
Käytämme kohtuullisia keinoja sisällyttääksemme verkkosivusto(i)lle ja sovelluksiin tarkkaa ja ajantasaista tietoa. SmartResQ/CorPatch<sup>®</sup> ei kuitenkaan anna mitään takuita tai vakuuksia tietojen oikeellisuudesta. Emme ota mitään vastuuta mistään verkkosivuston, tuotteiden tai sovellusten sisältämistä virheistä tai puutteista. Tällä verkkosivustolla tai sovelluksissa annetut tiedot eivät korvaa lääkärinhoitoa. Otamme vastuun tuotteistamme ja sovelluksistamme lääkinnällisistä laitteista annetun direktiivin EU 745/2017 (MDR) mukaisesti. </p>

 

<h3>Tekijänoikeudet </h3>

<p>Kaikki tämän sivuston sisältö, kuten teksti, grafiikka, logot, painikekuvakkeet, kuvat, äänileikkeet, digitaaliset lataukset, tietokokoelmat ja ohjelmistot, on SmartResQ/CorPatch<sup>®</sup>:n tai sen sisällöntoimittajien omaisuutta ja suojattu kansainvälisillä tekijänoikeuslaeilla. Koko tämän sivuston sisältökooste on SmartResQ/CorPatch<sup>®</sup>:n yksinomaista omaisuutta, tämän koosteen tekijänoikeudet ovat SmartResQ/CorPatch<sup>®</sup>:llä, ja sitä suojaavat kansainväliset tekijänoikeuslait. </p>

 

<h3>Rekisteröidyt tavaramerkit ja mallit </h3>

<p>SmartResQ/CorPatch<sup>®</sup>-tavaramerkkejä, ulkoasua ja tuotesuunnittelua ei saa käyttää minkään sellaisen tuotteen tai palvelun yhteydessä, joka ei ole SmartResQ/CorPatch<sup>®</sup>, millään tavalla, joka todennäköisesti aiheuttaa sekaannusta asiakkaiden keskuudessa, tai millään tavalla, joka halventaa tai mustamaalaa SmartResQ/CorPatch<sup>®</sup>-tuotetta. Kaikki muut tällä sivustolla esiintyvät tavaramerkit, jotka eivät ole SmartResQ/CorPatch<sup>®</sup>:n tai sen tytäryhtiöiden omistamia, ovat omistajiensa omaisuutta. Omistajat voivat olla tai voivat olla olematta sidoksissa SmartResQ/CorPatch<sup>®</sup>:iin tai sen tytäryhtiöihin tai sponsoroida niitä. </p>

 

<h3>Lisenssi ja käyttöoikeus </h3>

<p>SmartResQ/CorPatch<sup>®</sup> myöntää sinulle rajoitetun lisenssin käyttää ja hyödyntää henkilökohtaisesti sivustoamme (sivustojamme) ja sovelluksiamme. Et saa ladata (muuta kuin sivujen välimuistiin tallentaminen) tai muokata niitä tai mitään niiden osaa ilman SmartResQ/CorPatch<sup>®</sup>:n nimenomaista kirjallista lupaa. Tämä lisenssi ei sisällä näiden sivustojen tai sovellusten tai niiden sisällön jälleenmyyntiä tai kaupallista käyttöä: (a) tuoteluetteloiden, kuvausten tai hintojen keräämistä ja käyttöä: (b) tämän sivuston tai sen sisällön minkäänlaista johdannaiskäyttöä: (c) tilitietojen lataamista tai kopiointia toisen kauppiaan hyödyksi: tai (d) tiedonlouhinnan, robottien tai vastaavien tiedonkeruu- ja louhintatyökalujen käyttöä. </p>

 

<p>Tätä sivustoa tai sovellusta tai mitään sivuston osaa ei saa jäljentää, muokata, monistaa, kopioida, myydä, jälleenmyydä, vierailla tai muutoin hyödyntää mihinkään kaupalliseen tarkoitukseen ilman SmartResQ/CorPatch<sup>®</sup>:n nimenomaista kirjallista lupaa. Et saa kehystää tai käyttää kehystekniikoita SmartResQ/CorPatch<sup>®</sup>:n ja yhteistyökumppaneidemme tavaramerkkien, logojen tai muiden omistusoikeuden alaisten tietojen (mukaan lukien kuvat, teksti, sivun asettelu tai muoto) sisällyttämiseen ilman nimenomaista kirjallista lupaa. </p>

 

<p>Et saa käyttää metatunnisteita tai muuta ”piilotettua tekstiä”, jossa käytetään SmartResQ/CorPatch<sup>®</sup>-nimeä tai -tavaramerkkejä ilman SmartResQ/CorPatch<sup>®</sup>:n nimenomaista kirjallista lupaa. Luvattoman käytön seurauksena SmartResQ/CorPatch<sup>®</sup>:n myöntämä lupa tai lisenssi päättyy. </p>

 

<p>Sinulle myönnetään rajoitettu, peruutettavissa oleva ja ei-yksinomainen oikeus luoda hyperlinkki SmartResQ/CorPatch<sup>®</sup>:n kotisivulle niin kauan kuin linkki ei kuvaa SmartResQ/CorPatch<sup>®</sup>:a, sen osakkuusyhtiöitä tai niiden tuotteita tai palveluja väärällä, harhaanjohtavalla, halventavalla tai muulla tavalla loukkaavalla tavalla. Et saa käyttää mitään SmartResQ/CorPatch<sup>®</sup>-logoa tai muuta omistusoikeuden alaista grafiikkaa tai tavaramerkkiä osana linkkiä ilman nimenomaista kirjallista lupaa. 
<br>
Jos lisenssisopimusta ja sivuston käyttöoikeutta koskevaa sopimusta rikotaan, ryhdytään oikeustoimiin, ja kanne voidaan nostaa tuomioistuimessa. </p>

<h3>Sähköinen viestintä </h3>

<p>Kun käyt SmartResQ/CorPatch<sup>®</sup>:n kotisivulla ja sosiaalisen median kanavissa tai lähetät meille sähköpostia, viestit kanssamme sähköisesti. Annat suostumuksesi vastaanottaa meiltä sähköistä viestintää. Viestimme sinulle sähköpostitse tai julkaisemalla ilmoituksia verkkosivustoillamme tai sovelluksissamme ponnahdusikkunoiden tai ilmoitusten muodossa. Hyväksyt, että kaikki sopimukset, ilmoitukset, tiedotteet ja muu viestintä, jonka tarjoamme sinulle sähköisesti, täyttää kaikki lakisääteiset vaatimukset, joiden mukaan tällainen viestintä on tehtävä kirjallisesti. Tutustu tietosuojaselosteeseemme erillisessä asiakirjassa kotisivullamme. </p>

 
<p>Verkkosivustomme ja sosiaalisen median kanaviemme kävijöitä saatetaan pyytää julkaisemaan arvosteluja, kommentteja ja muuta sisältöä,esim. lähettämään ehdotuksia, ideoita, kommentteja, kysymyksiä tai muuta tietoa, kunhan sisältö ei ole laitonta, säädytöntä, uhkaavaa, herjaavaa, yksityisyyttä loukkaavaa, immateriaalioikeuksia loukkaavaa tai muutoin kolmansia osapuolia vahingoittavaa tai paheksuttavaa, eikä se koostu ohjelmistoviruksista, poliittisesta kampanjoinnista, kaupallisesta myynninedistämisestä, ketjukirjeistä, massapostituksista tai muunlaisesta roskapostista tai sisällä niitä. </p>

 

<p>Et saa käyttää väärää sähköpostiosoitetta, esiintyä toisena henkilönä tai oikeushenkilönä tai muutoin johtaa harhaan kortin tai muun sisällön alkuperän suhteen. SmartResQ/CorPatch<sup>®</sup> pidättää oikeuden (mutta ei velvollisuutta) poistaa tai muokata tällaista sisältöä, mutta ei tarkista julkaistua sisältöä säännöllisesti. Jos julkaiset sisältöä tai lähetät materiaalia, ja jollemme toisin ilmoita, annat SmartResQ/CorPatch<sup>®</sup>:lle ja sen yhteistyökumppaneille ei-yksinomaisen, rojaltittoman, pysyvän, peruuttamattoman ja täysin alilisensoitavissa olevan oikeuden käyttää, kopioida, muokata, mukauttaa, julkaista, kääntää, luoda johdannaisia teoksia, levittää ja esittää kyseistä sisältöä kaikkialla maailmassa missä tahansa mediassa. Annat SmartResQ/CorPatch<sup>®</sup>:lle ja sen yhteistyökumppaneille ja alilisenssinsaajille oikeuden käyttää lähettämääsi nimeä tällaisen sisällön yhteydessä, mikäli he niin haluavat. </p>

<p>Vakuutat ja takaat, että omistat tai hallitset muutoin kaikkia oikeuksia julkaisemaasi sisältöön, että sisältö on täsmällistä, että toimittamasi sisällön käyttö ei riko tätä käytäntöä eikä aiheuta vahinkoa kenellekään henkilölle tai entiteetille ja että korvaat SmartResQ/CorPatch<sup>®</sup>:lle tai sen yhteistyökumppaneille kaikki vaatimukset, jotka johtuvat toimittamastasi sisällöstä. SmartResQ/CorPatch<sup>®</sup>:lla on oikeus mutta ei velvollisuutta valvoa ja muokata tai poistaa mitä tahansa toimintaa tai sisältöä. SmartResQ/CorPatch<sup>®</sup> ei ota vastuuta eikä ole vastuussa mistään sinun tai kolmannen osapuolen julkaisemasta sisällöstä. </p>
</div>

<!--This is the 4th section (white) - title: Vastuuvapauslausekkeet ja takuut-->
<div class="white">
 

<h2>Vastuuvapauslausekkeet ja takuut </h2>

<h3>Tuotetiedot – CorPatch<sup>®</sup> ja CorPatch<sup>®</sup> Trainer </h3>

<p>SmartResQ/CorPatch<sup>®</sup>-tuotteen tai -palvelun oikean käytön varmistamiseksi on aina noudatettava viimeisimpiä käyttöoppaita, ohjeita ja kuvauksia. Ne löytyvät verkkosivuiltamme ja kunkin tuotekuvauksen alta. </p>

 

<p>CorPatch<sup>®</sup>-tuote on sertifioitu lääkinnälliseksi laitteeksi. Hätätilanteessa, jossa aikuinen henkilö on saanut sydämenpysähdyksen, tuote on otettava kotelostaan, asetettava ja kiinnitettävä oikein uhrin rintaan. Tuote pystyy keräämään tietoja painalluksista, syvyydestä, tiheydestä ja rintakehän palautumisesta, ja se voi lähettää nämä tiedot Bluetooth<sup>®</sup>-yhteyden kautta mahdollisiin laitteisiin. Laitteiden sisältämät ilmaiset sovellukset voivat opastaa sivullista PPE:n suorittamisessa ja näyttää paineluajan, syvyyden, rintakehän palautumisen ja tiheyden, jos ne on aktivoitu ja asennettu oikein, eli Bluetooth<sup>®</sup>, älypuhelin, sovellus, akun teho jne. SmartResQ/CorPatch<sup>®</sup> ei ole vastuussa ulkoisista tekijöistä, kuten häiritsevistä tietoliikennesignaaleista, datapeiton puutteesta, akkuvirran riittämättömyydestä, virheellisistä laitteisto- tai ohjelmistoasetuksista, jotka voivat vaikuttaa käyttökokemukseen tai vastaaviin. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ei myöskään ole vastuussa tuotteen käytön aikana aiheutuneista fyysisistä vahingoista, jos tuotetta käytetään ohjeiden vastaisesti, esim. suorittamalla rintakehän painelu epäergonomisella tavalla tai käyttämällä epäsopivaa käsien asentoa. SmartResQ/CorPatch<sup>®</sup> on arvioinut teknisiä riskejä osana lääkinnällisten laitteiden lakisääteistä riskinhallintaa, mutta mitään takuuta ei anneta tämän ylittävistä virheistä. Jos SmartResQ/CorPatch<sup>®</sup>-järjestelmässä ilmenee odottamattomia toimintahäiriöitä tai epätodennäköistä toimintaa, käyttäjän on suoritettava PPE manuaalisesti. Tässä tapauksessa SmartResQ/CorPatch<sup>®</sup> ei ole vastuussa, koska se ei ole SmartResQ/CorPatch<sup>®</sup>:n hallinnassa.
<br>
SmartResQ/CorPatch<sup>®</sup> valvoo akun varaustasoa ja kuntoa, mutta jos akun virta loppuu, tuote ei toimi. Käyttäjä on yksin vastuussa siitä, että laite on ajan tasalla, että se ei ole vahingoittunut ja että siinä on riittävästi akkuvirtaa toimiakseen oikein, mikä voidaan tehdä helposti suorittamalla harjoittelu, jossa vahvistetaan oikea toiminta. Suosittelemme 4 minuutin harjoittelua 3 kuukauden välein CorPatch<sup>®</sup>-laitteella. </p>

 

<p><b>Tärkeää!</b> CorPatch<sup>®</sup>-laitetta saa käyttää henkilöön ainoastaan todellisessa tilanteessa ja vain, jos henkilö on saanut sydänpysähdyksen. Sitä ei ole tarkoitettu käytettäväksi henkilöille, jotka kärsivät esimerkiksi aivohalvauksesta, sydänkohtauksesta tai muista terveyteen liittyvistä sairauksista, jotka eivät ole sydänpysähdys. CorPatch<sup>®</sup> ei ole tarkoitettu käytettäväksi, jos henkilö makaa pehmeällä pinnalla, esim. sohvalla tai sängyllä, koska syvyyspalaute voi olla epätarkka tällaisissa olosuhteissa. Mitään SmartResQ/CorPatch<sup>®</sup>-ratkaisuja ei ole tarkoitettu käytettäväksi liikkuvassa ympäristössä, mukaan lukien mutta ei rajoittuen lento-, meri- tai maantieambulansseihin. Jos laitetta käytetään potilaan kuljetuksen aikana tai jos se nostetaan/irrotetaan kehosta PPE:n aikana, se voi antaa epätarkkaa palautetta. CorPatch<sup>®</sup> on kiinnitettävä potilaan rintaan liimatarralla. Varmista, että potilas makaa kiinteällä, tasaisella ja liikkumattomalla alustalla ja että CorPatch<sup>®</sup> on kiinnitetty rintakehään liimatarralla. </p>

 

<p>CorPatch<sup>®</sup> Trainer -tuotetta saa käyttää ainoastaan harjoitusnukkeihin tai vastaaviin esineisiin Training Sessionoissa eikä koskaan todellisessa tilanteessa oikeaan henkilöön, joka on saanut sydänpysähdyksen tai jolla on muu sairaus. </p>

 

<p>Käyttämällä CorPatch<sup>®</sup>️- tai CorPatch<sup>®</sup>️ Trainer -laitetta säännöllisesti, on mahdollista harjoitella PPE-suoritusta rintakehän painallusten osalta. Suosittelemme, että harjoittelet harjoitusnukella, mutta jos sinulla ei ole mahdollisuutta käyttää sellaista, korvaavana välineenä voidaan käyttää palautumiseltaan riittävää toimistotuolia tai kovaa sohvaa. Ota huomioon harjoituskohteen joustavuus ja kovuus. SmartResQ ei suosittele pehmeiden esineiden, kuten tyynyjen tai pehmeiden sohvien, käyttämistä harjoittelussa, koska käyttäjäkokemus ei ole oikea. </p>

<p>Jos et löydä harjoitusnukkea tai korvaavaa esinettä, harkitse harjoittelevasi tilannetta, joka tapahtuu ennen rintakehän painelujen aloittamista. Tämä voi tarkoittaa maksuttoman sovelluksen antamien sydänpysähdyksen tunnistamista ja hätäpuhelun soittamista koskevien tietojen noudattamista ja  CorPatch<sup>®</sup>-laitteen purkamista avaimenperästä. Tällöin olet valmis käyttämään CorPatch<sup>®</sup>-laitetta nopeasti havaitessasi sydänpysähdyksen. </p>

 

<p>Älä käytä SmartResQ/CorPatch<sup>®</sup>️-järjestelmää harjoitteluun tai ”huvin vuoksi” ihmisiin (eläviin tai kuolleisiin), lemmikkeihin tai muihin eläviin olentoihin. </p>

 

<h3>Tuotteita, verkkosivusto(j)a ja sovelluksia koskeva vastuuvapauslauseke </h3>

<p>SmartResQ/CorPatch<sup>®</sup> ei anna mitään vakuutuksia tai takuita eikä ole vastuussa kenenkään sellaisen henkilön pätevyydestä, joka saa järjestelmän kautta tai sen perusteella annettua koulutustietoa ja/tai lääketieteellistä koulutusta, tai siitä, että kyseinen henkilö käyttää taitojaan järjestelmää käyttävän koulutuksen, kurssin tai oppijakson suorittamisen jälkeen. SmartResQ/CorPatch<sup>®</sup> ei takaa, että järjestelmästä koulutustietoa ja/tai lääketieteellistä koulutusta saavat henkilöt saavuttavat tietyn taitotason tai tarvittavan pätevyyden, jotta he voivat saada minkä tahansa sääntelyelimen tai valtion viranomaisen myöntämän lisenssin, todistuksen tai luokituksen. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ja sen yhteistyökumppanit pyrkivät olemaan mahdollisimman tarkkoja. SmartResQ/CorPatch<sup>®</sup> ei kuitenkaan vakuuta eikä takaa, että järjestelmä ja tässä annetut tiedot, tuotteet ja koulutus: (a) ovat aina saatavilla tai ylipäätään saatavilla; tai (b) ovat virheettömiä, täydellisiä, tarkkoja, todenmukaisia, ajantasaisia ja/tai harhaanjohtamattomia. Kun käytät järjestelmää, tiedät ja olet tietoinen siitä, että luovut kaikista SmartResQ/CorPatch<sup>®</sup>:iin kohdistuvista vaateista, jotka perustuvat järjestelmän kautta esitettyjen tietojen tai koulutuksen luotettavuuteen. </p>

 

<p>Tuotteitamme ei ole tarkoitettu lasten käyttöön. Tuotteet ovat pieniä ja värikkäitä, ja niitä voi erehtyä luulemaan leluiksi, mutta SmartResQ/CorPatch<sup>®</sup>-tuotteet eivät ole leluja! Suosittelemme, ettei tuotteita jätetä esille lasten ulottuville. SmartResQ/CorPatch<sup>®</sup> ei ota vastuuta siitä, että lapset käyttävät tuotteita. Vastuullisten aikuisten, esim. vanhempien, perheenjäsenten tai opettajien on annettava lapsille tai nuorille lupa käyttää CorPatch<sup>®</sup>-laitetta ja valvottava sen käyttöä. </p>

 

<p>Vastuuvapauslauseke ja vastuunrajoitus, SmartResQ/CorPatch<sup>®</sup> tarjoaa tämän sivuston ”sellaisena kuin se on” ja ”sellaisena kuin se on saatavilla”. SmartResQ/CorPatch<sup>®</sup> ei anna minkäänlaisia, suoria tai epäsuoria vakuutuksia tai takuita tämän sivuston toiminnasta tai sen sisältämistä tiedoista, sisällöstä, materiaaleista tai tuotteista. Hyväksyt nimenomaisesti, että tämän sivuston käyttö tapahtuu omalla vastuullasi. 
<br>
Sovellettavan lain sallimissa rajoissa SmartResQ/CorPatch<sup>®</sup> kieltäytyy kaikista suorista tai epäsuorista takuista, mukaan lukien, mutta ei rajoittuen, epäsuorat takuut myyntikelpoisuudesta ja soveltuvuudesta tiettyyn tarkoitukseen. SmartResQ/CorPatch<sup>®</sup> ei takaa, että tämä sivusto, sen palvelimet tai SmartResQ/CorPatch<sup>®</sup>:n lähettämä sähköposti ei sisällä viruksia/roskapostia tai muita haitallisia komponentteja. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ei ole vastuussa mistään tämän sivuston tai sen tuotteiden käytöstä aiheutuvista vahingoista, mukaan lukien, mutta ei rajoittuen, suorat, epäsuorat, satunnaiset, rangaistusluonteiset ja välilliset vahingot. 
<br>
Tietyt osavaltioiden lait eivät salli epäsuorien takuiden rajoittamista tai tiettyjen vahinkojen poissulkemista tai rajoittamista. Jos näitä lakeja sovelletaan sinuun, jotkut tai kaikki edellä mainitut vastuuvapauslausekkeet, poissulkemiset tai rajoitukset eivät välttämättä koske sinua, ja sinulla saattaa olla lisäoikeuksia. </p>

 

<p>Tällä verkkosivustolla esitettyjä tietoja ei pidä tulkita ammatilliseksi neuvonnaksi. Sinun on aina kysyttävä neuvoa tietyissä asioissa omaan tilanteeseesi perehtyneiltä ammattimaisilta neuvonantajilta ennen kuin teet mitään päätöksiä. </p>

 

<p>Verkkosivusto voi sisältää linkkejä, jotka johtavat sellaisten henkilöiden tai organisaatioiden ylläpitämille verkkosivustoille, joihin SmartResQ/CorPatch<sup>®</sup> ei voi vaikuttaa. SmartResQ/CorPatch<sup>®</sup> ei anna mitään vakuutuksia eikä takuita tällaisten verkkosivustojen sisältämien tietojen tarkkuudesta tai muista näkökohdista. </p>

 

<p>Vastuu tämän verkkosivuston artikkeleihin tai teksteihin sisältyvistä mielipiteistä, neuvoista, lausunnoista tai muista tiedoista on yksinomaan niiden kirjoittajalla, eivätkä ne välttämättä vastaa SmartResQ/CorPatch<sup>®</sup>:n mielipiteitä ja käytäntöjä. </p>

 

<h3>Vastuuvapauslauseke </h3>

<p>Ostamalla, lisensoimalla, katselemalla ja/tai käyttämällä verkkosivustoamme, tuotteitamme ja sovelluksiamme hyväksyt seuraavan: </p>
<ol>
<li>SmartResQ/CorPatch<sup>®</sup>:n tarjoamat järjestelmät ovat tiettyjä tuotteita, joita saa käyttää ainoastaan tuotteen käyttöohjeessa mainittuun käyttötarkoitukseen. Lue ohjeet ja käyttöoppaat huolellisesti ja varmista, että tunnet lääkinnälliset tuotteemme ennen käyttöönottoa.  </li>

<li>SmartResQ/CorPatch<sup>®</sup>:n tarjoamat järjestelmät ovat erityisiä koulutus- ja lääketieteellisiä harjoittelutuotteita ja -välineitä ei ole sertifioitu lääkinnällisiksi laitteiksi, ellei sitä ole nimenomaisesti mainittu. Niitä ei ole tarkoitettu kliiniseen tai diagnostiseen käyttöön, ja ne on tarkoitettu käytettäväksi yksinomaan lääketieteelliseen koulutukseen ja suorituskyvyn parantamiseen. </li>

<li>Käytät järjestelmää aina ainoastaan tällaiseen lääketieteelliseen koulutukseen ja suorituskyvyn parantamiseen liittyviin tarkoituksiin, kaikkien sovellettavien lakien ja asetusten mukaisesti sekä kaikkien sinulle sähköisesti tai henkilökohtaisesti toimittamiemme käyttäjäasiakirjojen, käyttöohjeiden, oppaiden ja/tai vaatimusten mukaisesti. </li>

<li>SmartResQ/CorPatch<sup>®</sup>-järjestelmä ei voi missään vaiheessa yksinään diagnosoida, hoitaa tai parantaa ihmisen tilaa tai hengenpelastustilanteessa tukea ammattimaisia lääketieteellisiä päätöksiä, diagnooseja tai hoitoja tai korvata asianmukaisesti koulutetun ja lisensoidun lääkärin tekemää diagnoosia, suositusta, neuvoa, hoitoa tai päätöstä. </li>

<li>Se, että laite on yhteydessä potilasvahinkoon tai -tulokseen, ei tarkoita, että laite olisi aiheuttanut vahingon tai tuloksen. </li>

<li>SmartResQ/CorPatch<sup>®</sup> ei ota vastuuta vahingoista, jotka johtuvat tuotteidemme kohtuuttomasta käytöstä tai tuotteen käyttötarkoituksen ylittävästä käytöstä. </li>
</ol>
 

<h3>Suorituskykytiedot </h3>

<p>Tässä esitetyt tiedot on tarkoitettu ainoastaan ohjeeksi SmartResQ/CorPatch<sup>®</sup>-tuotteiden käyttöä varten. SmartResQ/CorPatch<sup>®</sup> pyrkii jatkuvasti parantamaan tuotteidensa laatua ja luotettavuutta. Laitteissamme voi kuitenkin esiintyä toimintahäiriöitä tai vikoja, koska ne ovat luonnostaan sähköherkkiä ja alttiita fyysiselle rasitukselle ja haitallisille viestintäsignaaleille. SmartResQ/CorPatch<sup>®</sup>-tuotteita käyttäessään ostaja on vastuussa turvallisuus- ja testausstandardien noudattamisesta ja sellaisten tilanteiden välttämisestä, joissa toimintahäiriö tai vika voi johtaa henkilö- tai omaisuusvahinkoihin. </p>

 

<h3>Vakiotakuu </h3>

<p>SmartResQ/CorPatch<sup>®</sup>:n rajoitettu vakiotakuu edellyttää tuotteiden, verkkosivustojen ja sovellusten asianmukaista käyttöä. 
<br>
Tämä takuu koskee vain tuotteen ensimmäistä ostajaa ja vain, jos tuote on ostettu valtuutetulta SmartResQ/CorPatch<sup>®</sup>-jälleenmyyjältä. Muut valmistajat, toimittajat tai julkaisijat kuin SmartResQ/CorPatch<sup>®</sup>, voivat antaa omat takuunsa. Ota yhteyttä heihin saadaksesi lisätietoja. </p>

 

<p><u>Takuu ei kata: </u></p>
<ol>
<li>vikoja tai vaurioita, jotka johtuvat onnettomuudesta, väärinkäytöstä, epätavallisesta käytöstä, epätavallisista olosuhteista, epäasianmukaisesta varastoinnista, altistumisesta nesteelle, kosteudelle, hiekalle tai lialle, laiminlyönnistä tai epätavallisesta fyysisestä, sähköisestä tai sähkömekaanisesta rasituksesta, </li>

<li>liiallisesta voimankäytöstä tai metalliesineiden käytöstä johtuvia vikoja tai vaurioita, </li>

<li>laitteita, joiden valmistusnumero tai parannustietokoodi on poistettu, turmeltunut, vahingoittunut, muutettu tai tehty lukukelvottomaksi, </li>

<li>tuotteen tavanomaista kulumista tai normaalia vanhenemista, </li>

<li>naarmuja, kolhuja ja kosmeettisia vaurioita, ellei vika johdu materiaali- tai valmistusvirheestä, </li>

<li>vikoja tai vahinkoja, jotka johtuvat tuotteiden käytöstä yhdessä tai yhteydessä sellaisten lisävarusteiden, tuotteiden tai lisä- tai oheislaitteiden kanssa, joita SmartResQ/CorPatch<sup>®</sup> ei ole toimittanut tai hyväksynyt, </li>

<li>vikoja tai vahinkoja, jotka johtuvat epäasianmukaisesta testauksesta, käytöstä, ylläpidosta, asennuksesta, huollosta tai säädöstä, jota SmartResQ/CorPatch<sup>®</sup> ei ole toimittanut tai hyväksynyt, </li>

<li>SmartResQ/CorPatch<sup>®</sup>-tuotteen käytöstä julkaistujen ohjeiden ulkopuolella aiheutuneet vahingot, </li>

<li>ostetun tuotteen esittelyä/asennusta, </li>

<li>vikoja tai vaurioita, jotka johtuvat ulkoisista syistä, kuten törmäyksestä esineeseen, tulipalosta, tulvasta, liasta, myrskystä, salamasta, maanjäristyksestä, altistumisesta sääolosuhteille, varkaudesta, sulakkeen rikkoutumisesta tai minkä tahansa sähkölähteen epäasianmukaisesta käytöstä, </li>

<li>tartunnasta tai viruksista tai muista tuotteisiin sisältyvistä ohjelmisto-ongelmista johtuvat viat tai vahingot, </li>

<li>jos akkuja ladataan muilla kuin CorPatch<sup>®</sup> Trainerin kanssa yhteensopivilla latureilla, </li>

<li>akkukotelon tai kennojen sinetit ovat rikki tai niissä on merkkejä peukaloinnista, </li>

<li>tuotteita, jotka on korjannut tai käyttänyt tai jotka on ostettu muulta yritykseltä kuin SmartResQ/CorPatch<sup>®</sup>, </li>

<li>jos SmartResQ/CorPatch<sup>®</sup> saa asianomaisilta viranomaisilta tiedon, että tuote on varastettu tai jos et pysty poistamaan salasanaa tai muita turvatoimia, jotka on suunniteltu estämään luvattoman pääsyn tuotteeseen, tai jos et pysty todistamaan, että olet tuotteen valtuutettu käyttäjä, </li>

<li>jos tuotteita käytetään käyttöohjeissa mainittujen olosuhteiden, esim. lämpötila-alueen, paineen ja kosteuden, ulkopuolella. </li>
</ol>
 

<h3>Akut ja laturit </h3>

<p>SmartResQ/CorPatch<sup>®</sup>-tuotteet sisältävät joko ei-vaihdettavia akkuja (CorPatch<sup>®</sup>) tai ladattavia akkuja (CorPatch<sup>®</sup> Trainer). Tuotteissamme käytetyt akkutyypit on ilmoitettu kunkin yksittäisen tuotteen kohdalla. SmartResQ/CorPatch<sup>®</sup> ei ota vastuuta siitä, jos ladattavia akkuja ei käsitellä oikein käyttöohjeen mukaisesti. </p>

 

<p>Akkuja sisältävien laitteiden myynnin yhteydessä meidän velvollisuutemme on kiinnittää huomiosi seuraaviin seikkoihin: 
<br>
Loppukäyttäjänä sinulla on lakisääteinen velvollisuus hävittää ne asianmukaisesti. Yliviivatun roskakorin symboli tarkoittaa, ettei akkua saa hävittää kotitalousjätteen mukana. </p>

</div>            

<!--This is the 5th section (beige) - title: Tilaaminen -->
<div class="beige">
<h2>Tilaaminen </h2>

<p>Corpatch.com-verkkokauppa on avoinna 24 tuntia vuorokaudessa, ja voit tehdä ostoksia lähes milloin tahansa. Saatamme kuitenkin sulkea verkkokaupan ylläpidon ajaksi. Suuria määriä voi ostaa suoraan SmartResQ/CorPatch<sup>®</sup>:n kautta. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ei tarjoa tuotteita myytäväksi alaikäisille. Vain aikuiset voivat ostaa lapsille tarkoitettuja tuotteita. Ostaaksesi SmartResQ/CorPatch<sup>®</sup>:ltä sinun on oltava vähintään 18-vuotias ja sinulla on oltava voimassa oleva luottokortti tai muu hyväksymämme maksutapa. </p>

 

<p>Verkkokaupan tuotteiden esittely ei ole oikeudellisesti sitova tarjous, vaan ei-sitova verkkoluettelo. Kun olet valmis tekemään ostoksia, valitse haluamasi tuotteet ja laita ne “Ostoskoriin”. Voit muokata ostoskorin sisältöä tilaushetkeen saakka. Kaikki lisämaksut, kuten toimituskulut tai pankkikorttimaksut, lasketaan välittömästi ennen maksua. </p>

 

<p>Kun olet valmis tilaamaan, napsauta ”Kassalle" ja anna tarvittavat tiedot. Voit muuttaa ostoskorin sisältöä siihen asti, kunnes vahvistat ostoksesi klikkaamalla ”Maksa”-painiketta Tämän jälkeen teet ostoskoriin sisältyvistä tavaroista sitovan tilauksen, jota ei voi enää muuttaa. 
<br>
SmartResQ/CorPatch<sup>®</sup> voi hyväksyä tilauksen lähettämällä tilausvahvistuksen sähköpostitse tai toimittamalla tavarat toimitusajan kuluessa. </p>

 

<p>Tietyt maat saattavat estää tuotteidemme käytön ja omistamisen. Olet yksin vastuussa siitä, että selvität, onko tämän tuotteen maahantuonti ja/tai käyttö maassasi laillista. Lähetämme sinulle tilaamasi tuotteet, emmekä voi ottaa vastuuta tullikysymyksistä tai tämän laitteen omistamiseen tai käyttöön liittyvistä seurauksista. </p>

</div>

<!--This is the 6th section (white) - title: Maksaminen-->
<div class="white">
<h2>Maksaminen </h2>

<h3>Verkkosivustomme, sovelluksemme ja verkkokauppamme </h3>

<p>Verkkosivujemme ja sovellustemme käyttö on ilmaista, kunhan oikeudelliset periaatteet hyväksytään ja niitä noudatetaan. Huomaa, että tuotteidemme ostot saattavat olla saatavilla verkkokaupassa (verkkokaupoissa) verkkosivustollamme (verkkosivustoillamme) ja sovelluksessa (sovelluksissa). </p>

 

<h3>Tuotteemme </h3>

<p>SmartResQ/CorPatch<sup>®</sup> käyttää QuickPayta maksuyhdyskäytävänä. Maksukorttiteollisuuden (PCI) turvallisuusstandardilautakunta on sertifioinut QuickPayn PCI Data Security Standard (DSS) Level 1 -standardin viimeisimmän julkaisun mukaisesti, johon sisältyy: a) pätevän turvallisuusarvioijan (Qualified Security Assessor, QSA) laatima vuosittainen raportti - "Report on Compliance" (ROC); (b) hyväksytyn verkkotarkistuksen toimittajan (Approved Scan Vendor, ASV) neljännesvuosittain suorittamat verkkotarkistukset; ja c) suuri määrä työnkulkua ja tietojen käsittelyä koskevia sääntöjä ja ohjeita. </p>

 

<p>Hyväksymme seuraavat maksutavat: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>MasterCard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
 

<p>Maksut vähennetään tililtäsi tavaroiden lähetyksen yhteydessä. Kaikki summat ovat euromääräisiä, ja lakisääteinen arvonlisävero sisältyy jo kaikkiin mainittuihin hintoihin. Kaikkien luotto- tai maksukortin haltijoiden kortin myöntäjä tai maksupalveluntarjoaja suorittaa kortin kelpoisuustarkastuksen ja valtuutuksen. SmartResQ/CorPatch<sup>®</sup> ei ole vastuussa, jos maksukortintarjoajasi kieltäytyy hyväksymästä maksuja. </p>

 

<p>Käytämme luottokorttitietojen salausta SSL-protokollan (Secure Socket Layer) avulla. Näin varmistetaan, etteivät muut voi siepata luottokortin numeroa tai muita tietoja palveluntarjoajamme kanssa suoritettavan maksutapahtuman aikana. </p>

 

<p>Ennen sopimuksen tekemistä asiakkaan on tarkistettava ja hyväksyttävä ostohintaan lisätyt toimitus- ja logistiikkakulut, sillä ne veloitetaan asiakkaalta. Tehtyäsi tilauksen saat meiltä sähköpostiviestin, jossa ilmoitamme vastaanottaneemme tilauksesi. Huomaa, ettei tämä tarkoita, että tilauksesi on hyväksytty. Tilauksesi muodostaa tarjouksen SmartResQ/CorPatch<sup>®</sup>:lle tuotteen ostamisesta (ja SmartResQ/CorPatch<sup>®</sup> pidättää oikeuden kieltäytyä tuotteiden tilauksista). Tuotteita koskevaa sopimusta ei synny ennen kuin olemme vahvistaneet sinulle sähköpostitse, että tuotteet on lähetetty. Tarjouksesi hyväksyminen katsotaan täydelliseksi ja välillämme syntyy sopimus, kun lähetämme sinulle lähetysvahvistuksen sähköpostitse. </p>

 

<p>Toimituskulut ilmoitetaan aina kuhunkin yksittäiseen tilaukseen liittyen. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> säilyttää ostetun tuotteen omistusoikeuden siihen saakka, kunnes asiakas on maksanut laskun summan kokonaisuudessaan ja se on automaattisesti laadittu juuri ennen toimitusta. </p>

 

<p>Asiakkailla on oikeus hyvitykseen vain, jos heidän vastasaatavansa on laillisesti vahvistettu tai SmartResQ/CorPatch<sup>®</sup> ei ole kiistänyt niitä tai on tunnustanut ne. Lisäksi asiakkaalla on pidättämisoikeus vain, jos ja siltä osin kuin hänen vastasaatavansa perustuu samaan sopimussuhteeseen. </p>

 

<p>Jos asiakkaalla on meille maksuvelvoitteita, kaikki olemassa olevat saatavat erääntyvät välittömästi. </p>

 

<h3>Lisämaksut </h3>

<p>Lisämaksuja koskevia sääntöjä on muutettu 1.1.2018 alkaen. Näin ollen ei ole enää laillista periä lisämaksuja kuluttajakorteilla suoritettavista maksuista, jos kortit on myöntänyt EU:n alueella toimiva pankki / kortin myöntäjä. Tämä koskee sekä pankki- että luottokortteja. Kuluttajakortit ovat yksityiselle kuluttajalle myönnettyjä kortteja. 
<br>
Jos kortti on kuitenkin joko yrityskortti tai kuluttajakortti, joka on myönnetty EU:n ulkopuolella, tapahtumamaksusta peritään lisämaksu. Tämä tarkoittaa sitä, että kortinhaltija maksaa tapahtumamaksun automaattisesti. 
<br>
Maksu ei ylitä SmartResQ/CorPatch<sup>®</sup>:n hankkijan veloittamaa maksua. Maksu näkyy selvästi erillisenä eränä maksuikkunassa. </p>

</div>

<!--This is the 7th section (beige) - title: Toimitus-->
<div class="beige">
<h2>Toimitus </h2>

<p>Pyrimme toimittamaan tilaukset työpäivästä työpäivään ja käytämme kansainvälisesti luotettavaa kuljetusyritystä. Näet ostoksesi kokonaishinnan toimituksineen kassalla ennen lopullisen tilauksen hyväksymistä. </p>

 

<p>Jos asiakas ei ota tavaroita vastaan, SmartResQ/CorPatch<sup>®</sup> voi peruuttaa sopimuksen tai saada korvauksen laiminlyönnistä kahden viikon kuluessa käsittely- ja toimituskulujen kattamiseksi. </p>

 

<p>Jos asiakas on antanut virheelliset tiedot toimitusosoitteesta, paketti on mahdollista noutaa verkkokaupassamme mainitusta pakettiliikkeestä. Muutoin paketti menetetään. </p>

 

<p>Asiakas ei koskaan saa osatoimituksia, ellei SmartResQ/CorPatch<sup>®</sup> ole nimenomaisesti ilmoittanut siitä. </p>

 

<h3>Menettämisriski </h3>

<p>Tuotteen omistusoikeus siirtyy ostajalle, kun tuote on saatettu ostajan käyttöön tämän sopimuksen mukaisesti. Jos toimitusaika on kulunut umpeen, eikä ostaja saa tuotetta, joka on saatettu hänen käyttöönsä sopimuksen mukaisesti, ostaja kantaa riskin tuotteen ominaisuuksista johtuvasta menetyksestä tai vahingosta. </p>

</div>

<!--This is the 8th section (white) - title: Peruutus ja palautukset-->
<div class="white">
<h2>Peruutus ja palautukset </h2>

<p>Kun teet ostoksia SmartResQ/CorPatch<sup>®</sup>-verkkokaupassa tai offline-tilassa, on sinulla 14 päivää aikaa katua ja peruuttaa ostoksesi, jolloin voit ilmoittaa meille, että olet muuttanut mieltäsi ja palauttaa tuotteen meille samassa kunnossa kuin se on vastaanotettu. 
<br>
Hyväksymme vain käyttämättömien tuotteiden palautukset alkuperäisessä, sinetöidyssä ja vahingoittumattomassa pakkauksessa. Ne on pakattava asianmukaisesti lähetystä varten, muuten tuotteet katsotaan käytetyiksi, eikä osittaisia palautuksia hyväksytä. Peruuttamisoikeus koskee vain vastaanotettaessa sinetöimättömiä tuotteita. </p>
<h3>Hyväksyttävät syyt tuotteen palauttamiseksi </h3>
<ul>
<li>14 päivän peruutusoikeuden käyttäminen </li>

<li>Tuote ei vastaa kuvausta (takuu) </li>

<li>Tuote on viallinen </li>
</ul>
 

<p>CorPatch<sup>®</sup>-palautusehdot ovat EU:n vakiosääntöjen mukaiset. </p>
<p>Jos palautat tuotteen, säilytä alkuperäispakkaus. Älä vahingoita sitä tai liimaa tai kirjoita siihen. Hanki ja käytä erityistä palautuspakkausta, esim. pahvilaatikkoa. </p>

 

<p>CorPatch<sup>®</sup> Trainer -tuotteen peruutusehdot ovat EU:n vakiosääntöjen mukaiset. </p>

 

<p>Jos haluat käyttää peruutusoikeutta, sinun on ilmoitettava siitä meille 14 päivän kuluessa tuotteiden vastaanottamisesta. Peruutuspyynnöt on lähetettävä sähköpostitse osoitteeseen info@corpatch.com, ja niissä on ilmoitettava selkeästi, että haluat käyttää peruutusoikeuttasi sekä syy. </p>

 

<p>Odotamme, että palautat tuotteet mahdollisimman pian sen jälkeen, kun olet tehnyt peruutusilmoituksen, ja viimeistään 14 päivän kuluessa siitä, kun olet ilmoittanut siitä meille sähköpostitse. </p>

 

<p>Voimme hylätä takaisinmaksun, kunnes olet palauttanut tuotteet tai todistanut, että olet palauttanut ne. Takaisinmaksussa käytämme samaa maksutapaa kuin alkuperäisessä maksutapahtumassa. </p>

 

<h3>Syyt, joita ei hyväksytä tuotteen palauttamiseksi </h3>
<ul>
<li>Mielenmuutos 14 päivän peruutusoikeuden jälkeen. </li>

<li>Jos tuote on aktivoitu. </li>

<li>Jos tuotetta käytetään tai se vahingoittuu muulla tavoin. </li>

<li>Jos ohjelmisto/maksuton sovellus ladataan, yhdistetään, asennetaan tai muulla tavoin liitetään fyysiseen tuotteeseen (fyysisiin tuotteisiin). </li>
</ul>
 

<h3>Palauttaminen </h3>

<p>Hyväksymme vain avaamattomien tuotteiden palautukset alkuperäisessä ja vahingoittumattomassa pakkauksessa. Niiden on oltava asianmukaisesti pakattuja lähetystä varten, muutoin tuotteet katsotaan käytetyiksi, eikä palautuksia hyväksytä. </p>

<p><u>Ellei muuta ole mainittu, palautukset on lähetettävä osoitteeseen: </u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg
<br>
Tanska </p>
 

<p><b>TÄRKEÄÄ!</b> Olet yksin vastuussa pakkauksen laadusta ja tuotteista, kunnes olemme vastaanottaneet ne. Säilytä postikuitti, joka sisältää tiedot toimituskuluista ja tarvittaessa lähetysnumeron. Emme korvaa palautuksen toimituskuluja emmekä ota vastaan postiennakolla tai vastaavalla tavalla lähetettyjä paketteja. </p>

</div>

<!--This is the 9th section (beige) - title: Hyvitykset-->
<div class="beige">
<h2>Hyvitykset </h2>

<p>SmartResQ/CorPatch<sup>®</sup> on velvollinen korjaamaan, vaihtamaan tai antamaan hinnanalennuksen tai täyden hyvityksen, jos tuote osoittautuu vialliseksi 2 vuoden kuluessa tuotteen ostamisesta. 
<br>
Asiakkaalla ei ole oikeutta hyvitykseen, jos ongelma on vähäinen, kuten naarmut tuotteessa tai vastaava. 
<br>
Hyvitys käynnistetään kun SmartResQ/CorPatch<sup>®</sup> vastaanottaa asiakkaan tuotteen. Hyvityksen määrä riippuu tuotteiden tilasta SmartResQ/CorPatch<sup>®</sup>:n vastaanottohetkellä. </p>

 

<p>Asiakkaan hyvityksen käsittelytapa riippuu alkuperäisestä maksutavasta. Jos asiakas on maksanut luotto- tai pankkikortilla, hyvitykset lähetetään kortin myöntäneeseen pankkiin 5 arkipäivän kuluessa palautetun tuotteen tai peruutuspyynnön vastaanottamisesta. Ota yhteyttä kortin myöntäneeseen pankkiin, jos sinulla on kysyttävää siitä, milloin hyvitys kirjataan tilillesi. </p>

</div>

<!--This is the 10th section (white) - title: Henkilötietojen käsittely-->
<div class="white"> 
<h2>Henkilötietojen käsittely </h2>

<p>SmartResQ/CorPatch<sup>®</sup> arvostaa henkilötietojasi kolmivaiheisen tietosuojan vaatimustenmukaisuuden avulla: a. pidämme yksityiskohtaista karttaa tietovirroistamme, b. suoritamme tietovirtaan perustuvan oikeudellisen arvioinnin, jotta voimme c. toteuttaa tarvittavat turvatoimet tietojesi suojaamiseksi. </p>
 

<p>Käyttääksesi verkkokauppaamme sinun on annettava vähintään seuraavat tiedot: </p>
<ul>
<li>Nimi </li>

<li>Osoite </li>

<li>Sähköpostiosoite </li>

<li>Puhelinnumero </li>
</ul>
 

<p>Asiakkaiden henkilötietojen kerääminen tapahtuu voimassa olevan lainsäädännön ja EU:n yleisen tietosuoja-asetuksen (GDPR) puitteissa. </p>
 

<p>Jos haluat lisätietoja henkilötietojesi käsittelystä, lue tietosuojakäytäntömme. </p>

</div>

<!--this is the 11th section (beige) - title: Reklamaatiot-->
<div class="beige">
<h2>Reklamaatiot </h2>

<h3>Reklamaation tekeminen </h3>

<p>Jos tuotteessa on jotain vikaa, voit joko korjauttaa tai vaihtaa viallisen tuotteen, saada hyvityksen tai hinnanalennuksen tilanteesta riippuen. 
<br>
Edellytyksenä on tietenkin, että reklamaatio on perusteltu ja ettei vika johdu tuotteen virheellisestä käytöstä tai muusta virheellisestä toiminnasta. </p>


<p>Suosittelemme, että reklamoit mahdollisimman pian ja viikon kuluessa vian havaitsemisesta.
<br>
Jos sinulla on kysyttävää, kommentteja tai reklamaatioita, ota meihin yhteyttä sähköpostitse osoitteeseen: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

<h3>Reklamaatio muille EU:n elimille </h3>

<p>Jos haluat reklamoida muille EU:n elimille, käy Euroopan unionin virallisella verkkosivustolla. Löydät tietoa <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">täältä</a>.</p>

</div>

<!--this is the 12th section (white) - title: Tämän asiakirjan muutokset-->
<div class="white">
<h2>Tämän asiakirjan muutokset </h2>

<h3>Verkkosivustojen, sovellusten ja käytäntöjen muutokset </h3>

<p>SmartResQ/CorPatch<sup>®</sup> pidättää oikeuden tehdä muutoksia, poistaa, muokata tai täydentää verkkosivusto(j)a, sovelluksia, käytäntöjä ja asiakirjoja milloin tahansa ja mistä tahansa syystä ilman ennakkoilmoitusta. 
<br>
Jos jokin näistä ehdoista katsotaan pätemättömäksi, mitättömäksi tai jostain syystä täytäntöönpanokelvottomaksi, kyseinen ehto katsotaan erotettavaksi, eikä se vaikuta muiden ehtojen pätevyyteen ja täytäntöönpanokelpoisuuteen. </p>

 

<p>Jos muutamme käytäntöjämme, julkaisemme tarkistetut käytännöt verkossa päivitetyn tarkistuspäivämäärän kera. Kehotamme sinua tarkistamaan käytännöt säännöllisesti. Jos teemme käytäntöihimme olennaisia muutoksia, jotka muuttavat merkittävästi käytäntöjämme, saatamme ilmoittaa sinulle myös muilla tavoin, esimerkiksi lähettämällä sähköpostia tai julkaisemalla ilmoituksen verkkosivustollamme ja/tai sosiaalisessa mediassa ennen muutosten voimaantuloa. </p>

 

<h3>Tiettyyn ostokseen liittyvä </h3>

<p>Kun ostat tuotteen, sinua pyydetään hyväksymään tiettyjen asiakirjojen versio sellaisena kuin ne ovat juuri kyseisenä ajankohtana – tätä versiota ei muuteta enää kyseisen ajankohdan jälkeen, ja se määrää ehdot, jotka koskevat suhdettamme sinuun juuri kyseisen ostoksen osalta. </p>

</div>

<!--this is the 13th section (beige)- title: Laki ja lainkäyttöalue -->
<div class="beige"> 
<h2>Laki ja lainkäyttöalue </h2>

<h3>Tanskan lait ja Svendborgin käräjäoikeus </h3>

<p>SmartResQ/CorPatch<sup>®</sup> soveltaa Tanskan lakia ja oikeuspaikkaa kaikkiin oikeudellisiin riitoihin, mutta ei YK:n kansainvälistä tavaran kauppaa koskevaa yleissopimusta. 
<br>
Kaikki riidat, jotka liittyvät millään tavalla vierailuusi SmartResQ/CorPatch<sup>®</sup>:ssa tai SmartResQ/CorPatch<sup>®</sup>:n kautta ostamiisi tuotteisiin, on käsiteltävä luottamuksellisesti Tanskassa, lukuun ottamatta sitä, että jos olet jollain tavalla loukannut tai uhannut loukata SmartResQ/CorPatch<sup>®</sup>:n teollis- ja tekijänoikeuksia, SmartResQ/CorPatch<sup>®</sup> voi hakea kieltotuomiota tai muuta asianmukaista oikeussuojaa mistä tahansa maasta, ja suostut yksinomaiseen tuomiovaltaan ja oikeuspaikkaan tällaisissa tuomioistuimissa. </p>
<p>Jos käyttöehtosopimusta rikotaan, ryhdytään oikeustoimiin ja kanne voidaan nostaa tuomioistuimessa. </p>

 

<p>Meidän ja kuluttajan väliset riidat käsitellään Svendborgin käräjäoikeudessa, Christiansvej 41, DK-5700 Svendborg, Tanska. </p>

</div>

<!--this is the 14th section (white) title: Yhteystiedot-->
<div class="white">
<h2>Yhteystiedot </h2>

<p>Kiitos, että luit SmartResQ/CorPatch<sup>®</sup>:n käyttöehdot. 
<br>
Jos sinulla on kysyttävää, kommentteja tai reklamaatioita, ota yhteyttä meihin. </p>

 

<p>Yrityksemme sijaitsee osoitteessa: Lundevej 26, DK-5700 Svendborg, Tanska </p>

 

<p>Löydät meidät keskusyritysrekisteristä ALV-numerolla DK 38674102 </p>

 

<p>Voit soittaa meille numeroon +45 62 200 100 </p>

 

<p>tai </p>

 

<p>lähettää sähköpostia osoitteeseen: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<p><b>© SmartResQ ApS – Kaikki oikeudet pidätetään 
<br>
Tanska, versio 2.1 – Julkaistu 25.4.2023 </b></p>

</div>
   
<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->
   




<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Privacy Policy -->
<div class="beige">
<h1 id="policy">SmartResQ/CorPatch<sup>®</sup> -tietosuojakäytäntö </h1>

<!--This is the 16th section (beige) = joined together with the 15th section - title: Käsittelemme tietojasi EU:n yleisen tietosuoja-asetuksen mukaisesti-->
<h2>Käsittelemme tietojasi EU:n yleisen tietosuoja-asetuksen mukaisesti </h2>

<p>SmartResQ/CorPatch<sup>®</sup> kunnioittaa yksityisyyttäsi. Tässä tietosuojalausunnossa kuvataan yksityisyydensuojasi ja sitoumuksemme henkilötietojesi suojaamiseen. </p>

<p>Jos sinulla on kysyttävää henkilötietojesi käsittelystä, ota yhteyttä meihin. Rekisterinpitäjä on: </p>

<p><b>Yritys:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Osoite: </b>
<br>
Lundevej 26 
<br>
DK-5700 Svendborg 
<br>
Tanska </p>

<p><b>CVR-numero:</b> 38674102 </p>

<p><b>Puhelin:</b> +45 62 200 100 </p>

<p><b>Sähköposti:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> on tanskalainen/eurooppalainen yritys, jolla on oikeussubjekteja, liiketoimintaprosesseja, hallintorakenteita ja teknisiä järjestelmiä yli kansallisten rajojen. SmartResQ/CorPatch<sup>®</sup> tarjoaa tuotteita, ohjelmistoja ja palveluja julkisille/yksityisille yrityksille Euroopassa. </p>

<p>Pääkonttori sijaitsee Tanskassa ja SmartResQ/CorPatch<sup>®</sup> on eurooppalaisen tietosuojalainsäädännön alainen, mukaan lukien yleinen tietosuoja-asetus (GDPR). SmartResQ/CorPatch<sup>®</sup> tekee tärkeimmät henkilötietojen suojaa koskevat päätökset johtotasolla tietosuojavastaavan valvonnassa. </p>

<p>Tämä tietosuojalausunto on saatavilla verkkosivustoillamme ja sovelluksissamme. </p>

<p>Älä käytä SmartResQ/CorPatch<sup>®</sup>-sivustoja, sovelluksia tai palvelujamme, jos et hyväksy tapaa, jolla käsittelemme henkilötietoja tämän tietosuojaselosteen mukaisesti. </p>

</div>

<!--This is the 17th section (white) - title: Keräämiemme henkilötietojen tyyppi -->
<div class="white">
<h2>Keräämiemme henkilötietojen tyyppi  </h2>

<p>Kun käsittelijä määrittää henkilötietojesi käsittelytarkoitukset ja -keinot, käsittelijä toimii rekisterinpitäjänä. Tämä sisältää skenaariot, joissa SmartResQ/CorPatch<sup>®</sup> kerää henkilötietoja kun olet työnhakija, asiakkaan edustaja tai liidi, tai kun olet ohjelmiston käyttäjä. </p>

<p>SmartResQ käsittelee henkilötietoja eri tarkoituksiin riippuen suhteestamme sinuun. </p>

<p>Voimme käsitellä seuraavia tietoja: </p>
<ol>
<li>perusyhteystiedot, kuten nimi, osoite, puhelinnumero (matkapuhelin ja/tai lankapuhelin) ja sähköpostiosoite, </li>

<li>työllisyystiedot, kuten työnantaja, titteli, asema, mukaan lukien ammatilliset mieltymykset ja mielenkiinnon kohteet, </li>

<li>palaute, kommentit ja kysymykset, joiden aiheena ovat SmartResQ/CorPatch<sup>®</sup> tai tuotteemme ja palvelumme, </li>

<li>valokuvat tai videot, jotka on tallennettu toimipaikoissamme, </li>

<li>lataamasi sisältö, kuten valokuvat, videot ja suoritukset ajan myötä, </li>

<li>yksilölliset käyttäjätiedot, kuten sisäänkirjautumistunnus, käyttäjätunnus, salasana ja turvakysymys, </li>

<li>taloudelliset tiedot, jos hyväksyt sen, että käytämme tietojasi esimerkiksi maksukorttitietojesi tallentamiseen, </li>

<li>verkkoselaimesi tarjoamat liikennetiedot, kuten selaintyyppi, laite, kieli ja sen verkkosivuston osoite, josta tulit sekä muut liikennetiedot, mukaan lukien IP-osoite, </li>

<li>napsautusvirtakäyttäytyminen ja toiminta SmartResQ/CorPatch<sup>®</sup>:n tunnuksilla sekä tuotteissamme ja palveluissamme, </li>

<li>sähköpostikäyttäytyminen, kuten SmartResQ/CorPatch<sup>®</sup>:n sähköpostiviestit, jotka avaat milloin ja miten, </li>

<li>muut henkilötiedot, jotka sisältyvät profiiliisi ja jotka olet asettanut vapaasti kolmansien osapuolten sosiaalisiin verkostoihin, kuten LinkedIn, Facebook, Google jne., </li>

<li>tiedot, joita käytetään tieteellisiin tarkoituksiin sydänpysähdyksen jälkeisen eloonjäännin parantamiseksi ja jotka on kerätty verkkosivujemme ja sovellustemme kautta, </li>

<li>tietoa käyttäjistä, jotta voimme toimittaa tuotteita laatu- ja turvallisuusvaatimusten mukaisesti, tarjota palveluja käyttäjille sekä ylläpitää ja parantaa tarjontaamme, </li>

<li>tietoa työnhakijoista, jotta voimme käsitellä työhakemuksia, tiedottaa tulevista työtarjouksista sekä ylläpitää ja parantaa rekrytointiprosessejamme, </li>

<li>tietoa henkilöistä, jotka ovat hyväksyneet uutiskirjeiden ja muiden materiaalien vastaanottamisen, jotta voimme toimittaa materiaalit sekä ylläpitää ja parantaa tarjontaamme, </li>

<li>evästetiedot räätälöidyn mainonnan tarjoamiseksi sosiaalisessa mediassa ja verkkosivustoilla. </li>
</ol>
</div>

<!--This is the 18th section (beige) - tile: CorPatch<sup>®</sup> Services -alustalla ja -sovelluksissa kerätyt ja käsitellyt tiedot -->
<div class="beige">
<h2>CorPatch<sup>®</sup> Services -alustalla ja -sovelluksissa kerätyt ja käsitellyt tiedot </h2>

<p>SmartResQ käsittelee, kerää ja tallentaa seuraavia henkilötietoja, kun käytät CorPatch<sup>®</sup> Services -alustaa ja -sovelluksia. </p>

 
 

<h3>Kaikki käyttäjät (koulutuslaitoksen järjestelmänvalvoja, trainer, trainee / loppukäyttäjä) </h3>
<ul>
<li>Etunimi (jos ilmoitettu) </li>

<li>Sukunimi (jos ilmoitettu) </li>

<li>Lempinimet (jos ilmoitettu) </li>

<li>Sähköpostiosoite (pakollinen) </li>

<li>Ensisijainen viestintäkieli (pakollinen) </li>

<li>Salasanan salaus (pakollinen) </li>

<li>Onko sähköpostiosoite vahvistettu (pakollinen) </li>
</ul>
 

<h3>Lisäksi traineet/loppukäyttäjät (pakollinen) </h3>

<p>Käytetyn matkapuhelimen tiedot: </p>

<p>Käyttöjärjestelmä (Android/iOS): </p>
<ul>
<li>Käyttöjärjestelmäversio (esim. 9) </li>

<li>Valmistaja (esim. Samsung) </li>

<li>Malli (esim. SM-T518) </li>

<li>Sovellusversio (esim. 1.2.4) </li>

<li>Sovelluksen viimeisen etualapalvelun aika </li>

<li>Sovelluksen viimeisen taustapalvelun aika </li>
</ul>
 

<p>Käytetyn CorPatch<sup>®</sup>:n (CPS) tiedot: </p>
<ul>
<li>Sarjanumero / MAC-osoite </li>

<li>Laiteohjelmistoversio </li>

<li>Mallinimi (esim. CPS_01) </li>

<li>Valmistaja (tällä hetkellä aina SRQ) </li>

<li>Nimi (tällä hetkellä aina CorPatch<sup>®</sup>) </li>

<li>Akun kunto </li>

<li>Viat </li>
</ul>
 

<p>Käyttäjän perehdytystiedot: </p>
<ul>
<li>Tutorial suoritettu (kyllä/ei) </li>

<li>Käyttöehdot hyväksytty (kyllä/ei) </li>

<li>Itsearviointi suoritettu (kyllä/ei) </li>

<li>Testiharjoitus suoritettu (kyllä/ei) </li>

<li>Ensimmäinen sisäänkirjautuminen onnistui (kyllä/ei) </li>

<li>Oliko CPS yhdistetty (kyllä/ei) </li>
</ul>
 

<p>Koulutuksen kautta kerätyt tiedot: </p>
<ul>
<li>Koulutuksen päivämäärä, aika ja kesto </li>

<li>Koulutuksen tulos </li>

<li>Koulutustyyppi ja koulutusasetukset </li>

<li>Jos kyseessä on koulutus koulutuslaitoksessa, lisätietoja kurssista, kouluttajasta ja koulutuslaitoksesta </li>
</ul>
 
 

<h3>Palvelinlokit </h3>

<p>Seuraavat tiedot tallennetaan verkkopalvelinlokeihin: </p>
<ul>
<li>Yhteyttä käyttävän osapuolen IP-osoite </li>

<li>Yhteyttä käyttävän osapuolen selainversio </li>

<li>Yhteyden käytön aika/päivämäärä </li>

<li>Yhteys-URL </li>
</ul>

<h3>Ulkoiset tietoja käsittelevät palvelut: </h3>
<ul>
<li>Google/Firebase etälokeja, kaatumisten ja virheiden analysointia varten </li>

<li>Google/Firebase ilmoitusten lähettämistä varten </li>

<li>Sendgrid sähköpostiviestien lähettämistä varten </li>

<li>Hetzner Online GmbH verkkopalvelin- ja tietokantaisännöintiä varten </li>
</ul>
 

<h3>Mitä tapahtuu, kun käyttäjä poistetaan </h3>
<ul>
<li>Käyttäjä poistaa itsensä järjestelmästämme CorPatch<sup>®</sup> Services -verkkosivulla <a href="https://app.corpatch.com">https://app.corpatch.com</a>.</li>

<li>Käyttäjä merkitään poistetuksi. Tämän jälkeen hän ei voi enää kirjautua sisään, hän ei näy enää järjestelmänvalvojille jne. Käyttäjä on kuitenkin edelleen tietokannassa. </li>

<li>14 päivän jälkeen käyttäjän tiedot poistetaan automaattisesti tietokannasta. </li>

<li>Tieteellistä arviointia ja toiminnallisuuden parantamista varten CorPatch<sup>®</sup>:n koulutuksia ja käyttöä koskevat tiedot säilyvät tietokannassa myös käyttäjän poistamisen jälkeen, mutta viittaus (ID) käyttäjään on tyhjä ja kaikki viittaukset henkilötietoihin poistetaan.  </li>
</ul>

</div>

<!--This is the 19th section (white) - tile: Kuinka keräämme henkilötietojasi-->
<div class="white">
<h2>Kuinka keräämme henkilötietojasi </h2>

<p>Sinä toimitat meille suurimman osan käsittelemistämme henkilötiedoista itse. Keräämme tietoja ja käsittelemme niitä, kun sinä: </p>
<ul>
<li>rekisteröidyt verkossa tai tilaat tuotteitamme tai palvelujamme; esim. demografiset tiedot, sähköpostiosoite, maksutiedot, tuotteet, tilausmäärä, alennus ja tiheys. Mukaan lukien transaktiosähköpostiviestien, kuten tilausvahvistuksen, toimitusvahvistuksen ja palautusvahvistuksen, lähettäminen, </li>

<li>osallistuminen lähetettyyn viestintään (sähköposti, tekstiviesti, suoramainonta tai puhelin); esim. avaamisaste, klikkausaste ja sähköpostien lukemiseen käytetty aika, lähettäjän verkkotunnus ja sähköpostiohjelman tyyppi, </li>

<li>täytät vapaaehtoisesti asiakaskyselyn tai annat palautetta millä tahansa ilmoitustaulullamme tai sähköpostitse. </li>
</ul>

<p>Saatamme myös saada henkilötietoja epäsuorasti seuraavilta lähteiltä seuraavissa tapauksissa: </p>
<ul>
<li>Evästeistä: kun käyt verkkosivustoillamme tai sovelluksissamme, esim. IP-osoite, maa, katsotut sivut, katsotut tuotteet, vuorovaikutus/klikkaukset ja haut. </li>

<li>Sinulta tai muulta asiakkaaseemme liittyvältä henkilöltä. Tällainen henkilö voi olla johtaja tai kollega. Jos asiakas, jolle työskentelet, ostaa tuotteita tai palveluja SmartResQ/CorPatch<sup>®</sup>:lta SartResQ/CorPatch<sup>®</sup>:n kumppaniyrityksen kautta, saatamme kerätä sinua koskevia tietoja kumppaniyritykseltä. </li>

<li>SmartResQ/CorPatch<sup>®</sup>:n markkinointikumppanit, julkiset lähteet tai kolmansien osapuolten sosiaaliset verkostot. </li>

<li>SmartResQ/CorPatch<sup>®</sup> voi yhdistää yhdestä lähteestä kerättyjä henkilötietojasi toisesta lähteestä saatuihin tietoihin. Näin saamme kokonaisemman kuvan sinusta, jolloin voimme myös palvella sinua asianmukaisemmin ja räätälöidymmin. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Kuinka käytämme tietojasi-->
<div class="beige">
<h2>Kuinka käytämme tietojasi </h2>

<p>SmartResQ/CorPatch<sup>®</sup> tarvitsee tietoja sinusta, kun olet asiakkaana tai kun käytät palvelua, jotta voimme yleisesti hallinnoida asiakassuhteitamme ja täyttää asiakassitoumuksemme. Tällaisten henkilötietojen käsittelyn tarkoituksena on: </p>

<ul>
<li>tilauksesi käsittely, tilisi hallinnointi, </li>

<li>muita tuotteita ja palveluita, joista saattaisit pitää, koskevien erikoistarjousten lähettäminen sinulle sähköpostitse, </li>

<li>myynti- ja sopimusprosessin suorittaminen asiakkaille, </li>

<li>pyydettyjen tuotteiden ja palvelujen toimittaminen asiakkaille, </li>

<li>toimitusten suorittaminen sinun tai asiakkaiden kanssa tehtyjen sopimusten mukaisesti, </li>

<li>tuen tarjoaminen tuotteidemme ja palvelujemme käyttäjille, </li>

<li>tuotteidemme ja palvelujemme sekä SartResQ/CorPatch<sup>®</sup>-verkkosivustojen ja -sovellusten laadun, toiminnallisuuden ja käyttäjäkokemuksen parantaminen ja kehittäminen, </li>

<li>tietoturvauhkien havaitseminen, rajoittaminen ja ehkäiseminen sekä ylläpito, vianmääritys ja virheenkorjaus, </li>

<li>tuotteidemme ja palvelujemme väärinkäytön estäminen, </li>

<li>tilausten käsittely, laskutus, maksut tai muu taloudellinen seuranta, </li>

<li>kiinnostusprofiilien luominen merkityksellisten tuotteiden ja palveluiden myynninedistämiseksi, </li>

<li>käyttäjäyhteisöjen perustaminen käyttäjien ja SmartResQ/CorPatch<sup>®</sup>:n välisen koulutuksen ja vuorovaikutuksen helpottamiseen. </li>
</ul>
<h3>Tietoa liideistä </h3>

<p>SmartResQ/CorPatch<sup>®</sup> käsittelee liidien henkilötietoja markkinointitarkoituksiin. Tarjotakseen kohdennettua ja relevanttia sisältöä mahdollisille asiakkaille SmartResQ/CorPatch<sup>®</sup> luo kiinnostusprofiilin, joka perustuu aktiivisuuteesi, valintoihisi ja toimiisi SmartResQ/CorPatch<sup>®</sup>-sivuilla sekä reagointiisi markkinointisisältöön. Käsittelyn oikeudellinen perusta on ensisijaisesti sinun antamasi suostumus. </p>

<h3>Tietoa työnhakijoista </h3>

<p>Jos olet työnhakija, käsittelemme henkilötietoja arvioidaksemme potentiaaliasi SmartResQ/CorPatch<sup>®</sup>:n työntekijänä. Turvallinen, verkossa toimiva ura-alustamme takaa, että noudatamme uusimpia tietosuojaa koskevia lakeja ja asetuksia. Käsittelyn oikeudellinen perusta on antamasi suostumus. </p>

<h3>Tietoa verkkosivustolla kävijöistä </h3>

<p>Jotta voimme seurata sivustojemme käyttöä, käsittelemme kävijöiden henkilötietoja. Käsittely perustuu oikeutettuun etuumme suojella liikesalaisuuksiamme, työntekijöitämme, toimipaikkojamme ja sinua kävijänä. Sinulle ilmoitetaan oikeuksistasi tässä yhteydessä, kun rekisteröidyt sähköiseen kävijäjärjestelmäämme. </p>

<p>PPE:n laadun parantamiseksi, erityisesti PPE-koulutuksen avulla, SmartResQ/CorPatch<sup>®</sup> saattaa jakaa tietojasi kumppaniyrityksillemme (koulutuslaitoksille), jotta ne voivat tarjota sinulle tuotteitaan ja palvelujaan. </p>

<p>Kun käsittelemme tilauksesi, järjestelmämme voi lähettää tietojasi luottotietotoimistoille ja käyttää niistä saatuja tietoja petollisten ostosten estämiseksi. </p>

</div>

<!-- This is 21st section (white) - title: Kuinka säilytämme henkilötietojasi -->
<div class="white">
<h2>Kuinka säilytämme henkilötietojasi </h2>

<p>SmartResQ/CorPatch<sup>®</sup> ottaa sinun ja asiakkaidemme osoittaman luottamuksen erittäin vakavasti. SmartResQ/CorPatch<sup>®</sup> sitoutuu välttämään henkilötietojen luvatonta käyttöä, luovuttamista tai muuta poikkeavaa käsittelyä. SmartResQ/CorPatch<sup>®</sup> varmistaa käsittelemiemme henkilötietojen luottamuksellisuuden, säilyttää henkilötietojen eheyden ja varmistaa niiden saatavuuden sovellettavien tietosuojalakien mukaisesti. </p>

<p>Osana sitoumuksiamme käytämme kohtuullisia ja riittäviä organisatorisia, teknisiä ja fyysisiä menettelyjä ja toimenpiteitä keräämiemme ja käsittelemiemme tietojen suojaamiseksi. Otamme huomioon henkilötietotyypin ja riskin, jolle asiakkaamme altistuvat mahdollisen tietoturvaloukkauksen vuoksi, koska on erittäin todennäköistä, että henkilötietojen tietoturvaloukkausten perimmäiset syyt löytyvät sisäisesti. Uskomme, että sellaisen vahvan yrityskulttuurin rakentaminen, jossa tietosuojaa kunnioitetaan ja valvotaan työntekijöidemme keskuudessa, on olennaisen tärkeää tietojesi lainmukaisen käsittelyn ja suojaamisen varmistamiseksi. Tietoturvaloukkauksen sattuessa SmartResQ/CorPatch<sup>®</sup> noudattaa Tanskan tietosuojaviraston määrittämiä käytäntöjä. </p>

<p>Tietojasi säilytetään turvallisesti EU:n yleisen tietosuoja-asetuksen määräysten mukaisesti. </p>

</div>

<!--This is the 22nd section (beige) - title: Kuinka kauan säilytämme henkilötietojasi?-->
<div class="beige">
<h2>Kuinka kauan säilytämme henkilötietojasi? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> säilyttää henkilötietojasi vain niin kauan kuin se on tarpeen ilmoitettuja tarkoituksia varten, ottaen samalla huomioon tarpeemme vastata kyselyihin ja ratkaista ongelmia sekä noudattaa sovellettavan lain mukaisia oikeudellisia vaatimuksia. </p>

<p>Tämä tarkoittaa, että SmartResQ/CorPatch<sup>®</sup> voi säilyttää henkilötietojasi kohtuullisen ajan sen jälkeen, kun sinä ja asiakkaamme olette viimeksi olleet kanssamme tekemisissä. Kun keräämämme henkilötiedot eivät ole enää tarpeellisia, poistamme ne. Voimme käsitellä tietoja tilastollisia ja/tai tieteellisiä tarkoituksia varten, mutta tällaisissa tapauksissa tiedot pseudonymisoidaan tai anonymisoidaan. </p>

 
 

<h3>Tietojen säilyttämisen aikarajat </h3>

<p>Säilytämme henkilötietojasi niin kauan kuin on tarpeen tässä tietosuojakäytännössä esitettyjen tarkoitusten täyttämiseksi, ellei laki, oikeudelliset, verotukselliset tai sääntelyyn liittyvät syyt tai muut lailliset ja oikeutetut liiketoimintatarkoitukset edellytä tai salli pidempää säilytysaikaa. </p>

<table>
<tr>
<td><b>Tarkoitus </b></td>

<td><b>Aikaraja </b></td>
</tr>
<tr>
<td>Asiakaspalvelun ja kirjanpidon sääntely </td>

<td>5 vuotta tai niin kauan kuin meillä on velvollisuus täyttää tarvittava oikeudellinen vaatimus. 
<br>
 

Poistamme rekisteröintitietosi heti, kun poistat käyttäjätilisi meiltä tai kun lakisääteinen säilytysaika umpeutuu. 
</td>
</tr>
<tr>
<td>Ura-alusta </td>

<td>6 kuukautta hakemusasiakirjoille, jotka eivät johda työnhakijan palkkaamiseen. 
<br>
 

Uusiminen 6 kuukauden välein, jos haluat, että tulet harkituksi täytettäessä uusia avoimia työpaikkoja, ja suostut pidempään säilytysaikaan. 
</td>
</tr>
<tr>
<td>Markkinointitarkoitus </td>

<td>3 vuotta viimeisimmästä toiminnastasi, esim. vierailusta verkkosivustoillamme, ostoksesta tai viestintään osallistumisesta. 
<br>
 

Jos olet antanut markkinointiluvan (sähköposti, tekstiviesti, puhelin jne.), ja jos meillä on lupasi ottaa yhteyttä sinuun. 
<br>
 

Poistamme sähköpostiosoitteesi uutiskirjeestä automaattisesti, kun poistat käyttäjätilisi tai jos peruutat uutiskirjeemme tilauksen.  
</td>
</tr>
<tr>
<td>Tilaushistorian säilyttäminen ja tilausten täyttämisvelvoitteet </td>

<td>5 vuotta tai niin kauan kuin meillä on velvollisuus täyttää tarvittavat oikeudelliset vaatimukset  
</tr>
<tr>
<td>Asiakaskokemus </td>

<td>3 vuotta viimeisimmästä toiminnastasi, esim. vierailusta verkkosivustoillamme, ostoksesta tai viestintään osallistumisesta. 
<br>
 

Jos olet antanut markkinointiluvan (sähköposti, tekstiviesti, puhelin jne.), ja jos meillä on lupasi. 
</td>
</tr>
<tr>
<td>Petokset ja riskinarviointi </td>

<td>5 vuotta tai niin kauan kuin meillä on velvollisuus täyttää tarvittava oikeudellinen vaatimus. </td>
</tr>
</table>

</div>

<!--This is the 23rd section (white) - title: Markkinointiviestintä-->
<div class="white">
<h2>Markkinointiviestintä </h2>

<p>Sinulla on oikeus kieltäytyä vastaanottamasta markkinointiviestintää SmartResQ/CorPatch<sup>®</sup>:ltä. Voit tehdä sen kahdella eri tavalla: </p>
<ul>
<li>noudata asianomaisen markkinointiviestinnän peruutusohjeita, </li>

<li>muuta asetuksia tilin muokkausosiossa, jos sinulla on SmartResQ/CorPatch<sup>®</sup>-tili, </li>

<li>ota meihin yhteyttä sähköpostitse osoitteeseen <a href="mailto:info@corpatch.com">info@corpatch.com</a></li>
</ul>
 

<p>Huomaa, että vaikka kieltäytyisit vastaanottamasta markkinointiviestintää, saatat silti saada SmartResQ/CorPatch<sup>®</sup>:ltä hallinnollisia viestejä, kuten tilausvahvistuksia ja ilmoituksia, jotka ovat tarpeen tilisi hallinnoimiseksi tai asiakkaillemme esimerkiksi mobiilipalveluiden tai -sovellusten kautta tarjottavien palveluiden hallinnoimiseksi. </p>

</div>

<!--This is the 24th section (beige) - title: Tietosuojaoikeutesi-->
<div class="beige">
<h2>Tietosuojaoikeutesi </h2>

<p>SmartResQ/CorPatch<sup>®</sup>:lle on tärkeää, että olet tietoinen kaikista tietosuojaoikeuksistasi. </p>

<p>Jotkut tietosuojalait, mukaan lukien EU:n yleinen tietosuoja-asetus (GDPR), vastaava lainsäädäntö Saksassa Bundesdatenschutzgesetz (BDSG), Sveitsissä ja Yhdistyneessä kuningaskunnassa sekä jotkut Yhdysvaltojen osavaltioiden lait, antavat sinulle tiettyjä oikeuksia, jotka liittyvät meille jakamiisi henkilötietoihin. Jos asut Euroopan talousalueella, sinulla voi olla seuraavat oikeudet: </p>
<ol>
<li><b>Tarkastusoikeus</b> – Sinulla on oikeus pyytää meiltä kopioita henkilötiedoistasi. </li>

<li><b>Oikaisuoikeus</b> – Sinulla on oikeus pyytää meitä oikaisemaan henkilötiedot, jotka ovat mielestäsi virheellisiä. Sinulla on myös oikeus pyytää meitä täydentämään tietoja, jotka ovat mielestäsi puutteellisia.  </li>

<li><b>Poisto-oikeus</b> – Sinulla on oikeus pyytää meitä poistamaan henkilötietosi tietyin edellytyksin.  </li>

<li><b>Käsittelynrajoitusoikeus</b> – Sinulla on oikeus pyytää meitä rajoittamaan henkilötietojesi käsittelyä tietyin edellytyksin. </li>

<li><b>Käsittelynvastustusoikeus</b> – Sinulla on oikeus vastustaa henkilötietojesi käsittelyä tietyin edellytyksin. </li>

<li><b>Oikeus tietojen siirrettävyyteen</b> – Sinulla on oikeus pyytää, että siirrämme keräämämme henkilötiedot toiselle organisaatiolle tai tietyin edellytyksin suoraan sinulle. </li>
</ol>
 

<p>Jos haluat käyttää jotakin näistä oikeuksista, ota meihin yhteyttä sähköpostiosoitteeseen <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

</div>

<!--This is the 25th section (white) - title: Mitä evästeet ovat?-->
<div class="white">
<h2>Mitä evästeet ovat? </h2>

<p>Evästeet ovat pieniä tekstitiedostoja, jotka sisältävät merkkijonon ja luovat käyttäjälle yksilöllisen tunnuksen. Ne palautetaan verkkosivustolle ja/tai kolmansille osapuolille. Useimmat selaimet on alun perin asetettu hyväksymään evästeet, koska useimpien verkkosivustojen on käytettävä niitä. Voit kuitenkin muuttaa selainasetuksia niin, että selaimesi voi yleensä hylätä evästeet, estää kolmannen osapuolen evästeet tai määrittää, milloin eväste lähetetään.</p>
 
 

<p>SmartResQ/CorPatch<sup>®</sup> on sitoutunut varmistamaan, että sinulla on oikeus mukauttaa etujasi ja hallita meiltä tulevan digitaalisen markkinoinnin laajuutta valinnanhallintajärjestelmän avulla. </p>

</div>

<!--This is the 26th section (beige) - title: Kuinka käytämme evästeitä-->
<div class="beige">
<h2>Miten käytämme evästeitä </h2>

<p>SmartResQ/CorPatch<sup>®</sup> käyttää evästeitä monin eri tavoin parantaakseen käyttökokemustasi verkkosivustoillamme, sovelluksissamme ja palveluissamme erilaisista syistä, kuten: </p>
<ul>
<li>Toiminnallisuus – käytämme näitä evästeitä tunnistamaan sinut verkkosivustollamme ja muistamaan aiemmin valitsemasi asetukset. Näitä ovat valitsemasi kieli ja sijainti, jossa olet. Käytössä on yhdistelmä ensimmäisen ja toisen osapuolen evästeitä. </li>

<li>Mainonta – käytämme näitä evästeitä kerätäksemme tietoja vierailustasi verkkosivustoillamme tai sovelluksissamme, katsomastasi sisällöstä, seuraamistasi linkeistä sekä tietoja selaimestasi, laitteestasi ja IP-osoitteestasi. Joskus jaamme joitakin rajoitettuja osia näistä tiedoista kolmansien osapuolten kanssa mainostarkoituksiin. Voimme jakaa myös evästeiden avulla kerättyjä verkkotietoja mainoskumppaneidemme kanssa. Tämä tarkoittaa, että vieraillessasi toisella verkkosivustolla sinulle saatetaan näyttää mainoksia, jotka perustuvat selaustottumuksiisi verkkosivustollamme. </li>
</ul>


</div>

<!--This is the 27th section (white) - title: Käyttämiemme evästeiden tyyppi-->
<div class="white">
<h2>Käyttämiemme evästeiden tyyppi </h2>

<p>Verkkosivusto(i)llamme käytetään seuraavan tyyppisiä evästeitä: </p>
<ul>
<li><b>Google Analytics:</b> Tämän evästeen avulla voimme tarkastella tietoja käyttäjien suorittamista toiminnoista verkkosivustolla, mukaan lukien mutta ei rajoittuen sivujen katseluun, lähteeseen ja verkkosivustolla vietettyyn aikaan. Tiedot anonymisoidaan ja näytetään lukuina, joten niitä ei voida jäljittää yksittäisiin henkilöihin. Tämä auttaa suojaamaan yksityisyyttäsi. Google Analyticsin avulla näemme, mikä sisältö on suosittua sivuillamme, ja pyrimme tarjoamaan sinulle enemmän sitä, mitä haluat lukea ja nähdä. 

<li><b>Google Analytics -uudelleenmarkkinointi:</b> Asettaa evästeitä tietokoneellesi, mikä tarkoittaa, että kun poistut sivustoltamme, Google voi näyttää sinulle SmartResQ/CorPatch<sup>®</sup>-mainoksia, joista saatat olla kiinnostunut sen perusteella, miten olet aiemmin käyttäytynyt sivustollamme. Näitä tietoja ei voida tunnistaa henkilökohtaisesti. 

<li><b>Google Ads:</b> Google Adsin avulla näemme, mitkä sivut olivat avuksi yhteydenottolomakkeemme kautta tapahtuvassa lähettämisessä. Näitä tietoja ei voida tunnistaa henkilökohtaisesti, mutta yhteydenottolomakkeen tiedot voidaan. 

<li><b>Google Ads -uudelleenmarkkinointi:</b> Asettaa evästeitä tietokoneellesi, mikä tarkoittaa, että kun poistut sivustoltamme, Google voi näyttää sinulle SmartResQ/CorPatch<sup>®</sup>-mainoksia, joista saatat olla kiinnostunut sen perusteella, miten olet aiemmin käyttäytynyt sivustollamme. Näitä tietoja ei voida tunnistaa henkilökohtaisesti. 

<li><b>Facebook-uudelleenmarkkinointi:</b> Facebookin seurantapikseli asettaa tietokoneellesi evästeitä, jotka kertovat Facebookille sinun käyneen sivustolla. Oletamme, että olet kiinnostunut SmartResQ/CorPatch<sup>®</sup>:sta ja tämän sivuston sisällöstä. Kun käyt Facebookissa, sinulle esitetään tietoa tai mainoksia, joissa on samankaltainen sisältö. Käytä Facebookin yksityisyysasetuksiasi rajoittaaksesi tämän tyyppisen markkinoinnin esittämistä. 

<li><b>YouTube:</b> Integroimme videoita Googlen YouTube-alustalta (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, Yhdysvallat). Tietosuojakäytäntö: <a href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</li>
</ul>
 
</div>

<!--This is the 28th section (beige) - title: Miten voin hallita evästeitä-->
<div class="beige">
<h2>Miten voin hallita evästeitä </h2>

<p>Voit asettaa selaimen hylkäämään evästeitä tai poistamaan evästeitä. Jotkut verkkosivustomme ominaisuudet eivät kuitenkaan ehkä toimi siitä johtuen. Katso, kuinka vältät evästeet tietyissä selaimissa: </p>
<ul>
<li>Yhdysvaltain sivusto: <a href="https://www.aboutads.info/choices/"> https://www.aboutads.info/choices/</a></li>

<li>EU:n sivusto: <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>
</ul>
 

<p>SmartResQ/CorPatch<sup>®</sup> käyttää joitakin luotettavia kolmannen osapuolen palveluita sivuillamme. Palvelut saattavat hyödyntää evästeitä. Voit hylätä kolmannen osapuolen evästeet selaimessasi napsauttamalla tätä <a href="https://www.youronlinechoices.com/">linkkiä</a>.</p> 

<p>Voit estää Googlea keräämästä ja käsittelemästä Googlen evästeen tuottamia tietoja verkkosivustomme käytöstä lataamalla ja asentamalla Google Analytics Opt-out -selainlisäosan nykyiseen selaimeesi. Tämä lisäosa on saatavilla <a href="https://tools.google.com/dlpage/gaoptout">täältä</a>.</p>  

</div>

<!--This is the 29th section (white) - title: Muiden verkkosivustojen tietosuojakäytännöt-->
<div class="white">
<h2>Muiden verkkosivustojen tietosuojakäytännöt </h2>

<p>SmartResQ/CorPatch<sup>®</sup>-verkkosivustot sisältävät linkkejä muille verkkosivustoille. Tietosuojakäytäntömme koskee ainoastaan verkkosivustoamme, joten jos napsautat linkkiä toiselle verkkosivustolle, sinun on luettava sen tietosuojakäytäntö. </p>

</div>

<!--This is the 30th section (beige) - title: Tietosuojakäytäntömme muutokset-->
<div class="beige">
<h2>Tietosuojakäytäntömme muutokset </h2>

<p>Jos muutamme tietosuojalausuntoamme, julkaisemme tarkistetun lausunnon täällä päivitetyn tarkistuspäivän kera. Suosittelemme tarkistamaan lausunnon säännöllisesti. Jos teemme lausuntoomme olennaisia muutoksia, jotka muuttavat merkittävästi tietosuojakäytäntöjämme, saatamme ilmoittaa sinulle myös muilla tavoin, esimerkiksi lähettämällä sähköpostia tai julkaisemalla ilmoituksen verkkosivustollamme ja/tai sosiaalisessa mediassa ennen muutosten voimaantuloa. </p>

</div>

<!--This is the 31st section (white) - title: Kuinka voit ottaa yhteyttä SmartResQ ApSiin-->
<div class="white">
<h2>Kuinka voin ottaa yhteyttä SmartResQ ApSiin </h2>

<p>Jos sinulla on kysyttävää SmartResQ/CorPatch<sup>®</sup>:n tietosuojakäytännöstä, hallussamme olevista tiedoistasi tai jos haluat käyttää jotakin tietosuojaoikeuksiasi, ota meihin yhteyttä. </p>

<p>Sähköposti: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Verkkosivusto: <a href="https://corpatch.com">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Kuinka voit ottaa yhteyttä asianomaiseen viranomaiseen-->
<div class="beige"> 

<h2>Kuinka voin ottaa yhteyttä asianomaiseen viranomaiseen </h2>

<p>Jos haluat tehdä valituksen tai jos SmartResQ/CorPatch<sup>®</sup> ei mielestäsi ole käsitellyt ongelmaasi tyydyttävällä tavalla, voit ottaa yhteyttä tietosuojavaltuutetun toimistoon. </p>

<p>Tietosuojavaltuutetun toimiston osoite: 
<br><br>
Information Commissioner’s Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>

 

<p>Asiakaspalvelun puhelinnumero: +44 303 123 1113 </p>

<p>ICO verkkosivusto: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – Kaikki oikeudet pidätetään 
<br>
Tanska, Versio 1.3 – Julkaistu 25.4.2023 </b></p>

</div>

</div>
</body>
</html>`

export const termsOfUse_fr_FR: string = `<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
        
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
        
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>
<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 

<!--This is the 1st section - title: Conditions générales-->
<div class="top-section">

<h1><a href="#terms">Conditions générales</a> & <a href="#policy">Politique de confidentialité</a></h1>
<h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
             
</div>
    
<!--This is the 2nd section (white) - title: Objet de ce document and Comment contacter SmartResQ -->
<div class="white">
<h1 id="terms">Conditions générales – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Objet de ce document </h2>

<p>Bienvenue sur SmartResQ/CorPatch<sup>®</sup> ! </p>

 

<p>Vous lisez actuellement nos conditions générales ; un document contenant des informations sur votre relation avec SmartResQ ApS, qui a commencé lorsque vous avez accédé à notre site Web, téléchargé notre ou nos applications gratuites, ou acheté l’un de nos produits ou similaire. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> et ses associés vous fournissent leurs produits et services sous réserve des conditions suivantes. Si vous visitez ou passez commande sur nos sites Web ou applications, vous acceptez ces conditions. Nous vous conseillons vivement de les lire attentivement avant de procéder à toute utilisation de nos produits ou services. </p>

 

<p>Ce document couvre : </p>
<ul>
<li>Comment contacter SmartResQ </li>

<li>Produits, propriétés et bon usage </li>

<li>Avis de non-responsabilité et garanties </li>

<li>Commande </li>

<li>Paiement </li>

<li>Livraison </li>

<li>Responsabilités et droits relatifs à la vente </li>

<li>Retours </li>

<li>Traitement des données personnelles </li>

<li>Réclamations </li>

<li>Modifications apportées à ce document </li>

<li>Droit et juridiction </li>

<li>Coordonnées de contact </li>
</ul>
 

<p>Si vous avez des questions concernant les « Conditions générales » de SmartResQ, veuillez nous contacter. </p>

 

<h2>Comment contacter SmartResQ </h2>

<p>SmartResQ s’efforce d’augmenter les chances de survie grâce à une RCR de qualité en cas d’arrêt cardiaque. Nous voulons préparer et guider les passants à agir dès les premières minutes cruciales après avoir été témoins d’un arrêt cardiaque. C’est pourquoi nous avons développé CorPatch<sup>®</sup> - nous vous aidons à sauver une vie. </p>

 

<p>Notre société est située à Lundevej 26, 5700 Svendborg, Danemark </p>

 

<p>Vous pouvez nous trouver au registre central des entreprises sous le numéro de TVA DK 38674102 </p>

 

<p>Vous pouvez nous appeler au numéro de téléphone +45 62 200 100 </p>

 

<p>ou </p>

 

<p>envoyez-nous un e-mail à <a href="mailto:info@corpatch.com">info@corpatch.com</a></p> 

 

<h3>Des questions, commentaires ou réclamations ? </h3>

<p>Nous apprécions votre opinion, veuillez donc nous contacter via les coordonnées mentionnées ci-dessus ou utiliser le formulaire de contact sur nos sites Web ou applications. </p>

</div>

<!--This is the 3rd section (beige) - title: Produits, propriétés et bon usage -->
<div class="beige">
<h2>Produits, propriétés et bon usage </h2>

<h3>Nos sites Web, applications et produits </h3>

<p>Les paragraphes suivants décrivent les propriétés, droits et responsabilités essentiels liés à l’utilisation des services, applications et produits SmartResQ/CorPatch<sup>®</sup>. 
<br>
Nous déployons des efforts raisonnables pour inclure des informations exactes et à jour sur le(s) site(s) Web et dans les applications. Cependant, SmartResQ/CorPatch<sup>®</sup> ne fournit aucune garantie ou représentation quant à l’exactitude des informations. Nous déclinons toute responsabilité vis-à-vis de toute erreur ou omission dans le contenu, les produits et les applications du site Web. Les informations fournies sur ce site Web ou dans les applications ne remplacent pas les soins médicaux. Nous assumons la responsabilité de nos produits et applications conformément au Règlement relatif aux dispositifs médicaux UE 2017/745 (RDM). </p>

 

<h3>Droits d’auteur </h3>

<p>Tout le contenu figurant sur ce site, tel que les textes, les graphiques, les logos, les symboles de bouton, les images, les clips audio, les téléchargements numériques, les compilations de données et les logiciels, est la propriété de SmartResQ/CorPatch<sup>®</sup> ou de ses fournisseurs de contenu, et est protégé par les lois internationales sur les droits d’auteur. La compilation de tout le contenu de ce site est la propriété exclusive de SmartResQ/CorPatch<sup>®</sup>, avec la paternité des droits d’auteur pour cette collection par SmartResQ/CorPatch<sup>®</sup> et protégée par les lois internationales sur les droits d’auteur. </p>

 

<h3>Marques et modèles déposés </h3>

<p>Les marques déposées SmartResQ/CorPatch<sup>®</sup>, l’habillage commercial et la conception des produits ne sauraient être utilisés en relation avec tout produit ou service ne provenant pas de SmartResQ/CorPatch<sup>®</sup>, d’une manière susceptible de semer la confusion parmi les clients, ou de dénigrer ou discréditer SmartResQ/CorPatch<sup>®</sup>. Toutes les autres marques déposées non détenues par SmartResQ/CorPatch<sup>®</sup> ou ses filiales qui apparaissent sur ce site sont la propriété de leurs propriétaires respectifs, qui peuvent ou non être affiliés, connectés ou parrainés par SmartResQ/CorPatch<sup>®</sup> ou ses filiales. </p>

 

<h3>Licence et accès au site </h3>

<p>SmartResQ/CorPatch<sup>®</sup> vous accorde une licence limitée pour accéder et faire un usage personnel de son ou ses site(s) et application(s) et non pour télécharger (autrement que pour la mise en cache de page) ou modifier tout ou partie de celui-ci, sauf avec le consentement écrit exprès de SmartResQ/ CorPatch<sup>®</sup>. Cette licence n’inclut pas la revente ou l’utilisation commerciale de ces site(s) ou applications, ou de leur contenu : (a) toute collecte et utilisation de listes de produits, de descriptions ou de prix ; (b) toute utilisation dérivée de ce site ou de son contenu ; (c) tout téléchargement ou copie d’informations de compte au profit d’un autre marchand ; ou (d) toute utilisation d’exploration de données, de robots ou d’outils similaires de collecte et d’extraction de données.</p>

 

<p>Ce site (ou cette application ou toute partie de ce site) ne peut être reproduit, repensé, dupliqué, copié, vendu, revendu, visité ou autrement exploité à des fins commerciales sans le consentement exprès écrit de SmartResQ/CorPatch<sup>®</sup>. Vous ne pouvez pas encadrer ou utiliser des techniques de cadrage pour joindre une marque, un logo ou d’autres informations exclusives (y compris des images, du texte, une mise en page ou un formulaire) de SmartResQ/CorPatch<sup>®</sup> et de nos associés sans consentement explicite écrit. </p>

 

<p>Vous ne pouvez pas utiliser de balises méta ou tout autre « texte caché » utilisant le nom ou les marques SmartResQ/CorPatch<sup>®</sup> sans le consentement explicite écrit de SmartResQ/CorPatch<sup>®</sup>. Toute utilisation non autorisée met fin à l’autorisation ou à la licence accordée par SmartResQ/CorPatch<sup>®</sup>.</p>

 

<p>Vous bénéficiez d’un droit limité, révocable et non exclusif de créer un lien hypertexte vers la page d’accueil de SmartResQ/CorPatch<sup>®</sup> tant que le lien ne représente pas SmartResQ/CorPatch<sup>®</sup>, ses associés ou leurs produits ou services sous une forme fausse, trompeuse, désobligeante ou autrement offensante. Vous ne pouvez pas utiliser le logo SmartResQ/CorPatch<sup>®</sup> ou tout autre graphique ou marque déposée dans le cadre du lien sans autorisation explicite écrite. 
<br>
Si l’accord sur la licence et l’accès au site est violé, des poursuites judiciaires seront engagées et une action en justice pourra être intentée devant un tribunal. </p>

<h3>Communication électronique </h3>

<p>Lorsque vous visitez la page d’accueil et les réseaux sociaux de SmartResQ/CorPatch<sup>®</sup> ou que vous nous envoyez des e-mails, vous communiquez avec nous par voie électronique. Vous consentez à recevoir des communications de notre part de manière électronique. Nous communiquerons avec vous par e-mail ou en publiant des avis sur nos sites Web ou dans les applications sous la forme de fenêtres contextuelles ou de notifications. Vous convenez que tous les accords, avis, divulgations et autres communications que nous vous fournissons par voie électronique satisfont à toute exigence légale selon laquelle ces communications doivent être écrites. Veuillez vous référer à notre politique de confidentialité dans un document séparé qui peut être trouvé sur notre page d’accueil. </p>

 

<p>Les visiteurs de notre site Web et de nos canaux de médias sociaux peuvent être invités à publier des critiques, des commentaires et d’autres contenus, par exemple soumettre des suggestions, des idées, des commentaires, des questions ou d’autres informations, tant que le contenu n’est pas illégal, obscène, menaçant, diffamatoire, envahissant la vie privée, portant atteinte aux droits de propriété intellectuelle ou autrement préjudiciable à des tiers ou répréhensible, et ne consiste pas en ni ne contient des virus logiciels, des campagnes politiques, des sollicitations commerciales, des chaînes de lettres, des envois de masse ou toute forme de « spam ». </p>

 

<p>Vous ne pouvez pas utiliser une fausse adresse e-mail, usurper l’identité d’une personne ou d’une entité, ou autrement induire en erreur quant à l’origine d’une carte ou de tout autre contenu. SmartResQ/CorPatch<sup>®</sup> se réserve le droit (mais pas l’obligation) de supprimer ou de modifier ce contenu, mais ne révise pas régulièrement le contenu publié. Si vous publiez du contenu ou soumettez des documents et sauf indication contraire de notre part, vous accordez à SmartResQ/CorPatch<sup>®</sup> et à ses associés un droit non exclusif, libre de droits, perpétuel, irrévocable et entièrement sous-licenciable d’utiliser, reproduire, modifier, adapter, publier, traduire, créer des œuvres dérivées à partir de, distribuer et afficher ce contenu dans le monde entier sur n’importe quel support. Vous accordez à SmartResQ/CorPatch<sup>®</sup> et à ses associés et sous-licenciés le droit d’utiliser le nom que vous soumettez en relation avec ce contenu s’ils le souhaitent. </p>

 

<p>Vous déclarez et garantissez que vous possédez ou contrôlez autrement tous les droits sur le contenu que vous publiez ; que le contenu est exact ; que l’utilisation du contenu que vous fournissez ne viole pas cette politique et ne causera de préjudice à aucune personne ou entité ; et que vous indemniserez SmartResQ/CorPatch<sup>®</sup> ou ses associés pour toutes les réclamations résultant du contenu que vous fournissez. SmartResQ/CorPatch<sup>®</sup> a le droit mais non l’obligation de surveiller et de modifier ou de supprimer toute activité ou contenu. SmartResQ/CorPatch<sup>®</sup> décline toute responsabilité et n’assume aucune responsabilité pour tout contenu publié par vous-même ou un tiers. </p>

</div>

<!--This is the 4th section (white) - title: Avis de non-responsabilité et garanties-->
<div class="white">
<h2>Avis de non-responsabilité et garanties </h2>

<h3>Informations sur le produit – CorPatch<sup>®</sup> et CorPatch<sup>®</sup> Trainer </h3>

<p>Pour une utilisation correcte de tout produit ou service SmartResQ/CorPatch<sup>®</sup>, vous devez toujours suivre les derniers guides d’utilisation, instructions et descriptions. Ceux-ci peuvent être trouvés sur notre site Web et sous chaque description de produit. </p>

 

<p>Le produit CorPatch<sup>®</sup> est un dispositif médical certifié. En cas d’urgence concernant une personne adulte en arrêt cardiaque, le produit doit être sorti de son boîtier, puis placé et attaché correctement sur la poitrine de la personne en arrêt cardiaque. Le produit peut collecter des données sur la compression, la profondeur, la fréquence et le relâchement, et peut les transmettre via Bluetooth<sup>®</sup> à des appareils potentiels. Des applications gratuites sur les appareils peuvent aider le passant à utiliser la RCR et afficher la fraction de flux, la profondeur, le relâchement et la fréquence si elles sont activées et correctement installées, c’est-à-dire via Bluetooth<sup>®</sup>, smartphone, application, alimentation de la batterie, etc. SmartResQ/CorPatch<sup>®</sup> n’est pas responsable des facteurs externes, par exemple, des signaux de communication perturbateurs, un manque de couverture des données, un manque de batterie, des paramètres matériels ou logiciels incorrects, qui peuvent affecter l’expérience de l’utilisateur ou similaire. </p>

 

<p>De même, SmartResQ/CorPatch<sup>®</sup> n’est pas responsable des dommages physiques causés lors de l’utilisation du produit s’il n’est pas appliqué ou utilisé conformément aux instructions, par exemple, le fait d’effectuer des compressions thoraciques de manière non ergonomique ou d’avoir une position des mains inappropriée. SmartResQ/CorPatch<sup>®</sup> a évalué les risques techniques dans le cadre de la gestion des risques prescrite par la loi pour les dispositifs médicaux, mais aucune garantie n’est fournie pour les erreurs allant au-delà. En cas de dysfonctionnements inattendus ou de comportement non plausible d’un système SmartResQ/CorPatch<sup>®</sup>, l’utilisateur est invité à effectuer la RCR manuellement. Dans ce cas, SmartResQ/CorPatch<sup>®</sup> n’est pas responsable car cela est hors du contrôle de SmartResQ/CorPatch<sup>®</sup>. 
<br>
SmartResQ/CorPatch<sup>®</sup> surveille les niveaux et l’état de santé de la batterie, mais si la batterie est déchargée, le produit ne fonctionnera pas. C’est à l’utilisateur seul qu’il incombe de s’assurer que l’appareil est à jour, non endommagé et dispose d’une batterie suffisante pour fonctionner correctement, ce qui peut être fait facilement en exécutant une session d’entraînement qui valide la fonctionnalité correcte. Nous vous recommandons de vous entraîner pendant 4 minutes tous les 3 mois avec votre CorPatch<sup>®</sup>. </p>

 

<p><b>Important !</b> Le produit CorPatch<sup>®</sup> doit être utilisé exclusivement sur une personne en situation réelle et uniquement en cas d’arrêt cardiaque. Il n’est pas destiné à être utilisé sur des personnes souffrant, par exemple, d’un accident vasculaire cérébral, d’une crise cardiaque ou d’autres maladies liées qui ne sont pas un arrêt cardiaque. Le CorPatch<sup>®</sup> n’est pas destiné à être utilisé si la personne est allongée sur une surface molle, par exemple un canapé ou un lit, car les commentaires de profondeur dans de telles conditions peuvent s’avérer inexacts. Aucune solution SmartResQ/CorPatch<sup>®</sup> n’est destinée à être utilisée dans un environnement mobile, y compris, mais sans s’y limiter, une ambulance aérienne, maritime ou routière. S’il est utilisé pendant le transport du patient ou s’il est soulevé/retiré du corps pendant la RCR, l’appareil pourrait fournir des informations inexactes. Le CorPatch<sup>®</sup> doit être attaché à la poitrine du patient avec le patch. Assurez-vous que le patient est allongé sur une surface ferme, horizontale et immobile et que le CorPatch<sup>®</sup> est attaché à la poitrine avec le patch. </p>

 

<p>Le produit CorPatch<sup>®</sup> Trainer doit être utilisé exclusivement sur des mannequins ou objets similaires lors de Training Sessions et jamais en situation réelle sur une personne réelle souffrant d’un arrêt cardiaque ou de toute autre maladie. </p>

 

<p>Il est possible d’exercer régulièrement vos performances de RCR par rapport aux compressions thoraciques en utilisant CorPatch<sup>®</sup> ou CorPatch<sup>®</sup> Trainer. Nous vous recommandons de vous entraîner sur un mannequin, mais si vous n’en disposez pas, une chaise de bureau suffisamment réactive ou un canapé dur peuvent être utilisés en remplacement. Considérez l’élasticité et la dureté de l’objet avec lequel vous vous entraînez. SmartResQ ne recommande pas d’utiliser des objets mous, y compris, mais sans s’y limiter, des oreillers ou des canapés moelleux lors de l’entraînement, car l’expérience utilisateur ne sera pas correcte. </p>


<p>Si vous ne trouvez pas de mannequin ou d’objet de remplacement pour l’entraînement, envisagez de vous entraîner aux gestes requis avant de commencer les compressions thoraciques. Il peut s’agir de déballer le CorPatch<sup>®</sup> du porte-clés et de suivre les informations fournies par l’application gratuite sur l’identification d’un arrêt cardiaque et l’appel des services d’urgence. Dans ce cas, vous pourrez appliquer le CorPatch<sup>®</sup> rapidement au cas où vous seriez témoin d’un arrêt cardiaque. </p>

 

<p>N’utilisez aucun système SmartResQ/CorPatch<sup>®</sup> pour vous entraîner ou « vous amuser » sur une personne (vivante ou morte), un animal de compagnie ou tout autre être vivant. </p>

 

<h3>Avis de non-responsabilité concernant les produits, le(s) site(s) Web et les applications </h3>

<p>SmartResQ/CorPatch<sup>®</sup> ne fait aucune représentation ou garantie et ne sera pas responsable de la compétence de toute personne qui recevrait des informations éducatives et/ou une formation médicale fournies par ou basées sur le système ou de l’exercice de ses compétences par une telle personne après l’achèvement de toute formation, cours ou cursus utilisant le système. SmartResQ/CorPatch<sup>®</sup> ne garantit pas que toute personne recevant des informations pédagogiques et/ou une formation médicale du système atteindra un niveau de compétence particulier ou les compétences nécessaires pour se qualifier pour une licence, des certificats ou des qualifications délivrés par un organisme de réglementation ou une autorité gouvernementale. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> et ses associés s’efforcent d’être aussi précis que possible. Cependant, SmartResQ/CorPatch<sup>®</sup> ne fait aucune représentation et ne donne aucune garantie que le système et les informations, produits et formations fournis ici : (a) seront toujours ou simplement disponibles ; ou (b) sont exempts d’erreurs, complets, exacts, véridiques, à jour et/ou non trompeurs. Lorsque vous utilisez le système, vous avez conscience que vous renoncez à toute réclamation que vous pourriez avoir contre SmartResQ/CorPatch<sup>®</sup> pour vous être fié(e) à toute information ou entraînement fourni par le système. </p>

 

<p>Nos produits ne sont pas destinés à être utilisés par des enfants. Les produits sont petits et colorés, et peuvent être confondus avec des jouets, mais les produits SmartResQ/CorPatch<sup>®</sup> ne sont pas des jouets ! Nous vous recommandons de ne pas laisser les produits dans un endroit accessible aux enfants. SmartResQ/CorPatch<sup>®</sup> décline toute responsabilité concernant l’utilisation des produits par les enfants. Toute utilisation de CorPatch<sup>®</sup> par des enfants ou des adolescents doit être autorisée et surveillée par des adultes responsables, par exemple les parents, un membre de la famille ou des enseignants. </p>

 

<p>Exclusion de garantie et limitation de responsabilité, ce site est fourni par SmartResQ/CorPatch<sup>®</sup> sur une base « telle quelle » et « telle que disponible ». SmartResQ/CorPatch<sup>®</sup> ne fait aucune représentation ou garantie d’aucune sorte, explicite ou implicite, quant au fonctionnement de ce site ou aux informations, contenus, matériaux ou produits inclus sur ce site. Vous convenez expressément que votre utilisation de ce site s’effectue à vos risques et périls. 
<br>
Dans toute la mesure permise par la loi applicable, SmartResQ/CorPatch<sup>®</sup> décline toute garantie, explicite ou implicite, y compris, mais sans s’y limiter, les garanties implicites de qualité marchande et d’adéquation à un usage particulier. SmartResQ/CorPatch<sup>®</sup> ne garantit pas que ce site, ses serveurs ou les e-mails envoyés par SmartResQ/CorPatch<sup>®</sup> sont exempts de virus/spam ou d’autres composants nuisibles. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ne sera pas responsable des dommages de toute nature résultant de l’utilisation de ce site ou de ses produits, y compris, mais sans s’y limiter, les dommages directs, indirects, accessoires, punitifs et consécutifs. 
<br>
Les lois de certains États n’autorisent pas les limitations des garanties implicites ou l’exclusion ou la limitation de certains dommages. Si ces lois s’appliquent à vous, certaines ou toutes les clauses de non-responsabilité, exclusions ou limitations ci-dessus peuvent ne pas s’appliquer dans votre cas, et vous pourriez bénéficier de droits supplémentaires. </p>

 

<p>Les informations présentées sur ce site Web ne doivent pas être interprétées comme des conseils professionnels. Vous devriez toujours consulter des conseillers professionnels familiers avec votre situation factuelle pour obtenir des conseils sur des questions spécifiques avant de prendre une décision. </p>

 

<p>Le site Web peut contenir des liens menant à des sites Web gérés par des individus ou des organisations sur lesquels SmartResQ/CorPatch<sup>®</sup> n’a aucun contrôle. SmartResQ/CorPatch<sup>®</sup> ne fait aucune déclaration et ne fournit aucune garantie concernant l’exactitude ou tout autre aspect des informations contenues sur ces sites Web. </p>

 

<p>La responsabilité des opinions, conseils, déclarations ou autres informations contenues dans les articles ou textes de ce site Web incombe uniquement à l’auteur et ne reflète pas nécessairement les opinions et les politiques de SmartResQ/CorPatch<sup>®</sup> en tant que telle. </p>

 

<h3>Avertissement légal</h3>

<p>En achetant, octroyant une licence, visualisant, utilisant et/ou accédant à nos sites Web, produits et applications, vous reconnaissez et acceptez que : </p>
<ol>
<li>Les systèmes fournis par SmartResQ/CorPatch<sup>®</sup> sont des produits spécifiques à utiliser uniquement pour l’usage prévu indiqué dans le manuel du produit. Veuillez lire attentivement les instructions d’utilisation et les manuels et assurez-vous de bien connaître nos produits médicaux avant de les utiliser.  </li>

<li>Les systèmes fournis par SmartResQ/CorPatch<sup>®</sup> sont des produits et outils pédagogiques et médicaux spécifiques qui ne sont pas certifiés en tant que dispositifs médicaux, sauf indication explicite ; ils ne sont pas destinés à un usage clinique ou diagnostique et sont destinés à être utilisés uniquement à des fins de formation médicale et d’amélioration des performances. </li>

<li>À tout moment, vous ne devez utiliser et accéder au système que dans le cadre de ces objectifs de formation médicale et d’amélioration des performances ; en conformité avec toutes les lois et réglementations applicables ; et conformément à toute documentation utilisateur, manuels d’instructions, guides et/ou exigences que nous sommes susceptibles de vous fournir par voie électronique ou en personne. </li>

<li>À aucun moment un système SmartResQ/CorPatch<sup>®</sup> ne peut à lui seul diagnostiquer, traiter ou guérir la maladie d’un être humain ou dans une situation vitale ; aider aux décisions, diagnostics ou traitements médicaux professionnels ou remplacer tout diagnostic, recommandation, conseil, traitement ou décision par un médecin dûment formé et agréé. </li>

<li>L’association d’un dispositif avec un préjudice ou un résultat pour le patient ne signifie pas que le dispositif a causé le préjudice ou le résultat. </li>

<li>SmartResQ/CorPatch<sup>®</sup> décline toute responsabilité vis-à-vis des dommages résultant d’une utilisation déraisonnable de ses produits ou d’une utilisation au-delà de l’utilisation prévue du produit. </li>
</ol>
 

<h3>Informations sur les performances </h3>

<p>Les informations contenues dans ce document sont présentées uniquement à titre de guide pour l’application des produits SmartResQ/CorPatch<sup>®</sup>. SmartResQ/CorPatch<sup>®</sup> travaille continuellement à l’amélioration de la qualité et de la fiabilité de ses produits. Néanmoins, nos appareils peuvent mal fonctionner ou tomber en panne en raison de leur sensibilité électrique inhérente et de leur vulnérabilité au stress physique et aux signaux de communication indésirables. Il incombe à l’acheteur, lors de l’utilisation des produits SmartResQ/CorPatch<sup>®</sup>, de respecter les normes de sécurité et de test, et d’éviter les situations dans lesquelles un dysfonctionnement ou une panne pourrait entraîner des blessures corporelles ou des dommages matériels. </p>

 

<h3>Garantie standard </h3>

<p>La garantie limitée standard SmartResQ/CorPatch<sup>®</sup> est conditionnelle à l’utilisation appropriée des produits, des sites Web et des applications.
<br>
Cette garantie est limitée au premier acheteur du produit uniquement et uniquement si le produit a été acheté auprès d’un revendeur SmartResQ/CorPatch<sup>®</sup> agréé. Les fabricants, fournisseurs ou éditeurs, autres que SmartResQ/CorPatch<sup>®</sup>, peuvent vous fournir leurs propres garanties – veuillez les contacter pour plus d’informations. </p>

 

<p><u>La garantie ne couvre pas : </u></p>
<ol>
<li>Les défauts ou dommages résultant d’un accident, d’une mauvaise utilisation, d’une utilisation anormale, de conditions anormales, d’un stockage inapproprié, d’une exposition à un liquide, à l’humidité, au sable ou à la saleté, d’une négligence ou d’une contrainte physique, électrique ou électromécanique inhabituelle </li>

<li>Les défauts ou dommages résultant d’une force excessive ou de l’utilisation d’objets métalliques </li>

<li>Les équipements dont le numéro de production ou le code de données d’amélioration a été supprimé, dégradé, endommagé, modifié ou rendu illisible </li>

<li>L’usure normale ou le vieillissement normal du produit </li>

<li>Les rayures, bosses et dommages esthétiques, à moins que la panne ne soit due à un défaut de matériaux ou de fabrication </li>

<li>Les défauts ou dommages résultant de l’utilisation de produits en conjonction ou en connexion avec des accessoires, des produits ou des équipements auxiliaires/périphériques qui ne sont pas fournis ou approuvés par SmartResQ/CorPatch<sup>®</sup> </li>

<li>Les défauts ou dommages résultant d’un test, d’un fonctionnement, d’une maintenance, d’une installation, d’un entretien ou d’un réglage inappropriés,qui ne sont pas fournis ou approuvés par SmartResQ/CorPatch<sup>®</sup> </li>

<li>Les dommages causés par l’utilisation du produit SmartResQ/CorPatch<sup>®</sup> en dehors des directives publiées </li>

<li>La démonstration / installation du produit acheté </li>

<li>Les défauts ou dommages résultant de causes externes telles que collision avec un objet, incendie, inondation, saleté, rafale de vent, foudre, tremblement de terre, exposition aux conditions météorologiques, vol, fusible grillé ou mauvaise utilisation d’une quelconque source électrique </li>

<li>Les défauts ou dommages résultant de la transmission ou de virus, ou d’autres problèmes logiciels introduits dans les produits </li>

<li>Si les batteries sont chargées par des chargeurs autres que ceux compatibles avec CorPatch<sup>®</sup> Trainer </li>

<li>L’un des sceaux du boîtier de la batterie ou des cellules est brisé ou présente des signes d’effraction </li>

<li>Les produits réparés par, utilisés ou achetés auprès d’une autre société que SmartResQ/CorPatch<sup>®</sup> </li>

<li>Si SmartResQ/CorPatch<sup>®</sup> reçoit des informations des autorités publiques compétentes indiquant que le produit a été volé, ou si vous ne parvenez pas à désactiver les mesures de sécurité activées par mot de passe ou autres conçues pour empêcher l’accès non autorisé au produit, ou si vous ne pouvez pas prouver que vous êtes l’utilisateur autorisé du produit </li>

<li>Si les produits sont utilisés en dehors des conditions spécifiées dans les manuels, par exemple, la plage de température, la pression et l’humidité. </li>
</ol>
 

<h3>Batteries et chargeurs </h3>

<p>Les produits SmartResQ/CorPatch<sup>®</sup> contiennent soit des piles non remplaçables (CorPatch<sup>®</sup>) soit des piles rechargeables (CorPatch<sup>®</sup> Trainer). Les types de batteries utilisées dans nos produits sont décrits sous chaque produit individuel. SmartResQ/CorPatch<sup>®</sup> décline toute responsabilité si les batteries rechargeables ne sont pas manipulées correctement et conformément au manuel d’utilisation. </p>

 

<p>Dans le cadre de la vente d’appareils contenant des batteries, nous sommes dans l’obligation de porter à votre attention ce qui suit : 
<br>
En tant qu’utilisateur final, vous êtes légalement tenu de les éliminer correctement. Le symbole de la poubelle barrée signifie que la batterie ne doit pas être jetée avec les ordures ménagères. </p>

</div>            

<!--This is the 5th section (beige) - title: Commande -->
<div class="beige"> 
<h2>Commande </h2>

<p>La boutique en ligne Corpatch.com est ouverte 24h/24 et vous pouvez passer commande presque à tout moment. Cependant, nous pouvons fermer la boutique pour maintenance. L’achat en grand volume est possible directement avec SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> ne propose pas de produits à la vente aux mineurs. Les produits destinés aux enfants ne peuvent être achetés que par des adultes. Pour acheter chez SmartResQ/CorPatch<sup>®</sup>, vous devez avoir au moins 18 ans et posséder une carte de crédit valide ou un autre moyen de paiement que nous acceptons. </p>

 

<p>La présentation des produits dans la boutique en ligne n’est pas une offre juridiquement contraignante, mais un catalogue en ligne non contraignant. Lorsque vous désirez passez votre commande, sélectionnez les articles que vous souhaitez acheter et placez-les dans le « panier ». Vous pouvez modifier le contenu du panier jusqu’au moment de la commande. Tous les paiements supplémentaires tels que l’expédition ou les frais de carte de débit seront calculés immédiatement avant le paiement. </p>

 

<p>Lorsque vous désirez passer votre commande, cliquez sur « Commander » et saisissez les informations pertinentes. Vous pouvez modifier le contenu du panier jusqu’à ce que vous confirmiez votre achat en cliquant sur le bouton « Payer ». Passé ce stade, vous passez une commande ferme pour les marchandises contenues dans le panier, et votre commande ne peut plus être modifiée. 
<br>
SmartResQ/CorPatch<sup>®</sup> peut accepter la commande en envoyant une confirmation de commande par e-mail ou en livrant la marchandise dans le délai de livraison. </p>

 

<p>Certains pays peuvent empêcher l’utilisation et la détention de nos produits. Il est de votre seule responsabilité de savoir s’il est légal d’importer et/ou d’utiliser ce produit dans votre pays. Nous vous enverrons les produits que vous commandez et déclinons toute responsabilité vis-à-vis des problèmes de douane ou des conséquences éventuelles de votre détention ou utilisation de cet appareil. </p>

</div>

<!--This is the 6th section (white) - title: Paiement-->
<div class="white">
<h2>Paiement </h2>

<h3>Notre ou nos site(s) Web, application(s) et boutique(s) en ligne </h3>

<p>Nos sites Web et nos applications sont libres d’utilisation tant que nos politiques légales sont acceptées et respectées. Sachez que les achats de nos produits peuvent être disponibles dans la ou les boutique(s) en ligne sur notre ou nos site(s) Web et dans la ou les application(s). </p>

 

<h3>Nos produits </h3>

<p>SmartResQ/CorPatch<sup>®</sup> utilise QuickPay comme passerelle de paiement. QuickPay est certifié par le Conseil des normes de sécurité de l’industrie des cartes de paiement (PCI) conformément à la dernière version de la norme de sécurité des données PCI (DSS) niveau 1, qui comprend ; (a) un rapport annuel - « Rapport sur la conformité » (ROC) réalisé par un évaluateur de sécurité qualifié (QSA) ; (b) des analyses trimestrielles du réseau effectuées par le fournisseur d’analyse approuvé (ASV) ; et (c) un grand nombre de règles et de lignes directrices pour le flux de travail et le traitement des données. </p>

 

<p>Nous acceptons les paiements par : </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>Mastercard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
 

<p>Les paiements seront déduits de votre compte lors de l’expédition des marchandises. Tous les montants sont en euros et la taxe sur la valeur ajoutée légale est déjà incluse dans tous les prix mentionnés. Tous les titulaires de cartes de crédit/débit sont soumis à des contrôles de validation et à autorisation par l’émetteur de la carte ou du prestataire de paiement. SmartResQ/CorPatch<sup>®</sup> décline toute responsabilité dans le cas où votre fournisseur de carte de paiement refuserait d’autoriser les paiements. </p>
 
<p>Nous utilisons le cryptage des informations de carte de crédit via le protocole Secure Socket Layer (SSL). Cela garantit que personne ne peut intercepter le numéro de carte de crédit ou d’autres informations lors de la transaction avec notre fournisseur. </p>

 

<p>Avant de conclure le contrat, le client doit examiner et accepter les frais d’expédition et de logistique ajoutés au prix d’achat, car ces frais sont facturés au client. Après avoir passé commande, vous recevrez un e-mail de notre part accusant réception de votre commande. Veuillez noter que cela ne signifie pas que votre commande a été acceptée. Votre commande constitue une offre à SmartResQ/CorPatch<sup>®</sup> d’acheter un produit (et SmartResQ/CorPatch<sup>®</sup> se réserve le droit de refuser des commandes de produits). Aucun contrat n’existera en relation avec les produits jusqu’à ce que nous vous ayons confirmé par e-mail que le ou les produits ont été expédiés. Notre acceptation de votre offre sera réputée complète et le contrat entre nous sera formé lorsque nous vous enverrons un courrier de confirmation d’expédition. </p>

 

<p>Les frais d’expédition sont toujours indiqués par rapport à chaque commande individuelle. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> conserve la propriété de l’article acheté jusqu’à ce que le montant de la facture ait été entièrement payé par le client et automatiquement « prélevé » juste avant l’expédition. </p>

 

<p>Les clients n’ont droit à une compensation que si leurs demandes reconventionnelles ont été légalement établies ou sont incontestées ou reconnues par SmartResQ/CorPatch<sup>®</sup>. En outre, les clients ne disposent d’un droit de rétention que si et dans la mesure où leur demande reconventionnelle est basée sur la même relation contractuelle. </p>

 

<p>Si le client tarde à honorer ses obligations de paiement envers nous, toutes les créances existantes deviennent exigibles immédiatement. </p>

 

<h3>Frais de majoration </h3>

<p>Depuis le 1er janvier 2018, les règles concernant la surcharge ont été modifiées. Par conséquent, il n’est plus légal de majorer les frais sur les paiements effectués par carte bancaire si celle-ci est émise par des banques/émetteurs de cartes au sein de l’UE. Cela s’applique aux cartes de débit et de crédit. Les cartes individuelles sont des cartes délivrées à un consommateur privé. 
<br>
Toutefois, s’il s’agit d’une carte d’entreprise ou d’une carte individuelle émise en dehors de l’UE, les frais de transaction seront majorés. Cela signifie que le titulaire de la carte paie automatiquement les frais de transaction. 
<br>
Les frais ne seront pas supérieurs à ce que SmartResQ/CorPatch<sup>®</sup> est facturé par l’acquéreur. Les frais seront clairement affichés comme un poste distinct dans la fenêtre de paiement. </p>

</div>

<!--This is the 7th section (beige) - title: Livraison-->
<div class="beige">
<h2>Livraison </h2>

<p>Nous nous efforçons d’expédier les commandes d’un jour ouvrable à l’autre et utilisons une agence d’expédition de confiance internationale. Vous trouverez le prix total de votre achat, livraison comprise, sur la page de paiement et avant d’accepter votre commande finale. </p>

 

<p>Si le client n’accepte pas la marchandise, SmartResQ/CorPatch<sup>®</sup> peut résilier le contrat ou recevoir une indemnité pour inexécution après un délai de deux semaines pour couvrir les frais de traitement et d’expédition. </p>

 

<p>Si le client a fourni des informations incorrectes sur l’adresse de livraison, il est possible de récupérer le colis dans le magasin indiqué dans notre boutique en ligne, sinon le colis sera perdu. </p>

 

<p>Le client ne recevra jamais de livraisons partielles sauf indication explicite de SmartResQ/CorPatch<sup>®</sup>. </p>

 

<h3>Risque de perte </h3>

<p>La propriété des risques du produit est transférée à l’acheteur lorsque le produit est mis à la disposition de l’acheteur conformément à cet accord. Si le délai de livraison est passé et que l’acheteur ne reçoit pas un produit mis à sa disposition ou mis à sa disposition conformément à l’accord, l’acheteur assume le risque de perte ou de dommage causé par les caractéristiques du produit lui-même. </p>

</div>

<!--This is the 8th section (white) - title: Annulation et retours-->
<div class="white">
<h2>Annulation et retours </h2>

<p>Lorsque vous passez commande chez SmartResQ/CorPatch<sup>®</sup> en ligne ou hors ligne, vous avez 14 jours pour changer d’avis et annuler votre commande, pendant lesquels vous pouvez nous informer que vous avez changé d’avis et nous renvoyer l’article dans le même état que celui dans lequel il a été reçu. 
<br>
Nous n’acceptons que les retours d’articles inutilisés dans leur emballage d’origine, scellé et intact et ils doivent être correctement emballés pour l’expédition, sinon les produits seront considérés comme utilisés et aucun remboursement partiel ne s’appliquera. Le droit de rétractation ne s’applique qu’aux produits déscellés à la réception. </p>

 

<h3>Raisons acceptables pour renvoyer un produit </h3>
<ul>
<li>Utilisation du droit de rétractation de 14 jours </li>

<li>Produit non conforme à la description (garantie) </li>

<li>Le produit est défectueux </li>
</ul>
 

<p>Les conditions de retour de CorPatch<sup>®</sup> suivent les règles standard de l’UE. </p>

 

 

<p>Si vous renvoyez le produit, conservez l’emballage d’origine et ne l’endommagez pas, ne collez et/ou n’écrivez rien dessus. Procurez-vous et utilisez un emballage de retour dédié, par exemple une boîte en carton. </p>

 

<p>Pour CorPatch<sup>®</sup> Trainer, les conditions de rétractation pour ce produit obéissent aux règles standard de l’UE. </p>

 

<p>Pour exercer le droit de rétractation, vous devez nous en informer dans les 14 jours suivant la réception des articles. Les demandes d’annulation doivent être envoyées par e-mail à info@corpatch.com, en indiquant clairement que vous souhaitez utiliser vos droits de rétractation et pourquoi. </p>

 

<p>Veuillez renvoyer les articles dès que possible après l’avis d’annulation et au plus tard 14 jours après que vous nous en ayez informés par e-mail. </p>

 

<p>Nous pouvons refuser le remboursement jusqu’à ce que vous ayez renvoyé les articles ou prouvé que vous avez renvoyé les articles. Pour ce remboursement, nous utiliserons le même mode de paiement que celui utilisé lors de la transaction d’origine. </p>

 

<h3>Raisons non acceptables pour renvoyer un produit </h3>
<ul>
<li>Changement d’avis après le droit de rétractation de 14 jours </li>

<li>Si le produit a été activé </li>

<li>Si le produit est utilisé ou endommagé de toute autre manière </li>

<li>Si le logiciel/l’application gratuite est téléchargé, connecté, installé ou associé de toute autre manière au(x) produit(s) physique(s). </li>
</ul>
 

<h3>Comment renvoyer un produit </h3>

<p>Nous n’acceptons que les retours d’articles non ouverts dans leur emballage d’origine intact et ils doivent être correctement emballés pour l’expédition, sinon les produits seront considérés comme utilisés et aucun remboursement ne s’appliquera. </p>

 

<p><u>Sauf indication contraire, les retours doivent être envoyés à :</u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg (Suède) 
<br>
Danemark </p>

<p><b>IMPORTANT !</b> Vous assumez la seule responsabilité pour la qualité de l’emballage et des articles jusqu’à leur réception par nous. Veuillez conserver le reçu postal comprenant les informations sur les frais d’expédition et, le cas échéant, le numéro de suivi et de traçabilité. Nous ne couvrons pas les frais d’expédition de retour et nous n’acceptons pas les colis envoyés contre remboursement (COD) ou similaire. </p>

</div>

<!--This is the 9th section (beige) - title: Remboursements-->
<div class="beige"> 
<h2>Remboursements </h2>

<p>SmartResQ/CorPatch<sup>®</sup> est obligé de réparer, remplacer ou accorder une réduction de prix ou un remboursement complet si l’article s’avère défectueux dans les 2 ans suivant l’achat de l’article. 
<br>
Le client n’a pas droit à un remboursement si le problème est mineur, comme des rayures sur l’article ou similaire. 
<br>
Une fois que SmartResQ/CorPatch<sup>®</sup> reçoit l’article du client, un remboursement est initié. La valeur du montant du remboursement dépend de l’état des produits lorsqu’ils sont reçus chez SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>La manière dont le remboursement du client est traité dépend du mode de paiement d’origine. Si le client a payé par carte de crédit ou de débit, les remboursements seront envoyés à la banque émettrice de la carte dans les 5 jours ouvrables suivant la réception de l’article renvoyé ou de la demande d’annulation. Veuillez contacter la banque émettrice de la carte si vous avez des questions sur le moment où le crédit sera crédité sur votre compte. </p>

</div>

<!--This is the 10th section (white) - title: Traitement des données personnelles-->
<div class="white">
<h2>Traitement des données personnelles </h2>

<p>Chez SmartResQ/CorPatch<sup>®</sup>, nous accordons une grande importance à la protection de vos données personnelles grâce à notre effort de conformité des données en trois étapes : a. en conservant une cartographie détaillée de nos flux de données ; b. en entreprenant une évaluation juridique basée sur le flux de données afin de : c. mettre en œuvre les mesures de sécurité nécessaires pour assurer la sécurité de vos données. </p>

 

<p>Pour utiliser notre boutique en ligne, vous devez au moins fournir les informations suivantes : </p>
<ul>
<li>Nom </li>

<li>Adresse </li>

<li>Adresse e-mail </li>

<li>Numéro de téléphone </li>
</ul>
 

<p>La collecte d’informations personnelles sur les clients s’effectue dans le cadre de la législation en vigueur et de la législation européenne relative au règlement général sur la protection des données (RGPD). </p>

 

<p>Si vous souhaitez en savoir plus sur le traitement de vos données personnelles par nos soins, veuillez lire notre politique de confidentialité. 

</div>

<!--this is the 11th section (beige) - title: Réclamations-->
<div class="beige"> 
<h2>Réclamations </h2>

<h3>Comment nous adresser une réclamation </h3>

<p>En cas de problème avec le produit, vous pouvez soit faire réparer ou remplacer un produit défectueux, soit recevoir un remboursement ou une réduction de prix, selon la situation spécifique. 
<br>
Bien sûr, il est nécessaire que la réclamation soit justifiée et que le défaut ne soit pas causé par une utilisation incorrecte du produit ou un autre comportement incorrect. </p>

 

<p>Nous vous recommandons de formuler votre réclamation le plus tôt possible et dans la semaine suivant la découverte du défaut.
<br>
N’hésitez pas à nous contacter pour toute question, commentaire ou réclamation en nous envoyant un e-mail à : <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<h3>Réclamation auprès d’autres entités au sein de l’UE </h3>

<p>Veuillez visiter le site officiel de l’Union européenne pour porter plainte auprès d’autres entités au sein de l’UE. Trouvez des informations <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">ici</a>.</p>

</div>

<!--this is the 12th section (white) - title: Modifications apportées à ce document-->
<div class="white">

<h2>Modifications apportées à ce document </h2>

<h3>Sur le(s) site(s) Web, application(s) et politiques </h3>

<p>SmartResQ/CorPatch<sup>®</sup> se réserve le droit d’apporter des changements, de supprimer, de modifier ou de compléter ses sites Web, applications, politiques et documents à tout moment pour quelque raison que ce soit, sans préavis à quiconque. 
<br>
Si l’une de ces conditions est jugée invalide, nulle ou inapplicable pour quelque raison que ce soit, cette condition sera réputée nulle et non avenue et n’affectera pas la validité et l’applicabilité de toute condition restante. </p>

 

<p>Si nous modifions nos politiques, nous publierons les politiques révisées en ligne avec une date de révision mise à jour. Nous vous encourageons à consulter régulièrement les politiques. Si nous apportons des modifications importantes à nos politiques qui modifient considérablement nos pratiques en la matière, nous pouvons également vous en informer par d’autres moyens, par exemple, en envoyant un e-mail ou en publiant un avis sur notre site Web et/ou sur les réseaux sociaux avant que les modifications ne prennent effet. </p>

 

<h3>En relation avec un achat spécifique </h3>

<p>Lors de l’achat d’un produit, il vous sera demandé d’accepter une version de certains documents tels qu’ils sont à ce moment précis - cette version ne sera pas modifiée après ce moment et dictera les termes de notre relation avec vous concernant l’achat en question. </p>

</div>

<!--this is the 13th section (beige)- title: Droit et juridiction -->
<div class="beige">
<h2>Droit et juridiction </h2>

<h3>Les lois du Danemark et le tribunal de district de Svendborg </h3>

 

<p>SmartResQ/CorPatch<sup>®</sup> privilégie la loi danoise et les tribunaux danois pour tout litige juridique, et non pas la Convention de Vienne sur la vente internationale de marchandises (CVIM). 
<br>
Tout litige relatif de quelque manière que ce soit à votre visite sur SmartResQ/CorPatch<sup>®</sup> ou aux produits que vous achetez via SmartResQ/CorPatch<sup>®</sup> sera soumis à la confidentialité au Danemark, hormis le fait que, dans la mesure où vous avez violé ou menacé de violer les droits de propriété intellectuelle de SmartResQ/CorPatch<sup>®</sup>, SmartResQ/CorPatch<sup>®</sup> peut demander une injonction ou toute autre mesure appropriée dans n’importe quel pays. Vous consentez à la compétence et au lieu exclusifs de ces tribunaux. </p>

 

<p>Si l’accord sur les « Conditions générales » est violé, des poursuites judiciaires seront engagées et une action en justice pourra être intentée devant un tribunal. </p>

 

<p>Les litiges entre nous-même et tout consommateur sont soumis au tribunal de district de Svendborg, Christiansvej 41, 5700 Svendborg, Danemark. </p>

</div>

<!--this is the 14th section (white) title: Coordonnées de contact-->
<div class="white">
<h2>Coordonnées de contact </h2>

<p>Merci d’avoir lu les « Conditions générales » de SmartResQ/CorPatch<sup>®</sup>.
<br>
Si vous avez des questions, des commentaires ou des réclamations, n’hésitez pas à nous contacter. </p>

 

<p>Notre société est située à l’adresse : Lundevej 26, 5700 Svendborg, Danemark </p>

 

<p>Vous pouvez nous trouver au registre central des entreprises sous le numéro de TVA DK 38674102 </p>

 

<p>Vous pouvez nous téléphoner au Numéro de téléphone +45 62 200 100 </p>

 

<p>ou </p>

 

<p>envoyez-nous un e-mail à : <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<p><b>© SmartResQ ApS – Tous droits réservés 
<br>
Danemark, Version 2.1 – Publication le 25.04.2023 </b></p>

</div>
   
<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->
   



<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Politique de confidentialité -->
<div class="beige">
<h1 id="policy">Politique de confidentialité SmartResQ / CorPatch<sup>®</sup> </h1>

<h2>Nous traitons vos données conformément au RGPD </h2>

<p>SmartResQ/CorPatch<sup>®</sup> respecte votre vie privée. Cette déclaration de confidentialité décrit vos droits en matière de confidentialité et notre engagement à sécuriser vos informations personnelles. </p>

<p>Si vous avez des questions sur le traitement de vos données personnelles, veuillez nous contacter. Le responsable du traitement est : </p>

<p><b>Société :</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Adresse : </b>
<br>
Lundevej 26 
<br>
5700 Svendborg (Suède) 
<br>
Danemark </p>

<p><b>N° CVR :</b> 38674102 </p>

<p><b>N° de téléphone :</b> +45 62 200 100 </p>

<p><b>E-mail :</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> est une société danoise/européenne avec des entités juridiques, des processus commerciaux, des structures de gestion et des systèmes techniques qui dépassent les frontières nationales. SmartResQ/CorPatch<sup>®</sup> fournit des produits, des logiciels et des services aux entreprises publiques/privées en Europe. </p>

<p>Le siège social est situé au Danemark et SmartResQ/CorPatch<sup>®</sup> est soumis à la législation européenne sur la protection des données, y compris le Règlement général sur la protection des données (RGPD). Toutes les décisions importantes de SmartResQ/CorPatch<sup>®</sup> concernant la protection des données personnelles sont prises au niveau de la direction sous la supervision du délégué à la protection des données. </p>

<p>Cette déclaration de confidentialité est disponible sur nos sites Web et dans nos applications. </p>

<p>Veuillez ne pas utiliser les pages SmartResQ/CorPatch<sup>®</sup>, les applications ou nos services si vous n’acceptez pas la manière dont nous traitons les données personnelles dans le cadre de cette déclaration de confidentialité. </p>

</div>

<!--This is the 17th section (white) - title: Le type d’informations personnelles que nous recueillons -->
<div class="white">
<h2>Le type d’informations personnelles que nous recueillons  </h2>

<p>Lorsqu’un gestionnaire détermine les finalités et les moyens du traitement de vos données personnelles, il agit en tant que responsable du traitement. Cela inclut les scénarios où SmartResQ/CorPatch<sup>®</sup> collecte des données personnelles dans le contexte où vous êtes un demandeur d’emploi, un représentant d’un client ou un prospect, ou lorsque vous êtes un utilisateur de logiciel. </p>

<p>SmartResQ traite les informations personnelles à diverses fins, en fonction de la relation que nous entretenons avec vous. </p>

<p>Nous pouvons traiter les données suivantes : </p>
<ol>
<li>Données de base, telles que nom, adresse, numéro de téléphone (portable et/ou fixe) et e-mail </li>

<li>Données professionnelles telles que l’employeur, le titre, le poste, y compris les préférences et les intérêts dans un contexte professionnel </li>

<li>Feedback, commentaires ou questions sur SmartResQ/CorPatch<sup>®</sup> ou nos produits et services </li>

<li>Photos ou vidéos enregistrées sur nos sites </li>

<li>Contenu que vous avez téléchargé, tel que des photos, des vidéos et des performances au fil du temps </li>

<li>Informations utilisateur uniques telles que l’identifiant de connexion, le nom d’utilisateur, le mot de passe et la question de sécurité </li>

<li>Informations financières, lorsque vous acceptez que nous utilisions vos informations, par exemple pour conserver les détails de votre carte de paiement </li>

<li>Informations sur le trafic fournies par votre navigateur Web, telles que le type de navigateur, l’appareil, la langue et l’adresse du site Web d’où vous venez, et d’autres informations sur le trafic, y compris l’adresse IP </li>

<li>Comportement en matière de flux de clics et d’activité sur les identifiants de SmartResQ/CorPatch<sup>®</sup> et dans nos produits et services </li>

<li>Comportement en matière d’e-mails, tels que les e-mails de SmartResQ/CorPatch<sup>®</sup> que vous ouvrez, quand et comment </li>

<li>Autres informations personnelles contenues dans votre profil que vous avez librement placées sur des réseaux sociaux tiers, tels que LinkedIn, Facebook, Google, etc. </li>

<li>Informations utilisées à des fins scientifiques pour améliorer la survie après un arrêt cardiaque, collectées via nos sites Web et nos applications </li>

<li>Informations sur les utilisateurs pour fournir des produits conformes aux exigences de qualité et de sécurité, pour fournir des services aux utilisateurs et pour maintenir et améliorer nos offres </li>

<li>Informations sur les candidats pour gérer les candidatures, communiquer sur les offres d’emploi futures et maintenir et améliorer nos processus de recrutement </li>

<li>Informations sur les personnes qui se sont inscrites pour recevoir des newsletters et d’autres supports afin de fournir les supports et de maintenir et d’améliorer nos offres </li>

<li>Informations sur les cookies pour fournir des publicités personnalisées sur les réseaux sociaux et les sites Web. </li>
</ol>
</div>

<!--This is the 18th section (beige) - tile: Données collectées et traitées dans la plate-forme et les applications CorPatch<sup>®</sup> Services -->
<div class="beige">
<h2>Données collectées et traitées dans la plateforme et les applications CorPatch<sup>®</sup> Services </h2>

<p>SmartResQ gère, collecte et conserve les données personnelles suivantes lorsque vous utilisez la plate-forme ou les applications CorPatch<sup>®</sup> Services. </p>

<h3>Tous les utilisateurs (Institute Admin, Trainer, Trainee/utilisateur final) </h3>
<ul>
<li>Prénom (si renseigné) </li>

<li>Nom (si renseigné) </li>

<li>Surnom (si renseigné) </li>

<li>Adresse e-mail (obligatoire) </li>

<li>Langue de communication préférée (obligatoire) </li>

<li>Cryptage du mot de passe (obligatoire) </li>

<li>Si l’adresse e-mail a été validée (obligatoire) </li>
</ul>
 

<h3>En plus pour les trainees/utilisateurs finaux (obligatoire) </h3>

<p>Données sur le téléphone portable utilisé : </p>

<p>Système d’exploitation (Android/iOS) : </p>
<ul>
<li>Version du système d’exploitation (par exemple 9) </li>

<li>Fabricant (par exemple Samsung) </li>

<li>Modèle (par exemple SM-T518) </li>

<li>Version de l’application (par exemple 1.2.4) </li>

<li>Heure de la dernière activité de premier plan de l’application </li>

<li>Heure de la dernière activité d’arrière-plan de l’application </li>
</ul>
 

<p>Données sur le CorPatch<sup>®</sup> (CPS) utilisé : </p>
<ul>
<li>Numéro de série / adresse MAC </li>

<li>Version de firmware </li>

<li>Nom de modèle (par exemple CPS_01) </li>

<li>Fabricant (actuellement toujours SRQ) </li>

<li>Nom (actuellement toujours CorPatch<sup>®</sup>) </li>

<li>État de la batterie </li>

<li>Défauts </li>
</ul>
 

<p>Données d’intégration des utilisateurs : </p>
<ul>
<li>Tutorial terminé (oui/non) </li>

<li>Conditions d’utilisation acceptées (oui/non) </li>

<li>Auto-évaluation effectuée (oui/non) </li>

<li>Test d’entraînement effectué (oui/non) </li>

<li>Première connexion réussie (oui/non) </li>

<li>Un CPS était-il connecté (oui/non) </li>
</ul>
 

<p>Données recueillies durant l’entraînement : </p>
<ul>
<li>Date, heure et durée de l’entraînement </li>

<li>Résultat de l’entraînement </li>

<li>Type d’entraînement ou paramètres du mode Training </li>

<li>En cas d’entraînement au sein d’un institut, des informations complémentaires sur le cours, le trainer (formateur) et l’institut </li>
</ul>
 
 

<h3>Journaux du serveur </h3>

<p>Les données suivantes sont conservées dans les journaux du serveur Web : </p>
<ul>
<li>Adresse IP de la partie accédante </li>

<li>Version du navigateur de la partie accédante </li>

<li>Date/heure d’accès </li>

<li>URL d’accès </li>
</ul>
<h3>Services externes qui traitent des données : </h3>
<ul>
<li>Google/Firebase pour l’analyse à distance des connexions, des plantages et des erreurs </li>

<li>Google/Firebase pour l’envoi de notifications </li>

<li>Sendgrid pour l’envoi d’e-mails </li>

<li>Hetzner Online GmbH pour l’hébergement du backend Web et de la base de données </li>
</ul>
 

<h3>Que se passe-t-il lorsqu’un utilisateur est supprimé ? </h3>
<ul>
<li>L’utilisateur se supprime de notre système sur la page d’accueil des services CorPatch<sup>®</sup> https://app.corpatch.com 

<li>L’utilisateur est marqué comme supprimé. Dès lors, il ne peut plus se connecter, n’est plus visible pour les administrateurs, etc. mais l’utilisateur existe toujours dans la base de données. 

<li>Après 14 jours, les données de l’utilisateur sont automatiquement supprimées de la base de données. 

<li>Aux fins d’évaluation scientifique et d’amélioration des fonctionnalités, les données d’entraînement et d’utilisation de CorPatch<sup>®</sup> existeront toujours dans la base de données après la suppression de l’utilisateur, mais la référence (l’ID) à l’utilisateur sera vide, et toutes les références aux données personnelles seront supprimées.  
</ul>

</div>

<!--This is the 19th section (white) - tile: Comment nous collectons vos informations personnelles-->
<div class="white">

<h2>Comment nous recueillons vos informations personnelles </h2>

<p>La plupart des informations personnelles que nous traitons nous sont fournies directement par vos soins. Nous collectons et traitons des données lorsque : </p>
<ul>
<li>Vous vous inscrivez en ligne ou passez une commande pour l’un de nos produits ou services ; par exemple, données démographiques, adresse e-mail, informations de paiement, articles, montant de la commande, niveau et fréquence de ristourne. Ceci comprend l’envoi d’e-mails transactionnels, par exemple confirmation de commande, confirmation d’expédition et confirmation de remboursement. </li>

<li>Vous réagissez aux communications envoyées (e-mail, SMS, publipostage ou téléphone) ; par exemple le taux d’ouverture, le taux de clics et le temps passé à lire les e-mails, le domaine de l’expéditeur et le type de client de messagerie. </li>

<li>Vous répondez volontairement à un sondage auprès des clients ou fournissez des commentaires sur l’un de nos forums de discussion ou par e-mail. </li>
</ul>
 

<p>Nous pouvons également recevoir des informations personnelles indirectement, à partir des sources suivantes dans les scénarios suivants : </p>
<ul>
<li>À partir des cookies : lorsque vous visitez nos sites Web ou nos applications ; par exemple l’adresse IP, le pays, les pages consultées, les produits consultés, les interactions/clics et les recherches. </li>

<li>De vous-même ou de toute autre personne affiliée à notre client. Ces personnes peuvent être un supérieur hiérarchique ou un collègue. Si le client pour lequel vous travaillez achète des produits ou des services auprès de SmartResQ/CorPatch<sup>®</sup> par l’intermédiaire d’une société partenaire de SmartResQ/CorPatch<sup>®</sup>, nous pouvons recueillir des informations vous concernant auprès de la société partenaire. </li>

<li>Partenaires marketing de SmartResQ/CorPatch<sup>®</sup>, sources publiques ou réseaux sociaux de tiers. </li>

<li>SmartResQ/CorPatch<sup>®</sup> pourra combiner des données personnelles vous concernant recueillies auprès d’une source avec des informations obtenues d’une autre source. Cela nous donne une image plus complète de vous, ce qui nous permet également de vous servir de manière plus pertinente avec un niveau de personnalisation plus élevé. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Comment nous utilisons vos données-->
<div class="beige">
<h2>Comment nous utilisons vos données </h2>

<p>Afin de gérer de manière générale ses relations avec ses clients et de respecter ses engagements envers ses clients, SmartResQ/CorPatch<sup>®</sup> a besoin d’informations les concernant dans leur rôle de client ou lorsqu’ils utilisent un service. Les finalités du traitement de ces données personnelles sont les suivantes : </p>

<p>Traiter votre commande, gérer votre compte </p>
<ul>
<li>Vous envoyer un e-mail avec des offres spéciales sur d’autres produits et services que nous pensons susceptibles de vous plaire </li>

<li>Réaliser le processus de vente et de contrat pour les clients </li>

<li>Fournir les produits et services demandés aux clients </li>

<li>Effectuer des livraisons conformément aux accords conclus avec vous ou vos clients </li>

<li>Offrir un support technique aux utilisateurs de nos produits et services </li>

<li>Améliorer et développer la qualité, la fonctionnalité et l’expérience utilisateur de nos produits, services et sites Web et applications SmartResQ/CorPatch<sup>®</sup> </li>

<li>Détecter, limiter et prévenir les menaces de sécurité et effectuer l’entretien, le dépannage et le débogage </li>

<li>Empêcher l’utilisation abusive de nos produits et services </li>

<li>Traiter les commandes, la facturation, les paiements ou tout autre suivi financier </li>

<li>Créer des profils d’intérêt pour promouvoir des produits et services pertinents </li>

<li>Établir des communautés d’utilisateurs pour éduquer et faciliter l’interaction entre les utilisateurs et SmartResQ/CorPatch<sup>®</sup>. </li>
</ul>
 

<h3>À propos des prospects </h3>

<p>SmartResQ/CorPatch<sup>®</sup> traite les données personnelles des prospects à des fins de marketing. Afin de fournir un contenu ciblé et pertinent aux clients potentiels, SmartResQ/CorPatch<sup>®</sup> construit un profil d’intérêt basé sur votre activité et vos choix et actions sur les pages SmartResQ/CorPatch<sup>®</sup>, ainsi que votre réponse au contenu marketing. La base juridique d’un tel traitement est principalement votre consentement. </p>

<h3>À propos des demandeurs d’emploi </h3>

<p>Si vous avez posé votre candidature à un emploi, nous traitons vos données personnelles pour évaluer votre potentiel professionnel chez SmartResQ/CorPatch<sup>®</sup>. Notre plateforme de carrière en ligne sécurisée garantit que nous nous conformons aux dernières lois et réglementations en matière de confidentialité des données. La base juridique d’un tel traitement est votre consentement. </p>

<h3>À propos des visiteurs du site Web </h3>

<p>Afin de contrôler l’accès à nos sites, nous traitons des informations personnelles sur les visiteurs. Le traitement est basé sur notre intérêt légitime à protéger nos secrets commerciaux, nos employés, nos sites et vous-même lorsque vous visitez notre site. Nous vous informerons de vos droits dans ce contexte lors de votre inscription à notre système électronique pour les visiteurs. </p>

<p>Pour améliorer la qualité de la RCR, notamment moyennant un entraînement à la RCR, SmartResQ/CorPatch<sup>®</sup> peut partager vos données avec nos sociétés partenaires (Training Institutes) afin qu’elles puissent vous proposer leurs produits et services. </p>

<p>Lorsque nous traitons votre commande, notre système peut envoyer vos données à des agences de référence de crédit et également utiliser les informations qui en résultent pour empêcher les achats frauduleux. </p>

</div>

<!-- This is 21st section (white) - title: Comment nous conservons vos informations personnelles -->
<div class="white">
<h2>Comment nous conservons vos informations personnelles </h2>

<p>SmartResQ/CorPatch<sup>®</sup> prend très au sérieux la confiance que vous et nos clients nous accordez. SmartResQ/CorPatch<sup>®</sup> s’engage à éviter l’accès non autorisé, la divulgation ou tout autre traitement aberrant des données personnelles. SmartResQ/CorPatch<sup>®</sup> doit garantir la confidentialité des données personnelles traitées par la société, maintenir l’intégrité des données personnelles et assurer leur accessibilité conformément aux lois en vigueur en matière de confidentialité. </p>

<p>Dans le cadre de nos engagements, nous appliquons des procédures et des mesures organisationnelles, techniques et physiques raisonnables et adéquates pour protéger les informations que nous recueillons et traitons. Nous prenons en compte le type de données personnelles et le risque auquel nos clients sont exposés par toute faille de sécurité, car il y a une forte probabilité que les causes profondes des violations de données personnelles puissent être trouvées en interne, nous pensons que la construction d’une forte culture d’entreprise dans laquelle le respect et la vigilance de nos collaborateurs sur la protection des données est fondamentale pour assurer le traitement légal et la protection de vos informations. En cas de violation de données, SmartResQ/CorPatch<sup>®</sup> suivra les pratiques établies par le Datatilsynet danois. </p>

<p>Vos informations sont conservées en toute sécurité conformément aux dispositions du RGPD. </p>

</div>

<!--This is the 22nd section (beige) - title: Combien de temps conservons-nous vos données personnelles ?-->
<div class="beige">
<h2>Combien de temps conservons-nous vos données personnelles ? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> ne conserve vos données personnelles que le temps nécessaire aux fins énoncées, tout en tenant compte de son besoin de répondre aux questions et de résoudre les problèmes, et de se conformer aux exigences légales en vertu de la loi en vigueur. </p>

<p>Cela signifie que SmartResQ/CorPatch<sup>®</sup> peut conserver vos informations personnelles pendant une période raisonnable après votre dernière interaction et la dernière interaction de client avec nous. Lorsque les données personnelles que nous avons collectées ne sont plus nécessaires, nous les supprimons. Nous pouvons traiter des données à des fins statistiques et/ou scientifiques, mais dans de tels cas, les données seront pseudonymisées ou anonymisées. </p>

 
 

<h3>Délai de conservation des données </h3>

<p>Nous conserverons vos informations personnelles pendant la période nécessaire pour remplir les objectifs décrits dans la présente Politique de confidentialité, sauf si une période de conservation plus longue est requise ou autorisée par la loi, pour des raisons légales, fiscales ou réglementaires, ou à d’autres fins commerciales légitimes et légales. </p>

<table>
<tr>
<td><b>Objectif </b></td>

<td><b>Délai </b></td>
</tr>

<tr>
<td>Service client et réglementation comptable </td>

<td>5 ans ou aussi longtemps que nécessaire pour répondre aux exigences légales.
<br>
 
Nous supprimons vos données d’inscription dès que vous supprimez votre compte utilisateur chez nous ou à l’expiration de la période de conservation légale. 
</td>
</tr>
<tr>
<td>Plateforme des carrières </td>

<td>6 mois pour les dossiers de candidature qui n’aboutissent pas à l’embauche du demandeur d’emploi. 
<br> 

Renouvellement tous les 6 mois si vous souhaitez que votre dossier soit pris en compte pour de futures offres d’emploi et que vous consentez à une période de conservation plus longue. 
</td>
</tr>
<tr>
<td>Utilisation marketing </td>

<td>3 ans après votre dernière activité, par exemple visite sur nos sites Web, achat ou communication avec nos services. 
<br>
 

Si vous avez donné votre autorisation pour le marketing (e-mail, SMS, téléphone, etc.) et tant que nous avons votre autorisation pour vous contacter. 
<br>
 

Nous supprimons automatiquement votre adresse e-mail dans la newsletter, une fois que vous supprimez votre compte utilisateur ou si vous vous désabonnez de notre newsletter.  
</td>
</tr>
<tr>
<td>Conservation de l’historique des commandes et des obligations d’exécution des commandes </td>

<td>5 ans ou aussi longtemps que nécessaire pour répondre aux exigences légales.  </td>
</tr>
<tr>
<td>Expérience client </td>

<td>3 ans après votre dernière activité, par exemple visite sur nos sites Web, achat ou communication avec nos services. 
<br>
 

Si vous avez donné votre autorisation pour le marketing (e-mail, SMS, téléphone, etc.) et tant que nous avons votre autorisation. 
<td>
</tr>
<tr>
<td>Fraude et évaluation des risques </td>

<td>5 ans ou aussi longtemps que nécessaire pour répondre aux exigences légales. </td>
</tr>
</table>

</div>

<!--This is the 23rd section (white) - title: Communications Marketing-->
<div class="white">

<h2>Communications Marketing </h2>

<p>Vous avez le droit de refuser de recevoir des communications marketing de SmartResQ/CorPatch<sup>®</sup> et vous pouvez le faire en : </p>
<ul>
<li>suivant les instructions de désactivation dans les communications marketing pertinentes ; </li>

<li>modifiant les préférences dans la section d’édition appropriée pour le compte, si vous avez un compte chez SmartResQ/CorPatch<sup>®</sup> ; </li>

<li>nous contactant par e-mail à <a href="mailto:info@corpatch.com">info@corpatch.com</a></li>
</ul>
 

<p>Veuillez noter que même si vous refusez de recevoir des communications marketing, vous pouvez toujours recevoir des messages administratifs de SmartResQ/CorPatch<sup>®</sup>, tels que des confirmations de commande et des avis nécessaires pour gérer votre compte ou les services fournis à nos clients via, par exemple, des services mobiles ou des applications. </p>

</div>

<!--This is the 24th section (beige) - title: Vos droits en matière de protection des données-->
<div class="beige">
<h2>Vos droits en matière de protection des données </h2>

<p>Il est important pour SmartResQ/CorPatch<sup>®</sup> que vous soyez totalement au fait de tous vos droits en matière de protection des données. </p>

<p>Certaines lois sur la protection des données, y compris le règlement général sur la protection des données (RGPD) de l’Union européenne, la législation correspondante en Allemagne Bundesdatenschutzgesetz (BDSG), en Suisse et au Royaume-Uni, et certaines lois des États américains, vous confèrent certains droits en relation avec les données personnelles que vous nous avez communiquées. Si vous résidez dans l’Espace économique européen, vous pouvez exercer les droits suivants : </p>
<ol>
<li><b>Votre droit d’accès</b> – Vous avez le droit de nous demander des copies de vos informations personnelles. 

<li><b>Votre droit de rectification</b> – Vous avez le droit de nous demander de corriger toute information personnelle que vous jugeriez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous jugez incomplètes.  

<li><b>Votre droit à l’effacement</b> – Vous avez le droit de nous demander d’effacer vos informations personnelles sous certaines conditions.  

<li><b>Votre droit à un traitement restreint</b> – Vous avez le droit de nous demander de restreindre le traitement de vos informations personnelles sous certaines conditions. 

<li><b>Votre droit de vous opposer au traitement</b> – Vous avez le droit de vous opposer au traitement de vos informations personnelles sous certaines conditions. 

<li><b>Votre droit à la portabilité des données</b> – Vous avez le droit de demander que nous transférions les informations personnelles que nous avons recueillies à une autre organisation, ou directement à vous-même sous certaines conditions. 
</ol>
 

<p>Si vous souhaitez exercer l’un de ces droits, veuillez nous contacter à notre adresse e-mail : <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

</div>

<!--This is the 25th section (white) - title: Que sont les cookies ?-->
<div class="white">
<h2>Que sont les cookies ? </h2>

<p>Les cookies sont de petits fichiers texte qui contiennent une chaîne de caractères et créent un identifiant unique pour chaque utilisateur. Ils sont renvoyés au site Web et/ou à des tiers. La plupart des navigateurs sont initialement configurés pour accepter les cookies, car la plupart des sites Web sont tenus d’y accéder. Cependant, vous pouvez modifier les paramètres de votre navigateur afin que votre navigateur puisse généralement rejeter les cookies, bloquer les cookies tiers ou spécifier quand un cookie est envoyé.</p>
 
 

<p>SmartResQ/CorPatch<sup>®</sup> s’engage à garantir votre droit d’ajuster vos intérêts et de gérer la portée du marketing numérique de notre part par le biais d’un système de gestion préférentielle. </p>

</div>

<!--This is the 26th section (beige) - title: Comment nous utilisons les cookies-->
<div class="beige">
<h2>Comment nous utilisons les cookies </h2>

<p>SmartResQ/CorPatch<sup>®</sup> utilise les cookies de différentes manières pour améliorer votre expérience sur ses sites Web, applications et services pour différentes raisons, par exemple : </p>
<ul>
<li>Fonctionnalité – nous utilisons ces cookies pour vous reconnaître sur notre site Web et mémoriser vos préférences précédemment sélectionnées. Celles-ci incluent la langue que vous préférez et l’endroit où vous vous trouvez. Un mélange de cookies propriétaires et tiers est utilisé. </li>

<li>Publicité – nous utilisons ces cookies pour collecter des informations sur votre visite sur nos sites Web, nos applications, le contenu que vous avez consulté, les liens que vous avez suivis et des informations sur votre navigateur, votre appareil et votre adresse IP. Parfois, nous partageons certains aspects limités de ces données avec des tiers à des fins publicitaires. Nous pouvons également partager des données en ligne collectées via des cookies avec nos partenaires publicitaires. Cela signifie que lorsque vous visitez un autre site Web, des publicités peuvent vous être présentées en fonction de vos habitudes de navigation sur notre site Web. </li>
</ul>
</div>

<!--This is the 27th section (white) - title: Le type de cookies que nous utilisons-->
<div class="white">
<h2>Le type de cookies que nous utilisons </h2>

<p>Nos sites Web utilisent les types de cookies suivants : </p>
<ul>
<li><b>Google Analytics :</b> Ce cookie nous permet d’afficher des informations sur les activités du site Web pour les utilisateurs, y compris, mais sans s’y limiter, les pages vues, la source et le temps passé sur un site Web. Les informations sont anonymisées et affichées sous forme numérique, ce qui signifie qu’elles ne peuvent pas être attribuées à des individus particuliers. Cela aide à protéger votre vie privée. L’utilisation de Google Analytics nous permet de voir quel contenu est populaire sur nos pages, et de vous proposer autant que possible ce que vous aimez lire et voir. 

<li><b>Remarketing Google Analytics :</b> Place des cookies sur votre ordinateur, ce qui signifie que lorsque vous quittez notre site, Google peut vous montrer des publicités sur SmartResQ/CorPatch<sup>®</sup> susceptibles de vous intéresser en fonction de votre comportement antérieur sur notre site Web. Ces informations ne sont pas personnellement identifiables. 

<li><b>Google Ads :</b> L’utilisation de Google Ads nous permet de voir quelles pages ont été utiles pour mener à des soumissions via notre formulaire de contact. Ces informations ne sont pas personnellement identifiables, mais les données fournies dans le formulaire de contact le sont. 

<li><b>Remarketing Google Ads :</b> Place des cookies sur votre ordinateur, ce qui signifie que lorsque vous quittez notre site, Google peut vous montrer des publicités sur SmartResQ/CorPatch<sup>®</sup> susceptibles de vous intéresser en fonction de votre comportement antérieur sur notre site Web. Ces informations ne sont pas personnellement identifiables. 

<li><b>Remarketing Facebook :</b> Le pixel de suivi de Facebook place des cookies sur votre ordinateur qui indiquent à Facebook que vous avez consulté le site. Nous supposons alors que vous avez un intérêt pour SmartResQ/CorPatch<sup>®</sup> et le contenu de ce site. Lors de vos visites sur Facebook, vous recevrez des informations ou publicités au contenu similaire. Veuillez utiliser vos paramètres de confidentialité sur Facebook pour limiter l’exposition à ce type de marketing. 

<li><b>YouTube :</b> Nous intégrons des vidéos de la plateforme YouTube fournie par Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Politique de confidentialité : <a href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</li>
</ul>
 
</div>

<!--This is the 28th section (beige) - title: Comment gérer les cookies-->
<div class="beige">
<h2>Comment gérer les cookies </h2>

<p>Vous pouvez configurer votre navigateur pour qu’il refuse ou supprime les cookies. Cependant, certaines des fonctionnalités de notre site Web risquent de ne pas fonctionner en conséquence. Découvrez comment éviter les cookies dans des navigateurs spécifiques : </p>
<ul>
<li>Le site américain : <a href="https://www.aboutads.info/choices/"> https://www.aboutads.info/choices/</a></li>

<li>Le site européen : <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>
</ul>
<p>SmartResQ/CorPatch<sup>®</sup> utilise certains services tiers de confiance sur ses pages. Ces services peuvent utiliser des cookies. Vous pouvez choisir de refuser les cookies tiers dans votre navigateur en suivant ce <a href="https://www.youronlinechoices.com/">lien</a>.</p>

<p>Vous pouvez empêcher que les informations générées par le cookie Google concernant votre utilisation de nos sites soient collectées et traitées par Google en téléchargeant et en installant le module complémentaire de navigateur pour la désactivation de Google Analytics pour votre navigateur Web actuel. Ce module complémentaire est disponible <a href="https://tools.google.com/dlpage/gaoptout">ici</a>.</p>

</div>

<!--This is the 29th section (white) - title: Politiques de confidentialité des autres sites Web-->
<div class="white">
<h2>Politiques de confidentialité des autres sites Web </h2>

<p>Le site Web de SmartResQ/CorPatch<sup>®</sup> contient des liens vers d’autres sites Web. Notre politique de confidentialité s’applique uniquement à notre site Web, donc si vous cliquez sur un lien vers un autre site Web, vous devriez lire sa politique de confidentialité. </p>

</div>

<!--This is the 30th section (beige) - title: Modifications de notre politique de confidentialité-->
<div class="beige">
<h2>Modifications de notre politique de confidentialité </h2>

<p>Si nous modifions notre déclaration de confidentialité, nous publierons ici la déclaration révisée avec une date de révision mise à jour. Nous vous encourageons à consulter régulièrement la déclaration. Si nous apportons des modifications importantes à notre déclaration qui modifient considérablement nos pratiques de confidentialité, nous pouvons également vous en informer par d’autres moyens, par exemple, en envoyant un e-mail ou en publiant un avis sur notre site Web et/ou sur les réseaux sociaux avant que les modifications ne prennent effet. </p>

</div>

<!--This is the 31st section (white) - title: Comment contacter SmartResQ ApS-->
<div class="white">
<h2>Comment contacter SmartResQ ApS </h2>

<p>Si vous avez des questions sur la politique de confidentialité de SmartResQ/CorPatch<sup>®</sup>, les données que nous détenons sur vous, ou si vous souhaitez exercer l’un de vos droits en matière de protection des données, n’hésitez pas à nous contacter. </p>

<p>E-mail : <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Site Web : <a href="https://corpatch.com/">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Comment contacter l’autorité compétente-->
<div class="beige"> 

<h2>Comment contacter l’autorité compétente </h2>

<p>Si vous souhaitez déposer une plainte ou si vous estimez que SmartResQ/CorPatch<sup>®</sup> n’a pas répondu à votre problème de manière satisfaisante, vous pouvez contacter le Bureau du Commissaire à l’information (BCI). </p>

<p>L’adresse du BCI :
<br><br>
Information Commissioner’s Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>


<p>Numéro d’assistance : +44 303 123 1113 </p>

<p>Site Web du BCI : <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – Tous droits réservés 
<br>
Danemark, Version 1.3 – Publication 25.04.2023 </b></p>

</div>
</div>
</body>
</html>`

export const termsOfUse_it_IT: string = `<!DOCTYPE html>
<html lang="it">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }
        
        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }
        
        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>
<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> 

<!--This is the 1st section - title: Termini e condizioni -->
<div class="top-section">
        <h1><a href="#terms">Termini e condizioni</a> & <a href="#policy">Informativa sulla privacy</a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
               
</div>
   
<!--This is the 2nd section (white) - title: Scopo del documento and Come contattare SmartResQ -->
<div class="white">

<h1 id="terms">Termini e condizioni – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Scopo del documento </h2>

<p>Benvenuti in SmartResQ/CorPatch<sup>®</sup>! </p>

 

<p>State leggendo i nostri Termini e condizioni; un documento contenente informazioni sul vostro rapporto con SmartResQ ApS, iniziato quando siete entrati nel nostro sito web, avete scaricato le nostre applicazioni gratuite, avete acquistato uno dei nostri prodotti o altro. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> e i suoi associati forniscono i propri prodotti e servizi all'utente alle seguenti condizioni. Se visitate o fate acquisti sul nostro sito web o sulla nostra app, accettate queste condizioni. Vi consigliamo vivamente di leggerle attentamente prima di procedere all'utilizzo dei nostri prodotti o servizi. </p>

 

<p>Questo documento verte su: </p>
<ul>
<li>Come contattare SmartResQ </li>

<li>Prodotti, proprietà e uso corretto </li>

<li>Esclusioni di responsabilità e garanzie </li>

<li>Ordini </li>

<li>Pagamenti </li>

<li>Consegna </li>

<li>Responsabilità e diritti relativi alla vendita </li>

<li>Resi </li>

<li>Trattamento dei dati personali </li>

<li>Reclami </li>

<li>Modifiche al presente documento </li>

<li>Leggi e giurisdizione </li>

<li>Informazioni di contatto </li>
</ul>
 

<p>In caso di domande relative ai "Termini e condizioni" di SmartResQ, si prega di contattarci. </p>


<h2>Come contattare SmartResQ </h2>

<p>L'obiettivo di SmartResQ è quello di far aumentare la sopravvivenza attraverso una RCP di qualità in caso di arresto cardiaco. Vogliamo preparare e guidare gli astanti a intervenire fin dai primi minuti cruciali, dopo aver assistito a un arresto cardiaco. Pertanto, abbiamo sviluppato CorPatch<sup>®</sup> – Vi aiutiamo a salvare una vita. </p>

 

<p>La nostra azienda si trova a Lundevej 26, 5700 Svendborg, Danimarca </p>

 

<p>Ci trovate nel Registro Centrale delle Imprese con il numero di partita IVA DK 38674102 </p>

 

<p>Potete chiamarci al numero di telefono +45 62 200 100 </p>

 

<p>oppure </p>

 

<p>contattateci all'indirizzo e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<h3>Domande, commenti o reclami? </h3>

<p>Apprezziamo la vostra opinione, quindi vi invitiamo a contattarci attraverso le informazioni di contatto di cui sopra o utilizzando il modulo di contatto sul nostro sito web o sulla nostra app. </p>

</div>

<!--This is the 3rd section (beige) - title: Prodotti, proprietà e uso corretto-->
<div class="beige">
<h2>Prodotti, proprietà e uso corretto </h2>

<h3>I nostri siti web, app e prodotti </h3>

<p>I paragrafi seguenti descrivono le proprietà, i diritti e le responsabilità essenziali relative all'uso dei servizi, delle app e dei prodotti SmartResQ/CorPatch<sup>®</sup>. 
<br>
Ci sforziamo di includere informazioni accurate e aggiornate nei siti web e nelle applicazioni. Tuttavia, SmartResQ/CorPatch<sup>®</sup> non fornisce alcuna garanzia o dichiarazione in merito all'accuratezza delle informazioni. Non ci assumiamo alcuna responsabilità per eventuali errori o omissioni nei contenuti del sito web, nei prodotti e nelle applicazioni. Le informazioni fornite in questo sito web o nelle app non sostituiscono un'adeguata assistenza medica. Ci assumiamo la responsabilità dei nostri prodotti e delle nostre applicazioni in base alla direttiva sui dispositivi medici UE 745/2017 (MDR). </p>

 

<h3>Copyright </h3>

<p>Tutti i contenuti inclusi in questo sito, come testo, grafica, loghi, icone di pulsanti, immagini, clip audio, download digitali, compilazioni di dati e software, sono di proprietà di SmartResQ/CorPatch<sup>®</sup> o dei suoi fornitori di contenuti e sono protetti dalle leggi internazionali sul copyright. La stesura di tutti i contenuti di questo sito è di esclusiva proprietà di SmartResQ/CorPatch<sup>®</sup>, il cui copyright è di SmartResQ/CorPatch<sup>®</sup> ed è protetto dalle leggi internazionali sul copyright. </p>

 

<h3>Marchi registrati e design </h3>

p>I marchi SmartResQ/CorPatch<sup>®</sup>, la veste commerciale e il design del prodotto non possono essere utilizzati in relazione ad alcun prodotto o servizio che non sia SmartResQ/CorPatch<sup>®</sup>, in alcun modo che possa causare confusione tra i clienti o in qualsiasi modo che denigri o screditi SmartResQ/CorPatch<sup>®</sup>. Tutti gli altri marchi non di proprietà di SmartResQ/CorPatch<sup>®</sup> o delle sue consociate che appaiono su questo sito sono di proprietà dei rispettivi titolari, che possono o meno essere affiliati, collegati o sponsorizzati da SmartResQ/CorPatch<sup>®</sup> o dalle sue consociate. </ü>

 

<h3>Licenza e accesso al sito </h3>

<p>SmartResQ/CorPatch<sup>®</sup> concede all'utente una licenza limitata per l'accesso e l'uso personale dei siti e delle applicazioni di SmartResQ/CorPatch<sup>®</sup> e non consente di scaricare (ad eccezione della memorizzazione nella cache della pagina) o modificare il sito o parte di esso, salvo espresso consenso scritto di SmartResQ/CorPatch<sup>®</sup>. Questa licenza non include alcuna rivendita o uso commerciale di questi siti o applicazioni o dei loro contenuti: (a) la raccolta e l'uso di elenchi, descrizioni o prezzi dei prodotti; (b) qualsiasi uso derivato di questo sito o dei suoi contenuti: (c) qualsiasi download o copia di informazioni sull'account a beneficio di un altro commerciante; o (d) qualsiasi uso di data mining, robot o strumenti simili di raccolta ed estrazione di dati. </p>

 

<p>Questo sito o app o qualsiasi parte di questo sito non possono essere riprodotti, re-ingegnerizzati, duplicati, copiati, venduti, rivenduti, visitati o sfruttati in altro modo per qualsiasi scopo commerciale senza l'esplicito consenso scritto di SmartResQ/CorPatch<sup>®</sup>. Non è consentito incorniciare o utilizzare tecniche di framing per racchiudere marchi, loghi o altre informazioni di proprietà (compresi immagini, testi, layout di pagina o moduli) di SmartResQ/CorPatch<sup>®</sup> e dei nostri associati senza un esplicito consenso scritto. </p>

 

<p>Non è consentito utilizzare meta tag o qualsiasi altro "testo nascosto" che utilizzi il nome o i marchi SmartResQ/CorPatch<sup>®</sup> senza l'esplicito consenso scritto di SmartResQ/CorPatch<sup>®</sup>. Qualsiasi uso non autorizzato pone fine all'autorizzazione o alla licenza concessa da SmartResQ/CorPatch<sup>®</sup>.</p> 

 

<p>All'utente viene concesso il diritto limitato, revocabile e non esclusivo di creare un collegamento ipertestuale alla home page di SmartResQ/CorPatch<sup>®</sup>, a condizione che il collegamento non ritragga SmartResQ/CorPatch<sup>®</sup>, i suoi associati o i loro prodotti o servizi in modo falso, fuorviante, spregiativo o altrimenti offensivo. Non è consentito utilizzare alcun logo SmartResQ/CorPatch<sup>®</sup> o altri marchi o grafici di proprietà come parte del link senza un'espressa autorizzazione scritta. 
<br>
In caso di violazione dell'accordo sulla licenza e sull'accesso al sito, verranno intraprese azioni legali e potrà essere intentata una causa in tribunale. </p>

<h3>Comunicazioni elettroniche </h3>

<p>Quando visitate la homepage e i canali di social media di SmartResQ/CorPatch<sup>®</sup> o ci inviate e-mail, comunicate con noi per via elettronica. L'utente acconsente a ricevere le nostre comunicazioni per via elettronica. Comunicheremo con voi tramite e-mail o pubblicando avvisi sui nostri siti web o nelle app sotto forma di pop-up o notifiche. L'utente accetta che tutti gli accordi, gli avvisi, le divulgazioni e le altre comunicazioni che gli forniamo elettronicamente soddisfino qualsiasi requisito legale che preveda la forma scritta di tali comunicazioni. Si prega di consultare la nostra Informativa sulla privacy in un documento separato che può essere trovato sulla nostra homepage. </p>

 

<p>I visitatori del nostro sito web e dei nostri canali di social media possono essere invitati a pubblicare recensioni, commenti e altri contenuti, ad esempio, inviare suggerimenti, idee, commenti, domande o altre informazioni, purché il contenuto non sia illegale, osceno, minaccioso, diffamatorio, lesivo della privacy, lesivo dei diritti di proprietà intellettuale o altrimenti dannoso per terzi o discutibile e non consista di o contenga virus software, campagne politiche, richieste commerciali, catene di Sant'Antonio, mailing di massa o qualsiasi forma di "spam". </p>

 

<p>Non è consentito utilizzare un indirizzo e-mail falso, impersonare una persona o un'entità o ingannare in altro modo sull'origine di una carta o di altri contenuti. SmartResQ/CorPatch<sup>®</sup> si riserva il diritto (ma non l'obbligo) di rimuovere o modificare i suddetti contenuti, ma non controlla regolarmente i contenuti pubblicati. Se l'utente pubblica contenuti o invia materiale e, a meno che non sia indicato diversamente, concede a SmartResQ/CorPatch<sup>®</sup> e ai suoi associati il diritto non esclusivo, esente da royalty, perpetuo, irrevocabile e completamente trasmissibile di utilizzare, riprodurre, modificare, adattare, pubblicare, tradurre, creare opere derivate, distribuire e visualizzare tali contenuti in tutto il mondo su qualsiasi supporto. L'utente concede a SmartResQ/CorPatch<sup>®</sup> e ai suoi associati e sublicenziatari il diritto di utilizzare il nome da lui inviato in relazione ai suddetti contenuti, se lo desiderano. </p>

 

<p>L'utente dichiara e garantisce di possedere o di controllare in altro modo tutti i diritti sul contenuto che pubblica; che il contenuto è accurato; che l'uso del contenuto fornito non viola la presente informativa e non causerà danni a persone o entità; e che indennizzerà SmartResQ/CorPatch<sup>®</sup> o i suoi associati per tutti i reclami derivanti dal contenuto fornito. SmartResQ/CorPatch<sup>®</sup> ha il diritto, ma non l'obbligo, di monitorare e modificare o rimuovere qualsiasi attività o contenuto. SmartResQ/CorPatch<sup>®</sup> non si assume alcuna responsabilità per i contenuti pubblicati dall'utente o da terzi. </p>

</div>

<!--This is the 4th section (white) - title: Esclusioni di responsabilità e garanzie-->
<div class="white"> 
<h2>Esclusioni di responsabilità e garanzie </h2>

<h3>Informazioni sul prodotto – CorPatch<sup>®</sup> e Trainer CorPatch<sup>®</sup> </h3>

<p>Per un uso corretto di qualsiasi prodotto o servizio SmartResQ/CorPatch<sup>®</sup> è necessario seguire sempre le guide, le istruzioni e le descrizioni più aggiornate. Sono disponibili sul nostro sito web e sotto la descrizione di ogni prodotto. </p>

 

<p>Il prodotto CorPatch<sup>®</sup> è certificato come dispositivo medico. In caso di emergenza per un adulto in arresto cardiaco, il prodotto deve essere estratto dal suo alloggiamento, posizionato e fissato correttamente sul torace della vittima. Il prodotto è in grado di raccogliere dati su compressione, profondità, frequenza e ritorno indietro e può trasmetterli via Bluetooth<sup>®</sup> a potenziali dispositivi. Le applicazioni gratuite sui dispositivi possono guidare l'astante nell'esercizio della RCP e mostrare la frazione di flusso, la profondità, il ritorno indietro e la frequenza se attivate e correttamente installate, cioè Bluetooth<sup>®</sup>, smartphone, app, batteria, ecc. SmartResQ/CorPatch<sup>®</sup> non è responsabile di fattori esterni, ad esempio segnali di comunicazione interferenti, mancanza di copertura dei dati, mancanza di batteria, impostazioni hardware o software errate, che possono influire sull'esperienza dell'utente o simili. </p>

 

<p>Allo stesso modo, SmartResQ/CorPatch<sup>®</sup> non è responsabile di eventuali danni fisici causati dall'uso del prodotto nel caso in cui non venga applicato o utilizzato secondo le istruzioni, ad esempio eseguendo le compressioni toraciche in modo non ergonomico o con una posizione delle mani non adatta. SmartResQ/CorPatch<sup>®</sup> ha valutato i rischi tecnici nell'ambito della gestione del rischio prescritta dalla legge per i dispositivi medici, ma non viene fornita alcuna garanzia per gli errori che vanno oltre tali prescrizioni. In caso di malfunzionamenti imprevisti o di comportamenti non plausibili del sistema SmartResQ/CorPatch<sup>®</sup> , l'utente è tenuto a eseguire manualmente la RCP. In questo caso, SmartResQ/CorPatch<sup>®</sup> non è responsabile in quanto fuori dal controllo di SmartResQ/CorPatch<sup>®</sup>. 
<br>
SmartResQ/CorPatch<sup>®</sup> monitora i livelli della batteria e lo stato di salute, ma se la batteria si scarica, il prodotto non funziona. È responsabilità esclusiva dell'utente assicurarsi che il dispositivo sia aggiornato, non danneggiato e che abbia la batteria sufficiente per funzionare correttamente, cosa che può essere verificata facilmente eseguendo un'esercitazione che ne convalidi la corretta funzionalità. Si consiglia di esercitarsi con il CorPatch<sup>®</sup> per 4 minuti ogni tre mesi. </p>

 

<p><b>Importante!</b> Il prodotto CorPatch<sup>®</sup> deve essere utilizzato esclusivamente su una persona in una situazione reale e solo su persone in arresto cardiaco. Non è progettato per l'uso su persone affette, ad esempio, da ictus, infarto o altre patologie non legate all'arresto cardiaco. Il CorPatch<sup>®</sup> non è destinato all'uso se la persona è sdraiata su una superficie morbida, ad esempio un divano o un letto, poiché il feedback sulla profondità in queste condizioni potrebbe essere imprecisa. Le soluzioni SmartResQ/CorPatch<sup>®</sup> non sono destinate all'uso in un ambiente in movimento, comprese, ma non solo, le ambulanze aeree, marittime o stradali. Se utilizzato durante il trasporto del paziente o se viene sollevato/rimosso dal corpo durante la RCP, il dispositivo potrebbe fornire un feedback impreciso. Il CorPatch<sup>®</sup> deve essere applicato al torace del paziente con il patch. Assicurarsi che il paziente sia sdraiato su una superficie stabile, piana e non in movimento e che il CorPatch<sup>®</sup> sia fissato al torace con il patch. </p>

 

<p>Il prodotto Trainer CorPatch<sup>®</sup> deve essere utilizzato esclusivamente su manichini o oggetti simili durante le Training session e mai in una situazione reale su una persona colpita da arresto cardiaco o da qualsiasi altra malattia. </p>

 

<p>È possibile formare le proprie prestazioni di RCP in relazione alle compressioni toraciche utilizzando regolarmente CorPatch<sup>®</sup>️ o CorPatch<sup>®</sup>️ Trainer. Si consiglia di fare pratica su un manichino, ma se non si ha accesso a un manichino, si può utilizzare in sostituzione una sedia da ufficio con un movimento di ritorno adatto o un divano rigido. Considerare l'elasticità e la durezza dell'oggetto con cui si fa pratica. Durante l’esercitazione, SmartResQ sconsiglia l'uso di oggetti morbidi, tra cui cuscini o divani morbidi, poiché l'esperienza dell'utente non sarà corretta. </p>

<p>Se non è possibile reperire un manichino o un oggetto sostitutivo per le esercitazioni, considerare di fare pratica per le eventuali situazioni che si verificano prima di iniziare le compressioni toraciche. Ad esempio, si possono seguire le informazioni fornite dall'applicazione gratuita sull'identificazione di un arresto cardiaco e sulla chiamata ai servizi di emergenza ed estrarre il CorPatch<sup>®</sup> dal porta chiavi. In questo caso, sarete pronti ad applicare rapidamente il CorPatch<sup>®</sup> in caso di arresto cardiaco. </p>

 

<p>Non utilizzare i sistemi SmartResQ/CorPatch<sup>®</sup> per le esercitazioni o a fini di svago su persone (vive o morte), animali domestici o altri esseri umani o creature viventi. </p>

 

<h3>Esclusione di responsabilità per prodotti, siti web e applicazioni </h3>

<p>SmartResQ/CorPatch<sup>®</sup> non rilascia alcuna dichiarazione o garanzia e non è responsabile della competenza di nessuna persona che possa ricevere informazioni formative e/o formazione medica fornite attraverso o basate sul sistema o dell'esercizio delle proprie competenze da parte di tale persona dopo il completamento di qualsiasi formazione, corso o programma di studio che utilizzi il sistema. SmartResQ/CorPatch<sup>®</sup> non garantisce che chiunque riceva informazioni formative e/o formazione medica attraverso il sistema raggiunga un particolare livello di abilità o la competenza necessaria a qualificarsi per qualsiasi licenza, certificato o valutazione rilasciata da qualsiasi agenzia di regolamentazione o autorità governativa. </p>

<p>SmartResQ/CorPatch<sup>®</sup> e i suoi associati cercano di essere il più accurati possibile. Tuttavia, SmartResQ/CorPatch<sup>®</sup> non dichiara e non garantisce che il sistema e le informazioni, i prodotti e la formazione qui forniti: (a) siano sempre o comunque disponibili; o (b) siano privi di errori, completi, accurati, veritieri, aggiornati e/o non fuorvianti. Quando si utilizza il sistema, si è a conoscenza e si è consapevoli di rinunciare a qualsiasi reclamo nei confronti di SmartResQ/CorPatch<sup>®</sup> per aver fatto affidamento su qualsiasi informazione o formazione presentata attraverso il sistema. </p>

 

<p>I nostri prodotti non sono destinati all'uso da parte dei bambini. I prodotti sono piccoli e colorati e possono essere scambiati per giocattoli, ma i prodotti SmartResQ/CorPatch<sup>®</sup> non sono giocattoli! Si raccomanda di non lasciare i prodotti all'aperto e di tenerli fuori dalla portata dei bambini. SmartResQ/CorPatch<sup>®</sup> non si assume alcuna responsabilità in relazione all'uso dei prodotti da parte dei bambini. L'uso di CorPatch<sup>®</sup> da parte di bambini o adolescenti deve essere consentito e monitorato da adulti responsabili, ad esempio genitori, familiari o insegnanti. </p>

 

<p>Esclusione di garanzie e limitazione di responsabilità: questo sito è fornito da SmartResQ/CorPatch<sup>®</sup> "così com'è" e "come disponibile". SmartResQ/CorPatch<sup>®</sup> non rilascia dichiarazioni o garanzie di alcun tipo, espresse o implicite, in merito al funzionamento di questo sito o alle informazioni, ai contenuti, ai materiali o ai prodotti inclusi in questo sito. L'utente accetta espressamente che l’utilizzo di questo sito è a suo esclusivo rischio. 
<br>
Nella misura massima consentita dalle leggi vigenti, SmartResQ/CorPatch<sup>®</sup> non riconosce alcuna garanzia, espressa o implicita, incluse, a titolo esemplificativo, le garanzie implicite di commerciabilità e idoneità a uno scopo particolare. SmartResQ/CorPatch<sup>®</sup> non garantisce che questo sito, i suoi server o le e-mail inviate da SmartResQ/CorPatch<sup>®</sup> siano privi di virus/spam o altri componenti dannosi. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> non sarà responsabile per eventuali danni di alcun tipo derivanti dall'uso di questo sito o dei suoi prodotti, inclusi, ma non limitati a, danni diretti, indiretti, incidentali, punitivi e consequenziali. 
<br>
Alcune leggi statali non consentono limitazioni alle garanzie implicite o l'esclusione o la limitazione di determinati danni. Se queste leggi sono applicabili all'utente, alcune o tutte le esclusioni, i limiti o le limitazioni di cui sopra potrebbero non essere applicabili e l'utente potrebbe avere ulteriori diritti. </p>

 

<p>Le informazioni presentate in questo sito non devono essere interpretate come consulenza professionale. Prima di prendere qualsiasi decisione, si consiglia sempre di rivolgersi a consulenti professionisti che conoscono la situazione al fine di ottenere consigli su questioni specifiche. </p>

 

<p>Il sito web può contenere link che conducono a siti web gestiti da individui o organizzazioni su cui SmartResQ/CorPatch<sup>®</sup> non ha alcun controllo. SmartResQ/CorPatch<sup>®</sup> non dichiara e non fornisce alcuna garanzia in merito all'accuratezza o a qualsiasi altro aspetto delle informazioni contenute in tali siti web. </p>

 

<p>La responsabilità di qualsiasi opinione, consiglio, dichiarazione o altra informazione contenuta in articoli o testi di questo sito web è esclusivamente dell'autore e non riflette necessariamente le opinioni e le politiche di SmartResQ/CorPatch<sup>®</sup>. </p>

 

<h3>Esclusione di responsabilità legale </h3>

<p>Acquistando, concedendo in licenza, visualizzando, utilizzando e/o accedendo ai nostri siti web, prodotti e applicazioni, l'utente riconosce e accetta che: </p>
<ol>
<li>I sistemi forniti da SmartResQ/CorPatch<sup>®</sup> sono prodotti specifici da utilizzare esclusivamente per l'uso previsto indicato nel manuale del prodotto. Leggere attentamente le istruzioni e i manuali d'uso e assicurarsi di avere familiarità con i nostri prodotti medici prima di utilizzarli.  </li>

<li>I sistemi forniti da SmartResQ/CorPatch<sup>®</sup> sono prodotti e strumenti didattici e di formazione medica specifici non sono certificati come dispositivi medici, a meno che non sia esplicitamente indicato; non sono destinati ad alcun uso clinico o diagnostico e devono essere utilizzati esclusivamente per scopi di formazione medica e miglioramento delle prestazioni. </li>

<li>In qualsiasi momento l’utente utilizzerà e accederà al sistema esclusivamente in relazione a tali scopi di formazione medica e di miglioramento delle prestazioni; in conformità con tutte le leggi e i regolamenti applicabili e in conformità con qualsiasi documentazione per l'utente, manuale di istruzioni, guida e/o requisito che gli forniremo elettronicamente o di persona. </li>

<li>In nessun caso il sistema SmartResQ/CorPatch<sup>®</sup>, da solo, possa diagnosticare, trattare o curare la condizione di un essere umano o in una situazione di salvataggio; supportare decisioni mediche professionali, diagnosi o trattamenti o sostituire qualsiasi diagnosi, raccomandazione, consiglio, trattamento o decisione da parte di un medico adeguatamente formato e autorizzato. </li>

<li>L'associazione di un dispositivo con un danno o un esito per il paziente non significa che il dispositivo abbia causato il danno o l'esito. </li>

<li>SmartResQ/CorPatch<sup>®</sup> non si assume alcuna responsabilità per i danni derivanti da un uso irragionevole dei nostri prodotti o da un uso che va oltre l'uso previsto del prodotto. </li>
</ol>

<h3>Informazioni sulle prestazioni </h3>

<p>Le informazioni contenute nel presente documento sono presentate solo come guida per l'applicazione dei prodotti SmartResQ/CorPatch<sup>®</sup>. SmartResQ/CorPatch<sup>®</sup> lavora continuamente per migliorare la qualità e l'affidabilità dei suoi prodotti. Ciononostante, i nostri dispositivi possono funzionare male o guastarsi a causa della loro intrinseca sensibilità elettrica e vulnerabilità alle sollecitazioni fisiche e ai segnali di comunicazione avversi. È responsabilità dell'acquirente, quando utilizza i prodotti SmartResQ/CorPatch<sup>®</sup>, osservare gli standard di sicurezza e di collaudo ed evitare situazioni in cui un malfunzionamento o un guasto potrebbero causare lesioni fisiche o danni alle cose. </p>

 

<h3>Garanzia standard </h3>

<p>La Garanzia limitata standard SmartResQ/CorPatch<sup>®</sup> è subordinata all'uso corretto dei prodotti, dei siti web e delle applicazioni. 
<br>
La presente Garanzia è limitata al primo acquirente del prodotto e solo se il prodotto viene acquistato da un rivenditore autorizzato SmartResQ/CorPatch<sup>®</sup>. I produttori, i fornitori o gli editori, diversi da SmartResQ/CorPatch<sup>®</sup>, possono fornire le proprie garanzie: per ulteriori informazioni, contattarli. </p>

 

<p><u>La garanzia non copre: </u></p>
<ol>
<li>difetti o danni derivanti da incidenti, uso improprio, uso anomalo, condizioni anomale, stoccaggio improprio, esposizione a liquidi, umidità, sabbia o sporcizia, negligenza o insolite sollecitazioni fisiche, elettriche o elettromeccaniche, </li>

<li>difetti o danni derivanti da una forza eccessiva o dall'uso di oggetti metallici, </li>

<li>apparecchiature con numero di produzione o codice dati di miglioramento rimosso, deturpato, danneggiato, alterato o reso illeggibile, </li>

<li>l'usura ordinaria o il normale invecchiamento del prodotto, </li>

<li>graffi, ammaccature e danni estetici, a meno che il guasto non sia dovuto a un difetto di materiale o di lavorazione, </li>

<li>difetti o danni derivanti dall'uso dei prodotti in combinazione o in connessione con accessori, prodotti o apparecchiature ausiliarie/periferiche non forniti o approvati da SmartResQ/CorPatch<sup>®</sup>, </li>

<li>difetti o danni derivanti da test, funzionamento, manutenzione, installazione, servizio o regolazione impropri non forniti o approvati da SmartResQ/CorPatch<sup>®</sup>, </li>

<li>danni causati dal funzionamento del prodotto SmartResQ/CorPatch<sup>®</sup> al di fuori delle linee guida pubblicate, </li>

<li>dimostrazione/installazione del prodotto acquistato, </li>

<li>difetti o danni derivanti da cause esterne quali collisione con un oggetto, incendio, allagamento, sporcizia, tempesta di vento, fulmine, terremoto, esposizione a condizioni atmosferiche, furto, fusibili bruciati o uso improprio di qualsiasi fonte elettrica, </li>

<li>difetti o danni derivanti da trasmissione o virus, o altri problemi di software introdotti nei Prodotti, </li>

<li>se le batterie vengono ricaricate con caricabatterie diversi da quelli compatibili con Trainer CorPatch<sup>®</sup>, </li>

<li>se i sigilli del vano batteria o delle celle sono rotti o presentano segni di manomissione, </li>

<li>prodotti riparati, utilizzati o acquistati da un'azienda diversa da SmartResQ/CorPatch<sup>®</sup>, </li>

<li>se SmartResQ/CorPatch<sup>®</sup> riceve informazioni dalle autorità pubbliche competenti che il prodotto è stato rubato o se l'utente non è in grado di disattivare il codice di accesso o altre misure di sicurezza volte a prevenire l'accesso non autorizzato al prodotto o se non può dimostrare di essere l'utente autorizzato del prodotto, </li>

<li>se i prodotti vengono utilizzati al di fuori delle condizioni specificate nei manuali, ad esempio, intervallo di temperatura, pressione e umidità. </li>
</ol>
 

<h3>Batterie e caricabatterie </h3>

<p>I prodotti SmartResQ/CorPatch<sup>®</sup> contengono batterie non sostituibili (CorPatch<sup>®</sup>) o batterie ricaricabili (Trainer CorPatch<sup>®</sup>). I tipi di batterie utilizzati nei nostri prodotti sono descritti in ogni singolo prodotto. SmartResQ/CorPatch<sup>®</sup> non si assume alcuna responsabilità se le batterie ricaricabili non vengono maneggiate correttamente secondo le istruzioni per l'uso. </p>


<p>In relazione alla vendita di dispositivi contenenti batterie, siamo tenuti a far notare quanto segue: 
<br>
In qualità di utenti finali, lo smaltimento corretto è un obbligo di legge. Il simbolo del bidone barrato indica che la batteria non può essere gettata insieme ai rifiuti domestici. </p>

</div>           

<!--This is the 5th section (beige) - title: Ordini -->
<div class="beige">
<h2>Ordini </h2>

<p>Il negozio online di Corpatch.com è aperto 24 ore su 24 ed è possibile acquistare in qualsiasi momento. Tuttavia, potremmo chiudere il negozio per manutenzione. L'acquisto di grandi volumi può avvenire direttamente con SmartResQ/CorPatch<sup>®</sup>. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> non offre prodotti in vendita ai minori. I prodotti destinati ai bambini possono essere acquistati solo da adulti. Per acquistare da SmartResQ/CorPatch<sup>®</sup> è necessario avere almeno 18 anni e possedere una carta di credito valida o altri mezzi di pagamento accettati. </p>

 

<p>La presentazione dei prodotti nel negozio online non è un'offerta giuridicamente vincolante, ma un catalogo online non vincolante. Al momento dell'acquisto, selezionare gli articoli che si desidera acquistare e metterli nel "carrello". È possibile modificare il contenuto del carrello fino al momento dell'ordine. Eventuali pagamenti extra, come spese di spedizione o di carta di debito, saranno calcolati immediatamente prima del pagamento. </p>

 

<p>Quando si è pronti per l'ordine, cliccare su "Checkout/Cassa" e inserire le informazioni necessarie. È possibile modificare il contenuto del carrello fino alla conferma dell'acquisto che avviene cliccando il pulsante "Paga". A questo punto l’utente ha effettuato un ordine vincolante per la merce contenuta nel carrello, che non può più essere modificato. 
<br>
SmartResQ/CorPatch<sup>®</sup> può accettare l'ordine inviando una conferma d'ordine via e-mail o consegnando la merce entro il termine di consegna. </p>

 

<p>Alcuni paesi possono impedire l'uso e il possesso dei nostri prodotti. L'utente ha la responsabilità esclusiva di informarsi se questo prodotto sia legale da importare e/o utilizzare nel proprio paese. Invieremo i prodotti ordinati e non ci assumiamo alcuna responsabilità per questioni doganali o per eventuali implicazioni legate al possesso o all'utilizzo di questo dispositivo. </p>

</div>

<!--This is the 6th section (white) - title: Pagamenti-->
<div class="white">
<h2>Pagamenti </h2>

<h3>I nostri siti web, app e negozi online </h3>

<p>I nostri siti web e le nostre app sono liberi di essere utilizzati a condizione che le nostre politiche legali siano accettate e rispettate. Si noti che gli acquisti dei nostri prodotti potrebbero essere disponibili nei negozi online, sui nostri siti e nelle app. </p>

 

<h3>I nostri prodotti </h3>

<p>SmartResQ/CorPatch<sup>®</sup> utilizza QuickPay come gateway di pagamento. QuickPay è certificato dal Consiglio per gli standard di sicurezza dell'industria delle carte di pagamento (PCI – Payment Card Industry) in base all'ultima versione degli standard di sicurezza dei dati (DSS – Data Security Standard) PCI di livello 1, che comprende: (a) un rapporto annuale – relazione di conformità (ROC – Report on Compliance) condotto da un controllore della sicurezza qualificato (QSA – Qualified Security Assessor); (b) scansioni trimestrali della rete eseguite da Approved Scan Vendor (ASV), e (c) un gran numero di regole e linee guida per il flusso di lavoro e l'elaborazione dei dati. </p>

 

<p>Accettiamo pagamenti con: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>MasterCard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>
<p>I pagamenti saranno detratti dal vostro conto al momento della spedizione della merce. Tutti gli importi sono in euro e l'imposta sul valore aggiunto prevista dalla legge è già inclusa in tutti i prezzi indicati. Tutti i titolari di carte di credito/debito sono soggetti a controlli di convalida e autorizzazione da parte dell'emittente della carta o del fornitore di pagamenti. SmartResQ/CorPatch<sup>®</sup> non è responsabile nel caso in cui il fornitore della carta di pagamento si rifiuti di autorizzare i pagamenti. </p>

<p>Utilizziamo la crittografia per i dati della carta di credito attraverso il protocollo Secure Socket Layer (SSL). Questo garantisce che altri non possano intercettare il numero della carta di credito o altre informazioni durante la transazione con il nostro fornitore. </p>

 

<p>Prima di concludere il contratto, il cliente deve controllare e accettare i costi di spedizione e logistica aggiunti al prezzo di acquisto, in quanto tali costi vengono addebitati al cliente. Dopo aver effettuato un ordine, riceverete un'e-mail di conferma della ricezione dell'ordine. Si noti che questo non significa che l'ordine sia stato accettato. L'ordine costituisce un'offerta a SmartResQ/CorPatch<sup>®</sup> per l'acquisto di un prodotto (e SmartResQ/CorPatch<sup>®</sup> si riserva il diritto di rifiutare ordini di prodotti). Non esisterà alcun contratto in relazione ai prodotti fino a quando non avremo confermato via e-mail che il prodotto o i prodotti sono stati spediti. Avremo accettato completamente l'offerta e il contratto verrà stipulato tra le parti quando invieremo una mail di conferma della spedizione. </p>

 

<p>I costi di spedizione sono sempre indicati in relazione a ogni singolo ordine. </p>

 

<p>SmartResQ/CorPatch<sup>®</sup> mantiene la proprietà dell'articolo acquistato fino al completo pagamento dell'importo della fattura da parte del cliente e viene automaticamente "prelevato" poco prima della spedizione. </p>

 

<p>I clienti hanno diritto alla compensazione solo se le loro domande riconvenzionali sono state legalmente stabilite o sono incontestate o riconosciute da SmartResQ/CorPatch<sup>®</sup>. Inoltre, i clienti hanno un diritto di ritenzione solo se e nella misura in cui la loro domanda riconvenzionale si basa sullo stesso rapporto contrattuale. </p>

 

<p>Se il cliente è in arretrato con noi in merito a qualsiasi obbligo di pagamento, tutti i crediti esistenti diventano immediatamente esigibili. </p>

 

<h3>Commissioni di rincaro </h3>

<p>A partire dal 1° gennaio 2018, le regole relative al sovrapprezzo sono state modificate. Pertanto, non è più legale applicare commissioni sui pagamenti effettuati con le carte dei consumatori se queste sono emesse da banche/emittenti di carte all'interno dell'UE. Questo vale sia per le carte di debito che per quelle di credito. Le carte per i consumatori sono carte emesse a favore di un consumatore privato. 
<br>
Tuttavia, se la carta è aziendale o una carta per i consumatori emessa al di fuori dell'UE, la commissione della transazione sarà maggiorata. Ciò significa che il titolare della carta paga automaticamente la commissione di transazione. 
<br>
La commissione non sarà superiore a quella addebitata a SmartResQ/CorPatch<sup>®</sup> dall'acquirente. L'imposta sarà chiaramente indicata separatamente nella finestra di pagamento. </p>

</div>

<!--This is the 7th section (beige) - title: Consegna-->
<div class="beige">
<h2>Consegna </h2>

<p>Ci impegniamo a spedire gli ordini entro un giorno lavorativo e ci avvaliamo di un'agenzia di spedizioni di fiducia a livello internazionale. Il prezzo totale dell'acquisto, compresa la consegna, è indicato alla cassa prima di accettare l'ordine finale. </p>

 

<p>Se il cliente non accetta la merce, SmartResQ/CorPatch<sup>®</sup> può recedere dal contratto o ricevere un risarcimento per inadempimento dopo un periodo di due settimane a copertura delle spese di gestione e spedizione. </p>

 

<p>Se il cliente ha fornito dati errati sull'indirizzo di consegna, è possibile ritirare il pacco presso il punto di ritiro indicato nel nostro negozio online, altrimenti il pacco andrà perso. </p>

 

<p>Il cliente non riceverà mai consegne parziali se non esplicitamente indicato da SmartResQ/CorPatch<sup>®</sup>. </p>

 

<h3>Rischio di perdita </h3>

<p>Il rischio sul prodotto viene trasferito all'acquirente quando il prodotto viene messo a disposizione dell'acquirente in base al presente accordo. Se il termine di consegna è scaduto e l'acquirente non riceve un prodotto messo a disposizione per lui/lei o a sua disposizione in base al contratto, l'acquirente detiene il rischio di perdita o danno causato dalle caratteristiche del prodotto stesso. </p>

</div>

<!--This is the 8th section (white) - title: Cancellazione e restituzione-->
<div class="white">
<h2>Cancellazione e restituzione </h2>

<p>Quando si effettua un acquisto presso SmartResQ/CorPatch<sup>®</sup>, online o offline, ci sono 14 giorni di tempo per cambiare idea e annullare il contratto, informandoci di aver cambiato idea e restituendoci l'articolo nello stesso stato in cui è stato ricevuto. 
<br>
Accettiamo solo resi di articoli inutilizzati in confezioni originali, sigillate e non danneggiate e devono essere adeguatamente imballati per la spedizione, altrimenti i prodotti saranno considerati usati e non si applicheranno rimborsi parziali. Il diritto di recesso si applica solo ai prodotti non sigillati al momento della ricezione. </p>

 

<h3>Motivi accettabili per la restituzione di un prodotto </h3>
<ul>
<li>Applicazione del diritto di recesso di 14 giorni </li>

<li>Prodotto non conforme alla descrizione (garanzia) </li>

<li>Il prodotto è difettoso </li>
</ul>
 

<p>Le condizioni per la restituzione di CorPatch<sup>®</sup> seguono le regole standard dell'UE. </p>

<p>In caso di restituzione del prodotto, conservare l'imballaggio originale e non danneggiarlo, incollarlo o scriverci sopra. Procurarsi e utilizzare un imballaggio dedicato alla restituzione, ad esempio una scatola di cartone. </p>

 

<p>Per Trainer CorPatch<sup>®</sup>, i termini di ritiro di questo prodotto sono conformi alle norme standard dell'UE. </p>

 

<p>Per esercitare il diritto di recesso, è necessario comunicarlo entro 14 giorni dalla ricezione degli articoli. Le richieste di recesso devono essere inviate via e-mail a info@corpatch.com, indicando chiaramente che si desidera avvalersi dei diritti di recesso e il motivo. </p>

 

<p>Ci aspettiamo che il cliente restituisca gli articoli il prima possibile dopo aver comunicato il recesso e non più tardi di 14 giorni dopo averci informato via e-mail. </p>

 

<p>Possiamo rifiutare il rimborso finché non saranno restituiti gli articoli o non sarà dimostrato di averli restituiti. Per questo rimborso utilizzeremo lo stesso metodo di pagamento usato nella transazione originale. </p>

 

<h3>Motivi non accettabili per la restituzione di un prodotto </h3>
<ul>
<li>Ripensamento dopo il diritto di recesso di 14 giorni. </li>

<li>Se il prodotto è stato attivato. </li>

<li>Se il prodotto viene utilizzato o danneggiato in qualsiasi altro modo. </li>

<li>Se il software/applicazione gratuita viene scaricato, collegato, installato o in qualsiasi altro modo combinato con i prodotti fisici. </li>
</ul>
 

<h3>Come restituire il prodotto </h3>

<p>Accettiamo solo resi di articoli non aperti, nella confezione originale e non danneggiata e devono essere adeguatamente imballati per la spedizione, altrimenti i prodotti saranno considerati usati e non si applicheranno rimborsi. </p>
 

<p><u>Se non diversamente indicato, i resi devono essere inviati a: </u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>) 
<br>
Lundevej 26 
<br>
DK-5700 Svendborg 
<br>
Danimarca </p>

<p><b>IMPORTANTE!</b> Il cliente è l'unico responsabile della qualità dell'imballaggio e degli articoli fino al ricevimento da parte nostra. Si prega di conservare la ricevuta postale con le informazioni sulle spese di spedizione e, se del caso, il numero di tracciabilità. Non copriamo le spese di spedizione per la restituzione e non accettiamo pacchi inviati in contrassegno o simili. </p>

</div>

<!--This is the 9th section (beige) - title: Rimborsi-->
<div class="beige">
 

<h2>Rimborsi </h2>

<p>SmartResQ/CorPatch<sup>®</sup> è obbligata a riparare, sostituire o concedere una riduzione di prezzo o un rimborso completo se l'articolo si rivela difettoso entro 2 anni dall'acquisto. 
<br>
Il cliente non ha diritto al rimborso se il problema è di lieve entità, come ad esempio graffi o simili. 
<br>
Una volta che SmartResQ/CorPatch<sup>®</sup> riceve l'articolo del cliente, viene avviato il rimborso. Il valore dell'importo del rimborso dipende dallo stato dei prodotti al momento della ricezione presso SmartResQ/CorPatch<sup>®</sup>. </p>


<p>Il modo in cui viene elaborato il rimborso del cliente dipende dal metodo di pagamento originale. Se il cliente ha pagato con carta di credito o di debito, il rimborso sarà inviato alla banca emittente della carta entro cinque giorni lavorativi dal ricevimento dell'articolo restituito o della richiesta di cancellazione. Per sapere quando il rimborso verrà accreditato sul conto, contattare la banca che ha emesso la carta.  </p>

</div>

<!--This is the 10th section (white) - title: Trattamento dei dati personali-->
<div class="white">
<h2>Trattamento dei dati personali </h2>

<p>Noi di SmartResQ/CorPatch<sup>®</sup> proteggiamo i vostri dati personali con il nostro impegno al rispetto dei dati in tre fasi: a. mantenere una mappa dettagliata del nostro flusso di dati, b. eseguire valutazioni legali in base al flusso di dati al fine di, c. adottare le misure di sicurezza necessarie per mantenere i dati al sicuro. </p>

 

<p>Per poter utilizzare il nostro negozio online è necessario fornire almeno le seguenti informazioni: </p>
<ul>
<li>Nome </li>

<li>Indirizzo </li>

<li>Indirizzo e-mail </li>

<li>Numero di telefono </li>
</ul>
 

<p>La raccolta dei dati personali dei clienti avviene nell'ambito della legislazione vigente e del Regolamento generale sulla protezione dell'UE (RGDP). </p>

 

<p>Per ulteriori informazioni sul trattamento dei dati personali, consultare la nostra Informativa sulla privacy. </p>

</div>

<!--this is the 11th section (beige) - title: Reclami-->
<div class="beige"> 
<h2>Reclami </h2>

<h3>Come presentare un reclamo </h3>

<p>Se c'è qualcosa che non va nel prodotto, è possibile far riparare o sostituire il prodotto difettoso, ricevere un rimborso o una riduzione del prezzo, a seconda della situazione specifica. 
<br>
Naturalmente, è necessario che il reclamo sia giustificato e che il difetto non sia causato da un uso scorretto del prodotto o da un altro comportamento scorretto. </p>

 

<p>Si consiglia di presentare il reclamo il prima possibile ed entro una settimana da quando si riscontra il difetto.
<br>
Non esitate a contattarci per qualsiasi domanda, commento o reclamo via e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<h3>Presentare un reclamo ad altri enti all'interno dell'UE </h3>

<p>Per presentare reclami ad altri enti dell'UE, vi consigliamo di visitate il sito ufficiale dell'Unione europea. Sono disponibili più informazioni qui. </p>

</div>

<!--this is the 12th section (white) - title: Modifiche al presente documento-->
<div class="white">
<h2>Modifiche al presente documento </h2>

<h3>Sui siti web, app e politiche </h3>

<p>SmartResQ/CorPatch<sup>®</sup> si riserva il diritto di apportare modifiche, eliminare, modificare o integrare i propri siti web, le app, le politiche e i documenti in qualsiasi momento e per qualsiasi motivo, senza alcun preavviso. 
<br>
Se una qualsiasi delle presenti condizioni dovesse essere ritenuta non valida, nulla o per qualsiasi motivo inapplicabile, tale condizione sarà considerata separabile e non pregiudicherà la validità e l'applicabilità delle altre condizioni. </p>
 

<p>In caso di modifiche alle nostre informative, le pubblicheremo online con la data di revisione aggiornata. Vi invitiamo a controllare regolarmente le informative. Nel caso in cui apportassimo modifiche sostanziali alla nostra informativa che cambiano in modo significativo le nostre policy, possiamo anche informarvi in altri modi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sui social media prima che le modifiche entrino in vigore. </p>

 

<h3>Informazioni su un acquisto specifico </h3>

<p>Al momento dell'acquisto di un prodotto, verrà chiesto di accettare una versione di determinati documenti così come sono in quel preciso momento; tale versione non potrà essere modificata dopo tale momento e detterà i termini del nostro rapporto con l'utente in merito a quell'esatto acquisto. </p>

</div>

<!--this is the 13th section (beige)- title: Leggi e giurisdizione -->
<div class="beige">
<h2>Leggi e giurisdizione </h2>

<h3>Le leggi della Danimarca e il Tribunale distrettuale di Svendborg </h3>

 

<p>SmartResQ/CorPatch<sup>®</sup> applica la legge e sceglie le sedi danesi per qualsiasi controversia legale, ma non la CISG (Convenzione sulla vendita internazionale di beni). 
<br>
Qualsiasi controversia relativa in qualsiasi modo alla visita dell'utente a SmartResQ/CorPatch<sup>®</sup> o ai prodotti acquistati tramite SmartResQ/CorPatch<sup>®</sup> sarà sottoposta alla giurisdizione confidenziale della Danimarca, ad eccezione del fatto che, nella misura in cui l'utente abbia in qualsiasi modo violato o minacciato di violare i diritti di proprietà intellettuale di SmartResQ/CorPatch<sup>®</sup>, SmartResQ/CorPatch<sup>®</sup> potrà chiedere un'ingiunzione o altri provvedimenti appropriati in qualsiasi paese e l'utente acconsente alla giurisdizione esclusiva e allo svolgimento in sede in tali tribunali. </p>

 

<p>In caso di violazione dell'accordo "Termini e condizioni", verranno intraprese azioni legali e potrà essere intentata una causa in tribunale. </p>

 

<p>Le controversie tra noi e qualsiasi consumatore sono soggette al tribunale distrettuale di Svendborg, Christiansvej 41, 5700 Svendborg, Danimarca. </p>

</div>

<!--this is the 14th section (white) title: Informazioni di contatto-->
<div class="white">
<h2>Informazioni di contatto </h2>

<p>Grazie per aver letto i "Termini e condizioni" di SmartResQ/CorPatch<sup>®</sup>. 
<br>
In caso di domande, commenti o reclami, non esitate a contattarci. </p>

 

<p>La nostra azienda si trova presso: Lundevej 26, 5700 Svendborg, Danimarca </p>
 

<p>Ci trovate nel Registro Centrale delle Imprese con il numero di partita IVA DK 38674102 </p>

 

<p>Potete chiamarci al numero di telefono +45 62 200 100 </p>

 

<p>oppure </p>

 

<p>contattateci all'indirizzo e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

 

<p><b>© SmartResQ ApS – Tutti i diritti riservati
<br>
Danimarca, Versione 2.1 – Pubblicato il 25.04.2023 </b></p>

</div>
   
<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->
   
   


<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->
   
<!--This is the 15th section (beige) - title: Informativa sulla privacy  -->
<div class="beige">
<h1 id ="policy">Informativa sulla privacy SmartResQ / CorPatch<sup>®</sup> </h1>

<!--This is the 16th section (beige) = joined together with the 15th section - title: Come trattiamo i dati in conformità al RGDP-->
<h2>Come trattiamo i dati in conformità al RGDP </h2>

<p>SmartResQ/CorPatch<sup>®</sup> rispetta la vostra privacy. Questa dichiarazione sulla privacy descrive i diritti alla privacy e il nostro impegno a proteggere le informazioni personali. </p>

<p>In caso di domande sul trattamento dei vostri dati personali da parte nostra, contattateci. Il titolare del trattamento è: </p>

<p><b>Azienda:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Indirizzo: </b>
<br>
Lundevej 26 
<br>
5700 Svendborg 
<br>
Danimarca </p>
<p><b>N. CVR:</b> 38674102 </p>

<p><b>N. di telefono:</b> +45 62 200 100 </p>

<p><b>E-mail:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> è un'azienda danese/europea con entità legali, processi aziendali, strutture gestionali e sistemi tecnici situati oltre i confini nazionali. SmartResQ/CorPatch<sup>®</sup> fornisce prodotti, software e servizi ad aziende pubbliche e private in Europa. </p>

<p>La sede centrale si trova in Danimarca e SmartResQ/CorPatch<sup>®</sup> è soggetta alla legislazione europea sulla protezione dei dati, compreso il Regolamento generale sulla protezione dei dati (RGDP). Tutte le decisioni più importanti in SmartResQ/CorPatch<sup>®</sup> riguardanti la protezione dei dati personali vengono prese a livello dirigenziale, sotto la supervisione del Responsabile della protezione dei dati. </p>

<p>Questa dichiarazione sulla privacy è disponibile sui nostri siti web e nelle nostre applicazioni. </p>

<p>Si prega di non utilizzare le pagine di SmartResQ/CorPatch<sup>®</sup>, le app o i nostri servizi se non si accetta la nostra modalità di trattamento dei dati personali ai sensi della presente informativa sulla privacy. </p>

</div>

<!--This is the 17th section (white) - title: Il tipo di informazioni personali che raccogliamo -->
<div class="white">
<h2>Il tipo di informazioni personali che raccogliamo  </h2>

<p>Quando un responsabile determina le finalità e i mezzi del trattamento dei vostri dati personali, agisce come titolare del trattamento. Ciò include scenari in cui SmartResQ/CorPatch<sup>®</sup> raccoglie dati personali nel contesto di una ricerca di lavoro, di un rappresentante di un cliente o di un potenziale cliente, o se si è utenti del software. </p>

<p>SmartResQ tratta i dati personali per una serie di scopi, a seconda del rapporto in essere. </p>

<p>Possiamo trattare: </p>
<ol>
<li>Informazioni di base sui contatti, come nome, indirizzo, numero di telefono (cellulare e/o fisso) ed e-mail </li>

<li>Informazioni sull'occupazione, come il datore di lavoro, il titolo, la posizione, comprese le preferenze e gli interessi in un contesto professionale </li>

<li>Feedback, commenti o domande su SmartResQ/CorPatch<sup>®</sup> o sui nostri prodotti e servizi </li>

<li>Foto o video registrati nei nostri siti </li>

<li>Contenuti caricati dall'utente, come foto, video e prestazioni nel tempo </li>

<li>Informazioni univoche sull'utente, come ID di accesso, nome utente, password e domanda di sicurezza, </li>

<li>informazioni finanziarie, qualora l'utente acconsenta all'utilizzo delle sue informazioni, ad esempio per la memorizzazione dei dati della sua carta di pagamento, </li>

<li>Informazioni sul traffico fornite dal vostro browser web, come il tipo di browser, il dispositivo, la lingua e l'indirizzo del sito web da cui provenite, e altre informazioni sul traffico, tra cui l'indirizzo IP, </li>

<li>comportamento e attività sugli ID di SmartResQ/CorPatch<sup>®</sup> e con nostri prodotti e servizi, </li>

<li>comportamento della posta elettronica, come ad esempio i messaggi e-mail di SmartResQ/CorPatch<sup>®</sup> che apre quando e come, </li>

<li>altre informazioni personali contenute nel vostro profilo che avete liberamente inserito in social network terzi, come LinkedIn, Facebook, Google, ecc., </li>

<li>Informazioni utilizzate a fini scientifici per migliorare la sopravvivenza dopo un arresto cardiaco, raccolte tramite i nostri siti web e le nostre applicazioni, </li>

<li>informazioni sugli utenti per fornire prodotti conformi ai requisiti di qualità e sicurezza, per fornire servizi agli utenti e per mantenere e migliorare le nostre offerte, </li>

<li>informazioni sui candidati per gestire le domande di lavoro, per comunicare le offerte di lavoro future e per mantenere e migliorare i nostri processi di selezione del personale, </li>

<li>informazioni sulle persone che si sono iscritte per ricevere newsletter e altri materiali, per la consegna di materiali e per mantenere e migliorare le nostre offerte, </li>

<li>informazioni sui cookie per fornire pubblicità su misura sui social media e sui siti web. </li>
</ol>

</div>

<!--This is the 18th section (beige) - tile: Dati raccolti e gestiti nella piattaforma e nelle app dei servizi CorPatch<sup>®</sup> -->
<div class="beige">
<h2>Dati raccolti e gestiti nella piattaforma e nelle applicazioni dei servizi CorPatch<sup>®</sup> </h2>

<p>SmartResQ gestisce, raccoglie e memorizza i seguenti dati personali quando utilizza la piattaforma o le applicazioni dei Servizi CorPatch<sup>®</sup>. </p>

 
 

<h3>Tutti gli utenti (Institute Admin, Trainer, Trainee/utente finale) </h3>
<ul>
<li>Nome (se inserito) </li>

<li>Cognome (se inserito) </li>

<li>Soprannomi (se inseriti) </li>

<li>Indirizzo e-mail (obbligatorio) </li>

<li>Lingua di comunicazione preferita (obbligatorio) </li>

<li>Hash della password (obbligatorio) </li>

<li>Se l'indirizzo e-mail è stato convalidato (obbligatorio) </li>
</ul>
 

<h3>Dati aggiuntivi per Trainee/utenti finali (obbligatorio) </h3>

<p>Dati sul telefono cellulare utilizzato: </p>

<p>Sistema operativo (Android/iOS): </p>
<ul>
<li>Versione del sistema operativo (ad es. 9) </li>

<li>Produttore (ad es. Samsung) </li>

<li>Modello (ad es. SM-T518) </li>

<li>Versione dell'app (ad es. 1.2.4) </li>

<li>Ora dell'ultima attività in primo piano dell'app </li>

<li>Ora dell'ultima attività in background dell'app </li>
</ul>
 

<p>Dati sul CorPatch<sup>®</sup> (CPS) utilizzato: </p>
<ul>
<li>Numero di serie / indirizzo MAC </li>

<li>Versione del firmware </li>

<li>Nome del modello (ad es. CPS_01) </li>

<li>Produttore (attualmente sempre SRQ) </li>

<li>Nome (attualmente sempre CorPatch<sup>®</sup>) </li>

<li>Condizioni della batteria </li>

<li>Difetti </li>
</ul>
 

<p>Dati di inserimento degli utenti: </p>
<ul>
<li>Tutorial completato (sì/no) </li>

<li>Condizioni di utilizzo accettate (sì/no) </li>

<li>Autovalutazione completata (sì/no) </li>

<li>Formazione al test completata (sì/no) </li>

<li>Primo accesso riuscito (sì/no) </li>

<li>Connessione di CPS (sì/no) </li>
</ul>
 

<p>Dati raccolti durante le esercitazioni: </p>
<ul>
<li>Data, ora e durata delle esercitazioni </li>

<li>Risultati delle esercitazioni </li>

<li>Tipo di esercitazione o impostazioni dell'esercitazione </li>

<li>In caso di esercitazione all'interno di un istituto, informazioni aggiuntive sul corso, sul trainer e sull'istituto. </li>
</ul>
 
 

<h3>Registri del server </h3>

<p>I seguenti dati vengono memorizzati nei registri del server web: </p>
<ul>
<li>Indirizzo IP della parte che effettua l'accesso </li>

<li>Versione del browser della parte che accede </li>

<li>Data/ora dell'accesso </li>

<li>URL dell'accesso </li>
</ul>
<h3>Servizi esterni di elaborazione dei dati </h3>
<ul>
<li>Google/Firebase per l'accesso remoto, analisi degli errori e dei crash </li>

<li>Google/Firebase per l'invio di notifiche </li>

<li>Sendgrid per l'invio di e-mail </li>

<li>Hetzner Online GmbH per l'hosting del backend web e del database </li>
</ul>
 

<h3>Cosa succede quando un utente viene eliminato </h3>
<ul>
<li>L'utente elimina il proprio profilo dal nostro sistema sulla homepage dei servizi di CorPatch<sup>®</sup> <a href="https://app.corpatch.com">https://app.corpatch.com</a></li>

<li>L'utente viene contrassegnato come eliminato. Dopo di che, non avrà più accesso, non sarà visibile agli amministratori, ecc.ma l'utente esiste ancora nel database. </li>

<li>Dopo 14 giorni, i dati dell'utente vengono automaticamente eliminati dal database. </li>

<li>Ai fini della valutazione scientifica e del miglioramento della funzionalità, i dati relativi alla pratica e all'utilizzo di CorPatch<sup>®</sup> continueranno a essere presenti nel database anche dopo la cancellazione dell'utente, ma il riferimento (ID) all'utente sarà vuoto e tutti i riferimenti ai dati personali saranno rimossi.  </li>
</ul>

</div>

<!--This is the 19th section (white) - tile: Come raccogliamo i dati personali-->
<div class="white">
<h2>Come raccogliamo i dati personali </h2>

<p>La maggior parte dei dati personali che trattiamo ci vengono forniti direttamente dall'utente. Raccogliamo e trattiamo i dati quando: </p>
<ul>
<li>ci si registra online o si effettua un ordine per uno qualsiasi dei nostri prodotti o servizi; ad esempio, dati demografici, indirizzo e-mail, informazioni di pagamento, articoli, importo dell'ordine, livello e frequenza degli sconti. Compreso l'invio di e-mail transazionali, ad esempio la conferma dell'ordine, la conferma della spedizione e la conferma del rimborso, </li>

<li>si interagisce con le comunicazioni inviate (e-mail, SMS, posta o telefono); ad esempio, tasso di apertura, tasso di clic e tempo di lettura delle e-mail, dominio del mittente e tipo di client e-mail, </li>

<li>è possibile compilare volontariamente un sondaggio tra i clienti o fornire un feedback su una qualsiasi delle nostre bacheche o via e-mail. </li>
</ul>
 

<p>Potremmo anche ricevere informazioni personali indirettamente, dalle seguenti fonti, nei seguenti scenari: </p>
<ul>
<li>Dai cookie: quando si visitano i nostri siti web o le nostre app; ad esempio, indirizzo IP, paese, pagine visualizzate, prodotti visualizzati, interazioni/clic e ricerche. </li>

<li>Dall'utente o da qualsiasi altra persona affiliata al nostro cliente. Questa persona può essere un manager o un collega. Se il cliente per cui si lavora acquista prodotti o servizi da SmartResQ/CorPatch<sup>®</sup> attraverso una società partner di SmartResQ/CorPatch<sup>®</sup>, potremmo raccogliere informazioni su di voi dalla società partner. </li>

<li>Partner di marketing di SmartResQ/CorPatch<sup>®</sup>, fonti pubbliche o social network di terzi. </li>

<li>SmartResQ/CorPatch<sup>®</sup> potrà combinare i dati personali dell'utente raccolti da una fonte con informazioni ottenute da un'altra fonte. Questo ci permette di avere un quadro più completo dell'utente e di servirlo in modo più pertinente e con un livello di personalizzazione più elevato. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Come usiamo i dati-->
<div class="beige">
<h2>Come usiamo i dati </h2>

<p>Per gestire in generale le relazioni con i clienti e per rispettare gli impegni assunti con i clienti, SmartResQ/CorPatch<sup>®</sup> ha bisogno di informazioni sull'utente in qualità di cliente o quando utilizza un servizio. Le finalità del trattamento di tali dati personali sono: </p>

<ul>
<li>elaborare l'ordine, gestire l'account </li>

<li>inviare e-mail con offerte speciali su altri prodotti e servizi che riteniamo possano essere graditi </li>

<li>sviluppare il processo di vendita e di contratto per i clienti </li>

<li>fornire ai clienti i prodotti e i servizi richiesti </li>

<li>effettuare consegne in conformità agli accordi stipulati con l'utente o con i clienti </li>

<li>offrire assistenza agli utenti dei nostri prodotti e servizi </li>

<li>migliorare e sviluppare la qualità, la funzionalità e l'esperienza utente dei nostri prodotti, servizi e dei siti web e delle applicazioni SmartResQ/CorPatch<sup>®</sup> </li>

<li>rilevare, limitare e prevenire le minacce alla sicurezza ed eseguire la manutenzione, la risoluzione dei problemi e il debug </li>

<li>prevenire l'uso improprio dei nostri prodotti e servizi </li>

<li>elaborare ordini, fatture, pagamenti o altri procedimenti finanziari </li>

<li>creare profili di interesse per promuovere prodotti e servizi rilevanti </li>

<li>creare comunità di utenti per educare e facilitare l'interazione tra gli utenti e SmartResQ/CorPatch<sup>®</sup> </li>
</ul>
 

<h3>Informazioni dei potenziali clienti </h3>

<p>SmartResQ/CorPatch<sup>®</sup> elabora i dati personali dei contatti per scopi di marketing. Al fine di fornire contenuti mirati e pertinenti ai potenziali clienti, SmartResQ/CorPatch<sup>®</sup> costruisce un profilo di interessi basato sull'attività dell'utente e sulle sue scelte e azioni sulle pagine di SmartResQ/CorPatch<sup>®</sup>, nonché sulla sua risposta ai contenuti di marketing. La base giuridica di questo trattamento è principalmente il vostro consenso. </p>

<h3>Informazioni di chi cerca lavoro </h3>

<p>Se ha fatto domanda per lavorare presso di noi, tratteremo i suoi dati personali al fine di valutare il suo potenziale come dipendente SmartResQ/CorPatch<sup>®</sup>. La nostra piattaforma di offerta lavori online, sicura e protetta, garantisce il rispetto delle più recenti leggi e normative in materia di privacy dei dati. La base giuridica di questo trattamento è il vostro consenso. </p>

<h3>Informazioni dei visitatori del sito web </h3>

<p>Per monitorare l'accesso ai nostri siti, trattiamo i dati personali dei visitatori. Il trattamento si basa sul nostro legittimo interesse a proteggere i nostri segreti aziendali, i nostri dipendenti, le nostre sedi e i visitatori. L'utente sarà informato dei suoi diritti in questo contesto al momento della registrazione nel nostro sistema elettronico per i visitatori. </p>

<p>Per migliorare la qualità della RCP, in particolare attraverso la formazione sulla RCP, SmartResQ/CorPatch<sup>®</sup> potrebbe condividere i dati con le nostre società partner (Training Institute) affinché possano offrire i loro prodotti e servizi. </p>

<p>Durante l'elaborazione dell'ordine, il nostro sistema può inviare i dati dell'utente alle agenzie di riferimento creditizio e utilizzare le informazioni che ne derivano per prevenire acquisti fraudolenti. </p>

</div>

<!-- This is 21st section (white) - title: Come conserviamo i dati personali -->
<div class="white">
<h2>Come conserviamo i dati personali </h2>

<p>SmartResQ/CorPatch<sup>®</sup> prende molto sul serio la fiducia che voi e i nostri clienti ci dimostrate. SmartResQ/CorPatch<sup>®</sup> si impegna a evitare l'accesso non autorizzato, la divulgazione o altri trattamenti aberranti dei dati personali. SmartResQ/CorPatch<sup>®</sup> garantirà la riservatezza dei dati personali trattati, ne manterrà l'integrità e ne garantirà l'accessibilità in conformità alle leggi sulla riservatezza applicabili. </p>

<p>Nell'ambito dei nostri impegni, adottiamo procedure e misure organizzative tecniche e fisiche ragionevoli e adeguate per proteggere le informazioni che raccogliamo ed elaboriamo. Tenendo conto del tipo di dati personali e del rischio a cui i nostri clienti sono esposti da eventuali violazioni della sicurezza, poiché è molto probabile che le cause delle violazioni dei dati personali siano da ricercare internamente, riteniamo importante la costruzione di una solida cultura aziendale in cui il rispetto e la vigilanza sulla protezione dei dati tra i nostri dipendenti sia fondamentale per garantire il trattamento legale e la protezione dei dati. In caso di violazione dei dati, SmartResQ/CorPatch<sup>®</sup> seguirà le pratiche stabilite dalla Datatilsynet danese. </p>

<p>Le sue informazioni sono conservate in modo sicuro in conformità le norme GDPR. </p>
 
 </div>

<!--This is the 22nd section (beige) - title: Per quanto tempo conserviamo i dati personali?-->
<div class="beige">
<h2>Per quanto tempo conserviamo i dati personali? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> conserva i dati personali dell'utente solo per il tempo necessario a raggiungere gli scopi indicati, tenendo conto della necessità di rispondere alle richieste e risolvere i problemi, nonché di ottemperare ai requisiti legali previsti dalla normativa vigente. </p>

<p>Ciò significa che SmartResQ/CorPatch<sup>®</sup> può conservare i dati personali per un periodo ragionevole dopo l'ultima interazione con noi da parte vostra e dei nostri clienti. Quando i dati personali raccolti non sono più necessari, li cancelliamo. Possiamo trattare i dati a fini statistici e/o scientifici, ma in tal caso i dati saranno pseudonimizzati o anonimizzati. </p>

 
 

<h3>Tempi di archiviazione dei dati </h3>

<p>Conserveremo le informazioni personali dell'utente per il periodo necessario ad adempiere agli scopi delineati nella presente Informativa sulla Privacy, a meno che un periodo di conservazione più lungo sia richiesto o consentito dalla legge, per motivi legali, fiscali o normativi, o per altri scopi commerciali legittimi e leciti. </p>

<table>
<tr>
<td><b>Scopo </b></td>

<td><b>Periodo di tempo </b></td>
</tr>

<tr>
<td>Servizio clienti e normativa contabile </td>

<td>Cinque anni o finché siamo obbligati a soddisfare i requisiti legali necessari. 
<br>
 
I dati di registrazione vengono cancellati non appena l'utente cancella il proprio account o quando scade il periodo di conservazione previsto dalla legge. 
</td>
</tr>

<tr>
<td>Piattaforma di offerta lavori </td>

<td>Sei mesi per i documenti di candidatura che non comportano l'assunzione del candidato. 
<br>
 

Rinnovo ogni sei mesi se si desidera essere presi/e in considerazione per future offerte di lavoro e si acconsente a un periodo di conservazione più lungo. 
</td>
</tr>

<tr>
<td>A scopo di marketing </td>

<td>Tre anni dopo l'ultima attività, ad esempio la visita ai nostri siti web, l'acquisto o la partecipazione a una comunicazione. 
<br>
 
Se è stato dato il consenso al marketing (e-mail, SMS, telefono ecc.) e finché abbiamo il permesso di contattarvi. 
<br>

Cancelliamo automaticamente l'indirizzo e-mail nella newsletter, una volta cancellato il proprio account utente o all'annullamento dell'iscrizione alla nostra newsletter.  
</td>
</tr>

<tr>
<td>Memorizzazione della cronologia degli ordini e degli obblighi di evasione degli stessi </td>

<td>Cinque anni o finché siamo obbligati a soddisfare i requisiti legali necessari  </td>
</tr>

<tr>
<td>Esperienza del cliente </td>

<td>Tre anni dopo l'ultima attività, ad esempio la visita ai nostri siti web, l'acquisto o la partecipazione a una comunicazione.
<br>
 
Se è stato dato il consenso al marketing (e-mail, SMS, telefono ecc.) e finché abbiamo il permesso. 
</td>
</tr>

<tr>
<td>Frode e valutazione del rischio </td>

<td>Cinque anni o finché siamo obbligati a soddisfare i requisiti legali necessari. </td>
</tr>
</table>

</div>

<!--This is the 23rd section (white) - title: Comunicazioni di marketing-->
<div class="white">
<h2>Comunicazioni di marketing </h2>

<p>L'utente ha il diritto di rinunciare a ricevere comunicazioni di marketing da SmartResQ/CorPatch<sup>®</sup> e può farlo in due modi: </p>
<ul>
<li>seguendo le istruzioni di opt-out contenute nelle comunicazioni di marketing pertinenti, </li>

<li>modificando le preferenze nella sezione di modifica relativa all'account, se si dispone di un account con SmartResQ/CorPatch<sup>®</sup>, </li>

<li>contattandoci via e-mail all'indirizzo <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</li>
</ul>

<p>Si prega di notare che, anche se si sceglie di non ricevere comunicazioni di marketing, si possono comunque ricevere messaggi amministrativi da SmartResQ/CorPatch<sup>®</sup>, come conferme d'ordine e avvisi necessari per la gestione del proprio account o dei servizi forniti ai nostri clienti tramite, ad esempio, servizi mobili o app. </p>

</div>

<!--This is the 24th section (beige) - title: Diritti sulla protezione dei dati-->
<div class="beige">
<h2>Diritti sulla protezione dei dati </h2>

<p>Per SmartResQ/CorPatch<sup>®</sup> è importante che l'utente sia pienamente consapevole di tutti i suoi diritti in materia di protezione dei dati. </p>

<p>Alcune leggi sulla protezione dei dati, tra cui il Regolamento generale sulla protezione dei dati (RGDP) dell'Unione Europea, la legislazione corrispondente in Germania Bundesdatenschutzgesetz (BDSG), in Svizzera e nel Regno Unito, e alcune leggi statali statunitensi, forniscono all'utente alcuni diritti in relazione ai dati personali condivisi con noi. Se si risiede nello Spazio Economico Europeo, è possibile godere dei seguenti diritti: </p>
<ol>
<li><b>Diritto di accesso:</b> l'utente ha il diritto di chiederci copia delle sue informazioni personali. </li>

<li><b>Diritto di rettifica:</b> l'utente ha il diritto di richiedere la rettifica delle informazioni personali che ritiene inesatte. L'utente ha inoltre il diritto di richiedere il completamento delle informazioni che ritiene incomplete.  </li>

<li><b>Diritto alla cancellazione:</b> l'utente ha il diritto di richiedere la cancellazione dei propri dati personali a determinate condizioni.  </li>

<li><b>Diritto di limitare il trattamento:</b> l'utente ha il diritto di chiederci di limitare il trattamento delle informazioni personali a determinate condizioni. </li>

<li><b>Diritto di opposizione al trattamento:</b> l'utente ha il diritto di opporsi al trattamento dei dati personali a determinate condizioni. </li>

<li><b>Diritto alla portabilità dei dati:</b> l'utente ha il diritto di richiedere il trasferimento delle informazioni personali da noi raccolte a un'altra organizzazione o direttamente a lui, a determinate condizioni. </li>
</ol>

<p>Se si desidera esercitare uno di questi diritti, contattare l'indirizzo e-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

</div>

<!--This is the 25th section (white) - title: Cosa sono i cookie?-->
<div class="white">
<h2>Cosa sono i cookie? </h2>

<p>I cookie sono piccoli file di testo che contengono una stringa di caratteri e creano un identificatore unico per un utente. Vengono restituiti al sito web e/o a terzi. La maggior parte dei browser è inizialmente impostata per accettare i cookie, in quanto la maggior parte dei siti web è obbligata ad accedervi. Tuttavia, è possibile modificare le impostazioni del browser in modo da poter generalmente rifiutare i cookie, bloccare i cookie di terzi o specificare quando un cookie viene inviato.</p>
<p>SmartResQ/CorPatch<sup>®</sup> si impegna a garantire il diritto di regolare i propri interessi e di gestire l'ambito del marketing digitale da parte nostra attraverso un sistema di gestione preferenziale. </p>

</div>

<!--This is the 26th section (beige) - title: Come usiamo i cookie-->
<div class="beige">
<h2>Come usiamo i cookie </h2>

<p>SmartResQ/CorPatch<sup>®</sup> utilizza i cookie in diversi modi per migliorare la vostra esperienza sui nostri siti web, app e servizi per diversi motivi, ad esempio: </p>
<ul>
<li>Funzionalità: utilizziamo questi cookie per riconoscere l'utente sul nostro sito web e ricordare le preferenze selezionate in precedenza. Tra questi, la lingua preferita e la località in cui ci si trova. Viene utilizzato un mix di cookie di prima parte e di terze parti. </li>

<li>Pubblicità: utilizziamo questi cookie per raccogliere informazioni sulla visita ai nostri siti web, alle nostre app, sui contenuti visualizzati, sui link seguiti e sulle informazioni relative al browser, al dispositivo e all'indirizzo IP. A volte condividiamo alcuni aspetti limitati di questi dati con terze parti per scopi pubblicitari. Possiamo anche condividere i dati online raccolti attraverso i cookie con i nostri partner pubblicitari. Ciò significa che quando visitate un altro sito web, potreste ricevere pubblicità basata sulle vostre abitudini di navigazione sul nostro sito. </li>
</ul>

</div>

<!--This is the 27th section (white) - title: I tipi di cookie che usiamo-->
<div class="white">
<h2>I tipi di cookie che usiamo </h2>

<p>I nostri siti web utilizzano i seguenti tipi di cookie: </p>
<ul>
<li><b>Google Analytics:</b> Questo cookie ci consente di visualizzare informazioni sulle attività del sito web degli utenti, tra cui, a titolo esemplificativo ma non esaustivo, le visualizzazioni di pagina, la fonte e il tempo trascorso su un sito web. Le informazioni sono anonime e vengono visualizzate come numeri, il che significa che non possono essere ricondotte a singoli individui. In questo modo, si contribuisce a proteggere la privacy. Utilizzando Google Analytics, possiamo vedere quali sono i contenuti più popolari sulle nostre pagine e cerchiamo di offrire sempre di più ciò che piace leggere e vedere agli utenti. 

<li><b>Google Analytics remarketing:</b> Inserisce dei cookie nel computer, il che significa che quando si lascia il nostro sito, Google potrebbe mostrare annunci pubblicitari su SmartResQ/CorPatch<sup>®</sup> che potrebbero interessare in base ai propri precedenti comportamenti sul nostro sito web. Queste informazioni non sono identificabili personalmente. 

<li><b>Google Ads:</b> Utilizzando Google Ads, possiamo vedere quali pagine sono state utili per ottenere l'invio del modulo di contatto. Queste informazioni non sono identificabili personalmente, mentre lo sono i dati forniti nel modulo di contatto. 

<li><b>Google Ads remarketing:</b> Inserisce dei cookie nel computer, il che significa che quando si lascia il nostro sito, Google potrebbe mostrare annunci pubblicitari su SmartResQ/CorPatch<sup>®</sup> che potrebbero interessare in base ai propri precedenti comportamenti sul nostro sito web. Queste informazioni non sono identificabili personalmente. 

<li><b>Facebook Remarketing:</b> Il pixel di tracciamento di Facebook inserisce dei cookie nel computer dell'utente che indicano a Facebook che l'utente ha visitato il sito. Si presume quindi che l'utente sia interessato a SmartResQ/CorPatch<sup>®</sup> e ai contenuti di questo sito. Quando si visita Facebook, saranno esposte informazioni o pubblicità con contenuti simili. Utilizzare le impostazioni sulla privacy di Facebook per limitare l'esposizione a questo tipo di marketing. 

<li><b>YouTube:</b> Integriamo i video dalla piattaforma YouTube fornita da Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Informativa sulla privacy: <a href="https://www.google.com/policies/privacy/"> https://www.google.com/policies/privacy/</a>.</li>
</ul>
 
</div>

<!--This is the 28th section (beige) - title: Come gestire i cookie-->
<div class="beige">
<h2>Come gestire i cookie </h2>

<p>È possibile impostare il proprio browser in modo da non accettare o eliminare i cookie. Tuttavia, alcune funzionalità del nostro sito web potrebbero non funzionare di conseguenza. Ecco come evitare i cookie in browser specifici: </p>
<ul>
<li>Il sito USA: <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a></li>

<li>Il sito UE: <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>
</ul>
 

<p>SmartResQ/CorPatch<sup>®</sup> utilizza alcuni servizi di terze parti affidabili sulle nostre pagine. Questi servizi potrebbero utilizzare dei cookie. È possibile scegliere di rifiutare i cookie di terze parti nel browser seguendo questo <a href="https://www.youronlinechoices.com/">link</a>.</p>

<p>È possibile impedire che le informazioni generate dal cookie di Google sull'utilizzo dei nostri siti vengano raccolte ed elaborate da Google scaricando e installando il componente aggiuntivo del browser Google Analytics Opt-out per il browser web utilizzato. Questo componente aggiuntivo è disponibile <a href="https://tools.google.com/dlpage/gaoptout">qui</a>.</p>  

</div>

<!--This is the 29th section (white) - title: Politiche sulla privacy di altri siti-->
<div class="white">
<h2>Politiche sulla privacy di altri siti </h2>

<p>I siti web SmartResQ/CorPatch<sup>®</sup> contengono link ad altri siti web. La nostra politica sulla privacy si applica solo al nostro sito web, quindi se si clicca su un link a un altro sito web, è necessario leggere la loro politica sulla privacy. </p>

</div>

<!--This is the 30th section (beige) - title: Modifiche alla nostra informativa sulla privacy-->
<div class="beige">
<h2>Modifiche alla nostra informativa sulla privacy </h2>

<p>Se modifichiamo la nostra informativa sulla privacy, pubblicheremo qui l'informativa modificata con la data di revisione aggiornata. Vi invitiamo a verificare regolarmente l’informativa. Se apportiamo modifiche sostanziali all’informativa che cambiano in modo significativo le nostre pratiche in materia di privacy, possiamo anche informarvi in altri modi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sui social media prima che le modifiche entrino in vigore. </p>

</div>

<!--This is the 31st section (white) - title: Come contattare SmartResQ ApS-->
<div class="white">
<h2>Come contattare SmartResQ ApS </h2>

<p>In caso di domande sull’informativa sulla privacy di SmartResQ/CorPatch<sup>®</sup>, sui dati in nostro possesso o se desiderate esercitare uno dei vostri diritti di protezione dei dati, non esitate a contattarci. </p>

<p>E-mail: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p> 

<p>Sito web: <a href="https://corpatch.com/">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Come contattare l'autorità competente-->
<div class="beige">

<h2>Come contattare l'autorità competente </h2>

<p>Se si desidera presentare un reclamo o se si ritiene che SmartResQ/CorPatch<sup>®</sup> non abbia affrontato il problema in modo soddisfacente, è possibile contattare l'Ufficio del responsabile per l'informazione (ICO). </p>

<p>L'indirizzo dell'ICO è: 
<br><br>
Information Commissioner's Office 
<br>
Wycliffe House 
<br>
Water Lane 
<br>
Wilmslow 
<br>
Cheshire 
<br>
SK9 5AF </p>
 

<p>Numero dedicato: +44 303 123 1113 </p>

<p>Sito web dell'ICO: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – Tutti i diritti riservati 
<br>
Danimarca, Versione 1.3 – Pubblicato il 25.04.2023 </b></p>

</div>

</div>
</body>
</html>`

export const termsOfUse_es_ES: string = `<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Combined Document: T&C 2.1 and PP 1.3</title>

    <style>
        .ext-emb-cont {
          background-color: #f9f8f6;
          word-wrap: break-word;
        }

        .ext-emb-cont h1 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.5rem;
        }

        .ext-emb-cont h2 {
            color: #ac154c;
            font-family: sans-serif;
            font-size: 1.3rem;
        }

        .ext-emb-cont h2.black {
            color: #000000;
        }

        .ext-emb-cont h3 {
            color: #000000;
            font-family: sans-serif;
            font-style: italic;
            font-size: 1.1rem;
        }

        .ext-emb-cont p {
            color: black;
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont p.bold{
            font-weight: bold;
        }

        .ext-emb-cont li {
            font-family: sans-serif;
            line-height: 1.5;
        }

        .ext-emb-cont a {
            color: #ac154c;
        }

        .ext-emb-cont .top-section {
            background-color: #f9f8f6;
            text-align: center;
            padding: 25px;
        }

        .ext-emb-cont .white {
            background-color: #ffffff;
            padding: 25px;
        }

        .ext-emb-cont .beige {
            background-color: #f9f8f6;
            padding: 25px;
        }

        .ext-emb-cont table , tr , td {
            border: 2px solid black;
            border-collapse: collapse;
            padding: 10px;
            font-family: sans-serif;
            line-height: 1.5;
            table-layout: fixed;
            width: 100%;
        }
    </style>

</head>

<body>
<div class="ext-emb-cont">

<!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS --> <!-- TERMS AND CONDITIONS -->

    <!--This is the 1st section - title: Allgemeine Geschäftsbedingungen-->
    <div class="top-section">
        <h1><a href="#terms">Términos y Condiciones</a> & <a href="#policy">Política de Privacidad </a></h1>
        <h2 class="black">SmartResQ ApS / CorPatch<sup>®</sup></h2>
    </div>
<!--This is the 2nd section (white) - title: Zweck dieses Dokuments and Wie du SmartResQ kontaktierst -->
<div class="white">
<h1 id="terms">Condiciones generales de contrato – SmartResQ ApS / CorPatch<sup>®</sup> </h1>

<h2>Objeto del presente documento </h2>

<p>¡Bienvenido a SmartResQ/CorPatch<sup>®</sup>! </p>



<p>Actualmente está leyendo nuestros Términos y Condiciones; un documento con información sobre su relación
con SmartResQ ApS, que comenzó cuando entró en nuestro sitio web, descargó nuestra(s) app(s) gratuita(s),
compró uno de nuestros productos o similar. </p>



<p>SmartResQ/CorPatch<sup>®</sup> y nuestros asociados le proporcionamos nuestros productos y servicios sujetos a las
siguientes condiciones. Si visita o compra en nuestro(s) sitio(s) web o aplicación(es), usted acepta estas
condiciones. Le recomendamos encarecidamente que las lea detenidamente antes de utilizar nuestros productos
o servicios. </p>



<p>Este documento abarca: </p>
<ul>
<li>Cómo ponerse en contacto con SmartResQ </li>

<li>Productos, propiedades y uso adecuado </li>

<li>Renuncias y garantías </li>

<li>Pedidos </li>

<li>Pago </li>

<li>Entrega </li>

<li>Responsabilidades y derechos relacionados con la venta </li>

<li>Devolución </li>

<li>Tratamiento de datos personales </li>

<li>Reclamación </li>

<li>Cambios en este documento </li>

<li>Derecho y jurisdicción </li>

<li>Información de contacto </li>


</ul>
<p>Si tiene alguna pregunta en relación con los Términos y Condiciones de SmartResQ, póngase en contacto con
nosotros. </p>



<h2>Cómo ponerse en contacto con SmartResQ </h2>

<p>En SmartResQ pretendemos aumentar la supervivencia mediante una RCP de calidad en caso de parada cardiaca.
Queremos preparar y guiar a los transeúntes para que actúen desde los primeros minutos cruciales tras
presenciar una parada cardiaca. Por ello, hemos desarrollado CorPatch<sup>®</sup> – Le ayudamos a salvar una vida. </p>



<p>Nuestra empresa se encuentra en Lundevej 26, 5700 Svendborg, Dinamarca </p>



<p>Puede encontrarnos en el Registro Mercantil Central con el número de IVA DK 38674102 </p>



<p>Puede llamarnos al número de teléfono +45 62 200 100 </p>



<p>o </p>



<p>envíenos un correo electrónico a <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>



<h3>¿Alguna pregunta, comentario o reclamación? </h3>

<p>Agradecemos su opinión, así que póngase en contacto con nosotros a través de la información de contacto
mencionada anteriormente o utilice el formulario de contacto de nuestro(s) sitio(s) web o app(s). </p>


</div>

<!--This is the 3rd section (beige) - title: Produkte, Eigenschaften und ordnungsgemäße Nutzung-->
<div class="beige">
<h2>Productos, propiedades y uso adecuado </h2>

<h3>Nuestros sitios web, aplicaciones y productos </h3>

<p>Los siguientes párrafos describen las propiedades, derechos y responsabilidades esenciales relacionados con el
uso de los servicios, aplicaciones y productos SmartResQ/CorPatch<sup>®</sup>.
<br>
Hacemos todos los esfuerzos razonables para incluir información precisa y actualizada en los sitios web y en las
aplicaciones. Sin embargo,  SmartResQ/CorPatch<sup>®</sup> no garantiza la exactitud de la información. No asumimos
ninguna responsabilidad por cualquier error u omisión en el contenido del sitio web, productos y aplicaciones.
La información proporcionada en este sitio web o en las aplicaciones no sustituye la atención médica. Asumimos
la responsabilidad de nuestros productos y aplicaciones de acuerdo con la Directiva de Productos Sanitarios UE
745/2017 (MDR).</p>



<h3>Copyright </h3>

<p>ATodo el contenido incluido en este sitio, como texto, gráficos, logotipos, iconos de botones, imágenes, clips de
audio, descargas digitales, compilaciones de datos y software, es propiedad de SmartResQ/CorPatch<sup>®</sup> o de sus
proveedores de contenido y está protegido por las leyes internacionales de derechos de autor. La compilación
de todo el contenido de este sitio es propiedad exclusiva de SmartResQ/CorPatch<sup>®</sup>, con derechos de autor para
esta colección por SmartResQ/CorPatch<sup>®</sup>  y protegido por leyes internacionales de derechos de autor. </p>



<h3>Marcas y diseños registrados </h3>

<p>Las marcas registradas SmartResQ/CorPatch<sup>®</sup>, la imagen comercial y el diseño del producto no pueden ser
usados en conexión con ningún producto o servicio que no sea SmartResQ/CorPatch<sup>®</sup>, de ninguna manera que
pueda causar confusión entre los clientes, o de ninguna manera que menosprecie o desacredite a
SmartResQ/CorPatch<sup>®</sup>. Todas las demás marcas comerciales que no son propiedad de SmartResQ/CorPatch<sup>®</sup> o
sus subsidiarias que aparecen en este sitio son propiedad de sus respectivos dueños, quienes pueden o no estar
afiliados, conectados o patrocinados por SmartResQ/CorPatch<sup>®</sup> o sus subsidiarias. </p>



<h3>Licencia y acceso al sitio </h3>

<p>SmartResQ/CorPatch<sup>®</sup> le concede una licencia limitada para acceder y hacer uso personal de nuestro(s) sitio(s) y
aplicaciones y no para descargar (excepto el almacenamiento en caché de páginas) o modificarlo o cualquier
parte del mismo, excepto con el consentimiento expreso por escrito de SmartResQ/CorPatch<sup>®</sup>. Esta licencia no
incluye ninguna reventa o uso comercial de este sitio(s) o aplicaciones o su contenido: (a) cualquier recopilación
y uso de cualquier listado de productos, descripciones o precios: (b) cualquier uso derivado de este sitio o sus
contenidos: (c) cualquier descarga o copia de información de la cuenta en beneficio de otro comerciante: o (d)
cualquier uso de minería de datos, robots o herramientas similares de recopilación y extracción de datos.</p>



<p> Este sitio o aplicación o cualquier parte de este sitio no puede ser reproducido, rediseñado, duplicado, copiado,
vendido, revendido, visitado o explotado para cualquier propósito comercial sin el consentimiento expreso y por
escrito de SmartResQ/CorPatch<sup>®</sup>. Usted no puede enmarcar o utilizar técnicas de enmarcado para adjuntar
cualquier marca registrada, logotipo u otra información de propiedad (incluyendo imágenes, texto, diseño de
página o forma) de SmartResQ/CorPatch<sup>®</sup> y nuestros asociados sin el consentimiento expreso por escrito. </p>



<p>Usted no puede utilizar ninguna etiqueta meta o cualquier otro “texto oculto” utilizando el nombre
SmartResQ/CorPatch<sup>®</sup> o marcas registradas sin el consentimiento expreso y por escrito de
SmartResQ/CorPatch<sup>®</sup>. Cualquier uso no autorizado termina el permiso o licencia concedida por
SmartResQ/CorPatch<sup>®</sup>.</p>



<p>Se le concede un derecho limitado, revocable y no exclusivo para crear un hipervínculo a la página principal de
SmartResQ/CorPatch<sup>®</sup> siempre y cuando el enlace no represente a SmartResQ/CorPatch<sup>®</sup>, sus asociados o sus
productos o servicios de manera falsa, engañosa, despectiva u ofensiva. Usted no puede utilizar ningún logotipo
SmartResQ/CorPatch<sup>®</sup> u otro gráfico o marca registrada como parte del enlace sin permiso expreso por escrito.
<br>
Si se incumple el acuerdo sobre la licencia y el acceso al sitio, se emprenderán acciones legales y se podrá
presentar una demanda ante los tribunales. </p>

<h3>Comunicación electrónica </h3>

<p>Cuando visita la página de inicio y los canales de medios sociales de SmartResQ/CorPatch<sup>®</sup> o nos envía correos
electrónicos, se está comunicando con nosotros electrónicamente. Usted da su consentimiento para recibir
nuestras comunicaciones electrónicamente. Nos comunicaremos con usted por correo electrónico o mediante
la publicación de avisos en nuestros sitios web o en las aplicaciones en forma de ventanas emergentes o
notificaciones. Usted acepta que todos los acuerdos, avisos, divulgaciones y otras comunicaciones que le
proporcionemos electrónicamente satisfacen cualquier requisito legal de que dichas comunicaciones se realicen
por escrito. Consulte nuestra política de privacidad en un documento aparte que puede encontrar en nuestra
página de inicio. </p>



<p>Los visitantes de nuestro sitio web y canales de redes sociales pueden ser invitados a publicar reseñas,
comentarios y otros contenidos, por ejemplo, sugerencias, ideas, comentarios, preguntas u otra información,
siempre que el contenido no sea ilegal, obsceno, amenazador, difamatorio, invasivo de la privacidad, infractor
de los derechos de propiedad intelectual, o de otro modo perjudicial para terceros o censurable y no consista en
o contenga virus de software, campañas políticas, solicitudes comerciales, cartas en cadena, correos masivos, o
cualquier forma de “spam”. </p>



<p>Usted no puede utilizar una dirección de correo electrónico falsa, hacerse pasar por cualquier persona o entidad,
o inducir a error sobre el origen de una tarjeta u otro contenido. SmartResQ/CorPatch<sup>®</sup> se reserva el derecho
(pero no la obligación) de eliminar o editar dicho contenido, pero no revisa regularmente el contenido publicado.
Si publica contenido o envía material y a menos que indiquemos lo contrario, usted otorga a
SmartResQ/CorPatch<sup>®</sup> y sus asociados un derecho no exclusivo, libre de regalías, perpetuo, irrevocable y
totalmente sublicenciable para usar, reproducir, modificar, adaptar, publicar, traducir, crear trabajos derivados,
distribuir y mostrar dicho contenido en todo el mundo en cualquier medio. Usted otorga a SmartResQ/CorPatch<sup>®</sup>
y sus asociados y sublicenciatarios el derecho a utilizar el nombre que usted envíe en relación con dicho
contenido si así lo desean. </p>



<p>Usted declara y garantiza que posee o controla todos los derechos sobre el contenido que usted publica; que el
contenido es exacto; que el uso del contenido que usted suministra no viola esta política y no causará daño a
ninguna persona o entidad; y que indemnizará a SmartResQ/CorPatch<sup>®</sup> o sus asociados por todas las
reclamaciones resultantes del contenido que usted suministra. SmartResQ/CorPatch<sup>®</sup> tiene el derecho pero no
la obligación de supervisar y editar o eliminar cualquier actividad o contenido. SmartResQ/CorPatch<sup>®</sup> no se hace
responsable y no asume ninguna responsabilidad por cualquier contenido publicado por usted o cualquier
tercero. </p>


</div>
<!--This is the 4th section (white) - title: Haftungsausschlüsse und Garantien-->
<div class="white">
<h2>Renuncias y garantías </h2>

<h3>Información del producto – CorPatch<sup>®</sup> y CorPatch<sup>®</sup> Trainer </h3>

<p>Para el uso correcto de cualquier producto o servicio SmartResQ/CorPatch<sup>®</sup> debe seguir siempre las últimas guías
de usuario, instrucciones y descripciones. Puede encontrarlas en nuestro sitio web y en la descripción de cada
producto. </p>



<p>El producto CorPatch<sup>®</sup> está certificado como dispositivo médico. En caso de emergencia con una persona adulta
que sufra un paro cardíaco, el producto debe sacarse de su caja, colocarse y fijarse correctamente en el pecho
de la víctima. El producto puede recoger datos sobre compresión, profundidad, frecuencia y descompresión, y
podría transmitirlos por Bluetooth<sup>®</sup> a posibles dispositivos. Las aplicaciones gratuitas de los dispositivos pueden
guiar al transeúnte en el ejercicio de la RCP y mostrar la ratio de compresiones torácicas, la profundidad, la
descompresión y la frecuencia si están activadas y correctamente instaladas, es decir, Bluetooth<sup>®</sup>, smartphone,
aplicación, batería, etc. SmartResQ/CorPatch<sup>®</sup> no se hace responsable de factores externos, por ejemplo, señales
de comunicación interferentes, falta de cobertura de datos, falta de batería, hardware incorrecto o ajustes de
software, que puedan afectar a la experiencia del usuario o similares. </p>



<p>Del mismo modo, SmartResQ/CorPatch<sup>®</sup> no se hace responsable de ningún daño físico causado durante el uso
del producto en caso de que no se aplique o utilice de acuerdo con las instrucciones, por ejemplo, realizando las
compresiones torácicas de forma no ergonómica o teniendo una posición inadecuada de las manos.
SmartResQ/CorPatch<sup>®</sup> ha evaluado los riesgos técnicos como parte de la gestión de riesgos legalmente prescrita
para los productos sanitarios, pero no se ofrece ninguna garantía por errores que vayan más allá. En caso de mal
funcionamiento inesperado o de comportamiento no plausible de un sistema SmartResQ/CorPatch<sup>®</sup>, el usuario
deberá realizar la RCP manualmente. En este caso, SmartResQ/CorPatch<sup>®</sup> no se hace responsable, ya que está
fuera del control de SmartResQ/CorPatch<sup>®</sup>. </p>

<p>SmartResQ/CorPatch<sup>®</sup> controla los niveles de batería y el estado de salud, pero si la batería se agota, el producto
no funcionará. Es responsabilidad exclusiva del usuario asegurarse de que el dispositivo está actualizado, no sufre
daños y tiene batería suficiente para funcionar correctamente, lo que puede hacerse fácilmente realizando un
entrenamiento que valide su correcto funcionamiento. Recomendamos realizar un entrenamiento de 4 minutos
cada 3 meses con su CorPatch<sup>®</sup>. </p>



<p><b>¡Importante!</b> El producto CorPatch<sup>®</sup> debe utilizarse exclusivamente en una persona en una situación real y sólo si
la persona sufre un paro cardíaco. No está destinado a ser utilizado en personas que sufran, por ejemplo, un
derrame cerebral, un infarto de miocardio u otras enfermedades relacionadas con la salud que no sean un paro
cardíaco. El CorPatch<sup>®</sup> no está diseñado para utilizarse si la persona está tumbada sobre una superficie blanda,
por ejemplo, un sofá o una cama, ya que la respuesta de profundidad en tales condiciones podría ser imprecisa.
Las soluciones SmartResQ/CorPatch<sup>®</sup> no están diseñadas para su uso en un entorno en movimiento, incluyendo,
entre otros, una ambulancia aérea, marítima o de carretera. Si se utiliza durante el transporte del paciente o se
levanta/se retira del cuerpo durante la RCP, el dispositivo puede proporcionar una información inexacta. El
CorPatch<sup>®</sup> debe fijarse al tórax del paciente con el parche. Asegúrese de que el paciente esté tumbado sobre una
superficie firme, plana y que no se mueva y de que el CorPatch<sup>®</sup> esté sujeto al tórax con el parche. </p>



<p>El producto CorPatch<sup>®</sup> Trainer debe utilizarse exclusivamente en maniquíes u objetos similares en sesiones de
formación y nunca en una situación real en una persona real que sufra una parada cardiaca o cualquier otra
enfermedad relacionada con la salud. </p>



<p>Es posible entrenar su rendimiento en RCP en relación con las compresiones torácicas utilizando CorPatch<sup>®</sup>️ o
CorPatch<sup>®</sup>️ Trainer con regularidad. Le recomendamos que entrene con un maniquí, pero si no tiene acceso a un
maniquí, puede utilizar como sustituto una silla de oficina con suficiente retroceso o un sofá duro. Tenga en
cuenta la elasticidad y dureza del objeto con el que entrena. SmartResQ no recomienda el uso de objetos blandos,
incluyendo pero no limitado a almohadas o sofás blandos cuando se entrena, ya que la experiencia del usuario
no será la correcta. </p>


<p>Si no puede localizar un maniquí o un objeto de sustitución para el entrenamiento, considere la posibilidad de
entrenar la situación que se produce antes de iniciar las compresiones torácicas. Esto podría consistir en seguir
la información proporcionada por la aplicación gratuita sobre la identificación de una parada cardiaca y la llamada
a los servicios de emergencia y desembalar el CorPatch<sup>®</sup> del colgador de llaves. En este caso, estará preparado
para aplicar rápidamente el CorPatch<sup>®</sup> en caso de presenciar una parada cardiaca. </p>



<p>No utilice ningún sistema SmartResQ/CorPatch<sup>®</sup>️ para entrenar o “por diversión” con personas (vivas o muertas),
mascotas o cualquier otro ser humano o criatura viva. </p>



<h3>Descargo de responsabilidad sobre productos, sitios web y aplicaciones </h3>

<p>SmartResQ/CorPatch<sup>®</sup> no hace representaciones o garantías y no será responsable de la competencia de
cualquier persona que pueda recibir información educativa y / o formación médica proporcionada a través de o
basado en el sistema o para el ejercicio de sus habilidades por dicha persona después de la finalización de
cualquier formación, cursos o planes de estudio utilizando el sistema. SmartResQ/CorPatch<sup>®</sup> no garantiza que
cualquier persona que reciba información educativa y/o formación médica del sistema alcanzará cualquier nivel
de habilidad particular o la competencia necesaria para calificar para cualquier licencia, certificados o
calificaciones emitidas por cualquier agencia reguladora o autoridad gubernamental. </p>



<p>SmartResQ/CorPatch<sup>®</sup> y sus asociados intentan ser lo más precisos posible. Sin embargo, SmartResQ/CorPatch<sup>®</sup>
no declara ni garantiza que el sistema y la información, los productos y la formación que en él se ofrecen (a)
estén siempre disponibles o disponibles en absoluto; o (b) estén libres de errores, sean completos, exactos,
verdaderos, actualizados y/o no engañosos. Al utilizar el sistema, usted sabe y es consciente de que renuncia a
cualquier reclamación que pueda tener contra SmartResQ/CorPatch<sup>®</sup> por confiar en cualquier información o
formación presentada a través del sistema. </p>



<p>Nuestros productos no están destinados al uso infantil. Los productos son pequeños y coloridos y pueden
confundirse con juguetes, pero los productos SmartResQ/CorPatch<sup>®</sup> no son juguetes. Recomendamos no dejar
los productos al aire libre y al alcance de los niños. SmartResQ/CorPatch<sup>®</sup> no asume ninguna responsabilidad en
relación con el uso de los productos por parte de los niños. Cualquier uso de CorPatch<sup>®</sup> por parte de niños o
adolescentes debe ser permitido y supervisado por adultos responsables, por ejemplo, padres, familiares o
profesores. </p>



<p>Renuncia de garantías y limitación de responsabilidad, este sitio es proporcionado por SmartResQ/CorPatch<sup>®</sup> “tal
cual” y “según disponibilidad”. SmartResQ/CorPatch<sup>®</sup> no hace representaciones o garantías de ningún tipo,
expresa o implícita, en cuanto a la operación de este sitio o la información, contenido, materiales o productos
incluidos en este sitio. Usted acepta expresamente que el uso de este sitio es bajo su propio riesgo
<br>
En la medida permitida por la ley aplicable, SmartResQ/CorPatch<sup>®</sup> renuncia a todas las garantías, expresas o
implícitas, incluyendo, pero no limitado a, las garantías implícitas de comerciabilidad e idoneidad para un
propósito particular. SmartResQ/CorPatch<sup>®</sup> no garantiza que este sitio, sus servidores o el correo electrónico
enviado desde SmartResQ/CorPatch<sup>®</sup> estén libres de virus/spam u otros componentes dañinos. </p>



<p>SmartResQ/CorPatch<sup>®</sup> no será responsable de ningún daño de cualquier tipo que surja del uso de este sitio o sus
productos, incluyendo, pero no limitado a daños directos, indirectos, incidentales, punitivos y consecuentes.
<br>
La legislación de algunos estados no permite la limitación de las garantías implícitas ni la exclusión o limitación
de determinados daños. Si estas leyes son de aplicación en su caso, es posible que algunas o todas las renuncias,
exclusiones o limitaciones anteriores no se apliquen en su caso, y podría tener derechos adicionales. <p>



<p>La información presentada en este sitio web no debe interpretarse como asesoramiento profesional. Antes de
tomar una decisión, consulte siempre a asesores profesionales que conozcan su situación concreta. </p>



<p>El sitio web puede contener enlaces que conducen a sitios web mantenidos por personas u organizaciones sobre
las que SmartResQ/CorPatch<sup>®</sup> no tiene ningún control. SmartResQ/CorPatch<sup>®</sup> no hace ninguna representación y
no ofrece ninguna garantía con respecto a la exactitud o cualquier otro aspecto de la información contenida en
dichos sitios web. </p>



<p>La responsabilidad de cualquier opinión, consejo, declaración u otra información contenida en cualquier artículo
o texto de este sitio web es exclusivamente del autor y no refleja necesariamente las opiniones y políticas de
SmartResQ/CorPatch<sup>®</sup>. </p>



<h3>Aviso legal</h3>

<p>Al comprar, obtener licencias, ver, utilizar y/o acceder a nuestro(s) sitio(s) web, productos y aplicaciones, usted
reconoce y acepta que: </p>
<ol>
<li>Los sistemas proporcionados por SmartResQ/CorPatch<sup>®</sup> son productos específicos que deben
utilizarse únicamente para el uso previsto indicado en el manual del producto. Lea atentamente las
instrucciones de uso y los manuales, y asegúrese de estar familiarizado con nuestros productos
médicos antes de utilizarlos.  </li>

<li>Los sistemas proporcionados por SmartResQ/CorPatch<sup>®</sup> son productos y herramientas educativos y
de formación médica específicos, no están certificados como productos sanitarios a menos que se
indique explícitamente, no están destinados a ningún uso clínico o de diagnóstico y están pensados
para ser utilizados únicamente con fines de formación médica y mejora del rendimiento. </li>

<li>En todo momento, usted utilizará y accederá al sistema únicamente en relación con dichos fines de
formación médica y mejora del rendimiento; en cumplimiento de todas las leyes y normativas
aplicables; y de acuerdo con cualquier documentación de usuario, manual de instrucciones, guía y/o
requisito que le proporcionemos por vía electrónica o en persona. </li>

<li>En ningún momento cualquier sistema SmartResQ/CorPatch<sup>®</sup> por sí solo puede diagnosticar, tratar o
curar la condición de un ser humano o en una situación de salvar la vida; apoyar las decisiones
médicas profesionales, diagnósticos o tratamientos o reemplazar cualquier diagnóstico,
recomendación, consejo, tratamiento o decisión de un médico debidamente capacitado y licenciado. </li>

<li>La asociación de un producto con cualquier daño o resultado en un paciente no significa que el
producto haya causado el daño o el resultado. </li>

<li>SmartResQ/CorPatch<sup>®</sup> no se hace responsable de los daños resultantes de un uso no razonable de
nuestros productos o de un uso que exceda el uso previsto del producto. </li>


</ol>
<h3>Información de rendimiento </h3>

<p>La información aquí contenida se presenta únicamente como guía para la aplicación de los productos
SmartResQ/CorPatch<sup>®</sup>. SmartResQ/CorPatch<sup>®</sup> trabaja continuamente para mejorar la calidad y fiabilidad de sus
productos. No obstante, nuestros dispositivos pueden funcionar mal o fallar debido a su inherente sensibilidad
eléctrica y vulnerabilidad al estrés físico y a señales de comunicación adversas. Es responsabilidad del comprador,
al utilizar productos SmartResQ/CorPatch<sup>®</sup>, observar las normas de seguridad y pruebas y evitar situaciones en
las que un mal funcionamiento o fallo pueda provocar lesiones corporales o daños materiales. </p>



<h3>Garantía estándar </h3>

<p>La garantía limitada estándar de SmartResQ/CorPatch<sup>®</sup> está condicionada al uso adecuado de los productos,
sitios web y aplicaciones.
<br>
Esta Garantía se limita al primer comprador del producto únicamente y sólo si el producto se compra a un
distribuidor autorizado de SmartResQ/CorPatch<sup>®</sup>. Los fabricantes, proveedores o editores, distintos de
SmartResQ/CorPatch<sup>®</sup>, pueden proporcionarle sus propias garantías – póngase en contacto con ellos para
obtener más información. </p>



<p><u>La garantía no cubre: </u></p>
<ol>
<li>defectos o daños resultantes de accidentes, uso indebido, uso anormal, condiciones anormales,
almacenamiento inadecuado, exposición a líquidos, humedad, arena o suciedad, negligencia o
tensión física, eléctrica o electromecánica inusual, </li>

<li>defectos o daños resultantes de una fuerza excesiva o del uso de objetos metálicos, </li>

<li>equipos que tengan el número de producción o el código de datos de mejora borrados, desfigurados,
dañados, alterados o ilegibles, </li>

<li>desgaste ordinario o envejecimiento normal del producto, </li>

<li>arañazos, abolladuras y daños estéticos, a menos que el fallo se haya producido debido a un defecto
en los materiales o en la mano de obra, </li>

<li>defectos o daños resultantes del uso de los productos en conjunción o conexión con accesorios,
productos o equipos auxiliares/periféricos no suministrados o aprobados por SmartResQ/CorPatch<sup>®</sup>, </li>

<li>defectos o daños resultantes de pruebas, operación, mantenimiento, instalación, servicio o ajuste
inadecuados no suministrados o aprobados por SmartResQ/CorPatch<sup>®</sup>, </li>

<li>daños causados por el funcionamiento del producto SmartResQ/CorPatch<sup>®</sup> fuera de las directrices
publicadas, </li>

<li>demostración/instalación del producto adquirido, </li>

<li> Los defectos o daños resultantes de causas externas como colisión con un objeto, incendio,
inundación, suciedad, vendaval, rayo, terremoto, exposición a condiciones climatológicas, robo,
fusible fundido o uso inadecuado de cualquier fuente eléctrica, </li>

<li>defectos o daños resultantes de la transmisión o virus, u otros problemas de software introducidos en
los Productos, </li>

<li>si las baterías se cargan con cargadores que no sean compatibles con CorPatch<sup>®</sup> Trainer, </li>

<li>alguno de los precintos de la caja de la batería o de las celdas está roto o presenta indicios de
manipulación, </li>

<li>productos reparados por, utilizados o comprados a otra empresa que no sea SmartResQ/CorPatch<sup>®</sup>, </li>

<li>si SmartResQ/CorPatch<sup>®</sup> recibe información de las autoridades públicas pertinentes de que el
producto ha sido robado o si no puede desactivar el código de acceso u otras medidas de seguridad
diseñadas para evitar el acceso no autorizado al producto o no puede demostrar que es el usuario
autorizado del producto, </li>

<li>si los productos se utilizan fuera de las condiciones especificadas en los manuales, por ejemplo, rango
de temperatura, presión y humedad. </li>


</ol>
<h3>Baterías y cargadores </h3>

<p>Los productos SmartResQ/CorPatch<sup>®</sup> contienen pilas no sustituibles (CorPatch<sup>®</sup>) o pilas recargables (CorPatch<sup>®</sup>
Trainer). Los tipos de pilas utilizados en nuestros productos se describen en cada producto individual.
SmartResQ/CorPatch<sup>®</sup> no asume ninguna responsabilidad si las pilas recargables no se manipulan correctamente
de acuerdo con el manual de uso. </p>



<p>En relación con la venta de aparatos que contienen pilas, tenemos la obligación de poner en su conocimiento lo
siguiente:
<br>
Como usuario final, tiene la obligación legal de desecharlas correctamente. El símbolo del cubo de basura
tachado significa que la pila no puede desecharse con la basura doméstica. </p>


</div>
    <!--This is the 5th section (beige) - title: Bestellung -->
	<div class="beige">
	<h2>Pedidos </h2>

<p>La tienda web Corpatch.com está abierta las 24 horas del día y puede comprar prácticamente en cualquier
momento. Sin embargo, es posible que cerremos la tienda por mantenimiento. La compra en grandes volúmenes
puede realizarse directamente con SmartResQ/CorPatch<sup>®</sup>. </p>



<p>SmartResQ/CorPatch<sup>®</sup> no ofrece productos para la venta a menores. Los productos destinados a niños sólo
pueden ser comprados por adultos. Para comprar en SmartResQ/CorPatch<sup>®</sup> debe tener al menos 18 años y
poseer una tarjeta de crédito válida u otro medio de pago que aceptemos. </p>



<p>La presentación de los productos en la tienda en línea no es una oferta jurídicamente vinculante, sino un catálogo
en línea no vinculante. Cuando esté listo para comprar, seleccione los artículos que desee e introdúzcalos en la
 “cesta de la compra”. Puede modificar el contenido de la cesta hasta el momento de hacer el pedido. Cualquier
pago adicional, como gastos de envío o de tarjeta de débito, se calculará inmediatamente antes de que pague. </p>



<p>Cuando esté listo para realizar el pedido, haga clic en “Realizar pedido” e introduzca la información pertinente.
Puede modificar el contenido de la cesta de la compra hasta que confirme su compra haciendo clic en el botón
“Pagar”. A partir de ese momento, realizará un pedido vinculante de los productos contenidos en la cesta de la
compra que ya no podrá modificar.
<br>
SmartResQ/CorPatch<sup>®</sup> puede aceptar el pedido enviando una confirmación de pedido por correo electrónico o
entregando la mercancía dentro del plazo de entrega. </p>



<p>Algunos países pueden impedir el uso y la propiedad de nuestros productos. Usted es el único responsable de
averiguar si este producto puede importarse o utilizarse legalmente en su país. Le enviaremos los productos que
pida y no podemos aceptar ninguna responsabilidad por cuestiones aduaneras o cualquier implicación de su
propiedad o uso de este dispositivo. </p>

     </div>

    <!--This is the 6th section (white) - title: Zahlung-->
    <div class="white">
<h2>Pago </h2>

<h3>Nuestro(s) sitio(s) web, aplicación(es) y tienda(s) web </h3>

<p>El uso de nuestro(s) sitio(s) web y aplicación(es) es gratuito siempre que se acepten y cumplan nuestras políticas
legales. Tenga en cuenta que las compras de nuestros productos pueden estar disponibles en la(s) tienda(s) web
de nuestro(s) sitio(s) web y en la(s) aplicación(es). </p>



<h3>Nuestros productos </h3>

<p>SmartResQ/CorPatch<sup>®</sup> utiliza QuickPay como pasarela de pago. QuickPay está certificada por el Consejo de
Normas de Seguridad de la Industria de Tarjetas de Pago (PCI) siguiendo la última versión del Nivel 1 de la Norma
de Seguridad de Datos (DSS) de la PCI, que incluye: (a) un informe anual – “Report on Compliance” (ROC) realizado
por un Qualified Security Assessor (QSA), (b) escaneos trimestrales de la red realizados por Approved Scan
Vendor (ASV), y (c) un gran número de normas y directrices para el flujo de trabajo y el tratamiento de datos. </p>



<p>Aceptamos pagos por: </p>
<ul>
<li>VISA </li>

<li>Dankort </li>

<li>MasterCard </li>

<li>MobilePay </li>

<li>PayPal </li>

<li>Apple Pay </li>

<li>Maestro </li>
</ul>


<p>Los pagos se deducirán de su cuenta en el momento del envío de la mercancía. Todos los importes se expresan
en euros y el impuesto sobre el valor añadido legal ya está incluido en todos los precios mencionados. Todos los
titulares de tarjetas de crédito/débito están sujetos a comprobaciones de validación y autorización por parte del
emisor de la tarjeta o del proveedor de pago. SmartResQ/CorPatch<sup>®</sup> no se hace responsable en caso de que el
proveedor de su tarjeta de pago se niegue a autorizar los pagos. </p>


<p>Utilizamos el protocolo SSL (Secure Socket Layer) para cifrar la información de las tarjetas de crédito. Esto
garantiza que otros no puedan interceptar el número de tarjeta de crédito u otra información durante la
transacción con nuestro proveedor. </p>



<p>Antes de finalizar el contrato, el cliente debe revisar y aceptar los gastos de envío y logística añadidos al precio
de compra, ya que estos gastos corren a su cargo. Después de realizar un pedido, recibirá un correo electrónico
nuestro acusando recibo de su pedido. Tenga en cuenta que esto no significa que su pedido haya sido aceptado.
Su pedido constituye una oferta a SmartResQ/CorPatch<sup>®</sup> para comprar un producto (y SmartResQ/CorPatch<sup>®</sup> se
reserva el derecho de rechazar pedidos de productos). No existirá ningún contrato en relación con los productos
hasta que le hayamos confirmado por correo electrónico que el/los producto(s) ha(n) sido enviado(s). Nuestra
aceptación a su oferta se considerará completa y el contrato entre nosotros se formará cuando le enviemos un
correo de confirmación de envío. </p>



<p>Los gastos de envío se indican siempre en relación con cada pedido individual. </p>



<p>SmartResQ/CorPatch<sup>®</sup> conserva la propiedad del artículo adquirido hasta que el importe de la factura haya sido
abonado en su totalidad por el cliente y “retirado” automáticamente justo antes del envío. </p>



<p>Los clientes sólo tienen derecho a compensación si sus contrademandas han sido legalmente establecidas o son
indiscutibles o reconocidas por SmartResQ/CorPatch<sup>®</sup>. Además, los clientes solo tienen derecho de retención si
y en la medida en que su contrademanda se base en la misma relación contractual. </p>



<p>Si el cliente se retrasa en el cumplimiento de sus obligaciones de pago con nosotros, todas las reclamaciones
existentes vencerán inmediatamente. </p>



<h3>Recargo de tasas </h3>

<p>A partir del 1 de enero de 2018, se han modificado las normas relativas a los recargos. Por lo tanto, ya no es legal
cobrar recargos en los pagos con tarjetas de consumidores si son emitidas por bancos o emisores de tarjetas de
la UE. Esto se aplica tanto a las tarjetas de débito como a las de crédito. Las tarjetas de consumidores son tarjetas
emitidas a un consumidor privado. </p>

<p>Sin embargo, si la tarjeta es una tarjeta de empresa o una tarjeta de consumidor emitida fuera de la UE, la
comisión por transacción tendrá un recargo. Esto significa que el titular de la tarjeta paga automáticamente la
comisión por transacción.
<br>
La comisión no será superior a lo que cobre la entidad adquirente por SmartResQ/CorPatch<sup>®</sup>. La tasa se mostrará
claramente como un cargo separado en la ventana de pago. </p>

     </div>

    <!--This is the 7th section (beige) - title: Lieferung-->
    <div class="beige">
<h2>Entrega </h2>

<p>Nos esforzamos por enviar los pedidos de día laborable a día laborable y utilizamos una agencia de transporte
de confianza internacional. Encontrará el precio total de su compra, incluida la entrega, en la caja antes de
aceptar su pedido final. </p>



<p>Si el cliente no acepta la mercancía, SmartResQ/CorPatch<sup>®</sup> puede rescindir el contrato o recibir una
indemnización por incumplimiento tras un periodo de dos semanas para cubrir los gastos de manipulación y
envío. </p>



<p>Si el cliente ha facilitado datos incorrectos sobre la dirección de entrega, es posible recoger el paquete en la
tienda de paquetería indicada en nuestra tienda web; de lo contrario, el paquete se pierde. </p>



<p>El cliente nunca recibirá entregas parciales a menos que SmartResQ/CorPatch<sup>®</sup> lo indique explícitamente. </p>



<h3>Riesgo de pérdida </h3>

<p>La propiedad del riesgo del producto se transfiere al comprador cuando el producto se pone a su disposición
según este acuerdo. Si ha transcurrido el plazo de entrega y el comprador no recibe un producto puesto a su
disposición o a su disposición según el acuerdo, el comprador asume el riesgo de pérdida o daño causado por las
características del propio producto. </p>

     </div>

    <!--This is the 8th section (white) - title: Stornierung und Rückgabe-->
    <div class="white">
<h2>Anulación y devoluciones </h2>

<p>Cuando usted compra con SmartResQ/CorPatch<sup>®</sup> en línea o fuera de línea, tiene 14 días para arrepentirse y
cancelar donde puede informarnos que ha cambiado de opinión y devolvernos el artículo en el mismo estado en
que lo recibió.
<br>
Sólo aceptamos devoluciones de artículos sin usar en su embalaje original, precintado y sin daños, y deben estar
correctamente embalados para su envío; de lo contrario, los productos se considerarán usados y no se aplicarán
reembolsos parciales. El derecho de desistimiento sólo se aplica a los productos desprecintados en el momento
de la recepción. </p>



<h3>Motivos aceptables para devolver un producto </h3>
<ul>
<li>Uso del derecho de cancelación de 14 días </li>

<li>Producto no conforme a la descripción (garantía) </li>

<li>Producto defectuoso </li>
</ul>


<p>Las condiciones de devolución de CorPatch<sup>®</sup> siguen las normas estándar de la UE. </p>





<p>Si devuelve el producto, conserve el embalaje original y no lo dañe, pegue ni escriba en él. Obtén y utiliza un
embalaje de devolución específico, por ejemplo, una caja de cartón. </p>



<p>En el caso de CorPatch<sup>®</sup> Trainer, las condiciones de retirada de este producto siguen las normas estándar de la
UE. </p>



<p>Para ejercer el derecho de cancelación, deberá notificárnoslo en un plazo de 14 días a partir de la recepción de
los artículos. Las solicitudes de cancelación se enviarán por correo electrónico a info@corpatch.com, indicando
claramente que desea hacer uso de sus derechos de cancelación y el motivo. </p>



<p>Esperamos que devuelva los artículos lo antes posible después de haber notificado la cancelación y, a más tardar,
14 días después de habernos informado por correo electrónico. </p>



<p>Podemos rechazar el reembolso hasta que haya devuelto los artículos o hasta que demuestre que los ha
devuelto. Para este reembolso utilizaremos el mismo método de pago que se utilizó en la transacción original. </p>



<h3>Razones no aceptables para devolver un producto </h3>
<ul>
<li>Cambio de opinión tras el derecho de cancelación de 14 días. </li>

<li>Si el producto ha sido activado. </li>

<li>Si el producto se utiliza o daña de cualquier otra forma. </li>

<li>Si el software o la aplicación gratuita se descargan, conectan, instalan o combinan de cualquier otro
modo con los productos físicos. </li>
</ul>


<h3>Cómo volver </h3>

<p>Sólo aceptamos devoluciones de artículos sin abrir, en su embalaje original y sin daños, y deben estar
correctamente embalados para su envío; de lo contrario, los productos se considerarán usados y no se
aplicarán reembolsos. </p>



<p><u>Salvo que se indique lo contrario, las devoluciones deben enviarse a: </u>
<br>
SmartResQ ApS (CorPatch<sup>®</sup>)
<br>
Lundevej 26
<br>
DK-5700 Svendborg
<br>
Dinamarca
</p>


<p><b>¡IMPORTANTE!</b> Usted es el único responsable de la calidad del embalaje y de los artículos hasta que los
recibamos. Conserve el recibo postal, incluida la información sobre los gastos de envío y, si procede, el número
de seguimiento y localización. No cubrimos los gastos de envío de las devoluciones y no aceptamos paquetes
enviados “contra reembolso” o similares. </p>

</div>

<!--This is the 9th section (beige) - title: Rückerstattungen-->
<div class="beige">
<h2>Reembolsos </h2>

<p>SmartResQ/CorPatch<sup>®</sup> está obligado a reparar, sustituir, rebajar el precio o reembolsar el importe íntegro si el
artículo resulta defectuoso en un plazo de 2 años a partir de la fecha de compra.
<br>
El cliente no tiene derecho a devolución si el problema es menor, como arañazos en el artículo o similares.
<br>
Una vez que SmartResQ/CorPatch<sup>®</sup> recibe el artículo del cliente, se inicia el reembolso. El valor del importe del
reembolso depende del estado de los productos cuando se reciben en SmartResQ/CorPatch<sup>®</sup>. </p>



<p>La forma en que se procesa el reembolso del cliente depende del método de pago original. Si el cliente pagó con
tarjeta de crédito o débito, el reembolso se enviará al banco emisor de la tarjeta en un plazo de 5 días laborables
a partir de la recepción del artículo devuelto o de la solicitud de cancelación. Póngase en contacto con el banco
emisor de la tarjeta si tiene preguntas sobre cuándo se abonará el importe en su cuenta. </p>
</div>

<!--This is the 10th section (white) - title: Verarbeitung von persönlichen Informationen-->
<div class="white">

<h2>Tratamiento de datos personales </h2>


<p>En SmartResQ/CorPatch<sup>®</sup> valoramos sus datos personales a través de nuestro esfuerzo de cumplimiento de datos
en tres pasos: a. mantener un mapa detallado de nuestro flujo de datos, b. emprender una evaluación legal
basada en el flujo de datos para, c. implementar las medidas de seguridad necesarias para mantener sus datos
seguros. </p>



<p>Para poder utilizar nuestra tienda virtual, debe facilitar como mínimo la siguiente información: </p>
<ul>
<li>Nombre </li>

<li>Dirección </li>

<li>Correo electrónico </li>

<li>Número de teléfono </li>
</ul>


<p>La recopilación de información personal de los clientes tiene lugar en el marco de la legislación vigente y de la
legislación del Reglamento General de Protección de la UE (GDPR). </p>



<p>Si desea saber más sobre el tratamiento que hacemos de sus datos personales, lea nuestra política de privacidad. </p>

</div>

<!--this is the 11th section (beige) - title: Beschwerden-->
<div class="beige">
<h2>Quejas </h2>

<h3>Cómo presentar una reclamación </h3>

<p>Si hay algún problema con el producto, puede solicitar la reparación o sustitución del producto defectuoso, el
reembolso o una reducción del precio, dependiendo de la situación concreta.
<br>
Por supuesto, es requisito que la reclamación esté justificada y que el defecto no se deba a un uso incorrecto del
producto o a otra conducta incorrecta. </p>



<p>Le recomendamos que reclame lo antes posible y en el plazo de una semana desde que se descubra el defecto.
<br>
Si tiene alguna pregunta, comentario o queja, no dude en ponerse en contacto con nosotros por correo
electrónico: <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

<h3>Reclamar ante otras entidades de la UE </h3>

<p>Visite el sitio web oficial de la Unión Europea para reclamar ante otras entidades de la UE. Encuentre información <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">aquí</a>.</p>

</div>

<!--this is the 12th section (white) - title: Änderungen an diesem Dokument-->
<div class="white">
<h2>Modificaciones de este documento </h2>

<h3>En sitio(s) web, aplicación(es) y políticas </h3>

<p>SmartResQ/CorPatch<sup>®</sup> se reserva el derecho de realizar cambios, eliminar, modificar o complementar nuestro(s)
sitio(s) web, aplicación(es), políticas y documentos en cualquier momento y por cualquier motivo sin previo aviso
a nadie.
<br>
Si alguna de estas condiciones se considerara inválida, nula o inaplicable por cualquier motivo, dicha condición
se considerará separable y no afectará a la validez y aplicabilidad de las condiciones restantes. </p>



<p>Si cambiamos nuestras políticas, publicaremos las políticas revisadas en línea con una fecha de revisión
actualizada. Le recomendamos que revise las políticas con regularidad. Si introducimos cambios sustanciales en
nuestras políticas que modifiquen significativamente nuestras prácticas, también podremos notificárselo de
otras formas, por ejemplo, enviándole un correo electrónico o publicando un aviso en nuestro sitio web y/o en
las redes sociales antes de que los cambios entren en vigor. </p>



<h3>Relacionar una compra específica </h3>

<p>Al comprar un producto, se le pedirá que acepte una versión de ciertos documentos tal y como son en ese
momento exacto - esa versión no se cambiará después de ese momento y dictará los términos de nuestra relación
con usted con respecto a esa compra exacta. </p>

</div>

<!--this is the 13th section (beige)- title: Recht und Gerichtsbarkeit-->
<div class="beige">
<h2>Derecho y jurisdicción </h2>

<h3>Las leyes de Dinamarca y el Tribunal de Distrito de Svendborg </h3>



<p>SmartResQ/CorPatch<sup>®</sup> aplica la ley danesa y el lugar para cualquier disputa legal, pero no CISG.
<br>
Cualquier disputa relacionada de alguna manera con su visita a SmartResQ/CorPatch<sup>®</sup> o a los productos que
usted compra a través de SmartResQ/CorPatch<sup>®</sup> se someterá a confidencial en Dinamarca, salvo que, en la
medida en que usted ha violado o amenazado con violar los derechos de propiedad intelectual
SmartResQ/CorPatch<sup>®</sup>, SmartResQ/CorPatch<sup>®</sup> podrá solicitar medidas cautelares u otras medidas apropiadas en
cualquier país y usted acepta la jurisdicción exclusiva y el lugar en dichos tribunales. </p>



<p>Si se incumple el acuerdo de “Términos y Condiciones”, se emprenderán acciones legales y se podrá presentar
una demanda ante los tribunales. </p>



<p>Los litigios entre nosotros y cualquier consumidor se someterán al Tribunal de Distrito de Svendborg,
Christiansvej 41, 5700 Svendborg, Dinamarca. </p>

</div>

<!--this is the 14th section (white) title: Kontaktinformationen-->
<div class="white">
<h2>Información de contacto </h2>

<p>Gracias por leer los “Términos y Condiciones” de SmartResQ/CorPatch<sup>®</sup>.
<br>
Si tiene alguna pregunta, comentario o queja, no dude en ponerse en contacto con nosotros. </p>



<p>Nuestra empresa se encuentra en: Lundevej 26, 5700 Svendborg, Dinamarca </p>



<p>Puede encontrarnos en el Registro Mercantil Central con el número de IVA DK 38674102 </p>



<p>Puede llamarnos al número de teléfono +45 62 200 100 </p>



<p>o </p>



<p>envíenos un correo electrónico a: <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>



<p><b>© SmartResQ ApS – Todos los derechos reservados
<br>
Dinamarca, Versión 2.1 – Publicada el 2024.05.25 </b></p>

</div>

<!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS --> <!--END OF TERMS AND CONDITIONS -->



<!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY --> <!-- PRIVACY POLICY -->

<!--This is the 15th section (beige) - title: Datenschutzrichtlinie -->
<div class="beige">

<h1 id="policy">Política de privacidad de SmartResQ / CorPatch<sup>®</sup></h1>
 <!--This is the 16th section (beige) = joined together with the 15th section - title: Wir behandeln deine Daten in Übereinstimmung mit der DSGVO-->
<h2>Tratamos sus datos de conformidad con el GDPR </h2>

<p>SmartResQ/CorPatch<sup>®</sup> respeta su privacidad. Esta declaración de privacidad describe sus derechos de privacidad y nuestro compromiso para asegurar su información personal. </p>

<p>Si tiene alguna pregunta sobre el tratamiento de sus datos personales, póngase en contacto con nosotros. El responsable del tratamiento de datos es: </p>

<p><b>Empresa:</b> SmartResQ ApS (CorPatch<sup>®</sup>) </p>

<p><b>Dirección: </b>
<br>
Lundevej 26
<br>
5700 Svendborg
<br>
Dinamarca </p>



<p><b>Nº CVR:</b> 38674102 </p>

<p><b>Teléfono:</b> +45 62 200 100 </p>

<p><b>Correo electrónico:</b> <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>SmartResQ/CorPatch<sup>®</sup> es una empresa danesa/europea con entidades jurídicas, procesos empresariales, estructuras de gestión y sistemas técnicos transfronterizos. SmartResQ/CorPatch<sup>®</sup> ofrece productos, software y servicios a empresas públicas y privadas de Europa </p>

<p>La sede central se encuentra en Dinamarca y SmartResQ/CorPatch<sup>®</sup> está sujeta a la legislación europea de protección de datos, incluido el Reglamento General de Protección de Datos (RGPD). Todas las decisiones importantes en SmartResQ/CorPatch<sup>®</sup> relativas a la protección de datos personales se toman a nivel directivo bajo la supervisión del responsable de protección de datos. </p>

<p>Esta declaración de privacidad está disponible en nuestros sitios web y en nuestras aplicaciones. </p>

<p>No utilice las páginas, aplicaciones o servicios de SmartResQ/CorPatch<sup>®</sup> si no está de acuerdo con la forma en que procesamos los datos personales en virtud de esta declaración de privacidad. </p>

</div>

<!--This is the 17th section (white) - title: Die Art der persönlichen Daten, die wir sammeln -->
<div class="white">

<h2>Tipo de información personal que recopilamos   </h2>

<p>Cuando un responsable del tratamiento determine los fines y medios del tratamiento de sus datos personales, actuará como responsable del tratamiento. Esto incluye situaciones en las que SmartResQ/CorPatch<sup>®</sup> recopila datos personales en el contexto de que usted es un demandante de empleo, un representante de un cliente o un cliente potencial, o cuando usted es un usuario de software. </p>

<p>SmartResQ procesa información personal para una variedad de propósitos, dependiendo de la relación que tengamos con usted. </p>

<p>Podemos tratar: </p>
<ol>
<li>información básica de contacto, como nombre, dirección, número de teléfono (móvil y/o fijo) y correo electrónico, </li>

<li>información laboral como empleador, cargo, puesto, incluidas preferencias e intereses en un contexto profesional, </li>

<li>opiniones, comentarios o preguntas sobre SmartResQ/CorPatch<sup>®</sup> o nuestros productos y servicios, </li>

<li>fotos o vídeos grabados en nuestras instalaciones, </li>

<li>los contenidos que has subido, como fotos, vídeos y actuaciones a lo largo del tiempo, </li>

<li>información exclusiva del usuario, como identificación de inicio de sesión, nombre de usuario, contraseña y pregunta de seguridad, </li>

<li>información financiera, cuando usted acepte que utilicemos su información, por ejemplo, para almacenar los datos de su tarjeta de pago, </li>

<li>información de tráfico proporcionada por su navegador web, como el tipo de navegador, el dispositivo, el idioma y la dirección del sitio web del que procede y otra información de tráfico, incluida la dirección IP, </li>

<li>el comportamiento de los clics y la actividad en SmartResQ/CorPatch<sup>®</sup> ID y en nuestros productos y servicios, </li>

<li>comportamiento del correo electrónico, como los mensajes de correo electrónico de SmartResQ/CorPatch<sup>®</sup> que abre, cuándo y cómo, </li>

<li>otra información personal contenida en su perfil que haya colocado libremente en redes sociales de terceros, como LinkedIn, Facebook, Google, etc., </li>

<li>información utilizada con fines científicos para mejorar la supervivencia tras un paro cardíaco recopilada a través de nuestros sitios web y aplicaciones, </li>

<li>información sobre los usuarios para suministrar productos que cumplan los requisitos de calidad y seguridad, prestar servicios a los usuarios y mantener y mejorar nuestras ofertas, </li>

<li>información sobre los solicitantes de empleo para gestionar las solicitudes de empleo, comunicar futuras ofertas de empleo y mantener y mejorar nuestros procesos de contratación, </li>

<li>información sobre las personas que se han inscrito para recibir boletines y otros materiales con el fin de entregar los materiales y mantener y mejorar nuestras ofertas, </li>

<li>información de cookies para ofrecer publicidad personalizada en redes sociales y sitios web. </li>
</ol>

</div>

<!--This is the 18th section (beige) - tile: Daten, die auf der CorPatch<sup>®</sup> Services Plattform und in den Apps gesammelt und verarbeitet werden -->
<div class="beige">
<h2>Datos recogidos y tratados en la plataforma CorPatch<sup>®</sup> Services y sus aplicaciones </h2>

<p>SmartResQ maneja, recopila y almacena los siguientes datos personales cuando usted utiliza la plataforma o las aplicaciones de CorPatch<sup>®</sup> Services. </p>




<h3>Todos los usuarios (administrador del centro, formador, alumno/usuario final)</h3>
<ul>
<li>Nombre (si se ha introducido) </li>

<li>Apellidos (si se han introducido) </li>

<li>Apodos (si se han introducido) </li>

<li>Dirección de correo electrónico (obligatorio)</li>

<li>Idioma de comunicación preferido (obligatorio) </li>

<li>Contraseña hash (obligatorio) </li>

<li>Si se ha validado la dirección de correo electrónico (obligatorio) </li>
</ul>


<h3>Además, para alumnos/usuarios finales (obligatorio) </h3>

<p>Datos sobre el teléfono móvil utilizado: </p>


<p>Sistema operativo (Android/iOS): </p>
<ul>
<li>Versión del sistema operativo (por ejemplo, 9) </li>

<li>Fabricante (por ejemplo, Samsung) </li>

<li>Modelo (por ejemplo, SM-T518) </li>

<li>Versión de la aplicación (por ejemplo, 1.2.4) </li>

<li>Hora de la última actividad en primer plano de la aplicación </li>

<li>Hora de la última actividad en segundo plano de la aplicación </li>

</ul>

<p>Datos sobre el CorPatch<sup>®</sup> (CPS) utilizado: </p>
<ul>
<li>Número de serie / Dirección MAC </li>

<li>Versión del firmware </li>

<li>Nombre del modelo (por ejemplo, CPS_01) </li>

<li>Fabricante (actualmente siempre SRQ) </li>

<li>Nombre (actualmente siempre CorPatch<sup>®</sup>) </li>

<li>Estado de la batería </li>

<li>Defectos </li>
</ul>


<p>Datos de incorporación de usuarios: </p>
<ul>
<li>Tutoría finalizada (sí/no) </li>

<li>Condiciones de uso aceptadas (sí/no) </li>

<li>Autoevaluación completada (sí/no) </li>

<li>Pruebas de formación realizadas (sí/no) </li>

<li>Primer inicio de sesión con éxito (sí/no) </li>

<li>Se conectó un SPI (sí/no) </li>

</ul>

<p>Datos recogidos a través de la formación: </p>
<ul>
<li>Fecha, hora y duración de la formación </li>

<li>Resultado de la formación </li>

<li>Tipo de formación o configuración de la formación </li>

<li>En caso de formación en un instituto, información adicional sobre el curso, el formador y el centro </li>

</ul>


<h3>Registros del servidor </h3>

<p>Los siguientes datos se almacenan en los registros del servidor web: </p>
<ul>
<li>Dirección IP de la parte que accede </li>

<li>Versión del navegador de la parte que accede </li>

<li>Fecha/hora de acceso </li>

<li>URL de acceso </li>
</ul>
<h3>Servicios externos que procesan datos: </h3>
<ul>
<li>Google/Firebase para el registro remoto y el análisis de fallos y errores </li>

<li>Google/Firebase para enviar notificaciones </li>

<li>Sendgrid para enviar correos electrónicos </li>

<li>Hetzner Online GmbH para el alojamiento del backend web y la base de datos </li>

</ul>

<h3>¿Qué ocurre cuando se elimina un usuario? </h3>
<ul>
<li>El usuario se da de baja de nuestro sistema en la página de inicio de CorPatch<sup>®</sup> Services  <a href="https://app.corpatch.com">https://app.corpatch.com</a></li>

<li>El usuario se marca como eliminado. Después de eso, ya no puede iniciar sesión, ya no es visible para los administradores, etc., pero el usuario sigue existiendo en la base de datos. </li>

<li>Transcurridos 14 días, los datos del usuario se eliminan automáticamente de la base de datos. </li>

<li>A efectos de evaluación científica y mejora de la funcionalidad, los datos de las formaciones y del uso de CorPatch<sup>®</sup> seguirán existiendo en la base de datos tras la eliminación del usuario, pero la referencia (el ID) al usuario estará vacía, y se eliminarán todas las referencias a datos personales.   </li>
</ul>

</div>

<!--This is the 19th section (white) - tile: Wie wir deine persönlichen Daten sammeln-->
<div class="white">
<h2>Cómo recopilamos sukk información personal </h2>

<p>La mayoría de los datos personales que procesamos nos los proporciona usted directamente. Recogemos y tratamos datos cuando usted: </p>
<ul>
<li>registrarse en línea o realizar un pedido de cualquiera de nuestros productos o servicios; por ejemplo, datos demográficos, dirección de correo electrónico, información de pago, artículos, importe del pedido, nivel y frecuencia de los descuentos. Incluido el envío de correos electrónicos transaccionales, por ejemplo, confirmación de pedido, confirmación de envío y confirmación de reembolso, </li>

<li>participación en la comunicación enviada (correo electrónico, SMS, correo directo o teléfono); por ejemplo, tasa de apertura, tasa de clics y tiempo dedicado a leer los correos electrónicos, dominio del remitente y tipo de cliente de correo electrónico, </li>

<li>rellenar voluntariamente una encuesta de clientes o dar su opinión en cualquiera de nuestros tablones de anuncios o por correo electrónico. </li>
</ul>


<p>También podríamos recibir información personal indirectamente, de las siguientes fuentes en los siguientes escenarios: </p>
<ul>
<li>De cookies: cuando visita nuestros sitios web o aplicaciones; por ejemplo, dirección IP, país, páginas vistas, productos vistos, interacción/clics y búsquedas. </li>

<li>De usted o de cualquier otra persona relacionada con nuestro cliente. Estas personas pueden ser un gerente o un colega. Si el cliente para el que usted trabaja compra productos o servicios de SmartResQ/CorPatch<sup>®</sup> a través de una empresa asociada a SmartResQ/CorPatch<sup>®</sup>, podemos recopilar información sobre usted de la empresa asociada. </li>

<li>Socios de marketing de SmartResQ/CorPatch<sup>®</sup>, fuentes públicas o redes sociales de terceros. </li>

<li>SmartResQ/CorPatch<sup>®</sup> podrá combinar datos personales sobre usted recogidos de una fuente con información obtenida de otra fuente. Esto nos da una imagen más completa de usted, lo que también nos permite servirle de una manera más relevante con un mayor nivel de personalización. </li>
</ul>

</div>

<!--This is the 20th section (beige) - title: Wie wir deine Daten verwenden-->
<div class="beige">
<h2>Cómo utilizamos sus datos </h2>

<p>Con el fin de gestionar en general nuestras relaciones con los clientes y cumplir nuestros compromisos con ellos, SmartResQ/CorPatch<sup>®</sup>  necesita información sobre usted en su calidad de cliente o cuando utiliza un servicio. Los fines del tratamiento de dichos datos personales son: </p>

<ul>
<li>Tramitar su pedido, gestionar su cuenta, </li>
	
<li>enviarle por correo electrónico ofertas especiales sobre otros productos y servicios que consideremos de su interés, </li>

<li>enviarle por correo electrónico ofertas especiales sobre otros productos y servicios que consideremos de su interés, </li>

<li>realizar el proceso de venta y contratación para los clientes, </li>

<li>ofrecer a los clientes los productos y servicios solicitados, </li>

<li>realizar entregas de conformidad con los acuerdos celebrados con usted o con los clientes, </li>

<li>mejorar y desarrollar la calidad, funcionalidad y experiencia de usuario de nuestros productos, servicios y sitios web y aplicaciones de SmartResQ/CorPatch<sup>®</sup>, </li>

<li>detectar, limitar y prevenir las amenazas a la seguridad y realizar tareas de mantenimiento y solución de problemas y depuración, </li>

<li>prevenir el uso indebido de nuestros productos y servicios, </li>

<li>para procesar pedidos, facturación, pagos u otro seguimiento financiero,</li>

<li>crear perfiles de interés para promocionar productos y servicios relevantes, </li>

<li>establecer comunidades de usuarios para educar y facilitar la interacción entre los usuarios y SmartResQ/CorPatch<sup>®</sup>. </li>

</ul>

<h3>Acerca de las pistas </h3>

<p>SmartResQ/CorPatch<sup>®</sup> procesa datos personales de clientes potenciales con fines de marketing. Con el fin de proporcionar contenido específico y relevante a los clientes potenciales, SmartResQ/CorPatch<sup>®</sup> construye un perfil de interés basado en su actividad y sus elecciones y acciones en las páginas de SmartResQ/CorPatch<sup>®</sup>, así como su respuesta al contenido de marketing. La base jurídica de dicho tratamiento es principalmente su consentimiento. </p>

<h3>Sobre los demandantes de empleo</h3>

<p>Si es usted un candidato a un puesto de trabajo, procesamos sus datos personales para evaluar su potencial como empleado de SmartResQ/CorPatch<sup>®</sup>. Nuestra plataforma segura de carreras profesionales en línea garantiza que cumplimos las leyes y normativas más recientes en relación con la privacidad de los datos. La base jurídica de dicho tratamiento es su consentimiento.</p>

<h3>Acerca de los visitantes del sitio web</h3>

<p>Para controlar el acceso a nuestros sitios, procesamos información personal sobre los visitantes. El tratamiento se basa en nuestro interés legítimo de proteger nuestros secretos comerciales, empleados, ubicaciones y a usted como visitante. Se le informará de sus derechos en este contexto cuando se registre en nuestro sistema electrónico para visitantes. </p>

<p>Para mejorar la calidad de la RCP, especialmente a través de la formación en RCP, SmartResQ/CorPatch<sup>®</sup> podría compartir sus datos con nuestras empresas asociadas (Institutos de Formación) para que puedan ofrecerle sus productos y servicios. </p>

<p>Cuando procesamos su pedido, nuestro sistema puede enviar sus datos a las agencias de referencia de crédito, y también utilizar la información resultante de las mismas, para evitar compras fraudulentas. </p>

</div>

<!-- This is 21st section (white) - title: Wie speichern wir deine persönlichen Daten? -->
<div class="white">
<h2>Cómo almacenamos su información personal </h2>

<p>SmartResQ/CorPatch<sup>®</sup> se toma muy en serio la confianza que usted y nuestros clientes nos demuestran. SmartResQ/CorPatch<sup>®</sup> se compromete a evitar el acceso no autorizado, la divulgación u otro tratamiento aberrante de los datos personales. SmartResQ/CorPatch<sup>®</sup> garantizará la confidencialidad de los datos personales que procesamos, mantendrá la integridad de los datos personales y garantizará su accesibilidad de acuerdo con las leyes de privacidad aplicables. </p>

<p>Como parte de nuestros compromisos, adoptamos procedimientos y medidas organizativas, técnicas y físicas razonables y adecuadas para proteger la información que recopilamos y procesamos. Tenemos en cuenta el tipo de datos personales y el riesgo al que nuestros clientes están expuestos por cualquier violación de seguridad, ya que hay una alta probabilidad de que las causas fundamentales de las violaciones de datos personales se pueden encontrar internamente, creemos que la construcción de una fuerte cultura corporativa en la que el respeto y la vigilancia sobre la protección de datos entre nuestros empleados es fundamental para garantizar el tratamiento legal y la protección de su información. En caso de violación de datos, SmartResQ/CorPatch<sup>®</sup> seguirá las prácticas establecidas por el Datatilsynet danés. </p>

<p>Su información se almacena de forma segura de acuerdo con la normativa GDPR. </p>

</div>

<!--This is the 22nd section (beige) - title: Wie lange bewahren wir deine persönlichen Daten auf?-->
<div class="beige">
<h2>¿Cuánto tiempo conservamos sus datos personales? </h2>

<p>SmartResQ/CorPatch<sup>®</sup> conserva sus datos personales sólo durante el tiempo que sea necesario para los fines indicados, teniendo en cuenta nuestra necesidad de responder a las consultas y resolver los problemas y cumplir con los requisitos legales en virtud de la legislación aplicable. </p>

<p>Esto significa que SmartResQ/CorPatch<sup>®</sup> puede almacenar su información personal durante un período razonable después de su última interacción y la de nuestro cliente con nosotros. Cuando los datos personales que hemos recopilado ya no son necesarios, los eliminamos. Podemos procesar datos con fines estadísticos y/o científicos, pero en tales casos los datos serán seudonimizados o anonimizados. </p>




<h3>Plazo de conservación de los datos </h3>

<p>Conservaremos su información personal durante el período necesario para cumplir los fines descritos en la presente política de privacidad, a menos que la ley exija o permita un período de conservación más largo, por motivos legales, fiscales o reglamentarios u otros fines empresariales legítimos y legales. </p>



<table>
<tr>
<td><b>Propósito </b></td>
<td><b>Plazos </b></td>
</tr>

<tr>
<td>Atención al cliente y normativa contable </td>

<td>5 años o mientras estemos obligados a cumplir el requisito legal necesario.
<br>
Eliminamos sus datos de registro tan pronto como elimina su cuenta de usuario con nosotros, o una vez que expira el período de conservación legal.
</td>
</tr>

<tr>
<td>Plataforma profesional </td>

<td>6 meses para los documentos de solicitud que no den lugar a la contratación del solicitante de empleo.
<br>


Renovación cada 6 meses si desea que se le tenga en cuenta para futuras ofertas de empleo y da su consentimiento para un periodo de almacenamiento más largo.
</td>
</tr>

<tr>
<td>Objetivo de marketing </td>

<td>3 años después de su última actividad, por ejemplo, visita a nuestros sitios web, compra o participación en una comunicación.
<br>


Si ha dado su permiso de marketing (correo electrónico, SMS, teléfono, etc.) y siempre que tengamos su permiso para ponernos en contacto con usted.
<br>


Eliminamos su dirección de correo electrónico del boletín automáticamente, una vez que elimina su cuenta de usuario o si se da de baja de nuestro boletín.
</td>
</tr>

<tr>
<td>Almacenamiento del historial de pedidos y obligaciones de cumplimiento </td>

<td>5 años o mientras estemos obligados a cumplir los requisitos legales necesarios.   </td>
</tr>

<tr>
<td>Experiencia del cliente </td>

<td>3 años después de su última actividad, por ejemplo, visita a nuestros sitios web, compra o participación en una comunicación.
<br>


Si ha dado su permiso de marketing (correo electrónico, SMS, teléfono, etc.) y siempre que tengamos su permiso.
</td>
</tr>

<tr>
<td>Evaluación del riesgo de fraude </td>

<td>5 años o mientras estemos obligados a cumplir el requisito legal necesario. </td>
</tr>

</table>

</div>


<!--This is the 23rd section (white) - title: Marketingmitteilungen-->
<div class="white">
<h2>Comunicaciones de marketing </h2>

<p>Usted tiene derecho a optar por no recibir comunicaciones de marketing de SmartResQ/CorPatch<sup>®</sup> y puede hacerlo de dos maneras: </p>
<ul>
<li>siguiendo las instrucciones de exclusión en las comunicaciones comerciales pertinentes, </li>

<li>cambiar las preferencias en la sección de edición correspondiente a la cuenta, si tiene una cuenta con SmartResQ/CorPatch<sup>®</sup>, </li>

<li>poniéndose en contacto con nosotros por correo electrónico en  <a href="mailto:info@corpatch.com">info@corpatch.com</a>. </li>
</ul>


<p>Tenga en cuenta que incluso si opta por no recibir comunicaciones de marketing, puede seguir recibiendo mensajes administrativos de SmartResQ/CorPatch<sup>®</sup>, como confirmaciones de pedidos y avisos necesarios para gestionar su cuenta o los servicios prestados a nuestros clientes a través de, por ejemplo, servicios o aplicaciones móviles. </p>

</div>

<!--This is the 24th section (beige) - title: Deine Datenschutzrechte-->
<div class="beige">
<h2>Sus derechos en materia de protección de datos </h2>

<p>Para SmartResQ/CorPatch<sup>®</sup> es importante que usted conozca perfectamente todos sus derechos en materia de protección de datos. </p>

<p>Algunas leyes de protección de datos, incluido el Reglamento General de Protección de Datos (RGPD) de la Unión Europea, la legislación correspondiente en Alemania Bundesdatenschutzgesetz (BDSG), en Suiza y en el Reino Unido, y algunas leyes estatales de Estados Unidos, le otorgan ciertos derechos en relación con los datos personales que ha compartido con nosotros. Si usted reside en el Espacio Económico Europeo, puede tener los siguientes derechos:</p>
<ol>
<li><b>Su derecho de acceso</b> – Tiene derecho a solicitarnos copias de su información personal. </li>

<li><b>Derecho de rectificación</b> – Tiene derecho a solicitar que corrijamos cualquier información personal que considere inexacta. También tiene derecho a solicitarnos que completemos la información que considere incompleta.   </li>

<li><b>Su derecho a la eliminación</b> – Tiene derecho a solicitarnos que eliminemos su información personal en determinadas condiciones.   </li>

<li><b>Su derecho a restringir el tratamiento</b> – tiene derecho a solicitarnos que restrinjamos el tratamiento de sus datos personales en determinadas condiciones. </li>

<li><b>Su derecho a oponerse al tratamiento</b> – Tiene derecho a oponerse al tratamiento de sus datos personales en determinadas condiciones. </li>

<li><b>Su derecho a la portabilidad de los datos</b> – Tiene derecho a solicitar que transfiramos la información personal que hemos recopilado a otra organización, o directamente a usted en determinadas condiciones. </li>

</ol>

<p>Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros en nuestra dirección de correo electrónico <a href="mailto:info@corpatch.com">info@corpatch.com</a>.</p>

</div>

<!--This is the 25th section (white) - title: Was sind Cookies?-->
<div class="white">
<h2>¿Qué son las cookies? </h2>

<p>Las cookies son pequeños archivos de texto que contienen una cadena de caracteres y crean un identificador único para un usuario. Se devuelven al sitio web y/o a terceros. La mayoría de los navegadores están configurados inicialmente para aceptar cookies, ya que la mayoría de los sitios web necesitan acceder a ellas. Sin embargo, el usuario puede cambiar la configuración de su navegador para que, en general, pueda rechazar las cookies, bloquear las cookies de terceros o especificar cuándo se envía una cookie.</p>



<p>SmartResQ/CorPatch<sup>®</sup> se compromete a garantizar su derecho a ajustar sus intereses y gestionar el alcance de la comercialización digital de nosotros a través de un sistema de gestión preferente. </p>

</div>

<!--This is the 26th section (beige) - title: Wie wir Cookies verwenden-->
<div class="beige">
<h2>Cómo utilizamos las cookies </h2>

<p>SmartResQ/CorPatch<sup>®</sup> utiliza cookies de diversas formas para mejorar su experiencia en nuestros sitios web, aplicaciones y servicios por diferentes motivos, por ejemplo: </p>
<ul>
<li>Funcionalidad: utilizamos estas cookies para reconocerle en nuestro sitio web y recordar sus preferencias previamente seleccionadas. Por ejemplo, el idioma que prefiere y la ubicación en la que se encuentra. Se utiliza una combinación de cookies propias y de terceros. </li>

<li>Publicidad: utilizamos estas cookies para recopilar información sobre su visita a nuestros sitios web y aplicaciones, el contenido que ha visto, los enlaces que ha seguido e información sobre su navegador, dispositivo y dirección IP. A veces compartimos algunos aspectos limitados de estos datos con terceros con fines publicitarios. También podemos compartir datos en línea recogidos a través de cookies con nuestros socios publicitarios. Esto significa que cuando visite otro sitio web, es posible que se le muestre publicidad basada en sus patrones de navegación en nuestro sitio web. </li>
</ul>

</div>

<!--This is the 27th section (white) - title: Die Art der Cookies, die wir verwenden-->
<div class="white">
<h2>El tipo de cookies que utilizamos </h2>

<p>Nuestro(s) sitio(s) web utiliza(n) los siguientes tipos de cookies: </p>
<ul>
<li><b>Google Analytics: </b> Esta cookie nos permite ver información sobre las actividades del sitio web para los usuarios, incluyendo pero no limitado a páginas vistas, fuente y tiempo de permanencia en un sitio web. La información es anónima y se muestra en forma de números, lo que significa que no se puede rastrear hasta las personas. Esto ayuda a proteger su privacidad. Mediante el uso de Google Analytics, podemos ver qué contenido es popular en nuestras páginas, y nos esforzamos por ofrecerle más de lo que le gusta leer y ver. </li>

<li><b>Remarketing de Google Analytics:</b> Coloca cookies en su ordenador, lo que significa que cuando abandona nuestro sitio, Google puede mostrarle anuncios sobre SmartResQ/CorPatch<sup>®</sup> que pueden interesarle en función de su comportamiento anterior en nuestro sitio web. Esta información no es personalmente identificable. </li>

<li><b>Anuncios de Google: </b> Mediante el uso de Google Ads, podemos ver qué páginas fueron útiles para conducir a envíos a través de nuestro formulario de contacto. Esta información no es personalmente identificable, pero los datos proporcionados en el formulario de contacto sí lo son. </li>

<li><b>Google Ads remarketing:</b> Coloca cookies en su ordenador, lo que significa que cuando abandona nuestro sitio, Google puede mostrarle anuncios sobre SmartResQ/CorPatch<sup>®</sup> que pueden interesarle en función de su comportamiento anterior en nuestro sitio web. Esta información no es personalmente identificable. </li>

<li><b>Remarketing de Facebook: </b> El píxel de seguimiento de Facebook coloca cookies en su ordenador que indican a Facebook que ha visitado el sitio. A continuación, suponemos que usted tiene un interés en SmartResQ/CorPatch<sup>®</sup> y el contenido de este sitio. Cuando visite Facebook, estará expuesto a información o anuncios con contenido similar. Por favor, utilice su configuración de privacidad en Facebook para limitar la exposición a la comercialización de este tipo. </li>

<li><b>YouTube:</b> Integramos vídeos de la plataforma YouTube proporcionada por Google (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA). Política de privacidad: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</li>
</ul>

</div>

<!--This is the 28th section (beige) - title: Wie Cookies verwaltet werden-->
<div class="beige">
<h2>Cómo gestionar las cookies </h2>

<p>Puede configurar su navegador para que no acepte o elimine las cookies. Sin embargo, es posible que algunas funciones de nuestro sitio web no funcionen. Vea cómo evitar las cookies en navegadores específicos:</p>

<ul>
<li>Página web estadounidense: <a href="https://www.aboutads.info/choices/"> https://www.aboutads.info/choices/</a></li>

<li>Página web de la UE:  <a href="https://www.youronlinechoices.com/"> https://www.youronlinechoices.com/</a></li>

</ul>

<p>SmartResQ/CorPatch<sup>®</sup> utiliza algunos servicios de terceros de confianza en nuestras páginas. Estos servicios pueden utilizar cookies. Puede optar por rechazar las cookies de terceros en su navegador siguiendo este <a href="https://www.youronlinechoices.com/">enlace</a>.</p>

<p>Puede evitar que Google recopile y procese la información generada por la cookie de Google sobre su uso de nuestros sitios descargando e instalando el complemento de inhabilitación de Google Analytics para su navegador web actual. Este complemento está disponible <a href="https://tools.google.com/dlpage/gaoptout">aquí</a>.</p>

</div>

<!--This is the 29th section (white) - title: Datenschutzrichtlinien anderer Websites-->
<div class="white">

<h2>Políticas de privacidad de otros sitios web</h2>

<p>Los sitios web de SmartResQ/CorPatch<sup>®</sup> contienen enlaces a otros sitios web. Nuestra política de privacidad sólo se aplica a nuestro sitio web, por lo que si hace clic en un enlace a otro sitio web, debe leer su política de privacidad. </p>

</div>

<!--This is the 30th section (beige) - title: Änderungen an unserer Datenschutzrichtlinie -->
<div class="beige">
<h2>Cambios en nuestra política de privacidad </h2>

<p>Si cambiamos nuestra declaración de privacidad, publicaremos la declaración revisada aquí con una fecha de revisión actualizada. Le recomendamos que revise la declaración con regularidad. Si introducimos cambios sustanciales en nuestra declaración que modifiquen significativamente nuestras prácticas de privacidad, también podremos notificárselo de otras formas, por ejemplo, enviándole un correo electrónico o publicando un aviso en nuestro sitio web y/o redes sociales antes de que los cambios entren en vigor. </p>

</div>

<!--This is the 31st section (white) - title: Wie du SmartResQ ApS kontaktieren kannst-->
<div class="white">
<h2>Cómo ponerse en contacto con SmartResQ ApS</h2>

<p>Si tiene alguna pregunta sobre la política de privacidad de SmartResQ/CorPatch<sup>®</sup> , los datos que tenemos sobre usted, o desea ejercer alguno de sus derechos de protección de datos, no dude en ponerse en contacto con nosotros. </p>

<p>Correo electrónico:  <a href="mailto:info@corpatch.com">info@corpatch.com</a></p>

<p>Página web: <a href="https://www.corpatch.com/">https://corpatch.com</a></p>

</div>

<!--This is the 32nd section (beige) - title: Wie man die zuständige Behörde kontaktiert-->
<div class="beige">

<h2>Cómo ponerse en contacto con la autoridad competente </h2>

<p>Si desea presentar una reclamación o si considera que SmartResQ/CorPatch<sup>®</sup> no ha resuelto su problema de forma satisfactoria, puede ponerse en contacto con la Oficina del Comisionado de Información (ICO). </p>

<p>Dirección del ICO:
<br><br>
Information Commissioner’s Office
<br>
Wycliffe House
<br>
Water Lane
<br>
Wilmslow
<br>
Cheshire
<br>
SK9 5AF </p>



<p>Número de teléfono de ayuda:  +44 303 123 1113 </p>

<p>Página web del ICO:  <a href="https://www.ico.org.uk/">https://www.ico.org.uk</a></p>

<p><b>© SmartResQ ApS – Todos los derechos reservados
<br>
Dinamarca, Versión 1.3 – Publicada el 2024.05.25 </b></p>

</div>
</div>
</body>
</html>
`