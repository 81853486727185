import { defineMessages } from 'react-intl'

export default defineMessages({
  email: {
    id: 'cp.pages.passwordForgot.eMail',
    defaultMessage: 'E-Mail',
  },
  passwordForgotConfirmationMessage: {
    id: 'cp.pages.passwordForgot.confirmationMessage',
    defaultMessage: 'The verification e-mail wil be send to your account.',
  },
  passwordForgotConfirmationMessage2: {
    id: 'cp.pages.passwordForgot.confirmationMessage2',
    defaultMessage: 'Please check it.',
  },
  passwordForgotConfirmationTitle: {
    id: 'cp.pages.passwordForgot.confirmationTitle',
    defaultMessage: 'Reset your Password',
  },
  passwordForgotConfirmationSent: {
    id: 'cp.pages.passwordForgot.confirmationSent',
    defaultMessage: 'Send E-Mail',
  },
  passwordForgotBackToLogin: {
    id: 'cp.pages.passwordForgot.passwordForgotBackToLogin',
    defaultMessage: 'Back to login',
  },
  passwordForgotConfirmationSendMessage: {
    id: 'cp.pages.passwordForgot.confirmationSendMessage',
    defaultMessage: 'We send you an email with an approval link to',
  },
  passwordForgotTitle: {
    id: 'cp.pages.passwordForgot.passwordForgotTitle',
    defaultMessage: 'Password forgot',
  },
})
