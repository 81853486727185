import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { combineLatest, interval } from 'rxjs'
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

const start = Date.now()

const log = (args: any) => {
  const delta = Date.now() - start
  console.log(
    '%c 🛒 [ ' + delta + 'ms ] ' + args,
    'background: bisque; color: #333; padding:5px; font-weight:bold'
  )
}

export const connectShopPage = (store: AppStore) => {
  const { http, onChange } = tools(store)
  const { api } = config()
  const url = api + routes.shopSso

  //
  // Login / Logout
  //

  // We watch the global variable `Ecwid` and `Ecwid.setSsoProfile`
  // and check if the they become available
  const ecwidAvailable$ = interval(200).pipe(
    map(
      () =>
        window.Ecwid != null &&
        window.Ecwid.setSsoProfile != null &&
        window.xProductBrowser !== null
    ),
    startWith(false),
    distinctUntilChanged()
  )
  ecwidAvailable$.subscribe(available => log('ECWID available: ' + available))

  // Watch token changes
  const token$ = onChange(state => state.session.token)

  const ctx$ = combineLatest(token$, ecwidAvailable$).pipe(
    map(([token, ecwidAvailable]) => ({ token, ecwidAvailable }))
  )

  // Login Event - we have a token and Ecwid is available
  const login$ = ctx$.pipe(
    filter(ctx => ctx.token !== null && ctx.ecwidAvailable)
  )

  // Logout Event - we don't have a token and Ecwid is available
  const logout$ = ctx$.pipe(
    filter(ctx => ctx.token === null && ctx.ecwidAvailable)
  )

  login$.subscribe(() => log('Login Event'))
  logout$.subscribe(() => log('Logout Event'))

  login$.subscribe(() => {
    http<any>({ url }).subscribe(shopData => {
      const { ecwidSsoProfile } = shopData
      window.Ecwid.setSsoProfile(ecwidSsoProfile)
      log('Set SSO profile: "' + ecwidSsoProfile + '"')
    })
  })

  logout$.subscribe(() => {
    window.Ecwid.setSsoProfile('')
  })

  //
  // History
  //
  // TODO restore shop navigation
  // onChange(state => state.location).pipe(map(location => {
  //   if (location.pathname === '/shop') {
  //     return location.hash
  //   }
  // })
}
