import { AppStore } from 'state/store'
import { tools } from 'services/utils/tools'
import { Message } from '@restate/core'
import { routes } from 'model/ctrl/routes'
import { config } from 'config/config'
import { SignUpReq } from 'model/ctrl/signup/SignUpReq.schema'
import { AppRoutes } from 'routes'
import { Locale } from 'model/Locale'
import { EMail } from 'model/Email'

import messages from './TrainerInvite.messages'
import { USERROLES } from '../../model/UserRole'

export interface TrainerInviteMessage extends Message {
  type: 'Page/Service/TrainerInvite/Invite'
  payload: {
    email: EMail
    firstName: string
    lastName: string
  }
}

export const connectTrainerInvitePageService = (store: AppStore) => {
  const { onAction, http, pageParams, snackBar, intl } = tools(store)
  const { api } = config()
  const getInstituteId = pageParams(AppRoutes.TrainerInvite)

  onAction({ type: 'Page/Service/TrainerInvite/Invite' }).subscribe(pack => {
    const payload = (pack.message as TrainerInviteMessage).payload

    const successSnackbar = intl().formatMessage(
      messages.trainerSuccessSnackbar,
      { email: payload.email }
    )
    const errorSnackbar = intl().formatMessage(messages.trainerErrorSnackbar)

    const { id } = getInstituteId()

    if (!id) return

    const setSend = (send: boolean) =>
      store.next(s => {
        s.pages.trainerInvite.send = send
      })

    store.next(s => {
      s.pages.trainerInvite.meta.loading = true
    })
    const data: SignUpReq = {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      instituteId: id,
      locale: Locale.de,
      roles: [USERROLES.Trainer, USERROLES.Trainee],
    }

    http({
      url: api + routes.auth.signup,
      method: 'POST',
      data,
    }).subscribe(
      () => {
        store.next(s => {
          s.pages.trainerInvite.meta.loading = false
        })
        setSend(true)
        snackBar.success(successSnackbar)
      },
      () => {
        store.next(s => {
          s.pages.trainerInvite.meta.loading = false
        })
        snackBar.error(errorSnackbar)
      }
    )
  })
}
