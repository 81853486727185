export interface UserChangePasswordState {
  meta: {
    loading: boolean
    notFound: boolean
  }
}

export const defaultUserChangePasswordState: UserChangePasswordState = {
  meta: {
    loading: false,
    notFound: false,
  },
}
