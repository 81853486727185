import { AppStoreProvider } from '../../state/store'
import {
  ActionFactoryProps,
  createActionsHook,
  createStateHook,
  createNextHook,
} from '@restate/core'
import { InstituteState } from './Institute.state'
import { AppMessages } from 'state/appMessages'
import { Locale } from '../../model/Locale'

export const useInstituteState = createStateHook(
  AppStoreProvider,
  state => state.pages.institute
)

export const useInstituteResultConfigState = createStateHook(
  AppStoreProvider,
  state => state.pages.instituteResultConfig
)

export const useNextInstituteState = createNextHook(
  AppStoreProvider,
  state => state.pages.institute
)

export const useNextInstituteResultConfigState = createNextHook(
  AppStoreProvider,
  state => state.pages.instituteResultConfig
)

const InstituteActions = ({
  next,
}: ActionFactoryProps<InstituteState, AppMessages>) => ({
  setName(nextName: string) {
    next(state => (state.name = nextName))
  },
  setCity(nextCity: string) {
    next(state => (state.address.city = nextCity))
  },
  setPlz(nextPlz: string) {
    next(state => (state.address.zip = nextPlz))
  },
  setStreet1(nextStreet1: string) {
    next(state => (state.address.street = nextStreet1))
  },
  setCo1(nextCo1: string) {
    next(state => (state.address.co1 = nextCo1))
  },
  setState(nextState: string) {
    next(state => (state.address.state = nextState))
  },
  setCountry(nextCountry: string) {
    next(state => (state.address.country = nextCountry))
  },
  setLocale(nextLocale: Locale) {
    next(state => (state.settings.locale = nextLocale))
  },
})

export const useInstituteActions = createActionsHook(
  AppStoreProvider,
  state => state.pages.institute,
  InstituteActions
)
