import { Message } from '@restate/core'

export interface PagesUserChangeEmailMessages extends Message {
  type: 'USER/CHANGE_EMAIL/SEND'
}

export interface UserChangeEmailState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  nextEmail: string
  send: boolean
  errorNextEmail: boolean
  errorNextEmailMessage: string
}

export const defaultUserChangeEmailState: UserChangeEmailState = {
  meta: {
    loading: false,
    notFound: false,
  },
  nextEmail: '',
  send: false,
  errorNextEmail: false,
  errorNextEmailMessage: '',
}
