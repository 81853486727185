import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'
import React from 'react'
import { useIntl } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useInstituteAdminSharesState } from './InstituteAdminShares.restate'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import messages from './InstituteAdminShares.messages'

const useClasses = makeStyles(theme => ({
  table: {
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
  },
  startDate: {
    fontSize: '0.8rem',
    color: theme.palette.grey[500],
  },
}))

export function InstituteAdminShares() {
  const sharingObjects = useInstituteAdminSharesState(s => s.sharingObjects)
  const classes = useClasses()
  const intl = useIntl()
  const active = intl.formatMessage(messages.active)
  const deleted = intl.formatMessage(messages.deleted)

  const activeSharingObjects = sharingObjects.filter(
    sharingObject => sharingObject.meta.deletedAt === null
  )

  const deactivatedSharingObjects = sharingObjects.filter(
    sharingObject => sharingObject.meta.deletedAt !== null
  )

  const hasActiveSharingObjects = activeSharingObjects.length > 0
  const hasDeactivatedSharingObjects = deactivatedSharingObjects.length > 0

  return (
    <AppPageLayout title={intl.formatMessage(messages.pageTitle)}>
      <Card>
        <CardHeader title={active} />
        <CardContent>
          <Table size={'small'} className={classes.table}>
            <TableBody>
              {!hasActiveSharingObjects && (
                <Typography>
                  {intl.formatMessage(messages.noActiveSharingObjectsMessage)}
                </Typography>
              )}
              {hasActiveSharingObjects &&
                activeSharingObjects.map(sharingObject => (
                  <SharingObjectRow sharingObject={sharingObject} />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <CardHeader title={deleted} />
        <CardContent>
          <Table size={'small'} className={classes.table}>
            <TableBody>
              {!hasDeactivatedSharingObjects && (
                <Typography>
                  {intl.formatMessage(messages.noDeletedSharingObjectsMessage)}
                </Typography>
              )}
              {hasDeactivatedSharingObjects &&
                deactivatedSharingObjects.map(sharingObject => (
                  <SharingObjectRow sharingObject={sharingObject} disabled />
                ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </AppPageLayout>
  )
}

function SharingObjectRow(props: {
  sharingObject: SharingObjectDocument
  disabled?: boolean
}) {
  const { sharingObject, disabled = false } = props
  const classes = useClasses()
  const intl = useIntl()

  const goTo = useGoToHook()
  const navigateToSharingObject = () =>
    goTo(AppRoutes.InstituteAdminSharingObject, { id: sharingObject._id })

  return (
    <TableRow>
      <TableCell style={{ height: 60 }}>
        <div>{sharingObject.name}</div>
        <div className={classes.startDate}>
          {intl.formatMessage(messages.startDate)}
          {intl.formatDate(sharingObject.meta.createdAt)}
        </div>
        {sharingObject.meta.deletedAt && (
          <div className={classes.startDate}>
            {intl.formatMessage(messages.endDate)}
            {intl.formatDate(sharingObject.meta.deletedAt)}
          </div>
        )}
      </TableCell>
      <TableCell align='right'>
        {!disabled && (
          <IconButton onClick={navigateToSharingObject}>
            <RightIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
