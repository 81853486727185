import { defineMessages } from 'react-intl'

export default defineMessages({
  firstnameField: {
    id: 'cp.pages.register.firstnameField',
    defaultMessage: 'firstname',
  },
  lastnameField: {
    id: 'cp.pages.register.lastnameField',
    defaultMessage: 'lastname',
  },
  emailField: {
    id: 'cp.pages.register.emailField',
    defaultMessage: 'E-Mail',
  },
  passwordField: {
    id: 'cp.pages.register.passwordField',
    defaultMessage: 'password',
  },
  acceptTermsOfUse: {
    id: 'cp.pages.register.acceptTermsOfUse',
    defaultMessage: 'Do you accept the terms of use?',
  },
  create: {
    id: 'cp.pages.register.create',
    defaultMessage: 'Create Account',
  },
  successSnackbar: {
    id: 'cp.pages.register.successSnackbar',
    defaultMessage: 'You registration was successful.',
  },
  errorSnackbar: {
    id: 'cp.pages.register.errorSnackbar',
    defaultMessage: 'An unintended error occured. Please try again.',
  },
  newToShortError: {
    id: 'cp.pages.register.newToShortError',
    defaultMessage: 'The provided passwort is to short.',
  },
})
