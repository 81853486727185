import { RxStore } from '@restate/core'
import RouteParser from 'route-parser'
import { AppMessages } from 'state/appMessages'
import { State } from 'state/state'
import { goMessage } from 'services/router/router.restate'

interface RouteParams {
  id?: string
}

export const goTo = (store: RxStore<State, AppMessages>) => (
  route: string,
  params: RouteParams = {}
) => {
  const routeParser = new RouteParser(route)
  const fqRoute = routeParser.reverse(params)
  if (fqRoute) {
    store.dispatch(goMessage(fqRoute))
  } else {
    console.error(`Cannot parse route ${route} with params ${params}`)
  }
}
