import { animationFrameScheduler } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged'
import { map, observeOn } from 'rxjs/operators'
import { State } from 'state/state'
import { AppStore } from 'state/store'

export const onChange = (store: AppStore) => <T>(
  selector: (state: State) => T
) =>
  store.state$.pipe(
    observeOn(animationFrameScheduler),
    map(update => selector(update.state)),
    distinctUntilChanged()
  )
