const ALL_FRONTEND_LOCALES = [
  'ar-SA', // Assuming Saudi Arabia
  'da-DA',
  'de-DE',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'it-IT',
]

export type FrontendLocale =
  | 'ar-SA'
  | 'da-DA'
  | 'de-DE'
  | 'en-US'
  | 'es-ES'
  | 'fi-FI'
  | 'fr-FR'
  | 'it-IT'

export interface I18NState {
  locale: FrontendLocale
}

function isFrontendLocale(value: string): boolean {
  return ALL_FRONTEND_LOCALES.includes(value)
}

const DEFAULT_FALLBACK_LANGUAGE = 'en-US'

export const LOCAL_STORAGE_LANGUAGE = 'CP-language'

/**
 * Converts a short locale code (like 'de') into a fully qualified
 * language code (like 'de-DE')
 *
 * @param code
 * @returns
 */
export const toFrontendLocale = function (code: string): FrontendLocale {
  if (isFrontendLocale(code)) return code as FrontendLocale

  if (code.startsWith('de')) return 'de-DE'
  if (code.startsWith('en')) return 'en-US'
  if (code.startsWith('da')) return 'da-DA'
  if (code.startsWith('fi')) return 'fi-FI'
  if (code.startsWith('it')) return 'it-IT'
  if (code.startsWith('fr')) return 'fr-FR'
  if (code.startsWith('da')) return 'da-DA'
  if (code.startsWith('es')) return 'es-ES'
  if (code.startsWith('ar')) return 'ar-SA'

  console.warn(`Could not find FrontendLocale for code "${code}"`)
  return DEFAULT_FALLBACK_LANGUAGE
}

export const defaultI18NState: I18NState = {
  locale:
    (localStorage.getItem(LOCAL_STORAGE_LANGUAGE) as FrontendLocale) ||
    toFrontendLocale(getNavigatorLanguage()),
}

//
// See also index.html: getNavigatorLanguage for Ecwid
//
function getNavigatorLanguage(): string {
  if (!navigator) {
    return DEFAULT_FALLBACK_LANGUAGE
  }

  if (Array.isArray(navigator.languages)) {
    return navigator.languages[0]
  }

  return (
    (navigator as any).userLanguage ||
    navigator.language ||
    (navigator as any).browserLanguage ||
    DEFAULT_FALLBACK_LANGUAGE
  )
}
