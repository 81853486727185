import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import { EnhancedTableHead } from './components/EnhancedTableHead'
import { EnhancedTableToolbar } from './components/EnhancedTableToolbar'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'

import messages from './GenericTable.messages'

export interface GenericTableData {
  id: string
  selected: boolean
  isSelectionEnabled: boolean
}

export interface GenericTableCellHeader {
  id: string
  disablePadding: boolean
  label: string
  numeric: boolean
}

export interface GenericTableDataProps<Row extends GenericTableData> {
  checkboxEnabled?: boolean
  checkboxVisible?: boolean
  rows: Row[]
  headers: GenericTableCellHeader[]
  search: (searchTerm: string) => (entry: GenericTableData) => boolean
  rowRender: React.FC<{ row: any; idx: number }>
  selectedItemsMessage: React.FC<{ count: number }>
  tools?: React.FC<{ selected: string[]; setSelected: (ids: string[]) => any }>
  singleSelect?: boolean
  initialSortProperty?: string
  initialSortDirection?: Order
  title?: string
  subTitle?: string
  emptyMessage?: string
  isScrollable?: boolean
}

export type Order = 'asc' | 'desc'

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100, 200]

type StyleProps = {
  isScrollable: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: ({ isScrollable }: StyleProps) => ({
    overflowX: 'auto',
    maxHeight: isScrollable ? '60vh' : 'auto',
  }),
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  srollableTableBody: {
    overflow: 'scroll',
  },
}))

function sort(data: GenericTableData[], order: Order, property: string) {
  const sorted = [...data].sort((a: any, b: any) => {
    const propertyA = a[property]
    const propertyB = b[property]

    if (typeof propertyA === 'number' && typeof propertyB === 'number') {
      return propertyA - propertyB
    } else if (typeof propertyA === 'string' && typeof propertyB === 'string') {
      return propertyA.localeCompare(propertyB)
    } else if (
      typeof propertyA === 'boolean' &&
      typeof propertyB === 'boolean'
    ) {
      return propertyA === true ? 1 : 0
    } else if (propertyA instanceof Date && propertyB instanceof Date) {
      return propertyA.valueOf() - propertyB.valueOf()
    } else {
      return 1
    }
  })
  if (order === 'asc') {
    return sorted.reverse()
  }
  return sorted
}

const ROW_HEIGHT = 81

export function GenericTable<Row extends GenericTableData>(
  props: GenericTableDataProps<Row>
) {
  const intl = useIntl()
  const empty = intl.formatMessage(messages.emptyMessage)
  const previousPage = intl.formatMessage(messages.previousPage)
  const nextPage = intl.formatMessage(messages.nextPage)
  const searchPlaceholder = intl.formatMessage(messages.searchPlaceholder)
  const rowsPerPageLabel = intl.formatMessage(messages.rowsPerPageLabel)

  const {
    rows: data,
    search,
    headers,
    selectedItemsMessage,
    tools,
    singleSelect = false,
    checkboxEnabled = true,
    checkboxVisible = true,
    title = '',
    subTitle = '',
    emptyMessage = empty,
    initialSortProperty = headers[0].id,
    initialSortDirection = 'desc',
    isScrollable = true,
  } = props
  const classes = useStyles({ isScrollable } as StyleProps)
  const [order, setOrder] = React.useState<Order>(initialSortDirection)
  const [searchTerm, setSearchTerm] = React.useState('')
  const [orderBy, setOrderBy] = React.useState(initialSortProperty)
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWS_PER_PAGE_OPTIONS[2])

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const searchFn = search(searchTerm)

  const handleSelectAllClick = () => {
    if (selected.length === 0) {
      const newSelected = data.filter(searchFn).map(n => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (_event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (singleSelect) {
      newSelected = selectedIndex === -1 ? [id] : []
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
    }

    setSelected(newSelected)
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const setSearch = (term: string) => {
    setSearchTerm(term)
    setPage(0)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1
  const filteredRows = sort(data, order, orderBy).filter(searchFn)
  const displayRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  const RowComponent = props.rowRender

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selected={selected}
          setSelected={setSelected}
          numSelected={selected.length}
          searchTerm={searchTerm}
          setSearchTerm={setSearch}
          clearSearchTerm={() => setSearchTerm('')}
          selectedItemsMessage={selectedItemsMessage}
          tools={tools}
          title={title}
          subTitle={subTitle}
          searchPlaceholder={searchPlaceholder}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'medium'}
            aria-label='table'
            stickyHeader={isScrollable}
          >
            <EnhancedTableHead
              singleSelect={singleSelect}
              cellHeaders={headers}
              checkboxEnabled={checkboxEnabled}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody
              className={isScrollable ? classes.srollableTableBody : ''}
            >
              {data.length === 0 && (
                <TableRow style={{ height: rowsPerPage * ROW_HEIGHT }}>
                  <TableCell colSpan={headers.length + 1} align='center'>
                    <Typography variant='subtitle2'>{emptyMessage}</Typography>
                  </TableCell>
                </TableRow>
              )}

              {displayRows.map((row, idx) => {
                const isItemSelected = isSelected(row.id)

                return (
                  <TableRow
                    hover
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      padding='checkbox'
                      onClick={event => {
                        if (checkboxEnabled && row.isSelectionEnabled) {
                          handleClick(event, row.id)
                        }
                      }}
                    >
                      <Checkbox
                        checked={isItemSelected}
                        disabled={!checkboxEnabled || !row.isSelectionEnabled}
                        style={{ display: checkboxVisible ? undefined : 'none' }}
                      />
                    </TableCell>
                    <RowComponent row={row} idx={idx} />
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component='div'
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={rowsPerPageLabel}
          page={page}
          backIconButtonProps={{
            'aria-label': previousPage,
          }}
          nextIconButtonProps={{
            'aria-label': nextPage,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
