import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.institutes.pageNotFound.title',
    defaultMessage: 'No institute found',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.institutes.pageNotFound.message',
    defaultMessage: 'Do you want to add the first institute?',
  },
  addInstituteButton: {
    id: 'cp.pages.institutes.addInstituteButton',
    defaultMessage: 'Add Institute',
  },
  pageTitle: {
    id: 'cp.pages.institutes.pageTitle',
    defaultMessage: 'Institutes',
  },
  deleteLabel: {
    id: 'cp.pages.institutes.deleteLabel',
    defaultMessage: 'delete',
  },
  successSnackbar: {
    id: 'cp.pages.institutes.successSnackbar',
    defaultMessage: 'Institute was successfully deleted',
  },
  errorSnackbar: {
    id: 'cp.pages.institutes.errorSnackbar',
    defaultMessage: 'Institute could not be deleted',
  },
})
