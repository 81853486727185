export interface AdminInviteState {
  email: string
  send: boolean
  error: string | null
  meta: {
    loading: boolean
    notFound: boolean
  }
}

export const defaultAdminInviteState: AdminInviteState = {
  email: '',
  send: false,
  error: null,
  meta: {
    loading: false,
    notFound: false,
  },
}
