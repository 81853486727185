import { Message } from '@restate/core'
import { config } from 'config/config'
import { ForgotPasswordSendEmailReq } from 'model/ctrl/forgotPassword/ForgotPasswordSendEmailReq.schema'
import { routes } from 'model/ctrl/routes'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

import messages from '../../I18N/general.messages'

export interface PagesLoginPasswordForgotMessage extends Message {
  type: 'Pages/Login/PasswordForgot/SendEmail'
}

export const connectLoginPasswordForgot = (store: AppStore) => {
  const { onAction, http, onPageLeave, snackBar, intl } = tools(store)

  onPageLeave(AppRoutes.LoginPasswordForgot).subscribe(() => {
    store.next(s => {
      s.pages.loginPasswordForgot.send = 'input'
    })
  })

  onAction({ type: 'Pages/Login/PasswordForgot/SendEmail' }).subscribe(() => {
    const email = store.state.pages.login.email

    const request: ForgotPasswordSendEmailReq = {
      email,
    }

    const { api } = config()

    store.next(s => {
      s.pages.loginPasswordForgot.send = 'sending'
    })

    const onError = () => {
      store.next(s => {
        s.pages.loginPasswordForgot.send = 'error'

        const errorSnackbar = intl().formatMessage(
          messages.emailAllreadySentSnackbar
        )
        snackBar.error(errorSnackbar)
      })
    }

    const onSuccess = () => {
      store.next(s => {
        s.pages.loginPasswordForgot.send = 'send'
      })
    }

    http({
      url: api + routes.forgotPassword.sendEmail,
      method: 'POST',
      data: request,
    }).subscribe(onSuccess, onError)
  })
}
