import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'
import { useGoToHook } from '../../services/router/useGoTo.hook'
import { AppRoutes } from '../../routes'

const useStyles = makeStyles({
  Tile: {
    textAlign: 'center',
    margin: '100px 0',
    position: 'relative',
    cursor: 'pointer',
  },
})

interface DashboardTileProps {
  amount: number
  description: string
  size: 3 | 4
  children: any
}

export const DashboardTile: React.FC<DashboardTileProps> = props => {
  const classes = useStyles()
  const goTo = useGoToHook()

  return (
    <Grid
      item
      xs={props.size}
      className={classes.Tile}
      onClick={() => {
        goTo(AppRoutes.Trainees)
      }}
    >
      {props.children}
      <Typography variant='h3'>{props.amount}</Typography>
      <br />
      <Typography variant='h6'>{props.description}</Typography>
    </Grid>
  )
}

export default DashboardTile
