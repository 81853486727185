import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { CARD_DEFAULTS } from 'styles/styles'
import { theme } from 'theme/theme'

const useClasses = makeStyles({
  card: {
    ...CARD_DEFAULTS,
    display: 'flex',
    flexDirection: 'column',
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    flexGrow: 1,
  },

  imgContainer: {
    alignSelf: 'center',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: 260,
  },
  img: {
    margin: theme.spacing(),
    maxWidth: 260,
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
})

interface ImageCardProps {
  imgUrl: string
}

const useImgCardContentClasses = makeStyles({
  imgCardContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    flexGrow: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(),
    },
  },
})

export const ImageCardContent: React.FC = props => {
  const { imgCardContent } = useImgCardContentClasses()
  return <div className={imgCardContent}>{props.children}</div>
}

const useImgCardActionsClasses = makeStyles({
  imgCardActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(),
    },
    gap: theme.spacing(2),
  },
})

export const ImageCardActions: React.FC = props => {
  const { imgCardActions } = useImgCardActionsClasses()
  return <div className={imgCardActions}>{props.children}</div>
}

export const ImageCard: React.FC<ImageCardProps> = props => {
  const classes = useClasses()
  const { imgUrl, children } = props

  return (
    <Card className={classes.card} elevation={1}>
      <div className={classes.container}>
        <div className={classes.imgContainer}>
          <img
            className={classes.img}
            src={imgUrl}
            aria-hidden={true}
            alt='img'
          />
        </div>
        <div className={classes.cardContent}>{children}</div>
      </div>
    </Card>
  )
}
