import { Message, RxStore } from '@restate/core'
import { Institute } from 'model/Institute'
import { skip } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppMessages } from 'state/appMessages'
import { State } from 'state/state'
import { InstituteEntry } from './Institutes.state'

export interface PagesInstitutesServiceMessages extends Message {
  type: 'Pages/Institutes/Update'
}

function model2ViewModel(model: Institute[]): InstituteEntry[] {
  const entries: InstituteEntry[] = model.map(institute => ({
    _id: '' + (institute as any)._id,
    name: institute.name,
    street: institute.address.street,
    zip: institute.address.zip,
    city: institute.address.city,
    country: institute.address.country,
  }))

  return entries
}

export const connectInstitutesPage = (store: RxStore<State, AppMessages>) => {
  const { onChange } = tools(store)

  onChange(state => state.services.institutes)
    .pipe(skip(1))
    .subscribe(institutes => {
      const entries = model2ViewModel(institutes)
      setTimeout(
        () =>
          store.next(
            state => {
              state.pages.institutes.entries = entries
              state.pages.institutes.meta.loading = false
            },
            {
              type: 'Pages/Institutes/Update',
            }
          ),
        0
      )
    })
}
