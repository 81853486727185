import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.passwordForgotSetPassword.pageNotFound.title',
    defaultMessage: 'Not valid anymore',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.passwordForgotSetPassword.pageNotFound.message',
    defaultMessage: 'This code is not valid anymore.',
  },
  successTitle: {
    id: 'cp.pages.passwordForgotSetPassword.title',
    defaultMessage: 'Password changed!',
  },
  successMessage: {
    id: 'cp.pages.passwordForgotSetPassword.message',
    defaultMessage: 'All set up and you are good to go!',
  },
  loginButton: {
    id: 'cp.pages.passwordForgotSetPassword.button',
    defaultMessage: 'To the login page',
  },
  welcome: {
    id: 'cp.pages.passwordForgotSetPassword.welcome',
    defaultMessage: 'Welcome',
  },
  newPasswordRequired: {
    id: 'cp.components.passwordForgotSetPassword.newRequiredError',
    defaultMessage: 'A new password is required',
  },
  newToShortError: {
    id: 'cp.components.passwordForgotSetPassword.newToShortError',
    defaultMessage: 'The provided passwort is to short.',
  },
  passwordDontMatch: {
    id: 'cp.components.passwordForgotSetPassword.matchError',
    defaultMessage: "New passwords don't match",
  },
  newPassword: {
    id: 'cp.components.passwordForgotSetPassword.newPassword',
    defaultMessage: 'New Password',
  },
  newPasswordRepeat: {
    id: 'cp.components.passwordForgotSetPassword.newPasswordRepeat',
    defaultMessage: 'New Password (repeat)',
  },
  changePasswordButton: {
    id: 'cp.components.passwordForgotSetPassword.changePasswordButton',
    defaultMessage: 'Change Password',
  },
})
