import { UserRole } from 'model/UserRole'
import { animationFrameScheduler } from 'rxjs'
import { filter, map, observeOn, distinctUntilChanged } from 'rxjs/operators'
import { AppStore } from 'state/store'

export const hasUserRole = (store: AppStore) => (...roles: UserRole[]) =>
  store.state$.pipe(
    observeOn(animationFrameScheduler),
    map(update => update.state.session.user.role as UserRole),
    distinctUntilChanged(),
    filter((currentUserRole: UserRole) => roles.indexOf(currentUserRole) > -1)
  )
