import { Hidden } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MenuIcon from '@material-ui/icons/Menu'
import TranslateIcon from '@material-ui/icons/Translate'
import UserMenu from 'components/UserMenu/UserMenu'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Colors } from 'theme/colors'
import { DRAWER_WIDTH } from 'theme/constants'
import { useAppState } from '../../state/store'
import { useNextAppDrawerState } from '../AppDrawer/AppDrawer.restate'
import ChangeUserRole from '../ChangeUserRole/ChangeUserRole'
import TranslationMenu from '../TranslationMenu/TranslationMenu'
import messages from './NavBar.messages'

const useClasses = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: '150%',
  },
  appBar: {
    backgroundColor: Colors.neutrals[9],
    color: Colors.neutrals[2],
  },
  drawerSpacer: {
    [theme.breakpoints.up('md')]: {
      width: DRAWER_WIDTH,
    },
  },
}))

export interface UserMenuButtonProps {
  open: boolean
  handleClose: () => void
  handleOpen: () => void
  anchorEl?: HTMLDivElement | null
  showTranslationSwitch?: boolean
}

export const TranslationButton: React.FC<UserMenuButtonProps> = ({
  showTranslationSwitch = true,
  handleOpen,
  handleClose,
  open,
}) => {
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const embeddedMode = useAppState(state => state.routerExtension.embeddedMode)

  // Language will be set from the native frontend.
  if (embeddedMode) return null
  if (!showTranslationSwitch) return null

  return (
    <div>
      <div ref={anchorRef}></div>
      <TranslationMenu
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        anchorEl={anchorRef.current}
      />
      <IconButton
        onClick={() => handleOpen()}
        style={{
          color: Colors.neutrals[2],
          height: '1.5rem',
          width: '1.5rem',
          padding: '1.5rem',
        }}
      >
        <TranslateIcon />
      </IconButton>
    </div>
  )
}

const UserMenuButton: React.FC<UserMenuButtonProps> = ({
  open,
  handleClose,
  handleOpen,
}) => (
  <>
    <UserMenu open={open} handleClose={handleClose} />
    <IconButton onClick={handleOpen} color='inherit'>
      <AccountCircleIcon />
    </IconButton>
  </>
)

interface NavBarProps {
  title: string
  showBackButton: boolean
}
export const NavBar: React.FC<NavBarProps> = ({ title, showBackButton }) => {
  const intl = useIntl()
  const menuLabel = intl.formatMessage(messages.menuLabel)

  const classes = useClasses()
  const [openUserMenu, setOpenUserMenu] = useState(false)
  const [openTranslationMenu, setOpenTranslationMenu] = useState(false)
  const nextAppDrawer = useNextAppDrawerState()
  const history = useHistory()

  const openDrawer = () =>
    nextAppDrawer(state => {
      state.open = true
    })

  const goBack = () => {
    history.goBack()
  }

  return (
    <div>
      <AppBar position='static' color='inherit' elevation={0}>
        <Toolbar className={classes.appBar}>
          <div className={classes.drawerSpacer} />

          <Hidden mdUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label={menuLabel}
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          {showBackButton && (
            <IconButton onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            variant='h6'
            className={classes.title}
            noWrap={true}
            align='center'
          >
            {title}
          </Typography>

          <ChangeUserRole />

          <TranslationButton
            open={openTranslationMenu}
            handleClose={() => setOpenTranslationMenu(false)}
            handleOpen={() => setOpenTranslationMenu(true)}
          />
          <UserMenuButton
            open={openUserMenu}
            handleClose={() => setOpenUserMenu(false)}
            handleOpen={() => setOpenUserMenu(true)}
          />
        </Toolbar>
      </AppBar>
    </div>
  )
}
