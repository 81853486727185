import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'
import { center } from 'styles/styles'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'

interface SpinnerProps {
  visible: boolean
}

const useClasses = makeStyles({
  spinnerBackdrop: {
    zIndex: 1000,
    position: 'fixed',
    top: 68,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      top: 60,
    },
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',

    backgroundColor: Colors.neutrals[3],
    opacity: 0.01,
  },
  spinnerContainer: {
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      top: 0,
    },
    overflow: 'hidden',
    left: 0,
    height: 'calc(100vh - 50px)',
    width: '100vw',
    ...center,
  },
})

export const Spinner: React.FC<SpinnerProps> = ({ visible }) => {
  const classes = useClasses()
  return (
    <>
      {visible && (
        <>
          <div className={classes.spinnerBackdrop}></div>
          <div className={classes.spinnerContainer}>
            <CircularProgress />
          </div>
        </>
      )}
    </>
  )
}
