import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import { makeStyles } from '@material-ui/styles'
import { CircularTrainingProgress } from 'components/CircularTrainingProgress/CircularTrainingProgress'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState } from 'state/store'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import { useIntl } from 'react-intl'

import messages from './Course.messages'

const useClasses = makeStyles({
  title: {
    fontSize: 14,
  },
  location: {
    fontSize: 22,
  },
  date: {
    fontSize: 14,
  },

  institute: {
    fontSize: 14,
  },

  card: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
  },

  circle: {
    margin: theme.spacing(1),
  },
})

const CoursesLoading = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)

  return (
    <AppPageLayout
      title={pageTitle}
      loading={true}
      showBackButton
    ></AppPageLayout>
  )
}

export const Course: React.FC<{}> = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)
  const rowFlow = intl.formatMessage(messages.rowFlow)
  const rowDepth = intl.formatMessage(messages.rowDepth)
  const rowRecoil = intl.formatMessage(messages.rowRecoil)
  const rowFrequency = intl.formatMessage(messages.rowFrequency)
  const cellDate = intl.formatMessage(messages.cellDate)
  const cellDuration = intl.formatMessage(messages.cellDuration)
  const cellFeedback = intl.formatMessage(messages.cellFeedback)
  const cellName = intl.formatMessage(messages.cellName)
  const trainees = intl.formatMessage(messages.trainees)

  const course = useAppState(s => s.pages.course.course)
  const classes = useClasses()
  const goTo = useGoToHook()

  const navigateToTrainingPage = (id: string, idx: number) =>
    goTo(AppRoutes.Trainings, { id, idx: '' + idx })

  if (!course) {
    return <CoursesLoading />
  }

  const instituteName = course.institute.name

  return (
    <AppPageLayout title={pageTitle} showBackButton={true}>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color='textSecondary'
            gutterBottom
          >
            <FormattedDate
              value={new Date(course.date)}
              year='numeric'
              month='long'
              day='2-digit'
            />{' '}
          </Typography>

          <Typography className={classes.location} gutterBottom>
            {course?.location}
          </Typography>

          <Typography className={classes.institute}>
            {course.trainees.length} {trainees}
          </Typography>
          <Typography className={classes.institute}>{instituteName}</Typography>
          {/* <Typography className={classes.institute}>{institute.address.street}</Typography> */}
          {/* <Typography className={classes.institute}>{institute.address.city}</Typography> */}
          {/* <Typography className={classes.institute}>{institute.address.country}</Typography> */}

          <Typography className={classes.institute}>
            {course.comment}
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularTrainingProgress
              size={150}
              thickness={4}
              value={course.result.flowPercent}
              label={rowFlow}
              color={Colors.primary[4]}
              className={classes.circle}
            />
            <CircularTrainingProgress
              size={150}
              thickness={4}
              className={classes.circle}
              value={course.result.cprCorrectDepthPercent}
              color={Colors.vivid[3]}
              label={rowDepth}
            />
            <CircularTrainingProgress
              size={150}
              thickness={4}
              className={classes.circle}
              value={course.result.cprCorrectRecoilPercent}
              color={Colors.supportingLime[3]}
              label={rowRecoil}
            />
            <CircularTrainingProgress
              size={150}
              thickness={4}
              className={classes.circle}
              value={course.result.cprCorrectFrequencyPercent}
              color={Colors.supportingCyan[2]}
              label={rowFrequency}
            />
          </div>
        </CardContent>
      </Card>

      {/* {course.trainees.length > 0 && (
        <Card className={classes.card}>
          <CardContent>
            <List component='nav'>
              {course.trainees.map((trainee, idx) => (
                <>
                  <ListItem>
                    <ListItemText
                      key={trainee.email + '_' + idx}
                      secondary={trainee.email}
                      primary={'Trainee #' + trainee.trainee}
                    />
                  </ListItem>
                </>
              ))}
            </List>
          </CardContent>
        </Card>
      )} */}

      <Card className={classes.card}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{cellDate}</TableCell>
                <TableCell>{cellName}</TableCell>
                <TableCell>{cellDuration}</TableCell>
                <TableCell>{cellFeedback}</TableCell>
                <TableCell>{rowFlow}</TableCell>
                <TableCell>{rowDepth}</TableCell>
                <TableCell>{rowRecoil}</TableCell>
                <TableCell>{rowFrequency}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {course.trainings.map((training, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <FormattedTime
                    value={new Date(training.date)}
                    hour='2-digit'
                    minute='2-digit'
                  />
                </TableCell>
                <TableCell>
                  {!training.name || training.name === ''
                    ? '---'
                    : training.name}
                </TableCell>
                <TableCell>{training.sessionDuration}</TableCell>
                <TableCell>{training.feedbackMode}</TableCell>
                <TableCell>{training.result.flowPercent}%</TableCell>
                <TableCell>{training.result.cprCorrectDepthPercent}%</TableCell>
                <TableCell>
                  {training.result.cprCorrectRecoilPercent}%
                </TableCell>
                <TableCell>
                  {training.result.cprCorrectFrequencyPercent}%
                </TableCell>
                <TableCell align='right'>
                  <IconButton
                    onClick={() => navigateToTrainingPage(course._id, idx)}
                  >
                    <RightIcon />
                  </IconButton>{' '}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </CardContent>
      </Card>
    </AppPageLayout>
  )
}

export default Course
