import { TraineeDTO } from './../../model/ctrl/trainees/GetTraineesRes.schema'
import { AppStore } from '../../state/store'
import { tools } from '../../services/utils/tools'
import { config } from '../../config/config'
import RouteParser from 'route-parser'
import { routes } from '../../model/ctrl/routes'
import { AppRoutes } from '../../routes'
import { Message } from '@restate/core'
import { pageStateMachine } from '../../machines/pageState.machine'
import { defaultTraineesState } from './Trainees.state'
import { UUID } from 'model/UUID'
import { NotificationType } from 'model/Notification'
import { USERROLES } from '../../model/UserRole'

import messages from './Trainees.messages'

export interface PagesTraineeServiceMessages extends Message {
  type:
    | 'Pages/Trainee/Service/Loading'
    | 'Pages/Trainee/Service/Update'
    | 'Pages/Trainee/Service/Error'
    | 'Pages/Trainee/Service/Save'
    | 'Pages/Trainees/Notify'
    | 'Pages/Trainees/Delete'
  payload?: { ids: UUID[]; notificationType?: NotificationType }
}

export const connectTraineesPage = (store: AppStore) => {
  const { onLoginPageEnter, http, onPageLeave, onAction, snackBar, intl } =
    tools(store)
  const { api } = config()

  const successSnackbar = intl().formatMessage(messages.successSnackbar)
  const warningSnackbar = intl().formatMessage(messages.warningSnackbar)
  const errorSnackbar = intl().formatMessage(messages.errorSnackbar)

  const machine = pageStateMachine({
    id: 'connectTraineePage',
    fetchData,
    setLoading,
  })

  onLoginPageEnter(AppRoutes.Trainees, {
    roles: [USERROLES.CorPatchAdmin],
  }).subscribe(() => {
    setLoading()
    fetchData()
  })

  onPageLeave(AppRoutes.Institute).subscribe(() => machine.send('PAGE_LEAVE'))

  function setLoading() {
    store.next(state => {
      state.pages.trainees = defaultTraineesState
      state.pages.trainees.meta.loading = true
    })
  }

  function fetchData() {
    const path = new RouteParser(routes.trainees).reverse({})

    http<TraineeDTO[]>({ url: api + path }).subscribe(
      trainees => {
        store.next(
          state => {
            state.pages.trainees.data = trainees.map(t => {
              const firstnameInvalid =
                t.firstName === '' || t.firstName === 'null'
              const familyNameInvalid =
                t.familyName === '' || t.familyName === 'null'

              return {
                id: t._id,
                selected: false,
                isSelectionEnabled: true,
                displayName:
                  !familyNameInvalid && !firstnameInvalid
                    ? `${t.familyName}, ${t.firstName}`
                    : !familyNameInvalid
                    ? `${t.familyName}`
                    : !firstnameInvalid
                    ? `${t.firstName}`
                    : '---',
                ...t,
              }
            })
            state.pages.trainees.meta.loading = false
          },
          { type: 'Pages/Trainee/Service/Update' }
        )
      },

      _error => {
        console.error(_error)
        store.next(
          state => {
            state.pages.trainees = defaultTraineesState
            state.pages.trainees.meta.notFound = true
            state.pages.trainees.meta.loading = false
          },
          { type: 'Pages/Trainee/Service/Error' }
        )
      }
    )
  }

  onAction({ type: 'Pages/Trainees/Notify' }).subscribe(pck => {
    const { ids, notificationType } = (
      pck.message as PagesTraineeServiceMessages
    ).payload!

    let errorResponses = 0
    let successfulResponses = 0
    ids.forEach(id => {
      const data = { id, notificationType }

      http({
        url: api + routes.corPatchAdmin.notifyUser,
        method: 'POST',
        data,
      }).subscribe(
        // Snackbars do not translation because they are a dev-only feature
        () => {
          successfulResponses += 1
          if (successfulResponses === ids.length && errorResponses === 0) {
            snackBar.success('Users have been notified successfully')
          } else if (
            errorResponses > 0 &&
            successfulResponses + errorResponses === ids.length
          ) {
            snackBar.error('One or more users could not be notified')
          }
        },
        () => {
          errorResponses += 1
          if (errorResponses === ids.length) {
            snackBar.error(
              'An unknown error occured. No users have been notified'
            )
          }
        }
      )
    })
  })

  let errorResponses = 0
  let successfulResponses = 0
  onAction({ type: 'Pages/Trainees/Delete' }).subscribe(pck => {
    let ids = (pck.message as PagesTraineeServiceMessages).payload?.ids ?? []

    ids.forEach(id => {
      http({
        method: 'DELETE',
        route: {
          path: routes.userDelete,
          id,
        },
      }).subscribe(
        () => {
          successfulResponses += 1
          if (successfulResponses === ids.length && errorResponses === 0) {
            snackBar.success(successSnackbar)
          } else if (
            errorResponses > 0 &&
            successfulResponses + errorResponses === ids.length
          ) {
            snackBar.error(warningSnackbar)
          }
        },
        () => {
          errorResponses += 1
          if (errorResponses === ids.length) {
            snackBar.error(errorSnackbar)
          }

          const index = ids.indexOf(id)
          if (index !== -1) {
            ids?.splice(index, 1)
          }
        }
      )
    })

    const nextTraineeData = store.state.pages.trainees.data.filter(
      trainee => !ids?.includes(trainee._id)
    )
    store.next(s => {
      s.pages.trainees.data = nextTraineeData
    })
  })
}
