import { defineMessages } from 'react-intl'

export default defineMessages({
  logoutTitle: {
    id: 'cp.pages.logout.title',
    defaultMessage: 'Goodbye!',
  },
  logoutMessage: {
    id: 'cp.pages.logout.message',
    defaultMessage: 'See you next time',
  },
  loginButton: {
    id: 'cp.pages.logout.loginButton',
    defaultMessage: 'LOGIN',
  },
})
