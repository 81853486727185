import { Message } from '@restate/core'
import { USERROLES } from 'model/UserRole'
import { routes } from 'model/ctrl/routes'
import { AppRoutes } from 'routes'
import { config } from '../../config/config'
import { tools } from '../../services/utils/tools'
import { AppStore } from '../../state/store'
import { SharingTraineeAggregate } from 'model/SharingTraineeAggregate'
import messages from './TraineeShares.messages'

export interface PagesTraineeSharesAddMessages extends Message {
  type: 'Pages/TraineeShares/Shares/Add'
}

export interface PagesTraineeSharesDeactivateMessages extends Message {
  type: 'Pages/TraineeShares/Shares/Deactivate'
  sharingTrainee: SharingTraineeAggregate
}

export const connectTraineesSharePage = (store: AppStore) => {
  const {
    http,
    onAction,
    snackBar,
    onLoginPageEnter,
    goTo,
    intl,
    pageParams,
  } = tools(store)
  const { api } = config()

  function fetchSharingTrainees() {
    store.next(state => {
      state.pages.traineeShares.meta.loading = true
    })

    http<SharingTraineeAggregate[]>({
      route: { path: routes.sharing.trainee.sharingObjects },
    }).subscribe(
      res => {
        store.next(state => {
          state.pages.traineeShares.sharingTrainee = res
          state.pages.traineeShares.meta.loading = false
        })
      },
      () => {
        store.next(state => {
          state.pages.traineeShares.meta.loading = false
        })
      }
    )
  }

  onLoginPageEnter(AppRoutes.TraineeShares, {
    roles: [USERROLES.Trainee],
  }).subscribe(() => {
    fetchSharingTrainees()
  })

  onLoginPageEnter(AppRoutes.TraineeSharesInvite, {
    roles: [USERROLES.Trainee],
  }).subscribe(() => {
    const params = pageParams(AppRoutes.TraineeSharesInvite)
    const { id } = params()

    store.next(s => {
      s.pages.traineeShares.addDialogOpen = true
      s.pages.traineeShares.addDialogCode = id
    })

    goTo(AppRoutes.TraineeShares)
  })

  onAction({ type: 'Pages/TraineeShares/Shares/Add' }).subscribe(pck => {
    store.next(s => {
      s.pages.traineeShares.addDialogOpen = false
    })

    const url = api + routes.sharing.trainee.acceptInvitation
    const invitationCode = pck.state.pages.traineeShares.addDialogCode

    if (!invitationCode || invitationCode.length === 0) {
      const msg = intl().formatMessage(messages.snackBarInvalidCode)
      return snackBar.error(msg)
    }

    http({
      url,
      method: 'POST',
      data: { invitationCode },
    }).subscribe(
      () => {
        fetchSharingTrainees()
        snackBar.success(intl().formatMessage(messages.snackBarInvitationAdded))
      },
      e => {
        snackBar.error(
          intl().formatMessage(messages.snackBarErrorAddingInvitation, {
            errorMessage: e.errorMessage,
          })
        )
      }
    )
  })

  onAction({ type: 'Pages/TraineeShares/Shares/Deactivate' }).subscribe(pck => {
    const sharingTrainee = (pck.message as PagesTraineeSharesDeactivateMessages)
      .sharingTrainee

    const url = api + routes.sharing.trainee.deactivateInvitation

    http({
      url,
      method: 'POST',
      data: { sharingTraineeId: sharingTrainee._id },
    }).subscribe(
      () => {
        fetchSharingTrainees()
        snackBar.success(
          intl().formatMessage(messages.snackBarSharingDeactivated)
        )
      },
      e => {
        snackBar.error(
          intl().formatMessage(messages.snackBarErrorDeactivatingSharing, {
            errorMessage: e.errorMessage,
          })
        )
      }
    )
  })
}
