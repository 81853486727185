import { IconButton, TableCell, Tooltip } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/GetAppRounded'
import axios from 'axios'
import {
  GenericTable,
  GenericTableCellHeader,
  GenericTableData,
} from 'components/GenericTable/GenericTable'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import React from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import RouteParser from 'route-parser'
import { useNextAppState, useAppState } from 'state/store'
import { useExportState } from './Export.restate'
import { ExportTableData } from './Export.state'

import messages from './Export.messages'
import tableMessages from '../../components/CourseTable/CourseTable.messages'
import { USERROLES } from '../../model/UserRole'

const cellHeaders = (
  Provider: string,
  Date: string,
  Identifier: string,
  Location: string,
  TraineeCount: string
): GenericTableCellHeader[] => {
  return [
    { id: 'institute', numeric: false, disablePadding: false, label: Provider },
    { id: 'date', numeric: false, disablePadding: false, label: Date },
    {
      id: 'identifier',
      numeric: false,
      disablePadding: false,
      label: Identifier,
    },
    { id: 'location', numeric: false, disablePadding: false, label: Location },
    {
      id: 'traineeCount',
      numeric: false,
      disablePadding: false,
      label: TraineeCount,
    },
  ]
}
interface ExportTableProps {
  data: ExportTableData[]
  download: (selected: string[]) => any
}

export const ExportTable: React.FC<ExportTableProps> = props => {
  const intl = useIntl()
  const tableRowProvider = intl.formatMessage(tableMessages.tableRowProvider)
  const tableRowDate = intl.formatMessage(tableMessages.tableRowDate)
  const tableRowIndetifier = intl.formatMessage(
    tableMessages.tableRowIdentifier
  )
  const tableRowLocation = intl.formatMessage(tableMessages.tableRowLocation)
  const tableRowTraineeCount = intl.formatMessage(
    tableMessages.tableRowTraineeCount
  )
  const downloadMessage = intl.formatMessage(tableMessages.downloadMessage)
  const downloadCSVTitle = intl.formatMessage(tableMessages.downloadCSVTitle)
  const deleteLabel = intl.formatMessage(tableMessages.deleteLabel)

  const userRole = useAppState(s => s.session.user.role)

  const { data, download } = props

  const search = (searchTerm: string) => {
    const searchReg = RegExp(searchTerm, 'i')
    return (entry: ExportTableData & GenericTableData) =>
      searchReg.test(entry.institute) ||
      searchReg.test(entry.location) ||
      searchReg.test(entry.identifier)
  }
  return (
    <GenericTable
      rows={data}
      initialSortDirection='asc'
      initialSortProperty='date'
      headers={cellHeaders(
        tableRowProvider,
        tableRowDate,
        tableRowIndetifier,
        tableRowLocation,
        tableRowTraineeCount
      )}
      search={search as any}
      singleSelect={true}
      checkboxEnabled={userRole === USERROLES.CorPatchAdmin ? true : false}
      selectedItemsMessage={() => (
        <FormattedMessage id='exportTable' defaultMessage={downloadMessage} />
      )}
      rowRender={({ row }: { row: ExportTableData }) => (
        <>
          <TableCell>{row.institute}</TableCell>
          <TableCell>
            {
              <FormattedDate
                value={row.date}
                year='numeric'
                month='2-digit'
                day='2-digit'
              />
            }
          </TableCell>
          <TableCell>{row.identifier}</TableCell>
          <TableCell>{row.location}</TableCell>
          <TableCell>{row.traineeCount}</TableCell>
        </>
      )}
      tools={({ selected }) =>
        userRole === USERROLES.CorPatchAdmin ? (
          <Tooltip title={downloadCSVTitle}>
            <IconButton
              aria-label={deleteLabel}
              onClick={() => download(selected)}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        ) : null
      }
    />
  )
}

export const Export: React.FC<{}> = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)

  const { loading } = useExportState(s => s.meta)
  const courses = useExportState(s => s.courses)
  const next = useNextAppState(s => s)

  const download = (selected: string[]) => {
    // alert('Download ' + selected[0])
    const id = selected[0]
    const course = courses.find(course => course.id === id)

    let fileName = 'data.zip'
    if (course) {
      fileName = (
        course.date.toISOString().slice(0, 19) +
        '-' +
        course.institute +
        '.zip'
      ).replace(/\s/g, '_')
    }

    const parser = new RouteParser(routes.export.csv)
    const path = parser.reverse({ id })
    const url = config().api + path

    next(state => {
      state.pages.export.meta.loading = true
    })

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: props => console.log(props),
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      next(state => {
        state.pages.export.meta.loading = false
      })
    })
  }

  return (
    <AppPageLayout title={pageTitle} loading={loading}>
      <ExportTable data={courses} download={download} />
    </AppPageLayout>
  )
}

export default Export
