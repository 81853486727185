import { defineMessages } from 'react-intl'

export default defineMessages({
  loadingTitle: {
    id: 'cp.pages.trainings.title',
    defaultMessage: 'Trainings',
  },
  trainingsNotFoundTitle: {
    id: 'cp.pages.trainings.notFound.title',
    defaultMessage: 'Nicht gefunden.',
  },
  trainingsNotFoundMessage: {
    id: 'cp.pages.trainings.notFound.message',
    defaultMessage: 'Wurde gelöscht. Sorry :(',
  },
  trainingsTrainee: {
    id: 'cp.pages.trainings.trainee',
    defaultMessage: 'Trainee',
  },
  trainingsFlow: {
    id: 'cp.pages.trainings.flow',
    defaultMessage: 'Flow',
  },
  trainingsDepth: {
    id: 'cp.pages.trainings.depth',
    defaultMessage: 'Depth',
  },
  trainingsRecoil: {
    id: 'cp.pages.trainings.recoil',
    defaultMessage: 'Recoil',
  },
  trainingsFrequency: {
    id: 'cp.pages.trainings.frequency',
    defaultMessage: 'Frequency',
  },
  trainingNumber: {
    id: 'cp.pages.trainings.trainingNumber',
    defaultMessage: 'Training #',
  },
})
