export const routes = {
  auth: {
    login: '/api/auth/login',
    validateLogin: '/api/auth/authenticate/email',
    changeRole: '/api/auth/changeRole',
    me: '/api/auth/me', // TODO: move to `me`
    signup: '/api/auth/signup',
    delete: '/api/auth/delete',
    traineeSignUp: '/api/auth/traineeSignUp',
    validate: '/api/auth/validate/:id',
    validateEmail: '/api/auth/email/validate/:id',
    initialPassword: '/api/auth/initialPassword',
  },
  forgotPassword: {
    sendEmail: '/api/auth/forgotPassword/sendEmail',
    resetPassword: '/api/auth/forgotPassword/resetPassword',
    checkCode: '/api/auth/forgotPassword/checkCode/:id',
  },
  corPatchAdmin: {
    institute: '/api/corpatch-admin/institutes/:id',
    institutes: '/api/corpatch-admin/institutes/',
    globalConfig: '/api/corpatch-admin/global-config',
    resultConfig: '/api/corpatch-admin/resultConfig/institute/:id',
    resultConfigInstituteAdmin: '/api/institute-admin/resultConfig/institute',
    deletedData: '/api/deletion',
    notifyUser: '/api/notification/user',
    testNotifications: '/api/notification/test/:id',
  },
  cron: {
    notifications: '/api/cron/notifications',
    purgeData: '/api/cron/purge',
  },
  export: {
    csv: '/api/export/csv/:id',
    json: '/api/export/json/:id',
    allCPS: '/api/export/cpx',
    allMobiles: '/api/export/mobiles',
  },
  me: {
    changeEmail: '/api/me/email',
    changePassword: '/api/me/password',
    changeMe: '/api/me/update',
    settings: '/api/me/settings',
  },
  research: {
    courses: '/api/research/courses',
    rawData: '/api/research/rawData',
  },
  mobile: {
    count: '/api/mobile/count',
    device: '/api/mobile/devices/:id',
    devices: '/api/mobile/devices',
    status: '/api/mobile/status',
  },
  cpx: {
    user: '/api/cpx/user/device',
    count: '/api/cpx/count',
    connect: '/api/cpx/connect',
    device: '/api/cpx/devices/:id',
    devices: '/api/cpx/devices',
  },
  cpt: {
    config: '/api/config',
  },
  cps: {
    config: '/api/cps/config',
    pushToken: '/api/cps/push',
    singleTrainings: '/api/cps/single-training',
    userSingleTrainings: '/api/cps/single-training/:id',
  },
  onboarding: {
    onboarding: '/api/onboarding',
    resendVerificationEmail: '/api/onboarding/email/validate',
  },
  course: '/api/courses/:id',
  courses: '/api/courses',
  dashboard: '/api/dashboard',
  hooks: {
    ecwid: '/api/hooks/ecwid',
  },
  sharing: {
    trainee: {
      acceptInvitation: '/api/sharing/trainee/accept-invitation',
      deactivateInvitation: '/api/sharing/trainee/deactivate-invitation',
      sharingObjects: '/api/sharing/trainee/sharing-objects',
      postSingleTraining: '/api/sharing/trainee/post-single-training',
    },
    admin: {
      deleteSharingObject: '/api/sharing/admin/delete-sharing-object/:id',
    },
    instituteAdmin: {},
    pullRequest: '/api/sharing/pull/:sharingObjectId',
    sharingObject: '/api/sharing/sharingObjects/:id',
    sharingObjects: '/api/sharing/sharingObjects',
    dummy: '/api/sharing/dummy',
  },
  rawData: '/api/rawData',
  shopSso: '/api/shop',
  trainees: '/api/trainees',
  trainer: '/api/trainers/:id',
  trainers: '/api/trainers',
  user: '/api/user/:id',
  userInviteAgain: '/api/user/:id/invite',
  userDelete: '/api/user/:id/delete',
  debug: '/api/debug',
  debugEntry: '/api/debug/:sessionId',
}
