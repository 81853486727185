export const config = () => ({
  api: process.env.REACT_APP_API_SERVER_URL || '',

  apiAbsolute:
    process.env.REACT_APP_API_SERVER_URL || window.location.origin || '',

  passwordMinLen: 8,

  APPLE_APP_STORE_CPS_URL:
    'https://apps.apple.com/us/app/corpatch/id1570110910',
  GOOGLE_PLAY_STORE_CPS_URL:
    'https://play.google.com/store/apps/details?id=com.corpatch.app.cps',
})
