import { defineMessages } from 'react-intl'

export default defineMessages({
  drawerShop: {
    id: 'cp.components.appDrawer.drawerShop',
    defaultMessage: 'Shop',
  },
  drawerTrainers: {
    id: 'cp.components.appDrawer.drawerTrainers',
    defaultMessage: 'Trainers',
  },
  drawerCourses: {
    id: 'cp.components.appDrawer.drawerCourses',
    defaultMessage: 'Courses',
  },
  drawerSingleTrainings: {
    id: 'cp.components.appDrawer.drawerSingleTrainings',
    defaultMessage: 'Trainings',
  },
  drawerExport: {
    id: 'cp.components.appDrawer.drawerExport',
    defaultMessage: 'Export',
  },
  drawerSettings: {
    id: 'cp.components.appDrawer.drawerSettings',
    defaultMessage: 'Settings',
  },
  drawerInstitutes: {
    id: 'cp.components.appDrawer.drawerInstitutes',
    defaultMessage: 'Institutes',
  },
  drawerTrainees: {
    id: 'cp.components.appDrawer.drawerTrainees',
    defaultMessage: 'Trainees',
  },
  drawerImprint: {
    id: 'cp.components.appDrawer.drawerImprint',
    defaultMessage: 'Imprint',
  },
  drawerShares: {
    id: 'cp.components.appDrawer.drawerShares',
    defaultMessage: 'Shares',
  },
})
