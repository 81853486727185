import { Message } from '@restate/core'
import { SharingTraining } from 'model/SharingTraining'
import { SharingTrainingDto } from 'model/SharingTraining.dto'
import { routes } from 'model/ctrl/routes'
import { SingleTrainingDocument } from 'model/db/SingleTrainingDocument.db-schema'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { USERROLES } from '../../model/UserRole'

export interface SingleTrainingView extends SingleTrainingDocument {
  origin: string
}

export interface SingleTrainingShareMessage extends Message {
  type: 'Page/SingleTraining/Share'
}

export const connectSingleTrainingsPage = (store: AppStore) => {
  const { onLoginPageEnter, http, snackBar, hasUserRole, onAction } = tools(
    store
  )

  onLoginPageEnter(AppRoutes.SingleTrainings).subscribe(() => {
    hasUserRole(USERROLES.Trainee).subscribe(() => {
      http({
        route: { path: routes.sharing.trainee.sharingObjects },
      }).subscribe(res => {
        store.next(state => {
          state.pages.singleTrainings.traineeSharingObjects = res as any // TODO: fix typing
        })
      })

      http<SingleTrainingView[]>({
        route: { path: routes.cps.singleTrainings },
      }).subscribe(
        res => {
          store.next(state => {
            state.pages.singleTrainings.singleTrainings = res
          })
        },
        () => {
          snackBar.error('Single Trainings could not be fetched')
        }
      )
    })
  })

  onAction({ type: 'Page/SingleTraining/Share' }).subscribe(() => {
    const { share } = store.state.pages.singleTrainings
    const sharingTrainingDto: SharingTrainingDto = {
      singleTrainingId: share.selectedTrainingId,
      sharingObjectIds: share.selectedTraineeSharingObject,
    }

    store.next(state => {
      state.pages.singleTrainings.share.selectedTrainingId = ''
      state.pages.singleTrainings.share.selectedTraineeSharingObject = []
    })

    http<SharingTraining>({
      method: 'POST',
      route: { path: routes.sharing.trainee.postSingleTraining },
      data: sharingTrainingDto,
    }).subscribe(
      _success => {
        snackBar.success('Single Training shared!')
        http({
          route: { path: routes.sharing.trainee.sharingObjects },
        }).subscribe(res => {
          store.next(state => {
            state.pages.singleTrainings.traineeSharingObjects = res as any // TODO: fix typing
          })
        })
      },
      _err => {
        snackBar.error('Single Training could not be shared')
        console.log(_err)
      }
    )
  })
}
