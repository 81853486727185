import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    id: 'cp.pages.me.title',
    defaultMessage: 'Personal setting',
  },
  notFoundTitle: {
    id: 'cp.pages.me.notFoundTitle',
    defaultMessage: 'Personal setting',
  },
  firstName: {
    id: 'cp.pages.me.firstName',
    defaultMessage: 'First name',
  },
  familyName: {
    id: 'cp.pages.me.familyName',
    defaultMessage: 'Family name',
  },
  gender: {
    id: 'cp.pages.me.gender',
    defaultMessage: 'Gender',
  },
  genderMale: {
    id: 'cp.pages.me.genderMale',
    defaultMessage: 'Male',
  },
  genderFemale: {
    id: 'cp.pages.me.genderFemale',
    defaultMessage: 'Female',
  },
  genderOther: {
    id: 'cp.pages.me.genderOther',
    defaultMessage: 'Other',
  },
  save: {
    id: 'cp.pages.me.save',
    defaultMessage: 'Save',
  },
  snackBarSaved: {
    id: 'cp.pages.me.snackBarSaved.saved',
    defaultMessage: 'Saved',
  },
  snackBarError: {
    id: 'cp.pages.me.snackBarSaved.error',
    defaultMessage: 'Error saving personal setting',
  },
  deleteOwnButtonRequest: {
    id: 'cp.pages.me.deleteOwnButtonRequest',
    defaultMessage: 'Delete your account permenantly',
  },
  deleteOwnContent: {
    id: 'cp.pages.me.deleteOwnContent',
    defaultMessage:
      'This action will delete your account permanently. Please be confident that you have saved any data that might get lost when deleting your account. Be advised that you can not reverse this action.',
  },
  deleteOwnButtonConfirm: {
    id: 'cp.pages.me.deleteOwnButtonConfirm',
    defaultMessage: 'Delete your account permanently',
  },
})
