import { Message } from '@restate/core'
import axios from 'axios'
import { config } from 'config/config'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { routes } from 'model/ctrl/routes'
import { CorpatchAdminUserDTO, UserDTO } from '../../model/User.dto'
import { TwoFactorAuthenticationResponse } from '../../model/ctrl/login/LoginRes.schema'
import { AppRoutes } from '../../routes'

import validationMessages from '../../pages/ValidateLogin/ValidateLogin.messages'

export const LS_VERIFICATION_KEY = 'corpatch-email-verification'

export enum LoginService {
  Login = 'Service/Login/Request',
  LoginSuccess = 'Service/Login/Success',
  LoginValidate = 'Service/Login/Validate',
}

export interface LoginMessage extends Message {
  type: LoginService.Login
  payload: {
    email: string
    password: string
  }
}

const is2FAValidationReponse = (
  res: UserDTO | TwoFactorAuthenticationResponse
): res is TwoFactorAuthenticationResponse =>
  Boolean((res as TwoFactorAuthenticationResponse).verificationToken)

export interface LoginValidateMessage extends Message {
  type: LoginService.LoginValidate
  payload: {
    validationToken: string
    verificationToken: string
  }
}

export type LoginServiceMessages =
  | LoginMessage
  | { type: LoginService.LoginSuccess }
  | LoginValidateMessage

export const connectLogin = (store: AppStore) => {
  const { onAction, snackBar, http, goTo, intl } = tools(store)

  onAction({ type: LoginService.Login }).subscribe(event => {
    const { api } = config()
    const { email, password } = (event.message as LoginMessage).payload

    store.next(s => {
      s.pages.login.showProgress = true
      s.pages.login.error = ''
    })

    axios
      .post(api + routes.auth.login, {
        email,
        password,
      })
      .then(function (response) {
        const data: UserDTO | TwoFactorAuthenticationResponse =
          response.data.data

        if (is2FAValidationReponse(data)) {
          localStorage.setItem(LS_VERIFICATION_KEY, data.verificationToken)
          return goTo(AppRoutes.ValidateLoginPublic)
        }

        store.next(s => {
          s.session.user = data
          s.pages.login.showProgress = false
          s.session.token = data.token
        })
        store.dispatch({ type: LoginService.LoginSuccess })
      })
      .catch(function () {
        store.next(s => {
          s.pages.login.showProgress = false
          s.pages.login.error = 'Error'
        })
      })
  })

  onAction({ type: LoginService.LoginValidate }).subscribe(event => {
    const {
      validationToken,
      verificationToken,
    } = (event.message as LoginValidateMessage).payload

    http<CorpatchAdminUserDTO>({
      route: {
        path: routes.auth.validateLogin,
      },
      method: 'POST',
      data: {
        validationToken,
        verificationToken,
      },
    }).subscribe(
      response => {
        store.next(s => {
          s.session.user = response
          s.session.token = response.token
        })

        localStorage.removeItem(LS_VERIFICATION_KEY)

        goTo(AppRoutes.Home)
      },
      () => {
        const errorSnackbar = intl().formatMessage(
          validationMessages.errorSnackbar
        )
        snackBar.error(errorSnackbar)
      }
    )
  })
}
