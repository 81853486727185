import { Patch } from 'immer'
import { RxStore, StatePackage } from '@restate/core'
// import sourceMapSupport from 'source-map-support'

interface LoggerProps {
  color?: string
  backgroundColor?: string
}

export function connectLogger(
  appStore: RxStore<any, any>,
  props: LoggerProps = { color: 'white', backgroundColor: 'DarkOrange' }
) {
  const { color, backgroundColor } = props

  // sourceMapSupport.install()

  const name =
    appStore.options.storeName === '' ? 'RxState' : appStore.options.storeName

  appStore.state$.subscribe(update => {
    console.groupCollapsed(
      formatGroupName(name, update),
      `color: ${color}; background:${backgroundColor};`
    )
    formatPatches(update.patches || [])
    console.log('ID', (update as any).id)
    console.log('State: ', update.state)
    if (!/password/.test(JSON.stringify(update.message))) {
      console.log('Message: ', update.message)
    }
    formatStack(update)
    console.groupEnd()
  })
}

function formatStack(update: StatePackage<any, any>) {
  const stack: string | undefined = (update as any).stack
  if (!stack) return

  const URL_REG = /http:.*:\d/

  const lines = stack
    .split('\n')
    .map(line => line.trim())
    .filter(line => !/^Error/.test(line))
    .filter(line => !/internal\/operators/.test(line))
    .filter(line => !/internal\/Scheduler.ts/.test(line))
    .filter(line => !/internal\/scheduler/.test(line))
    .filter(line => !/internal\/Subject.ts/.test(line))
    .filter(line => !/internal\/BehaviorSubject.ts/.test(line))
    .filter(line => !/internal\/Observable.ts/.test(line))
    .filter(line => !/node_modules/.test(line))
    .filter(line => !/Notification.observe/.test(line))
    .filter(line => !/rx-store.ts/.test(line))
    .filter(line => !/RxStore/.test(line))
    .filter(line => !/Subscriber.ts/.test(line))
    .filter(line => !/webpack\/bootstrap/.test(line))
    .filter(line => !/chunk.js/.test(line))
    .filter(line => URL_REG.test(line))
    .map(line => {
      const match = URL_REG.exec(line)
      return match ? match[0] : ''
    })

  console.log('Source :', lines)
}

function formatGroupName(name: string, update: StatePackage<any, any>) {
  const MAX_LEN = 60

  let payload =
    update.message.payload != null ? JSON.stringify(update.message.payload) : ''

  if (/password/.test(payload)) {
    return `%c [${name}] : UNDEFINED`
  }

  if (payload.length > MAX_LEN) {
    payload = payload.substring(0, MAX_LEN - 3) + '...'
  }

  return `%c [${name}] : ${update.message.type} ${payload}`
}

function formatPatches(patches: Patch[]) {
  patches.forEach(patch => {
    const path = patch.path.join('.')
    switch (patch.op) {
      case 'add': {
        console.groupCollapsed(
          '%c Add "' + path + '":',
          'color: green',
          patch.value
        )
        console.log(JSON.stringify(patch.value, null, 2))
        console.groupEnd()
        break
      }
      case 'remove': {
        console.groupCollapsed(
          '%c Remove "' + path + '":',
          'color: red',
          patch.value
        )
        console.log(JSON.stringify(patch.value, null, 2))
        console.groupEnd()
        break
      }
      case 'replace': {
        console.groupCollapsed(
          '%c Replace "' + path + '":',
          'color: #008800',
          patch.value
        )
        console.log(JSON.stringify(patch.value, null, 2))
        console.groupEnd()
        break
      }

      default: {
        console.groupCollapsed(
          '%c Default "' + path + '":',
          'color: green',
          patch.value
        )
        console.log(JSON.stringify(patch.value, null, 2))
        console.groupEnd()
      }
    }
  })
}
