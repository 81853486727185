import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

enum NetworkServiceMessageType {
  Offline = 'Service/Network/Offline',
  Online = 'Service/Network/Online',
}

export interface NetworkServiceMessages {
  type: NetworkServiceMessageType
}

export const connectNetwork = (store: AppStore) => {
  const { onAction } = tools(store)

  window.addEventListener('offline', () => {
    store.next(
      state => {
        state.network.online = false
      },
      { type: NetworkServiceMessageType.Offline }
    )
  })

  window.addEventListener('online', () => {
    store.next(
      state => {
        state.network.online = true
      },
      { type: NetworkServiceMessageType.Online }
    )
  })

  function init() {
    const online = navigator.onLine
    store.next(
      state => {
        state.network.online = online
      },
      {
        type: online
          ? NetworkServiceMessageType.Online
          : NetworkServiceMessageType.Offline,
      }
    )
  }

  onAction({ type: 'App/Init' }).subscribe(init)
}
