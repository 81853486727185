import {
  Button,
  CardContent,
  CardHeader,
  FormGroup,
  TextField,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { useDispatchHook } from 'state/store'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import {
  useInstituteActions,
  useInstituteState,
  useInstituteResultConfigState,
  useNextInstituteResultConfigState,
} from '../Institute.restate'
import { useIntl } from 'react-intl'
import messages from './InstituteForm.messages'
import Modal from '@material-ui/core/Modal'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Locale } from '../../../model/Locale'
import FormControl from '@material-ui/core/FormControl'

const useStyles = makeStyles({
  card: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    width: '100%',
  },

  field: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      width: '100%',
    },
  },

  button: {
    margin: theme.spacing(1),
  },

  secondaryButton: {
    margin: theme.spacing(1),
    color: Colors.neutrals[4],
  },

  modal: {
    position: 'absolute',
    height: '250',
    width: '250',
    top: '40%',
    left: '40%',
    padding: '20px',
  },

  cardHeader: {},
})

export const InstituteForm = () => {
  const intl = useIntl()

  const institute = intl.formatMessage(messages.institute)
  const instituteAddress = intl.formatMessage(messages.instituteAddress)
  const instituteName = intl.formatMessage(messages.instituteName)
  const instituteStreet = intl.formatMessage(messages.instituteStreet)
  const instituteStreetAdditional = intl.formatMessage(
    messages.instituteStreetAdditional
  )
  const instituteState = intl.formatMessage(messages.instituteState)
  const instituteZIP = intl.formatMessage(messages.instituteZIP)
  const instituteCity = intl.formatMessage(messages.instituteCity)
  const instituteCountry = intl.formatMessage(messages.instituteCountry)
  const instituteSaveButton = intl.formatMessage(messages.instituteSaveButton)
  const instituteResultConfigLabelGood = intl.formatMessage(
    messages.instituteResultConfigLabelGood
  )
  const instituteResultConfigLabelGreat = intl.formatMessage(
    messages.instituteResultConfigLabelGreat
  )
  const instituteResultConfigSave = intl.formatMessage(
    messages.instituteResultConfigSave
  )
  const instituteResultConfigInfo = intl.formatMessage(
    messages.instituteResultConfigInfo
  )
  const instituteResultConfigBtn = intl.formatMessage(
    messages.instituteResultConfigBtn
  )
  const localeLabel = intl.formatMessage(messages.localeLabel)
  const localeEnglish = intl.formatMessage(messages.localeEnglish)
  const localeGerman = intl.formatMessage(messages.localeGerman)
  const localeDanish = intl.formatMessage(messages.localeDanish)
  const localeFinish = intl.formatMessage(messages.localeFinish)
  const localeItalian = intl.formatMessage(messages.localeItalian)
  const localeFrench = intl.formatMessage(messages.localeFrench)
  const localeSpanish = intl.formatMessage(messages.localeSpanish)

  const { card, field, button, modal } = useStyles()
  const state = useInstituteState(s => s)
  const instituteResultConfig = useInstituteResultConfigState(s => s)
  const instituteActions = useInstituteActions()
  const dispatch = useDispatchHook()

  const nextResultConfig = useNextInstituteResultConfigState(s => s)
  const [isResultConfigOpen, setResultConfigOpen] = useState(false)
  const [thresholdGood, setThresholdGood] = useState(
    instituteResultConfig.simple.thresholdGood
  )
  const [thresholdGreat, setThresholdGreat] = useState(
    instituteResultConfig.simple.thresholdGreat
  )
  const [touched, setTouched] = useState(false)

  const onSave = () => dispatch({ type: 'Pages/Institute/Service/Save' })
  const dispatchResultConfig = () => {
    nextResultConfig(s => {
      s.simple.thresholdGood = thresholdGood
      s.simple.thresholdGreat = thresholdGreat
    })
    dispatch({ type: 'Pages/Institute/CorPatchAdmin/ResultConfig/Save' })
    setTouched(false)
    setResultConfigOpen(false)
  }
  return (
    <div style={{ width: '100%' }}>
      <Card className={card}>
        <CardHeader title={institute} subheader={instituteAddress} />
        <CardContent>
          <FormGroup row>
            <TextField
              label={instituteName}
              required
              className={field}
              style={{ width: '100%' }}
              value={state.name}
              onChange={e => instituteActions.setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              label={instituteStreet}
              required
              style={{ width: '100%' }}
              className={field}
              value={state.address.street}
              onChange={e => instituteActions.setStreet1(e.target.value)}
            />
            <TextField
              label={instituteStreetAdditional}
              style={{ width: '100%' }}
              className={field}
              value={state.address.co1}
              onChange={e => instituteActions.setCo1(e.target.value)}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              label={instituteCountry}
              required
              className={field}
              style={{ width: '48%' }}
              value={state.address.country}
              onChange={e => instituteActions.setCountry(e.target.value)}
            />
            <TextField
              label={instituteState}
              className={field}
              style={{ width: '48%' }}
              value={state.address.state}
              onChange={e => instituteActions.setState(e.target.value)}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              label={instituteZIP}
              required
              className={field}
              style={{ width: '34%' }}
              value={state.address.zip}
              onChange={e => instituteActions.setPlz(e.target.value)}
            />
            <TextField
              label={instituteCity}
              required
              className={field}
              style={{ width: '34%' }}
              value={state.address.city}
              onChange={e => {
                instituteActions.setCity(e.target.value)
              }}
            />
            <FormControl className={field} style={{ width: '27%' }}>
              <InputLabel id='locale-select'>{localeLabel}</InputLabel>
              <Select
                value={
                  state.settings?.locale ? state.settings.locale : Locale.en
                }
                onChange={e =>
                  instituteActions.setLocale(e.target.value as Locale)
                }
                labelId='locale-select'
              >
                <MenuItem value={Locale.en}>{localeEnglish}</MenuItem>
                <MenuItem value={Locale.de}>{localeGerman}</MenuItem>
                <MenuItem value={Locale.da}>{localeDanish}</MenuItem>
                <MenuItem value={Locale.fi}>{localeFinish}</MenuItem>
                <MenuItem value={Locale.it}>{localeItalian}</MenuItem>
                <MenuItem value={Locale.fr}>{localeFrench}</MenuItem>
                <MenuItem value={Locale.es}>{localeSpanish}</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </CardContent>
      </Card>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          marginTop: theme.spacing(2),
        }}
      >
        <Button
          variant='contained'
          color='secondary'
          size='small'
          className={button}
          onClick={onSave}
        >
          {instituteSaveButton}
        </Button>
        <Button
          variant='contained'
          color='primary'
          size='small'
          className={button}
          onClick={() => {
            setResultConfigOpen(true)
            setThresholdGood(instituteResultConfig.simple.thresholdGood)
            setThresholdGreat(instituteResultConfig.simple.thresholdGreat)
          }}
        >
          {instituteResultConfigBtn}
        </Button>
      </div>

      <Modal
        open={isResultConfigOpen}
        onClose={() => setResultConfigOpen(false)}
      >
        <Card className={modal}>
          <FormGroup row>
            <TextField
              label={instituteResultConfigLabelGood}
              style={{ width: '100%' }}
              required
              type='number'
              className={field}
              value={thresholdGood}
              onChange={e => {
                if (
                  Number.parseInt(e.target.value) >= 1 &&
                  Number.parseInt(e.target.value) <= 100
                ) {
                  setTouched(true)
                  setThresholdGood(Number.parseInt(e.target.value))
                }
              }}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              label={instituteResultConfigLabelGreat}
              style={{ width: '100%' }}
              required
              type='number'
              className={field}
              value={thresholdGreat}
              onChange={e => {
                if (
                  Number.parseInt(e.target.value) >= 1 &&
                  Number.parseInt(e.target.value) <= 100
                ) {
                  setTouched(true)
                  setThresholdGreat(Number.parseInt(e.target.value))
                }
              }}
            />
          </FormGroup>
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{ margin: '10px auto', display: 'block' }}
            onClick={dispatchResultConfig}
            disabled={!touched}
          >
            {instituteResultConfigSave}
          </Button>
          <FormGroup row>
            <div
              style={{
                maxWidth: '220px',
                fontSize: '10px',
                textAlign: 'center',
              }}
            >
              {instituteResultConfigInfo}
            </div>
          </FormGroup>
        </Card>
      </Modal>
    </div>
  )
}
