import { Message } from '@restate/core'
import md5 from 'md5'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

export interface UserMenuMessages extends Message {
  type: 'Component/UserMenu/Update'
}

//
// Initializes the UseMenu in the NavBar with the current user data
//

export const connectUserMenu = (store: AppStore) => {
  const { onChange } = tools(store)

  onChange(state => state.session.user).subscribe(user => {
    const emailHash = md5(user.email)
    const imgUrl = `https://www.gravatar.com/avatar/${emailHash}`

    store.next(
      state => {
        state.components.userMenu = {
          avatarUrl: imgUrl,
          userName: user.familyName,
          eMail: user.email,
        }
      },
      { type: 'Component/UserMenu/Update' }
    )
  })
}
