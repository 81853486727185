import { Message } from '@restate/core'
import { routes } from 'model/ctrl/routes'
import { CourseAggregate } from 'pages/Course/Course.state'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

export interface PagesServiceCourseUpdateMessage extends Message {
  type: 'Pages/Service/Course/Update' | 'Pages/Service/Course/Error'
}

export const connectTrainingsPageService = (store: AppStore) => {
  const { http, onLoginPageEnter } = tools(store)

  onLoginPageEnter(AppRoutes.Trainings).subscribe(params => {
    const { id, idx } = params.pageEnter

    const onSuccess = (course: CourseAggregate) =>
      store.next(
        state => {
          state.pages.trainings.idx = idx
          state.pages.course.course = course
          state.pages.course.meta.notFound = false
          state.pages.course.meta.loading = false
          state.pages.trainings.meta.notFound = false
          state.pages.trainings.meta.loading = false
        },
        { type: 'Pages/Service/Course/Update' }
      )

    const onError = () =>
      store.next(
        state => {
          state.pages.course.course = null
          state.pages.course.meta.notFound = true
          state.pages.course.meta.loading = false
          state.pages.trainings.meta.notFound = true
          state.pages.trainings.meta.loading = false
        },
        { type: 'Pages/Service/Course/Error' }
      )

    http<CourseAggregate>({
      route: {
        path: routes.course,
        id,
      },
    }).subscribe(onSuccess, onError)
  })
}
