import {
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitIcon from '@material-ui/icons/ExitToApp'
import PasswordIcon from '@material-ui/icons/LockOpen'
import EmailIcon from '@material-ui/icons/MailOutline'
import React from 'react'
import { useLogout } from 'services/utils/useLogout.hook'
import { useUserMenuState } from './UserMenu.restate'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useIntl } from 'react-intl'

import messages from './userMenu.messages'
import { AppRoutes } from 'routes'

const useClasses = makeStyles({
  avatarListItem: {
    minWidth: 250,
  },
  avatar: {
    width: 32,
    height: 32,
  },
})

interface UserMenuProps {
  anchorEl: HTMLElement
  open: boolean
  avatarUrl: string
  userName: string
  eMail: string

  handleClose: () => void
  handleAccount: () => void
  handleEmail: () => void
  handlePassword: () => void
  handleLogout: () => void
}

export const UserMenu: React.FC<UserMenuProps> = props => {
  const intl = useIntl()
  const Password = intl.formatMessage(messages.Password)
  const Logout = intl.formatMessage(messages.Logout)
  const EMail = intl.formatMessage(messages.EMail)

  const {
    open,
    userName,
    eMail,
    handleClose,
    anchorEl,
    handleLogout,
    handleAccount,
    handleEmail,
    handlePassword,
  } = props
  const classes = useClasses()

  return (
    <Menu
      id='user-menu'
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      keepMounted
    >
      <MenuItem
        button
        className={classes.avatarListItem}
        onClick={handleAccount}
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={userName} secondary={eMail} />
      </MenuItem>

      <Divider />

      <MenuItem button onClick={handleEmail}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary={EMail} />
      </MenuItem>

      <MenuItem button onClick={handlePassword}>
        <ListItemIcon>
          <PasswordIcon />
        </ListItemIcon>
        <ListItemText primary={Password} />
      </MenuItem>

      <Divider />

      <MenuItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText primary={Logout} />
      </MenuItem>
    </Menu>
  )
}

const ConnectedUserMenu: React.FC<{
  open: boolean
  handleClose: () => void
}> = ({ open, handleClose }) => {
  const { userName, eMail, avatarUrl } = useUserMenuState(s => s)
  const go = useGoToHook()
  const logout = useLogout()

  const handleMaker = (path: string) => () => {
    handleClose()
    go(path)
  }

  const handleLogout = () => {
    handleClose()
    logout()
  }

  const handleAccount = handleMaker(AppRoutes.MeAccount)
  const handleEmail = handleMaker(AppRoutes.MeEMail)
  const handlePassword = handleMaker(AppRoutes.MePassword)

  const anchorRef = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={anchorRef}></div>
      {anchorRef.current != null && (
        <UserMenu
          anchorEl={anchorRef.current}
          open={open}
          avatarUrl={avatarUrl}
          userName={userName}
          eMail={eMail}
          handleClose={handleClose}
          handleAccount={handleAccount}
          handleEmail={handleEmail}
          handlePassword={handlePassword}
          handleLogout={handleLogout}
        />
      )}
    </>
  )
}

export default ConnectedUserMenu
