import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress, Typography } from '@material-ui/core'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'

const SIZE = 75

const useClasses = makeStyles({
  box: {
    position: 'relative',
  },
  circleBackground: {
    position: 'absolute',
    color: Colors.neutrals[8],
  },
  circleForeground: {
    position: 'absolute',
  },
  text: {
    textAlign: 'center',
  },

  label: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
})

interface CircularTrainingProgressProps {
  value: number
  label?: string
  thickness?: number
  color?: string
  size?: number
  fontSize?: number
  styles?: React.CSSProperties
  className?: string
  showLabel?: boolean
}

export const CircularTrainingProgress: React.FC<CircularTrainingProgressProps> = props => {
  const {
    value,
    size = SIZE,
    fontSize = 16,
    color = Colors.vivid[5],
    thickness = 3,
    label = '',
    styles = {},
    className = '',
    showLabel = true,
  } = props
  const classes = useClasses()

  const sizeStyle = {
    height: size,
    width: size,
  }

  const labelStyle = {
    textAlign: 'center' as any,
    fontWeight: 'bold' as any,
    lineHeight: size + 'px',
    fontSize: fontSize + 'px',
    color: Colors.neutrals[3],
    ...sizeStyle,
  }

  return (
    <div style={{ width: size, ...styles }} className={className}>
      <div className={classes.box} style={sizeStyle}>
        <CircularProgress
          variant='static'
          size={SIZE}
          value={100}
          color='inherit'
          thickness={thickness}
          style={sizeStyle}
          className={classes.circleBackground}
        />
        <CircularProgress
          variant='static'
          color='inherit'
          thickness={thickness}
          size={SIZE}
          value={value}
          style={{ ...sizeStyle, color }}
          className={classes.circleForeground}
        />
        <div className={classes.text} style={labelStyle}>
          <Typography style={labelStyle as any}>
            {value}
            {'%'}
          </Typography>
        </div>
      </div>
      {showLabel && (
        <Typography
          color='inherit'
          className={classes.label}
          style={{ color: Colors.neutrals[2] }}
        >
          {label}
        </Typography>
      )}
    </div>
  )
}
