import { RxStore } from '@restate/core'
import { History } from 'history'
import { map } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppMessages } from 'state/appMessages'
import { State } from 'state/state'
import { GoMessage, RouterMessageType } from './router.restate'

export const defaultRouterExtensionState = {
  embeddedMode: false,
}

export interface RouterExtensionState {
  embeddedMode: boolean
}

interface ConnectRouterProps {
  store: RxStore<State, AppMessages>
  history: History<any>
}

export function connectRouter({ store, history }: ConnectRouterProps) {
  const { onAction } = tools(store)

  const subs = [
    onAction({ type: RouterMessageType.GO })
      .pipe(
        map(pack => pack.message as GoMessage),
        map(message => message.payload.path)
      )
      .subscribe(path => {
        history.push(path)
      }),
    onAction({ type: RouterMessageType.BACK }).subscribe(() => {
      history.goBack()
    }),
  ]

  return function cleanUp() {
    subs.forEach(sub => sub.unsubscribe())
  }
}
