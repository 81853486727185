import { List, ListItem, Typography } from '@material-ui/core'
import { UserRole } from 'model/UserRole'
import React from 'react'
import { useIntl } from 'react-intl'
import { YesNoDialog } from '../YesNoDialog/GenericConfirmDeletionDialog'
import messages from './Dialog.messages'

interface ConfirmDeletionDialogProps {
  open: boolean
  userRoles: UserRole[]
  onPositiveButtonClick: () => void
  onNegativeButtonClick: () => void
}

export const ConfirmDeletionDialog = ({
  open,
  userRoles,
  onPositiveButtonClick,
  onNegativeButtonClick,
}: ConfirmDeletionDialogProps) => {
  const intl = useIntl()
  const deleteDialogTitle = intl.formatMessage(
    messages.confirmDeleteDialogTitle
  )
  const deleteDialogContentDescription = intl.formatMessage(
    messages.confirmDeleteDialogContentDescription
  )
  const deleteDialogContentConclusion = intl.formatMessage(
    messages.confirmDeleteDialogContentConclusion
  )
  const deleteDialogButtonPrimary = intl.formatMessage(
    messages.deleteDialogButtonPrimary
  )
  const deleteDialogButtonSecondary = intl.formatMessage(
    messages.deleteDialogButtonSecondary
  )

  return (
    <YesNoDialog
      open={open}
      onNoButtonClick={onNegativeButtonClick}
      onYesButtonClick={onPositiveButtonClick}
      dialogTitle={deleteDialogTitle}
      yesButtonTitle={deleteDialogButtonPrimary}
      noButtonTitle={deleteDialogButtonSecondary}
    >
      <Typography>{deleteDialogContentDescription}</Typography>

      <List>
        {userRoles.map((role, index) => (
          <ListItem key={index}>
            <Typography>&#x2022; {role}</Typography>
          </ListItem>
        ))}
      </List>

      <Typography gutterBottom={true}>
        {deleteDialogContentConclusion}
      </Typography>
    </YesNoDialog>
  )
}
