import { AppStore } from 'state/store'
import { tools } from 'services/utils/tools'
import { distinctUntilChanged, delay, skip } from 'rxjs/operators'

//
// In order to change the language of the store we have to reload the page.
//
// The other part - loading the ECWID store - is in the `index.html`
// See https://github.com/kodira/corpatch/issues/247
//
export const connectEcwidLocaleService = (store: AppStore) => {
  const { onChange } = tools(store)

  onChange(state => state.i18n.locale)
    .pipe(
      skip(1), // ignore the first initial loading
      distinctUntilChanged(), // if changed
      delay(200) //
    )
    .subscribe(() => {
      window.location.reload()
    })
}
