import { createIntl, createIntlCache } from 'react-intl'
import { AppStore } from 'state/store'

const translations: any = {
  de: require(`../../I18N/translations/de`),
  en: require(`../../I18N/translations/en`),
  da: require(`../../I18N/translations/da`),
  fi: require(`../../I18N/translations/fi`),
  it: require(`../../I18N/translations/it`),
  es: require(`../../I18N/translations/es`),
}

const cache = createIntlCache()

//
// see https://github.com/formatjs/react-intl/blob/master/docs/API.md#createintl
//
export const intl = (store: AppStore) => () => {
  const locale = store.state.i18n.locale
  const language = locale.split(/[-_]/)[0] // language without region code
  const messages: any = translations[language] || translations['de']
  const intl = createIntl({ locale, messages }, cache)
  return intl
}
