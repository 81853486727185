import React, { useEffect } from 'react'
import { Typography, Button } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { AppRoutes } from 'routes'
import { makeStyles } from '@material-ui/styles'
import { config } from 'config/config'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import axios from 'axios'
import { Images } from 'theme/Images'

import messages from './Emailvalidation.messages'
import { useTitle } from 'components/layouts/useTitle.hook'

const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    display: 'grid',
    placeContent: 'center',
  },
})

export const EmailValidation = (props: any) => {
  const goTo = useGoToHook()
  const classes = useStyles()
  const intl = useIntl()

  useTitle('Corpatch')

  const validationText = intl.formatMessage(messages.validationText)
  const loginButton = intl.formatMessage(messages.loginButton)

  const { api } = config()
  const id = props.match.params.id

  useEffect(() => {
    axios
      .get(api + '/api/auth/email/validate/' + id)
      .then(() => {
        console.log('SUCCESS VALDIATION')
      })
      .catch(() => {
        console.error('FAILURE VALIDATION')
      })
  }, [id, api])

  return (
    <div className={classes.wrapper}>
      <ImageCard imgUrl={Images.emailValidation}>
        <ImageCardContent>
          <Typography>{validationText}</Typography>
        </ImageCardContent>
        <ImageCardActions>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => goTo(AppRoutes.Login)}
          >
            {loginButton}
          </Button>
        </ImageCardActions>
      </ImageCard>
    </div>
  )
}

export default EmailValidation
