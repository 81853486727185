import { Card, CardContent, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useNextAppState } from 'state/store'
import { theme } from 'theme/theme'
import { useGlobalSettingsState } from './CptConfig.restate'

const useStyles = makeStyles({
  card: {
    marginTop: '1em',
    width: '100%',
  },
  dialogContent: {
    minWidth: '15vw',
    minHeight: '10vh',
  },
  cardHeader: {
    textTransform: 'uppercase',
    width: '100%',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginTop: '4rem',
    color: theme.palette.grey.A700,
    paddingLeft: '25px',
  },
  row: {
    width: '100%',
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: '1fr 1fr',
  },
  cell: {
    marginTop: '1rem',
    marginBottom: '1rem',
    fontFamily: theme.typography.fontFamily,
  },
  select: {
    width: '100%',
    margin: 'auto',
  },
})

type InputEvent = React.ChangeEvent<{ value: string }>

export const CptConfig: React.FC = () => {
  const classes = useStyles()

  const feedbackConfig = useGlobalSettingsState(s => s.feedbackConfig)
  const resultConfig = useGlobalSettingsState(s => s.resultConfig)
  const sessionConfig = useGlobalSettingsState(s => s.sessionConfig)

  const next = useNextAppState(s => s.pages.globalSettings)

  const setAverageCountDepth = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.averageCountDepth = Number.parseInt(e.target.value)
    })

  const setAverageCountFreq = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.averageCountFreq = Number.parseInt(e.target.value)
    })

  const setAverageCountRecoil = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.averageCountRecoil = Number.parseInt(e.target.value)
    })

  const setMaxDepth = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.maxDepth = Number.parseInt(e.target.value)
    })

  const setMaxDepthLimit = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.maxDepthLimit = Number.parseInt(e.target.value)
    })

  const setMaxFreq = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.maxFreq = Number.parseInt(e.target.value)
    })

  const setMaxFreqLimit = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.maxFreqLimit = Number.parseInt(e.target.value)
    })

  const setMaxRecoil = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.maxRecoil = Number.parseInt(e.target.value)
    })

  const setMinDepth = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minDepth = Number.parseInt(e.target.value)
    })

  const setMinDepthLimit = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minDepthLimit = Number.parseInt(e.target.value)
    })

  const setMinFreq = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minFreq = Number.parseInt(e.target.value)
    })

  const setMinFreqLimit = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minFreqLimit = Number.parseInt(e.target.value)
    })

  const setMinRecoil = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minRecoil = Number.parseInt(e.target.value)
    })

  const setMinRecoilLimit = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.minRecoilLimit = Number.parseInt(e.target.value)
    })

  const setNoFlow = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.noFlow = Number.parseInt(e.target.value)
    })

  const setVentilationTime = (e: InputEvent) =>
    next(s => {
      s.feedbackConfig.ventilationTime = Number.parseInt(e.target.value)
    })

  const setThresholdGood = (e: InputEvent) =>
    next(s => {
      s.resultConfig.simple.thresholdGood = Number.parseInt(e.target.value)
    })

  const setThresholdGreat = (e: InputEvent) =>
    next(s => {
      s.resultConfig.simple.thresholdGreat = Number.parseInt(e.target.value)
    })

  const setDebounceTime = (e: InputEvent) =>
    next(s => {
      s.sessionConfig.audioFeedbackDebounceTime = Number.parseInt(
        e.target.value
      )
    })

  const Cell: React.FC = props => (
    <div className={classes.cell}> {props.children}</div>
  )

  const Row: React.FC = props => (
    <div className={classes.row}>{props.children}</div>
  )

  const AvgCountDepth = () => (
    <Row>
      <Cell>averageCountDepth</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.averageCountDepth}
          onChange={setAverageCountDepth}
        />
      </Cell>
    </Row>
  )

  const AvgCountFreq = () => (
    <Row>
      <Cell>averageCountFreq</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.averageCountFreq}
          onChange={setAverageCountFreq}
        />
      </Cell>
    </Row>
  )

  const MaxDepth = () => (
    <Row>
      <Cell>maxDepth</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.maxDepth}
          onChange={setMaxDepth}
        />
      </Cell>
    </Row>
  )

  const MaxDepthLimit = () => (
    <Row>
      <Cell>maxDepthLimit</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.maxDepthLimit}
          onChange={setMaxDepthLimit}
        />
      </Cell>
    </Row>
  )

  const MaxFreq = () => (
    <Row>
      <Cell>maxFreq</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.maxFreq}
          onChange={setMaxFreq}
        />
      </Cell>
    </Row>
  )

  const MaxRecoil = () => (
    <Row>
      <Cell>maxRecoil</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.maxRecoil}
          onChange={setMaxRecoil}
        ></TextField>
      </Cell>
    </Row>
  )

  const AvgCountRecoil = () => (
    <Row>
      <Cell>averageCountRecoil</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.averageCountRecoil}
          onChange={setAverageCountRecoil}
        ></TextField>
      </Cell>
    </Row>
  )

  const MinDepth = () => (
    <Row>
      <Cell>minDepth</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minDepth}
          onChange={setMinDepth}
        />
      </Cell>
    </Row>
  )

  const MinDepthLimit = () => (
    <Row>
      <Cell>minDepthLimit</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minDepthLimit}
          onChange={setMinDepthLimit}
        />
      </Cell>
    </Row>
  )

  const MinFrequency = () => (
    <Row>
      <Cell>minFreq</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minFreq}
          onChange={setMinFreq}
        />
      </Cell>
    </Row>
  )

  const MinFrequencyLimit = () => (
    <Row>
      <Cell>minFreqLimit</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minFreqLimit}
          onChange={setMinFreqLimit}
        />
      </Cell>
    </Row>
  )

  const MaxFrequencyLimit = () => (
    <Row>
      <Cell>maxFreqLimit</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.maxFreqLimit}
          onChange={setMaxFreqLimit}
        />
      </Cell>
    </Row>
  )

  const MinRecoil = () => (
    <Row>
      <Cell>minRecoil</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minRecoil}
          onChange={setMinRecoil}
        />
      </Cell>
    </Row>
  )
  const MinRecoilLimit = () => (
    <Row>
      <Cell>minRecoilLimit</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.minRecoilLimit}
          onChange={setMinRecoilLimit}
        />
      </Cell>
    </Row>
  )
  const NoFlow = () => (
    <Row>
      <Cell>noFlow</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.noFlow}
          onChange={setNoFlow}
        />
      </Cell>
    </Row>
  )
  const VentilationTime = () => (
    <Row>
      <Cell>ventilationTime</Cell>
      <Cell>
        <TextField
          type='number'
          value={feedbackConfig.ventilationTime}
          onChange={setVentilationTime}
        />
      </Cell>
    </Row>
  )
  const DebounceTime = () => (
    <Row>
      <Cell>audioFeedbackDebounceTime</Cell>
      <Cell>
        <TextField
          type='number'
          value={sessionConfig.audioFeedbackDebounceTime}
          onChange={setDebounceTime}
        />
      </Cell>
    </Row>
  )

  return (
    <>
      <Typography className={classes.cardHeader}>Depth</Typography>
      <Card className={classes.card}>
        <CardContent>
          <AvgCountDepth />
          <MinDepthLimit />
          <MinDepth />
          <MaxDepth />
          <MaxDepthLimit />
        </CardContent>
      </Card>

      <Typography className={classes.cardHeader}>Frequency</Typography>
      <Card className={classes.card}>
        <CardContent>
          <AvgCountFreq />
          <MinFrequencyLimit />
          <MinFrequency />
          <MaxFreq />
          <MaxFrequencyLimit />
        </CardContent>
      </Card>

      <Typography className={classes.cardHeader}>Recoil</Typography>
      <Card className={classes.card}>
        <CardContent>
          <AvgCountRecoil />
          <MinRecoilLimit />
          <MinRecoil />
          <MaxRecoil />
        </CardContent>
      </Card>

      <Typography className={classes.cardHeader}>Misc</Typography>
      <Card className={classes.card}>
        <CardContent>
          <NoFlow />
          <VentilationTime />
          <DebounceTime />
        </CardContent>
      </Card>

      <Typography className={classes.cardHeader}>
        Global Result Config
      </Typography>

      <Card className={classes.card}>
        <CardContent>
          <Row>
            <Cell>Threshold result "Good"</Cell>
            <Cell>
              <TextField
                type='number'
                value={resultConfig.simple.thresholdGood}
                onChange={setThresholdGood}
              />
            </Cell>
          </Row>
          <Row>
            <Cell>Threshold result "Perfect"</Cell>
            <Cell>
              <TextField
                type='number'
                value={resultConfig.simple.thresholdGreat}
                onChange={setThresholdGreat}
              />
            </Cell>
          </Row>
        </CardContent>
      </Card>
    </>
  )
}

export default CptConfig
