//
// Pallet #6 from Refactoring UI / Color Palettes v 1.01
//

// These are the splashes of color that should appear the most in your UI,
// and are the ones that determine the overall "look" of the site. Use these
// for things like primary actions, links, navigation items, icons, accent
// borders, or text you want to emphasize.
const primary = [
  'hsl(338, 78%, 28%)',
  'hsl(338, 78%, 33%)',
  // dark red => darker accent color for borders, error notifications etc.
  'hsl(338, 78%, 38%)',
  'hsl(338, 78%, 43%)',
  // main => main accent color for buttons etc.
  'hsl(338, 78%, 48%)',
  'hsl(338, 78%, 52%)',
  'hsl(338, 78%, 58%)',
  // light red
  'hsl(338, 78%, 62%)',
  'hsl(338, 78%, 68%)',
  'hsl(338, 78%, 72%)',
]

// vivid

const vivid = [
  'hsl(47, 72%, 56%)',
  'hsl(47, 72%, 61%)',
  'hsl(47, 72%, 66%)',
  // special color for icons / warnings
  'hsl(47, 72%, 71%)',
  'hsl(47, 72%, 76%)',
  'hsl(47, 72%, 81%)',
  'hsl(47, 72%, 83%)',
  'hsl(47, 72%, 85%)',
  'hsl(47, 72%, 87%)',
  'hsl(47, 72%, 89%)',
]

// These are the colors you will use the most and will make up the majority
// of your UI. Use them for most of your text, backgrounds, and borders,
// as well as for things like secondary buttons and links
const neutrals = [
  'hsl(42, 15%, 13%)',
  'hsl(40, 13%, 23%)',
  'hsl(37, 11%, 28%)',
  'hsl(41, 9%, 35%)',
  'hsl(41, 8%, 48%)',
  'hsl(41, 8%, 61%)',
  'hsl(39, 11%, 69%)',
  'hsl(40, 15%, 80%)',
  'hsl(43, 13%, 90%)',
  // constrast background for cards etc.
  'hsl(40, 23%, 97%)',
]

// These colors should be used fairly conservatively throughout your UI to
// avoid overpowering your primary colors. Use them when you need an
// element to stand out, or to reinforce things like error states or positive
// trends with the appropriate semantic color.
const supportingCyan = [
  // dark
  'hsl(178, 53%, 59%)',
  'hsl(178, 53%, 64%)',
  // main secondary color => secondary buttons, etc.
  'hsl(178, 53%, 69%) ',
  'hsl(178, 53%, 74%)',
  // light
  'hsl(178, 53%, 79%)',
  'hsl(178, 53%, 81%)',
  'hsl(178, 53%, 83%)',
  'hsl(178, 53%, 85%)',
  'hsl(178, 53%, 87%)',
  'hsl(178, 53%, 89%)',
]

const supportingLime = [
  'hsl(81, 86%, 14%)',
  'hsl(81, 78%, 21%)',
  'hsl(83, 74%, 27%)',
  // Primary success colors => success notifications, etc.
  'hsl(83, 70%, 34%)',
  'hsl(83, 64%, 42%)',
  'hsl(83, 55%, 52%)',
  'hsl(83, 63%, 61%)',
  'hsl(83, 68%, 74%)',
  'hsl(84, 77%, 86%)',
  'hsl(83, 88%, 94%)',
]

export const background = ['hsl(0, 0%, 100%)']
export const black = 'rgba(0, 0, 0, 0.87)'

export const Colors = {
  primary,
  vivid,
  neutrals,
  supportingCyan,
  supportingLime,
  background,
  black,
}
