import { routes } from 'model/ctrl/routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { PostMeChangeEmailReq } from 'model/ctrl/me/PostMeChangeEmailReq.schema'
import { config } from 'config/config'

import messages from './UserChangeEmail.messages'

export const connectUserChangeEmailPageService = (store: AppStore) => {
  const { onAction, http, snackBar, intl } = tools(store)
  const msgEmailChanged = intl().formatMessage(messages.emailChangedSnackbar)
  const msgEmailError = intl().formatMessage(messages.emailErrorSnackbar)

  const { api } = config()

  onAction({ type: 'USER/CHANGE_EMAIL/SEND' }).subscribe(() => {
    const newEmail = store.state.pages.userChangeEmail.nextEmail
    const data: PostMeChangeEmailReq = { newEmail }

    store.next(s => {
      s.pages.userChangeEmail.meta.loading = true
    })

    const onSuccess = () => {
      snackBar.success(msgEmailChanged)
      store.next(s => {
        s.pages.userChangeEmail.meta.loading = false
        s.pages.userChangeEmail.send = true
        s.session.user.email = newEmail
      })
    }

    const onError = () => {
      snackBar.error(msgEmailError)
      store.next(s => {
        s.pages.userChangeEmail.meta.loading = false
        s.pages.userChangeEmail.nextEmail = s.session.user.email
      })
    }

    http({
      method: 'POST',
      url: api + routes.me.changeEmail,
      data,
    }).subscribe(onSuccess, onError)
  })
}
