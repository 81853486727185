export interface OnboardingProgress {
  cpsConnected: boolean
  emailAddressVerified: boolean
  legalConditionsAccepted: boolean
  selfAssessmentCompleted: boolean
  testTrainingCompleted: boolean
  tutorialCompleted: boolean
  userLoggedIn: boolean
}

export interface OnboardingState {
  onboarding: OnboardingProgress
  meta: {
    loading: boolean
    notFound: boolean
  }
}

export const defaultOnboardingState = {
  onboarding: {
    userLoggedIn: false,
    tutorialCompleted: false,
    testTrainingCompleted: false,
    selfAssessmentCompleted: false,
    legalConditionsAccepted: false,
    cpsConnected: false,
    emailAddressVerified: false,
  },
  meta: {
    loading: true,
    notFound: false,
  },
}
