import { Message } from '@restate/core'
import { distinctUntilChanged, map, skip } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

export enum SessionServiceMessageType {
  Done = 'Service/Session/Done',
  Restored = 'Service/Session/Restored',
}

export interface ServiceSessionMessages extends Message {
  type: SessionServiceMessageType
}

export const connectSession = (store: AppStore) => {
  const { onAction } = tools(store)

  // Load session data
  onAction({ type: 'App/Init' }).subscribe(() => {
    try {
      const session = JSON.parse(localStorage.getItem('corpatch') || 'null')
      if (session) {
        store.next(
          s => {
            s.session = session
          },
          { type: SessionServiceMessageType.Restored }
        )
      }
    } catch (e) {
      console.error('Error parsing session infos')
    } finally {
      store.dispatch({ type: SessionServiceMessageType.Done })
    }
  })

  // Save session on changes in the state
  store.state$
    .pipe(
      map(update => update.state.session),
      distinctUntilChanged(),
      skip(1)
    )
    .subscribe(() => {
      const { session } = store.state
      localStorage.setItem('corpatch', JSON.stringify(session))
    })
}
