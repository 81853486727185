import { defineMessages } from 'react-intl'

export default defineMessages({
  pageNotFoundTitle: {
    id: 'cp.pages.userDetails.notFound.title',
    defaultMessage: 'Nicht gefunden.',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.userDetails.notFound.message',
    defaultMessage: 'Wurde gelöscht. Sorry :(',
  },
  invitation: {
    id: 'cp.pages.userDetails.invitation',
    defaultMessage: 'Invitation',
  },
  sendInvitation: {
    id: 'cp.pages.userDetails.sendInvitation',
    defaultMessage: 'Send Invitation',
  },
  signUpAccepted: {
    id: 'cp.pages.userDetails.signUpAccepted',
    defaultMessage: 'Accepted',
  },
  signUpPending: {
    id: 'cp.pages.userDetails.signUpPending',
    defaultMessage: 'Pending',
  },
  userDetailsEmail: {
    id: 'cp.pages.userDetails.userDetailsEmail',
    defaultMessage: 'E-Mail',
  },
  successSnackbar: {
    id: 'cp.pages.userDetails.successSnackbar',
    defaultMessage: 'Invitation send',
  },
  errorSnackbar: {
    id: 'cp.pages.userDetails.errorSnackbar',
    defaultMessage: 'Oh no. Could not send invite',
  },
})
