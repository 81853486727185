import CourseTable from 'components/CourseTable/CourseTable'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { useIntl } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState } from 'state/store'
import { Images } from 'theme/Images'
import { USERROLES } from '../../model/UserRole'
import messages from './Courses.messages'
import { useCoursesState } from './Courses.restate'

// const useClasses = makeStyles({})

const CoursesLoading = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)

  return <AppPageLayout title={pageTitle} loading={true}></AppPageLayout>
}

const CoursesNotFound = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)

  return (
    <PageNotFound
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.empty}
    />
  )
}

export const Courses: React.FC<{}> = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)

  const { loading, notFound } = useCoursesState(s => s.meta)
  const { data } = useCoursesState(s => s)
  const goTo = useGoToHook()

  const userRole = useAppState(s => s.session.user.role)

  const showInstituteCol =
    userRole === USERROLES.CorPatchAdmin || userRole === USERROLES.Trainer

  const showAssociatedTrainerCol = userRole === USERROLES.InstituteAdmin

  const navigateToCoursePage = (id: string) => goTo(AppRoutes.Course, { id })

  if (loading) return <CoursesLoading />

  if (notFound) return <CoursesNotFound />

  return (
    <AppPageLayout title={pageTitle}>
      <CourseTable
        data={data}
        onCourseClick={navigateToCoursePage}
        showInstituteCol={showInstituteCol}
        showAssociatedTrainerCol={showAssociatedTrainerCol}
      />
    </AppPageLayout>
  )
}

export default Courses
