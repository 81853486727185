import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormGroup,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { SharingTraineeAggregate } from 'model/SharingTraineeAggregate'
import React from 'react'
import { useAppState, useDispatchHook, useNextAppState } from 'state/store'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { useTraineeSharesState } from './TraineeShares.restate'
import { YesNoDialog } from 'components/YesNoDialog/GenericConfirmDeletionDialog'
import { useIntl } from 'react-intl'
import messages from './TraineeShares.messages'

const TraineeSharesLoading = () => {
  const intl = useIntl()
  return (
    <AppPageLayout
      title={intl.formatMessage(messages.pageTitle)}
      loading={true}
    ></AppPageLayout>
  )
}

const TraineeSharesNotFound = () => {
  const intl = useIntl()
  return (
    <PageNotFound
      title={intl.formatMessage(messages.pageTitleNotFound)}
      message={intl.formatMessage(messages.pageNotFoundMessage)}
      img={Images.detailedExamination}
    />
  )
}

const useClasses = makeStyles({
  listItem: {
    backgroundColor: 'white',
  },
  card: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    width: '100%',
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
})

function isActive(s: SharingTraineeAggregate) {
  return s.endDate === null && s.sharingObject.meta.deletedAt === null
}

export const TraineeShares: React.FC<{}> = () => {
  const { loading, notFound } = useTraineeSharesState(s => s.meta)
  const next = useNextAppState(s => s.pages.traineeShares)
  const classes = useClasses()
  const sharingTrainee = useTraineeSharesState(s => s.sharingTrainee)
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)

  if (loading) return <TraineeSharesLoading />

  if (notFound) return <TraineeSharesNotFound />

  const activeSharingTrainee = sharingTrainee.filter(isActive)
  const deactivatedSharingTrainee = sharingTrainee.filter(s => !isActive(s))

  return (
    <AppPageLayout title={pageTitle}>
      <div style={{ width: '100%' }}>
        <Typography variant='h5'>
          {intl.formatMessage(messages.active)}
        </Typography>
        {activeSharingTrainee.length === 0 && <NoActiveItems />}
        {activeSharingTrainee.map(s => (
          <SharingTraineeCard sharingTrainee={s} />
        ))}
      </div>

      <div style={{ width: '100%', marginTop: 20 }}>
        <Typography variant='h5'>
          {intl.formatMessage(messages.inactive)}
        </Typography>
        {deactivatedSharingTrainee.length === 0 && <NoDeActiveItems />}
        {deactivatedSharingTrainee.map(s => (
          <SharingTraineeCard sharingTrainee={s} />
        ))}
      </div>

      <Fab
        className={classes.fab}
        color='secondary'
        aria-label={intl.formatMessage(messages.addShare)}
        onClick={() =>
          next(s => {
            s.addDialogCode = ''
            s.addDialogOpen = true
          })
        }
      >
        <AddIcon />
      </Fab>
      <AddDialog />
    </AppPageLayout>
  )
}

function NoActiveItems() {
  const intl = useIntl()
  return (
    <Typography
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      {intl.formatMessage(messages.noActiveItemsMessage)}
    </Typography>
  )
}

function NoDeActiveItems() {
  const intl = useIntl()

  return (
    <Typography
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      {intl.formatMessage(messages.noInactiveItemsMessage)}
    </Typography>
  )
}

function SharingTraineeCard(props: {
  sharingTrainee: SharingTraineeAggregate
}) {
  const { sharingTrainee } = props
  const { institute } = sharingTrainee
  const dispatch = useDispatchHook()
  const [deactivateDialogOpen, setDeactivateDialogOpen] = React.useState(false)
  const active = isActive(sharingTrainee)
  const intl = useIntl()

  function deactivate() {
    setDeactivateDialogOpen(true)
  }

  return (
    <Card elevation={1} style={{ marginTop: 20 }}>
      <DeactivateDialog
        sharingTrainee={sharingTrainee}
        open={deactivateDialogOpen}
        onYesButtonClick={() =>
          dispatch({
            type: 'Pages/TraineeShares/Shares/Deactivate',
            sharingTrainee,
          })
        }
        onNoButtonClick={() => setDeactivateDialogOpen(false)}
      />
      <CardContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <Typography variant='h6'>{institute.name}</Typography>
            <Typography>{sharingTrainee.sharingObject.name}</Typography>
          </div>
          <div style={{ minWidth: active ? 250 : 298 }}>
            <Typography>
              {intl.formatMessage(messages.startDate)}
              {formatDate(sharingTrainee.startDate)}
            </Typography>
            <Typography>
              {intl.formatMessage(messages.endDate)}
              {formatDate(sharingTrainee.endDate)}
            </Typography>
          </div>
          {active && (
            <div>
              <Tooltip title={intl.formatMessage(messages.deactivateTooltip)}>
                <IconButton onClick={deactivate}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

function AddDialog() {
  const open = useAppState(s => s.pages.traineeShares.addDialogOpen)
  const code = useAppState(s => s.pages.traineeShares.addDialogCode)
  const next = useNextAppState(s => s.pages.traineeShares)
  const dispatch = useDispatchHook()
  const addButtonDisabled = !code || code.length === 0
  const intl = useIntl()

  return (
    <Dialog open={open}>
      <DialogTitle>{intl.formatMessage(messages.addDialogTitle)}</DialogTitle>
      <DialogContent>
        <Typography variant='body1' style={{ marginBottom: 20 }}>
          {intl.formatMessage(messages.addDialogDescription)}
        </Typography>
        <FormGroup>
          <TextField
            value={code}
            variant='outlined'
            onChange={e =>
              next(s => {
                s.addDialogCode = e.target.value
              })
            }
          />
        </FormGroup>

        <Typography style={{ marginTop: 20 }}>
          {intl.formatMessage(messages.addDialogDescription2)}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            next(s => {
              s.addDialogOpen = false
            })
          }
        >
          {intl.formatMessage(messages.addDialogCancel)}
        </Button>
        <Button
          disabled={addButtonDisabled}
          color='primary'
          onClick={() => dispatch({ type: 'Pages/TraineeShares/Shares/Add' })}
        >
          {intl.formatMessage(messages.addDialogAdd)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function DeactivateDialog(props: {
  open: boolean
  sharingTrainee: SharingTraineeAggregate
  onYesButtonClick: () => void
  onNoButtonClick: () => void
}) {
  const intl = useIntl()
  return (
    <YesNoDialog
      open={props.open}
      dialogTitle={intl.formatMessage(messages.deactivateDialogTitle)}
      onYesButtonClick={props.onYesButtonClick}
      onNoButtonClick={props.onNoButtonClick}
      noButtonTitle={intl.formatMessage(messages.deactivateDialogNo)}
      yesButtonTitle={intl.formatMessage(messages.deactivateDialogYes)}
    >
      <Typography style={{ marginTop: 20, marginBottom: 30 }}>
        {intl.formatMessage(messages.deactivateDialogDescription, {
          instituteName: props.sharingTrainee.institute.name,
        })}
      </Typography>
    </YesNoDialog>
  )
}

function formatDate(dateStr: string | null) {
  if (!dateStr) {
    return '-'
  }

  const date = new Date(dateStr)

  return date.toLocaleString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

export default TraineeShares
