import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import React from 'react'

interface YesNoDialogProps {
  open: boolean
  dialogTitle: string
  yesButtonTitle: string
  noButtonTitle: string
  onYesButtonClick: () => void
  onNoButtonClick: () => void
}

export const YesNoDialog = ({
  open,
  children,
  dialogTitle,
  yesButtonTitle,
  noButtonTitle,
  onYesButtonClick,
  onNoButtonClick,
}: React.PropsWithChildren<YesNoDialogProps>) => {
  return (
    <Dialog open={open} onClose={onNoButtonClick}>
      <DialogTitle>{dialogTitle}</DialogTitle>

      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        <Button onClick={onNoButtonClick}>{noButtonTitle}</Button>
        <Button variant='contained' color='primary' onClick={onYesButtonClick}>
          {yesButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
