import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatchHook } from 'state/store'
import { CARD_DEFAULTS } from 'styles/styles'
import { Colors } from 'theme/colors'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import generalMessages from '../../I18N/general.messages'
import messages from './UserDetails.messages'
import { useUserDetailsState } from './UserDetails.restate'

const useClasses = makeStyles({
  submitButton: {
    marginTop: 40,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  innerContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  error: {
    ...theme.typography.h6,
    color: theme.palette.error.dark,
    padding: theme.spacing(),
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid ' + theme.palette.primary.dark,
  },

  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    backgroundColor: Colors.neutrals[9],
  },

  card: {
    ...CARD_DEFAULTS,
  },

  cardContent: {
    height: '100%',
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },

  cardImg: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing() + 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 220,
      // height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  cardForm: {},

  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(),
    },
  },

  send: {
    display: 'flex',
    height: 215,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkCircleIcon: {
    width: 80,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      margin: theme.spacing(2),
    },
    margin: theme.spacing(3),
    color: Colors.supportingLime[5],
  },
})

const UserDetailsLoading = () => (
  <AppPageLayout showBackButton={true} title='' loading={true}></AppPageLayout>
)

const UserDetailsNotFound = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)

  return (
    <PageNotFound
      title={pageNotFoundTitle}
      message={pageNotFoundMessage}
      img={Images.missingUrl}
    />
  )
}

export const UserDetails: React.FC<{}> = () => {
  const intl = useIntl()
  const tableRowName = intl.formatMessage(generalMessages.tableRowName)
  const invitation = intl.formatMessage(messages.invitation)
  const sendInvitation = intl.formatMessage(messages.sendInvitation)
  const signUpAccepted = intl.formatMessage(messages.signUpAccepted)
  const signUpPending = intl.formatMessage(messages.signUpPending)
  const userDetailsEmail = intl.formatMessage(messages.userDetailsEmail)

  const { loading, notFound } = useUserDetailsState(s => s.meta)
  const userDetails = useUserDetailsState(s => s.userDetails)
  const classes = useClasses()
  const dispatch = useDispatchHook()

  if (loading) return <UserDetailsLoading />
  if (notFound) return <UserDetailsNotFound />
  if (!userDetails) return <UserDetailsNotFound />

  const sendInvite = () => dispatch({ type: 'Page/UserDetails/SendInvite' })

  const title = userDetails.firstName + ' ' + userDetails.familyName

  return (
    <AppPageLayout title={title} showBackButton={true}>
      <ImageCard
        imgUrl={
          userDetails.gender === 'male'
            ? Images.maleAvatar
            : Images.femaleAvatar
        }
      >
        <ImageCardContent>
          <Grid
            direction='column'
            container={true}
            className={classes.innerContainer}
          >
            <Typography variant='caption'>{tableRowName}</Typography>
            <Typography variant='body1'>
              {userDetails.firstName} {userDetails.familyName}
            </Typography>

            <Typography
              variant='caption'
              style={{ marginTop: theme.spacing(2) }}
            >
              {userDetailsEmail}
            </Typography>
            <Typography variant='body1'>{userDetails.email} </Typography>

            <Typography
              variant='caption'
              style={{ marginTop: theme.spacing(2) }}
            >
              {invitation}
            </Typography>
            <Typography variant='body1'>
              {userDetails.signUpStatus === 'valid'
                ? signUpAccepted
                : signUpPending}
            </Typography>
          </Grid>
        </ImageCardContent>
        <ImageCardActions>
          <Button
            type='submit'
            variant='contained'
            color='secondary'
            disabled={false}
            onClick={sendInvite}
          >
            {sendInvitation}
          </Button>
        </ImageCardActions>
      </ImageCard>
    </AppPageLayout>
  )
}

export default UserDetails
