import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { UserRole } from '../../model/UserRole'
import { AppRoutes } from '../../routes'
import { useAppState, useDispatchHook } from '../../state/store'

import messages from './ChangeUserRole.messages'

function ChangeUserRole() {
  const intl = useIntl()
  const SelectLabel = intl.formatMessage(messages.selectLabel)

  const dispatch = useDispatchHook()
  const { role, availableRoles } = useAppState(state => state.session.user)
  const history = useHistory()

  const handleRoleChange = async (requestedRole: UserRole) => {
    history.push(AppRoutes.Home)

    dispatch({
      type: 'Components/ChangeUserRole/Set',
      payload: { requestedRole },
    })
  }

  return (
    <FormControl>
      <InputLabel style={{ whiteSpace: 'nowrap' }} id='available-role-select'>
        {SelectLabel}
      </InputLabel>
      <Select
        style={{ minWidth: '8rem' }}
        labelId='available-role-select'
        id='available-role-select'
        value={role}
        onChange={event => handleRoleChange(event.target.value as UserRole)}
      >
        {availableRoles?.map((role, index) => (
          <MenuItem key={index} value={role}>{role}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ChangeUserRole
