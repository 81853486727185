import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  Card,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { CARD_DEFAULTS } from 'styles/styles'
import { Colors } from 'theme/colors'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { isEmail } from 'utils/isEmail'
import messages from './Invite.messages'
import { Spinner } from 'components/Spinner/Spinner'
import { useAppState } from 'state/store'

const useClasses = makeStyles({
  submitButton: {
    marginTop: 40,
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  innerContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  error: {
    ...theme.typography.h6,
    color: theme.palette.error.dark,
    padding: theme.spacing(),
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid ' + theme.palette.primary.dark,
  },

  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    backgroundColor: Colors.neutrals[9],
  },

  card: {
    ...CARD_DEFAULTS,
  },

  cardContent: {
    height: '100%',
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },

  cardImg: {
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing() + 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 220,
      // height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  cardForm: {},

  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(),
    },
  },

  send: {
    display: 'flex',
    height: 215,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkCircleIcon: {
    width: 80,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      margin: theme.spacing(2),
    },
    margin: theme.spacing(3),
    color: Colors.supportingLime[5],
  },
})

interface OnSendProps {
  email: string
  firstName: string
  lastName: string
}

interface InviteCardProps {
  title: string
  onCancel: () => any
  onSend: (props: OnSendProps) => any
  imgSrc: string
}

const LoadingInvite = () => {
  const classes = useClasses()

  return (
    <Card className={classes.card}>
      <Spinner visible={true} />
    </Card>
  )
}

const InviteCard: React.FC<InviteCardProps> = props => {
  const intl = useIntl()
  const inviteSubheader = intl.formatMessage(messages.inviteCardSubheader)
  const inviteFirstNameLabel = intl.formatMessage(messages.inviteFirstNameLabel)
  const inviteSurnameLabel = intl.formatMessage(messages.inviteSurnameLabel)
  const inviteSendButton = intl.formatMessage(messages.inviteSendButton)
  const inviteCancelButton = intl.formatMessage(messages.inviteCancelButton)
  const invalidEmail = intl.formatMessage(messages.inviteInvalidEmail)
  const eMailRequired = intl.formatMessage(messages.inviteEmailRequired)

  const { title, onCancel, onSend, imgSrc } = props
  const classes = useClasses()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [touched, setTouched] = useState(false)

  const handleChange = (e: any) => {
    setTouched(true)
    setError(false)
    setEmail(e.target.value)
  }

  const onBlur = () => {
    const isValidEmail = isEmail(email)
    setError(!isValidEmail)
  }

  return (
    <ImageCard imgUrl={imgSrc}>
      <ImageCardContent>
        <Typography variant='h5'>{title}</Typography>
        <Typography variant='caption'>{inviteSubheader}</Typography>
        <Grid
          direction='column'
          container={true}
          className={classes.innerContainer}
        >
          <FormControl>
            <TextField
              label={inviteFirstNameLabel}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              label={inviteSurnameLabel}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            <TextField
              id='email'
              type='email'
              label={eMailRequired}
              value={email}
              onChange={handleChange}
              helperText={error ? invalidEmail : ''}
              error={error}
              onBlur={onBlur}
              autoComplete='email'
            />
          </FormControl>
        </Grid>
      </ImageCardContent>
      <ImageCardActions>
        <Button
          type='submit'
          onClick={() => onSend({ email, firstName, lastName })}
          variant='contained'
          color='secondary'
          disabled={error || !touched}
        >
          {inviteSendButton}
        </Button>
        <Button onClick={() => onCancel()}>{inviteCancelButton}</Button>
      </ImageCardActions>
    </ImageCard>
  )
}

interface SendProps {
  onOk: () => any
}
const Send: React.FC<SendProps> = props => {
  const intl = useIntl()

  const emailSend = intl.formatMessage(messages.inviteEmailSend)
  const emailSent = intl.formatMessage(messages.inviteEmailSent)
  const OK = intl.formatMessage(messages.inviteOk)

  const { onOk } = props
  return (
    <ImageCard imgUrl={Images.mail}>
      <ImageCardContent>
        <Typography variant='h5'>{emailSend}</Typography>
        <Typography variant='caption'>{emailSent}</Typography>
      </ImageCardContent>
      <ImageCardActions>
        <Button
          type='submit'
          onClick={onOk}
          variant='contained'
          color='secondary'
        >
          {OK}
        </Button>
      </ImageCardActions>
    </ImageCard>
  )
}

interface InviteProps {
  title: string
  send: boolean
  onSend: (props: OnSendProps) => any
  onCancel: () => any
  onOk: () => any
  imgSrc?: string
}

export const Invite: React.FC<InviteProps> = props => {
  const {
    send,
    title,
    onOk,
    onSend,
    onCancel,
    imgSrc = Images.mobileApp,
  } = props
  const adminInviteLoading = useAppState(s => s.pages.adminInvite.meta.loading)
  const trainerInviteLoading = useAppState(
    s => s.pages.trainerInvite.meta.loading
  )

  if (send) return <Send onOk={onOk} />
  if (adminInviteLoading || trainerInviteLoading) return <LoadingInvite />

  return (
    <InviteCard
      title={title}
      onCancel={onCancel}
      onSend={onSend}
      imgSrc={imgSrc}
    />
  )
}
