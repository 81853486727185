import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React, { useState } from 'react'
import { InstituteAdminTable } from './components/InstituteAdminTable'
import { InstituteForm } from './components/InstituteForm'
import { InstituteTrainerTable } from './components/InstituteTrainerTable'
import { useInstituteState } from './Institute.restate'
import { Typography } from '@material-ui/core'
import { TabsPageLayout } from 'components/layouts/TabsPageLayout'
import { useIntl } from 'react-intl'

import messages from './Institute.messages'
import { institutePageTabIndex } from './Institute.page-service'
import { InstituteSharingObjects } from './components/InstituteSharingObject'

interface TabPanelProps {
  index: number
  value: number
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { value, index, children } = props
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </Typography>
  )
}

export const Institute = () => {
  const intl = useIntl()
  const Base = intl.formatMessage(messages.tabsBase)
  const Admins = intl.formatMessage(messages.tabsAdmins)
  const Trainers = intl.formatMessage(messages.tabsTrainers)
  const institutePageNotFoundTitle = intl.formatMessage(
    messages.institutePageNotFoundTitle
  )
  const institutePageNotFoundMessage = intl.formatMessage(
    messages.institutePageNotFoundMessage
  )
  const institute = intl.formatMessage(messages.institute)
  const tabs = [Base, Admins, Trainers, 'Sharing']

  const state = useInstituteState(s => s)
  const [tabIndex, setTabIndex] = useState(institutePageTabIndex)

  if (state.meta.loading) {
    return (
      <TabsPageLayout
        title={institute}
        showBackButton={true}
        tabIdx={tabIndex}
        tabs={tabs}
        onTabChange={idx => setTabIndex(idx)}
        loading={true}
      />
    )
  }

  if (state.meta.notFound) {
    return (
      <PageNotFound
        title={institutePageNotFoundTitle}
        message={institutePageNotFoundMessage}
      />
    )
  }

  return (
    <TabsPageLayout
      title={institute}
      showBackButton={true}
      tabIdx={tabIndex}
      tabs={tabs}
      onTabChange={idx => setTabIndex(idx)}
    >
      <TabPanel value={tabIndex} index={0}>
        <InstituteForm />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <InstituteAdminTable />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <InstituteTrainerTable />
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        <InstituteSharingObjects />
      </TabPanel>
    </TabsPageLayout>
  )
}

export default Institute
