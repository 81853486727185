import { defineMessages } from 'react-intl'

export default defineMessages({
  trainersNotFoundTitle: {
    id: 'cp.pages.trainersNotFound.title',
    defaultMessage: 'Trainer not found',
  },
  trainersNotFoundMessage: {
    id: 'cp.pages.trainersNotFound.message',
    defaultMessage: 'This trainer has been deleted. Sorry :(',
  },
  trainersNotAvailableTitle: {
    id: 'cp.pages.trainersNotAvailable.title',
    defaultMessage: 'No trainers yet!',
  },
  trainersNotAvailableMessage: {
    id: 'cp.pages.trainersNotAvailable.message',
    defaultMessage: 'Do you want to invite the first trainer?',
  },
  trainersNotAvailableButton: {
    id: 'cp.pages.trainersNotAvailable.button',
    defaultMessage: 'Invite Trainer',
  },
  trainersTitle: {
    id: 'cp.pages.trainersTable.trainers',
    defaultMessage: 'Trainers',
  },
  selected: {
    id: 'cp.pages.trainersTable.selected',
    defaultMessage: 'selected',
  },
  tableRowName: {
    id: 'cp.pages.trainersTable.tableRowName',
    defaultMessage: 'Name',
  },
  tableRowEmail: {
    id: 'cp.pages.trainersTable.tableRowEmail',
    defaultMessage: 'E-Mail',
  },
  inviteTrainer: {
    id: 'cp.pages.trainersTable.inviteTrainer',
    defaultMessage: 'Invite Trainer',
  },
  deleteLabel: {
    id: 'cp.pages.trainersTable.deleteLabel',
    defaultMessage: 'delete',
  },
  successSnackbar: {
    id: 'cp.pages.trainers.successSnackbar',
    defaultMessage: 'Deleted.',
  },
  errorSnackbar: {
    id: 'cp.pages.trainers.errorSnackbar',
    defaultMessage: 'Oh no. Could not delete user :/',
  },
})
