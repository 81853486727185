import { Message } from '@restate/core'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { InitialPasswordReq } from 'model/ctrl/signup/InitialPasswordReq.schema'
import { UserInfo } from 'model/ctrl/signup/ValidateRes.schema'
import { UserDTO } from 'model/User.dto'
import RouteParser from 'route-parser'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { defaultAcceptInvitationPageState } from './AcceptInvitation.state'

export interface AcceptInvitationMessage extends Message {
  type: 'Page/AcceptInvitation/Send'
  payload: string
}

export const connectAcceptInvitationPageService = (store: AppStore) => {
  const { onPageEnter, onPageLeave, http, onAction, pageParams } = tools(store)
  const { api } = config()
  const params = pageParams(AppRoutes.AcceptInvitation)

  onPageEnter(AppRoutes.AcceptInvitation).subscribe(props => {
    const { id } = props

    const route = new RouteParser(routes.auth.validate)
    const fullRoute = route.reverse({ id })

    if (id) {
      http<UserInfo>({
        url: api + fullRoute,
        method: 'GET',
      }).subscribe(
        // success
        userInfo =>
          store.next(s => {
            s.pages.acceptInvitation.meta = {
              loading: false,
              notFound: false,
              success: false,
            }
            s.pages.acceptInvitation.userInfo = userInfo
          }),

        // error
        () =>
          store.next(s => {
            s.pages.acceptInvitation.meta = {
              loading: false,
              notFound: true,
              success: false,
            }
          })
      )
    }
  })

  onPageLeave(AppRoutes.AcceptInvitation).subscribe(() => {
    store.next(s => {
      s.pages.acceptInvitation = defaultAcceptInvitationPageState
    })
  })

  onAction({ type: 'Page/AcceptInvitation/Send' }).subscribe(pack => {
    const newPassword = (pack.message as AcceptInvitationMessage).payload
    const { id } = params()

    store.next(s => {
      s.pages.acceptInvitation.meta.loading = true
    })
    const data: InitialPasswordReq = {
      key: id,
      newPassword,
    }

    http<UserDTO>({
      method: 'POST',
      url: api + routes.auth.initialPassword,
      data,
    }).subscribe(
      // Success
      data => {
        store.next(s => {
          s.pages.acceptInvitation.meta.success = true
          s.pages.acceptInvitation.meta.loading = false
          s.session.user = {
            ...data,
          }
          s.session.token = data.token
        })
      },
      // Error
      () => {
        store.next(s => {
          s.pages.acceptInvitation.meta = {
            loading: false,
            notFound: true,
            success: false,
          }
        })
      }
    )
  })
}
