import { filter } from 'rxjs/internal/operators/filter'
import { AppStore } from 'state/store'
import { onChange } from './onChange'
import { map } from 'rxjs/operators'

export const onLogin = (store: AppStore) => () =>
  onChange(store)(state => state.session.token).pipe(
    map(token => token !== null),
    filter(hasToken => hasToken === true)
  )
