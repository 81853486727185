import { makeStyles } from '@material-ui/styles'
import React, { FormEvent } from 'react'
import { theme } from 'theme/theme'
import { useLoginState, useLoginActions } from 'pages/Login/Login.restate'
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import EmailIcon from '@material-ui/icons/Email'
import { Colors } from 'theme/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { PublicPageLayout } from 'components/layouts/PublicPageLayout'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useDispatchHook, useAppState } from 'state/store'
import { useIntl } from 'react-intl'

import messages from './LoginPasswordForrgot.messages'
import { isEmail } from 'utils/isEmail'
import { useRTL } from 'utils/useRTL'

const useClasses = makeStyles({
  submitButton: {
    marginTop: 40,
  },

  innerContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },

  error: {
    ...theme.typography.h6,
    color: theme.palette.error.dark,
    padding: theme.spacing(),
    fontSize: '1rem',
    backgroundColor: theme.palette.grey[50],
    border: '1px solid ' + theme.palette.primary.dark,
  },

  center: {
    display: 'grid',
    placeItems: 'center',
    marginTop: '15vh',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
    flexGrow: 1,
    backgroundColor: Colors.background[0],
  },

  card: {
    // minWidth: '400px',
    // borderTop: `4px solid ${Colors.primary[3]}`,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      borderRadius: 0,
    },
    borderRadius: 8,
  },

  cardContent: {
    '& h5': {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(5),
    },
  },

  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button': {
      marginLeft: theme.spacing(),
    },
  },

  send: {
    display: 'flex',
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkCircleIcon: {
    width: 80,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      margin: theme.spacing(2),
    },
    margin: theme.spacing(3),
    color: Colors.supportingCyan[3],
  },
})

const EmailForm: React.FC = () => {
  const intl = useIntl()
  const ConfirmationMessage = intl.formatMessage(
    messages.passwordForgotConfirmationMessage
  )
  const ConfirmationMessage2 = intl.formatMessage(
    messages.passwordForgotConfirmationMessage2
  )
  const ConfirmationTitle = intl.formatMessage(
    messages.passwordForgotConfirmationTitle
  )
  const ConfirmationSent = intl.formatMessage(
    messages.passwordForgotConfirmationSent
  )
  const passwordForgotBackToLogin = intl.formatMessage(
    messages.passwordForgotBackToLogin
  )

  const classes = useClasses()
  const { email } = useLoginState()
  const { setEmail } = useLoginActions()
  const isRTL = useRTL()
  const goTo = useGoToHook()
  const dispatch = useDispatchHook()

  const backToLogin = () => goTo(AppRoutes.Login)
  const sendEmail = (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    dispatch({ type: 'Pages/Login/PasswordForgot/SendEmail' })
  }

  const disabled = !isEmail(email)

  return (
    <div className={classes.center}>
      <Card className={classes.card} elevation={3}>
        <CardContent className={classes.cardContent}>
          <Typography variant='h5'>{ConfirmationTitle}</Typography>
          <Typography>{ConfirmationMessage}</Typography>
          <Typography>{ConfirmationMessage2}</Typography>
          <form onSubmit={e => sendEmail(e)}>
            <Grid
              direction='column'
              container={true}
              className={classes.innerContainer}
            >
              <FormControl>
                <InputLabel htmlFor='email'>
                  <FormattedMessage {...messages.email} />
                </InputLabel>
                <Input
                  id='email'
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  autoComplete='email'
                  endAdornment={
                    !isRTL && (
                      <InputAdornment position='end'>
                        <EmailIcon color='action' />
                      </InputAdornment>
                    )
                  }
                  startAdornment={
                    isRTL && (
                      <InputAdornment position='end'>
                        <EmailIcon color='action' />
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            </Grid>
          </form>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => sendEmail()}
            disabled={disabled}
          >
            {ConfirmationSent}
          </Button>
          <Button onClick={backToLogin}>{passwordForgotBackToLogin}</Button>
        </CardActions>
      </Card>
    </div>
  )
}

const Send = () => {
  const intl = useIntl()
  const ConfirmationSent = intl.formatMessage(
    messages.passwordForgotConfirmationSent
  )
  const ConfirmationSendMessage = intl.formatMessage(
    messages.passwordForgotConfirmationSendMessage
  )
  const passwordForgotBackToLogin = intl.formatMessage(
    messages.passwordForgotBackToLogin
  )

  const goTo = useGoToHook()
  const backToLogin = () => goTo(AppRoutes.Login)

  const classes = useClasses()
  const { email } = useLoginState()
  return (
    <div className={classes.center}>
      <Card className={classes.card} elevation={3}>
        <CardContent className={classes.send}>
          <CheckCircleIcon
            color='inherit'
            className={classes.checkCircleIcon}
          />
          <Typography variant='h6' style={{ marginBottom: 20 }}>
            {ConfirmationSent}
          </Typography>
          <Typography>{ConfirmationSendMessage}</Typography>
          <Typography>{email}.</Typography>
        </CardContent>
        <CardActions
          className={classes.actions}
          style={{ marginTop: theme.spacing(3) }}
        >
          <Button onClick={backToLogin}>{passwordForgotBackToLogin}</Button>
        </CardActions>
      </Card>
    </div>
  )
}

const Sending = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </div>
)

export const LoginPasswordForgot: React.FC = () => {
  const intl = useIntl()
  const passwordForgotTitle = intl.formatMessage(messages.passwordForgotTitle)
  const state = useAppState(s => s.pages.loginPasswordForgot.send)

  const isSend = state === 'send'
  const isSending = state === 'sending'
  const isInput = state === 'input'
  const isError = state === 'error'

  return (
    <PublicPageLayout title={passwordForgotTitle} showTranslationSwitch>
      {isInput && <EmailForm />}
      {isSending && <Sending />}
      {isSend && <Send />}
      {isError && <EmailForm />}
    </PublicPageLayout>
  )
}

export default LoginPasswordForgot
