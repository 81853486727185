import RouteParser from 'route-parser'
import { goMessage } from 'services/router/router.restate'
import { useDispatchHook } from 'state/store'

interface RouteParams {
  id?: string
  idx?: string
}

export function useGoToHook() {
  const dispatch = useDispatchHook()
  return function (path: string, params: RouteParams = {}) {
    const routeParser = new RouteParser(path)
    const fqRoute = routeParser.reverse(params)

    if (fqRoute) {
      dispatch(goMessage(fqRoute))
    } else {
      console.error(
        `Cannot parse route ${path} with params ${JSON.stringify(params)}`
      )
    }
  }
}
