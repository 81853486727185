import { defineMessages } from 'react-intl'

export default defineMessages({
  tableRowAssociatedTrainer: {
    id: 'cp.components.courseTable.tableRowAssociatedTrainer',
    defaultMessage: 'Associated Trainer',
  },
  tableRowProvider: {
    id: 'cp.components.courseTable.tableRowProvider',
    defaultMessage: 'Provider',
  },
  tableRowIdentifier: {
    id: 'cp.components.courseTable.tableRowIdentifier',
    defaultMessage: 'Identifier',
  },
  tableRowDate: {
    id: 'cp.components.courseTable.tableRowDate',
    defaultMessage: 'Date',
  },
  tableRowLocation: {
    id: 'cp.components.courseTable.tableRowLocation',
    defaultMessage: 'Location',
  },
  tableRowTraineeCount: {
    id: 'cp.components.courseTable.tableRowTraineeCount',
    defaultMessage: 'Trainee Count',
  },
  tableRowValue: {
    id: 'cp.components.courseTable.tableRowValue',
    defaultMessage: 'Value',
  },
  selected: {
    id: 'cp.components.courseTable.selected',
    defaultMessage: 'selected',
  },
  downloadMessage: {
    id: 'cp.components.courseTable.downloadMessage',
    defaultMessage: 'Course selected for download',
  },
  downloadCSVTitle: {
    id: 'cp.components.courseTable.downloadCSVTitle',
    defaultMessage: 'Download CSV file',
  },
  deleteLabel: {
    id: 'cp.components.courseTable.deleteLabel',
    defaultMessage: 'delete',
  },
})
