import { defineMessages } from 'react-intl'

export default defineMessages({
  inviteCardSubheader: {
    id: 'cp.components.invite.inviteSubheader',
    defaultMessage: 'An invitation will be send to this e-mail account.',
  },
  inviteFirstNameLabel: {
    id: 'cp.components.invite.inviteFirstnameLabel',
    defaultMessage: 'First name',
  },
  inviteSurnameLabel: {
    id: 'cp.components.invite.inviteSurnameLabel',
    defaultMessage: 'Family name',
  },
  inviteSendButton: {
    id: 'cp.components.invite.inviteSendButton',
    defaultMessage: 'Send Invitation',
  },
  inviteCancelButton: {
    id: 'cp.components.invite.inviteCancelButton',
    defaultMessage: 'Cancel',
  },
  inviteInvalidEmail: {
    id: 'cp.components.invite.inviteInvalidEmail',
    defaultMessage: 'This is not an valid email',
  },
  inviteEmailSend: {
    id: 'cp.components.invite.inviteEmailSend',
    defaultMessage: 'Send',
  },
  inviteEmailSent: {
    id: 'cp.components.invite.inviteEmailSent',
    defaultMessage: 'E-Mail sent',
  },
  inviteOk: {
    id: 'cp.components.invite.inviteOk',
    defaultMessage: 'OK',
  },
  inviteEmailRequired: {
    id: 'cp.components.invite.inviteEmailRequired',
    defaultMessage: 'E-Mail *',
  },
})
