import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { GenericTableCellHeader, Order } from '../GenericTable'
import { useIntl } from 'react-intl'

import messages from '../GenericTable.messages'

interface EnhancedTableProps {
  cellHeaders: GenericTableCellHeader[]
  checkboxEnabled?: boolean
  checkboxVisible?: boolean
  singleSelect: boolean
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

export const EnhancedTableHead: React.FC<EnhancedTableProps> = props => {
  const intl = useIntl()
  const multiSelect = intl.formatMessage(messages.multiSelect)

  const {
    checkboxEnabled = true,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    singleSelect,
  } = props
  const classes = useStyles()
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  const { cellHeaders } = props

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          {!singleSelect && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              disabled={rowCount === 0 || !checkboxEnabled}
              inputProps={{ 'aria-label': multiSelect }}
            />
          )}
        </TableCell>
        {cellHeaders.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
