import { UserRole, USERROLES } from 'model/UserRole'

export interface AppDrawerMessage {
  type: 'AppDrawer/Role'
}

export interface AppDrawerState {
  open: boolean
  variant: UserRole
}

export const defaultAppDrawerState: AppDrawerState = {
  open: false,
  variant: USERROLES.Trainee,
}
