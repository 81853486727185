import { Message } from '@restate/core'

export enum RouterMessageType {
  GO = 'Service/Router/Go',
  BACK = 'Service/Router/Back',
}

export interface GoMessage extends Message {
  type: RouterMessageType
  payload: {
    path: string
  }
}

export const goMessage: (path: string) => GoMessage = path => ({
  type: RouterMessageType.GO,
  payload: { path },
})

export const goBackMessage: () => GoMessage = () => ({
  type: RouterMessageType.BACK,
  payload: { path: '' },
})
