import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTitle } from './useTitle.hook'
import { useLayoutStyles } from './useLayoutStyles.hook'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { LOGO_HEIGHT } from 'theme/constants'
import Footer from 'components/Footer/Footer'
import { TranslationButton } from '../NavBar/NavBar'
import { Colors } from '../../theme/colors'

const useClasses = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
    },
    backgroundColor: Colors.neutrals[9],
  },
  logoImg: {
    // width: 150,
    height: LOGO_HEIGHT,
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
})

interface PublicPageLayoutProps {
  title: string
  showTranslationSwitch?: boolean
}

export const PublicPageLayout: React.FunctionComponent<PublicPageLayoutProps> = props => {
  const { root, deco } = useLayoutStyles()
  const { logoImg, header } = useClasses()
  const { title, showTranslationSwitch = false } = props
  const [isOpen, setIsOpen] = useState(false)

  useTitle(title)

  return (
    <div className={root}>
      <div className={deco} />

      <div className={header}>
        <img className={logoImg} src={Images.logoSmallUrl} alt='logo' />

        <TranslationButton
          showTranslationSwitch={showTranslationSwitch}
          open={isOpen}
          handleClose={() => {
            setIsOpen(false)
          }}
          handleOpen={() => {
            setIsOpen(true)
          }}
        />
      </div>

      {props.children}

      <div style={{ flexGrow: 1 }} />
      <Footer />
    </div>
  )
}
