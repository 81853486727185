import { CourseTableData } from 'components/CourseTable/CourseTable'
import { Course } from 'model/Course'
import { routes } from 'model/ctrl/routes'
import { TrainerDTO } from 'model/ctrl/trainers/GetTrainersRes.schema'
import { Institute } from 'model/Institute'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { CoursesPageMessage } from './Courses.state'
import { CourseDocument } from 'model/db/CourseDocument.db-schema'

import messages from './Courses.messages'
import { USERROLES } from '../../model/UserRole'

export interface CourseAggregate extends Course {
  institute: Institute
  trainer: TrainerDTO
}

function model2ViewModel(model: CourseAggregate[]): CourseTableData[] {
  return model.map(course => {
    return {
      id: course._id,
      selected: false,
      isSelectionEnabled: true,
      date: new Date(course.date),
      institute: course.institute.name,
      trainer: course.trainer
        ? course.trainer.firstName + ' ' + course.trainer.familyName
        : '-',
      trainerEmail: course.trainer ? course.trainer.email : '-',
      identifier: course.identifier || '',
      location: course.location,
      traineeCount: course.trainees.length,
    }
  })
}

export const connectCoursesPageService = (store: AppStore) => {
  const { onLoginPageEnter, http, onPageLeave, onAction, snackBar, intl } =
    tools(store)

  onPageLeave(AppRoutes.Courses).subscribe(() => {
    store.next(state => {
      state.pages.courses.meta.loading = true
      state.pages.courses.meta.notFound = false
    })
  })

  function fetchCourses() {
    http<CourseAggregate[]>({ route: { path: routes.courses } }).subscribe(
      courses => {
        store.next(
          state => {
            state.pages.courses.data = model2ViewModel(courses)
            state.pages.courses.meta.loading = false
            state.pages.courses.meta.notFound = false
          },
          { type: 'Page/Courses/Service/Update' }
        )
      },

      _error => {
        console.error(_error)
        store.next(
          state => {
            debugger
            state.pages.courses.data = []
            state.pages.courses.meta.notFound = true
            state.pages.courses.meta.loading = false
          },
          { type: 'Page/Courses/Service/Error' }
        )
      }
    )
  }

  onLoginPageEnter(AppRoutes.Courses, {
    roles: [
      USERROLES.CorPatchAdmin,
      USERROLES.InstituteAdmin,
      USERROLES.Trainer,
    ],
  }).subscribe(() => {
    fetchCourses()
  })

  //
  // Delete institute and associated trainers, admins and trainees
  //
  onAction({ type: 'Page/Courses/Delete' }).subscribe(deleteReq => {
    const id = (deleteReq.message as CoursesPageMessage).payload
    const isAdmin = store.state.session.user.role === USERROLES.CorPatchAdmin

    if (isAdmin) {
      http<CourseDocument>({
        route: { path: routes.course, id },
        method: 'DELETE',
      }).subscribe(
        () => {
          const successSnackbar = intl().formatMessage(messages.successSnackbar)
          snackBar.success(successSnackbar)
          fetchCourses()
        },
        () => {
          const errorSnackbar = intl().formatMessage(messages.errorSnackbar)
          snackBar.error(errorSnackbar)
        }
      )
    }
  })
}
