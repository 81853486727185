import { defineMessages } from 'react-intl'

export default defineMessages({
  SettingsPageTitle: {
    id: 'cp.pages.settings.title',
    defaultMessage: 'Settings',
  },
  TableRowProperty: {
    id: 'cp.pages.settings.tableRowProperty',
    defaultMessage: 'Property',
  },
  TableRowValue: {
    id: 'cp.pages.settings.tableRowValue',
    defaultMessage: 'Value',
  },
  TableRowLocked: {
    id: 'cp.pages.settings.tableRowLocked',
    defaultMessage: 'Locked',
  },
  TableRowTrainees: {
    id: 'cp.pages.settings.tableRowTrainees',
    defaultMessage: 'Default number of trainees',
  },
  TableRowDuration: {
    id: 'cp.pages.settings.tableRowDuration',
    defaultMessage: 'Session Duration',
  },
  TableRowFeedback: {
    id: 'cp.pages.settings.tableRowFeedback',
    defaultMessage: 'Feedback Mode',
  },
  TableRowType: {
    id: 'cp.pages.settings.tableRowType',
    defaultMessage: 'Session Type',
  },
  TableRowCompression: {
    id: 'cp.pages.settings.tableRowCompression',
    defaultMessage: 'Compression only',
  },

  TableRowFrequency: {
    id: 'cp.pages.settings.tableRowFrequency',
    defaultMessage: 'Target Frequency',
  },
  TableRowResult: {
    id: 'cp.pages.settings.tableRowResult',
    defaultMessage: 'Result Type',
  },
  TableRowERC: {
    id: 'cp.pages.settings.tableRowERC',
    defaultMessage: 'Check for vital signs',
  },
  TableRowSaveButton: {
    id: 'cp.pages.settings.tableRowSaveButton',
    defaultMessage: 'Save',
  },
  FeedbackModeMenuItemNone: {
    id: 'cp.pages.settings.feedbackMode.menuItemNone',
    defaultMessage: 'none',
  },
  FeedbackModeMenuItemExpert: {
    id: 'cp.pages.settings.feedbackMode.menuItemExpert',
    defaultMessage: 'expert',
  },
  FeedbackModeMenuItemSimple: {
    id: 'cp.pages.settings.feedbackMode.menuItemSimple',
    defaultMessage: 'simple',
  },
  ResultTypeMenuItemNone: {
    id: 'cp.pages.settings.resultType.menuItemNone',
    defaultMessage: 'none',
  },
  ResultTypeMenuItemSimple: {
    id: 'cp.pages.settings.resultType.menuItemSimple',
    defaultMessage: 'simple',
  },
  ResultTypeMenuItemStandard: {
    id: 'cp.pages.settings.resultType.menuItemStandard',
    defaultMessage: 'standard',
  },
  ResultTypeMenuItemExpert: {
    id: 'cp.pages.settings.resultType.menuItemExpert',
    defaultMessage: 'expert',
  },
  MenuItemThresholdGood: {
    id: 'cp.pages.settings.menuItemThresholdGood',
    defaultMessage: 'Threshold Feedback "Good"',
  },
  MenuItemThresholdGreat: {
    id: 'cp.pages.settings.menuItemThresholdGreat',
    defaultMessage: 'Threshold Feedback "Great"',
  },
  TableRowResultConfigSaveButton: {
    id: 'cp.pages.settings.tableRowResultConfigSaveButton',
    defaultMessage: 'Save result configuration',
  },
})
