import { defineMessages } from 'react-intl'

export default defineMessages({
  Password: {
    id: 'cp.components.userMenu.password',
    defaultMessage: 'Password',
  },
  Logout: {
    id: 'cp.components.userMenu.logout',
    defaultMessage: 'Logout',
  },
  EMail: {
    id: 'cp.components.userMenu.email',
    defaultMessage: 'E-Mail',
  },
})
