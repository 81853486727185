import { UserInfo } from 'model/ctrl/signup/ValidateRes.schema'

export interface AcceptInvitationPageState {
  meta: {
    loading: boolean
    notFound: boolean
    success: boolean
  }
  userInfo: UserInfo | null
}

export const defaultAcceptInvitationPageState: AcceptInvitationPageState = {
  meta: {
    loading: true,
    notFound: false,
    success: false,
  },
  userInfo: null,
}
