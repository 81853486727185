import { AppStore } from 'state/store'
import RouteParser from 'route-parser'

export const pageParams = (store: AppStore) => (route: string) => {
  const routeParser = new RouteParser(route)
  return () => {
    const pathname = store.state.location.pathname
    const match = routeParser.match(pathname)
    return match !== false ? match : {}
  }
}
