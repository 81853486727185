import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'cp.pages.dashboard.pageTitle',
    defaultMessage: 'Statistics',
  },
  usersCount: {
    id: 'cp.pages.dashboard.usersCount',
    defaultMessage: 'total amount of registered users',
  },
  mobilesCount: {
    id: 'cp.pages.dashboard.mobilesCount',
    defaultMessage: 'all mobiles recognized',
  },
  cpsCount: {
    id: 'cp.pages.dashboard.cpsCount',
    defaultMessage: 'total CPS at work',
  },
  trainingsCount: {
    id: 'cp.pages.dashboard.trainingsCount',
    defaultMessage: 'all trainings absolved',
  },
  lastMonth: {
    id: 'cp.pages.dashboard.lastMonth',
    defaultMessage: 'Development in the last month.',
  },
  usersCountRecently: {
    id: 'cp.pages.dashboard.usersCountRecently',
    defaultMessage: 'new users registered',
  },
  mobilesCountRecently: {
    id: 'cp.pages.dashboard.mobilesCountRecently',
    defaultMessage: 'recently newly seen mobiles',
  },
  cpsCountRecently: {
    id: 'cp.pages.dashboard.cpsCountRecently',
    defaultMessage: 'new CPS connected',
  },
})
