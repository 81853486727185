import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { Images } from 'theme/Images'
import { Button } from '@material-ui/core'
import { useGoBack } from 'services/router/useGoBack.hook'
import { useIntl } from 'react-intl'

import messages from './InternalServerError.messages'

export const InternalServerError: React.FC<{}> = () => {
  const intl = useIntl()
  const serverErrorTitle = intl.formatMessage(messages.serverErrorTitle)
  const serverErrorMessage = intl.formatMessage(messages.serverErrorTitle)
  const serverErrorButton = intl.formatMessage(messages.serverErrorButton)

  const goBack = useGoBack()
  return (
    <PageNotFound
      variant='public'
      title={serverErrorTitle}
      message={serverErrorMessage}
      img={Images.bugFix}
      action={() => (
        <Button color='secondary' variant='contained' onClick={goBack}>
          {serverErrorButton}
        </Button>
      )}
    />
  )
}

export default InternalServerError
