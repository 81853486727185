const IMG_DIR = process.env.PUBLIC_URL + '/imgs/'

// Color for undraw: #d64343
// or #65931a
// vivid: #dd901d
// cyan:#dd901d
export const Images = {
  // logoUrl: IMG_DIR + 'corpatch-logo-with-text.svg',
  bugFix: IMG_DIR + 'undraw_bug_fixing.svg',
  celebration: IMG_DIR + 'undraw_celebration.svg',
  confirmation: IMG_DIR + 'undraw_confirmation.svg',
  detailedExamination: IMG_DIR + 'undraw_detailed_examination_joef.svg',
  doctors: IMG_DIR + 'undraw_doctors_hwty.svg',
  emailValidation: IMG_DIR + 'undraw_mail_2_tqip.svg',
  empty: IMG_DIR + 'undraw_empty.svg',
  femaleAvatar: IMG_DIR + 'undraw_female_avatar.svg',
  otherAvatar: IMG_DIR + 'undraw_other_avatar.svg',
  forgotPassword: IMG_DIR + 'undraw_forgot_password.svg',
  logoSmallUrl: IMG_DIR + 'corpatch-logo-with-text-small.svg',
  logout: IMG_DIR + 'undraw_quitting_time_dm8t.svg',
  mail: IMG_DIR + 'undraw_mail_2.svg',
  maleAvatar: IMG_DIR + 'undraw_male_avatar.svg',
  medicine: IMG_DIR + 'undraw_medicine_b1ol.svg',
  missingUrl: IMG_DIR + 'undraw_blank_canvas_3rbb.svg',
  mobileApp: IMG_DIR + 'undraw_mobile_app.svg',
  productTearDown: IMG_DIR + 'undraw_product_teardown.svg',
  questions: IMG_DIR + 'undraw_questions.svg',
  securityOn: IMG_DIR + 'undraw_security_on.svg',
  teacher: IMG_DIR + 'undraw_teacher.svg',
  qrCodeAppStoreCPS: IMG_DIR + 'qr-code-appstore-cps.svg',
  qrCodePlayStoreCPS: IMG_DIR + 'qr-code-playstore-cps.svg',
  newsletter: IMG_DIR + 'undraw_newsletter_vovu.svg',
}
