import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  ImageCard,
  ImageCardActions,
  ImageCardContent,
} from 'components/ImageCard/ImageCard'
import { PublicPageLayout } from 'components/layouts/PublicPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import { Spinner } from 'components/Spinner/Spinner'
import React, { ChangeEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import FormattedMessage from 'react-intl/dist/components/message'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useDispatchHook } from 'state/store'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import messages from './LoginPasswordForgotSetPassword.messages'
import { useLoginPasswordForgotSetPasswordState } from './LoginPasswordForgotSetPassword.restate'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      margin: '1rem',
    },
  },
  inputField1: {
    marginTop: theme.spacing(5),
    minHeight: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      width: '100%',
    },
  },
  inputField: {
    marginBottom: theme.spacing(2),
    minHeight: 70,
  },
  welcome: {
    marginBottom: theme.spacing(3),
  },
})

interface ChangePasswordProps {
  onSend: (props: { newPassword: string }) => any
}

export const ChangePassword: React.FC<ChangePasswordProps> = props => {
  const intl = useIntl()

  const newRequiredError = intl.formatMessage(messages.newPasswordRequired)
  const newToShortError = intl.formatMessage(messages.newToShortError)
  const matchError = intl.formatMessage(messages.passwordDontMatch)
  const newPassword = intl.formatMessage(messages.newPassword)
  const newPasswordRepeat = intl.formatMessage(messages.newPasswordRepeat)
  const changePasswordButton = intl.formatMessage(messages.changePasswordButton)

  const { onSend } = props
  const classes = useStyles()

  const [newPassword0, setNewPassword0] = useState('')
  const [errorNewPassword0, setErrorNewPassword0] = useState(false)
  const [errorNewPasswordMessage0, setErrorNewPasswordMessage0] = useState('')

  const [newPassword1, setNewPassword1] = useState('')
  const [errorNewPassword1, setErrorNewPassword1] = useState(false)
  const [errorNewPasswordMessage1, setErrorNewPasswordMessage1] = useState('')

  //
  // NewPassword0
  //
  function resetNewPassword0Validation() {
    setErrorNewPassword0(false)
    setErrorNewPasswordMessage0('')
  }

  function validateNewPassword0() {
    resetNewPassword0Validation()

    if (newPassword0 === '') {
      setErrorNewPassword0(true)
      setErrorNewPasswordMessage0(newRequiredError)
    }
    if (newPassword0.length < 8) {
      setErrorNewPassword0(true)
      setErrorNewPasswordMessage0(newToShortError)
    }
  }

  //
  // NewPassword1
  //
  function resetNewPassword1Validation() {
    setErrorNewPassword1(false)
    setErrorNewPasswordMessage1('')
  }

  function validateNewPassword1() {
    resetNewPassword1Validation()

    if (newPassword0 !== newPassword1) {
      setErrorNewPassword1(true)
      setErrorNewPasswordMessage1(matchError)
    }
    if (newPassword1.length < 8) {
      setErrorNewPassword0(true)
      setErrorNewPasswordMessage0(newToShortError)
    }
  }

  function validate() {
    validateNewPassword0()
    validateNewPassword1()

    const errorNewPasswordMatch = newPassword0 !== newPassword1

    return !errorNewPasswordMatch && !errorNewPassword0
  }

  function handleSetNewPassword0(e: ChangeEvent<HTMLInputElement>) {
    resetNewPassword0Validation()
    setNewPassword0(e.target.value)
  }

  function handleSetNewPassword1(e: ChangeEvent<HTMLInputElement>) {
    resetNewPassword1Validation()
    setNewPassword1(e.target.value)
  }

  function sendChangePasswordRequest() {
    const valid = validate()
    if (valid) {
      onSend({ newPassword: newPassword0 })
    }
  }

  const buttonDisabled =
    errorNewPassword0 || errorNewPassword1 || newPassword0.length === 0

  return (
    <div className={classes.container}>
      <ImageCard imgUrl={Images.forgotPassword}>
        <ImageCardContent>
          <TextField
            value={newPassword0}
            label={newPassword}
            onChange={handleSetNewPassword0}
            className={classes.inputField}
            onBlur={validateNewPassword0}
            error={errorNewPassword0}
            helperText={errorNewPasswordMessage0}
            type='password'
            placeholder={newPassword}
          />
          <TextField
            value={newPassword1}
            label={newPasswordRepeat}
            onChange={handleSetNewPassword1}
            onBlur={validateNewPassword1}
            className={classes.inputField}
            error={errorNewPassword1}
            helperText={errorNewPasswordMessage1}
            type='password'
            placeholder={newPasswordRepeat}
          />
        </ImageCardContent>
        <ImageCardActions>
          <Button
            color='secondary'
            variant='contained'
            onClick={sendChangePasswordRequest}
            disabled={buttonDisabled}
          >
            {changePasswordButton}
          </Button>
        </ImageCardActions>
      </ImageCard>
    </div>
  )
}

const LoadingPage = () => {
  const classes = useStyles()

  return (
    <PublicPageLayout title=''>
      <div className={classes.container}>
        <Spinner visible={true} />
      </div>
    </PublicPageLayout>
  )
}

const NotFoundPage = () => {
  const intl = useIntl()
  const goTo = useGoToHook()
  const goToLogin = () => goTo(AppRoutes.Login)

  const message = intl.formatMessage(messages.pageNotFoundMessage)
  const title = intl.formatMessage(messages.pageNotFoundTitle)
  return (
    <PageNotFound
      variant='public'
      message={message}
      title={title}
      img={Images.empty}
      action={() => (
        <Button color='secondary' variant='contained' onClick={goToLogin}>
          <FormattedMessage {...messages.loginButton} />
        </Button>
      )}
    />
  )
}

const SetPasswordPage = () => {
  const intl = useIntl()
  const title = intl.formatMessage(messages.successTitle)
  const dispatch = useDispatchHook()

  const onSend = ({ newPassword }: { newPassword: string }) => {
    dispatch({
      type: 'Pages/LoginPasswordForgotSetPassword/Set',
      payload: newPassword,
    })
  }

  return (
    <PublicPageLayout title={title} showTranslationSwitch>
      <ChangePassword onSend={onSend} />
    </PublicPageLayout>
  )
}

const SuccessPage = () => {
  const intl = useIntl()
  const message = intl.formatMessage(messages.successMessage)
  const title = intl.formatMessage(messages.successTitle)
  const loginButton = intl.formatMessage(messages.loginButton)

  const goTo = useGoToHook()

  const goToLogin = () => goTo(AppRoutes.Login)

  return (
    <PageNotFound
      variant='public'
      message={message}
      title={title}
      img={Images.confirmation}
      action={() => (
        <Button color='secondary' variant='contained' onClick={goToLogin}>
          {loginButton}
        </Button>
      )}
    />
  )
}

export const LoginPasswordForgotSetPassword: React.FC<{}> = () => {
  const { loading, notFound } = useLoginPasswordForgotSetPasswordState(
    s => s.meta
  )
  const success = useLoginPasswordForgotSetPasswordState(s => s.success)

  if (loading) return <LoadingPage />
  if (notFound) return <NotFoundPage />
  if (success) return <SuccessPage />

  return <SetPasswordPage />
}

export default LoginPasswordForgotSetPassword
