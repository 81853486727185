import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'cp.pages.traineeShares.pageTitle',
    defaultMessage: 'Trainee Shares',
  },
  pageTitleNotFound: {
    id: 'cp.pages.traineeShares.pageTitleNotFound',
    defaultMessage: 'Not found',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.traineeShares.pageNotFoundMessage',
    defaultMessage: 'The trainee shares you are looking for do not exists.',
  },
  active: {
    id: 'cp.pages.traineeShares.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'cp.pages.traineeShares.inactive',
    defaultMessage: 'Inactive',
  },
  addShare: {
    id: 'cp.pages.traineeShares.addShare',
    defaultMessage: 'Add Share',
  },
  noActiveItemsMessage: {
    id: 'cp.pages.traineeShares.noActiveItemsMessage',
    defaultMessage:
      'Currently, you have not agreed to any data sharing agreements. Press the plus button at the bottom right to enter a corresponding invitation code.',
  },
  noInactiveItemsMessage: {
    id: 'cp.pages.traineeShares.noInactiveItemsMessage',
    defaultMessage: 'No in-active sharing agreements.',
  },
  startDate: {
    id: 'cp.pages.traineeShares.startDate',
    defaultMessage: 'Start date: ',
  },
  endDate: {
    id: 'cp.pages.traineeShares.endDate',
    defaultMessage: 'End date: ',
  },
  deactivateTooltip: {
    id: 'cp.pages.traineeShares.deactivateTooltip',
    defaultMessage: 'Deactivate',
  },

  addDialogTitle: {
    id: 'cp.pages.traineeShares.addDialogTitle',
    defaultMessage: 'Invitation',
  },
  addDialogDescription: {
    id: 'cp.pages.traineeShares.addDialogDescription',
    defaultMessage:
      'Enter the invitation code you got from your partner organization:',
  },
  addDialogDescription2: {
    id: 'cp.pages.traineeShares.addDialogDescription2',
    defaultMessage:
      'If you choose to click "Add," you are acknowledging that you agree to the terms and conditions provided to you by the partner organization along with this invitation link/code. It is essential to read and understand the terms and conditions before proceeding.',
  },
  addDialogCancel: {
    id: 'cp.pages.traineeShares.addDialogCancel',
    defaultMessage: 'Cancel',
  },
  addDialogAdd: {
    id: 'cp.pages.traineeShares.addDialogAdd',
    defaultMessage: 'Add',
  },
  deactivateDialogTitle: {
    id: 'cp.pages.traineeShares.deactivateDialogTitle',
    defaultMessage: 'Deactivate',
  },
  deactivateDialogDescription: {
    id: 'cp.pages.traineeShares.deactivateDialogDescription',
    defaultMessage:
      'Are you sure you want to deactivate this sharing agreement with {instituteName}?',
  },
  deactivateDialogNo: {
    id: 'cp.pages.traineeShares.deactivateDialogNo',
    defaultMessage: 'Cancel',
  },
  deactivateDialogYes: {
    id: 'cp.pages.traineeShares.deactivateDialogYes',
    defaultMessage: 'Deactivate',
  },
  snackBarInvalidCode: {
    id: 'cp.pages.traineeShares.snackBarInvalidCode',
    defaultMessage: 'Please enter a valid invitation code.',
  },
  snackBarInvitationAdded: {
    id: 'cp.pages.traineeShares.snackBarInvitationAdded',
    defaultMessage: 'Invitation added',
  },
  snackBarErrorAddingInvitation: {
    id: 'cp.pages.traineeShares.snackBarErrorAddingInvitation',
    defaultMessage: 'Error adding invitation: {errorMessage}!',
  },
  snackBarSharingDeactivated: {
    id: 'cp.pages.traineeShares.snackBarSharingDeactivated',
    defaultMessage: 'Sharing deactivated',
  },
  snackBarErrorDeactivatingSharing: {
    id: 'cp.pages.traineeShares.snackBarErrorDeactivatingSharing',
    defaultMessage: 'Error deactivating sharing: {errorMessage}!',
  },
})
