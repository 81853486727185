import { defineMessages } from 'react-intl'

export default defineMessages({
  confirmDeleteDialogTitle: {
    id: 'cp.components.dialog.confirmDeletion.title',
    defaultMessage: 'Delete selected account?',
  },
  confirmDeleteDialogContentDescription: {
    id: 'cp.components.dialog.confirmDeletion.contentDescription',
    defaultMessage:
      'You are about to delete an account with the following role(s):',
  },
  confirmDeleteDialogContentConclusion: {
    id: 'cp.components.dialog.confirmDeletion.contentConclusion',
    defaultMessage:
      'The deletion is permanent. This account will no longer be available.',
  },
  deleteDialogButtonPrimary: {
    id: 'cp.pages.trainee.deleteDialogButtonPrimary',
    defaultMessage: 'Delete',
  },
  deleteDialogButtonSecondary: {
    id: 'cp.pages.trainee.deleteDialogButtonSecondary',
    defaultMessage: 'Close',
  },
})
