import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { USERROLES } from '../../model/UserRole'

export const connectInstituteAdminSharesPage = (store: AppStore) => {
  const { onLoginPageEnter, http } = tools(store)
  const { api } = config()

  // //
  // // entering page and user is a corpatch admin
  // //
  onLoginPageEnter(AppRoutes.InstituteAdminShares, {
    roles: [USERROLES.InstituteAdmin],
  }).subscribe(() => {
    http<SharingObjectDocument[]>({
      url: api + routes.sharing.sharingObjects,
      method: 'GET',
    }).subscribe(sharingObjects => {
      store.next(state => {
        state.pages.instituteAdminShares.sharingObjects = sharingObjects
      })
    })
  })
}
