export const AppRoutes = {
  AcceptInvitation: '/invite/:id',
  AdminInvite: '/institutes/:id/admin/invite',
  Course: '/courses/:id',
  Courses: '/courses',
  EmailValidation: '/email/validate/:id',
  Export: '/export',
  GlobalSettings: '/global-settings',
  Home: '/',
  Imprint: '/imprint',
  Institute: '/institutes/:id',
  InstituteAdminShares: '/institute-admin-shares',
  InstituteAdminSharingObject: '/institute-admin-shares/:id',
  Institutes: '/institutes',
  InternalServerError: '/error/500',
  Login: '/login',
  LoginPasswordForgot: '/login/password',
  LoginPasswordForgotSetPassword: '/login/reset/:code',
  LoginTermsOfUse: '/login/termsofuse',
  Logout: '/logout',
  MeAccount: '/me/account',
  MeEMail: '/me/email',
  MePassword: '/me/password',
  NetworkError: '/error/network',
  Register: '/register',
  Settings: '/settings',
  SharingObject: '/sharing/:id',
  Shop: '/shop',
  SingleTrainings: '/cps/trainings',
  Trainee: '/trainees/:id',
  Trainees: '/trainees',
  TraineeShares: '/trainee-shares',
  TraineeSharesInvite: '/trainee-shares/invite/:id',
  Trainer: '/trainers/:id',
  TrainerInvite: '/institutes/:id/trainer/invite',
  Trainers: '/trainers',
  Trainings: '/courses/:id/training/:idx',
  UserDetails: '/user/:id',
  ValidateLogin: '/login/validate/:token',
  ValidateLoginPublic: '/login/validate',
}
