import { defineMessages } from 'react-intl'

export default defineMessages({
  serverErrorTitle: {
    id: 'cp.pages.internalServerError.title',
    defaultMessage: 'Oh no! Something went wrong!',
  },
  serverErrorMessage: {
    id: 'cp.pages.internalServerError.message',
    defaultMessage:
      'The server was not able to complete the request. This is on us. Please try again later. ',
  },
  serverErrorButton: {
    id: 'cp.pages.internalServerError.button',
    defaultMessage: 'Try again',
  },
})
