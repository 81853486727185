import { defineMessages } from 'react-intl'

export default defineMessages({
  institute: {
    id: 'cp.components.instituteForm.institute',
    defaultMessage: 'Institute',
  },
  instituteAddress: {
    id: 'cp.components.instituteForm.instituteAddress',
    defaultMessage: 'Institute Address',
  },
  instituteName: {
    id: 'cp.components.instituteForm.instituteName',
    defaultMessage: 'Name of the institute',
  },
  instituteStreet: {
    id: 'cp.components.instituteForm.instituteStreet',
    defaultMessage: 'Street and house number',
  },
  instituteStreetAdditional: {
    id: 'cp.components.instituteForm.instituteStreetAdditional',
    defaultMessage: 'Addition to address',
  },
  instituteState: {
    id: 'cp.components.instituteForm.instituteState',
    defaultMessage: 'State',
  },
  instituteZIP: {
    id: 'cp.components.instituteForm.instituteZIP',
    defaultMessage: 'Zip code',
  },
  instituteCity: {
    id: 'cp.components.instituteForm.instituteCity',
    defaultMessage: 'City',
  },
  instituteCountry: {
    id: 'cp.components.instituteForm.instituteCountry',
    defaultMessage: 'Country',
  },
  instituteSaveButton: {
    id: 'cp.components.instituteForm.instituteSaveButton',
    defaultMessage: 'Save',
  },
  instituteResultConfigBtn: {
    id: 'cp.components.instituteResultConfig.button',
    defaultMessage: 'configure result feedback',
  },
  instituteResultConfigLabelGood: {
    id: 'cp.components.instituteResultConfig.thresholdGood',
    defaultMessage: 'Threshold Good',
  },
  instituteResultConfigLabelGreat: {
    id: 'cp.components.instituteResultConfig.thresholdGreat',
    defaultMessage: 'Threshold Great',
  },
  instituteResultConfigSave: {
    id: 'cp.components.instituteResultConfig.save',
    defaultMessage: 'Save Configuration',
  },
  instituteResultConfigInfo: {
    id: 'cp.components.instituteResultConfig.info',
    defaultMessage:
      'Be advised that changes are made on an institute-wide level.',
  },
  localeLabel: {
    id: 'cp.components.instituteForm.localeLabel',
    defaultMessage: 'Language',
  },
  localeEnglish: {
    id: 'cp.components.instituteForm.localeEnglish',
    defaultMessage: 'English',
  },
  localeGerman: {
    id: 'cp.components.instituteForm.localeGerman',
    defaultMessage: 'German',
  },
  localeDanish: {
    id: 'cp.components.instituteForm.localeDanish',
    defaultMessage: 'Danish',
  },
  localeFinish: {
    id: 'cp.components.instituteForm.localeFinish',
    defaultMessage: 'Finish',
  },
  localeItalian: {
    id: 'cp.components.instituteForm.localeItalian',
    defaultMessage: 'Italian',
  },
  localeFrench: {
    id: 'cp.components.instituteForm.localeFrench',
    defaultMessage: 'French',
  },
  localeSpanish: {
    id: 'cp.components.instituteForm.localeSpanish',
    defaultMessage: 'Spanish',
  },
})
