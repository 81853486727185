import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'

export interface SharingObjectsState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  data: SharingObjectDocument
}

export const defaultSharingObjectsState: SharingObjectsState = {
  meta: {
    loading: false,
    notFound: false,
  },
  data: {
    _id: '',
    name: '',
    type: 'training',
    version: '?',
    instituteId: '?',
    invitationCode: '?',
    pullApiSecret: '?',
    webHook: '?',
    active: false,
    meta: {
      createdAt: new Date(),
      deletedAt: null,
      updatedAt: new Date(),
    },
  },
}
