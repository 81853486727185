import { Message } from '@restate/core'
import { config } from 'config/config'
import { ForgotPasswordSetPasswordReq } from 'model/ctrl/forgotPassword/ForgotPasswordSetPasswordReq.schema'
import { routes } from 'model/ctrl/routes'
import { AppRoutes } from 'routes'
import { delay } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

export interface PagesPasswordForgotSetPasswordMessage extends Message {
  type: 'Pages/LoginPasswordForgotSetPassword/Set'
  payload: string
}

export const connectLoginPasswordForgotSetPasswordService = (
  store: AppStore
) => {
  const { onPageLeave, onPageEnter, pageParams, http, onAction } = tools(store)
  const params = pageParams(AppRoutes.LoginPasswordForgotSetPassword)

  onPageLeave(AppRoutes.LoginPasswordForgotSetPassword).subscribe(() => {
    store.next(s => {
      s.pages.loginPasswordForgotSetPassword.meta = {
        loading: true,
        notFound: false,
      }
      s.pages.loginPasswordForgotSetPassword.success = false
    })
  })

  onPageEnter(AppRoutes.LoginPasswordForgotSetPassword).subscribe(() => {
    const { code } = params()

    const success = () => {
      store.next(s => {
        s.pages.loginPasswordForgotSetPassword.meta = {
          loading: false,
          notFound: false,
        }
      })
    }

    const error = () => {
      store.next(s => {
        s.pages.loginPasswordForgotSetPassword.meta = {
          loading: false,
          notFound: true,
        }
      })
    }

    http({
      route: {
        path: routes.forgotPassword.checkCode,
        id: code,
      },
    })
      .pipe(delay(500))
      .subscribe(success, error)
  })

  onAction({ type: 'Pages/LoginPasswordForgotSetPassword/Set' }).subscribe(
    pck => {
      const { api } = config()
      const { code } = params()

      const password = (pck.message as PagesPasswordForgotSetPasswordMessage)
        .payload

      const data: ForgotPasswordSetPasswordReq = {
        code,
        password,
      }

      store.next(s => {
        s.pages.loginPasswordForgotSetPassword.meta.loading = true
      })

      const success = () => {
        store.next(s => {
          s.pages.loginPasswordForgotSetPassword = {
            meta: {
              loading: false,
              notFound: false,
            },
            success: true,
          }
        })
      }

      const error = () => {
        store.next(s => {
          s.pages.loginPasswordForgotSetPassword = {
            meta: {
              loading: false,
              notFound: true,
            },
            success: false,
          }
        })
      }

      http({
        method: 'POST',
        url: api + routes.forgotPassword.resetPassword,
        data,
      })
        .pipe(delay(1000))
        .subscribe(success, error)
    }
  )
}
