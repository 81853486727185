import { AppStore } from 'state/store'
import { onPageEnter } from './onPageEnter'
import { onLogin } from './onLogin'
import { combineLatest, animationFrameScheduler } from 'rxjs'
import { map, observeOn, filter } from 'rxjs/operators'
import { UserRole, USERROLES } from 'model/UserRole'

interface OnLoginPageEnterProps {
  roles: UserRole[]
}

const defaultOnLoginPageEnterProps = {
  roles: [
    USERROLES.CorPatchAdmin,
    USERROLES.InstituteAdmin,
    USERROLES.Trainer,
    USERROLES.Trainee,
    USERROLES.Unknown,
  ] as UserRole[],
}

/**
 * If the user opens a page and is logged in
 */
export const onLoginPageEnter = (store: AppStore) => (
  route: string,
  props: OnLoginPageEnterProps = defaultOnLoginPageEnterProps
) => {
  const enterEvent = onPageEnter(store)(route)
  const loginEvent = onLogin(store)()

  function hasRole() {
    return props.roles.indexOf(store.state.session.user.role) !== -1
  }

  return combineLatest(enterEvent, loginEvent).pipe(
    observeOn(animationFrameScheduler),
    filter(hasRole),
    map(arr => ({
      pageEnter: arr[0],
      loginEvent: arr[1],
    }))
  )
}
