import { defineMessages } from 'react-intl'

export default defineMessages({
  tabsBase: {
    id: 'cp.pages.institute.tabsBaseData',
    defaultMessage: 'BASE DATA',
  },
  tabsAdmins: {
    id: 'cp.pages.institute.tabsAdmins',
    defaultMessage: 'ADMINS',
  },
  tabsTrainers: {
    id: 'cp.pages.institute.tabsTrainers',
    defaultMessage: 'TRAINERS',
  },
  institutePageNotFoundTitle: {
    id: 'cp.pages.institute.PageNotFound.title',
    defaultMessage: 'Institute not found.',
  },
  institutePageNotFoundMessage: {
    id: 'cp.pages.institute.PageNotFound.message',
    defaultMessage:
      'This institute has been deleted and cannot be edited anymore.',
  },
  institute: {
    id: 'cp.pages.institute.institute',
    defaultMessage: 'Institute',
  },
  instituteSuccessSnackbar: {
    id: 'cp.pages.institute.snackbarSuccess',
    defaultMessage: 'Saved',
  },
  instituteErrorSnackbar: {
    id: 'cp.pages.institute.snackbarError',
    defaultMessage: 'Error saving institute',
  },
})
