import { routes } from 'model/ctrl/routes'
import { SharingObjectDocument } from 'model/db/SharingObjectDocument.db-schema'
import { AppRoutes } from 'routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { USERROLES } from '../../model/UserRole'
import { defaultSharingObjectsState } from './SharingObject.state'
import { Message } from '@restate/core'
import messages from './SharingObject.messages'

export interface PageSharingObjectSaveMessage extends Message {
  type: 'Page/SharingObject/Save'
}

export interface PageSharingObjectDeleteMessage extends Message {
  type: 'Page/SharingObject/Delete'
}

export type PageSharingObjectMessages =
  | PageSharingObjectSaveMessage
  | PageSharingObjectDeleteMessage

export const connectSharingObjectPage = (store: AppStore) => {
  const {
    onLoginPageEnter,
    http,
    snackBar,
    pageParams,
    onAction,
    goTo,
    intl,
  } = tools(store)

  const { CorPatchAdmin, InstituteAdmin } = USERROLES

  function fetchData(url: string) {
    const params = pageParams(url)
    const { id } = params()

    http<SharingObjectDocument>({
      route: { path: routes.sharing.sharingObject, id },
    }).subscribe(
      sharingObjects =>
        store.next(state => {
          state.pages.sharingObjects.data = sharingObjects
        }),
      error => {
        console.log(error)
        store.next(state => {
          state.pages.sharingObjects = defaultSharingObjectsState
          state.pages.sharingObjects.meta.notFound = true
          state.pages.sharingObjects.meta.loading = false
        })
      }
    )
  }

  onLoginPageEnter(AppRoutes.SharingObject, {
    roles: [CorPatchAdmin],
  }).subscribe(_params => {
    fetchData(AppRoutes.SharingObject)
  })

  onLoginPageEnter(AppRoutes.InstituteAdminSharingObject, {
    roles: [InstituteAdmin],
  }).subscribe(_params => {
    fetchData(AppRoutes.InstituteAdminSharingObject)
  })

  onAction({ type: 'Page/SharingObject/Save' }).subscribe(_ => {
    const sharingObject = store.state.pages.sharingObjects.data
    const id = sharingObject._id

    function onSuccess() {
      snackBar.success(intl().formatMessage(messages.snackBarSaved))
    }

    function onError() {
      snackBar.error(intl().formatMessage(messages.snackBarSaveError))
    }

    http<SharingObjectDocument>({
      method: 'POST',
      route: { path: routes.sharing.sharingObject, id },
      data: sharingObject,
    }).subscribe(onSuccess, onError)
  })

  onAction({ type: 'Page/SharingObject/Delete' }).subscribe(() => {
    const sharingObject = store.state.pages.sharingObjects.data
    const id = sharingObject._id

    function onSuccess() {
      snackBar.success(intl().formatMessage(messages.snackBarDeleted))
      goTo(AppRoutes.Institute, { id: sharingObject.instituteId })
    }

    function onError() {
      snackBar.error(intl().formatMessage(messages.snackBarDeleteError))
    }

    http<SharingObjectDocument>({
      method: 'POST',
      route: { path: routes.sharing.admin.deleteSharingObject, id },
    }).subscribe(onSuccess, onError)
  })
}
