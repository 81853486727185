import { debounceTime } from 'rxjs/operators'
import { RouterMessageType } from 'services/router/router.restate'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

export const connectAppDrawer = (store: AppStore) => {
  const { onAction, onChange } = tools(store)

  onAction({ type: RouterMessageType.GO }).subscribe(() =>
    store.next(s => {
      s.components.appDrawer.open = false
    })
  )

  onChange(s => s.session.user.role)
    .pipe(debounceTime(10))
    .subscribe(role => {
      store.next(s => {
        s.components.appDrawer.variant = role
      })
    })
}
