import { AppStore } from 'state/store'
import { goTo } from './goTo'
import { http } from './http'
import { onAction } from './onAction'
import { onChange } from './onChange'
import { onLogin } from './onLogin'
import { onLogout } from './onLogout'
import { onPageEnter } from './onPageEnter'
import { onPageLeave } from './onPageLeave'
import { hasUserRole } from './hasUserRole'
import { snackBar } from './snackBar'
import { pageParams } from './pageParams'
import { onLoginPageEnter } from './onLoginPageEnter'
import { intl } from './intl'

export const tools = (store: AppStore) => ({
  goTo: goTo(store),
  hasUserRole: hasUserRole(store),
  http: http(store),
  intl: intl(store),
  onAction: onAction(store),
  onChange: onChange(store),
  onLogin: onLogin(store),
  onLoginPageEnter: onLoginPageEnter(store),
  onLogout: onLogout(store),
  onPageEnter: onPageEnter(store),
  onPageLeave: onPageLeave(store),
  pageParams: pageParams(store),
  snackBar: snackBar(store),
})
