import { FeedbackConfig } from 'model/FeedbackConfig'
import { ResultConfig } from '../../model/CptConfig'

export interface GlobalSettingsState {
  meta: {
    loading: boolean
  }
  feedbackConfig: FeedbackConfig
  resultConfig: ResultConfig
  sessionConfig: {
    audioFeedbackDebounceTime: number
  }
}

export const defaultGlobalSettingsState: GlobalSettingsState = {
  meta: {
    loading: true,
  },
  feedbackConfig: {
    averageCountDepth: -1,
    averageCountFreq: -1,
    averageCountRecoil: -1,
    maxDepth: -1,
    maxDepthLimit: -1,
    maxFreq: -1,
    maxFreqLimit: -1,
    maxRecoil: -1,
    minDepth: -1,
    minDepthLimit: -1,
    minFreq: -1,
    minFreqLimit: -1,
    minRecoil: -1,
    minRecoilLimit: -1,
    noFlow: -1,
    ventilationTime: -1,
  },
  resultConfig: {
    simple: {
      thresholdGood: 50,
      thresholdGreat: 70,
    },
  },
  sessionConfig: {
    audioFeedbackDebounceTime: 1000,
  },
}
