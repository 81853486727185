import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { CircularTrainingProgress } from 'components/CircularTrainingProgress/CircularTrainingProgress'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { FormattedDate, FormattedTime, useIntl } from 'react-intl'
import { useAppState } from 'state/store'
import { Colors } from 'theme/colors'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'

import message from './Trainings.messages'

const useClasses = makeStyles({
  title: {
    fontSize: 26,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  location: {
    fontSize: 22,
  },
  date: {
    fontSize: 12,
  },
  time: {
    fontSize: 22,
  },
  dateTime: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  duration: {
    fontSize: 16,
  },

  card: {
    minWidth: '100%',
    marginBottom: theme.spacing(2),
  },

  circle: {
    margin: theme.spacing(1),
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const TrainingsLoading = () => {
  const intl = useIntl()
  const loadingTitle = intl.formatMessage(message.loadingTitle)

  return <AppPageLayout title={loadingTitle} loading={true}></AppPageLayout>
}

const TrainingsNotFound = () => {
  const intl = useIntl()
  const trainingsNotFoundTitle = intl.formatMessage(
    message.trainingsNotFoundTitle
  )
  const trainingsNotFoundMessage = intl.formatMessage(
    message.trainingsNotFoundMessage
  )

  return (
    <PageNotFound
      title={trainingsNotFoundTitle}
      message={trainingsNotFoundMessage}
      img={Images.detailedExamination}
    />
  )
}

export const Trainings: React.FC<{}> = () => {
  const intl = useIntl()
  const trainingTitle = intl.formatMessage(message.loadingTitle)
  const trainingsTrainee = intl.formatMessage(message.trainingsTrainee)
  const trainingsFlow = intl.formatMessage(message.trainingsFlow)
  const trainingsDepth = intl.formatMessage(message.trainingsDepth)
  const trainingsRecoil = intl.formatMessage(message.trainingsRecoil)
  const trainingsFrequency = intl.formatMessage(message.trainingsFrequency)
  const trainingNumber = intl.formatMessage(message.trainingNumber)

  const { loading, notFound } = useAppState(s => s.pages.course.meta)
  const course = useAppState(s => s.pages.course.course)
  const trainingsPage = useAppState(s => s.pages.trainings)

  const classes = useClasses()

  const training = course?.trainings[trainingsPage.idx]

  if (loading) {
    return <TrainingsLoading />
  }

  if (notFound || training == null) {
    return <TrainingsNotFound />
  }

  return (
    <AppPageLayout title={trainingTitle} showBackButton={true}>
      <>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.header}>
              <div>
                <Typography className={classes.title}>
                  {trainingNumber}
                  {Number(trainingsPage.idx) + 1}
                </Typography>
              </div>
              <div className={classes.dateTime}>
                <Typography className={classes.time}>
                  <FormattedTime value={training.date} />
                </Typography>

                <Typography className={classes.date}>
                  <FormattedDate
                    value={training.date}
                    year='numeric'
                    month='long'
                    day='2-digit'
                  />
                </Typography>
              </div>
            </div>

            <Table>
              <TableHead>
                <TableCell>{trainingsTrainee}</TableCell>
                <TableCell>{trainingsFlow}</TableCell>
                <TableCell>{trainingsDepth}</TableCell>
                <TableCell>{trainingsRecoil}</TableCell>
                <TableCell>{trainingsFrequency}</TableCell>
              </TableHead>
              <TableBody>
                {training.sessions.map(session => (
                  <TableRow>
                    <TableCell>{session.trainee}</TableCell>
                    <TableCell>
                      <CircularTrainingProgress
                        size={50}
                        fontSize={12}
                        showLabel={false}
                        thickness={4}
                        className={classes.circle}
                        value={session.result.flowPercent}
                        color={Colors.primary[3]}
                      />
                    </TableCell>
                    <TableCell>
                      <CircularTrainingProgress
                        size={50}
                        fontSize={12}
                        showLabel={false}
                        thickness={4}
                        className={classes.circle}
                        value={session.result.cprCorrectDepthPercent}
                        color={Colors.vivid[3]}
                      />
                    </TableCell>
                    <TableCell>
                      <CircularTrainingProgress
                        size={50}
                        fontSize={12}
                        showLabel={false}
                        thickness={4}
                        className={classes.circle}
                        value={session.result.cprCorrectRecoilPercent}
                        color={Colors.supportingLime[3]}
                      />
                    </TableCell>
                    <TableCell>
                      <CircularTrainingProgress
                        size={50}
                        fontSize={12}
                        showLabel={false}
                        thickness={4}
                        className={classes.circle}
                        value={session.result.cprCorrectFrequencyPercent}
                        color={Colors.supportingCyan[3]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </>
    </AppPageLayout>
  )
}

export default Trainings
