import { Course } from 'model/Course'
import { routes } from 'model/ctrl/routes'
import { TrainerDTO } from 'model/ctrl/trainers/GetTrainersRes.schema'
import { Institute } from 'model/Institute'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { USERROLES } from '../../model/UserRole'
import { AppRoutes } from '../../routes'
import { ExportTableData } from './Export.state'

export interface CourseAggregate extends Course {
  institute: Institute
  trainer: TrainerDTO
}

function model2ViewModel(model: CourseAggregate[]): ExportTableData[] {
  return model.map(course => ({
    id: (course as any)._id as string,
    selected: false,
    isSelectionEnabled: true,
    date: new Date(course.date),
    institute: course.institute.name,
    identifier: course.identifier || '',
    location: course.location,
    traineeCount: course.trainees.length,
  }))
}

export const connectExportPageService = (store: AppStore) => {
  const { onLoginPageEnter, http } = tools(store)

  onLoginPageEnter(AppRoutes.Export, {
    roles: [
      USERROLES.CorPatchAdmin,
      USERROLES.InstituteAdmin,
      USERROLES.Trainer,
    ],
  }).subscribe(() => {
    http<CourseAggregate[]>({ route: { path: routes.courses } }).subscribe(
      courses => {
        store.next(
          state => {
            state.pages.export.courses = model2ViewModel(courses)
          },
          { type: 'Page/Courses/Service/Update' }
        )
      },

      _error => {
        console.error(_error)
        store.next(
          state => {
            state.pages.courses.data = []
            state.pages.courses.meta.notFound = true
            state.pages.courses.meta.loading = false
          },
          { type: 'Page/Courses/Service/Error' }
        )
      }
    )
  })
}
