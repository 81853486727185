import { Message } from '@restate/core'
import { PostMeChangePasswordReq } from 'model/ctrl/me/PostMeChangePasswordReq.schema'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { config } from 'config/config'
import { routes } from 'model/ctrl/routes'
import { HttpError } from 'services/utils/http'
import { ChangePasswordErrorMessage } from 'model/ctrl/me/PostMeChangePasswordRes.schema'

import messages from './UserChangePassword.messages'
import { AppRoutes } from '../../routes'

export interface ChangePasswordMessage extends Message {
  type: 'Page/ChangePassword/Send'
  payload: { oldPassword: string; newPassword: string }
}

export const connectChangePasswordPageService = (store: AppStore) => {
  const { onAction, http, snackBar, intl, goTo } = tools(store)
  const wrongPasswordSnackbar = intl().formatMessage(
    messages.wrongPasswordSnackbar
  )
  const notChangedPasswordSnackbar = intl().formatMessage(
    messages.notChangedPasswordSnackbar
  )
  const successSnackbar = intl().formatMessage(messages.successSnackbar)

  const { api } = config()

  onAction({ type: 'Page/ChangePassword/Send' }).subscribe(pack => {
    const {
      oldPassword,
      newPassword,
    } = (pack.message as ChangePasswordMessage).payload

    const data: PostMeChangePasswordReq = { oldPassword, newPassword }

    const onError = (err: HttpError<ChangePasswordErrorMessage>) => {
      if (err.errorMessage === 'Wrong password') {
        snackBar.error(wrongPasswordSnackbar)
      } else {
        snackBar.error(notChangedPasswordSnackbar + err.errorMessage)
      }
    }

    const onSuccess = () => {
      snackBar.success(successSnackbar)
      goTo(AppRoutes.Home)
    }

    http({
      method: 'POST',
      url: api + routes.me.changePassword,
      data,
    }).subscribe(onSuccess, onError)
  })
}
