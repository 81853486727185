import { Gender } from 'model/Gender'

export interface MeState {
  firstName: string
  familyName: string
  gender: Gender
  meta: {
    ready: boolean
    loading: boolean
  }
}

export const defaultMeState: MeState = {
  firstName: '',
  familyName: '',
  gender: 'other',
  meta: {
    ready: false,
    loading: false,
  },
}
