import { animationFrameScheduler } from 'rxjs'
import { filter, observeOn } from 'rxjs/operators'
import { AppMessages } from 'state/appMessages'
import { AppStore } from 'state/store'

export type ActionType = Pick<AppMessages, 'type'>

export const onAction = (store: AppStore) => (actionType: ActionType) =>
  store.state$.pipe(
    observeOn(animationFrameScheduler),
    filter(update => update.message.type === actionType.type)
  )
