import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'cp.pages.sharingObject.pageTitle',
    defaultMessage: 'Sharing Objects',
  },
  pageNotFoundTitle: {
    id: 'cp.pages.sharingObject.pageNotFoundTitle',
    defaultMessage: 'Not found',
  },
  pageNotFoundMessage: {
    id: 'cp.pages.sharingObject.pageNotFoundDescription',
    defaultMessage: 'The sharing objects you are looking for do not exists.',
  },
  title: {
    id: 'cp.pages.sharingObject.form.title',
    defaultMessage: 'Sharing object',
  },
  name: {
    id: 'cp.pages.sharingObject.form.name',
    defaultMessage: 'Name',
  },
  invitationCode: {
    id: 'cp.pages.sharingObject.form.invitationCode',
    defaultMessage: 'Invitation Code',
  },
  invitationLink: {
    id: 'cp.pages.sharingObject.form.invitationLink',
    defaultMessage: 'Invitation Link',
  },
  webHook: {
    id: 'cp.pages.sharingObject.form.webHook',
    defaultMessage: 'WebHook',
  },
  pullUrl: {
    id: 'cp.pages.sharingObject.form.pullUrl',
    defaultMessage: 'Pull URL',
  },
  pullApiSecret: {
    id: 'cp.pages.sharingObject.form.pullApiSecret',
    defaultMessage: 'Secret',
  },
  apiAccess: {
    id: 'cp.pages.sharingObject.form.apiAccess',
    defaultMessage: 'API Access',
  },
  enabled: {
    id: 'cp.pages.sharingObject.form.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'cp.pages.sharingObject.form.disabled',
    defaultMessage: 'Disabled',
  },
  saveButton: {
    id: 'cp.pages.sharingObject.form.saveButton',
    defaultMessage: 'Save',
  },
  deleteButton: {
    id: 'cp.pages.sharingObject.form.deleteButton',
    defaultMessage: 'Delete',
  },
  confirmDeleteDialogTitle: {
    id: 'cp.pages.sharingObject.form.confirmDeleteDialogTitle',
    defaultMessage: 'Remove Sharing Object',
  },
  confirmDeleteDialogYes: {
    id: 'cp.pages.sharingObject.form.confirmDeleteDialogYes',
    defaultMessage: 'Delete',
  },
  confirmDeleteDialogNo: {
    id: 'cp.pages.sharingObject.form.confirmDeleteDialogNo',
    defaultMessage: 'Cancel',
  },
  confirmDeleteDialogMessage1: {
    id: 'cp.pages.sharingObject.form.confirmDeleteDialogMessage1',
    defaultMessage: 'Are you sure you want to delete the sharing object?',
  },
  confirmDeleteDialogMessage2: {
    id: 'cp.pages.sharingObject.form.confirmDeleteDialogMessage2',
    defaultMessage:
      'This action cannot be undone, but no data will be lost. The §sharing object will be visible as deactivated.',
  },
  snackBarSaved: {
    id: 'cp.pages.sharingObject.snackBarSaved',
    defaultMessage: 'Saved',
  },
  snackBarSaveError: {
    id: 'cp.pages.sharingObject.snackBarSaveError',
    defaultMessage: 'Could not save',
  },
  snackBarDeleted: {
    id: 'cp.pages.sharingObject.snackBarDeleted',
    defaultMessage: 'Deleted',
  },
  snackBarDeleteError: {
    id: 'cp.pages.sharingObject.snackBarDeleteError',
    defaultMessage: 'Could not delete',
  },
})
