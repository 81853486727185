import { AppPageLayout } from 'components/layouts/AppPageLayout'
import React from 'react'
import { useAppState } from 'state/store'

function renderShop() {
  ;(window as any)?.xProductBrowser?.(
    'categoriesPerRow=3',
    'views=grid(20,3) list(60) table(60)',
    'categoryView=grid',
    'searchView=list',
    'id=my-store-20335351'
  )
}

const ToggleShop: React.FC = props => {
  const pathname = useAppState(state => state.location.pathname)
  const showShop = pathname === '/shop'
  const styles = {
    display: showShop ? 'initial' : 'none',
  }
  return (
    <div>
      <div style={styles}>{props.children}</div>
    </div>
  )
}

const ShopPageLayout = () => {
  const hash = useAppState(state => state.location.hash)
  const showBackButton = hash.length > 0
  return (
    <ToggleShop>
      <AppPageLayout title='Shop' showBackButton={showBackButton}>
        <div id='my-store-20335351' style={{ minWidth: '90vw' }}></div>
      </AppPageLayout>
    </ToggleShop>
  )
}

export class Shop extends React.Component<{}> {
  componentDidMount() {
    setTimeout(renderShop, 500)
  }

  render() {
    return (
      <>
        <ShopPageLayout />
      </>
    )
  }
}
