import { RxStore } from '@restate/core'
import { combineLatest, animationFrameScheduler } from 'rxjs'
import { tools } from 'services/utils/tools'
import { State } from 'state/state'
import { AppMessages } from 'state/appMessages'
import { SessionServiceMessageType } from 'services/session/session.service'
import RouteParser from 'route-parser'
import { AppRoutes } from 'routes'
import { observeOn } from 'rxjs/operators'

const PUBLIC_PAGES = [
  AppRoutes.AcceptInvitation,
  AppRoutes.InternalServerError,
  AppRoutes.Login,
  AppRoutes.LoginPasswordForgot,
  AppRoutes.LoginPasswordForgotSetPassword,
  AppRoutes.Logout,
  AppRoutes.LoginTermsOfUse,
  AppRoutes.Register,
  AppRoutes.EmailValidation,
  AppRoutes.ValidateLogin,
  AppRoutes.ValidateLoginPublic,
]

function isPublicPage(location: string) {
  return PUBLIC_PAGES.some(page => {
    const match = new RouteParser(page).match(location)
    return match !== false
  })
}

export const connectRouteGuard = (store: RxStore<State, AppMessages>) => {
  const { goTo, onChange, onAction } = tools(store)

  // Token changes
  const token$ = onChange(s => s.session.token)

  // Location changes
  const location$ = onChange(s => s.location.pathname)

  // Session restored
  const session$ = onAction({ type: SessionServiceMessageType.Done })

  // We watch for token changes
  combineLatest(token$, location$, session$)
    .pipe(observeOn(animationFrameScheduler))
    .subscribe(props => {
      const [token, location] = props

      if (token !== null && location === '/login') {
        goTo('/')
      }

      // Do nothing on public pages
      if (isPublicPage(location)) {
        return
      }

      if (token === null) {
        goTo('/login?redirect=' + location)
      }
    })
}
