import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'

export const background = {
  backgroundColor: Colors.neutrals[9],
}

export const center: any = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
}

export const CARD_DEFAULTS = {
  minWidth: 700,
  maxWidth: 700,
  minHeight: 350,
  width: 700,
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    minHeight: 'auto',
    width: '100%',
    height: '100%',
    borderRadius: 0,
  },
}
