import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import {
  ImageCard,
  ImageCardContent,
  ImageCardActions,
} from '../../components/ImageCard/ImageCard'
import { AppRoutes } from '../../routes'
import {
  LoginService,
  LS_VERIFICATION_KEY,
} from '../../services/login/login.service'
import { useAppState, useDispatchHook } from '../../state/store'
import { Images } from '../../theme/Images'

import messages from './ValidateLogin.messages'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  },
})

function ValidateLogin() {
  const classes = useStyles()
  const intl = useIntl()

  const validationTitle = intl.formatMessage(messages.validationTitle)
  const validationContent = intl.formatMessage(messages.validationContent)
  const validationButton = intl.formatMessage(messages.validationButton)
  const successTitle = intl.formatMessage(messages.successTitle)
  const successContent = intl.formatMessage(messages.successContent)
  const errorTitle = intl.formatMessage(messages.errorTitle)
  const errorContent = intl.formatMessage(messages.errorContent)

  const [verificationToken] = useState(() =>
    localStorage.getItem(LS_VERIFICATION_KEY)
  )

  const { token } = useParams<{ token: string }>()
  const dispatch = useDispatchHook()
  const history = useHistory()

  const isNetworkInitialized = useAppState(s => s.network.online)
  const isLoggedInAllready = useAppState(s => s.session.token)

  useEffect(() => {
    if (isNetworkInitialized && token && verificationToken) {
      dispatch({
        type: LoginService.LoginValidate,
        payload: { validationToken: token, verificationToken },
      })
    }
  }, [token, isNetworkInitialized, dispatch, verificationToken])

  if (Boolean(isLoggedInAllready)) {
    history.push(AppRoutes.Home)
  }

  if (token && verificationToken) {
    return (
      <div className={classes.root}>
        <Card>
          <CardContent>
            <Typography variant='h5'>{successTitle}</Typography>

            <Typography>{successContent}</Typography>

            <div className={classes.root}>
              <CircularProgress />
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }

  if (token && !verificationToken) {
    return (
      <div className={classes.root}>
        <ImageCard imgUrl={Images.bugFix}>
          <ImageCardContent>
            <Typography variant='h5'>{errorTitle}</Typography>

            <Typography>{errorContent}</Typography>
          </ImageCardContent>

          <ImageCardActions>
            <Button onClick={() => history.push(AppRoutes.Login)}>
              {validationButton}
            </Button>
          </ImageCardActions>
        </ImageCard>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <ImageCard imgUrl={Images.mail}>
        <ImageCardContent>
          <Typography variant='h5'>{validationTitle}</Typography>

          <Typography>{validationContent}</Typography>
        </ImageCardContent>

        <ImageCardActions>
          <Button onClick={() => history.push(AppRoutes.Login)}>
            {validationButton}
          </Button>
        </ImageCardActions>
      </ImageCard>
    </div>
  )
}

export default ValidateLogin
