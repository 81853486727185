import { defineMessages } from 'react-intl'

export default defineMessages({
  institutePageSnackbarSuccess: {
    id: 'cp.components.general.institutePageSnackbarSuccess',
    defaultMessage: 'Saved',
  },
  institutePageSnackbarError: {
    id: 'cp.components.general.institutePageSnackbarError',
    defaultMessage: 'Error saving institute',
  },
  tableRowName: {
    id: 'cp.components.general.tableRowName',
    defaultMessage: 'Name',
  },

  // Util snackbars to use everywhere, default success and failure handlers
  emailAllreadySentSnackbar: {
    id: 'cp.components.general.snackbar.emailAllreadySentSnackbar',
    defaultMessage:
      'The E-Mail has been sent within the last 10 minutes. Please wait before you can send another one.',
  },
})
