import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: {
    id: 'cp.pages.course.title',
    defaultMessage: 'Course',
  },
  rowFlow: {
    id: 'cp.pages.course.rowFlow',
    defaultMessage: 'Flow',
  },
  rowDepth: {
    id: 'cp.pages.course.rowDepth',
    defaultMessage: 'Depth',
  },
  rowRecoil: {
    id: 'cp.pages.course.rowRecoil',
    defaultMessage: 'Recoil',
  },
  rowFrequency: {
    id: 'cp.pages.course.rowFrequency',
    defaultMessage: 'Frequency',
  },
  cellDate: {
    id: 'cp.pages.course.cellDate',
    defaultMessage: 'Date',
  },
  cellDuration: {
    id: 'cp.pages.course.cellDuration',
    defaultMessage: 'Duration',
  },
  cellFeedback: {
    id: 'cp.pages.course.cellFeedback',
    defaultMessage: 'Feedback',
  },
  cellName: {
    id: 'cp.pages.course.cellName',
    defaultMessage: 'Name',
  },
  trainees: {
    id: 'cp.pages.course.trainees',
    defaultMessage: 'Trainees',
  },
})
