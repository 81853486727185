import RouteParser from 'route-parser'
import { animationFrameScheduler } from 'rxjs'
import { map } from 'rxjs/internal/operators/map'
import {
  distinctUntilChanged,
  filter,
  observeOn,
  pairwise,
} from 'rxjs/operators'
import { AppStore } from 'state/store'

export const onPageLeave = (store: AppStore) => (route: string) => {
  const parser = new RouteParser(route)

  const ret = store.state$.pipe(
    observeOn(animationFrameScheduler),
    map(update => update.state.location.pathname),
    distinctUntilChanged(),
    pairwise(),
    map(([oldPage, currPage]) => ({
      oldPageIsPage: parser.match(oldPage) !== false,
      currPageIsPage: parser.match(currPage) !== false,
    })),
    filter(
      ({ oldPageIsPage, currPageIsPage }) =>
        oldPageIsPage === true && currPageIsPage === false
    )
  )

  return ret
}
