import { Message } from '@restate/core'
import { routes } from 'model/ctrl/routes'
import { AppRoutes } from 'routes'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { CourseAggregate } from './Course.state'

export interface PagesServiceCourseUpdateMessage extends Message {
  type: 'Pages/Service/Course/Update' | 'Pages/Service/Course/Error'
}

export const connectCoursePageService = (store: AppStore) => {
  const { http, onPageEnter, onLogin } = tools(store)

  combineLatest(onLogin(), onPageEnter(AppRoutes.Course))
    .pipe(map(([_login, pageEnter]) => pageEnter))
    .subscribe(params => {
      const { id } = params

      http<CourseAggregate>({
        route: {
          path: routes.course,
          id,
        },
      }).subscribe(
        course =>
          store.next(
            state => {
              state.pages.course.course = course
              state.pages.course.meta.loading = false
              state.pages.course.meta.notFound = false
            },
            { type: 'Pages/Service/Course/Update' }
          ),
        _error => {
          console.error('COURSES:', _error)
          store.next(
            state => {
              state.pages.course.course = null
              state.pages.course.meta.notFound = true
              state.pages.course.meta.loading = false
            },
            { type: 'Pages/Service/Course/Error' }
          )
        }
      )
    })
}
