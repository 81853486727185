import { Button, Fab, IconButton, TableCell, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import { makeStyles } from '@material-ui/styles'
import {
  GenericTable,
  GenericTableCellHeader,
  GenericTableData,
} from 'components/GenericTable/GenericTable'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { InstituteServiceMessageType } from 'services/institutes/institutes.service'
import { useDispatchHook, useNextAppState } from 'state/store'
import { Colors } from 'theme/colors'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { useInstitutesState } from './Institutes.restate'
import { InstituteEntry } from './Institutes.state'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { AppRoutes } from 'routes'

import messages from './Institutes.messages'
import tableMessages from './InstitutesTable.messages'

const useClasses = makeStyles({
  institutes: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 100,
  },

  instituteCard: {
    width: '30vw',
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  instituteEntryActions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 10,
    right: 10,
  },
  subheader: {
    color: Colors.neutrals[3],
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
})

const CreateInstituteActions: React.FC<{}> = () => {
  const intl = useIntl()
  const addInstituteButton = intl.formatMessage(messages.addInstituteButton)

  const dispatch = useDispatchHook()
  const onClick = () => dispatch({ type: InstituteServiceMessageType.Create })
  return (
    <Button variant='contained' color='secondary' onClick={onClick}>
      {addInstituteButton}
    </Button>
  )
}

const AddButton = () => {
  const classes = useClasses()
  const dispatch = useDispatchHook()
  const onClick = () => dispatch({ type: InstituteServiceMessageType.Create })

  return (
    <Fab
      color='secondary'
      aria-label='Add institute'
      className={classes.fab}
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  )
}

const cellHeaders = (
  Name: string,
  Street: string,
  City: string,
  ZIP: string,
  Country: string
): GenericTableCellHeader[] => {
  const cellHeaders: GenericTableCellHeader[] = [
    { id: 'institute', numeric: false, disablePadding: false, label: Name },
    { id: 'street', numeric: false, disablePadding: false, label: Street },
    { id: 'city', numeric: false, disablePadding: false, label: City },
    { id: 'zip', numeric: false, disablePadding: false, label: ZIP },
    { id: 'country', numeric: false, disablePadding: false, label: Country },
    { id: 'actions', numeric: true, disablePadding: false, label: '' },
  ]
  return cellHeaders
}

type InstitutesTableData = InstituteEntry & GenericTableData

export const Institutes: React.FC<{}> = () => {
  const intl = useIntl()
  const pageNotFoundTitle = intl.formatMessage(messages.pageNotFoundTitle)
  const pageNotFoundMessage = intl.formatMessage(messages.pageNotFoundMessage)
  const pageTitle = intl.formatMessage(messages.pageTitle)
  const tableRowName = intl.formatMessage(tableMessages.tableRowName)
  const tableRowStreet = intl.formatMessage(tableMessages.tableRowStreet)
  const tableRowCity = intl.formatMessage(tableMessages.tableRowCity)
  const tableRowZIP = intl.formatMessage(tableMessages.tableRowZIP)
  const tableRowCountry = intl.formatMessage(tableMessages.tableRowCountry)
  const deleteWarning = intl.formatMessage(tableMessages.deleteWarning)
  const deleteLabel = intl.formatMessage(messages.deleteLabel)

  const goTo = useGoToHook()
  const next = useNextAppState(s => s.pages.institutes)
  const dispatch = useDispatchHook()

  const { entries, meta } = useInstitutesState()
  const rows: InstitutesTableData[] = entries
    .map(entry => ({ 
      id: entry._id,
      selected: false,       
      isSelectionEnabled: true,
      ...entry, 
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const empty = entries.length === 0
  const { loading } = meta

  const search = (searchTerm: string) => {
    const searchReg = RegExp(searchTerm, 'i')
    return (entry: InstitutesTableData) =>
      searchReg.test(entry.name) ||
      searchReg.test(entry.city) ||
      searchReg.test(entry.country) ||
      searchReg.test(entry.zip) ||
      searchReg.test(entry.street)
  }

  if (loading) {
    return <AppPageLayout title={pageTitle} loading={true} />
  } else if (empty) {
    return (
      <PageNotFound
        title={pageNotFoundTitle}
        message={pageNotFoundMessage}
        img={Images.teacher}
        action={() => <CreateInstituteActions />}
      />
    )
  }

  const goToInstitutePage = (id: string) => goTo(AppRoutes.Institute, { id })
  const deleteInstitute = (ids: string[]) => {
    const id = ids[0]
    next(s => {
      s.entries = s.entries.filter(institute => institute._id !== id)
    })

    dispatch({ type: InstituteServiceMessageType.Delete, payload: id })
  }

  return (
    <>
      <AppPageLayout title={pageTitle}>
        <GenericTable
          rows={rows}
          headers={cellHeaders(
            tableRowName,
            tableRowStreet,
            tableRowCity,
            tableRowZIP,
            tableRowCountry
          )}
          search={search as any}
          selectedItemsMessage={({ count }) => (
            <FormattedMessage
              id='institutesTable'
              defaultMessage={deleteWarning}
              values={{ count }}
            />
          )}
          rowRender={({ row }) => (
            <>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.street}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.zip}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => goToInstitutePage(row.id)}>
                  <RightIcon />
                </IconButton>
              </TableCell>
            </>
          )}
          singleSelect={true}
          tools={({ selected, setSelected }) => (
            <Tooltip title={deleteLabel}>
              <IconButton
                aria-label={deleteLabel}
                onClick={() => {
                  deleteInstitute(selected)
                  setSelected([])
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        />
        <AddButton />
      </AppPageLayout>
    </>
  )
}

export default Institutes
