import { Button, IconButton, TableCell, Tooltip } from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import {
  GenericTable,
  GenericTableCellHeader,
} from 'components/GenericTable/GenericTable'
import { InstituteUserDto } from 'model/ctrl/institute/GetInstituteRes.schema'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState, useDispatchHook, useNextAppState } from 'state/store'
import { theme } from 'theme/theme'
import { useStyles } from './useStyles'
import DeleteIcon from '@material-ui/icons/Delete'

import messages from './InstituteTrainerTable.messages'
import { SelectedUserIds } from 'pages/Institute/SelectedUserIds'
import { ConfirmDeletionDialog } from 'components/dialogs/ConfirmDeletionDialog'

const cellHeaders = (
  Street: string,
  Name: string
): GenericTableCellHeader[] => {
  return [
    { id: 'firstName', numeric: false, disablePadding: false, label: Name },
    { id: 'email', numeric: false, disablePadding: false, label: Street },
    { id: 'actions', numeric: true, disablePadding: false, label: '' },
  ]
}

const search = (searchTerm: string) => {
  const searchReg = RegExp(searchTerm, 'i')
  return (entry: InstituteUserDto) =>
    searchReg.test(entry.firstName) ||
    searchReg.test(entry.familyName) ||
    searchReg.test(entry.email)
}

export const InstituteTrainerTable: React.FC = () => {
  const intl = useIntl()
  const trainerTableTitle = intl.formatMessage(messages.trainerTableTitle)
  const trainerTableSubtitle = intl.formatMessage(messages.trainerTableSubtitle)
  const trainerTableButton = intl.formatMessage(messages.trainerTableButton)
  const tableRowsStreet = intl.formatMessage(messages.tableRowsStreet)
  const name = intl.formatMessage(messages.trainerTableName)
  const trainerEmptyMessage = intl.formatMessage(messages.trainerEmptyMessage)
  const selected = intl.formatMessage(messages.selected)
  const deleteLabel = intl.formatMessage(messages.deleteLabel)
  // const deleteTrainerMessage = intl.formatMessage(messages.deleteTrainerMessage)

  const classes = useStyles()
  const goTo = useGoToHook()
  const dispatch = useDispatchHook()
  const instituteId = useAppState(state => state.pages.institute.id)
  const [selectedIdsToDelete, setSelectedIdsToDelete] = useState<
    SelectedUserIds
  >(null)
  const goToTrainerInvitePage = () =>
    goTo(AppRoutes.TrainerInvite, { id: instituteId })
  const next = useNextAppState(s => s.pages.institute)

  const navigateToUserDetailsPage = (id: string) =>
    goTo(AppRoutes.UserDetails, { id })

  const trainers = useAppState(state => state.pages.institute.trainers).map(
    trainer => ({
      id: trainer._id,
      selected: false,
      isSelectionEnabled: true,
      ...trainer,
    })
  )

  const getTrainerRoles = (id: string) => {
    return trainers.find(t => t._id === id)?.roles ?? []
  }

  const deleteTrainers = (ids: string[]) => {
    ids.forEach(id => {
      next(s => {
        s.admins = s.admins.filter(admin => admin._id !== id)
        s.trainers = s.trainers.filter(trainer => trainer._id !== id)
      })
    })

    dispatch({ type: 'Pages/Trainers/Delete', payload: ids })
  }
  return (
    <>
      <div style={{ width: '100%', marginTop: theme.spacing(2) }}>
        <GenericTable
          title={trainerTableTitle}
          subTitle={trainerTableSubtitle}
          rows={trainers}
          headers={cellHeaders(tableRowsStreet, name)}
          singleSelect={true}
          search={search as any}
          emptyMessage={trainerEmptyMessage}
          selectedItemsMessage={({ count }) => (
            <FormattedMessage
              id='institutesTable'
              defaultMessage={`{count, number} {count, plural, one { trainer } other { trainers }} ${selected}`}
              // defaultMessage={deleteTrainerMessage}
              values={{ count }}
            />
          )}
          rowRender={({ row }) => (
            <>
              <TableCell>
                {row.firstName} {row.familyName}
              </TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => navigateToUserDetailsPage(row.id)}>
                  <RightIcon />
                </IconButton>
              </TableCell>
            </>
          )}
          tools={({ selected, setSelected }) => (
            <Tooltip title={deleteLabel}>
              <IconButton
                aria-label={deleteLabel}
                onClick={() => {
                  setSelectedIdsToDelete({
                    selectedIds: selected,
                    clearSelection: () => setSelected([]),
                  })
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        />

        <ConfirmDeletionDialog
          open={
            !!selectedIdsToDelete &&
            selectedIdsToDelete?.selectedIds.length === 1
          }
          userRoles={getTrainerRoles(selectedIdsToDelete?.selectedIds[0] ?? '')}
          onPositiveButtonClick={() => {
            deleteTrainers(selectedIdsToDelete?.selectedIds ?? [])
            selectedIdsToDelete?.clearSelection()
            setSelectedIdsToDelete(null)
          }}
          onNegativeButtonClick={() => {
            selectedIdsToDelete?.clearSelection()
            setSelectedIdsToDelete(null)
          }}
        />

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            className={classes.button}
            onClick={goToTrainerInvitePage}
          >
            {trainerTableButton}
          </Button>
        </div>
      </div>
    </>
  )
}
