import { createStateHook, createNextHook } from '@restate/core'
import { AppStoreProvider } from 'state/store'

export const useAdminInviteState = createStateHook(
  AppStoreProvider,
  state => state.pages.adminInvite
)
export const useNextAdminInviteState = createNextHook(
  AppStoreProvider,
  state => state.pages.adminInvite
)
