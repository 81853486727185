import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'

//
// Initializes the userChangeEmail page with the current user email
//
export const connectUserChangePassword = (store: AppStore) => {
  const { onChange } = tools(store)

  onChange(state => state.session.user.email).subscribe(() => {
    store.next(state => {
      state.pages.userChangeEmail.nextEmail = state.session.user.email
    })
  })
}
