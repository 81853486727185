import { defineMessages } from 'react-intl'

export default defineMessages({
  passwordRequired: {
    id: 'cp.components.changePassword.requiredError',
    defaultMessage: 'The current password is required',
  },
  passwordEqual: {
    id: 'cp.components.changePassword.equalError',
    defaultMessage: 'The new password is equal to the old one',
  },
  newPasswordRequired: {
    id: 'cp.components.changePassword.newRequiredError',
    defaultMessage: 'A new password is required',
  },
  passwordDontMatch: {
    id: 'cp.components.changePassword.matchError',
    defaultMessage: "New passwords don't match",
  },
  currentPassword: {
    id: 'cp.components.changePassword.currentPassword',
    defaultMessage: 'Current password',
  },
  newPassword: {
    id: 'cp.components.changePassword.newPassword',
    defaultMessage: 'New Password',
  },
  newPasswordRepeat: {
    id: 'cp.components.changePassword.newPasswordRepeat',
    defaultMessage: 'New Password (repeat)',
  },
  changePasswordButton: {
    id: 'cp.components.changePassword.changePasswordButton',
    defaultMessage: 'Change Password',
  },
  setPasswordButton: {
    id: 'cp.components.changePassword.setPasswordButton',
    defaultMessage: 'Set Password',
  },
  newToShortError: {
    id: 'cp.components.changePassword.newToShortError',
    defaultMessage: 'The provided passwort is to short.',
  },
})
