import { Input, InputAdornment } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import React, { useState } from 'react'
import { Colors } from 'theme/colors'
import { theme } from 'theme/theme'
import { useRTL } from 'utils/useRTL'

interface EnhancedTableToolbarProps {
  clearSearchTerm: () => void
  numSelected: number
  searchTerm: string
  selected: string[]
  setSelected: (ids: string[]) => any
  selectedItemsMessage: React.FC<{ count: number }>
  setSearchTerm: (nextSearchTerm: string) => void
  subTitle: string
  title: string
  searchPlaceholder: string
  tools?: React.FC<{ selected: string[]; setSelected: (ids: string[]) => any }>
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 76,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    highlight: {
      color: Colors.black,
      backgroundColor: theme.palette.secondary.main,
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {},
    selectedMessage: {},
  })
)

const DefaultTools: React.FC = () => {
  return <></>
}

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = props => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    searchTerm,
    setSearchTerm,
    clearSearchTerm,
    setSelected,
    selectedItemsMessage,
    selected,
    tools = DefaultTools,
    title = '',
    subTitle = '',
    searchPlaceholder = '',
  } = props

  const [originalSearchString, setOriginalSearchString] = useState('')

  const SelectedItemsMessage = selectedItemsMessage
  const Tools = tools
  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const isRTL = useRTL()

  return (
    <Toolbar
      className={[classes.root, numSelected > 0 ? classes.highlight : ''].join(
        ' '
      )}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color='inherit' variant='subtitle1'>
            <SelectedItemsMessage count={numSelected} />
          </Typography>
        ) : (
          <div style={{ marginTop: theme.spacing(2) }}>
            <Typography variant='h5'>{title}</Typography>
            <Typography variant='subtitle1'>{subTitle}</Typography>
          </div>
        )}
      </div>

      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tools selected={selected} setSelected={setSelected} />
        ) : (
          <>
            <Input
              style={{ minWidth: 200 }}
              value={originalSearchString}
              placeholder={searchPlaceholder}
              onChange={event => {
                let escapedString = escapeRegExp(event.target.value)
                setSearchTerm(escapedString)
                setOriginalSearchString(event.target.value)
              }}
              endAdornment={
                !isRTL && (
                  <InputAdornment position='end'>
                    {searchTerm === '' ? (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={clearSearchTerm}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }
              startAdornment={
                isRTL && (
                  <InputAdornment position='end'>
                    {searchTerm === '' ? (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={clearSearchTerm}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }
            />
          </>
        )}
      </div>
    </Toolbar>
  )
}
