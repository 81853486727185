import { ActionFactoryProps, createActionsHook } from '@restate/core'
import {
  AppStoreProvider,
  useAppState,
  useNextAppState,
} from '../../state/store'
import { InstitutesState } from './Institutes.state'
import { AppMessages } from 'state/appMessages'

export const useInstitutesState = () =>
  useAppState(state => state.pages.institutes)
export const useNextInstituteState = () =>
  useNextAppState(state => state.pages.institutes)

const InstitutesActions = (
  _props: ActionFactoryProps<InstitutesState, AppMessages>
) => {
  // const { dispatch } = props

  return {
    newInstitute() {
      // dispatch()
    },
  }
}

export const useInstitutesAction = createActionsHook(
  AppStoreProvider,
  state => state.pages.institutes,
  InstitutesActions
)

// export const InstitutePageService = (store:AppStore) {
