import { createStateHook, createNextHook } from '@restate/core'
import { AppStoreProvider } from 'state/store'

export const useTrainerInviteState = createStateHook(
  AppStoreProvider,
  state => state.pages.trainerInvite
)
export const useNextTrainerInviteState = createNextHook(
  AppStoreProvider,
  state => state.pages.trainerInvite
)
