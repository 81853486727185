import {
  Button,
  Card,
  CardContent,
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { FeedbackMode } from 'model/FeedbackMode'
import { SessionType } from 'model/SessionType'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatchHook, useNextAppState } from 'state/store'
import { range } from 'utils/range'
import messages from './Settings.messages'
import { useSettingsState } from './Settings.restate'
import { ResultType } from 'model/ResultType'
import {
  useNextInstituteResultConfigState,
  useInstituteResultConfigState,
} from 'pages/Institute/Institute.restate'
import { ResultConfig } from 'model/CptConfig'

const SettingsLoading = () => {
  const intl = useIntl()
  const settingsTitle = intl.formatMessage(messages.SettingsPageTitle)

  return <AppPageLayout title={settingsTitle} loading={true}></AppPageLayout>
}

export const Settings: React.FC<{}> = () => {
  const intl = useIntl()
  const settingsTitle = intl.formatMessage(messages.SettingsPageTitle)
  const Property = intl.formatMessage(messages.TableRowProperty)
  const Value = intl.formatMessage(messages.TableRowValue)
  const Locked = intl.formatMessage(messages.TableRowLocked)
  const Trainees = intl.formatMessage(messages.TableRowTrainees)
  const Duration = intl.formatMessage(messages.TableRowDuration)
  const Feedback = intl.formatMessage(messages.TableRowFeedback)
  const Type = intl.formatMessage(messages.TableRowType)
  const Compression = intl.formatMessage(messages.TableRowCompression)
  const Frequency = intl.formatMessage(messages.TableRowFrequency)
  const Result = intl.formatMessage(messages.TableRowResult)
  const ERC = intl.formatMessage(messages.TableRowERC)
  const SaveButton = intl.formatMessage(messages.TableRowSaveButton)
  const FeedbackModeMenuItemNone = intl.formatMessage(
    messages.FeedbackModeMenuItemNone
  )
  const FeedbackModeMenuItemExpert = intl.formatMessage(
    messages.FeedbackModeMenuItemExpert
  )
  const FeedbackModeMenuItemSimple = intl.formatMessage(
    messages.FeedbackModeMenuItemSimple
  )
  const ResultTypeMenuItemNone = intl.formatMessage(
    messages.ResultTypeMenuItemNone
  )
  const ResultTypeMenuItemSimple = intl.formatMessage(
    messages.ResultTypeMenuItemSimple
  )
  const ResultTypeMenuItemStandard = intl.formatMessage(
    messages.ResultTypeMenuItemStandard
  )
  const ResultTypeMenuItemExpert = intl.formatMessage(
    messages.ResultTypeMenuItemExpert
  )
  const MenuItemThresholdGood = intl.formatMessage(
    messages.MenuItemThresholdGood
  )
  const MenuItemThresholdGreat = intl.formatMessage(
    messages.MenuItemThresholdGreat
  )
  const TableRowResultConfigSaveButton = intl.formatMessage(
    messages.TableRowResultConfigSaveButton
  )

  const appConfig = useSettingsState(s => s.appConfig)
  const instituteResultConfig = useInstituteResultConfigState(s => s)
  const next = useNextAppState(s => s.pages.settings.appConfig!.sessionConfig)
  const nextInstituteResultConfig = useNextInstituteResultConfigState(s => s)
  const dispatch = useDispatchHook()
  const [touched, setTouched] = useState(false)

  if (!appConfig) return <SettingsLoading />

  const sessionConfig = appConfig.sessionConfig

  const setNumberOfTrainees = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.numberOfTrainees.value = Number.parseInt(e.target.value as string)
    })

  const setSessionDuration = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.sessionDuration.value = Number.parseInt(e.target.value as string)
    })

  const setFeedbackMode = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.feedbackMode.value = (e.target.value as unknown) as FeedbackMode
    })

  const setSessionType = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.sessionType.value = e.target.value as SessionType
    })

  const setResultType = (e: React.ChangeEvent<{ value: unknown }>) =>
    next(s => {
      s.resultType.value = e.target.value as ResultType
    })

  const setExecRoutine = () =>
    next(s => {
      s.showErcRoutine.value = !s.showErcRoutine.value
    })

  const setThresholdGood = (e: React.ChangeEvent<{ value: string }>) =>
    nextInstituteResultConfig((s: ResultConfig) => {
      s.simple.thresholdGood = Number.parseInt(e.target.value) as number
    })

  const setThresholdGreat = (e: React.ChangeEvent<{ value: string }>) =>
    nextInstituteResultConfig((s: ResultConfig) => {
      s.simple.thresholdGreat = Number.parseInt(e.target.value) as number
    })

  const save = () => dispatch({ type: 'Page/Settings/Save' })
  const saveResultConfig = () => {
    dispatch({ type: 'Pages/Institute/InstituteAdmin/ResultConfig/Save' })
    setTouched(false)
  }

  return (
    <AppPageLayout title={settingsTitle}>
      <Card id='settings-card'>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{Property}</TableCell>
                <TableCell>{Value}</TableCell>
                <TableCell>{Locked}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{Trainees}</TableCell>
                <TableCell>
                  <Select
                    value={sessionConfig.numberOfTrainees.value}
                    onChange={setNumberOfTrainees}
                  >
                    {range(20).map(i => (
                      <MenuItem
                        key={i + 1}
                        value={i + 1}
                        style={{ padding: 20 }}
                      >
                        {i + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.numberOfTrainees.locked}
                    onChange={() =>
                      next(s => {
                        s.numberOfTrainees.locked = !s.numberOfTrainees.locked
                      })
                    }
                  ></Checkbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{Duration}</TableCell>
                <TableCell>
                  <Select
                    value={sessionConfig.sessionDuration.value}
                    onChange={setSessionDuration}
                  >
                    {range(20)
                      .map(i => (i + 1) * 30)
                      .map(sec => (
                        <MenuItem key={sec} value={sec} style={{ padding: 20 }}>
                          {formatSessionDuration(sec)}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.sessionDuration.locked}
                    onChange={() =>
                      next(s => {
                        s.sessionDuration.locked = !s.sessionDuration.locked
                      })
                    }
                  ></Checkbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{Feedback}</TableCell>
                <TableCell>
                  <Select
                    value={sessionConfig.feedbackMode.value}
                    onChange={setFeedbackMode}
                  >
                    <MenuItem value={'none'}>
                      {FeedbackModeMenuItemNone}
                    </MenuItem>
                    <MenuItem value={'simple'}>
                      {FeedbackModeMenuItemSimple}
                    </MenuItem>
                    <MenuItem value={'expert'}>
                      {FeedbackModeMenuItemExpert}
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.feedbackMode.locked}
                    onChange={() =>
                      next(s => {
                        s.feedbackMode.locked = !s.feedbackMode.locked
                      })
                    }
                  ></Checkbox>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{Type}</TableCell>
                <TableCell>
                  <Select
                    value={sessionConfig.sessionType.value}
                    onChange={setSessionType}
                  >
                    <MenuItem value={'compressionOnly'}>{Compression}</MenuItem>
                    <MenuItem value={'30:2'}>30:2</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.sessionType.locked}
                    onChange={() =>
                      next(s => {
                        s.sessionType.locked = !s.sessionType.locked
                      })
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{Frequency}</TableCell>
                <TableCell style={{ minWidth: 200 }}>
                  <Select
                    value={sessionConfig.targetFrequency.value}
                    disabled={true}
                  >
                    <MenuItem value={sessionConfig.targetFrequency.value}>
                      {sessionConfig.targetFrequency.value}
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.targetFrequency.locked}
                    disabled={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{Result}</TableCell>
                <TableCell>
                  <Select
                    value={sessionConfig.resultType.value}
                    onChange={setResultType}
                  >
                    <MenuItem value={'none'}>{ResultTypeMenuItemNone}</MenuItem>
                    <MenuItem value={'simple'}>
                      {ResultTypeMenuItemSimple}
                    </MenuItem>
                    <MenuItem value={'standard'}>
                      {ResultTypeMenuItemStandard}
                    </MenuItem>
                    <MenuItem value={'expert'}>
                      {ResultTypeMenuItemExpert}
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.resultType.locked}
                    onChange={() =>
                      next(s => {
                        s.resultType.locked = !s.resultType.locked
                      })
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{ERC}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.showErcRoutine.value}
                    onChange={setExecRoutine}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={sessionConfig.showErcRoutine.locked}
                    onChange={() =>
                      next(s => {
                        s.showErcRoutine.locked = !s.showErcRoutine.locked
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              width: '100%',
            }}
          >
            <Button variant='contained' color='secondary' onClick={save}>
              {SaveButton}
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card style={{ width: '78%', marginTop: '20px' }}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{Property}</TableCell>
                <TableCell>{Value}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{MenuItemThresholdGood}</TableCell>
                <TableCell>
                  <TextField
                    type='number'
                    value={instituteResultConfig.simple.thresholdGood}
                    onChange={e => {
                      if (
                        Number.parseInt(e.target.value) >= 1 &&
                        Number.parseInt(e.target.value) <= 100
                      ) {
                        setTouched(true)
                        setThresholdGood(e)
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{MenuItemThresholdGreat}</TableCell>
                <TableCell>
                  <TextField
                    type='number'
                    value={instituteResultConfig.simple.thresholdGreat}
                    onChange={e => {
                      if (
                        Number.parseInt(e.target.value) >= 1 &&
                        Number.parseInt(e.target.value) <= 100
                      ) {
                        setTouched(true)
                        setThresholdGreat(e)
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>

        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              width: '100%',
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              onClick={saveResultConfig}
              disabled={!touched}
            >
              {TableRowResultConfigSaveButton}
            </Button>
          </div>
        </CardContent>
      </Card>
    </AppPageLayout>
  )
}

function formatSessionDuration(sec: number) {
  const minutes = Math.floor(sec / 60)
  const seconds = sec % 60

  const minFormated = minutes < 10 ? '0' + minutes : '' + minutes
  const secFormated = seconds < 10 ? '0' + seconds : '' + seconds

  return `${minFormated}:${secFormated}`
}

export default Settings
