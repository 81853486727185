import React, { useEffect } from 'react'
import { Colors } from 'theme/colors'
import { styled } from '@material-ui/styles'
import { SnackbarProvider, useSnackbar, MaterialDesignContent } from 'notistack'
import { useStoreHook } from 'state/store'
import { tools } from 'services/utils/tools'
import { AppSnackBarSuccessMessage } from './AppSnacBar.service'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Colors.supportingLime[3],
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Colors.primary[5],
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Colors.supportingCyan[5],
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Colors.vivid[5],
  },
}))

const SnackBarListener: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const store = useStoreHook()

  useEffect(() => {
    const { onAction } = tools(store)

    const subSuccess = onAction({ type: 'AppSnackBar/Success' }).subscribe(
      msg => {
        enqueueSnackbar((msg.message as AppSnackBarSuccessMessage).message, {
          variant: 'success',
        })
      }
    )

    const subError = onAction({ type: 'AppSnackBar/Error' }).subscribe(msg => {
      enqueueSnackbar((msg.message as AppSnackBarSuccessMessage).message, {
        variant: 'error',
      })
    })

    const cleanup = () => {
      subSuccess.unsubscribe()
      subError.unsubscribe()
    }

    return cleanup
  })

  return <></>
}

export const AppSnackBar: React.FC<{}> = props => {
  // const defaultToast = () => enqueueSnackbar('Default', { variant: 'default' })
  // const errorToast = () => enqueueSnackbar('Error', { variant: 'error' })
  // const infoToast = () => enqueueSnackbar('Info', { variant: 'info' })
  // const successToast = () => enqueueSnackbar('Success', { variant: 'success' })

  return (
    <SnackbarProvider
      maxSnack={3}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
      }}
    >
      <SnackBarListener />
      {props.children}
    </SnackbarProvider>
  )
}
