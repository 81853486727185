import { defineMessages } from 'react-intl'

export default defineMessages({
  successSnackbar: {
    id: 'cp.components.changeUserRole.successSnackbar',
    defaultMessage: 'Changed role to {role}',
  },
  errorSnackbar: {
    id: 'cp.components.changeUserRole.errorSnackbar',
    defaultMessage:
      'An unknown error occured. Please try again or contact the support if the error persists.',
  },
  selectLabel: {
    id: 'cp.components.changeUserRole.selectLabel',
    defaultMessage: 'choose role',
  },
})
