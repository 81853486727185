import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { AppPageLayout } from '../../components/layouts/AppPageLayout'
import { useIntl } from 'react-intl'

import messages from './Dashboard.messages'
import { Grid } from '@material-ui/core'
import { useDashboardState } from './Dashboard.restate'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import GroupIcon from '@material-ui/icons/Group'
import HealingIcon from '@material-ui/icons/Healing'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { Typography } from '@material-ui/core'
import { DashboardTile } from './DashboardTile'

const useStyles = makeStyles({
  title: {
    width: '100%',
    textAlign: 'center',
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
  numberBox: {
    textAlign: 'center',
    margin: '100px 0',
  },
  materialIcon: {
    position: 'relative',
    fontSize: '200%',
  },
})

const Dashboard = () => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage(messages.pageTitle)
  const usersCount = intl.formatMessage(messages.usersCount)
  const mobilesCount = intl.formatMessage(messages.mobilesCount)
  const cpsCount = intl.formatMessage(messages.cpsCount)
  const trainingsCount = intl.formatMessage(messages.trainingsCount)
  const lastMonth = intl.formatMessage(messages.lastMonth)
  const usersCountRecently = intl.formatMessage(messages.usersCountRecently)
  const mobilesCountRecently = intl.formatMessage(messages.mobilesCountRecently)
  const cpsCountRecently = intl.formatMessage(messages.cpsCountRecently)

  const dashboard = useDashboardState(s => s.data)
  const classes = useStyles()

  return (
    <AppPageLayout title={pageTitle}>
      <div className={classes.wrapper}>
        <Grid container>
          <DashboardTile
            amount={dashboard.usersCount}
            description={usersCount}
            size={3}
          >
            <GroupIcon className={classes.materialIcon} />
          </DashboardTile>
          <DashboardTile
            amount={dashboard.mobilesCount}
            description={mobilesCount}
            size={3}
          >
            <PhoneAndroidIcon className={classes.materialIcon} />
          </DashboardTile>
          <DashboardTile
            amount={dashboard.cpsCount}
            description={cpsCount}
            size={3}
          >
            <FavoriteBorderIcon className={classes.materialIcon} />
          </DashboardTile>
          <DashboardTile
            amount={dashboard.trainingsCount}
            description={trainingsCount}
            size={3}
          >
            <HealingIcon className={classes.materialIcon} />
          </DashboardTile>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h5'} className={classes.title}>
              {lastMonth}
              <CalendarTodayIcon className={classes.materialIcon} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <DashboardTile
            amount={dashboard.usersCountRecently}
            description={usersCountRecently}
            size={4}
          >
            <GroupIcon className={classes.materialIcon} />
          </DashboardTile>
          <DashboardTile
            amount={dashboard.mobilesCountRecently}
            description={mobilesCountRecently}
            size={4}
          >
            <PhoneAndroidIcon className={classes.materialIcon} />
          </DashboardTile>
          <DashboardTile
            amount={dashboard.cpsCountRecently}
            description={cpsCountRecently}
            size={4}
          >
            <FavoriteBorderIcon className={classes.materialIcon} />
          </DashboardTile>
        </Grid>
      </div>
    </AppPageLayout>
  )
}

export default Dashboard
