import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { NotificationType } from 'model/Notification'
import React, { useEffect, useState } from 'react'
import { useDispatchHook } from 'state/store'
import CptConfig from '../../components/Config/CptConfig/CptConfig'
import { useGlobalSettingsState } from './GlobalSettings.restate'

const useStyles = makeStyles({
  card: {
    marginTop: '1em',
    width: '100%',
  },
  dialogContent: {
    minWidth: '15vw',
    minHeight: '10vh',
  },
  select: {
    width: '100%',
    margin: 'auto',
  },
})

const GlobalSettingsLoading: React.FC = () => (
  <AppPageLayout title='Global Settings' loading></AppPageLayout>
)

export const GlobalSettings: React.FC = () => {
  const cptConfig = useGlobalSettingsState(s => s)
  const cptConfigLoading = cptConfig.meta.loading

  const dispatch = useDispatchHook()

  const save = () => dispatch({ type: 'Page/GlobalSettings/Config/Save' })

  useEffect(() => {
    dispatch({ type: 'Page/GlobalSettings/Config/Load' })

    // dispatch is not wrapped inside useCallback an thus cause infinite call of this side effect, too bad.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (cptConfigLoading) return <GlobalSettingsLoading />

  return (
    <AppPageLayout title='Global Settings'>
      <CptConfig />

      <Button
        variant='contained'
        color='secondary'
        style={{ marginTop: '2rem', minWidth: '300px' }}
        onClick={save}
      >
        Save All
      </Button>
    </AppPageLayout>
  )
}

export interface NotificationSelectCardProps {
  handleSubmit: (notificationType: NotificationType) => void
  handleClose: () => void
  showClose: boolean
}

export const NotificationSelectCard = ({
  handleSubmit,
  showClose,
  handleClose,
}: NotificationSelectCardProps) => {
  const classes = useStyles()

  const notificationDefinitions: Record<NotificationType, string> = {
    'soc-critical': 'state of charge critical',
    'soc-low': 'state of charge low',
    'soh-critical': 'State of health',
    onboarding: 'onboarding',
    training: 'Training',
    'long-time-no-see': 'long-time-no-see',
  }

  const [
    notificationType,
    setNotificationType,
  ] = useState<NotificationType | null>(null)
  const [isOpen, setIsOpen] = useState(true)
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>Test Notifications globally</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormControl className={classes.select}>
          <InputLabel id='notification-select'>
            Select the type of notification to send
          </InputLabel>
          <Select
            value={notificationType}
            onChange={e =>
              setNotificationType(e.target.value as NotificationType)
            }
            labelId='notification-select'
          >
            {Object.entries(notificationDefinitions).map(
              ([key, description]) => (
                <MenuItem value={key}>{description}</MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {showClose && <Button onClick={() => handleClose()}>Close</Button>}

        <Button
          variant='contained'
          color='primary'
          disabled={!notificationType}
          onClick={() => notificationType && handleSubmit(notificationType)}
        >
          Send Notification
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GlobalSettings
