import {
  createStateHook,
  createNextHook,
  createActionsHook,
  ActionFactoryProps,
} from '@restate/core'
import { AppStoreProvider } from 'state/store'
import { UserChangeEmailState } from './UserChangeEmail.state'
import { AppMessages } from 'state/appMessages'
import { isEmail } from 'utils/isEmail'

export const useUserChangeEmailState = createStateHook(
  AppStoreProvider,
  state => state.pages.userChangeEmail
)
export const useNextUserChangeEmailState = () =>
  createNextHook(AppStoreProvider, state => state.pages.userChangeEmail)

const UserChangeEmailActions = ({
  next,
  state,
  dispatch,
}: ActionFactoryProps<UserChangeEmailState, AppMessages>) => {
  function validateEmail() {
    const { nextEmail } = state()
    if (nextEmail === '') {
      next(s => {
        s.errorNextEmail = true
        s.errorNextEmailMessage = 'eMail is empty'
      })
      return false
    }

    if (!isEmail(nextEmail)) {
      next(s => {
        s.errorNextEmail = true
        s.errorNextEmailMessage = 'This is not a valid eMail address'
      })
      return false
    }

    next(s => {
      s.errorNextEmail = false
    })

    return true
  }

  function setNextEmail(nextEmail: string) {
    next(s => {
      s.errorNextEmail = false
      s.errorNextEmailMessage = ''
      s.nextEmail = nextEmail
    })
  }

  function sendChangeEmailRequest() {
    const ok = validateEmail()
    if (ok) {
      dispatch({ type: 'USER/CHANGE_EMAIL/SEND' })
    }
  }

  return {
    validateEmail,
    setNextEmail,
    sendChangeEmailRequest,
  }
}

export const useUserChangeEmailActions = createActionsHook(
  AppStoreProvider,
  state => state.pages.userChangeEmail,
  UserChangeEmailActions
)
