import { Button, IconButton, TableCell, Tooltip } from '@material-ui/core'
import RightIcon from '@material-ui/icons/ChevronRightRounded'
import {
  GenericTable,
  GenericTableCellHeader,
} from 'components/GenericTable/GenericTable'
import { AppPageLayout } from 'components/layouts/AppPageLayout'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { useAppState, useDispatchHook } from 'state/store'
import { Images } from 'theme/Images'
import { theme } from 'theme/theme'
import { useTrainersState } from './Trainers.restate'
import { TrainersTableData } from './Trainers.state'
import DeleteIcon from '@material-ui/icons/Delete'
import { useIntl } from 'react-intl'

import messages from './Trainers.messages'
import { USERROLES } from 'model/UserRole'

const TrainersLoading = () => {
  const intl = useIntl()
  const trainersTitle = intl.formatMessage(messages.trainersTitle)

  return <AppPageLayout title={trainersTitle} loading={true}></AppPageLayout>
}

const TrainersNotFound = () => {
  const intl = useIntl()
  const trainersNotFoundTitle = intl.formatMessage(
    messages.trainersNotFoundTitle
  )
  const trainersNotFoundMessage = intl.formatMessage(
    messages.trainersNotFoundMessage
  )

  return (
    <PageNotFound
      title={trainersNotFoundTitle}
      message={trainersNotFoundMessage}
      img={Images.detailedExamination}
    />
  )
}

const TrainersTableEmpty = () => {
  const intl = useIntl()
  const trainersNotAvailableTitle = intl.formatMessage(
    messages.trainersNotAvailableTitle
  )
  const trainersNotAvailableMessage = intl.formatMessage(
    messages.trainersNotAvailableMessage
  )
  const trainersNotAvailableButton = intl.formatMessage(
    messages.trainersNotAvailableButton
  )

  const goTo = useGoToHook()
  const id = useAppState(state => (state.session.user.institute as any)._id)
  const goToTrainerInvitePage = () => goTo(AppRoutes.TrainerInvite, { id })

  return (
    <PageNotFound
      title={trainersNotAvailableTitle}
      message={trainersNotAvailableMessage}
      img={Images.medicine}
      action={() => (
        <Button
          variant='contained'
          color='secondary'
          onClick={goToTrainerInvitePage}
        >
          {trainersNotAvailableButton}
        </Button>
      )}
    />
  )
}

const TrainersTable = () => {
  const intl = useIntl()
  const trainersTitle = intl.formatMessage(messages.trainersTitle)
  const selected = intl.formatMessage(messages.selected)
  const deleteLabel = intl.formatMessage(messages.deleteLabel)
  const tableRowName = intl.formatMessage(messages.tableRowName)
  const tableRowEmail = intl.formatMessage(messages.tableRowEmail)
  const inviteTrainer = intl.formatMessage(messages.inviteTrainer)

  const goTo = useGoToHook()
  const trainers: TrainersTableData[] = useTrainersState(trainerPage => 
    trainerPage.data.map(trainer => {
      return {
        ...trainer,
        isSelectionEnabled: trainer.roles.filter(role => ![USERROLES.Trainer, USERROLES.Trainee].includes(role)).length === 0
      }
    })
  )
  const id = useAppState(state => (state.session.user.institute as any)._id)
  const goToTrainerInvitePage = () => goTo(AppRoutes.TrainerInvite, { id })
  const dispatch = useDispatchHook()

  const cellHeaders: GenericTableCellHeader[] = [
    {
      id: 'displayName',
      numeric: false,
      disablePadding: false,
      label: tableRowName,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: tableRowEmail,
    },
    { id: 'actions', numeric: true, disablePadding: false, label: '' },
  ]

  const search = (searchTerm: string) => {
    const searchExp = RegExp(searchTerm, 'i')
    return (entry: TrainersTableData) => {
      return searchExp.test(entry.displayName) || searchExp.test(entry.email)
    }
  }

  const navigateToUserDetailsPage = (id: string) =>
    goTo(AppRoutes.UserDetails, { id })

  const deleteTrainer = (ids: string[]) => {
    dispatch({ type: 'Pages/Trainers/Delete', payload: ids })
  }

  return (
    <AppPageLayout title={trainersTitle}>
      <div style={{ width: '100%', marginTop: theme.spacing(2) }}>
        <GenericTable
          rows={trainers}
          singleSelect={true}
          headers={cellHeaders}
          search={search as any}
          selectedItemsMessage={({ count }: { count: number }) => (
            <FormattedMessage
              id='admins_count'
              defaultMessage={`{count, number} {count, plural, one { trainer } other { trainers }} ${selected}`}
              values={{ count }}
            />
          )}
          rowRender={({ row }: { row: TrainersTableData }) => (
            <>
              <TableCell>{row.displayName}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell align='right'>
                <IconButton onClick={() => navigateToUserDetailsPage(row.id)}>
                  <RightIcon />
                </IconButton>
              </TableCell>
            </>
          )}
          tools={({ selected, setSelected }) => (
            <Tooltip title={deleteLabel}>
              <IconButton
                aria-label={deleteLabel}
                onClick={() => {
                  deleteTrainer(selected)
                  setSelected([])
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        />

        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={goToTrainerInvitePage}
          >
            {inviteTrainer}
          </Button>
        </div>
      </div>
    </AppPageLayout>
  )
}

export const Trainers: React.FC<{}> = () => {
  const { loading, notFound } = useTrainersState(s => s.meta)
  const empty = useTrainersState(s => s.data.length === 0)

  if (loading) return <TrainersLoading />
  if (notFound) return <TrainersNotFound />
  if (empty) return <TrainersTableEmpty />

  return <TrainersTable />
}

export default Trainers
