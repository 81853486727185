import { createStateHook, createNextHook } from '@restate/core'
import { AppStoreProvider } from 'state/store'

export const useLoginPasswordForgotSetPasswordState = createStateHook(
  AppStoreProvider,
  state => state.pages.loginPasswordForgotSetPassword
)

export const useNextLoginPasswordForgotSetPasswordState = () =>
  createNextHook(
    AppStoreProvider,
    state => state.pages.loginPasswordForgotSetPassword
  )
