import { CptConfig } from 'model/CptConfig'

export interface SettingsState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  appConfig: CptConfig | null
}

export const defaultSettingsState: SettingsState = {
  meta: {
    loading: false,
    notFound: false,
  },
  appConfig: null,
}
