import { GenericTableData } from 'components/GenericTable/GenericTable'

export interface ExportTableData extends GenericTableData {
  date: Date
  institute: string
  identifier: string
  location: string
  traineeCount: number
}

export interface ExportPageState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  courses: ExportTableData[]
}

export const defaultExportPageState: ExportPageState = {
  meta: {
    loading: false,
    notFound: false,
  },
  courses: [],
}
