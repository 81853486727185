import { Message } from '@restate/core'
import { PostMeChangeUserReq } from 'model/ctrl/me/PostMeChangeUserReq.schema'
import { routes } from 'model/ctrl/routes'
import { tools } from 'services/utils/tools'
import { AppStore } from 'state/store'
import { defaultMeState } from './Me.state'
import messages from './Me.messages'
import { AppRoutes } from 'routes'
import { defaultState } from 'state/state'
import { LogoutService } from 'services/logout/logout.service'

export interface PagesMeServiceMessages extends Message {
  type: 'Page/Me/Save' | 'Page/Me/Delete'
}

export const connectMePage = (store: AppStore) => {
  const { http, onAction, snackBar, onLogout, onChange, intl, goTo } = tools(
    store
  )

  const setLoading = (loading: boolean) =>
    store.next(s => {
      s.pages.me.meta.loading = loading
    })

  onChange(s => s.session.user).subscribe(() => {
    store.next(s => {
      s.pages.me = {
        firstName: s.session.user.firstName,
        familyName: s.session.user.familyName,
        gender: s.session.user.gender,
        meta: {
          loading: false,
          ready: true,
        },
      }
    })
  })

  onLogout().subscribe(() => {
    store.next(s => {
      s.pages.me = defaultMeState
    })
  })

  onAction({ type: 'Page/Me/Save' }).subscribe(() => {
    const me = store.state.pages.me

    const data: PostMeChangeUserReq = {
      firstName: me.firstName,
      familyName: me.familyName,
      gender: me.gender,
    }

    const onSuccess = () => {
      setLoading(false)
      store.next(s => {
        s.session.user.firstName = me.firstName
        s.session.user.familyName = me.familyName
        s.session.user.gender = me.gender
      })
      snackBar.success(intl().formatMessage(messages.snackBarSaved))
    }

    const onError = () => {
      setLoading(false)
      snackBar.error(intl().formatMessage(messages.snackBarError))
    }

    setLoading(true)

    http({
      route: { path: routes.me.changeMe },
      method: 'POST',
      data,
    }).subscribe(onSuccess, onError)
  })

  onAction({ type: 'Page/Me/Delete' }).subscribe(() => {
    http({
      route: { path: routes.auth.delete },
      method: 'DELETE',
    }).subscribe(
      () => {
        snackBar.success('The deletion of your account was successfull')
        const location = store.state.location
        store.next(() => ({ ...defaultState, location }), {
          type: LogoutService.Done,
        })
        goTo(AppRoutes.Logout)
        localStorage.removeItem('corpatch')
      },
      () => snackBar.error('There was an error while deleting your account.')
    )
  })
}
