import { createStateHook, createNextHook } from '@restate/core'
import { AppStoreProvider } from 'state/store'
import { State } from 'state/state'

const stateSelector = (state: State) => state.components.userMenu
export const useUserMenuState = createStateHook(AppStoreProvider, stateSelector)
export const useNextUserMenuState = createNextHook(
  AppStoreProvider,
  stateSelector
)
