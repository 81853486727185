import { AppStore } from '../../state/store'
import { tools } from '../../services/utils/tools'
import { routes } from '../../model/ctrl/routes'
import { UserSettings } from '../../model/UserSettings'
import { Message } from '@restate/core'
import { USERROLES } from '../../model/UserRole'
import { LOCAL_STORAGE_LANGUAGE } from 'I18N/I18N.state'

export interface PagesTranslationMenuServiceMessages extends Message {
  type: 'Page/Settings/Locale'
}

export const connectTranslationMenuPage = (store: AppStore) => {
  const { http, snackBar, onAction, hasUserRole } = tools(store)

  onAction({ type: 'Page/Settings/Locale' }).subscribe(() => {
    const i18n = store.state.i18n.locale
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE, i18n)

    hasUserRole(
      USERROLES.CorPatchAdmin,
      USERROLES.InstituteAdmin,
      USERROLES.Trainee,
      USERROLES.Trainer
    ).subscribe(() => {
      const data = { locale: i18n.split('-')[0] }

      http<UserSettings>({
        route: { path: routes.me.settings },
        method: 'POST',
        data,
      }).subscribe(() => {
        snackBar.success('Language saved')
      })
    })
  })
}
