import { Button } from '@material-ui/core'
import { PageNotFound } from 'components/PageNotFound/PageNotFound'
import React from 'react'
import { AppRoutes } from 'routes'
import { useGoToHook } from 'services/router/useGoTo.hook'
import { Images } from 'theme/Images'
import { useIntl } from 'react-intl'

import messages from './Logout.messages'

export const Logout: React.FC<{}> = () => {
  const intl = useIntl()
  const logoutTitle = intl.formatMessage(messages.logoutTitle)
  const logoutMessage = intl.formatMessage(messages.logoutMessage)
  const loginButton = intl.formatMessage(messages.loginButton)

  const go = useGoToHook()
  return (
    <PageNotFound
      variant='public'
      title={logoutTitle}
      message={logoutMessage}
      img={Images.logout}
      action={() => (
        <Button
          color='secondary'
          variant='contained'
          onClick={() => go(AppRoutes.Login)}
        >
          {loginButton}
        </Button>
      )}
    />
  )
}

export default Logout
