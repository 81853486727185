import { CourseTableData } from 'components/CourseTable/CourseTable'
import { Message } from '@restate/core'

export interface CoursesPageMessage extends Message {
  type:
    | 'Page/Courses/Service/Update'
    | 'Page/Courses/Service/Error'
    | 'Page/Courses/Delete'
  payload?: string
}

export interface CoursesState {
  meta: {
    loading: boolean
    notFound: boolean
  }
  data: CourseTableData[]
}

export const defaultCoursesState: CoursesState = {
  meta: {
    loading: true,
    notFound: false,
  },
  data: [],
}
